<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 175px;
      "
    >
      <div class="title1">
        <img
          src="@/assets/纹理.png"
          style="position: absolute; top: 0; right: 0"
        />
        <div style="margin-top: 36px">
          <div style="float: left; color: white">
            <span style="font-size: 20px">日用电量</span
            ><span style="font-size: 14px; margin-left: 5px">(kWh)</span>
          </div>
          <div style="float: right; color: white">
            <span style="font-size: 20px">月用电量</span
            ><span style="font-size: 14px; margin-left: 5px">(kWh)</span>
          </div>
          <div style="clear: both"></div>
        </div>
        <div style="margin-top: 23px">
          <div
            style="
              float: left;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 120px;
              text-align: center;
            "
          >
            <!-- 6543.5 -->
            {{ elecV1 }}
          </div>
          <div
            style="
              float: right;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 150px;
              text-align: center;
            "
          >
            <!-- 27.6 -->
            {{ elecV2 }}
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="title2">
        <img
          src="@/assets/纹理.png"
          style="position: absolute; top: 0; right: 0"
        />
        <div style="margin-top: 36px">
          <div style="float: left; color: white">
            <span style="font-size: 20px">日用水量</span
            ><span style="font-size: 14px; margin-left: 5px">(m3)</span>
          </div>
          <div style="float: right; color: white">
            <span style="font-size: 20px">月用水量</span
            ><span style="font-size: 14px; margin-left: 5px">(m3)</span>
          </div>
          <div style="clear: both"></div>
        </div>
        <div style="margin-top: 23px">
          <div
            style="
              float: left;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 108px;
              text-align: center;
            "
          >
            <!-- 123.8 -->
            {{ waterV1 }}
          </div>
          <div
            style="
              float: right;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 108px;
              text-align: center;
            "
          >
            <!-- 3230.8 -->
            {{ waterV2 }}
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="title3">
        <img
          src="@/assets/纹理.png"
          style="position: absolute; top: 0; right: 0"
        />
        <div style="margin-top: 36px">
          <div style="float: left; color: white">
            <span style="font-size: 20px">日用气量</span
            ><span style="font-size: 14px; margin-left: 5px">(m3)</span>
          </div>
          <div style="float: right; color: white">
            <span style="font-size: 20px">月用气量</span
            ><span style="font-size: 14px; margin-left: 5px">(m3)</span>
          </div>
          <div style="clear: both"></div>
        </div>
        <div style="margin-top: 23px">
          <div
            style="
              float: left;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 108px;
              text-align: center;
            "
          >
            <!-- 86.5 -->
            <!-- {{ airV1 }} -->
            321
          </div>
          <div
            style="
              float: right;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 108px;
              text-align: center;
            "
          >
            <!-- 2408.8 -->
            <!-- {{ airV2 }} -->
            8025
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="title4">
        <img
          src="@/assets/纹理.png"
          style="position: absolute; top: 0; right: 0"
        />
        <div style="margin-top: 36px">
          <div style="float: left; color: white">
            <span style="font-size: 20px">日用蒸汽量</span
            ><span style="font-size: 14px; margin-left: 5px">(T)</span>
          </div>
          <div style="float: right; color: white">
            <span style="font-size: 20px">月用蒸汽量</span
            ><span style="font-size: 14px; margin-left: 5px">(T)</span>
          </div>
          <div style="clear: both"></div>
        </div>
        <div style="margin-top: 23px">
          <div
            style="
              float: left;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 108px;
              text-align: center;
            "
          >
            <!-- 0 -->
            <!-- {{ heatV1 }} -->
            0
          </div>
          <div
            style="
              float: right;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 190px;
              text-align: right;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            <!-- 0 -->
            <!-- {{ heatV2 }} -->
            0
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 342px;
        margin-top: 20px;
      "
    >
      <div class="body1" style="padding: 18px">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                width: 4px;
                height: 20px;
                background: #3f6df8;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
              用能折标量占比分析
            </div>
          </div>
          <div style="">
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickNH(1)"
              :class="{ activeNH: isActivenTime === 1 }"
            >
              日
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickNH(2)"
              :class="{ activeNH: isActivenTime === 2 }"
            >
              月
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickNH(3)"
              :class="{ activeNH: isActivenTime === 3 }"
            >
              年
            </div>
          </div>
        </div>

        <div style="width: 100%; height: 310px">
          <v-chart ref="chart1" :option="chartPieOption1" />
        </div>
      </div>
      <div class="body2" style="padding: 18px">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                width: 4px;
                height: 20px;
                background: #3f6df8;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
              用能成本占比分析
            </div>
          </div>
          <div style="">
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickNH2(1)"
              :class="{ activeNH: isActivenTime2 === 1 }"
            >
              日
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickNH2(2)"
              :class="{ activeNH: isActivenTime2 === 2 }"
            >
              月
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickNH2(3)"
              :class="{ activeNH: isActivenTime2 === 3 }"
            >
              年
            </div>
          </div>
        </div>

        <div style="width: 100%; height: 310px">
          <v-chart ref="chart2" :option="chartPieOption2" />
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        background: white;
        height: 270px;
        margin-top: 20px;
        padding: 20px;
        padding-bottom: 0;
        padding-top: 10px;
      "
    >
      <div
        style="
          height: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        "
      >
        <div
          style="
            width: 4px;
            height: 20px;
            background: #3f6df8;
            margin-right: 10px;
          "
        ></div>
        <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
          月度用能环比
        </div>
      </div>

      <div
        style="
          width: 100%;
          height: 230px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div style="width: 25%; height: 100%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              color: #082254;
              margin-top: 10px;
              font-size: 16px;
              height: 21px;
            "
          >
            用电同期环比：
            <template v-if="isAddElec"
              ><img
                src="@/assets/指数上升.png"
                style="vertical-align: middle"
              />+{{ elecPercent }}%</template
            >
            <template v-if="isLessElec"
              ><img
                src="@/assets/指数下降.png"
                style="vertical-align: middle"
              />-{{ elecPercent }}%</template
            >
            <template v-if="isZeroElec"> 0% </template>
            <template v-if="isNullElec"> -- </template>
          </div>
          <div style="color: #082254; font-size: 14px; margin-left: 20px">
            (kWh)
          </div>
          <div style="width: 100%; height: 180px">
            <v-chart ref="chartB2" :option="chartBarOption1" />
          </div>
        </div>
        <div style="width: 25%; height: 100%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              color: #082254;
              margin-top: 10px;
              font-size: 16px;
              height: 21px;
            "
          >
            用水同期环比：
            <template v-if="isAddWater"
              ><img
                src="@/assets/指数上升.png"
                style="vertical-align: middle"
              />+{{ waterPercent }}%</template
            >
            <template v-if="isLessWater"
              ><img
                src="@/assets/指数下降.png"
                style="vertical-align: middle"
              />-{{ waterPercent }}%</template
            >
            <template v-if="isZeroWater"> 0% </template>
            <template v-if="isNullWater"> -- </template>
          </div>
          <div style="color: #082254; font-size: 14px; margin-left: 20px">
            (m3)
          </div>
          <div style="width: 100%; height: 180px">
            <v-chart ref="chartB1" :option="chartBarOption2" />
          </div>
        </div>
        <div style="width: 25%; height: 100%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              color: #082254;
              margin-top: 10px;
              font-size: 16px;
              height: 21px;
            "
          >
            用气同期环比：
            <template v-if="isAddAir"
              ><img
                src="@/assets/指数上升.png"
                style="vertical-align: middle"
              />+{{ airPercent }}%</template
            >
            <template v-if="isLessAir"
              ><img
                src="@/assets/指数下降.png"
                style="vertical-align: middle"
              />-{{ airPercent }}%</template
            >
            <template v-if="isZeroAir"> 0% </template>
            <template v-if="isNullAir"> -- </template>
          </div>
          <div style="color: #082254; font-size: 14px; margin-left: 20px">
            (m3)
          </div>
          <div style="width: 100%; height: 180px">
            <v-chart ref="chartB3" :option="chartBarOption3" />
          </div>
        </div>
        <div style="width: 25%; height: 100%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              color: #082254;
              margin-top: 10px;
              font-size: 16px;
              height: 21px;
            "
          >
            用蒸汽同期环比：
            <!-- <img
              src="@/assets/指数下降.png"
              style="vertical-align: middle"
            /> -->
            <template v-if="isAddHeat"
              ><img
                src="@/assets/指数上升.png"
                style="vertical-align: middle"
              />+{{ heatPercent }}%</template
            >
            <template v-if="isLessHeat"
              ><img
                src="@/assets/指数下降.png"
                style="vertical-align: middle"
              />-{{ heatPercent }}%</template
            >
            <template v-if="isZeroHeat"> 0% </template>
            <template v-if="isNullHeat"> -- </template>
          </div>
          <div style="color: #082254; font-size: 14px; margin-left: 20px">
            (T)
          </div>
          <div style="width: 100%; height: 180px">
            <v-chart ref="chartB4" :option="chartBarOption4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";
import {
  getOverallElecSame,
  getOverallAirSame,
  getOverallSteamSame,
  getOverallWaterSame,
  getElecDay,
  getAirDay,
  getSteamDay,
  getWaterDay,
  getElecMonth,
  getAirMonth,
  getSteamMonth,
  getWaterMonth,
  setProject,
} from "@/request/apiLg";
export default {
  data() {
    // var dataAxis = ["当月", "上月"];
    // var data1 = [
    //   3230.8,
    //   {
    //     value: 2843.1,
    //     itemStyle: {
    //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         { offset: 0, color: "#55ffbc" },
    //         { offset: 0.62, color: "#47A5E1" },
    //         { offset: 1, color: "#3F6DF8" },
    //       ]),
    //       borderRadius: 15,
    //       opacity: 0.6,
    //     },
    //   },
    // ];
    // var data2 = [
    //   27.6,
    //   {
    //     value: 25.9,
    //     itemStyle: {
    //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         { offset: 0, color: "#55ffbc" },
    //         { offset: 0.62, color: "#47A5E1" },
    //         { offset: 1, color: "#3F6DF8" },
    //       ]),
    //       borderRadius: 15,
    //       opacity: 0.6,
    //     },
    //   },
    // ];
    // var data3 = [
    //   2408.8,
    //   {
    //     value: 2842.4,
    //     itemStyle: {
    //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         { offset: 0, color: "#55ffbc" },
    //         { offset: 0.62, color: "#47A5E1" },
    //         { offset: 1, color: "#3F6DF8" },
    //       ]),
    //       borderRadius: 15,
    //       opacity: 0.6,
    //     },
    //   },
    // ];
    var colorList_data_2 = [
      {
        c1: "#6DCB86", //实践
        c2: "#6DCB86",
        c3: "#6DCB86",
      },
      {
        c1: "#E97F75", //操作
        c2: "#EA9B7A",
        c3: "#F6B084",
      },
      {
        c1: "#8C44E1", //操作
        c2: "#AD6BF1",
        c3: "#B97DF9",
      },
      {
        c1: "#277CFE",
        c2: "#6BCDFF", //操作
      },
    ];
    return {
      isAddElec: false,
      isLessElec: false,
      isZeroElec: false,
      isNullElec: false,
      elecPercent: "",

      isAddWater: false,
      isLessWater: false,
      isZeroWater: false,
      isNullWater: false,
      waterPercent: "",

      isAddAir: false,
      isLessAir: false,
      isZeroAir: false,
      isNullAir: false,
      airPercent: "",

      isAddHeat: false,
      isLessHeat: false,
      isZeroHeat: false,
      isNullHeat: false,
      heatPercent: "",
      elecV1: "",
      elecV2: "",
      waterV1: "",
      waterV2: "",
      airV1: "",
      airV2: "",
      heatV1: "",
      heatV2: "",
      dataAxis: ["上月", "当月"],
      dataValue1: [],
      dataValue2: [],
      dataValue3: [],
      dataValue4: [],
      isActivenTime: 1,
      isActivenTime2: 1,
      // chartBarOption1: {

      // },
      // chartBarOption2: {

      // },
      // chartBarOption3: {

      // },
      // chartBarOption4: {},
      chartPieOption1: {
        color: ["#5492D1", "#46BCEE", "#ED9C00", "#53EFC3"],
        grid: {
          // containLabel: true,
          // top: "10px",
          // left: "5px",
          // right: "10px",
          // bottom: "10px",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 150,
          bottom: 20,
          // data: data.legendData,
          // selected: data.selected,
          textStyle: {
            color: "#082254",
          },
          data: [
            {
              name: "用电量",
              textStyle: {
                color: "#EA9779",
              },
            },
            {
              name: "用水量",
              textStyle: {
                color: "#3F6DF8",
              },
            },

            {
              name: "燃气量",
              textStyle: {
                color: "#A05CEB",
              },
            },
            {
              name: "蒸汽量",
              textStyle: {
                color: "#6DCB86",
              },
            },
          ],
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            // roseType: "radius",
            radius: "80%",
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              color: function (params) {
                // debugger
                var colorList = colorList_data_2;
                if (params.dataIndex === 3) {
                  return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                      offset: 0,
                      color: colorList[params.dataIndex].c1,
                    },
                    {
                      offset: 1,
                      color: colorList[params.dataIndex].c2,
                    },
                  ]);
                } else {
                  return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                      offset: 0,
                      color: colorList[params.dataIndex].c1,
                    },
                    {
                      //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                      offset: 0.5,
                      color: colorList[params.dataIndex].c2,
                    },
                    {
                      offset: 1,
                      color: colorList[params.dataIndex].c3,
                    },
                  ]);
                }
                /*  return colorList[params.dataIndex]*/
              },
            },
            label: {
              position: "outside",
              // alignTo: "edge",
              formatter: (data) => {
                // console.log("data", data);
                // debugger
                if (data.name === "蒸汽量") {
                  return `{a|蒸汽量: ${data.value}tec ${data.percent}%}`;
                } else if (data.name === "用电量") {
                  return `{b|用电量: ${data.value}tec ${data.percent}%}`;
                } else if (data.name === "燃气量") {
                  return `{c|燃气量: ${data.value}tec ${data.percent}%}`;
                } else if (data.name === "用水量") {
                  return `{d|用水量: ${data.value}tec ${data.percent}%}`;
                }
                // const rowArr = [];
                // data.forEach(() => {
                // const value = this.avaliableTwo(v.data.value[1]);
                // if (value) {
                //   if (v.data.itemStyle) {
                //     rowArr.push(
                //       `<span style="color:red;">单耗: ${value} </span>`
                //     );
                //   } else {
                //     rowArr.push(`<span style="">单耗: ${value} </span>`);
                //   }
                // }
                // });
                // rowArr.push(`记录时间：${this.formatToMin(data[0].axisValue)}`);
                // return rowArr.join("<br/>");
              },
              // minMargin: 5,
              // edgeDistance: 10,
              // lineHeight: 15,
              rich: {
                a: {
                  fontSize: 14,
                  color: "#6DCB86",
                  // fontWeight: "bold",
                },
                b: {
                  fontSize: 14,
                  color: "#EA9779",
                  // fontWeight: "bold",
                },
                c: {
                  fontSize: 14,
                  color: "#A05CEB",
                  // fontWeight: "bold",
                },
                d: {
                  fontSize: 14,
                  color: "#3F6DF8",
                  // fontWeight: "bold",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length2: 20,
              // smooth: true,
              // minTurnAngle: 50,
            },
            data: [
              { value: 0, name: "蒸汽量" },
              { value: 1750.1, name: "用电量" },
              { value: 363.1, name: "燃气量" },
              { value: 16.7, name: "用水量" },
            ],
          },
        ],
      },
      chartPieOption2: {
        color: ["#5492D1", "#46BCEE", "#ED9C00", "#53EFC3"],
        grid: {
          // containLabel: true,
          // top: "10px",
          // left: "5px",
          // right: "10px",
          // bottom: "10px",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 150,
          bottom: 20,
          // data: data.legendData,
          // selected: data.selected,
          textStyle: {
            color: "#082254",
          },
          data: [
            {
              name: "用电量",
              textStyle: {
                color: "#EA9779",
              },
            },
            {
              name: "用水量",
              textStyle: {
                color: "#3F6DF8",
              },
            },

            {
              name: "燃气量",
              textStyle: {
                color: "#A05CEB",
              },
            },
            {
              name: "蒸汽量",
              textStyle: {
                color: "#6DCB86",
              },
            },
          ],
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            // roseType: "radius",
            radius: "80%",
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              color: function (params) {
                // debugger
                var colorList = colorList_data_2;
                if (params.dataIndex === 3) {
                  return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                      offset: 0,
                      color: colorList[params.dataIndex].c1,
                    },
                    {
                      offset: 1,
                      color: colorList[params.dataIndex].c2,
                    },
                  ]);
                } else {
                  return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                      offset: 0,
                      color: colorList[params.dataIndex].c1,
                    },
                    {
                      //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                      offset: 0.5,
                      color: colorList[params.dataIndex].c2,
                    },
                    {
                      offset: 1,
                      color: colorList[params.dataIndex].c3,
                    },
                  ]);
                }
                /*  return colorList[params.dataIndex]*/
              },
            },
            label: {
              position: "outside",
              // alignTo: "edge",
              formatter: (data) => {
                // console.log("data", data);
                // debugger
                if (data.name === "蒸汽量") {
                  return `{a|蒸汽量: ${data.value}万元 ${data.percent}%}`;
                } else if (data.name === "用电量") {
                  return `{b|用电量: ${data.value}万元 ${data.percent}%}`;
                } else if (data.name === "燃气量") {
                  return `{c|燃气量: ${data.value}万元 ${data.percent}%}`;
                } else if (data.name === "用水量") {
                  return `{d|用水量: ${data.value}万元 ${data.percent}%}`;
                }
                // const rowArr = [];
                // data.forEach(() => {
                // const value = this.avaliableTwo(v.data.value[1]);
                // if (value) {
                //   if (v.data.itemStyle) {
                //     rowArr.push(
                //       `<span style="color:red;">单耗: ${value} </span>`
                //     );
                //   } else {
                //     rowArr.push(`<span style="">单耗: ${value} </span>`);
                //   }
                // }
                // });
                // rowArr.push(`记录时间：${this.formatToMin(data[0].axisValue)}`);
                // return rowArr.join("<br/>");
              },
              // minMargin: 5,
              // edgeDistance: 10,
              // lineHeight: 15,
              rich: {
                a: {
                  fontSize: 14,
                  color: "#6DCB86",
                  // fontWeight: "bold",
                },
                b: {
                  fontSize: 14,
                  color: "#EA9779",
                  // fontWeight: "bold",
                },
                c: {
                  fontSize: 14,
                  color: "#A05CEB",
                  // fontWeight: "bold",
                },
                d: {
                  fontSize: 14,
                  color: "#3F6DF8",
                  // fontWeight: "bold",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length2: 20,
              // smooth: true,
              // minTurnAngle: 50,
            },
            data: [
              { value: 0, name: "蒸汽量" },
              { value: 560.3, name: "用电量" },
              { value: 112.8, name: "燃气量" },
              { value: 12.4, name: "用水量" },
            ],
          },
        ],
      },
    };
  },
  methods: {
    clickNH(index) {
      this.isActivenTime = index;
    },
    clickNH2(index) {
      this.isActivenTime2 = index;
    },
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
    }),
    chartBarOption1() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: this.dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            data: this.dataValue1,
          },
        ],
      };
    },
    chartBarOption2() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: this.dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            data: this.dataValue2,
          },
        ],
      };
    },
    chartBarOption3() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: this.dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            data: this.dataValue3,
          },
        ],
      };
    },
    chartBarOption4() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: this.dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            data: this.dataValue4,
          },
        ],
      };
    },
  },
  watch: {
    currentProjectId(val) {
      // debugger
      console.log(val);
      this.isAddElec = false;
      this.isLessElec = false;
      this.isZeroElec = false;
      this.isNullElec = true;
      this.dataValue1 = [];

      this.isAddAir = false;
      this.isLessAir = false;
      this.isZeroAir = false;
      this.isNullAir = true;
      this.dataValue3 = [];

      this.isAddHeat = false;
      this.isLessHeat = false;
      this.isZeroHeat = false;
      this.isNullHeat = true;
      this.dataValue4 = [];

      this.isAddWater = false;
      this.isLessWater = false;
      this.isZeroWater = false;
      this.isNullWater = true;
      this.dataValue2 = [];

      this.elecV1 = "-- --";
      this.airV1 = "-- --";
      this.heatV1 = "-- --";
      this.waterV1 = "-- --";
      this.elecV2 = "-- --";
      this.airV2 = "-- --";
      this.heatV2 = "-- --";
      this.waterV2 = "-- --";
      setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
        (res2) => {
          if (res2.data.code === 0) {
            getOverallElecSame().then((res) => {
              // if (res.data) {
              //   if (res.data.data.formatValue === null) {
              //     this.elecUse = "-- --";
              //   } else {
              //     this.elecUse = res.data.data.formatValue;
              //   }
              // } else {
              //   this.elecUse = "-- --";
              // }
              if (res.data.data.length === 0) {
                this.isAddElec = false;
                this.isLessElec = false;
                this.isZeroElec = false;
                this.isNullElec = true;
                this.dataValue1 = [];
                return;
              }
              if (res.data.data[1].formatValue === null) {
                res.data.data[1].formatValue = "";
              }
              if (res.data.data[0].formatValue === null) {
                res.data.data[0].formatValue = "";
              }

              if (
                res.data.data[1].formatValue === "" &&
                res.data.data[0].formatValue === ""
              ) {
                this.isAddElec = false;
                this.isLessElec = false;
                this.isZeroElec = false;
                this.isNullElec = true;
              } else if (
                res.data.data[0].formatValue === "0" ||
                res.data.data[0].formatValue === ""
              ) {
                this.isAddElec = false;
                this.isLessElec = false;
                this.isZeroElec = false;
                this.isNullElec = true;
              }
              // Number(str)
              else if (
                Number(res.data.data[1].formatValue) ===
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddElec = false;
                this.isLessElec = false;
                this.isZeroElec = true;
                this.isNullElec = false;
              } else if (
                Number(res.data.data[1].formatValue) >
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddElec = true;
                this.isLessElec = false;
                this.isZeroElec = false;
                this.isNullElec = false;
                this.elecPercent = (
                  ((Number(res.data.data[1].formatValue) -
                    Number(res.data.data[0].formatValue)) /
                    Number(res.data.data[0].formatValue)) *
                  100
                ).toFixed(1);
              } else if (
                Number(res.data.data[1].formatValue) <
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddElec = false;
                this.isLessElec = true;
                this.isZeroElec = false;
                this.isNullElec = false;
                this.elecPercent = (
                  ((Number(res.data.data[0].formatValue) -
                    Number(res.data.data[1].formatValue)) /
                    Number(res.data.data[0].formatValue)) *
                  100
                ).toFixed(1);
              }
              this.dataValue1 = [
                {
                  value: res.data.data[0].formatValue,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#55ffbc" },
                      { offset: 0.62, color: "#47A5E1" },
                      { offset: 1, color: "#3F6DF8" },
                    ]),
                    borderRadius: 15,
                    opacity: 0.6,
                  },
                },
                res.data.data[1].formatValue,
              ];
            });
            getOverallAirSame().then((res) => {
              if (res.data.data.length === 0) {
                this.isAddAir = false;
                this.isLessAir = false;
                this.isZeroAir = false;
                this.isNullAir = true;
                this.dataValue3 = [];
                return;
              }
              if (res.data.data[1].formatValue === null) {
                res.data.data[1].formatValue = "";
              }
              if (res.data.data[0].formatValue === null) {
                res.data.data[0].formatValue = "";
              }
              if (
                res.data.data[1].formatValue === "" &&
                res.data.data[0].formatValue === ""
              ) {
                this.isAddAir = false;
                this.isLessAir = false;
                this.isZeroAir = false;
                this.isNullAir = true;
              } else if (
                res.data.data[0].formatValue === "0" ||
                res.data.data[0].formatValue === ""
              ) {
                this.isAddAir = false;
                this.isLessAir = false;
                this.isZeroAir = false;
                this.isNullAir = true;
              }
              // Number(str)
              else if (
                Number(res.data.data[1].formatValue) ===
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddAir = false;
                this.isLessAir = false;
                this.isZeroAir = true;
                this.isNullAir = false;
              } else if (
                Number(res.data.data[1].formatValue) >
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddAir = true;
                this.isLessAir = false;
                this.isZeroAir = false;
                this.isNullAir = false;
                this.airPercent = (
                  ((Number(res.data.data[1].formatValue) -
                    Number(res.data.data[0].formatValue)) /
                    Number(res.data.data[0].formatValue)) *
                  100
                ).toFixed(1);
              } else if (
                Number(res.data.data[1].formatValue) <
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddAir = false;
                this.isLessAir = true;
                this.isZeroAir = false;
                this.isNullAir = false;
                this.airPercent = (
                  ((Number(res.data.data[0].formatValue) -
                    Number(res.data.data[1].formatValue)) /
                    Number(res.data.data[0].formatValue)) *
                  100
                ).toFixed(1);
              }

              this.dataValue3 = [
                {
                  value: res.data.data[0].formatValue,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#55ffbc" },
                      { offset: 0.62, color: "#47A5E1" },
                      { offset: 1, color: "#3F6DF8" },
                    ]),
                    borderRadius: 15,
                    opacity: 0.6,
                  },
                },
                res.data.data[1].formatValue,
              ];
            });
            getOverallSteamSame().then((res) => {
              if (res.data.data.length === 0) {
                this.isAddHeat = false;
                this.isLessHeat = false;
                this.isZeroHeat = false;
                this.isNullHeat = true;
                this.dataValue4 = [];
                return;
              }
              if (res.data.data[1].formatValue === null) {
                res.data.data[1].formatValue = "";
              }
              if (res.data.data[0].formatValue === null) {
                res.data.data[0].formatValue = "";
              }

              if (
                res.data.data[1].formatValue === "" &&
                res.data.data[0].formatValue === ""
              ) {
                this.isAddHeat = false;
                this.isLessHeat = false;
                this.isZeroHeat = false;
                this.isNullHeat = true;
              } else if (
                res.data.data[0].formatValue === "0" ||
                res.data.data[0].formatValue === ""
              ) {
                this.isAddHeat = false;
                this.isLessHeat = false;
                this.isZeroHeat = false;
                this.isNullHeat = true;
              }
              // Number(str)
              else if (
                Number(res.data.data[1].formatValue) ===
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddHeat = false;
                this.isLessHeat = false;
                this.isZeroHeat = true;
                this.isNullHeat = false;
              } else if (
                Number(res.data.data[1].formatValue) >
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddHeat = true;
                this.isLessHeat = false;
                this.isZeroHeat = false;
                this.isNullHeat = false;
                this.heatPercent = (
                  ((Number(res.data.data[1].formatValue) -
                    Number(res.data.data[0].formatValue)) /
                    Number(res.data.data[0].formatValue)) *
                  100
                ).toFixed(1);
              } else if (
                Number(res.data.data[1].formatValue) <
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddHeat = false;
                this.isLessHeat = true;
                this.isZeroHeat = false;
                this.isNullHeat = false;
                this.heatPercent = (
                  ((Number(res.data.data[0].formatValue) -
                    Number(res.data.data[1].formatValue)) /
                    Number(res.data.data[0].formatValue)) *
                  100
                ).toFixed(1);
              }
              this.dataValue4 = [
                {
                  value: res.data.data[0].formatValue,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#55ffbc" },
                      { offset: 0.62, color: "#47A5E1" },
                      { offset: 1, color: "#3F6DF8" },
                    ]),
                    borderRadius: 15,
                    opacity: 0.6,
                  },
                },
                res.data.data[1].formatValue,
              ];
            });
            getOverallWaterSame().then((res) => {
              if (res.data.data.length === 0) {
                this.isAddWater = false;
                this.isLessWater = false;
                this.isZeroWater = false;
                this.isNullWater = true;
                this.dataValue2 = [];
                return;
              }
              // debugger
              if (res.data.data[1].formatValue === null) {
                res.data.data[1].formatValue = "";
              }
              if (res.data.data[0].formatValue === null) {
                res.data.data[0].formatValue = "";
              }

              if (
                res.data.data[1].formatValue === "" &&
                res.data.data[0].formatValue === ""
              ) {
                this.isAddWater = false;
                this.isLessWater = false;
                this.isZeroWater = false;
                this.isNullWater = true;
              } else if (res.data.data[0].formatValue === "") {
                this.isAddWater = false;
                this.isLessWater = false;
                this.isZeroWater = false;
                this.isNullWater = true;
              } else if (res.data.data[0].formatValue === "0") {
                this.isAddWater = false;
                this.isLessWater = false;
                this.isZeroWater = false;
                this.isNullWater = true;
              }
              // Number(str)
              else if (
                Number(res.data.data[1].formatValue) ===
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddWater = false;
                this.isLessWater = false;
                this.isZeroWater = true;
                this.isNullWater = false;
              } else if (
                Number(res.data.data[1].formatValue) >
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddWater = true;
                this.isLessWater = false;
                this.isZeroWater = false;
                this.isNullWater = false;
                this.waterPercent = (
                  ((Number(res.data.data[1].formatValue) -
                    Number(res.data.data[0].formatValue)) /
                    Number(res.data.data[0].formatValue)) *
                  100
                ).toFixed(1);
              } else if (
                Number(res.data.data[1].formatValue) <
                Number(res.data.data[0].formatValue)
              ) {
                this.isAddWater = false;
                this.isLessWater = true;
                this.isZeroWater = false;
                this.isNullWater = false;
                this.waterPercent = (
                  ((Number(res.data.data[0].formatValue) -
                    Number(res.data.data[1].formatValue)) /
                    Number(res.data.data[0].formatValue)) *
                  100
                ).toFixed(1);
              }
              this.dataValue2 = [
                {
                  value: res.data.data[0].formatValue,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#55ffbc" },
                      { offset: 0.62, color: "#47A5E1" },
                      { offset: 1, color: "#3F6DF8" },
                    ]),
                    borderRadius: 15,
                    opacity: 0.6,
                  },
                },
                res.data.data[1].formatValue,
              ];
            });
            getElecDay().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.elecV1 = "-- --";
                } else {
                  this.elecV1 = res.data.data.formatValue;
                }
              } else {
                this.elecV1 = "-- --";
              }
            });
            getAirDay().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.airV1 = "-- --";
                } else {
                  this.airV1 = res.data.data.formatValue;
                }
              } else {
                this.airV1 = "-- --";
              }
            });
            getSteamDay().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.heatV1 = "-- --";
                } else {
                  this.heatV1 = res.data.data.formatValue;
                }
              } else {
                this.heatV1 = "-- --";
              }
            });
            getWaterDay().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.waterV1 = "-- --";
                } else {
                  this.waterV1 = res.data.data.formatValue;
                }
              } else {
                this.waterV1 = "-- --";
              }
            });

            getElecMonth().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.elecV2 = "-- --";
                } else {
                  this.elecV2 = res.data.data.formatValue;
                }
              } else {
                this.elecV2 = "-- --";
              }
            });
            getAirMonth().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.airV2 = "-- --";
                } else {
                  this.airV2 = res.data.data.formatValue;
                }
              } else {
                this.airV2 = "-- --";
              }
            });
            getSteamMonth().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.heatV2 = "-- --";
                } else {
                  this.heatV2 = res.data.data.formatValue;
                }
              } else {
                this.heatV2 = "-- --";
              }
            });
            getWaterMonth().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.waterV2 = "-- --";
                } else {
                  this.waterV2 = res.data.data.formatValue;
                }
              } else {
                this.waterV2 = "-- --";
              }
            });
          }
        }
      );
    },
  },
  mounted() {
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getOverallElecSame().then((res) => {
            // if (res.data) {
            //   if (res.data.data.formatValue === null) {
            //     this.elecUse = "-- --";
            //   } else {
            //     this.elecUse = res.data.data.formatValue;
            //   }
            // } else {
            //   this.elecUse = "-- --";
            // }
            if (res.data.data.length === 0) {
              this.isAddElec = false;
              this.isLessElec = false;
              this.isZeroElec = false;
              this.isNullElec = true;
              this.dataValue1 = [];
              return;
            }
            if (res.data.data[1].formatValue === null) {
              res.data.data[1].formatValue = "";
            }
            if (res.data.data[0].formatValue === null) {
              res.data.data[0].formatValue = "";
            }

            if (
              res.data.data[1].formatValue === "" &&
              res.data.data[0].formatValue === ""
            ) {
              this.isAddElec = false;
              this.isLessElec = false;
              this.isZeroElec = false;
              this.isNullElec = true;
            } else if (
              res.data.data[0].formatValue === "0" ||
              res.data.data[0].formatValue === ""
            ) {
              this.isAddElec = false;
              this.isLessElec = false;
              this.isZeroElec = false;
              this.isNullElec = true;
            }
            // Number(str)
            else if (
              Number(res.data.data[1].formatValue) ===
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddElec = false;
              this.isLessElec = false;
              this.isZeroElec = true;
              this.isNullElec = false;
            } else if (
              Number(res.data.data[1].formatValue) >
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddElec = true;
              this.isLessElec = false;
              this.isZeroElec = false;
              this.isNullElec = false;
              this.elecPercent = (
                ((Number(res.data.data[1].formatValue) -
                  Number(res.data.data[0].formatValue)) /
                  Number(res.data.data[0].formatValue)) *
                100
              ).toFixed(1);
            } else if (
              Number(res.data.data[1].formatValue) <
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddElec = false;
              this.isLessElec = true;
              this.isZeroElec = false;
              this.isNullElec = false;
              this.elecPercent = (
                ((Number(res.data.data[0].formatValue) -
                  Number(res.data.data[1].formatValue)) /
                  Number(res.data.data[0].formatValue)) *
                100
              ).toFixed(1);
            }
            this.dataValue1 = [
              {
                value: res.data.data[0].formatValue,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#55ffbc" },
                    { offset: 0.62, color: "#47A5E1" },
                    { offset: 1, color: "#3F6DF8" },
                  ]),
                  borderRadius: 15,
                  opacity: 0.6,
                },
              },
              res.data.data[1].formatValue,
            ];
          });
          getOverallAirSame().then((res) => {
            if (res.data.data.length === 0) {
              this.isAddAir = false;
              this.isLessAir = false;
              this.isZeroAir = false;
              this.isNullAir = true;
              this.dataValue3 = [];
              return;
            }
            if (res.data.data[1].formatValue === null) {
              res.data.data[1].formatValue = "";
            }
            if (res.data.data[0].formatValue === null) {
              res.data.data[0].formatValue = "";
            }
            if (
              res.data.data[1].formatValue === "" &&
              res.data.data[0].formatValue === ""
            ) {
              this.isAddAir = false;
              this.isLessAir = false;
              this.isZeroAir = false;
              this.isNullAir = true;
            } else if (
              res.data.data[0].formatValue === "0" ||
              res.data.data[0].formatValue === ""
            ) {
              this.isAddAir = false;
              this.isLessAir = false;
              this.isZeroAir = false;
              this.isNullAir = true;
            }
            // Number(str)
            else if (
              Number(res.data.data[1].formatValue) ===
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddAir = false;
              this.isLessAir = false;
              this.isZeroAir = true;
              this.isNullAir = false;
            } else if (
              Number(res.data.data[1].formatValue) >
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddAir = true;
              this.isLessAir = false;
              this.isZeroAir = false;
              this.isNullAir = false;
              this.airPercent = (
                ((Number(res.data.data[1].formatValue) -
                  Number(res.data.data[0].formatValue)) /
                  Number(res.data.data[0].formatValue)) *
                100
              ).toFixed(1);
            } else if (
              Number(res.data.data[1].formatValue) <
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddAir = false;
              this.isLessAir = true;
              this.isZeroAir = false;
              this.isNullAir = false;
              this.airPercent = (
                ((Number(res.data.data[0].formatValue) -
                  Number(res.data.data[1].formatValue)) /
                  Number(res.data.data[0].formatValue)) *
                100
              ).toFixed(1);
            }

            this.dataValue3 = [
              {
                value: res.data.data[0].formatValue,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#55ffbc" },
                    { offset: 0.62, color: "#47A5E1" },
                    { offset: 1, color: "#3F6DF8" },
                  ]),
                  borderRadius: 15,
                  opacity: 0.6,
                },
              },
              res.data.data[1].formatValue,
            ];
          });
          getOverallSteamSame().then((res) => {
            if (res.data.data.length === 0) {
              this.isAddHeat = false;
              this.isLessHeat = false;
              this.isZeroHeat = false;
              this.isNullHeat = true;
              this.dataValue4 = [];
              return;
            }
            if (res.data.data[1].formatValue === null) {
              res.data.data[1].formatValue = "";
            }
            if (res.data.data[0].formatValue === null) {
              res.data.data[0].formatValue = "";
            }

            if (
              res.data.data[1].formatValue === "" &&
              res.data.data[0].formatValue === ""
            ) {
              this.isAddHeat = false;
              this.isLessHeat = false;
              this.isZeroHeat = false;
              this.isNullHeat = true;
            } else if (
              res.data.data[0].formatValue === "0" ||
              res.data.data[0].formatValue === ""
            ) {
              this.isAddHeat = false;
              this.isLessHeat = false;
              this.isZeroHeat = false;
              this.isNullHeat = true;
            }
            // Number(str)
            else if (
              Number(res.data.data[1].formatValue) ===
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddHeat = false;
              this.isLessHeat = false;
              this.isZeroHeat = true;
              this.isNullHeat = false;
            } else if (
              Number(res.data.data[1].formatValue) >
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddHeat = true;
              this.isLessHeat = false;
              this.isZeroHeat = false;
              this.isNullHeat = false;
              this.heatPercent = (
                ((Number(res.data.data[1].formatValue) -
                  Number(res.data.data[0].formatValue)) /
                  Number(res.data.data[0].formatValue)) *
                100
              ).toFixed(1);
            } else if (
              Number(res.data.data[1].formatValue) <
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddHeat = false;
              this.isLessHeat = true;
              this.isZeroHeat = false;
              this.isNullHeat = false;
              this.heatPercent = (
                ((Number(res.data.data[0].formatValue) -
                  Number(res.data.data[1].formatValue)) /
                  Number(res.data.data[0].formatValue)) *
                100
              ).toFixed(1);
            }
            this.dataValue4 = [
              {
                value: res.data.data[0].formatValue,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#55ffbc" },
                    { offset: 0.62, color: "#47A5E1" },
                    { offset: 1, color: "#3F6DF8" },
                  ]),
                  borderRadius: 15,
                  opacity: 0.6,
                },
              },
              res.data.data[1].formatValue,
            ];
          });
          getOverallWaterSame().then((res) => {
            if (res.data.data.length === 0) {
              this.isAddWater = false;
              this.isLessWater = false;
              this.isZeroWater = false;
              this.isNullWater = true;
              this.dataValue2 = [];
              return;
            }
            if (res.data.data[1].formatValue === null) {
              res.data.data[1].formatValue = "";
            }
            if (res.data.data[0].formatValue === null) {
              res.data.data[0].formatValue = "";
            }

            if (
              res.data.data[1].formatValue === "" &&
              res.data.data[0].formatValue === ""
            ) {
              this.isAddWater = false;
              this.isLessWater = false;
              this.isZeroWater = false;
              this.isNullWater = true;
            } else if (res.data.data[0].formatValue === "") {
              this.isAddWater = false;
              this.isLessWater = false;
              this.isZeroWater = false;
              this.isNullWater = true;
            } else if (res.data.data[0].formatValue === "0") {
              this.isAddWater = false;
              this.isLessWater = false;
              this.isZeroWater = false;
              this.isNullWater = true;
            }
            // Number(str)
            else if (
              Number(res.data.data[1].formatValue) ===
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddWater = false;
              this.isLessWater = false;
              this.isZeroWater = true;
              this.isNullWater = false;
            } else if (
              Number(res.data.data[1].formatValue) >
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddWater = true;
              this.isLessWater = false;
              this.isZeroWater = false;
              this.isNullWater = false;
              this.waterPercent = (
                ((Number(res.data.data[1].formatValue) -
                  Number(res.data.data[0].formatValue)) /
                  Number(res.data.data[0].formatValue)) *
                100
              ).toFixed(1);
            } else if (
              Number(res.data.data[1].formatValue) <
              Number(res.data.data[0].formatValue)
            ) {
              this.isAddWater = false;
              this.isLessWater = true;
              this.isZeroWater = false;
              this.isNullWater = false;
              this.waterPercent = (
                ((Number(res.data.data[0].formatValue) -
                  Number(res.data.data[1].formatValue)) /
                  Number(res.data.data[0].formatValue)) *
                100
              ).toFixed(1);
            }
            this.dataValue2 = [
              {
                value: res.data.data[0].formatValue,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#55ffbc" },
                    { offset: 0.62, color: "#47A5E1" },
                    { offset: 1, color: "#3F6DF8" },
                  ]),
                  borderRadius: 15,
                  opacity: 0.6,
                },
              },
              res.data.data[1].formatValue,
            ];
          });
          getElecDay().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.elecV1 = "-- --";
              } else {
                this.elecV1 = res.data.data.formatValue;
              }
            } else {
              this.elecV1 = "-- --";
            }
          });
          getAirDay().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.airV1 = "-- --";
              } else {
                this.airV1 = res.data.data.formatValue;
              }
            } else {
              this.airV1 = "-- --";
            }
          });
          getSteamDay().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.heatV1 = "-- --";
              } else {
                this.heatV1 = res.data.data.formatValue;
              }
            } else {
              this.heatV1 = "-- --";
            }
          });
          getWaterDay().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.waterV1 = "-- --";
              } else {
                this.waterV1 = res.data.data.formatValue;
              }
            } else {
              this.waterV1 = "-- --";
            }
          });

          getElecMonth().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.elecV2 = "-- --";
              } else {
                this.elecV2 = res.data.data.formatValue;
              }
            } else {
              this.elecV2 = "-- --";
            }
          });
          getAirMonth().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.airV2 = "-- --";
              } else {
                this.airV2 = res.data.data.formatValue;
              }
            } else {
              this.airV2 = "-- --";
            }
          });
          getSteamMonth().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.heatV2 = "-- --";
              } else {
                this.heatV2 = res.data.data.formatValue;
              }
            } else {
              this.heatV2 = "-- --";
            }
          });
          getWaterMonth().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.waterV2 = "-- --";
              } else {
                this.waterV2 = res.data.data.formatValue;
              }
            } else {
              this.waterV2 = "-- --";
            }
          });
        }
      }
    );
  },
};
</script>

<style scoped>
.title1 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #f6b084 0%, #ea9b7a 46%, #e97f75 100%);
  border-radius: 4px;
  position: relative;
}
.title2 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #71a1de 0%, #5a82dd 46%, #516adc 100%);
  border-radius: 4px;
  position: relative;
}
.title3 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #b97df9 0%, #ad6bf1 54%, #8c44e1 100%);
  border-radius: 4px;
  position: relative;
}
.title4 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #6bcf84 0%, #6ec887 46%, #2aab4c 100%);
  border-radius: 4px;
  position: relative;
}

.body1 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.body2 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
</style>