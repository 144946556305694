<template>
  <div style="width: 100%; height: 100%">
    <div v-if="currentProjectId === 'mgm'" class="mgmbg">
      <div
        style="
          color: #fff;
          position: relative;
          top: 7.9%;
          left: 10.5%;
          width: 140px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        {{
          mgmData[tenantId + ".mgm.HVAC.ColdTotalFlow"]
            ? mgmData[tenantId + ".mgm.HVAC.ColdTotalFlow"]
            : "--"
        }}
      </div>
      <div
        style="
          color: #fff;
          position: relative;
          top: 9%;
          left: 10.5%;
          width: 140px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        {{
          mgmData[tenantId + ".mgm.HVAC.HotTotalFlow"]
            ? mgmData[tenantId + ".mgm.HVAC.HotTotalFlow"]
            : "--"
        }}
      </div>
      <div
        style="
          color: #fff;
          position: relative;
          top: 10.3%;
          left: 10.5%;
          width: 140px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        {{
          mgmData[tenantId + ".mgm.HP.ColdTotalFlow"]
            ? mgmData[tenantId + ".mgm.HP.ColdTotalFlow"]
            : "--"
        }}
      </div>
      <div
        style="
          color: #fff;
          position: relative;
          top: 11.4%;
          left: 10.5%;
          width: 140px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        {{
          mgmData[tenantId + ".mgm.CH.ColdTotalFlow"]
            ? mgmData[tenantId + ".mgm.CH.ColdTotalFlow"]
            : "--"
        }}
      </div>

      <div
        style="
          color: #fff;
          position: relative;
          top: 34.4%;
          left: 89%;
          width: 120px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        <input
          type="text"
          style="
            width: 100%;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            background: transparent;
            color: #fff;
            border: none;
            outline: none;
          "
          value="8"
        />
      </div>
      <div
        style="
          color: #fff;
          position: relative;
          top: 35.9%;
          left: 89%;
          width: 120px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        <input
          type="text"
          style="
            width: 100%;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            background: transparent;
            color: #fff;
            border: none;
            outline: none;
          "
          value="9"
        />
      </div>
      <div
        style="
          color: #fff;
          position: relative;
          top: 37.5%;
          left: 89%;
          width: 120px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        <input
          type="text"
          style="
            width: 100%;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            background: transparent;
            color: #fff;
            border: none;
            outline: none;
          "
          value="---"
        />
      </div>
      <div
        style="
          color: #fff;
          position: relative;
          top: 39%;
          left: 89%;
          width: 120px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        <input
          type="text"
          style="
            width: 100%;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            background: transparent;
            color: #fff;
            border: none;
            outline: none;
          "
          value="---"
        />
      </div>
      <div
        style="
          color: #fff;
          position: relative;
          top: 40.5%;
          left: 91.5%;
          width: 80px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        <input
          type="text"
          style="
            width: 100%;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            background: transparent;
            color: #fff;
            border: none;
            outline: none;
          "
          value="---"
        />
      </div>
      <div
        style="
          color: #fff;
          position: relative;
          top: 42%;
          left: 89%;
          width: 120px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
        "
      >
        <input
          type="text"
          style="
            width: 100%;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            background: transparent;
            color: #fff;
            border: none;
            outline: none;
          "
          value="7"
        />
      </div>
    </div>
    <div v-else-if="currentProjectId === 'wtzx'"></div>
    <div v-else class="backg">
      <div
        style="
          width: 100%;
          height: 50px;
          border-bottom: 1px solid rgba(8, 34, 84, 0.1);
          padding-left: 50px;
          padding-bottom: 20px;
          background: #082254;
        "
      >
        <div
          style="
            float: left;
            padding: 15px 15px 10px 15px;
            text-align: center;
            color: white;
            font-size: 16px;
            margin-right: 10px;
            cursor: pointer;
          "
          v-for="(item, index) in dataTabs"
          :key="index"
          @click="clickTabs(item)"
          :class="{ isClick: currentTabs === item.code }"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        v-if="currentProjectId === 'zgds'"
        style="width: 100%; height: calc(100% - 70px); position: relative"
      >
        <div
          v-if="currentTabs === 'T1'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg"
        >
          <div class="arr1">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ib"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ib"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 453px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ic"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ic"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 475px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Uab"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Uab"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 498px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ubc"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ubc"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 520px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Uca"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Uca"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 542px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.P"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 565px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Q"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Q"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 587px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.PF"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.PF"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 80%;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ep_imp"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ep_imp"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr2">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 33.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_003.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_003.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 33.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_003.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_003.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 33.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_004.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_004.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 33.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_004.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_004.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 264px;
                left: 33.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_005.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_005.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 33.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_005.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_005.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.2%;
                left: 33.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_006.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_006.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 359px;
                left: 33.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_006.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_006.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr3">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_007.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_007.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_007.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_007.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_008.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_008.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_008.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_008.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_009.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_009.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_009.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_009.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_010.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_010.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_010.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_010.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.8%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_011.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_011.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 434px;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_011.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_011.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 484px;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_012.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_012.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 506px;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_012.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_012.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 557px;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_013.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_013.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72%;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_013.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_013.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 629px;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_014.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_014.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 651px;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_014.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_014.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 701px;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_015.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_015.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 724px;
                left: 49.9%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_015.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_015.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr4">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_016.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_016.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_016.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_016.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_017.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_017.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_017.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_017.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_018.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_018.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_018.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_018.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_019.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_019.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_019.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_019.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.8%;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_020.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_020.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 434px;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_020.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_020.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 484px;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_021.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_021.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 506px;
                left: 66.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_021.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_021.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr5">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_022.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_022.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_022.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_022.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_023.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_023.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_023.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_023.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_024.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_024.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_024.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_024.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_025.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_025.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_025.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_025.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.8%;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_026.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_026.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 434px;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_026.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_026.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 484px;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_027.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_027.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 506px;
                left: 83.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_027.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_027.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr6">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ia"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ib"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ib"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 453px;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ic"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ic"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 475px;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Uab"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Uab"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 498px;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ubc"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ubc"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 520px;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Uca"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Uca"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 542px;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.P"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.P"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 565px;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Q"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Q"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 587px;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.PF"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.PF"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 80%;
                left: 92%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ep_imp"]
                  ? t1RealtimeData[tenantId + ".zgds.Elec_DTU1_1_028.Ep_imp"]
                  : "--"
              }}
            </div>
          </div>
        </div>

        <div
          v-if="currentTabs === 'T2'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg2"
        >
          <div class="arr1">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_1_002.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Ib"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Ib"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 453px;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Ic"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Ic"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 475px;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Uab"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Uab"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 498px;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Ubc"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Ubc"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 520px;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Uca"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Uca"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 542px;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.P"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 565px;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Q"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Q"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 587px;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.PF"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.PF"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 80%;
                left: 6.6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Ep_imp"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_029.Ep_imp"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr2">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 38.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_030.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_030.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 38.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_030.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_030.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 38.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_031.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_031.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 38.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_031.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_031.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 264px;
                left: 38.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_032.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_032.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 38.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_032.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_032.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.2%;
                left: 38.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_033.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_033.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 359px;
                left: 38.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_033.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_033.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr3">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_034.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_034.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_034.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_034.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_035.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_035.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_035.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_035.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_036.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_036.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_036.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_036.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_037.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_037.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_037.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_037.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.8%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_038.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_038.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 434px;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_038.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_038.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 484px;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_039.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_039.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 506px;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_039.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_039.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 557px;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_040.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_040.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72%;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_040.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_040.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 629px;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_041.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_041.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 651px;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_041.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_041.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 701px;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_042.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_042.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 724px;
                left: 57.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_042.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_042.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr4">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_043.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_043.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_043.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_043.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_044.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_044.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_044.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_044.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_045.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_045.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_045.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_045.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_046.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_046.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_046.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_046.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.8%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_047.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_047.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 434px;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_047.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_047.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 484px;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_048.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_048.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 506px;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_048.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_048.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72.8%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_049.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_049.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 76%;
                left: 75.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_049.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_049.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr5">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_050.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_050.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_050.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_050.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_051.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_051.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_051.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_051.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_052.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_052.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_052.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_052.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_053.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_053.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_053.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_053.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.8%;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_054.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_054.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 434px;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_054.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_054.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 484px;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_055.P"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_055.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 506px;
                left: 94.2%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_055.Ia"]
                  ? t2RealtimeData[tenantId + ".zgds.Elec_DTU1_2_055.Ia"]
                  : "--"
              }}
            </div>
          </div>
        </div>

        <div
          v-if="currentTabs === 'T3'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg3"
        >
          <div class="arr1">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ib"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ib"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 453px;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ic"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ic"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 475px;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Uab"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Uab"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 498px;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ubc"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ubc"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 520px;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Uca"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Uca"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 542px;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.P"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 565px;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Q"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Q"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 587px;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.PF"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.PF"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 80%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ep_imp"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_056.Ep_imp"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr2">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_057.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_057.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_057.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_057.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_058.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_058.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_058.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_058.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_059.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_059.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_059.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_059.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.2%;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_060.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_060.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 359px;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_060.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_060.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_061.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_061.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 434px;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_061.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_061.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 484px;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_062.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_062.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 506px;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_062.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_062.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 557px;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_063.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_063.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72%;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_063.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_063.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 629px;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_064.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_064.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 651px;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_064.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_064.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 701px;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_065.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_065.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 724px;
                left: 26.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_065.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_065.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr3">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 41.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_066.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_066.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 41.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_066.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_066.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 41.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_067.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_067.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 41.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_067.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_067.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 264px;
                left: 41.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_068.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_068.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 41.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_068.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_068.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.2%;
                left: 41.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_069.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_069.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 359px;
                left: 41.3%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_069.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_069.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr4">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 56.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_070.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_070.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 56.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_070.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_070.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 56.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_071.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_071.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 56.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_071.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_071.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 56.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_072.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_072.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 56.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_072.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_072.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 56.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_073.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_073.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 56.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_073.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_073.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr5">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 71%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_074.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_074.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 71%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_074.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_074.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 71%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_075.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_075.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 71%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_075.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_075.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 71%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_076.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_076.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 71%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_076.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_076.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr6">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_077.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_077.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_077.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_077.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_078.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_078.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_078.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_078.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_079.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_079.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_079.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_079.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 336px;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_080.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_080.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_080.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_080.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_081.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_081.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_081.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_081.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 62.6%;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_082.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_082.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 500px;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_082.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_082.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 549px;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_083.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_083.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 571px;
                left: 85.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_083.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_083.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr7">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ia"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ib"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ib"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 453px;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ic"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ic"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 475px;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Uab"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Uab"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 498px;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ubc"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ubc"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 520px;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Uca"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Uca"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 542px;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.P"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.P"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 565px;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Q"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Q"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 587px;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.PF"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.PF"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 80%;
                left: 93.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ep_imp"]
                  ? t3RealtimeData[tenantId + ".zgds.Elec_DTU1_3_084.Ep_imp"]
                  : "--"
              }}
            </div>
          </div>
        </div>

        <div
          v-if="currentTabs === 'T4'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg4"
        >
          <div class="arr1">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ib"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ib"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 453px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ic"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ic"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 475px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Uab"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Uab"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 498px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ubc"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ubc"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 520px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Uca"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Uca"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 542px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.P"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 565px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Q"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Q"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 587px;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.PF"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.PF"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 80%;
                left: 6%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ep_imp"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_085.Ep_imp"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr2">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_086.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_086.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_086.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_086.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_087.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_087.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_087.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_087.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_088.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_088.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_088.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_088.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.2%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_089.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_089.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 359px;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_089.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_089.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_090.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_090.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_090.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_090.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 479px;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_091.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_091.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 502px;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_091.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_091.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 549px;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_092.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_092.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 75%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_092.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_092.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 621px;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_093.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_093.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 84.8%;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_093.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_093.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 692px;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_094.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_094.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 714px;
                left: 30.7%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_094.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_094.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr3">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_095.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_095.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_095.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_095.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_096.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_096.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_096.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_096.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_097.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_097.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37.8%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_097.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_097.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_098.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_098.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_098.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_098.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.6%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_099.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_099.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_099.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_099.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63%;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_100.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_100.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 503px;
                left: 46.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_100.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_100.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr4">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 62.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_101.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_101.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 62.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_101.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_101.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 62.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_102.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_102.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 62.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_102.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_102.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 62.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_103.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_103.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 62.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_103.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_103.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 62.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_104.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_104.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 62.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_104.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_104.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr5">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 78.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_105.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_105.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 78.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_105.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_105.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 78.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_106.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_106.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 78.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_106.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_106.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 78.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_107.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_107.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 78.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_107.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_107.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr6">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_108.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_108.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_108.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_108.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_109.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_109.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_109.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_109.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_110.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_110.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_110.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_110.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 336px;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_111.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_111.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_111.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_111.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_112.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_112.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_112.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_112.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 62.6%;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_113.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_113.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 500px;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_113.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_113.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 549px;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_114.P"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_114.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 571px;
                left: 94.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_114.Ia"]
                  ? t4RealtimeData[tenantId + ".zgds.Elec_DTU1_4_114.Ia"]
                  : "--"
              }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="currentProjectId === 'tcsd'"
        style="width: 100%; height: calc(100% - 70px); position: relative"
      >
        <div
          v-if="currentTabs === '高变'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg20"
        ></div>
        <div
          v-if="currentTabs === 'T1'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg21"
        ></div>
        <div
          v-if="currentTabs === 'T2'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg22"
        ></div>
        <div
          v-if="currentTabs === 'T3'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg23"
        ></div>
        <div
          v-if="currentTabs === 'T4'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg24"
        ></div>
        <div
          v-if="currentTabs === 'T5'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg25"
        ></div>
        <div
          v-if="currentTabs === 'T6'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg26"
        ></div>
      </div>

      <div
        v-else
        style="width: 100%; height: calc(100% - 50px); position: relative"
      >
        <div
          v-if="currentTabs === 'T1'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg31"
        >
          <div class="arr1">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63.8%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66.7%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ib"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ib"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 69.6%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ic"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ic"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72.6%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Uab"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Uab"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 75.5%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ubc"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ubc"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 78.5%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Uca"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Uca"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 81.4%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.P"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 84.4%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Q"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Q"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 87.3%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.PF"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.PF"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 90.3%;
                left: 5.04%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ep_imp"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_101.Ep_imp"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr2">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.3%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_002.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_002.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_002.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_002.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_003.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_003.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_003.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_003.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_004.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_004.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_004.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_004.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.2%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_005.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_005.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.2%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_005.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_005.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_006.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_006.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 17.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_006.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_006.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr3">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.3%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_007.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_007.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_007.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_007.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_008.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_008.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_008.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_008.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_009.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_009.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37.8%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_009.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_009.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_010.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_010.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_010.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_010.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.6%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_011.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_011.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.6%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_011.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_011.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_012.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_012.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66%;
                left: 32.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_012.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_012.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr4">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_013.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_013.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_013.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_013.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_014.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_014.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_014.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_014.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_015.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_015.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_015.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_015.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_016.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_016.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_016.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_016.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.6%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_017.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_017.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_017.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_017.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_018.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_018.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_018.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_018.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72.4%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_019.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_019.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 75.1%;
                left: 48.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_019.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU1_4_019.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr5">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_020.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_020.P"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_020.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_020.Ia"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_021.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_021.P"]
                  : "--"
              }} -->
              22.1
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_021.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_021.Ia"]
                  : "--"
              }} -->
              30.8
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_022.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_022.P"]
                  : "--"
              }} -->
              8.16
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_022.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_022.Ia"]
                  : "--"
              }} -->
              15.5
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 45.2%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_023.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_023.P"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.8%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_023.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_023.Ia"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 54.7%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_024.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_024.P"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 57.4%;
                left: 63.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_024.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_024.Ia"]
                  : "--"
              }} -->
              0
            </div>
          </div>

          <div class="arr6">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37%;
                left: 69.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_025.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_025.P"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 40%;
                left: 69.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_025.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_025.Ia"]
                  : "--"
              }} -->
              0
            </div>
          </div>
          <div class="arr7">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37%;
                left: 75.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_026.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_026.P"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 40%;
                left: 75.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_026.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_026.Ia"]
                  : "--"
              }} -->
              0
            </div>
          </div>

          <div class="arr8">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63.8%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ia"]
                  : "--"
              }} -->
              0
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66.8%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ib"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ib"]
                  : "--"
              }} -->
              0
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 69.8%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ic"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ic"]
                  : "--"
              }} -->
              0
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72.8%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Uab"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Uab"]
                  : "--"
              }} -->
              0
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 75.8%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ubc"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ubc"]
                  : "--"
              }} -->
              0
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 78.8%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Uca"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Uca"]
                  : "--"
              }} -->
              0
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 81.8%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.P"]
                  : "--"
              }} -->
              0
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 84.8%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Q"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Q"]
                  : "--"
              }} -->
              0
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 87.7%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.PF"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.PF"]
                  : "--"
              }} -->
              0
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 90.8%;
                left: 79.5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ep_imp"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_027.Ep_imp"]
                  : "--"
              }} -->
              0
            </div>
          </div>
          <div class="arr9">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_028.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_028.P"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_028.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_028.Ia"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_029.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_029.P"]
                  : "--"
              }} -->
              5.6
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_029.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_029.Ia"]
                  : "--"
              }} -->
              7.8
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_030.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_030.P"]
                  : "--"
              }} -->
              3.8
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_030.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_030.Ia"]
                  : "--"
              }} -->
              5.3
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 45.2%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_031.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_031.P"]
                  : "--"
              }} -->
              2.2
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 48%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_031.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_031.Ia"]
                  : "--"
              }} -->
              3.1
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 54%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_032.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_032.P"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 57%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_032.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_032.Ia"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63.6%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_033.P"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_033.P"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66.6%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_033.Ia"]
                  ? t31RealtimeData[tenantId + ".jkjd.Elec_DTU2_1_033.Ia"]
                  : "--"
              }} -->
              0
            </div>
          </div>
        </div>
        <div
          v-if="currentTabs === 'T2'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg32"
        >
          <div class="arr1">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63.8%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66.8%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ib"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ib"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 69.8%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ic"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ic"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72.8%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Uab"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Uab"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 75.8%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ubc"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ubc"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 78.8%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Uca"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Uca"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 81.8%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.P"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 84.8%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Q"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Q"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 87.7%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.PF"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.PF"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 90.2%;
                left: 5%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ep_imp"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_102.Ep_imp"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr2">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_002.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_002.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_002.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_002.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_003.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_003.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_003.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_003.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_004.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_004.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_004.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_004.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.2%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_005.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_005.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.2%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_005.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_005.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_006.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_006.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 18.56%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_006.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_006.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr3">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_007.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_007.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_007.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_007.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_008.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_008.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_008.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_008.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_009.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_009.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37.8%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_009.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_009.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_010.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_010.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_010.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_010.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.6%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_011.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_011.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.6%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_011.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_011.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 64.1%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_012.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_012.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 67%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_012.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_012.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 74.4%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_013.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_013.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 77.3%;
                left: 34.85%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_013.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_013.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr4">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_014.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_014.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_014.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_014.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_015.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_015.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_015.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_015.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_016.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_016.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_016.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_016.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_017.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_017.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_017.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_017.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 54.7%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_018.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_018.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 57.6%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_018.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_018.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 65%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_019.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_019.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 68%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_019.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_019.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 74.4%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_020.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_020.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 77.3%;
                left: 51.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_020.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_5_020.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr5">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_021.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_021.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_021.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_021.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_022.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_022.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_022.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_022.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_023.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_023.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_023.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_023.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 43.8%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_024.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_024.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 46.8%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_024.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_024.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.8%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_025.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_025.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 57%;
                left: 67.4%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_025.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_025.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr6">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37%;
                left: 75%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_026.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_026.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 40%;
                left: 75%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_026.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_026.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr7">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37%;
                left: 80.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_027.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_027.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 40%;
                left: 80.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_027.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_027.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr8">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_028.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_028.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_028.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_028.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_029.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_029.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_029.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_029.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_030.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_030.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_030.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_030.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_031.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_031.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_031.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_031.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.5%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_032.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_032.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.4%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_032.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_032.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63.6%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_033.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_033.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66.6%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_033.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_033.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 73.8%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_034.P"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_034.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 76.6%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_034.Ia"]
                  ? t32RealtimeData[tenantId + ".jkjd.Elec_DTU1_6_034.Ia"]
                  : "--"
              }}
            </div>
          </div>
        </div>
        <div
          v-if="currentTabs === 'T3'"
          style="width: 100%; height: 100%; position: relative"
          :class="imgDirection === 'left' ? 'tbg331' : 'tbg332'"
        >
          <div
            style="
              wdith: 120px;
              height: 120px;
              position: absolute;
              top: 35%;
              right: 10px;
              cursor: pointer;
            "
            v-if="imgDirection === 'left'"
            @click="toRight"
          >
            <img src="@/assets/direction.png" />
          </div>
          <div
            style="
              wdith: 120px;
              height: 120px;
              position: absolute;
              top: 35%;
              right: 10px;
              cursor: pointer;
            "
            v-if="imgDirection === 'right'"
            @click="toLeft"
          >
            <img src="@/assets/direction.png" />
          </div>

          <div v-if="imgDirection === 'left'">
            <div class="arr1">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 63.8%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ia"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 66.8%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ib"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ib"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 69.8%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ic"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ic"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 72.8%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Uab"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Uab"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 75.8%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ubc"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ubc"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 78.8%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Uca"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Uca"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 81.8%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.P"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 84.8%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Q"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Q"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 87.7%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.PF"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.PF"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 90.2%;
                  left: 5.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ep_imp"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_103.Ep_imp"]
                    : "--"
                }}
              </div>
            </div>
            <div class="arr2">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 15.2%;
                  left: 19.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_002.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_002.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 18.4%;
                  left: 19.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_002.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_002.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 25%;
                  left: 19.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_003.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_003.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 28%;
                  left: 19.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_003.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_003.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 34.6%;
                  left: 19.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_004.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_004.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 38%;
                  left: 19.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_004.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_004.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 44.2%;
                  left: 19.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_005.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_005.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 47.2%;
                  left: 19.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_005.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_005.Ia"]
                    : "--"
                }}
              </div>
            </div>
            <div class="arr3">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 15.2%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_006.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_006.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 18.4%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_006.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_006.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 25%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_007.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_007.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 28%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_007.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_007.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 34.6%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_008.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_008.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 37.8%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_008.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_008.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 44.4%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_009.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_009.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 47.5%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_009.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_009.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 53.6%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_010.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_010.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 56.6%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_010.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_010.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 64.1%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_011.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_011.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 67%;
                  left: 36.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_011.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_011.Ia"]
                    : "--"
                }}
              </div>
            </div>
            <div class="arr4">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 15.2%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_012.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_012.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 18.4%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_012.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_012.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 25%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_013.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_013.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 28%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_013.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_013.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 34.6%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_014.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_014.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 38%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_014.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_014.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 44.4%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_015.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_015.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 47.5%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_015.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_015.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 53.6%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_016.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_016.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 56.5%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_016.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_016.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 62.6%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_017.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_017.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 65.8%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_017.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_017.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 549px;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_018.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_018.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 75%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_018.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_018.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 81%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_019.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_019.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 84%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_019.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_019.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 90%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_020.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_020.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 93%;
                  left: 53.1%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_020.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_7_020.Ia"]
                    : "--"
                }}
              </div>
            </div>

            <div class="arr5">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 15.2%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_021.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_021.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 18.4%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_021.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_021.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 25%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_022.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_022.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 28%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_022.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_022.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 34.6%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_023.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_023.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 38%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_023.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_023.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 43.9%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_024.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_024.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 46.8%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_024.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_024.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 53%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_025.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_025.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 56%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_025.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_025.Ia"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 61.9%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_026.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_026.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 64.8%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_026.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_026.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 71%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_027.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_027.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 73.8%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_027.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_027.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 80%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_028.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_028.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 83%;
                  left: 70.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_028.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_028.Ia"]
                    : "--"
                }}
              </div>
            </div>

            <div class="arr6">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 15.2%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_029.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_029.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 18.4%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_029.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_029.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 25%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_030.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_030.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 28%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_030.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_030.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 34.6%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_031.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_031.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 38%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_031.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_031.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 43.9%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_032.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_032.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 46.8%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_032.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_032.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 53%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_033.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_033.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 56%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_033.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_033.Ia"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 61.9%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_034.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_034.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 64.8%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_034.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_034.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 71%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_035.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_035.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 73.8%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_035.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_035.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 80%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_036.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_036.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 83%;
                  left: 87.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_036.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_036.Ia"]
                    : "--"
                }}
              </div>
            </div>
            <div class="arr7">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 37%;
                  left: 96.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_037.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_037.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 40%;
                  left: 96.2%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_037.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_037.Ia"]
                    : "--"
                }}
              </div>
            </div>
          </div>
          <div v-if="imgDirection === 'right'">
            <div class="arr1">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 37%;
                  left: 3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_038.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_038.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 40%;
                  left: 3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_038.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_038.Ia"]
                    : "--"
                }}
              </div>
            </div>
            <div class="arr2">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 65%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ia"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 68%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ib"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ib"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 70.8%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ic"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ic"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 73.6%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Uab"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Uab"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 76.6%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ubc"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ubc"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 79.8%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Uca"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Uca"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 82.6%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.P"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 85.6%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Q"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Q"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 88.6%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.PF"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.PF"]
                    : "--"
                }}
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 91.6%;
                  left: 10.3%;
                  text-align: left;
                  padding-left: 5px;
                  width: 100px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ep_imp"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_2_039.Ep_imp"]
                    : "--"
                }}
              </div>
            </div>
            <div class="arr3">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 15.2%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_040.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_040.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 18.4%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_040.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_040.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 25%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_041.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_041.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 28%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_041.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_041.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 34.6%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_042.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_042.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 38%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_042.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_042.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 44.2%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_043.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_043.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 47.2%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_043.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_043.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 53.5%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_044.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_044.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 56.5%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_044.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_044.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 62.6%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_045.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_045.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 65.8%;
                  left: 27.05%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_045.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_045.Ia"]
                    : "--"
                }}
              </div>
            </div>

            <div class="arr3">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 15.2%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_046.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_046.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 18.4%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_046.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_046.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 25%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_047.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_047.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 28%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_047.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_047.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 34.6%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_048.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_048.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 38%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_048.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_048.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 44.4%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_049.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_049.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 47.5%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_049.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_049.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 53.6%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_050.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_050.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 56.5%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_050.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_050.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 63%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_051.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_051.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 66%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_051.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_051.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 72.4%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_052.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_052.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 75.1%;
                  left: 61.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_052.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_052.Ia"]
                    : "--"
                }}
              </div>
            </div>

            <div class="arr4">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 15.2%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_053.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_053.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 18.4%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_053.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_053.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 25%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_054.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_054.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 28%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_054.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_054.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 34.6%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_055.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_055.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 38%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_055.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_055.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 43.8%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_056.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_056.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 46.8%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_056.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_056.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 53%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_057.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_057.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 56%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_057.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_057.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 63%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_058.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_058.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 66%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_058.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_058.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 72%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_059.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_059.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 75%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_059.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_059.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 81%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_060.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_060.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 84%;
                  left: 78.15%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_060.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_060.Ia"]
                    : "--"
                }}
              </div>
            </div>

            <div class="arr5">
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 15.2%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_061.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_061.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 18.4%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_061.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_061.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 25%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_062.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_062.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 28%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_062.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_062.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 34.6%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_063.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_063.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 38%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_063.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_063.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 43.8%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_064.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_064.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 46.8%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_064.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_064.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 53%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_065.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_065.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 56%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_065.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_065.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 63%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_066.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_066.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 66%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_066.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_066.Ia"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 72%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_067.P"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_067.P"]
                    : "--"
                }}
              </div>
              <div
                style="
                  position: absolute;
                  color: white;
                  font-size: 14px;
                  top: 75%;
                  left: 94.8%;
                  text-align: left;
                  padding-left: 5px;
                  width: 50px;
                  height: 20px;
                  line-height: 20px;
                "
              >
                {{
                  t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_067.Ia"]
                    ? t33RealtimeData[tenantId + ".jkjd.Elec_DTU1_3_067.Ia"]
                    : "--"
                }}
              </div>
            </div>
          </div>

          <!-- <div
          style="
            wdith: 120px;
            height: 120px;
            position: absolute;
            top: 45.2%;
            left: 10px;
            cursor: pointer;
          "
          v-if="imgDirection === 'right'"
          @click="toLeft"
        >
          <img src="@/assets/向右.png" />
        </div> -->
        </div>
        <div
          v-if="currentTabs === 'T4'"
          style="width: 100%; height: 100%; position: relative"
          class="tbg34"
        >
          <div class="arr1">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63.8%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66.8%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ib"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ib"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 69.8%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ic"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ic"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72.8%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Uab"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Uab"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 75.8%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ubc"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ubc"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 78.8%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Uca"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Uca"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 81.8%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.P"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 84.8%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Q"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Q"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 87.7%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.PF"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.PF"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 90.2%;
                left: 5.1%;
                text-align: left;
                padding-left: 5px;
                width: 100px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ep_imp"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_104.Ep_imp"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr2">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 16.15%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_002.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_002.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 16.15%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_002.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_002.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 16.15%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_003.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_003.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 16.15%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_003.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_003.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 16.15%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_004.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_004.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 16.15%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_004.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_004.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.2%;
                left: 16.15%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_005.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_005.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.2%;
                left: 16.15%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_005.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_005.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr3">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_006.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_006.P"]
                  : "--"
              }} -->
              58.5
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_006.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_006.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_007.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_007.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_007.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_007.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_008.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_008.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37.8%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_008.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_008.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_009.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_009.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_009.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_009.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.6%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_010.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_010.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.6%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_010.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_010.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_011.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_011.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66%;
                left: 29.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_011.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_011.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr4">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_012.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_012.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_012.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_012.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_013.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_013.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_013.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_013.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_014.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_014.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_014.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_014.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44.4%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_015.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_015.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.5%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_015.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_015.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 53.6%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_016.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_016.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 56.5%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_016.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_016.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_017.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_017.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_017.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_017.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72.4%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_018.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_018.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 75.1%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_018.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_018.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 81.4%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_019.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_019.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 84.4%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_019.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_019.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 90.4%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_020.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_020.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 93.4%;
                left: 43.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_020.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_1_020.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr5">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.2%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_021.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_021.P"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.4%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              <!-- {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_021.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_021.Ia"]
                  : "--"
              }} -->
              0
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_022.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_022.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_022.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_022.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_023.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_023.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_023.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_023.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 45.2%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_024.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_024.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47.8%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_024.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_024.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 54.7%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_025.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_025.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 57.4%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_025.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_025.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_026.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_026.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_026.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_026.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 72.4%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_027.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_027.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 75.1%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_027.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_027.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 81.4%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_028.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_028.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 84.4%;
                left: 57.5%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_028.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_028.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr6">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15.6%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_029.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_029.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.8%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_029.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_029.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25.4%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_030.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_030.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 28.4%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_030.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_030.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 35%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_031.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_031.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 38%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_031.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_031.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 45.2%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_032.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_032.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 48%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_032.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_032.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 54.8%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_033.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_033.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 57.8%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_033.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_033.Ia"]
                  : "--"
              }}
            </div>

            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 63.8%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_034.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_034.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 66.6%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_034.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_034.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 73%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_035.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_035.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 76%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_035.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_035.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 82%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_036.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_036.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 85%;
                left: 72.6%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_036.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_036.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr7">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37.8%;
                left: 77.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_037.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_037.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 40.8%;
                left: 77.8%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_037.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_037.Ia"]
                  : "--"
              }}
            </div>
          </div>
          <div class="arr8">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37.8%;
                left: 83.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_038.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_038.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 40.8%;
                left: 83.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_038.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_038.Ia"]
                  : "--"
              }}
            </div>
          </div>

          <div class="arr9">
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 15%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_039.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_039.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 18.2%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_039.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_039.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 25%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_040.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_040.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 27.8%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_040.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_040.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 34.6%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_041.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_041.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 37.6%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_041.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_041.Ia"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 44%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_042.P"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_042.P"]
                  : "--"
              }}
            </div>
            <div
              style="
                position: absolute;
                color: white;
                font-size: 14px;
                top: 47%;
                left: 96.1%;
                text-align: left;
                padding-left: 5px;
                width: 50px;
                height: 20px;
                line-height: 20px;
              "
            >
              {{
                t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_042.Ia"]
                  ? t34RealtimeData[tenantId + ".jkjd.Elec_DTU1_8_042.Ia"]
                  : "--"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // getMonitorRelative,
  getMonitorRealtime,
  setProject,
} from "@/request/apiLg";
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      t31RealtimeData: {},
      t32RealtimeData: {},
      t33RealtimeData: {},
      t34RealtimeData: {},
      t1RealtimeData: {},
      t1RelativeData: {},
      t2RealtimeData: {},
      t2RelativeData: {},
      t3RealtimeData: {},
      t3RelativeData: {},
      t4RealtimeData: {},
      t4RelativeData: {},
      mgmData: {},
      currentTabs: "T1",
      timer1: null,
      timer2: null,
      timer3: null,
      timer4: null,
      timer5: null,
      array1: [
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
      ],

      array2: ["0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00"],

      array3: [
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
      ],
      array4: [
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
      ],
      array5: [
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
      ],
      array6: [
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
        "0.00",
      ],
      dataTabs: [
        { name: "T1", code: "T1" },
        { name: "T2", code: "T2" },
        { name: "T3", code: "T3" },
        { name: "T4", code: "T4" },
      ],
      imgDirection: "left",
      tenantId: "",
      urlIrame:
        "http://johpcyvc.cloudpreview.cloud.techsel.net/#/outofLogin?loginInfo=dXNlcm5hbWU9bGluZ2dhbiZwYXNzd29yZD1saW5nZ2FuMTIz",
      //   url:
      //     "http://johpcyvc.cloudpreview.cloud.techsel.net/#/outofLogin?loginInfo=dXNlcm5hbWU9YWRtaW4mcGFzc3dvcmQ9MTIzNDU2",
    };
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
      currentProjectName: (state) => state.currentProjectName,
      projectArray: (state) => state.projectArray,
    }),
  },
  created() {
    this.tenantId = process.env.VUE_APP_JIMU_USER;
  },
  mounted() {
    // this.currentProjectId = JSON.parse(window.localStorage.getItem("PROJECTID"))
    // console.log('this.currentProjectId',this.currentProjectId)
    // let
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (JSON.parse(window.localStorage.getItem("PROJECTID")) === "zgds") {
          this.currentTabs = "T1";
          this.dataTabs = [
            { name: "T1", code: "T1" },
            { name: "T2", code: "T2" },
            { name: "T3", code: "T3" },
            { name: "T4", code: "T4" },
          ];
          if (res2.data.code === 0) {
            console.log(res2.data);
            // getMonitorRelative([
            //   this.tenantId+".zgds.Elec_DTU1_1_002.Ep_imp",
            //   this.tenantId+".zgds.Elec_DTU1_1_018.Ep_imp",
            // ]).then((res) => {
            //   // debugger
            //   this.t1RelativeData = res.data.data;
            // });
            let data = [
              this.tenantId + ".zgds.Elec_DTU1_1_002.Ep_imp",
              this.tenantId + ".zgds.Elec_DTU1_1_028.Ep_imp",
              this.tenantId + ".zgds.Elec_DTU1_1_002.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_002.Ib",
              this.tenantId + ".zgds.Elec_DTU1_1_002.Ic",
              this.tenantId + ".zgds.Elec_DTU1_1_002.Uab",
              this.tenantId + ".zgds.Elec_DTU1_1_002.Ubc",
              this.tenantId + ".zgds.Elec_DTU1_1_002.Uca",
              this.tenantId + ".zgds.Elec_DTU1_1_002.P",
              this.tenantId + ".zgds.Elec_DTU1_1_002.Q",
              this.tenantId + ".zgds.Elec_DTU1_1_002.PF",
              this.tenantId + ".zgds.Elec_DTU1_1_003.P",
              this.tenantId + ".zgds.Elec_DTU1_1_003.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_004.P",
              this.tenantId + ".zgds.Elec_DTU1_1_004.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_005.P",
              this.tenantId + ".zgds.Elec_DTU1_1_005.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_006.P",
              this.tenantId + ".zgds.Elec_DTU1_1_006.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_007.P",
              this.tenantId + ".zgds.Elec_DTU1_1_007.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_008.P",
              this.tenantId + ".zgds.Elec_DTU1_1_008.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_009.P",
              this.tenantId + ".zgds.Elec_DTU1_1_009.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_010.P",
              this.tenantId + ".zgds.Elec_DTU1_1_010.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_011.P",
              this.tenantId + ".zgds.Elec_DTU1_1_011.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_012.P",
              this.tenantId + ".zgds.Elec_DTU1_1_012.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_013.P",
              this.tenantId + ".zgds.Elec_DTU1_1_013.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_014.P",
              this.tenantId + ".zgds.Elec_DTU1_1_014.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_015.P",
              this.tenantId + ".zgds.Elec_DTU1_1_015.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_016.P",
              this.tenantId + ".zgds.Elec_DTU1_1_016.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_017.P",
              this.tenantId + ".zgds.Elec_DTU1_1_017.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_018.P",
              this.tenantId + ".zgds.Elec_DTU1_1_018.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_019.P",
              this.tenantId + ".zgds.Elec_DTU1_1_019.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_020.P",
              this.tenantId + ".zgds.Elec_DTU1_1_020.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_021.P",
              this.tenantId + ".zgds.Elec_DTU1_1_021.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_022.P",
              this.tenantId + ".zgds.Elec_DTU1_1_022.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_023.P",
              this.tenantId + ".zgds.Elec_DTU1_1_023.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_024.P",
              this.tenantId + ".zgds.Elec_DTU1_1_024.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_025.P",
              this.tenantId + ".zgds.Elec_DTU1_1_025.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_026.P",
              this.tenantId + ".zgds.Elec_DTU1_1_026.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_027.P",
              this.tenantId + ".zgds.Elec_DTU1_1_027.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_028.Ia",
              this.tenantId + ".zgds.Elec_DTU1_1_028.Ib",
              this.tenantId + ".zgds.Elec_DTU1_1_028.Ic",
              this.tenantId + ".zgds.Elec_DTU1_1_028.Uab",
              this.tenantId + ".zgds.Elec_DTU1_1_028.Ubc",
              this.tenantId + ".zgds.Elec_DTU1_1_028.Uca",
              this.tenantId + ".zgds.Elec_DTU1_1_028.P",
              this.tenantId + ".zgds.Elec_DTU1_1_028.Q",
              this.tenantId + ".zgds.Elec_DTU1_1_028.PF",
            ];
            getMonitorRealtime(data).then((res) => {
              this.t1RealtimeData = res.data.data;
            });

            this.timer1 = setInterval(() => {
              // getMonitorRelative([
              //   this.tenantId+".zgds.Elec_DTU1_1_002.Ep_imp",
              //   this.tenantId+".zgds.Elec_DTU1_1_018.Ep_imp",
              // ]).then((res) => {
              //   if(res.data.code===0){

              //     this.t1RelativeData = res.data.data;
              //   }
              //   // else if(){

              //   // }
              // });
              let data = [
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ep_imp",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ep_imp",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ib",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ic",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Uab",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ubc",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Uca",
                this.tenantId + ".zgds.Elec_DTU1_1_002.P",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Q",
                this.tenantId + ".zgds.Elec_DTU1_1_002.PF",
                this.tenantId + ".zgds.Elec_DTU1_1_003.P",
                this.tenantId + ".zgds.Elec_DTU1_1_003.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_004.P",
                this.tenantId + ".zgds.Elec_DTU1_1_004.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_005.P",
                this.tenantId + ".zgds.Elec_DTU1_1_005.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_006.P",
                this.tenantId + ".zgds.Elec_DTU1_1_006.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_007.P",
                this.tenantId + ".zgds.Elec_DTU1_1_007.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_008.P",
                this.tenantId + ".zgds.Elec_DTU1_1_008.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_009.P",
                this.tenantId + ".zgds.Elec_DTU1_1_009.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_010.P",
                this.tenantId + ".zgds.Elec_DTU1_1_010.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_011.P",
                this.tenantId + ".zgds.Elec_DTU1_1_011.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_012.P",
                this.tenantId + ".zgds.Elec_DTU1_1_012.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_013.P",
                this.tenantId + ".zgds.Elec_DTU1_1_013.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_014.P",
                this.tenantId + ".zgds.Elec_DTU1_1_014.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_015.P",
                this.tenantId + ".zgds.Elec_DTU1_1_015.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_016.P",
                this.tenantId + ".zgds.Elec_DTU1_1_016.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_017.P",
                this.tenantId + ".zgds.Elec_DTU1_1_017.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_018.P",
                this.tenantId + ".zgds.Elec_DTU1_1_018.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_019.P",
                this.tenantId + ".zgds.Elec_DTU1_1_019.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_020.P",
                this.tenantId + ".zgds.Elec_DTU1_1_020.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_021.P",
                this.tenantId + ".zgds.Elec_DTU1_1_021.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_022.P",
                this.tenantId + ".zgds.Elec_DTU1_1_022.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_023.P",
                this.tenantId + ".zgds.Elec_DTU1_1_023.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_024.P",
                this.tenantId + ".zgds.Elec_DTU1_1_024.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_025.P",
                this.tenantId + ".zgds.Elec_DTU1_1_025.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_026.P",
                this.tenantId + ".zgds.Elec_DTU1_1_026.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_027.P",
                this.tenantId + ".zgds.Elec_DTU1_1_027.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ib",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ic",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Uab",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ubc",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Uca",
                this.tenantId + ".zgds.Elec_DTU1_1_028.P",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Q",
                this.tenantId + ".zgds.Elec_DTU1_1_028.PF",
              ];
              getMonitorRealtime(data).then((res) => {
                this.t1RealtimeData = res.data.data;
              });
            }, 60 * 1000);
          }
        } else if (this.currentProjectId === "tcsd") {
          this.currentTabs = "高变";
          this.dataTabs = [
            { name: "高变", code: "高变" },
            { name: "T1", code: "T1" },
            { name: "T2", code: "T2" },
            { name: "T3", code: "T3" },
            { name: "T4", code: "T4" },
            { name: "T5", code: "T5" },
            { name: "T6", code: "T6" },
          ];
        } else if (this.currentProjectId === "mgm") {
          if (res2.data.code === 0) {
            console.log(res2.data);
            let data = [
              this.tenantId + ".mgm.HVAC.ColdTotalFlow",
              this.tenantId + ".mgm.HVAC.HotTotalFlow",
              this.tenantId + ".mgm.HP.ColdTotalFlow",
              this.tenantId + ".mgm.CH.ColdTotalFlow",
            ];
            getMonitorRealtime(data).then((res) => {
              console.log("mgm", res);
              this.mgmData = res.data.data;
            });

            this.timer1 = setInterval(() => {
              let data = [
                this.tenantId + ".mgm.HVAC.ColdTotalFlow",
                this.tenantId + ".mgm.HVAC.HotTotalFlow",
                this.tenantId + ".mgm.HP.ColdTotalFlow",
                this.tenantId + ".mgm.CH.ColdTotalFlow",
              ];
              getMonitorRealtime(data).then((res) => {
                this.mgmData = res.data.data;
              });
            }, 60 * 1000);
          }
        } else if (this.currentProjectId === "jkjd") {
          this.currentTabs = "T1";
          this.dataTabs = [
            { name: "T1", code: "T1" },
            { name: "T2", code: "T2" },
            { name: "T3", code: "T3" },
            { name: "T4", code: "T4" },
          ];
          if (res2.data.code === 0) {
            console.log(res2.data);
            let data = [
              this.tenantId + ".jkjd.Elec_DTU2_1_027.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_027.Ib",
              this.tenantId + ".jkjd.Elec_DTU2_1_027.Ic",
              this.tenantId + ".jkjd.Elec_DTU2_1_027.Uab",
              this.tenantId + ".jkjd.Elec_DTU2_1_027.Ubc",
              this.tenantId + ".jkjd.Elec_DTU2_1_027.Uca",
              this.tenantId + ".jkjd.Elec_DTU2_1_027.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_027.Q",
              this.tenantId + ".jkjd.Elec_DTU2_1_027.PF",
              this.tenantId + ".jkjd.Elec_DTU2_1_027.Ep_imp",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.Ib",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.Ic",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.Uab",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.Ubc",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.Uca",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.Q",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.PF",
              this.tenantId + ".jkjd.Elec_DTU1_4_101.Ep_imp",
              this.tenantId + ".jkjd.Elec_DTU1_4_002.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_002.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_003.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_003.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_004.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_004.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_005.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_005.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_006.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_006.Ia",

              this.tenantId + ".jkjd.Elec_DTU1_4_007.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_007.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_008.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_008.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_009.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_009.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_010.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_010.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_011.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_011.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_012.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_012.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_013.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_013.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_014.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_014.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_015.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_015.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_016.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_016.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_017.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_017.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_018.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_018.Ia",
              this.tenantId + ".jkjd.Elec_DTU1_4_019.P",
              this.tenantId + ".jkjd.Elec_DTU1_4_019.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_020.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_020.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_021.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_021.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_022.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_022.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_023.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_023.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_024.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_024.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_025.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_025.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_026.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_026.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_028.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_028.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_029.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_029.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_030.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_030.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_031.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_031.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_032.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_032.Ia",
              this.tenantId + ".jkjd.Elec_DTU2_1_033.P",
              this.tenantId + ".jkjd.Elec_DTU2_1_033.Ia",
            ];
            getMonitorRealtime(data).then((res) => {
              this.t31RealtimeData = res.data.data;
            });

            this.timer1 = setInterval(() => {
              let data = [
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ib",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ic",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Uab",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ubc",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Uca",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Q",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.PF",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ep_imp",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ib",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ic",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Uab",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ubc",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Uca",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Q",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.PF",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ep_imp",
                this.tenantId + ".jkjd.Elec_DTU1_4_002.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_002.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_003.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_003.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_004.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_004.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_005.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_005.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_006.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_006.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_007.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_007.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_008.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_008.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_009.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_009.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_010.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_010.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_011.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_011.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_012.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_012.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_013.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_013.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_014.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_014.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_015.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_015.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_016.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_016.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_017.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_017.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_018.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_018.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_019.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_019.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_020.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_020.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_021.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_021.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_022.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_022.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_023.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_023.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_024.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_024.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_025.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_025.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_026.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_026.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_028.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_028.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_029.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_029.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_030.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_030.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_031.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_031.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_032.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_032.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_033.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_033.Ia",
              ];
              getMonitorRealtime(data).then((res) => {
                this.t31RealtimeData = res.data.data;
              });
            }, 60 * 1000);
          }
        }
      }
    );
  },
  methods: {
    toRight() {
      this.imgDirection = "right";
    },
    toLeft() {
      this.imgDirection = "left";
    },
    clickTabs(item) {
      this.currentTabs = item.code;
      clearInterval(this.timer1); // 清除定时器
      this.timer1 = null;
      clearInterval(this.timer2); // 清除定时器
      this.timer2 = null;
      clearInterval(this.timer3); // 清除定时器
      this.timer3 = null;
      clearInterval(this.timer4); // 清除定时器
      this.timer4 = null;
      clearInterval(this.timer5); // 清除定时器
      this.timer5 = null;
      if (this.currentTabs === "T1" && this.currentProjectId === "zgds") {
        // getMonitorRelative([
        //   this.tenantId+".zgds.Elec_DTU1_1_002.Ep_imp",
        //   this.tenantId+".zgds.Elec_DTU1_1_018.Ep_imp",
        // ]).then((res) => {
        //   this.t1RelativeData = res.data.data;
        // });
        let data = [
          this.tenantId + ".zgds.Elec_DTU1_1_002.Ep_imp",
          this.tenantId + ".zgds.Elec_DTU1_1_018.Ep_imp",
          this.tenantId + ".zgds.Elec_DTU1_1_002.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_002.Ib",
          this.tenantId + ".zgds.Elec_DTU1_1_002.Ic",
          this.tenantId + ".zgds.Elec_DTU1_1_002.Uab",
          this.tenantId + ".zgds.Elec_DTU1_1_002.Ubc",
          this.tenantId + ".zgds.Elec_DTU1_1_002.Uca",
          this.tenantId + ".zgds.Elec_DTU1_1_002.P",
          this.tenantId + ".zgds.Elec_DTU1_1_002.Q",
          this.tenantId + ".zgds.Elec_DTU1_1_002.PF",
          this.tenantId + ".zgds.Elec_DTU1_1_003.P",
          this.tenantId + ".zgds.Elec_DTU1_1_003.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_004.P",
          this.tenantId + ".zgds.Elec_DTU1_1_004.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_005.P",
          this.tenantId + ".zgds.Elec_DTU1_1_005.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_006.P",
          this.tenantId + ".zgds.Elec_DTU1_1_006.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_007.P",
          this.tenantId + ".zgds.Elec_DTU1_1_007.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_008.P",
          this.tenantId + ".zgds.Elec_DTU1_1_008.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_009.P",
          this.tenantId + ".zgds.Elec_DTU1_1_009.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_010.P",
          this.tenantId + ".zgds.Elec_DTU1_1_010.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_011.P",
          this.tenantId + ".zgds.Elec_DTU1_1_011.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_012.P",
          this.tenantId + ".zgds.Elec_DTU1_1_012.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_013.P",
          this.tenantId + ".zgds.Elec_DTU1_1_013.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_014.P",
          this.tenantId + ".zgds.Elec_DTU1_1_014.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_015.P",
          this.tenantId + ".zgds.Elec_DTU1_1_015.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_016.P",
          this.tenantId + ".zgds.Elec_DTU1_1_016.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_017.P",
          this.tenantId + ".zgds.Elec_DTU1_1_017.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_018.P",
          this.tenantId + ".zgds.Elec_DTU1_1_018.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_019.P",
          this.tenantId + ".zgds.Elec_DTU1_1_019.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_020.P",
          this.tenantId + ".zgds.Elec_DTU1_1_020.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_021.P",
          this.tenantId + ".zgds.Elec_DTU1_1_021.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_022.P",
          this.tenantId + ".zgds.Elec_DTU1_1_022.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_023.P",
          this.tenantId + ".zgds.Elec_DTU1_1_023.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_024.P",
          this.tenantId + ".zgds.Elec_DTU1_1_024.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_025.P",
          this.tenantId + ".zgds.Elec_DTU1_1_025.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_026.P",
          this.tenantId + ".zgds.Elec_DTU1_1_026.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_027.P",
          this.tenantId + ".zgds.Elec_DTU1_1_027.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_028.Ia",
          this.tenantId + ".zgds.Elec_DTU1_1_028.Ib",
          this.tenantId + ".zgds.Elec_DTU1_1_028.Ic",
          this.tenantId + ".zgds.Elec_DTU1_1_028.Uab",
          this.tenantId + ".zgds.Elec_DTU1_1_028.Ubc",
          this.tenantId + ".zgds.Elec_DTU1_1_028.Uca",
          this.tenantId + ".zgds.Elec_DTU1_1_028.P",
          this.tenantId + ".zgds.Elec_DTU1_1_028.Q",
          this.tenantId + ".zgds.Elec_DTU1_1_028.PF",
        ];
        getMonitorRealtime(data).then((res) => {
          this.t1RealtimeData = res.data.data;
        });

        this.timer1 = setInterval(() => {
          // getMonitorRelative([
          //   this.tenantId+".zgds.Elec_DTU1_1_002.Ep_imp",
          //   this.tenantId+".zgds.Elec_DTU1_1_018.Ep_imp",
          // ]).then((res) => {
          //   this.t1RelativeData = res.data.data;
          // });
          let data = [
            this.tenantId + ".zgds.Elec_DTU1_1_002.Ep_imp",
            this.tenantId + ".zgds.Elec_DTU1_1_028.Ep_imp",
            this.tenantId + ".zgds.Elec_DTU1_1_002.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_002.Ib",
            this.tenantId + ".zgds.Elec_DTU1_1_002.Ic",
            this.tenantId + ".zgds.Elec_DTU1_1_002.Uab",
            this.tenantId + ".zgds.Elec_DTU1_1_002.Ubc",
            this.tenantId + ".zgds.Elec_DTU1_1_002.Uca",
            this.tenantId + ".zgds.Elec_DTU1_1_002.P",
            this.tenantId + ".zgds.Elec_DTU1_1_002.Q",
            this.tenantId + ".zgds.Elec_DTU1_1_002.PF",
            this.tenantId + ".zgds.Elec_DTU1_1_003.P",
            this.tenantId + ".zgds.Elec_DTU1_1_003.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_004.P",
            this.tenantId + ".zgds.Elec_DTU1_1_004.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_005.P",
            this.tenantId + ".zgds.Elec_DTU1_1_005.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_006.P",
            this.tenantId + ".zgds.Elec_DTU1_1_006.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_007.P",
            this.tenantId + ".zgds.Elec_DTU1_1_007.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_008.P",
            this.tenantId + ".zgds.Elec_DTU1_1_008.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_009.P",
            this.tenantId + ".zgds.Elec_DTU1_1_009.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_010.P",
            this.tenantId + ".zgds.Elec_DTU1_1_010.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_011.P",
            this.tenantId + ".zgds.Elec_DTU1_1_011.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_012.P",
            this.tenantId + ".zgds.Elec_DTU1_1_012.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_013.P",
            this.tenantId + ".zgds.Elec_DTU1_1_013.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_014.P",
            this.tenantId + ".zgds.Elec_DTU1_1_014.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_015.P",
            this.tenantId + ".zgds.Elec_DTU1_1_015.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_016.P",
            this.tenantId + ".zgds.Elec_DTU1_1_016.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_017.P",
            this.tenantId + ".zgds.Elec_DTU1_1_017.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_018.P",
            this.tenantId + ".zgds.Elec_DTU1_1_018.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_019.P",
            this.tenantId + ".zgds.Elec_DTU1_1_019.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_020.P",
            this.tenantId + ".zgds.Elec_DTU1_1_020.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_021.P",
            this.tenantId + ".zgds.Elec_DTU1_1_021.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_022.P",
            this.tenantId + ".zgds.Elec_DTU1_1_022.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_023.P",
            this.tenantId + ".zgds.Elec_DTU1_1_023.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_024.P",
            this.tenantId + ".zgds.Elec_DTU1_1_024.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_025.P",
            this.tenantId + ".zgds.Elec_DTU1_1_025.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_026.P",
            this.tenantId + ".zgds.Elec_DTU1_1_026.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_027.P",
            this.tenantId + ".zgds.Elec_DTU1_1_027.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_028.Ia",
            this.tenantId + ".zgds.Elec_DTU1_1_028.Ib",
            this.tenantId + ".zgds.Elec_DTU1_1_028.Ic",
            this.tenantId + ".zgds.Elec_DTU1_1_028.Uab",
            this.tenantId + ".zgds.Elec_DTU1_1_028.Ubc",
            this.tenantId + ".zgds.Elec_DTU1_1_028.Uca",
            this.tenantId + ".zgds.Elec_DTU1_1_028.P",
            this.tenantId + ".zgds.Elec_DTU1_1_028.Q",
            this.tenantId + ".zgds.Elec_DTU1_1_028.PF",
          ];
          getMonitorRealtime(data).then((res) => {
            this.t1RealtimeData = res.data.data;
          });
        }, 60 * 1000);
      }
      if (this.currentTabs === "T2" && this.currentProjectId === "zgds") {
        // getMonitorRelative([tenantId+".zgds.Elec_DTU1_2_029.Ep_imp"]).then((res) => {
        //   this.t2RelativeData = res.data.data;
        // });
        let data = [
          this.tenantId + ".zgds.Elec_DTU1_2_029.Ep_imp",
          this.tenantId + ".zgds.Elec_DTU1_2_029.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_029.Ib",
          this.tenantId + ".zgds.Elec_DTU1_2_029.Ic",
          this.tenantId + ".zgds.Elec_DTU1_2_029.Uab",
          this.tenantId + ".zgds.Elec_DTU1_2_029.Ubc",
          this.tenantId + ".zgds.Elec_DTU1_2_029.Uca",
          this.tenantId + ".zgds.Elec_DTU1_2_029.P",
          this.tenantId + ".zgds.Elec_DTU1_2_029.Q",
          this.tenantId + ".zgds.Elec_DTU1_2_029.PF",
          this.tenantId + ".zgds.Elec_DTU1_2_030.P",
          this.tenantId + ".zgds.Elec_DTU1_2_030.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_031.P",
          this.tenantId + ".zgds.Elec_DTU1_2_031.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_032.P",
          this.tenantId + ".zgds.Elec_DTU1_2_032.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_033.P",
          this.tenantId + ".zgds.Elec_DTU1_2_033.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_034.P",
          this.tenantId + ".zgds.Elec_DTU1_2_034.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_035.P",
          this.tenantId + ".zgds.Elec_DTU1_2_035.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_036.P",
          this.tenantId + ".zgds.Elec_DTU1_2_036.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_037.P",
          this.tenantId + ".zgds.Elec_DTU1_2_037.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_038.P",
          this.tenantId + ".zgds.Elec_DTU1_2_038.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_039.P",
          this.tenantId + ".zgds.Elec_DTU1_2_039.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_040.P",
          this.tenantId + ".zgds.Elec_DTU1_2_040.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_041.P",
          this.tenantId + ".zgds.Elec_DTU1_2_041.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_042.P",
          this.tenantId + ".zgds.Elec_DTU1_2_042.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_043.P",
          this.tenantId + ".zgds.Elec_DTU1_2_043.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_044.P",
          this.tenantId + ".zgds.Elec_DTU1_2_044.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_045.P",
          this.tenantId + ".zgds.Elec_DTU1_2_045.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_046.P",
          this.tenantId + ".zgds.Elec_DTU1_2_046.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_047.P",
          this.tenantId + ".zgds.Elec_DTU1_2_047.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_048.P",
          this.tenantId + ".zgds.Elec_DTU1_2_048.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_049.P",
          this.tenantId + ".zgds.Elec_DTU1_2_049.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_050.P",
          this.tenantId + ".zgds.Elec_DTU1_2_050.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_051.P",
          this.tenantId + ".zgds.Elec_DTU1_2_051.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_052.P",
          this.tenantId + ".zgds.Elec_DTU1_2_052.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_053.P",
          this.tenantId + ".zgds.Elec_DTU1_2_053.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_054.P",
          this.tenantId + ".zgds.Elec_DTU1_2_054.Ia",
          this.tenantId + ".zgds.Elec_DTU1_2_055.P",
          this.tenantId + ".zgds.Elec_DTU1_2_055.Ia",
        ];
        getMonitorRealtime(data).then((res) => {
          this.t2RealtimeData = res.data.data;
        });

        this.timer2 = setInterval(() => {
          // getMonitorRelative([tenantId+".zgds.Elec_DTU1_2_029.Ep_imp"]).then(
          //   (res) => {
          //     this.t2RelativeData = res.data.data;
          //   }
          // );
          let data = [
            this.tenantId + ".zgds.Elec_DTU1_2_029.Ep_imp",
            this.tenantId + ".zgds.Elec_DTU1_2_029.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_029.Ib",
            this.tenantId + ".zgds.Elec_DTU1_2_029.Ic",
            this.tenantId + ".zgds.Elec_DTU1_2_029.Uab",
            this.tenantId + ".zgds.Elec_DTU1_2_029.Ubc",
            this.tenantId + ".zgds.Elec_DTU1_2_029.Uca",
            this.tenantId + ".zgds.Elec_DTU1_2_029.P",
            this.tenantId + ".zgds.Elec_DTU1_2_029.Q",
            this.tenantId + ".zgds.Elec_DTU1_2_029.PF",
            this.tenantId + ".zgds.Elec_DTU1_2_030.P",
            this.tenantId + ".zgds.Elec_DTU1_2_030.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_031.P",
            this.tenantId + ".zgds.Elec_DTU1_2_031.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_032.P",
            this.tenantId + ".zgds.Elec_DTU1_2_032.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_033.P",
            this.tenantId + ".zgds.Elec_DTU1_2_033.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_034.P",
            this.tenantId + ".zgds.Elec_DTU1_2_034.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_035.P",
            this.tenantId + ".zgds.Elec_DTU1_2_035.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_036.P",
            this.tenantId + ".zgds.Elec_DTU1_2_036.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_037.P",
            this.tenantId + ".zgds.Elec_DTU1_2_037.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_038.P",
            this.tenantId + ".zgds.Elec_DTU1_2_038.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_039.P",
            this.tenantId + ".zgds.Elec_DTU1_2_039.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_040.P",
            this.tenantId + ".zgds.Elec_DTU1_2_040.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_041.P",
            this.tenantId + ".zgds.Elec_DTU1_2_041.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_042.P",
            this.tenantId + ".zgds.Elec_DTU1_2_042.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_043.P",
            this.tenantId + ".zgds.Elec_DTU1_2_043.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_044.P",
            this.tenantId + ".zgds.Elec_DTU1_2_044.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_045.P",
            this.tenantId + ".zgds.Elec_DTU1_2_045.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_046.P",
            this.tenantId + ".zgds.Elec_DTU1_2_046.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_047.P",
            this.tenantId + ".zgds.Elec_DTU1_2_047.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_048.P",
            this.tenantId + ".zgds.Elec_DTU1_2_048.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_049.P",
            this.tenantId + ".zgds.Elec_DTU1_2_049.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_050.P",
            this.tenantId + ".zgds.Elec_DTU1_2_050.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_051.P",
            this.tenantId + ".zgds.Elec_DTU1_2_051.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_052.P",
            this.tenantId + ".zgds.Elec_DTU1_2_052.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_053.P",
            this.tenantId + ".zgds.Elec_DTU1_2_053.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_054.P",
            this.tenantId + ".zgds.Elec_DTU1_2_054.Ia",
            this.tenantId + ".zgds.Elec_DTU1_2_055.P",
            this.tenantId + ".zgds.Elec_DTU1_2_055.Ia",
          ];
          getMonitorRealtime(data).then((res) => {
            this.t2RealtimeData = res.data.data;
          });
        }, 60 * 1000);
      }
      if (this.currentTabs === "T3" && this.currentProjectId === "zgds") {
        // getMonitorRelative([
        //   this.tenantId+".zgds.Elec_DTU1_3_056.Ep_imp",
        //   this.tenantId+".zgds.Elec_DTU1_3_084.Ep_imp",
        // ]).then((res) => {
        //   this.t3RelativeData = res.data.data;
        // });
        let data = [
          this.tenantId + ".zgds.Elec_DTU1_3_056.Ep_imp",
          this.tenantId + ".zgds.Elec_DTU1_3_084.Ep_imp",
          this.tenantId + ".zgds.Elec_DTU1_3_056.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_056.Ib",
          this.tenantId + ".zgds.Elec_DTU1_3_056.Ic",
          this.tenantId + ".zgds.Elec_DTU1_3_056.Uab",
          this.tenantId + ".zgds.Elec_DTU1_3_056.Ubc",
          this.tenantId + ".zgds.Elec_DTU1_3_056.Uca",
          this.tenantId + ".zgds.Elec_DTU1_3_056.P",
          this.tenantId + ".zgds.Elec_DTU1_3_056.Q",
          this.tenantId + ".zgds.Elec_DTU1_3_056.PF",
          this.tenantId + ".zgds.Elec_DTU1_3_057.P",
          this.tenantId + ".zgds.Elec_DTU1_3_057.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_058.P",
          this.tenantId + ".zgds.Elec_DTU1_3_058.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_059.P",
          this.tenantId + ".zgds.Elec_DTU1_3_059.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_060.P",
          this.tenantId + ".zgds.Elec_DTU1_3_060.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_061.P",
          this.tenantId + ".zgds.Elec_DTU1_3_061.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_062.P",
          this.tenantId + ".zgds.Elec_DTU1_3_062.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_063.P",
          this.tenantId + ".zgds.Elec_DTU1_3_063.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_064.P",
          this.tenantId + ".zgds.Elec_DTU1_3_064.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_065.P",
          this.tenantId + ".zgds.Elec_DTU1_3_065.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_066.P",
          this.tenantId + ".zgds.Elec_DTU1_3_066.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_067.P",
          this.tenantId + ".zgds.Elec_DTU1_3_067.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_068.P",
          this.tenantId + ".zgds.Elec_DTU1_3_068.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_069.P",
          this.tenantId + ".zgds.Elec_DTU1_3_069.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_070.P",
          this.tenantId + ".zgds.Elec_DTU1_3_070.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_071.P",
          this.tenantId + ".zgds.Elec_DTU1_3_071.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_072.P",
          this.tenantId + ".zgds.Elec_DTU1_3_072.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_073.P",
          this.tenantId + ".zgds.Elec_DTU1_3_073.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_074.P",
          this.tenantId + ".zgds.Elec_DTU1_3_074.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_075.P",
          this.tenantId + ".zgds.Elec_DTU1_3_075.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_076.P",
          this.tenantId + ".zgds.Elec_DTU1_3_076.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_077.P",
          this.tenantId + ".zgds.Elec_DTU1_3_077.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_078.P",
          this.tenantId + ".zgds.Elec_DTU1_3_078.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_079.P",
          this.tenantId + ".zgds.Elec_DTU1_3_079.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_080.P",
          this.tenantId + ".zgds.Elec_DTU1_3_080.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_081.P",
          this.tenantId + ".zgds.Elec_DTU1_3_081.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_082.P",
          this.tenantId + ".zgds.Elec_DTU1_3_082.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_083.P",
          this.tenantId + ".zgds.Elec_DTU1_3_083.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_084.Ia",
          this.tenantId + ".zgds.Elec_DTU1_3_084.Ib",
          this.tenantId + ".zgds.Elec_DTU1_3_084.Ic",
          this.tenantId + ".zgds.Elec_DTU1_3_084.Uab",
          this.tenantId + ".zgds.Elec_DTU1_3_084.Ubc",
          this.tenantId + ".zgds.Elec_DTU1_3_084.Uca",
          this.tenantId + ".zgds.Elec_DTU1_3_084.P",
          this.tenantId + ".zgds.Elec_DTU1_3_084.Q",
          this.tenantId + ".zgds.Elec_DTU1_3_084.PF",
        ];
        getMonitorRealtime(data).then((res) => {
          this.t3RealtimeData = res.data.data;
        });

        this.timer3 = setInterval(() => {
          // getMonitorRelative([
          //   this.tenantId+".zgds.Elec_DTU1_3_056.Ep_imp",
          //   this.tenantId+".zgds.Elec_DTU1_3_084.Ep_imp",
          // ]).then((res) => {
          //   this.t3RelativeData = res.data.data;
          // });
          let data = [
            this.tenantId + ".zgds.Elec_DTU1_3_056.Ep_imp",
            this.tenantId + ".zgds.Elec_DTU1_3_084.Ep_imp",
            this.tenantId + ".zgds.Elec_DTU1_3_056.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_056.Ib",
            this.tenantId + ".zgds.Elec_DTU1_3_056.Ic",
            this.tenantId + ".zgds.Elec_DTU1_3_056.Uab",
            this.tenantId + ".zgds.Elec_DTU1_3_056.Ubc",
            this.tenantId + ".zgds.Elec_DTU1_3_056.Uca",
            this.tenantId + ".zgds.Elec_DTU1_3_056.P",
            this.tenantId + ".zgds.Elec_DTU1_3_056.Q",
            this.tenantId + ".zgds.Elec_DTU1_3_056.PF",
            this.tenantId + ".zgds.Elec_DTU1_3_057.P",
            this.tenantId + ".zgds.Elec_DTU1_3_057.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_058.P",
            this.tenantId + ".zgds.Elec_DTU1_3_058.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_059.P",
            this.tenantId + ".zgds.Elec_DTU1_3_059.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_060.P",
            this.tenantId + ".zgds.Elec_DTU1_3_060.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_061.P",
            this.tenantId + ".zgds.Elec_DTU1_3_061.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_062.P",
            this.tenantId + ".zgds.Elec_DTU1_3_062.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_063.P",
            this.tenantId + ".zgds.Elec_DTU1_3_063.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_064.P",
            this.tenantId + ".zgds.Elec_DTU1_3_064.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_065.P",
            this.tenantId + ".zgds.Elec_DTU1_3_065.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_066.P",
            this.tenantId + ".zgds.Elec_DTU1_3_066.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_067.P",
            this.tenantId + ".zgds.Elec_DTU1_3_067.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_068.P",
            this.tenantId + ".zgds.Elec_DTU1_3_068.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_069.P",
            this.tenantId + ".zgds.Elec_DTU1_3_069.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_070.P",
            this.tenantId + ".zgds.Elec_DTU1_3_070.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_071.P",
            this.tenantId + ".zgds.Elec_DTU1_3_071.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_072.P",
            this.tenantId + ".zgds.Elec_DTU1_3_072.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_073.P",
            this.tenantId + ".zgds.Elec_DTU1_3_073.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_074.P",
            this.tenantId + ".zgds.Elec_DTU1_3_074.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_075.P",
            this.tenantId + ".zgds.Elec_DTU1_3_075.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_076.P",
            this.tenantId + ".zgds.Elec_DTU1_3_076.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_077.P",
            this.tenantId + ".zgds.Elec_DTU1_3_077.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_078.P",
            this.tenantId + ".zgds.Elec_DTU1_3_078.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_079.P",
            this.tenantId + ".zgds.Elec_DTU1_3_079.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_080.P",
            this.tenantId + ".zgds.Elec_DTU1_3_080.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_081.P",
            this.tenantId + ".zgds.Elec_DTU1_3_081.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_082.P",
            this.tenantId + ".zgds.Elec_DTU1_3_082.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_083.P",
            this.tenantId + ".zgds.Elec_DTU1_3_083.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_084.Ia",
            this.tenantId + ".zgds.Elec_DTU1_3_084.Ib",
            this.tenantId + ".zgds.Elec_DTU1_3_084.Ic",
            this.tenantId + ".zgds.Elec_DTU1_3_084.Uab",
            this.tenantId + ".zgds.Elec_DTU1_3_084.Ubc",
            this.tenantId + ".zgds.Elec_DTU1_3_084.Uca",
            this.tenantId + ".zgds.Elec_DTU1_3_084.P",
            this.tenantId + ".zgds.Elec_DTU1_3_084.Q",
            this.tenantId + ".zgds.Elec_DTU1_3_084.PF",
          ];
          getMonitorRealtime(data).then((res) => {
            this.t3RealtimeData = res.data.data;
          });
        }, 60 * 1000);
      }
      if (this.currentTabs === "T4" && this.currentProjectId === "zgds") {
        // getMonitorRelative([tenantId+".zgds.Elec_DTU2_1_085.Ep_imp"]).then((res) => {
        //   this.t4RelativeData = res.data.data;
        // });
        let data = [
          this.tenantId + ".zgds.Elec_DTU1_4_085.Ep_imp",
          this.tenantId + ".zgds.Elec_DTU1_4_085.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_085.Ib",
          this.tenantId + ".zgds.Elec_DTU1_4_085.Ic",
          this.tenantId + ".zgds.Elec_DTU1_4_085.Uab",
          this.tenantId + ".zgds.Elec_DTU1_4_085.Ubc",
          this.tenantId + ".zgds.Elec_DTU1_4_085.Uca",
          this.tenantId + ".zgds.Elec_DTU1_4_085.P",
          this.tenantId + ".zgds.Elec_DTU1_4_085.Q",
          this.tenantId + ".zgds.Elec_DTU1_4_085.PF",
          this.tenantId + ".zgds.Elec_DTU1_4_086.P",
          this.tenantId + ".zgds.Elec_DTU1_4_086.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_087.P",
          this.tenantId + ".zgds.Elec_DTU1_4_087.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_088.P",
          this.tenantId + ".zgds.Elec_DTU1_4_088.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_089.P",
          this.tenantId + ".zgds.Elec_DTU1_4_089.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_090.P",
          this.tenantId + ".zgds.Elec_DTU1_4_090.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_091.P",
          this.tenantId + ".zgds.Elec_DTU1_4_091.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_092.P",
          this.tenantId + ".zgds.Elec_DTU1_4_092.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_093.P",
          this.tenantId + ".zgds.Elec_DTU1_4_093.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_094.P",
          this.tenantId + ".zgds.Elec_DTU1_4_094.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_095.P",
          this.tenantId + ".zgds.Elec_DTU1_4_095.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_096.P",
          this.tenantId + ".zgds.Elec_DTU1_4_096.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_097.P",
          this.tenantId + ".zgds.Elec_DTU1_4_097.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_098.P",
          this.tenantId + ".zgds.Elec_DTU1_4_098.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_099.P",
          this.tenantId + ".zgds.Elec_DTU1_4_099.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_100.P",
          this.tenantId + ".zgds.Elec_DTU1_4_100.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_101.P",
          this.tenantId + ".zgds.Elec_DTU1_4_101.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_102.P",
          this.tenantId + ".zgds.Elec_DTU1_4_102.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_103.P",
          this.tenantId + ".zgds.Elec_DTU1_4_103.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_104.P",
          this.tenantId + ".zgds.Elec_DTU1_4_104.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_105.P",
          this.tenantId + ".zgds.Elec_DTU1_4_105.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_106.P",
          this.tenantId + ".zgds.Elec_DTU1_4_106.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_107.P",
          this.tenantId + ".zgds.Elec_DTU1_4_107.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_108.P",
          this.tenantId + ".zgds.Elec_DTU1_4_108.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_109.P",
          this.tenantId + ".zgds.Elec_DTU1_4_109.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_110.P",
          this.tenantId + ".zgds.Elec_DTU1_4_110.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_111.P",
          this.tenantId + ".zgds.Elec_DTU1_4_111.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_112.P",
          this.tenantId + ".zgds.Elec_DTU1_4_112.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_113.P",
          this.tenantId + ".zgds.Elec_DTU1_4_113.Ia",
          this.tenantId + ".zgds.Elec_DTU1_4_114.P",
          this.tenantId + ".zgds.Elec_DTU1_4_114.Ia",
        ];
        getMonitorRealtime(data).then((res) => {
          this.t4RealtimeData = res.data.data;
        });

        this.timer4 = setInterval(() => {
          // getMonitorRelative([tenantId+".zgds.Elec_DTU1_4_085.Ep_imp"]).then(
          //   (res) => {
          //     this.t4RelativeData = res.data.data;
          //   }
          // );
          let data = [
            this.tenantId + ".zgds.Elec_DTU1_4_085.Ep_imp",
            this.tenantId + ".zgds.Elec_DTU1_4_085.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_085.Ib",
            this.tenantId + ".zgds.Elec_DTU1_4_085.Ic",
            this.tenantId + ".zgds.Elec_DTU1_4_085.Uab",
            this.tenantId + ".zgds.Elec_DTU1_4_085.Ubc",
            this.tenantId + ".zgds.Elec_DTU1_4_085.Uca",
            this.tenantId + ".zgds.Elec_DTU1_4_085.P",
            this.tenantId + ".zgds.Elec_DTU1_4_085.Q",
            this.tenantId + ".zgds.Elec_DTU1_4_085.PF",
            this.tenantId + ".zgds.Elec_DTU1_4_086.P",
            this.tenantId + ".zgds.Elec_DTU1_4_086.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_087.P",
            this.tenantId + ".zgds.Elec_DTU1_4_087.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_088.P",
            this.tenantId + ".zgds.Elec_DTU1_4_088.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_089.P",
            this.tenantId + ".zgds.Elec_DTU1_4_089.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_090.P",
            this.tenantId + ".zgds.Elec_DTU1_4_090.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_091.P",
            this.tenantId + ".zgds.Elec_DTU1_4_091.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_092.P",
            this.tenantId + ".zgds.Elec_DTU1_4_092.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_093.P",
            this.tenantId + ".zgds.Elec_DTU1_4_093.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_094.P",
            this.tenantId + ".zgds.Elec_DTU1_4_094.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_095.P",
            this.tenantId + ".zgds.Elec_DTU1_4_095.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_096.P",
            this.tenantId + ".zgds.Elec_DTU1_4_096.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_097.P",
            this.tenantId + ".zgds.Elec_DTU1_4_097.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_098.P",
            this.tenantId + ".zgds.Elec_DTU1_4_098.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_099.P",
            this.tenantId + ".zgds.Elec_DTU1_4_099.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_100.P",
            this.tenantId + ".zgds.Elec_DTU1_4_100.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_101.P",
            this.tenantId + ".zgds.Elec_DTU1_4_101.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_102.P",
            this.tenantId + ".zgds.Elec_DTU1_4_102.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_103.P",
            this.tenantId + ".zgds.Elec_DTU1_4_103.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_104.P",
            this.tenantId + ".zgds.Elec_DTU1_4_104.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_105.P",
            this.tenantId + ".zgds.Elec_DTU1_4_105.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_106.P",
            this.tenantId + ".zgds.Elec_DTU1_4_106.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_107.P",
            this.tenantId + ".zgds.Elec_DTU1_4_107.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_108.P",
            this.tenantId + ".zgds.Elec_DTU1_4_108.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_109.P",
            this.tenantId + ".zgds.Elec_DTU1_4_109.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_110.P",
            this.tenantId + ".zgds.Elec_DTU1_4_110.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_111.P",
            this.tenantId + ".zgds.Elec_DTU1_4_111.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_112.P",
            this.tenantId + ".zgds.Elec_DTU1_4_112.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_113.P",
            this.tenantId + ".zgds.Elec_DTU1_4_113.Ia",
            this.tenantId + ".zgds.Elec_DTU1_4_114.P",
            this.tenantId + ".zgds.Elec_DTU1_4_114.Ia",
          ];
          getMonitorRealtime(data).then((res) => {
            this.t4RealtimeData = res.data.data;
          });
        }, 60 * 1000);
      }
      if (this.currentTabs === "T1" && this.currentProjectId === "jkjd") {
        let data = [
          this.tenantId + ".jkjd.Elec_DTU2_1_027.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_027.Ib",
          this.tenantId + ".jkjd.Elec_DTU2_1_027.Ic",
          this.tenantId + ".jkjd.Elec_DTU2_1_027.Uab",
          this.tenantId + ".jkjd.Elec_DTU2_1_027.Ubc",
          this.tenantId + ".jkjd.Elec_DTU2_1_027.Uca",
          this.tenantId + ".jkjd.Elec_DTU2_1_027.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_027.Q",
          this.tenantId + ".jkjd.Elec_DTU2_1_027.PF",
          this.tenantId + ".jkjd.Elec_DTU2_1_027.Ep_imp",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.Ib",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.Ic",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.Uab",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.Ubc",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.Uca",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.Q",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.PF",
          this.tenantId + ".jkjd.Elec_DTU1_4_101.Ep_imp",
          this.tenantId + ".jkjd.Elec_DTU1_4_002.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_002.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_003.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_003.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_004.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_004.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_005.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_005.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_006.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_006.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_4_007.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_007.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_008.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_008.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_009.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_009.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_010.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_010.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_011.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_011.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_012.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_012.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_013.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_013.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_014.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_014.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_015.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_015.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_016.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_016.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_017.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_017.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_018.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_018.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_019.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_019.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_020.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_020.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_021.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_021.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_022.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_022.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_023.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_023.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_024.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_024.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_025.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_025.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_026.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_026.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_028.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_028.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_029.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_029.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_030.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_030.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_031.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_031.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_032.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_032.Ia",
          this.tenantId + ".jkjd.Elec_DTU2_1_033.P",
          this.tenantId + ".jkjd.Elec_DTU2_1_033.Ia",
        ];
        getMonitorRealtime(data).then((res) => {
          this.t31RealtimeData = res.data.data;
        });

        this.timer1 = setInterval(() => {
          let data = [
            this.tenantId + ".jkjd.Elec_DTU2_1_027.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_027.Ib",
            this.tenantId + ".jkjd.Elec_DTU2_1_027.Ic",
            this.tenantId + ".jkjd.Elec_DTU2_1_027.Uab",
            this.tenantId + ".jkjd.Elec_DTU2_1_027.Ubc",
            this.tenantId + ".jkjd.Elec_DTU2_1_027.Uca",
            this.tenantId + ".jkjd.Elec_DTU2_1_027.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_027.Q",
            this.tenantId + ".jkjd.Elec_DTU2_1_027.PF",
            this.tenantId + ".jkjd.Elec_DTU2_1_027.Ep_imp",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.Ib",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.Ic",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.Uab",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.Ubc",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.Uca",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.Q",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.PF",
            this.tenantId + ".jkjd.Elec_DTU1_4_101.Ep_imp",
            this.tenantId + ".jkjd.Elec_DTU1_4_002.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_002.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_003.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_003.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_004.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_004.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_005.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_005.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_006.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_006.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_007.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_007.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_008.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_008.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_009.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_009.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_010.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_010.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_011.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_011.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_012.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_012.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_013.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_013.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_014.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_014.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_015.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_015.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_016.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_016.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_017.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_017.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_018.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_018.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_019.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_019.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_020.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_020.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_021.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_021.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_022.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_022.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_023.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_023.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_024.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_024.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_025.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_025.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_026.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_026.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_028.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_028.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_029.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_029.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_030.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_030.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_031.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_031.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_032.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_032.Ia",
            this.tenantId + ".jkjd.Elec_DTU2_1_033.P",
            this.tenantId + ".jkjd.Elec_DTU2_1_033.Ia",
          ];
          getMonitorRealtime(data).then((res) => {
            this.t31RealtimeData = res.data.data;
          });
        }, 60 * 1000);
      }
      if (this.currentTabs === "T2" && this.currentProjectId === "jkjd") {
        let data = [
          this.tenantId + ".jkjd.Elec_DTU1_5_102.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_102.Ib",
          this.tenantId + ".jkjd.Elec_DTU1_5_102.Ic",
          this.tenantId + ".jkjd.Elec_DTU1_5_102.Uab",
          this.tenantId + ".jkjd.Elec_DTU1_5_102.Ubc",
          this.tenantId + ".jkjd.Elec_DTU1_5_102.Uca",
          this.tenantId + ".jkjd.Elec_DTU1_5_102.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_102.Q",
          this.tenantId + ".jkjd.Elec_DTU1_5_102.PF",
          this.tenantId + ".jkjd.Elec_DTU1_5_102.Ep_imp",

          this.tenantId + ".jkjd.Elec_DTU1_5_002.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_002.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_003.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_003.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_004.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_004.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_005.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_005.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_006.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_006.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_5_007.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_007.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_008.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_008.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_009.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_009.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_010.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_010.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_011.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_011.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_012.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_012.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_013.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_013.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_014.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_014.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_015.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_015.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_016.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_016.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_017.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_017.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_018.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_018.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_019.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_019.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_5_020.P",
          this.tenantId + ".jkjd.Elec_DTU1_5_020.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_6_021.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_021.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_022.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_022.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_023.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_023.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_024.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_024.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_025.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_025.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_026.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_026.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_027.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_027.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_028.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_028.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_029.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_029.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_030.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_030.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_031.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_031.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_032.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_032.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_033.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_033.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_6_034.P",
          this.tenantId + ".jkjd.Elec_DTU1_6_034.Ia",
        ];
        getMonitorRealtime(data).then((res) => {
          this.t32RealtimeData = res.data.data;
        });

        this.timer2 = setInterval(() => {
          let data = [
            this.tenantId + ".jkjd.Elec_DTU1_5_102.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_102.Ib",
            this.tenantId + ".jkjd.Elec_DTU1_5_102.Ic",
            this.tenantId + ".jkjd.Elec_DTU1_5_102.Uab",
            this.tenantId + ".jkjd.Elec_DTU1_5_102.Ubc",
            this.tenantId + ".jkjd.Elec_DTU1_5_102.Uca",
            this.tenantId + ".jkjd.Elec_DTU1_5_102.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_102.Q",
            this.tenantId + ".jkjd.Elec_DTU1_5_102.PF",
            this.tenantId + ".jkjd.Elec_DTU1_5_102.Ep_imp",

            this.tenantId + ".jkjd.Elec_DTU1_5_002.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_002.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_003.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_003.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_004.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_004.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_005.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_005.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_006.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_006.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_5_007.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_007.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_008.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_008.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_009.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_009.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_010.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_010.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_011.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_011.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_012.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_012.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_013.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_013.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_014.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_014.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_015.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_015.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_016.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_016.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_017.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_017.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_018.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_018.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_019.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_019.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_5_020.P",
            this.tenantId + ".jkjd.Elec_DTU1_5_020.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_6_021.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_021.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_022.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_022.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_023.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_023.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_024.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_024.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_025.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_025.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_026.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_026.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_027.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_027.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_028.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_028.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_029.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_029.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_030.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_030.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_031.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_031.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_032.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_032.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_033.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_033.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_6_034.P",
            this.tenantId + ".jkjd.Elec_DTU1_6_034.Ia",
          ];
          getMonitorRealtime(data).then((res) => {
            this.t32RealtimeData = res.data.data;
          });
        }, 60 * 1000);
      }
      if (this.currentTabs === "T3" && this.currentProjectId === "jkjd") {
        let data = [
          this.tenantId + ".jkjd.Elec_DTU1_7_103.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_103.Ib",
          this.tenantId + ".jkjd.Elec_DTU1_7_103.Ic",
          this.tenantId + ".jkjd.Elec_DTU1_7_103.Uab",
          this.tenantId + ".jkjd.Elec_DTU1_7_103.Ubc",
          this.tenantId + ".jkjd.Elec_DTU1_7_103.Uca",
          this.tenantId + ".jkjd.Elec_DTU1_7_103.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_103.Q",
          this.tenantId + ".jkjd.Elec_DTU1_7_103.PF",
          this.tenantId + ".jkjd.Elec_DTU1_7_103.Ep_imp",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.Ib",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.Ic",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.Uab",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.Ubc",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.Uca",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.Q",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.PF",
          this.tenantId + ".jkjd.Elec_DTU1_2_039.Ep_imp",

          this.tenantId + ".jkjd.Elec_DTU1_7_002.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_002.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_003.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_003.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_004.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_004.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_4_005.P",
          this.tenantId + ".jkjd.Elec_DTU1_4_005.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_006.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_006.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_7_007.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_007.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_008.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_008.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_009.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_009.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_010.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_010.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_011.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_011.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_012.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_012.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_013.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_013.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_014.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_014.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_015.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_015.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_016.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_016.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_017.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_017.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_018.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_018.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_019.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_019.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_7_020.P",
          this.tenantId + ".jkjd.Elec_DTU1_7_020.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_2_021.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_021.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_022.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_022.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_023.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_023.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_024.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_024.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_025.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_025.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_026.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_026.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_027.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_027.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_028.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_028.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_029.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_029.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_030.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_030.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_031.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_031.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_032.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_032.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_033.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_033.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_2_034.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_034.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_035.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_035.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_036.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_036.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_037.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_037.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_2_038.P",
          this.tenantId + ".jkjd.Elec_DTU1_2_038.Ia",
          // this.tenantId+".jkjd.Elec_DTU1_2_039.P",
          // this.tenantId+".jkjd.Elec_DTU1_2_039.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_3_040.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_040.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_041.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_041.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_042.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_042.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_043.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_043.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_044.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_044.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_045.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_045.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_046.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_046.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_047.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_047.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_048.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_048.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_049.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_049.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_050.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_050.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_051.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_051.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_052.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_052.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_053.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_053.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_054.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_054.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_055.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_055.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_056.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_056.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_057.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_057.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_058.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_058.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_059.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_059.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_060.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_060.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_061.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_061.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_062.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_062.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_063.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_063.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_064.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_064.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_065.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_065.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_066.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_066.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_3_067.P",
          this.tenantId + ".jkjd.Elec_DTU1_3_067.Ia",
        ];
        getMonitorRealtime(data).then((res) => {
          this.t33RealtimeData = res.data.data;
        });

        this.timer3 = setInterval(() => {
          let data = [
            this.tenantId + ".jkjd.Elec_DTU1_7_103.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_103.Ib",
            this.tenantId + ".jkjd.Elec_DTU1_7_103.Ic",
            this.tenantId + ".jkjd.Elec_DTU1_7_103.Uab",
            this.tenantId + ".jkjd.Elec_DTU1_7_103.Ubc",
            this.tenantId + ".jkjd.Elec_DTU1_7_103.Uca",
            this.tenantId + ".jkjd.Elec_DTU1_7_103.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_103.Q",
            this.tenantId + ".jkjd.Elec_DTU1_7_103.PF",
            this.tenantId + ".jkjd.Elec_DTU1_7_103.Ep_imp",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.Ib",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.Ic",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.Uab",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.Ubc",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.Uca",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.Q",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.PF",
            this.tenantId + ".jkjd.Elec_DTU1_2_039.Ep_imp",

            this.tenantId + ".jkjd.Elec_DTU1_7_002.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_002.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_003.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_003.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_004.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_004.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_4_005.P",
            this.tenantId + ".jkjd.Elec_DTU1_4_005.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_006.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_006.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_7_007.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_007.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_008.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_008.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_009.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_009.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_010.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_010.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_011.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_011.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_012.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_012.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_013.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_013.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_014.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_014.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_015.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_015.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_016.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_016.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_017.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_017.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_018.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_018.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_019.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_019.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_7_020.P",
            this.tenantId + ".jkjd.Elec_DTU1_7_020.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_2_021.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_021.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_022.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_022.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_023.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_023.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_024.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_024.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_025.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_025.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_026.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_026.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_027.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_027.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_028.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_028.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_029.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_029.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_030.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_030.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_031.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_031.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_032.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_032.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_033.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_033.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_2_034.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_034.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_035.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_035.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_036.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_036.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_037.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_037.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_2_038.P",
            this.tenantId + ".jkjd.Elec_DTU1_2_038.Ia",
            // this.tenantId+".jkjd.Elec_DTU1_2_039.P",
            // this.tenantId+".jkjd.Elec_DTU1_2_039.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_3_040.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_040.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_041.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_041.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_042.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_042.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_043.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_043.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_044.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_044.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_045.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_045.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_3_046.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_046.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_047.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_047.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_048.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_048.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_049.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_049.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_050.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_050.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_051.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_051.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_052.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_052.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_3_053.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_053.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_054.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_054.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_055.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_055.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_056.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_056.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_057.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_057.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_058.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_058.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_059.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_059.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_060.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_060.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_3_061.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_061.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_062.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_062.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_063.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_063.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_064.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_064.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_065.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_065.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_066.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_066.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_3_067.P",
            this.tenantId + ".jkjd.Elec_DTU1_3_067.Ia",
          ];
          getMonitorRealtime(data).then((res) => {
            this.t33RealtimeData = res.data.data;
          });
        }, 60 * 1000);
      }
      if (this.currentTabs === "T4" && this.currentProjectId === "jkjd") {
        let data = [
          this.tenantId + ".jkjd.Elec_DTU1_1_104.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_104.Ib",
          this.tenantId + ".jkjd.Elec_DTU1_1_104.Ic",
          this.tenantId + ".jkjd.Elec_DTU1_1_104.Uab",
          this.tenantId + ".jkjd.Elec_DTU1_1_104.Ubc",
          this.tenantId + ".jkjd.Elec_DTU1_1_104.Uca",
          this.tenantId + ".jkjd.Elec_DTU1_1_104.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_104.Q",
          this.tenantId + ".jkjd.Elec_DTU1_1_104.PF",
          this.tenantId + ".jkjd.Elec_DTU1_1_104.Ep_imp",

          this.tenantId + ".jkjd.Elec_DTU1_1_002.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_002.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_003.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_003.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_004.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_004.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_005.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_005.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_1_006.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_006.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_007.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_007.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_008.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_008.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_009.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_009.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_010.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_010.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_011.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_011.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_1_012.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_012.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_013.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_013.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_014.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_014.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_015.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_015.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_016.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_016.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_017.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_017.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_018.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_018.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_019.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_019.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_1_020.P",
          this.tenantId + ".jkjd.Elec_DTU1_1_020.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_8_021.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_021.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_022.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_022.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_023.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_023.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_024.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_024.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_025.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_025.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_026.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_026.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_027.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_027.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_028.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_028.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_8_029.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_029.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_030.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_030.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_031.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_031.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_032.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_032.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_033.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_033.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_034.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_034.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_035.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_035.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_036.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_036.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_8_037.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_037.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_8_038.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_038.Ia",

          this.tenantId + ".jkjd.Elec_DTU1_8_039.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_039.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_040.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_040.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_041.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_041.Ia",
          this.tenantId + ".jkjd.Elec_DTU1_8_042.P",
          this.tenantId + ".jkjd.Elec_DTU1_8_042.Ia",
        ];
        getMonitorRealtime(data).then((res) => {
          this.t34RealtimeData = res.data.data;
        });

        this.timer4 = setInterval(() => {
          let data = [
            this.tenantId + ".jkjd.Elec_DTU1_1_104.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_104.Ib",
            this.tenantId + ".jkjd.Elec_DTU1_1_104.Ic",
            this.tenantId + ".jkjd.Elec_DTU1_1_104.Uab",
            this.tenantId + ".jkjd.Elec_DTU1_1_104.Ubc",
            this.tenantId + ".jkjd.Elec_DTU1_1_104.Uca",
            this.tenantId + ".jkjd.Elec_DTU1_1_104.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_104.Q",
            this.tenantId + ".jkjd.Elec_DTU1_1_104.PF",
            this.tenantId + ".jkjd.Elec_DTU1_1_104.Ep_imp",

            this.tenantId + ".jkjd.Elec_DTU1_1_002.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_002.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_003.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_003.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_004.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_004.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_005.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_005.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_006.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_006.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_1_007.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_007.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_008.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_008.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_009.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_009.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_010.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_010.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_011.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_011.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_012.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_012.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_013.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_013.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_014.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_014.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_015.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_015.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_016.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_016.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_017.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_017.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_018.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_018.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_019.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_019.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_1_020.P",
            this.tenantId + ".jkjd.Elec_DTU1_1_020.Ia",

            this.tenantId + ".jkjd.Elec_DTU1_8_021.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_021.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_022.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_022.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_023.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_023.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_024.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_024.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_025.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_025.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_026.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_026.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_027.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_027.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_028.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_028.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_029.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_029.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_030.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_030.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_031.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_031.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_032.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_032.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_033.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_033.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_034.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_034.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_035.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_035.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_036.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_036.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_037.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_037.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_038.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_038.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_039.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_039.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_040.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_040.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_041.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_041.Ia",
            this.tenantId + ".jkjd.Elec_DTU1_8_042.P",
            this.tenantId + ".jkjd.Elec_DTU1_8_042.Ia",
          ];
          getMonitorRealtime(data).then((res) => {
            this.t34RealtimeData = res.data.data;
          });
        }, 60 * 1000);
      }
    },
  },
  beforeDestroy() {
    // if (this.timer1) {
    //   clearInterval(this.timer1);
    // }
    // if (this.timer2) {
    //   clearInterval(this.timer2);
    // }
    // if (this.timer3) {
    //   clearInterval(this.timer3);
    // }
    // if (this.timer4) {
    //   clearInterval(this.timer4);
    // }
    clearInterval(this.timer1); // 清除定时器
    this.timer1 = null;
    clearInterval(this.timer2); // 清除定时器
    this.timer2 = null;
    clearInterval(this.timer3); // 清除定时器
    this.timer3 = null;
    clearInterval(this.timer4); // 清除定时器
    this.timer4 = null;
    clearInterval(this.timer5); // 清除定时器
    this.timer5 = null;
  },
  watch: {
    currentProjectId(val) {
      // debugger
      clearInterval(this.timer1); // 清除定时器
      this.timer1 = null;
      clearInterval(this.timer2); // 清除定时器
      this.timer2 = null;
      clearInterval(this.timer3); // 清除定时器
      this.timer3 = null;
      clearInterval(this.timer4); // 清除定时器
      this.timer4 = null;
      clearInterval(this.timer5); // 清除定时器
      this.timer5 = null;
      console.log(val);
      // this.currentTabs = item.code;
      if (val === "zgds") {
        this.currentTabs = "T1";
        this.dataTabs = [
          { name: "T1", code: "T1" },
          { name: "T2", code: "T2" },
          { name: "T3", code: "T3" },
          { name: "T4", code: "T4" },
        ];
        setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
          (res2) => {
            if (
              JSON.parse(window.localStorage.getItem("PROJECTID")) === "zgds"
            ) {
              this.currentTabs = "T1";
              this.dataTabs = [
                { name: "T1", code: "T1" },
                { name: "T2", code: "T2" },
                { name: "T3", code: "T3" },
                { name: "T4", code: "T4" },
              ];
            } else {
              this.currentTabs = "高变";
              this.dataTabs = [
                { name: "高变", code: "高变" },
                { name: "T1", code: "T1" },
                { name: "T2", code: "T2" },
                { name: "T3", code: "T3" },
                { name: "T4", code: "T4" },
                { name: "T5", code: "T5" },
                { name: "T6", code: "T6" },
              ];
            }
            if (res2.data.code === 0) {
              console.log(res2.data);
              // getMonitorRelative([
              //   this.tenantId+".zgds.Elec_DTU1_1_002.Ep_imp",
              //   this.tenantId+".zgds.Elec_DTU1_1_018.Ep_imp",
              // ]).then((res) => {
              //   // debugger
              //   this.t1RelativeData = res.data.data;
              // });
              let data = [
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ep_imp",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ep_imp",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ib",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ic",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Uab",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Ubc",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Uca",
                this.tenantId + ".zgds.Elec_DTU1_1_002.P",
                this.tenantId + ".zgds.Elec_DTU1_1_002.Q",
                this.tenantId + ".zgds.Elec_DTU1_1_002.PF",
                this.tenantId + ".zgds.Elec_DTU1_1_003.P",
                this.tenantId + ".zgds.Elec_DTU1_1_003.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_004.P",
                this.tenantId + ".zgds.Elec_DTU1_1_004.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_005.P",
                this.tenantId + ".zgds.Elec_DTU1_1_005.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_006.P",
                this.tenantId + ".zgds.Elec_DTU1_1_006.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_007.P",
                this.tenantId + ".zgds.Elec_DTU1_1_007.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_008.P",
                this.tenantId + ".zgds.Elec_DTU1_1_008.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_009.P",
                this.tenantId + ".zgds.Elec_DTU1_1_009.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_010.P",
                this.tenantId + ".zgds.Elec_DTU1_1_010.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_011.P",
                this.tenantId + ".zgds.Elec_DTU1_1_011.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_012.P",
                this.tenantId + ".zgds.Elec_DTU1_1_012.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_013.P",
                this.tenantId + ".zgds.Elec_DTU1_1_013.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_014.P",
                this.tenantId + ".zgds.Elec_DTU1_1_014.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_015.P",
                this.tenantId + ".zgds.Elec_DTU1_1_015.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_016.P",
                this.tenantId + ".zgds.Elec_DTU1_1_016.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_017.P",
                this.tenantId + ".zgds.Elec_DTU1_1_017.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_018.P",
                this.tenantId + ".zgds.Elec_DTU1_1_018.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_019.P",
                this.tenantId + ".zgds.Elec_DTU1_1_019.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_020.P",
                this.tenantId + ".zgds.Elec_DTU1_1_020.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_021.P",
                this.tenantId + ".zgds.Elec_DTU1_1_021.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_022.P",
                this.tenantId + ".zgds.Elec_DTU1_1_022.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_023.P",
                this.tenantId + ".zgds.Elec_DTU1_1_023.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_024.P",
                this.tenantId + ".zgds.Elec_DTU1_1_024.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_025.P",
                this.tenantId + ".zgds.Elec_DTU1_1_025.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_026.P",
                this.tenantId + ".zgds.Elec_DTU1_1_026.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_027.P",
                this.tenantId + ".zgds.Elec_DTU1_1_027.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ia",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ib",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ic",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Uab",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Ubc",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Uca",
                this.tenantId + ".zgds.Elec_DTU1_1_028.P",
                this.tenantId + ".zgds.Elec_DTU1_1_028.Q",
                this.tenantId + ".zgds.Elec_DTU1_1_028.PF",
              ];
              getMonitorRealtime(data).then((res) => {
                this.t1RealtimeData = res.data.data;
              });

              this.timer1 = setInterval(() => {
                // getMonitorRelative([
                //   this.tenantId+".zgds.Elec_DTU1_1_002.Ep_imp",
                //   this.tenantId+".zgds.Elec_DTU1_1_018.Ep_imp",
                // ]).then((res) => {
                //   if(res.data.code===0){

                //     this.t1RelativeData = res.data.data;
                //   }
                //   // else if(){

                //   // }
                // });
                let data = [
                  this.tenantId + ".zgds.Elec_DTU1_1_002.Ep_imp",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.Ep_imp",
                  this.tenantId + ".zgds.Elec_DTU1_1_002.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_002.Ib",
                  this.tenantId + ".zgds.Elec_DTU1_1_002.Ic",
                  this.tenantId + ".zgds.Elec_DTU1_1_002.Uab",
                  this.tenantId + ".zgds.Elec_DTU1_1_002.Ubc",
                  this.tenantId + ".zgds.Elec_DTU1_1_002.Uca",
                  this.tenantId + ".zgds.Elec_DTU1_1_002.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_002.Q",
                  this.tenantId + ".zgds.Elec_DTU1_1_002.PF",
                  this.tenantId + ".zgds.Elec_DTU1_1_003.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_003.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_004.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_004.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_005.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_005.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_006.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_006.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_007.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_007.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_008.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_008.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_009.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_009.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_010.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_010.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_011.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_011.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_012.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_012.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_013.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_013.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_014.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_014.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_015.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_015.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_016.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_016.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_017.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_017.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_018.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_018.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_019.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_019.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_020.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_020.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_021.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_021.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_022.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_022.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_023.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_023.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_024.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_024.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_025.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_025.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_026.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_026.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_027.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_027.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.Ia",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.Ib",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.Ic",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.Uab",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.Ubc",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.Uca",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.P",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.Q",
                  this.tenantId + ".zgds.Elec_DTU1_1_028.PF",
                ];
                getMonitorRealtime(data).then((res) => {
                  this.t1RealtimeData = res.data.data;
                });
              }, 60 * 1000);
            }
          }
        );
      } else if (this.currentProjectId === "tcsd") {
        this.currentTabs = "高变";
        this.dataTabs = [
          { name: "高变", code: "高变" },
          { name: "T1", code: "T1" },
          { name: "T2", code: "T2" },
          { name: "T3", code: "T3" },
          { name: "T4", code: "T4" },
          { name: "T5", code: "T5" },
          { name: "T6", code: "T6" },
        ];
      } else if (this.currentProjectId === "mgm") {
        setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
          (res2) => {
            if (res2.data.code === 0) {
              let data = [
                this.tenantId + ".mgm.HVAC.ColdTotalFlow",
                this.tenantId + ".mgm.HVAC.HotTotalFlow",
                this.tenantId + ".mgm.HP.ColdTotalFlow",
                this.tenantId + ".mgm.CH.ColdTotalFlow",
              ];
              getMonitorRealtime(data).then((res) => {
                console.log("mgm", res);
                this.mgmData = res.data.data;
              });

              this.timer5 = setInterval(() => {
                let data = [
                  this.tenantId + ".mgm.HVAC.ColdTotalFlow",
                  this.tenantId + ".mgm.HVAC.HotTotalFlow",
                  this.tenantId + ".mgm.HP.ColdTotalFlow",
                  this.tenantId + ".mgm.CH.ColdTotalFlow",
                ];
                getMonitorRealtime(data).then((res) => {
                  this.mgmData = res.data.data;
                });
              }, 60 * 1000);
            }
          }
        );
      } else if (this.currentProjectId === "jkjd") {
        this.currentTabs = "T1";
        this.dataTabs = [
          { name: "T1", code: "T1" },
          { name: "T2", code: "T2" },
          { name: "T3", code: "T3" },
          { name: "T4", code: "T4" },
        ];
        setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
          (res2) => {
            if (res2.data.code === 0) {
              console.log(res2.data);
              let data = [
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ib",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ic",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Uab",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ubc",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Uca",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Q",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.PF",
                this.tenantId + ".jkjd.Elec_DTU2_1_027.Ep_imp",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ib",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ic",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Uab",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ubc",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Uca",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Q",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.PF",
                this.tenantId + ".jkjd.Elec_DTU1_4_101.Ep_imp",
                this.tenantId + ".jkjd.Elec_DTU1_4_002.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_002.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_003.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_003.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_004.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_004.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_005.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_005.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_006.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_006.Ia",

                this.tenantId + ".jkjd.Elec_DTU1_4_007.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_007.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_008.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_008.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_009.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_009.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_010.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_010.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_011.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_011.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_012.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_012.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_013.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_013.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_014.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_014.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_015.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_015.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_016.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_016.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_017.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_017.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_018.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_018.Ia",
                this.tenantId + ".jkjd.Elec_DTU1_4_019.P",
                this.tenantId + ".jkjd.Elec_DTU1_4_019.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_020.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_020.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_021.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_021.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_022.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_022.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_023.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_023.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_024.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_024.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_025.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_025.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_026.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_026.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_028.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_028.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_029.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_029.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_030.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_030.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_031.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_031.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_032.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_032.Ia",
                this.tenantId + ".jkjd.Elec_DTU2_1_033.P",
                this.tenantId + ".jkjd.Elec_DTU2_1_033.Ia",
              ];
              getMonitorRealtime(data).then((res) => {
                this.t31RealtimeData = res.data.data;
              });

              this.timer1 = setInterval(() => {
                let data = [
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.Ib",
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.Ic",
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.Uab",
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.Ubc",
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.Uca",
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.Q",
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.PF",
                  this.tenantId + ".jkjd.Elec_DTU2_1_027.Ep_imp",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.Ib",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.Ic",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.Uab",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.Ubc",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.Uca",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.Q",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.PF",
                  this.tenantId + ".jkjd.Elec_DTU1_4_101.Ep_imp",
                  this.tenantId + ".jkjd.Elec_DTU1_4_002.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_002.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_003.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_003.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_004.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_004.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_005.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_005.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_006.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_006.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_007.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_007.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_008.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_008.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_009.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_009.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_010.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_010.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_011.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_011.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_012.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_012.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_013.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_013.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_014.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_014.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_015.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_015.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_016.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_016.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_017.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_017.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_018.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_018.Ia",
                  this.tenantId + ".jkjd.Elec_DTU1_4_019.P",
                  this.tenantId + ".jkjd.Elec_DTU1_4_019.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_020.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_020.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_021.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_021.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_022.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_022.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_023.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_023.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_024.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_024.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_025.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_025.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_026.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_026.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_028.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_028.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_029.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_029.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_030.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_030.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_031.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_031.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_032.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_032.Ia",
                  this.tenantId + ".jkjd.Elec_DTU2_1_033.P",
                  this.tenantId + ".jkjd.Elec_DTU2_1_033.Ia",
                ];
                getMonitorRealtime(data).then((res) => {
                  this.t31RealtimeData = res.data.data;
                });
              }, 60 * 1000);
            }
          }
        );
      } else if (this.currentProjectId === "wtzx") {
        //
      }
    },
  },
};
</script>

<style scoped>
/* .backg::-webkit-scrollbar {
  display: none;
} */
.isClick {
  color: #3f6df8 !important;
  border-bottom: 4px solid #3f6df8;
}
.backg {
  padding: 20px;
  width: 100%;
  height: 100%;
  /* background: url("~@/assets/t1bg.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position:center;
  position: relative; */
}
.tbg {
  background: url("~@/assets/t1bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.mgmbg {
  background: url("~@/assets/mgmbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.tbg2 {
  background: url("~@/assets/t2bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg3 {
  background: url("~@/assets/t3bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg4 {
  background: url("~@/assets/t4bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.tbg20 {
  background: url("~@/assets/gb.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg21 {
  background: url("~@/assets/t11.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg22 {
  background: url("~@/assets/t12.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg23 {
  background: url("~@/assets/t13.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg24 {
  background: url("~@/assets/t14.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg25 {
  background: url("~@/assets/t15.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg26 {
  background: url("~@/assets/t16.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg31 {
  background: url("~@/assets/t31.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg32 {
  background: url("~@/assets/t32.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg331 {
  background: url("~@/assets/t331.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg332 {
  background: url("~@/assets/t332.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg34 {
  background: url("~@/assets/t34.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
</style>
