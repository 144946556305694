<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 150px;
      "
    >
      <div class="title1">
        <img
          src="@/assets/firstC.png"
          style="position: absolute; top: 27px; right: 30px"
        />
        <div style="width: 110px; margin-top: 30px">
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 22px;
              color: #082254;
            "
          >
            室外温度
          </div>
          <div style="width: 100%; text-align: center; margin-top: 10px">
            <span style="font-size: 38px; color: #3f6df8">{{OutdoorTemperature}}</span>
            <span style="font-size: 22px; color: #3f6df8">℃</span>
          </div>
        </div>
        <!-- <div style="margin-top: 36px">
          <div style="float: left; color: white">
            <span style="font-size: 20px">日用电量</span
            ><span style="font-size: 14px">(kWh)</span>
          </div>
          <div style="float: right; color: white">
            <span style="font-size: 20px">月用电量</span
            ><span style="font-size: 14px">(万kWh)</span>
          </div>
          <div style="clear: both"></div>
        </div>
        <div style="margin-top: 23px">
          <div
            style="
              float: left;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 120px;
              text-align: center;
            "
          >
            6543.5
          </div>
          <div
            style="
              float: right;
              font-size: 34px;
              color: white;
              font-weight: 500;
              width: 120px;
              text-align: center;
            "
          >
            27.6
          </div>
          <div style="clear: both"></div>
        </div> -->
      </div>
      <div class="title2">
        <img
          src="@/assets/secondC.png"
          style="position: absolute; top: 27px; right: 30px"
        />
        <div style="width: 110px; margin-top: 30px">
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 22px;
              color: #082254;
            "
          >
            室外湿度
          </div>
          <div style="width: 100%; text-align: center; margin-top: 10px">
            <span style="font-size: 38px; color: #3f6df8">{{OutdoorHumidity}}</span>
            <span style="font-size: 22px; color: #3f6df8">%</span>
          </div>
        </div>
      </div>
      <div class="title3">
        <img
          src="@/assets/thirdC.png"
          style="position: absolute; top: 27px; right: 30px"
        />
        <div style="width: 110px; margin-top: 30px">
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 22px;
              color: #082254;
            "
          >
            <!-- 露点温度 -->湿球温度
          </div>
          <div style="width: 100%; text-align: center; margin-top: 10px">
            <span style="font-size: 38px; color: #3f6df8">{{DewPointTemperature}}</span>
            <span style="font-size: 22px; color: #3f6df8">℃</span>
          </div>
        </div>
      </div>
      <div class="title4">
        <img
          src="@/assets/forthC.png"
          style="position: absolute; top: 27px; right: 30px"
        />
        <div style="width: 110px; margin-top: 30px; display: inline-block">
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 22px;
              color: #082254;
            "
          >
            供水温度
          </div>
          <div style="width: 100%; text-align: center; margin-top: 10px">
            <span style="font-size: 38px; color: #3f6df8">{{WaterSupplyTemperature}}</span>
            <span style="font-size: 22px; color: #3f6df8">℃</span>
          </div>
        </div>
        <div style="width: 110px; margin-top: 30px; display: inline-block">
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 22px;
              color: #082254;
            "
          >
            回水温度
          </div>
          <div style="width: 100%; text-align: center; margin-top: 10px">
            <span style="font-size: 38px; color: #3f6df8">{{ReturnWaterTemperature}}</span>
            <span style="font-size: 22px; color: #3f6df8">℃</span>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 360px;
        margin-top: 20px;
      "
    >
      <div class="body1" style="padding: 18px">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                width: 4px;
                height: 20px;
                background: #3f6df8;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
              综合能效
            </div>
          </div>

          <!-- <div style="">
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickNH(1)"
              :class="{ activeNH: isActivenTime === 1 }"
            >
              年
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickNH(2)"
              :class="{ activeNH: isActivenTime === 2 }"
            >
              月
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickNH(3)"
              :class="{ activeNH: isActivenTime === 3 }"
            >
              日
            </div>
          </div> -->
        </div>
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 10px;
          "
        >
          <div style="margin-right: 50px">
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickCat(1)"
              :class="{ activeNH: isActivenCat === 1 }"
            >
              系统
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickCat(2)"
              :class="{ activeNH: isActivenCat === 2 }"
            >
              主机
            </div>
          </div>

          <div
            class="square2-categorys"
            style="border-radius: 2px; margin-right: 20px"
            @click="clickNH(1)"
            :class="{ activeNH: isActivenTime === 1 }"
          >
            实时
          </div>

          <div
            class="square2-categorys"
            style="border-radius: 2px; margin-right: 20px"
            @click="clickNH(2)"
            :class="{ activeNH: isActivenTime === 2 }"
          >
            累计
          </div>

          <div style="">
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickNH(3)"
              :class="{ activeNH: isActivenTime === 3 }"
            >
              日
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickNH(4)"
              :class="{ activeNH: isActivenTime === 4 }"
            >
              月
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickNH(5)"
              :class="{ activeNH: isActivenTime === 5 }"
            >
              年
            </div>
          </div>

          <div style="margin-left: 14px; width: 180px" class="date">
            <el-date-picker
              style="width: 180px"
              v-model="value1"
              :disabled="isDisableDate"
              :type="changeDateType"
              placeholder="选择日期"
              value-format="yyyy-MM-dd 00:00:00"
              @change="changeDate1"
            >
            </el-date-picker>
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 280px;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="width: 40%; height: 100%">
            <v-chart ref="chart1" :option="chartPieOption1" />
          </div>
          <div
            style="
              width: 60%;
              height: 100%;
              padding-top: 50px;
              padding-left: 30px;
            "
          >
            <div style="height: 29px; line-height: 29px">
              <span style="font-size: 16px; color: #082254; line-height: 29px"
                >时间范围：</span
              >
              <span
                style="font-size: 22px; color: #3f6df8; line-height: 29px"
                >{{ timeSpan }}</span
              >
            </div>
            <div style="height: 29px; line-height: 29px; margin-top: 28px">
              <span style="font-size: 16px; color: #082254; line-height: 29px"
                >综合能效：</span
              >
              <span
                style="
                  font-size: 22px;
                  color: #3f6df8;
                  line-height: 29px;
                  vertical-align: bottom;
                "
                >{{ dashboardValue }}</span
              >
            </div>
            <div style="height: 29px; line-height: 29px; margin-top: 28px">
              <span style="font-size: 16px; color: #082254; line-height: 29px"
                >接入时间：</span
              >
              <span
                style="
                  font-size: 22px;
                  color: #3f6df8;
                  line-height: 29px;
                  vertical-align: bottom;
                "
                >2021-03-23</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="body2" style="padding: 18px">
        <!-- <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                width: 4px;
                height: 20px;
                background: #3f6df8;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
              用能成本占比分析
            </div>
          </div>
          <div style="">
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickNH(1)"
              :class="{ activeNH: isActivenTime === 1 }"
            >
              年
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickNH(2)"
              :class="{ activeNH: isActivenTime === 2 }"
            >
              月
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickNH(3)"
              :class="{ activeNH: isActivenTime === 3 }"
            >
              日
            </div>
          </div>
        </div> -->

        <div
          v-if="isShowPie"
          style="width: 100%; height: 303px; margin-top: 60px"
        >
          <v-chart ref="chart2" :option="chartPieOption2" />
        </div>
        <div
          v-else
          style="
            width: 100%;
            height: 343px;
            margin-top: 21px;
            line-height: 343px;
            font-size: 20px;
            color: #3f6df8;
          "
        >
          暂无数据
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        background: white;
        height: 300px;

        padding: 20px;
        padding-bottom: 0;
        padding-top: 10px;
        position: relative;
      "
    >
      <!-- margin-top: 20px; <div
        style="position: absolute; top: 40px; left: 20px; z-index: 99"
        class="sp-class"
      >
        时间选择：<el-date-picker
          style="width: 180px"
          v-model="value2"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd 00:00:00"
          @change="searchChart"
        >
        </el-date-picker>
      </div> -->
      <!-- <div
        style="
          height: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        "
      >
        <div
          style="
            width: 4px;
            height: 20px;
            background: #3f6df8;
            margin-right: 10px;
          "
        ></div>
        <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
          能耗数据
        </div>
      </div> -->

      <div style="width: 100%; height: 290px">
        <v-chart ref="chartB2" :option="chartMultiOption" />
      </div>
      <!-- <div
        style="
          width: 100%;
          height: 230px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div style="width: 25%; height: 100%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              color: #082254;
              margin-top: 10px;
              font-size: 16px;
              height: 21px;
            "
          >
            用电同期环比：<img
              src="@/assets/指数上升.png"
              style="vertical-align: middle"
            />+6%
          </div>
          <div style="color: #082254; font-size: 14px; margin-left: 20px">
            (万kWh)
          </div>
          <div style="width: 100%; height: 180px">
            <v-chart ref="chartB2" :option="chartBarOption2" />
          </div>
        </div>
        <div style="width: 25%; height: 100%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              color: #082254;
              margin-top: 10px;
              font-size: 16px;
              height: 21px;
            "
          >
            用水同期环比：<img
              src="@/assets/指数上升.png"
              style="vertical-align: middle"
            />+12%
          </div>
          <div style="color: #082254; font-size: 14px; margin-left: 20px">
            (m3)
          </div>
          <div style="width: 100%; height: 180px">
            <v-chart ref="chartB1" :option="chartBarOption1" />
          </div>
        </div>
        <div style="width: 25%; height: 100%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              color: #082254;
              margin-top: 10px;
              font-size: 16px;
              height: 21px;
            "
          >
            用气同期环比：<img
              src="@/assets/指数下降.png"
              style="vertical-align: middle"
            />-18%
          </div>
          <div style="color: #082254; font-size: 14px; margin-left: 20px">
            (m3)
          </div>
          <div style="width: 100%; height: 180px">
            <v-chart ref="chartB3" :option="chartBarOption3" />
          </div>
        </div>
        <div style="width: 25%; height: 100%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: bold;
              color: #082254;
              margin-top: 10px;
              font-size: 16px;
              height: 21px;
            "
          >
            用蒸汽同期环比：
            
            0%
          </div>
          <div style="color: #082254; font-size: 14px; margin-left: 20px">
            (T)
          </div>
          <div style="width: 100%; height: 180px">
            <v-chart ref="chartB4" :option="chartBarOption4" />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";
import {
  // getProjects,
  setProject,
  getColdDashboard,
  getColdPercent,
  getColdChart,
  getMonitorRealtime
} from "@/request/apiLg";
import {
  getRealtime
} from "@/request/apiJk";
export default {
  data() {
    var dataAxis = ["当月", "上月"];
    var data1 = [
      3230.8,
      {
        value: 2843.1,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#55ffbc" },
            { offset: 0.62, color: "#47A5E1" },
            { offset: 1, color: "#3F6DF8" },
          ]),
          borderRadius: 15,
          opacity: 0.6,
        },
      },
    ];
    var data2 = [
      27.6,
      {
        value: 25.9,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#55ffbc" },
            { offset: 0.62, color: "#47A5E1" },
            { offset: 1, color: "#3F6DF8" },
          ]),
          borderRadius: 15,
          opacity: 0.6,
        },
      },
    ];
    var data3 = [
      2408.8,
      {
        value: 2842.4,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#55ffbc" },
            { offset: 0.62, color: "#47A5E1" },
            { offset: 1, color: "#3F6DF8" },
          ]),
          borderRadius: 15,
          opacity: 0.6,
        },
      },
    ];
    // var colorList_data_2 = [
    //   {
    //     c1: "#6DCB86", //实践
    //     c2: "#6DCB86",
    //     c3: "#6DCB86",
    //   },
    //   {
    //     c1: "#E97F75", //操作
    //     c2: "#EA9B7A",
    //     c3: "#F6B084",
    //   },
    //   {
    //     c1: "#8C44E1", //操作
    //     c2: "#AD6BF1",
    //     c3: "#B97DF9",
    //   },
    //   {
    //     c1: "#277CFE",
    //     c2: "#6BCDFF", //操作
    //   },
    // ];
    return {
      OutdoorTemperature:'24.1',//室外温度
      OutdoorHumidity:'62',//室外湿度
      DewPointTemperature:'19.0',//露点温度
      WaterSupplyTemperature:'',//供水温度
      ReturnWaterTemperature:'',//回水温度
      params1: {},
      changeZoom: 16,
      dataXvalue: [],
      startTime1: "",
      endTime1: "",
      isActivenCat: 1,
      COPName: "",
      coldName: "",
      elecName: "",
      tempName: "",
      timeSpan: "实时",
      isDisableDate: false,
      timer: null,
      pieData: [],
      dashboardValue: "",
      dashboardValueC: "0",
      isShowPie: true,
      COPValue: [],
      coldValue: [],
      elecValue: [],
      tempValue: [],
      value1: "",
      value2: "",
      changeDateType: "date",
      isActivenTime: 3,
      chartBarOption1: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: this.changeZoom,
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: data1,
          },
        ],
      },
      chartBarOption2: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: data2,
          },
        ],
      },
      chartBarOption3: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: data3,
          },
        ],
      },
      chartBarOption4: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: [0, 0],
          },
        ],
      },
      // chartPieOption1: {
      //   color: ["#5492D1", "#46BCEE", "#ED9C00", "#53EFC3"],
      //   grid: {
      //     // containLabel: true,
      //     // top: "10px",
      //     // left: "5px",
      //     // right: "10px",
      //     // bottom: "10px",
      //   },
      //   tooltip: {
      //     trigger: "item",
      //     formatter: "{b}: {d}%",
      //   },
      //   legend: {
      //     type: "scroll",
      //     orient: "vertical",
      //     right: 0,
      //     top: 150,
      //     bottom: 20,
      //     // data: data.legendData,
      //     // selected: data.selected,
      //     textStyle: {
      //       color: "#082254",
      //     },
      //     data: [
      //       {
      //         name: "用电量",
      //         textStyle: {
      //           color: "#EA9779",
      //         },
      //       },
      //       {
      //         name: "用水量",
      //         textStyle: {
      //           color: "#3F6DF8",
      //         },
      //       },

      //       {
      //         name: "燃气量",
      //         textStyle: {
      //           color: "#A05CEB",
      //         },
      //       },
      //       {
      //         name: "蒸汽量",
      //         textStyle: {
      //           color: "#6DCB86",
      //         },
      //       },
      //     ],
      //   },
      //   series: [
      //     {
      //       // name: "访问来源",
      //       type: "pie",
      //       roseType: "radius",
      //       radius: "80%",
      //       center: ["50%", "50%"],
      //       avoidLabelOverlap: false,
      //       itemStyle: {
      //         color: function (params) {
      //           // debugger
      //           var colorList = colorList_data_2;
      //           if (params.dataIndex === 3) {
      //             return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
      //               {
      //                 //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

      //                 offset: 0,
      //                 color: colorList[params.dataIndex].c1,
      //               },
      //               {
      //                 offset: 1,
      //                 color: colorList[params.dataIndex].c2,
      //               },
      //             ]);
      //           } else {
      //             return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
      //               {
      //                 //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

      //                 offset: 0,
      //                 color: colorList[params.dataIndex].c1,
      //               },
      //               {
      //                 //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

      //                 offset: 0.5,
      //                 color: colorList[params.dataIndex].c2,
      //               },
      //               {
      //                 offset: 1,
      //                 color: colorList[params.dataIndex].c3,
      //               },
      //             ]);
      //           }
      //           /*  return colorList[params.dataIndex]*/
      //         },
      //       },
      //       label: {
      //         position: "outside",
      //         // alignTo: "edge",
      //         formatter: (data) => {
      //           // console.log("data", data);
      //           // debugger
      //           if (data.name === "蒸汽量") {
      //             return `{a|蒸汽量: ${data.value}tec ${data.percent}%}`;
      //           } else if (data.name === "用电量") {
      //             return `{b|用电量: ${data.value}tec ${data.percent}%}`;
      //           } else if (data.name === "燃气量") {
      //             return `{c|燃气量: ${data.value}tec ${data.percent}%}`;
      //           } else if (data.name === "用水量") {
      //             return `{d|用水量: ${data.value}tec ${data.percent}%}`;
      //           }
      //           // const rowArr = [];
      //           // data.forEach(() => {
      //           // const value = this.avaliableTwo(v.data.value[1]);
      //           // if (value) {
      //           //   if (v.data.itemStyle) {
      //           //     rowArr.push(
      //           //       `<span style="color:red;">单耗: ${value} </span>`
      //           //     );
      //           //   } else {
      //           //     rowArr.push(`<span style="">单耗: ${value} </span>`);
      //           //   }
      //           // }
      //           // });
      //           // rowArr.push(`记录时间：${this.formatToMin(data[0].axisValue)}`);
      //           // return rowArr.join("<br/>");
      //         },
      //         // minMargin: 5,
      //         // edgeDistance: 10,
      //         // lineHeight: 15,
      //         rich: {
      //           a: {
      //             fontSize: 14,
      //             color: "#6DCB86",
      //             // fontWeight: "bold",
      //           },
      //           b: {
      //             fontSize: 14,
      //             color: "#EA9779",
      //             // fontWeight: "bold",
      //           },
      //           c: {
      //             fontSize: 14,
      //             color: "#A05CEB",
      //             // fontWeight: "bold",
      //           },
      //           d: {
      //             fontSize: 14,
      //             color: "#3F6DF8",
      //             // fontWeight: "bold",
      //           },
      //         },
      //       },
      //       emphasis: {
      //         label: {
      //           show: true,
      //           fontSize: 14,
      //           // fontWeight: "bold",
      //         },
      //       },
      //       labelLine: {
      //         show: true,
      //         length2: 20,
      //         // smooth: true,
      //         // minTurnAngle: 50,
      //       },
      //       data: [
      //         { value: 0, name: "蒸汽量" },
      //         { value: 1750.1, name: "用电量" },
      //         { value: 363.1, name: "燃气量" },
      //         { value: 16.7, name: "用水量" },
      //       ],
      //     },
      //   ],
      // },
      // chartPieOption2: {

      // },
    };
  },
  mounted() {
    let dateT = new Date();
    var YY = dateT.getFullYear() + "-";
    var MM =
      (dateT.getMonth() + 1 < 10
        ? "0" + (dateT.getMonth() + 1)
        : dateT.getMonth() + 1) + "-";
    var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    this.value1 = YY + MM + DD + " 00:00:00";
    this.value2 = YY + MM + DD + " 00:00:00";
    let tempDate = YY + MM + DD + " 00:00:00";
    this.timeSpan = YY + MM + DD;
    dateT = new Date(tempDate);
    dateT = dateT.setDate(dateT.getDate() + 1);
    dateT = this.formatDate(dateT);
    this.params1 = {
      queryTypeEnum: "DURATION",
      timeUnit: "DAYS",
      interval: 1,
      startTime: tempDate,
      endTime: dateT,
    };
    this.startTime1 = this.params1.startTime;
    this.endTime1 = this.params1.endTime;
    this.params1.copType = "system_cop";
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          if(this.currentProjectId == 'jkjd'){
            // let data = [
    this.getdataM()

            //   "linkgap.jkjd.HVAC_JSQ.Temp",
            //   "linkgap.jkjd.HVAC_FSQ.Temp",
            // ];
            // getMonitorRealtime(data).then((res) => {
            //   console.log('金科酒店供水回水温度：',res)
            //   if(res.data.code == 0){
            //     this.WaterSupplyTemperature = Number(res.data.data['linkgap.jkjd.HVAC_FSQ.Temp']).toFixed(1)
            //     this.ReturnWaterTemperature = Number(res.data.data['linkgap.jkjd.HVAC_JSQ.Temp']).toFixed(1)
            //   }
            // });
          }else if(this.currentProjectId == 'zgds'){
            let data = [
              "linkgap.zgds.HVAC_JSQ.Temp",
              "linkgap.zgds.HVAC_FSQ.Temp",
            ];
            getMonitorRealtime(data).then((res) => {
              console.log('紫光大厦供水回水温度：',res)
              if(res.data.code == 0){
                this.WaterSupplyTemperature = Number(res.data.data['linkgap.zgds.HVAC_FSQ.Temp']).toFixed(1)
                this.ReturnWaterTemperature = Number(res.data.data['linkgap.zgds.HVAC_JSQ.Temp']).toFixed(1)
              }
            });
          }else if(this.currentProjectId == 'mgm'){
            let data = [
              "linkgap.mgm.CS.Temp",
              "linkgap.mgm.CR.Temp",
            ];
            getMonitorRealtime(data).then((res) => {
              console.log('美高梅供水回水温度：',res)
              if(res.data.code == 0){
                this.WaterSupplyTemperature = Number(res.data.data['linkgap.mgm.CS.Temp']).toFixed(1)
                this.ReturnWaterTemperature = Number(res.data.data['linkgap.mgm.CR.Temp']).toFixed(1)
              }
            });
          }else if(this.currentProjectId == 'wtzx'){
            let data = [
              "linkgap.wtzx.HVAC_JSQ.Temp",
              "linkgap.wtzx.HVAC_FSQ.Temp",
            ];
            getMonitorRealtime(data).then((res) => {
              console.log('文体中心供水回水温度：',res)
              if(res.data.code == 0){
                this.WaterSupplyTemperature = Number(res.data.data['linkgap.wtzx.HVAC_FSQ.Temp']).toFixed(1)
                this.ReturnWaterTemperature = Number(res.data.data['linkgap.wtzx.HVAC_JSQ.Temp']).toFixed(1)
              }
            });
          }
          getColdDashboard(this.params1).then((res) => {
            console.log(res);
            if (res.data.data.formatValue === null) {
              this.dashboardValue = "--";
              this.dashboardValueC = 0;
            } else {
              this.dashboardValue = res.data.data.formatValue;
              this.dashboardValueC = res.data.data.formatValue;
            }
            console.log("this.dashboardValue1", this.dashboardValue);
          });
          getColdPercent(this.params1).then((res) => {
            console.log(res);
            let temp = [];
            res.data.data.forEach((item) => {
              if (item.value === null) {
                temp.push(1);
              }
            });
            if (temp.length === res.data.data.length) {
              this.isShowPie = false;
            } else {
              this.isShowPie = true;
            }
            this.pieData = res.data.data;
          });
          // this.timer = setInterval(() => {
          //   this.dashboardValue = "";
          //   this.dashboardValueC = 0;
          //   getColdDashboard(params1).then((res) => {
          //     console.log(res);
          //     if (res.data.data.formatValue === null) {
          //       this.dashboardValue = "--";
          //       this.dashboardValueC = 0;
          //     } else {
          //       this.dashboardValue = res.data.data.formatValue;
          //       this.dashboardValueC = res.data.data.formatValue;
          //     }
          //     console.log("this.dashboardValue1", this.dashboardValue);
          //   });
          //   getColdPercent(params1).then((res) => {
          //     console.log(res);
          //     let temp = [];
          //     res.data.data.forEach((item) => {
          //       if (item.value === null) {
          //         temp.push(1);
          //       }
          //     });
          //     if (temp.length === res.data.data.length) {
          //       this.isShowPie = false;
          //     } else {
          //       this.isShowPie = true;
          //     }
          //     this.pieData = res.data.data;
          //   });
          // }, 60 * 1000);

          // let params3 = new Date(this.value1).getTime();
          getColdChart(this.params1).then((res) => {
            console.log(res.data.data.valueList);
            if (this.isActivenTime === 1) {
              res.data.data.timeList.forEach((item) => {
                this.dataXvalue.push(
                  this.formatDate(item).split(" ")[1].split(":")[0] +
                    ":" +
                    this.formatDate(item).split(" ")[1].split(":")[1]
                );
              });
            } else if (this.isActivenTime === 2) {
              res.data.data.timeList.forEach((item) => {
                this.dataXvalue.push(
                  this.formatDate(item).split(" ")[0].split("-")[1]
                );
              });
            } else if (this.isActivenTime === 3) {
              res.data.data.timeList.forEach((item) => {
                this.dataXvalue.push(
                  this.formatDate(item).split(" ")[1].split(":")[0] +
                    ":" +
                    this.formatDate(item).split(" ")[1].split(":")[1]
                );
              });
            } else if (this.isActivenTime === 4) {
              res.data.data.timeList.forEach((item) => {
                this.dataXvalue.push(
                  this.formatDate(item).split(" ")[0].split("-")[1] +
                    "-" +
                    this.formatDate(item).split(" ")[0].split("-")[2]
                );
              });
            } else if (this.isActivenTime === 5) {
              res.data.data.timeList.forEach((item) => {
                this.dataXvalue.push(
                  this.formatDate(item).split(" ")[0].split("-")[1]
                );
              });
            }
            res.data.data.valueList.forEach((item) => {
              if (item.code === "system_cop" || item.code === "host_cop") {
                this.COPValue = item.formatValue;
                this.COPName = item.name;
              }
              if (item.code === "cold") {
                this.coldValue = item.formatValue;
                this.coldName = item.name;
              }
              if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
                this.elecValue = item.formatValue;
                this.elecName = item.name;
              }
              if (item.code === "temp") {
                this.tempValue = item.formatValue;
                this.tempName = item.name;
              }
            });
          });
        }
      }
    );
  
    if(this.currentProjectId == 'jkjd'){
      this.OutdoorTemperature = '23.8'//室外温度
      this.OutdoorHumidity = '64'//室外湿度
      this.DewPointTemperature = '19.4'//露点温度
    }else if(this.currentProjectId == 'zgds'){
      this.OutdoorTemperature = '24.3'//室外温度
      this.OutdoorHumidity = '61'//室外湿度
      this.DewPointTemperature = '19.2'//露点温度
    }else if(this.currentProjectId == 'mgm' ){
      this.OutdoorTemperature = '28.2'//室外温度
      this.OutdoorHumidity = '70'//室外湿度
      this.DewPointTemperature = '21'//露点温度
    }else if(this.currentProjectId == 'wtzx'){
      this.OutdoorTemperature = '26.6'//室外温度
      this.OutdoorHumidity = '58'//室外湿度
      this.DewPointTemperature = '19.8'//露点温度
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getdataM(){
      const arr = [
        "hub.jkjd.HVAC_CHW.STemper",
        "hub.jkjd.HVAC_CHW.RTemper",
        "hub.jkjd.HVAC_OA.Hum",
        "hub.jkjd.HVAC_OA.Temp"
      ]
      getRealtime(arr).then((res) =>{
        if(res.data.code === 0){
          this.WaterSupplyTemperature = res.data.data["hub.jkjd.HVAC_CHW.STemper"]
          this.ReturnWaterTemperature = res.data.data["hub.jkjd.HVAC_CHW.RTemper"]
          this.OutdoorHumidity = res.data.data["hub.jkjd.HVAC_OA.Hum"]
          this.OutdoorTemperature = res.data.data["hub.jkjd.HVAC_OA.Temp"]
          this.DewPointTemperature = 27.3
        }
      })
    },
    clickCat(index) {
      this.dashboardValue = "";
      this.dashboardValueC = 0;
      this.isActivenCat = index;
      this.dataXvalue = [];
      this.COPValue = [];
      this.coldValue = [];
      this.elecValue = [];
      this.COPName = "";
      this.coldName = "";
      this.elecName = "";
      this.isShowPie = false;
      if (this.isActivenTime == 1) {
        this.timeSpan = "实时";
        this.isDisableDate = true;
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.params1 = {
          queryTypeEnum: "REALTIME",
        };
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          if(index == 2){
            this.dashboardValue = 5.4;
            this.dashboardValueC = 5.4;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
              console.log('1111',item.formatValue)
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
        this.timer = setInterval(() => {
          this.isShowPie = false;
          getColdDashboard(this.params1).then((res) => {
            console.log(res);
            if (res.data.data.formatValue === null) {
              this.dashboardValue = "--";
              this.dashboardValueC = 0;
            } else {
              this.dashboardValue = res.data.data.formatValue;
              this.dashboardValueC = res.data.data.formatValue;
            }
            if(index == 2){
            this.dashboardValue = 5.4;
            this.dashboardValueC = 5.4;
          }
            console.log("this.dashboardValue1", this.dashboardValue);
          });
          getColdPercent(this.params1).then((res) => {
            console.log(res);
            let temp = [];
            res.data.data.forEach((item) => {
              if (item.value === null) {
                temp.push(1);
              }
            });
            if (temp.length === res.data.data.length) {
              this.isShowPie = false;
            } else {
              this.isShowPie = true;
            }
            this.pieData = res.data.data;
          });
        }, 60 * 1000);
      } else if (this.isActivenTime == 2) {
        this.timeSpan = "累计";
        this.isDisableDate = true;
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.params1 = {
          queryTypeEnum: "ACCUM",
          startTime: "2021-03-23 00:00:00",
        };
        this.isShowPie = false;
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          if(index == 2){
            this.dashboardValue = 5.4;
            this.dashboardValueC = 5.4;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
               console.log('2222',item.formatValue)
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      } else if (this.isActivenTime == 3) {
        this.isDisableDate = false;
        this.changeDateType = "date";

        if (this.timer) {
          clearInterval(this.timer);
        }
        this.isShowPie = false;
        this.params1 = {
          queryTypeEnum: "DURATION",
          timeUnit: "DAYS",
          interval: 1,
          startTime: this.startTime1,
          endTime: this.endTime1,
        };
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          if(index == 2){
            this.dashboardValue = 5.4;
            this.dashboardValueC = 5.4;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              // this.COPValue = [
              //   5.2,5.6,5.3,5.3,5.5,5.8,
              //   5.2,5.6,5.3,5.3,5.5,5.8,
              //   5.2,5.6,5.3,5.3,5.5,5.8,
              //   5.2,5.6,5.3,5.3,5.5,5.8,
              // ];
              this.COPName = item.name;
            }
            if (item.code === "cold") {
               console.log('33333',item.formatValue)
              this.coldValue = item.formatValue;
              
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
               
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              
              this.tempName = item.name;
            }
          });
        });
      } else if (this.isActivenTime == 4) {
        this.isDisableDate = false;
        this.changeDateType = "month";
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.isShowPie = false;
        this.params1 = {
          queryTypeEnum: "DURATION",
          timeUnit: "MONTHS",
          interval: 1,
          startTime: this.startTime1,
          endTime: this.endTime1,
        };
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          if(index == 2){
            this.dashboardValue = 5.4;
            this.dashboardValueC = 5.4;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
               console.log('44444',item.formatValue)
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      } else if (this.isActivenTime == 5) {
        this.isDisableDate = false;
        this.changeDateType = "year";
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.params1 = {
          queryTypeEnum: "DURATION",
          timeUnit: "YEARS",
          interval: 1,
          startTime: this.startTime1,
          endTime: this.endTime1,
        };
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          if(index == 2){
            this.dashboardValue = 5.4;
            this.dashboardValueC = 5.4;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        this.isShowPie = false;
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
               console.log('55555',item.formatValue)
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      }
    },
    searchChart(v) {
      // debugger
      let params3 = new Date(v).getTime();
      getColdChart(params3).then((res) => {
        console.log(res.data.data.valueList);
        res.data.data.valueList.forEach((item) => {
          if (item.code === "system_cop" || item.code === "host_cop") {
            this.COPValue = item.formatValue;
            this.COPName = item.name;
          }
          if (item.code === "cold") {
            this.coldValue = item.formatValue;
            this.coldName = item.name;
          }
          if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
            this.elecValue = item.formatValue;
            this.elecName = item.name;
          }
          if (item.code === "temp") {
            this.tempValue = item.formatValue;
            this.tempName = item.name;
          }
        });
      });
    },
    changeDate1(v) {
      this.dashboardValue = "";
      this.dashboardValueC = 0;
      this.dataXvalue = [];
      this.COPValue = [];
      this.coldValue = [];
      this.elecValue = [];
      this.COPName = "";
      this.coldName = "";
      this.elecName = "";
      if (this.isActivenTime === 3) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        let dateT = new Date(v);
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        let tempDate = YY + MM + DD + " 00:00:00";
        this.timeSpan = YY + MM + DD;
        dateT = dateT.setDate(dateT.getDate() + 1);
        dateT = this.formatDate(dateT);
        this.params1 = {
          queryTypeEnum: "DURATION",
          timeUnit: "DAYS",
          interval: 1,
          startTime: tempDate,
          endTime: dateT,
        };
        this.startTime1 = this.params1.startTime;
        this.endTime1 = this.params1.endTime;
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        this.isShowPie = false;
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      } else if (this.isActivenTime === 4) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        let dateT = new Date(v);
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        let tempDate = YY + MM + DD + " 00:00:00";
        this.timeSpan =
          YY +
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1);
        dateT = dateT.setMonth(dateT.getMonth() + 1);
        dateT = this.formatDate(dateT);
        this.params1 = {
          queryTypeEnum: "DURATION",
          timeUnit: "MONTHS",
          interval: 1,
          startTime: tempDate,
          endTime: dateT,
        };
        this.startTime1 = this.params1.startTime;
        this.endTime1 = this.params1.endTime;
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        this.isShowPie = false;
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      } else if (this.isActivenTime === 5) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        let dateT = new Date(v);
        let YY = dateT.getFullYear() + "-";
        let MM = "01" + "-";
        let DD = "01";
        // if (YY == "2021-") {
        //   MM =
        //     (dateT.getMonth() + 1 < 10
        //       ? "0" + (dateT.getMonth() + 1)
        //       : dateT.getMonth() + 1) + "-";
        // }
        let tempDate = YY + MM + DD + " 00:00:00";
        this.timeSpan = dateT.getFullYear();
        dateT = dateT.setFullYear(dateT.getFullYear() + 1);
        dateT = this.formatDate(dateT);
        this.params1 = {
          queryTypeEnum: "DURATION",
          timeUnit: "YEARS",
          interval: 1,
          startTime: tempDate,
          endTime: dateT,
        };
        this.startTime1 = this.params1.startTime;
        this.endTime1 = this.params1.endTime;
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        this.isShowPie = false;
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      }
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    clickNH(index) {
      this.dashboardValue = "";
      this.dashboardValueC = 0;
      this.dataXvalue = [];
      this.COPValue = [];
      this.coldValue = [];
      this.elecValue = [];
      this.COPName = "";
      this.coldName = "";
      this.elecName = "";
      this.isActivenTime = index;
      if (index == 1) {
        this.timeSpan = "实时";
        this.isDisableDate = true;
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.params1 = {
          queryTypeEnum: "REALTIME",
        };
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        this.isShowPie = false;
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
        this.isShowPie = false;
        this.timer = setInterval(() => {
          getColdDashboard(this.params1).then((res) => {
            console.log(res);
            if (res.data.data.formatValue === null) {
              this.dashboardValue = "--";
              this.dashboardValueC = 0;
            } else {
              this.dashboardValue = res.data.data.formatValue;
              this.dashboardValueC = res.data.data.formatValue;
            }
            console.log("this.dashboardValue1", this.dashboardValue);
          });
          getColdPercent(this.params1).then((res) => {
            console.log(res);
            let temp = [];
            res.data.data.forEach((item) => {
              if (item.value === null) {
                temp.push(1);
              }
            });
            if (temp.length === res.data.data.length) {
              this.isShowPie = false;
            } else {
              this.isShowPie = true;
            }
            this.pieData = res.data.data;
          });
        }, 60 * 1000);
      } else if (index == 2) {
        this.timeSpan = "累计";
        this.isDisableDate = true;
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.isShowPie = false;
        this.params1 = {
          queryTypeEnum: "ACCUM",
          startTime: "2021-03-23 00:00:00",
        };
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      } else if (index == 3) {
        this.isDisableDate = false;
        this.changeDateType = "date";

        if (this.timer) {
          clearInterval(this.timer);
        }
        let dateT = new Date();
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        let tempDate = YY + MM + DD + " 00:00:00";
        this.timeSpan = YY + MM + DD;
        dateT = new Date(tempDate);
        dateT = dateT.setDate(dateT.getDate() + 1);
        dateT = this.formatDate(dateT);
        this.params1 = {
          queryTypeEnum: "DURATION",
          timeUnit: "DAYS",
          interval: 1,
          startTime: tempDate,
          endTime: dateT,
        };
        this.startTime1 = this.params1.startTime;
        this.endTime1 = this.params1.endTime;
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        this.isShowPie = false;
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      } else if (index == 4) {
        this.isDisableDate = false;
        this.changeDateType = "month";
        if (this.timer) {
          clearInterval(this.timer);
        }
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        let tempDate = YY + MM + DD + " 00:00:00";
        this.timeSpan =
          YY +
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1);
        dateT = new Date(tempDate);
        dateT = dateT.setMonth(dateT.getMonth() + 1);
        dateT = this.formatDate(dateT);
        this.params1 = {
          queryTypeEnum: "DURATION",
          timeUnit: "MONTHS",
          interval: 1,
          startTime: tempDate,
          endTime: dateT,
        };
        this.startTime1 = this.params1.startTime;
        this.endTime1 = this.params1.endTime;
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        this.isShowPie = false;
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      } else if (index == 5) {
        this.isDisableDate = false;
        this.changeDateType = "year";
        if (this.timer) {
          clearInterval(this.timer);
        }
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01" + "-";
        let DD = "01";
        // if (YY == "2021-") {
        //   MM =
        //     (dateT.getMonth() + 1 < 10
        //       ? "0" + (dateT.getMonth() + 1)
        //       : dateT.getMonth() + 1) + "-";
        // }
        let tempDate = YY + MM + DD + " 00:00:00";
        this.timeSpan = dateT.getFullYear();
        dateT = new Date(tempDate);
        dateT = dateT.setFullYear(dateT.getFullYear() + 1);
        dateT = this.formatDate(dateT);
        this.params1 = {
          queryTypeEnum: "DURATION",
          timeUnit: "YEARS",
          interval: 1,
          startTime: tempDate,
          endTime: dateT,
        };
        this.startTime1 = this.params1.startTime;
        this.endTime1 = this.params1.endTime;
        if (this.isActivenCat === 1) {
          this.params1.copType = "system_cop";
        } else {
          this.params1.copType = "host_cop";
        }
        this.isShowPie = false;
        getColdDashboard(this.params1).then((res) => {
          console.log(res);
          if (res.data.data.formatValue === null) {
            this.dashboardValue = "--";
            this.dashboardValueC = 0;
          } else {
            this.dashboardValue = res.data.data.formatValue;
            this.dashboardValueC = res.data.data.formatValue;
          }
          console.log("this.dashboardValue1", this.dashboardValue);
        });
        getColdPercent(this.params1).then((res) => {
          console.log(res);
          let temp = [];
          res.data.data.forEach((item) => {
            if (item.value === null) {
              temp.push(1);
            }
          });
          if (temp.length === res.data.data.length) {
            this.isShowPie = false;
          } else {
            this.isShowPie = true;
          }
          this.pieData = res.data.data;
        });
        getColdChart(this.params1).then((res) => {
          console.log(res.data.data.valueList);
          if (this.isActivenTime === 1) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 2) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          } else if (this.isActivenTime === 3) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else if (this.isActivenTime === 4) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1] +
                  "-" +
                  this.formatDate(item).split(" ")[0].split("-")[2]
              );
            });
          } else if (this.isActivenTime === 5) {
            res.data.data.timeList.forEach((item) => {
              this.dataXvalue.push(
                this.formatDate(item).split(" ")[0].split("-")[1]
              );
            });
          }
          res.data.data.valueList.forEach((item) => {
            if (item.code === "system_cop" || item.code === "host_cop") {
              this.COPValue = item.formatValue;
              this.COPName = item.name;
            }
            if (item.code === "cold") {
              this.coldValue = item.formatValue;
              this.coldName = item.name;
            }
            if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
              this.elecValue = item.formatValue;
              this.elecName = item.name;
            }
            if (item.code === "temp") {
              this.tempValue = item.formatValue;
              this.tempName = item.name;
            }
          });
        });
      }
    },
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
      currentProjectName: (state) => state.currentProjectName,
      projectArray: (state) => state.projectArray,
    }),
    chartMultiOption() {
      return {
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
        ],
        grid: {
          containLabel: true,
          top: "30px",
          left: "5px",
          right: "170px",
          bottom: "10px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        legend: {
          orient: "vertical",
          data: [this.COPName, this.coldName, this.elecName, this.tempName],
          right: "0",
          bottom: "20",
          textStyle: {
            color: "#082254",
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.dataXvalue,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: "耗能",
            name: "(kWh)",
            position: "left",
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "(°C)",
            position: "right",
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "(COP)",
            position: "right",
            offset: 50,
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: this.elecName,
            type: "bar",
            barWidth: this.changeZoom,
            itemStyle: {
              color: "#82C84A",
              borderRadius: 8,
            },
            data: this.elecValue,
          },
          {
            name: this.coldName,
            type: "bar",
            barWidth: this.changeZoom,
            itemStyle: {
              color: "#417AF3",
              borderRadius: 8,
            },
            data: this.coldValue,
          },
          // {
          //   name: "预测总用电量",
          //   type: "line",
          //   // yAxisIndex: 1,
          //   smooth: true,
          //   itemStyle: {
          //     color: "#41F39A",
          //   },
          //   areaStyle: {
          //     color: {
          //       type: "linear",
          //       x: 0,
          //       y: 0,
          //       x2: 0,
          //       y2: 1,
          //       colorStops: [
          //         {
          //           offset: 0,
          //           color: "#41F39A", // 0% 处的颜色
          //         },
          //         {
          //           offset: 1,
          //           color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
          //         },
          //       ],
          //       global: false, // 缺省为 false
          //     },
          //   },
          //   data: [
          //     2.6,
          //     5.9,
          //     9.0,
          //     26.4,
          //     28.7,
          //     70.7,
          //     175.6,
          //     182.2,
          //     48.7,
          //     18.8,
          //     6.0,
          //     2.3,
          //   ],
          // },
          {
            name: this.COPName,
            type: "line",
            yAxisIndex: 2,
            itemStyle: {
              color: "#E95B5B",
            },
            data: this.COPValue,
          },
          {
            name: this.tempName,
            type: "line",
            yAxisIndex: 1,
            itemStyle: {
              color: "#FAC858",
            },
            data: this.tempValue,
          },
        ],
      };
    },
    chartPieOption2() {
      return {
        color: ["#82C84A", "#E95B5B", "#417AF3", "#41CBF9", "#FAC858"],
        // center: ["10%", "50%"],
        grid: {
          // containLabel: true,
          // top: "10px",
          // left: "5px",
          // right: "10px",
          // bottom: "10px",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          bottom: 20,
          // data: [this.COPName, this.coldName, this.elecName, this.tempName],
          // bottom: 20,
          // data: data.legendData,
          // selected: data.selected,
          // textStyle: {
          //   color: "#082254",
          // },
          // data: [
            // {
            //   name: "冷冻主机",
            //   textStyle: {
            //     color: "#417AF3",
            //   },
            // },
            // {
            //   name: "冷却塔",
            //   textStyle: {
            //     color: "#82C84A",
            //   },
            // },

            // {
            //   name: "冷冻水二次泵",
            //   textStyle: {
            //     color: "#FAC858",
            //   },
            // },
            // {
            //   name: "冷冻水泵",
            //   textStyle: {
            //     color: "#41CBF9",
            //   },
            // },
            // {
            //   name: "冷却水泵",
            //   textStyle: {
            //     color: "#E95B5B",
            //   },
            // },
          // ],
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            // roseType: "radius",
            radius: "76%",
            center: ["35%", "45%"],
            avoidLabelOverlap: false,
            // itemStyle: {
            //   color: function (params) {
            //     // debugger
            //     var colorList = colorList_data_2;
            //     if (params.dataIndex === 3) {
            //       return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            //         {
            //           //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

            //           offset: 0,
            //           color: colorList[params.dataIndex].c1,
            //         },
            //         {
            //           offset: 1,
            //           color: colorList[params.dataIndex].c2,
            //         },
            //       ]);
            //     } else {
            //       return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            //         {
            //           //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

            //           offset: 0,
            //           color: colorList[params.dataIndex].c1,
            //         },
            //         {
            //           //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

            //           offset: 0.5,
            //           color: colorList[params.dataIndex].c2,
            //         },
            //         {
            //           offset: 1,
            //           color: colorList[params.dataIndex].c3,
            //         },
            //       ]);
            //     }
            //     /*  return colorList[params.dataIndex]*/
            //   },
            // },
            label: {
              position: "outside",
              // alignTo: "edge",
              formatter: (data) => {
                // console.log("data", data);
                // debugger
                // if (data.name === "冷冻主机") {
                //   return `{a|冷冻主机: ${data.percent}%}`;
                // } else if (data.name === "冷却塔") {
                //   return `{b|冷却塔: ${data.percent}%}`;
                // } else if (data.name === "冷冻水二次泵") {
                //   return `{c|冷冻水二次泵: ${data.percent}%}`;
                // } else if (data.name === "冷冻水泵") {
                //   return `{d|冷冻水泵: ${data.percent}%}`;
                // } else if (data.name === "冷却水泵") {
                //   return `{e|冷却水泵: ${data.percent}%}`;
                // }
                return `${data.name}: ${data.percent}%`;
                // const rowArr = [];
                // data.forEach(() => {
                // const value = this.avaliableTwo(v.data.value[1]);
                // if (value) {
                //   if (v.data.itemStyle) {
                //     rowArr.push(
                //       `<span style="color:red;">单耗: ${value} </span>`
                //     );
                //   } else {
                //     rowArr.push(`<span style="">单耗: ${value} </span>`);
                //   }
                // }
                // });
                // rowArr.push(`记录时间：${this.formatToMin(data[0].axisValue)}`);
                // return rowArr.join("<br/>");
              },
              // minMargin: 5,
              // edgeDistance: 10,
              // lineHeight: 15,
              rich: {
                a: {
                  fontSize: 14,
                  color: "#417AF3",
                  // fontWeight: "bold",
                },
                b: {
                  fontSize: 14,
                  color: "#82C84A",
                  // fontWeight: "bold",
                },
                c: {
                  fontSize: 14,
                  color: "#FAC858",
                  // fontWeight: "bold",
                },
                d: {
                  fontSize: 14,
                  color: "#41CBF9",
                  // fontWeight: "bold",
                },
                e: {
                  fontSize: 14,
                  color: "#E95B5B",
                  // fontWeight: "bold",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length2: 20,
              // smooth: true,
              // minTurnAngle: 50,
            },
            data: this.pieData,
          },
        ],
      };
    },
    chartPieOption1() {
      return {
        series: [
          {
            type: "gauge",
            // startAngle: 180,
            // endAngle: 0,
            radius: "100%",
            center: ["50%", "50%"],
            min: 0,
            max: 10,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                width: 0,
                color: [
                  [0.1, "#F97375"],
                  [0.2, "#D27190"],
                  [0.3, "#B871A4"],
                  // [0.4, "#816FC9"],
                  [0.7, "#406DF8"],

                  [0.8, "#B871A4"],
                  [0.9, "#D27190"],
                  [1, "#F97375"],
                ],
              },
            },
            pointer: {
              // icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
              length: "75%",
              // width: 20,
              // offsetCenter: [0, "-60%"],
              itemStyle: {
                color: "#406DF8",
              },
            },
            axisTick: {
              length: 12,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: "auto",
                width: 3,
              },
            },
            progress: {
              show: false,
            },
            axisLabel: {
              // show:false,
              color: "#3F6DF8",
              fontSize: 16,
              // distance: -60,
              // formatter: function (value) {
              //   if (value === 0.875) {
              //     return "优";
              //   } else if (value === 0.625) {
              //     return "中";
              //   } else if (value === 0.375) {
              //     return "良";
              //   } else if (value === 0.125) {
              //     return "差";
              //   }
              // },
            },
            title: {
              // offsetCenter: [0, "-20%"],
              // fontSize: 30,
            },
            detail: {
              fontSize: 40,
              offsetCenter: [0, "70%"],
              valueAnimation: true,
              formatter: (value) => {
                // debugger
                // console.log("this.dashboardValue",this.dashboardValue)
                if (this.dashboardValue === "--") {
                  return "--";
                } else {
                  return value;
                }
              },
              color: "#3F6DF8",
            },
            data: [
              {
                value: this.dashboardValueC,
                // name: "成绩评定",
              },
            ],
          },
        ],
      };
    },
  },
  watch: {
    isActivenTime(val) {
      console.log(val);
      if (val === 5) {
        this.changeZoom = "auto";
      } else {
        this.changeZoom = 16;
      }
    },
    currentProjectId(val) {
      // debugger
      console.log('名称：',val);
      this.dashboardValue = "--";
      this.dashboardValueC = 0;
      this.isShowPie = false;
      this.pieData = [];
      this.dataXvalue = [];
      this.COPValue = "";
      this.COPName = [];
      this.coldValue = "";
      this.coldName = [];
      this.elecValue = "";
      this.elecName = [];
      this.tempValue = "";
      this.tempName = [];
      setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
        (res2) => {
          if (res2.data.code === 0) {
            if(val == 'jkjd'){
              let data = [
                "linkgap.jkjd.HVAC_JSQ.Temp",
                "linkgap.jkjd.HVAC_FSQ.Temp",
              ];
              getMonitorRealtime(data).then((res) => {
                console.log('金科酒店供水回水温度：',res)
                if(res.data.code == 0){
                  this.WaterSupplyTemperature = Number(res.data.data['linkgap.jkjd.HVAC_FSQ.Temp']).toFixed(1)
                  this.ReturnWaterTemperature = Number(res.data.data['linkgap.jkjd.HVAC_JSQ.Temp']).toFixed(1)
                }
              });
            }else if(val == 'zgds'){
              let data = [
                "linkgap.zgds.HVAC_JSQ.Temp",
                "linkgap.zgds.HVAC_FSQ.Temp",
              ];
              getMonitorRealtime(data).then((res) => {
                console.log('紫光大厦供水回水温度：',res)
                if(res.data.code == 0){
                  this.WaterSupplyTemperature = Number(res.data.data['linkgap.zgds.HVAC_FSQ.Temp']).toFixed(1)
                  this.ReturnWaterTemperature = Number(res.data.data['linkgap.zgds.HVAC_JSQ.Temp']).toFixed(1)
                }
              });
            }else if(val == 'mgm'){
              let data = [
                "linkgap.mgm.CS.Temp",
                "linkgap.mgm.CR.Temp",
              ];
              getMonitorRealtime(data).then((res) => {
                console.log('美高梅供水回水温度：',res)
                if(res.data.code == 0){
                  this.WaterSupplyTemperature = Number(res.data.data['linkgap.mgm.CS.Temp']).toFixed(1)
                  this.ReturnWaterTemperature = Number(res.data.data['linkgap.mgm.CR.Temp']).toFixed(1)
                }
              });
            }else if(val == 'wtzx'){
              let data = [
                "linkgap.wtzx.HVAC_JSQ.Temp",
                "linkgap.wtzx.HVAC_FSQ.Temp",
              ];
              getMonitorRealtime(data).then((res) => {
                console.log('文体中心供水回水温度：',res)
                if(res.data.code == 0){
                  this.WaterSupplyTemperature = Number(res.data.data['linkgap.wtzx.HVAC_FSQ.Temp']).toFixed(1)
                  this.ReturnWaterTemperature = Number(res.data.data['linkgap.wtzx.HVAC_JSQ.Temp']).toFixed(1)
                }
              });
            }
            getColdDashboard(this.params1).then((res) => {
              console.log(res);
              if (res.data.data.formatValue === null) {
                this.dashboardValue = "--";
                this.dashboardValueC = 0;
              } else {
                this.dashboardValue = res.data.data.formatValue;
                this.dashboardValueC = res.data.data.formatValue;
              }
              console.log("this.dashboardValue1", this.dashboardValue);
            });
            getColdPercent(this.params1).then((res) => {
              console.log(res);
              let temp = [];
              res.data.data.forEach((item) => {
                if (item.value === null) {
                  temp.push(1);
                }
              });
              if (temp.length === res.data.data.length) {
                this.isShowPie = false;
              } else {
                this.isShowPie = true;
              }
              this.pieData = res.data.data;
            });
            getColdChart(this.params1).then((res) => {
              console.log(res.data.data.valueList);
              if (this.isActivenTime === 1) {
                res.data.data.timeList.forEach((item) => {
                  this.dataXvalue.push(
                    this.formatDate(item).split(" ")[1].split(":")[0] +
                      ":" +
                      this.formatDate(item).split(" ")[1].split(":")[1]
                  );
                });
              } else if (this.isActivenTime === 2) {
                res.data.data.timeList.forEach((item) => {
                  this.dataXvalue.push(
                    this.formatDate(item).split(" ")[0].split("-")[1]
                  );
                });
              } else if (this.isActivenTime === 3) {
                res.data.data.timeList.forEach((item) => {
                  this.dataXvalue.push(
                    this.formatDate(item).split(" ")[1].split(":")[0] +
                      ":" +
                      this.formatDate(item).split(" ")[1].split(":")[1]
                  );
                });
              } else if (this.isActivenTime === 4) {
                res.data.data.timeList.forEach((item) => {
                  this.dataXvalue.push(
                    this.formatDate(item).split(" ")[0].split("-")[1] +
                      "-" +
                      this.formatDate(item).split(" ")[0].split("-")[2]
                  );
                });
              } else if (this.isActivenTime === 5) {
                res.data.data.timeList.forEach((item) => {
                  this.dataXvalue.push(
                    this.formatDate(item).split(" ")[0].split("-")[1]
                  );
                });
              }
              res.data.data.valueList.forEach((item) => {
                if (item.code === "system_cop" || item.code === "host_cop") {
                  this.COPValue = item.formatValue;
                  this.COPName = item.name;
                }
                if (item.code === "cold") {
                  this.coldValue = item.formatValue;
                  this.coldName = item.name;
                }
                if (item.code === "Ep_imp" || item.code === "host_Ep_imp") {
                  this.elecValue = item.formatValue;
                  this.elecName = item.name;
                }
                if (item.code === "temp") {
                  this.tempValue = item.formatValue;
                  this.tempName = item.name;
                }
              });
            });
          }
        }
      );
      if(val == 'jkjd'){
        this.OutdoorTemperature = '23.8'//室外温度
        this.OutdoorHumidity = '64'//室外湿度
        this.DewPointTemperature = '19.4'//露点温度

      }else if(val == 'zgds'){
        this.OutdoorTemperature = '24.3'//室外温度
        this.OutdoorHumidity = '61'//室外湿度
        this.DewPointTemperature = '19.2'//露点温度

      }else if(val == 'mgm' ){
        this.OutdoorTemperature = '28.2'//室外温度
        this.OutdoorHumidity = '70'//室外湿度
        this.DewPointTemperature = '21'//露点温度

      }else if(val == 'wtzx'){
        this.OutdoorTemperature = '26.6'//室外温度
        this.OutdoorHumidity = '58'//室外湿度
        this.DewPointTemperature = '19.8'//露点温度

      }
    },
  },
};
</script>

<style scoped>
.sp-class >>> input {
  width: 180px !important;
  height: 29px !important;
}
.sp-class >>> .el-input__icon {
  line-height: 29px !important;
}
.date >>> input {
  width: 180px !important;
  height: 29px !important;
}
.date >>> .el-input__icon {
  line-height: 29px !important;
}
.title1 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  /* background: linear-gradient(135deg, #f6b084 0%, #ea9b7a 46%, #e97f75 100%); */
  border-radius: 4px;
  position: relative;
  background: white;
}
.title2 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  /* background: linear-gradient(135deg, #71a1de 0%, #5a82dd 46%, #516adc 100%); */
  border-radius: 4px;
  position: relative;
  background: white;
}
.title3 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  /* background: linear-gradient(135deg, #b97df9 0%, #ad6bf1 54%, #8c44e1 100%); */
  border-radius: 4px;
  position: relative;
  background: white;
}
.title4 {
  padding-left: 10px;
  padding-right: 30px;
  margin-left: 10px;
  width: 24%;
  height: 100%;
  /* background: linear-gradient(135deg, #6bcf84 0%, #6ec887 46%, #2aab4c 100%); */
  border-radius: 4px;
  position: relative;
  background: white;
}

.body1 {
  width: 50%;
  height: 100%;
  background: white;
  position: relative;
}
.body2 {
  width: 50%;
  height: 100%;
  background: white;
  position: relative;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.square2-categorys {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  /* border-right: none; */
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
</style>