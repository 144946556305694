<template>
  <div class="workshopMonitor">
    <div class="center">
      <img class="bg-line" src="~@/assets/workshopMonitor/circle.webp" alt="" />
      <!-- <img
        class="arrow-item arrow-item1-1"
        src="~@/assets/workshopMonitor/arrow.webp"
        alt=""
      />
      <img
        class="arrow-item arrow-item1-2"
        src="~@/assets/workshopMonitor/arrow.webp"
        alt=""
      /> -->
      <div class="title-bg">挤压车间</div>
    </div>
    <div class="day-list">
      <div class="title-name">
        <img src="~@/assets/workshopMonitor/day-title.png" alt="" />
        当日产量排行
      </div>
      <div class="day-item" v-for="(item, index) in dayList" :key="index">
        <div class="item-index">{{ index + 1 }}</div>
        <div class="item-body">
          <div class="item-name">{{ item.name }}</div>
          <div class="item-num">
            <div
              class="item-value"
              :style="{ width: (item.number * 100) / numSum + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="census-list">
      <div class="title-name">
        <img src="~@/assets/workshopMonitor/day-title.png" alt="" />
        机台统计
      </div>
      <div class="census-item">
        <div class="census-circle"></div>
        <div class="census-value">总机：{{ jitaiList.length }}台</div>
      </div>
      <div class="census-item" v-for="item in censusList" :key="item.name">
        <div class="census-circle"></div>
        <div class="census-value">{{ item.name }}：{{ item.value }}台</div>
      </div>
    </div>
    <div class="jitai-list">
      <div
        class="jitai-item"
        v-for="(item, index) in jitaiList"
        :key="item.name"
        :class="[
          'jitai-item' + (index + 1),
          'jitai-item-status-' + item.status,
        ]"
        @mouseover="changeShow(item, index)"
        @mouseleave="showIndex = -1"
        @click="goEdit(item)"
      >
        <div class="jitai-num">
          <div class="num-title">{{ item.name }}</div>
          <div class="num-value" v-if="item.status === 0 && item.number > 0">
            当日产量:<span>{{ item.number.toFixed(2) }}</span> t <br />
            实时功率:<span>{{ 35 }}</span> kw
          </div>
          <div class="num-status" :class="'num-status' + item.status">
            <div class="num-circle"></div>
            {{
              item.status === 0 ? "生产" : item.status === 1 ? "离线" : "停产"
            }}
          </div>
          <div class="router" v-show="showIndex === index">
            <span>进入详情</span>
            <img src="~@/assets/workshopMonitor/router-icon.png" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="jitai-item jitai-item2"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      // status 0生产 1离线 2停产
      dayList: [],
      censusListSum: 0,
      numSum: 0,
      showIndex: -1,
      censusList: [
        {
          name: "生产",
          value: 0,
        },
        {
          name: "离线",
          value: 0,
        },
        {
          name: "停产",
          value: 0,
        },
      ],
      jitaiList: [
        {
          name: "H27501T挤压机台",
          number: 1.5 * 24,
          status: 0,
        },
        {
          name: "2#机台",
          number: 0,
          status: 1,
        },
        {
          name: "3#机台",
          number: 0,
          status: 1,
        },
        {
          name: "4#机台",
          number: 0,
          status: 1,
        },
        {
          name: "5#机台",
          number: 0,
          status: 1,
        },
        {
          name: "6#机台",
          number: 0,
          status: 1,
        },
        {
          name: "7#机台",
          number: 0,
          status: 1,
        },
        {
          name: "8#机台",
          number: 0,
          status: 1,
        },
      ],
    };
  },
  components: {},
  methods: {
    goEdit(item) {
      if (item.status == 1) {
        this.$message({
          message: "机台离线中！",
          type: "warning",
        });
      } else {
        this.$router.push({ name: "actualTimeWorkshopMonitor2" });
      }
    },
    changeShow(item, index) {
      if (item.status != 1) {
        this.showIndex = index;
      }
    },
  },
  mounted() {
    let numSum = 0;
    this.jitaiList.forEach((element) => {
      numSum += (element.number || 0) - 0;
      this.censusList[element.status].value += 1;
    });
    this.numSum = numSum;
    let jitaiList = [...this.jitaiList];
    this.dayList = jitaiList.sort((a, b) => {
      return b.number - a.number;
    });
  },
};
</script>

<style lang="less" scoped>
.workshopMonitor {
  background: url("~@/assets/workshopMonitor/bg.png");
  height: 100%;
  background-size: 100% 100%;
  position: relative;
  // border: 1px solid transparent;
  overflow: hidden;
  .center {
    width: 348px;
    height: 279px;
    position: absolute;
    bottom: 30%;
    left: 40%;
    .bg-line {
      width: 100%;
      position: absolute;
      z-index: 3;
    }
    .title-bg {
      position: absolute;
      width: 240px;
      height: 71px;
      left: 61px;
      top: -35px;
      background: url("~@/assets/workshopMonitor/title-bg.png");
      background-size: 100% 100%;
      font-size: 24px;
      font-weight: 800;
      color: #ffffff;
      line-height: 71px;
      text-align: center;
      z-index: 2;
    }
  }
  .arrow-item {
    position: absolute;
    left: 40%;
    bottom: 30%;
    &.arrow-item1-1 {
      left: -40%;
      top: 73%;
    }
    &.arrow-item1-2 {
      left: -32%;
      top: 78%;
    }
    &.arrow-item1-3 {
      left: -26%;
      top: 83%;
    }
    &.arrow-item1-4 {
      left: -19%;
      top: 90%;
    }
  }
  .day-list {
    margin: 10px 11px;
    background: linear-gradient(
      270deg,
      rgba(1, 2, 98, 0) 0%,
      rgba(0, 1, 34, 0.18) 28%,
      rgba(1, 55, 200, 0.72) 100%
    );
    width: 200px;
    padding-bottom: 10px;
    .day-item:nth-child(2) .item-index {
      background-color: #b59e27;
    }
    .day-item:nth-child(3) .item-index {
      background-color: #727355;
    }
    .day-item:nth-child(4) .item-index {
      background-color: #5e6862;
    }
    .day-item {
      display: flex;
      padding-left: 14px;
      margin-bottom: 9px;
      .item-index {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        margin-right: 7px;
        background-color: #1f4195;
        color: #f9f9f9;
        text-align: center;
        line-height: 22px;
        flex-shrink: 0;
      }
      .item-name {
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
      }
      .item-num {
        width: 140px;
        height: 8px;
        border-radius: 4px;
        border: 1px solid #415993;
        position: relative;
        .item-value {
          width: 80%;
          height: 100%;
          background: linear-gradient(
            270deg,
            #55ffbc 0%,
            rgba(63, 109, 248, 0.35) 100%
          );
          border-radius: 4px;
        }
      }
    }
  }
  .title-name {
    display: flex;
    background: url("~@/assets/workshopMonitor/day-bg.png") no-repeat left;
    background-size: 100% 100%;
    line-height: 34px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 12px;
    padding: 0 5px;
    box-sizing: content-box;
    > img {
      height: 34px;
    }
  }
  .census-list {
    width: 180px;
    background: linear-gradient(
      270deg,
      rgba(1, 2, 98, 0) 0%,
      rgba(0, 1, 34, 0.18) 28%,
      rgba(1, 55, 200, 0.72) 100%
    );
    padding-bottom: 10px;
    position: absolute;
    bottom: 10px;
    left: 10px;

    .census-item:nth-child(3) .census-circle {
      background-color: #30fff7;
    }
    .census-item:nth-child(4) .census-circle {
      background-color: #d39a21;
    }
    .census-item:nth-child(5) .census-circle {
      background-color: #f8f8f8;
    }
    .census-item {
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin-bottom: 6px;
      .census-circle {
        width: 8px;
        height: 8px;
        box-shadow: 0px 0px 8px 0px #f8f8f8;
        border: 1px solid rgba(252, 252, 252, 0.89);
        margin-right: 10px;
        border-radius: 50%;
      }
      .census-value {
        color: #fff;
        font-size: 14px;
      }
    }
  }
  .jitai-item {
    width: 202px;
    height: 148px;
    background: url("~@/assets/workshopMonitor/jitai.png") no-repeat left;
    background-size: 100% 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    &.jitai-item-status-0 {
      background: url("~@/assets/workshopMonitor/jitai.webp") no-repeat left;
      background-size: 100% 100%;
    }
    &.jitai-item1 {
      left: 7%;
      top: 43%;
      .jitai-num {
        right: -26%;
        top: -65%;
      }
    }
    &.jitai-item2 {
      left: 21%;
      top: 32%;
    }
    &.jitai-item3 {
      left: 32%;
      top: 21%;
    }
    &.jitai-item4 {
      left: 48%;
      top: 7%;
      .jitai-num {
        left: 50%;
        bottom: 75%;
      }
    }
    &.jitai-item5 {
      left: 78%;
      top: 25%;
    }
    &.jitai-item6 {
      left: 82%;
      top: 58%;
    }
    &.jitai-item7 {
      left: 70%;
      top: 71%;
    }
    &.jitai-item8 {
      left: 56%;
      top: 81%;
    }
    .jitai-num {
      width: 170px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 100%;
      z-index: 2;
      .router {
        width: 160px;
        height: 60px;
        background: rgba(3, 7, 71, 0.9);
        border: 2px solid #2ce0ff;
        position: absolute;
        top: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          margin-right: 8px;
        }
        transition: all 1s;
      }
      .num-title {
        width: 100%;
        height: 33px;
        line-height: 33px;
        background: linear-gradient(
          270deg,
          rgba(0, 102, 255, 0) 0%,
          #0b4cff 87%,
          rgba(11, 61, 214, 0) 100%
        );
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        color: #ffffff;
        text-shadow: 0px 0px 8px #2765fb;
      }
      .num-value {
        width: 100%;
        background: linear-gradient(
          270deg,
          rgba(0, 63, 158, 0) 0%,
          rgba(0, 30, 113, 0.89) 86%,
          rgba(1, 40, 157, 0) 100%
        );
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        line-height: 30px;
        margin-top: 5px;
        text-align: left;
        padding-left: 25px;
        letter-spacing: 0.5px;
        span {
          width: 47px;
          height: 28px;
          font-size: 16px;
          font-weight: 500;
          color: #f8e71c;
          line-height: 28px;
        }
      }
      .num-status {
        border-radius: 11px;
        font-size: 14px;
        padding: 0 5px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .num-circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 4px;
        }
        &.num-status0 {
          border: 1px solid #2fcde8;
          color: #2fcde8;
          .num-circle {
            background: #2fcde8;
            box-shadow: 0px 0px 8px 0px #2fcde8;
            border: 1px solid #c1f2f0;
          }
        }
        &.num-status1 {
          border: 1px solid #919cbc;
          color: #919cbc;
          .num-circle {
            background: #919cbc;
            box-shadow: 0px 0px 8px 0px #f8f8f8;
            border: 1px solid #919cbc;
          }
        }
        &.num-status2 {
          border: 1px solid #fff;
          color: #fff;
          .num-circle {
            background: #d38b21;
            box-shadow: 0px 0px 8px 0px rgba(255, 240, 202, 0.89);
            border: 1px solid #fff0ca;
          }
        }
      }
    }
  }
}
</style>
