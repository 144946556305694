<template>
  <div
    style="
      width: 100%;
      height: 100%;
      background: transparent;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      overflow: auto;
    "
  >
    <div
      style="
        height: 100%;
        width: 250px;
        flex: none;
        border-right: 1px solid rgba(8, 34, 84, 0.1);
        overflow: hidden;
        position: relative;
        background: white;

        padding-top: 10px;
      "
    >
      <div
        style="
          width: 100%;
          height: 40px;
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div style="font-size: 16px; font-weight: 600; color: #082254">
          角色列表
        </div>
        <div
          style="
            width: 72px;
            height: 32px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
            line-height: 32px;
            text-align: center;
          "
          @click="toAdd"
        >
          新增
        </div>
      </div>

      <div
        style="
          width: 100%;
          overflow: auto;
          height: 550px;
          margin-top: 50px;
          padding-right: 10px;
        "
      >
        <div
          v-for="(item, index) in tableData"
          :key="index"
          style="
            width: 100%;
            height: 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #082254;
            cursor: pointer;
          "
          @click="clickLeft(item, index)"
        >
          <div
            style="
              color: #082254;
              width: 90%;
              flex: none;
              height: 100%;
              line-height: 32px;
              padding-left: 20px;
            "
            :class="{ isClick: currentLeftId === item.roleId }"
          >
            {{ item.roleName }}
          </div>
          <div style="flex: 1" v-if="item.updated">
            <el-dropdown trigger="click" @command="handleCommand">
              <i class="el-icon-more-outline"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{
                    command: 'a',
                    item: item,
                  }"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{
                    command: 'b',
                    item: item,
                  }"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div style="flex: 1; padding: 20px; overflow: hidden; background: white">
      <div
        style="
          font-size: 16px;
          font-weight: 600;
          color: #082254;
          height: 22px;
          line-height: 22px;
          padding-left: 10px;
        "
      >
        权限列表
      </div>
      <div style="overflow: auto; width: 100%;height:90% ">
        <el-tree
          ref="eltree"
          style="width: 100%; height: 100%; overflow: auto"
          :data="dataTree"
          show-checkbox
          :check-on-click-node="isClickOncheck"
          node-key="menuId"
          :props="defaultProps"
          @check="validateCheck"
          :default-expanded-keys="defaultExpandArray"
        >
        </el-tree>
        <!--:check-strictly="isCheckStrict" @node-click="getTreeAttr" -->
      </div>
      <div style="width: 100%">
        <div
          style="
            width: 70px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #3f6df8;
            color: white;
            margin: 0 auto;
            cursor: pointer;
            margin-top:20px
          "
          @click="saveRole"
          v-if="isshowBTN"
        >
          保存
        </div>
      </div>
    </div>

    <el-dialog
      title="角色设置"
      :visible.sync="changePasswordVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item
          label="角色名称："
          prop="roleName"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input :disabled="isView" v-model="ruleForm.roleName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addNew">确 定</el-button>
        <el-button @click="changePasswordVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import formList from "./list";
import {
  roleMenuSave,
  roleSearch,
  roleMenu,
  roleAdd,
  roleEdit,
  // alarmSetUpdate,
  // alarmSetCheck,
  alarmSearchSet,
  alarmSetGet,
  // alarmSetLevel,
  bindInstanceTree,
  getInstanceTree,
  alarmSetDel,
  roleDelete,
  // reportManageSearch,
  // getReportType,
  // getReportTypeParams,
  // reportManageAdd,
  reportManagechangeStatus,
  // reportManagedeatil,
  // reportManageupdate,
  // reportManageDelete,
  // getInstance,
  getInstanceAttr,
} from "@/request/apiLg";
export default {
  // components: {
  //   formList,
  // },
  filters: {
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
  },
  data() {
    return {
      optionsSSS: [
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      optionsSS: [],
      optionsS: [
        {
          value: "ALL",
          label: "全部",
        },
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      isEdit: false,
      isView: false,
      testList: [
        {
          nodeName: "",
          show: true,
          childNodeList: [],
        },
      ],
      // testList: [
      //   {
      //     childNodeList: [
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             instanceId: "",
      //             nodeName: "二级节点",
      //             show: false,
      //           },
      //         ],

      //         nodeName: "一级节点",
      //         show: true,
      //       },
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             nodeName: "二级节点1",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点1",
      //         show: true,
      //       },
      //     ],
      //     // instanceId: "root",
      //     nodeName: "根节点",
      //     show: true,
      //   },
      //   {
      //     childNodeList: [
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             // instanceId: "second",
      //             nodeName: "二级节点",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点",
      //         show: true,
      //       },
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             // instanceId: "second",
      //             nodeName: "二级节点1",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点1",
      //         show: true,
      //       },
      //     ],
      //     // instanceId: "root",
      //     nodeName: "根节点",
      //     show: true,
      //   },
      // ],
      listTemp: [
        {
          name: 1,
          children: [
            {
              name: 2,
              children: [
                {
                  name: 3,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      changeTreeVisible: false,
      optionsInstance: [],
      optionsInstanceAttr: [],
      optionsSourceType: [
        {
          name: "展示节点",
          code: true,
        },
        {
          name: "具体仪表",
          code: false,
        },
      ],
      dateV: 1,
      optionsDate: [
        {
          name: "1日",
          code: 1,
        },
        {
          name: "2日",
          code: 2,
        },
        {
          name: "3日",
          code: 3,
        },
        {
          name: "4日",
          code: 4,
        },
        {
          name: "5日",
          code: 5,
        },

        {
          name: "6日",
          code: 6,
        },
        {
          name: "7日",
          code: 7,
        },
        {
          name: "8日",
          code: 8,
        },
        {
          name: "9日",
          code: 9,
        },
        {
          name: "10日",
          code: 10,
        },
        {
          name: "11日",
          code: 11,
        },
        {
          name: "12日",
          code: 12,
        },
        {
          name: "13日",
          code: 13,
        },
        {
          name: "14日",
          code: 14,
        },
        {
          name: "15日",
          code: 15,
        },
        {
          name: "16日",
          code: 16,
        },
        {
          name: "17日",
          code: 17,
        },
        {
          name: "18日",
          code: 18,
        },
        {
          name: "19日",
          code: 19,
        },
        {
          name: "20日",
          code: 20,
        },
        {
          name: "21日",
          code: 21,
        },
        {
          name: "22日",
          code: 22,
        },
        {
          name: "23日",
          code: 23,
        },
        {
          name: "24日",
          code: 24,
        },
        {
          name: "25日",
          code: 25,
        },
        {
          name: "26日",
          code: 26,
        },
        {
          name: "27日",
          code: 27,
        },
        {
          name: "28日",
          code: 28,
        },
        {
          name: "29日",
          code: 29,
        },
        {
          name: "30日",
          code: 30,
        },
        {
          name: "31日",
          code: 31,
        },
      ],
      currentLeftId: "",
      selectTimeV21: 1,
      selectTimeV22: 0,
      isShowMonth: false,
      selectTimeV: 0,
      timeArray: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      timeArray2: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      treeReportId: "",
      isShowDay: false,
      options1: [],
      options2: [[]],
      rules: {
        banner: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        activeName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
      },
      ruleForm: {
        roleName: "",
        // // alarmId: "",
        // alarmLevelId: "",
        // alarmLevelName: "",
        // alarmType: "",
        // attrId: "",
        // attrName: "",
        // instanceId: "",
        // instanceName: "",
        // maxDisplay: 0,
        // minDisplay: 0,
        // // periodMinute: "",
        // released: false,
      },
      treeForm: {
        treeName: "",
        treeArray: [1],
      },
      changePasswordVisible: false,
      total: 0,
      loading: true,
      value11: "",
      value22: "",
      currentName: "",
      isInterval: false,
      value2: [],
      tableKey: [],
      tableKey2: [],
      isborder1: false,
      tableData: [],
      changeDateType: "date",
      selectValueX: "",
      isShowSelectx: false,
      selectValueY: "",
      isShowSelecty: false,
      isClickOncheck: true,
      defaultExpandArray: [],
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      dataTree: [],
      // options1: [
      //   {
      //     value: "P",
      //     label: "电能-有功功率",
      //   },
      // ],
      // options2: [
      //   {
      //     value: "P",
      //     label: "水能-瞬时流量",
      //   },
      // ],
      value1: "",
      // value2: "",
      isActivenTime: 1,
      query: {
        limit: 10,
        page: 1,
        alarmType: "ALL",
        instanceName: "",
      },
      isshowBTN: false,
    };
  },
  mounted() {
    // let dateT = new Date();
    // var YY = dateT.getFullYear() + "-";
    // var MM =
    //   (dateT.getMonth() + 1 < 10
    //     ? "0" + (dateT.getMonth() + 1)
    //     : dateT.getMonth() + 1) + "-";
    // var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    // this.value1 = YY + MM + DD;
    roleSearch({
      limit: 100000,
      page: 1,
      roleName: "",
    }).then((res2) => {
      this.loading = false;
      console.log(res2);
      this.tableData = res2.data.data;
      this.total = res2.data.count;
      this.currentLeftId = res2.data.data[0].roleId;
      roleMenu(res2.data.data[0].roleId).then((res) => {
        console.log(res);
        if (!res2.data.data[0].updated) {
          this.isshowBTN = false;
          res.data.data.forEach((item) => {
            item.disabled = true;
            if (item.children.length > 0) {
              item.children.forEach((item1) => {
                item1.disabled = true;
              });
            }
          });
        } else {
          this.isshowBTN = true;
        }
        this.dataTree = res.data.data;
        let temp = [];
        this.dataTree.forEach((item) => {
          if (item.children.length === 0) {
            if (item.selected) {
              temp.push(item.menuId);
            }
          }
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              if (item2.selected) {
                temp.push(item2.menuId);
              }
            });
          }
        });
        this.$nextTick(() => {
          this.$refs.eltree.setCheckedKeys(temp);
        });
      });
      // this.tableKey = res2.data.data.timestamps;
    });
    // getReportType().then((res) => {
    //   this.options1 = res.data.data;
    //   getReportTypeParams(this.options1[0].code).then((res2) => {
    //     this.options2[0] = res2.data.data;
    //   });
    // });
    // alarmSetLevel().then((res) => {
    //   console.log(res.data.data);
    //   this.optionsSS = res.data.data;
    //   // this.mutationsGetInstance(res.data.data);
    // });
    // getInstance().then((res) => {
    //   this.optionsInstance = res.data.data;
    //   // getInstanceAttr(this.optionsInstance[0].instanceId).then((res1) => {
    //   //   this.optionsInstanceAttr = res1.data.data;
    //   // });
    // });
  },
  computed: {
    ...mapState({
      insatnceArr: (state) => state.insatnceArr,
    }),
  },
  methods: {
    saveRole() {
      let temp = {};
      let tempA = [];
      let tempB = JSON.parse(JSON.stringify(this.dataTree));
      temp.roleId = this.currentLeftId;
      console.log("this.dataTree", this.dataTree);
      tempB.forEach((item) => {
        // debugger
        if (item.selected) {
          tempA.push(item);
          // console.log("tempA", tempA);
          // tempA.forEach((item1) => {
          //   item1.children = [];
          // });

          // if (item.children.length > 0) {
          //   console.log("tempA", tempA);
          //   item.children.forEach((item2) => {
          //     if (item2.selected) {
          //       // tempA[index].children.push(item2);
          //       tempA.forEach((item1) => {
          //         if (item1.menuId === item.menuId) {
          //           item1.children.push(item2);
          //         }
          //       });
          //     }
          //   });
          // }
        }
      });

      // console.log("tempc", tempc);

      let tempc = JSON.parse(JSON.stringify(tempA));
      tempA.forEach((item) => {
        item.children = [];
      });
      tempc.forEach((item, index) => {
        if (item.children.length > 0) {
          item.children.forEach((item2) => {
            if (item2.selected) {
              tempA[index].children.push(item2);
            }
          });
        }
      });
      console.log("tempA", tempA);
      console.log("tempc", tempc);
      // tempA.forEach((item,index)=>{

      // })
      // let tempc = []
      // tempA.forEach((item,index)=>{
      //   tempc.push(item)
      //   // tempc[index].children = []
      //   tempc.forEach(item1=>{
      //     item1.children = []
      //   })
      //   // if(item.children.length>0){
      //   //   item.children.forEach((item2) => {
      //   //     if(item2.selected){
      //   //       tempc[index].children.push(item2)
      //   //     }
      //   //   })
      //   // }
      // })
      // console.log("tempc", tempc);
      temp.menuList = tempA;
      console.log("temp", temp);
      roleMenuSave(temp).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.$message.success("保存成功");
        }
      });
    },
    validateCheck(
      checkedNodes,
      checkedKeys,
      halfCheckedNodes,
      halfCheckedKeys
    ) {
      // debugger;
      // this.attr1 = [];
      // this.attr2 = [];
      console.log(checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys);
      this.dataTree.forEach((item, index) => {
        // debugger
        if (item.menuId === checkedNodes.menuId) {
          // temp.push(item.menuId);

          this.dataTree[index].selected = !this.dataTree[index].selected;
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              item2.selected = !item2.selected;
            });
          }
        } else {
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              if (item2.menuId === checkedNodes.menuId) {
                item2.selected = !item2.selected;
                if (item2.selected) {
                  item.selected = true;
                }
              }
            });
          }
        }
      });
      this.dataTree.forEach((item) => {
        let temp = [];
        if (item.children.length > 0) {
          item.children.forEach((item2) => {
            if (!item2.selected) {
              temp.push(1);
            }
          });
          if (temp.length === item.children.length) {
            item.selected = false;
          }
        }
      });
      console.log("this.dataTree", this.dataTree);
    },
    clickLeft(item, index) {
      console.log(item, index);
      this.currentLeftId = item.roleId;
      this.dataTree = [];
      roleMenu(this.currentLeftId).then((res) => {
        console.log(res);
        if (!item.updated) {
          this.isshowBTN = false;
          res.data.data.forEach((item1) => {
            item1.disabled = true;
            if (item1.children.length > 0) {
              item1.children.forEach((item2) => {
                item2.disabled = true;
              });
            }
          });
        } else {
          this.isshowBTN = true;
        }
        this.dataTree = res.data.data;
        let temp = [];
        this.dataTree.forEach((item) => {
          if (item.children.length === 0) {
            if (item.selected) {
              temp.push(item.menuId);
            }
          }
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              if (item2.selected) {
                temp.push(item2.menuId);
              }
            });
          }
        });
        // temp = ['resourceAnalyze','resourceOverall','resourceSanki']
        console.log(temp);
        this.$nextTick(() => {
          this.$refs.eltree.setCheckedKeys(temp);
        });
      });
    },
    // handleCommand(command) {
    //   // this.$message("click on item " + command);
    //   console.log(command);
    //   if (command.command === "a") {
    //     this.testList[command.index].childNodeList.push({
    //       childNodeList: [],
    //       nodeName: "",
    //       show: true,
    //     });
    //   } else if (command.command === "b") {
    //     this.testList.splice(command.index, 1);
    //   }
    // },
    ...mapMutations(["mutationsGetInstance"]),
    toDetail(id) {
      // alarmSetGet(id).then((resA) => {
      //   console.log(resA)
      // })
      // this.$router.push({
      //   name: "alarmsetDetail",
      //   query: { id: id },
      // });

      alarmSetGet(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          getInstanceAttr(resA.data.data.instanceId).then((res2) => {
            // debugger
            // this.options2[v] = res2.data.data;
            // console.log("this.options2", this.options2);
            // let temp = JSON.parse(JSON.stringify(this.options2));
            // this.options2 = temp;
            this.optionsInstanceAttr = res2.data.data;
            this.changePasswordVisible = true;
            this.isEdit = false;
            this.isView = true;
            this.ruleForm = resA.data.data;
            this.ruleForm.periodTime =
              (this.ruleForm.periodTime / 60).toString() + "分钟";
          });
        }
      });
    },
    showUpdate(id) {
      alarmSetGet(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          getInstanceAttr(resA.data.data.instanceId).then((res2) => {
            // debugger
            // this.options2[v] = res2.data.data;
            // console.log("this.options2", this.options2);
            // let temp = JSON.parse(JSON.stringify(this.options2));
            // this.options2 = temp;
            this.optionsInstanceAttr = res2.data.data;
            this.changePasswordVisible = true;
            this.isEdit = true;
            this.isView = false;
            this.ruleForm = resA.data.data;
          });

          // this.ruleForm.reportName = resA.data.data.name;
          // this.selectTimeV21 = resA.data.data.beginDay;
          // this.selectTimeV = resA.data.data.beginHour;
          // this.ruleForm.reportTypesArray = [];
          // this.options2 = [[]];
          // getReportType().then((res) => {
          //   this.options1 = res.data.data;
          //   console.log(
          //     "resA.data.data.reportTypeAndParas",
          //     resA.data.data.reportTypeAndParas
          //   );
          //   // resA.data.data.reportTypeAndParas.forEach((item1, index1) => {
          //   // this.ruleForm.reportTypesArray.push({
          //   //   params1: item1.reportTypeName
          //   // });
          //   // debugger;
          //   for (let i = 0; i < resA.data.data.reportTypeAndParas.length; i++) {
          //     getReportTypeParams(
          //       resA.data.data.reportTypeAndParas[i].reportType
          //     ).then((res2) => {
          //       // debugger
          //       if (resA.data.data.reportTypeAndParas[i].reportParas) {
          //         this.options2[i] = res2.data.data;
          //         this.ruleForm.reportTypesArray.push({
          //           params1: resA.data.data.reportTypeAndParas[i].reportType,
          //           params2: [],
          //         });
          //         resA.data.data.reportTypeAndParas[i].reportParas.forEach(
          //           (item2) => {
          //             this.ruleForm.reportTypesArray[i].params2.push(
          //               item2.reportType
          //             );
          //           }
          //         );
          //       } else {
          //         this.ruleForm.reportTypesArray.push({
          //           params1: resA.data.data.reportTypeAndParas[i].reportType,
          //           params2: [],
          //         });
          //         this.options2[i] = [];
          //       }
          //       this.$nextTick(() => {
          //         let temp1 = JSON.parse(JSON.stringify(this.ruleForm));
          //         let temp2 = JSON.parse(JSON.stringify(this.options2));
          //         this.ruleForm = temp1;
          //         this.options2 = temp2;
          //         console.log(
          //           "111",
          //           this.options2,
          //           this.ruleForm.reportTypesArray
          //         );
          //       });
          //     });
          //   }

          //   // });
          // });
        }
      });
    },
    changeStatus(id, index) {
      if (index === 0) {
        this.$confirm("确认暂停？")
          .then(() => {
            // done();
            reportManagechangeStatus(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      } else if (index === 1) {
        this.$confirm("确认发布？")
          .then(() => {
            // done();
            reportManagechangeStatus(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      }
    },
    deleteReport(id) {
      this.$confirm("确认删除？")
        .then(() => {
          // done();
          alarmSetDel(id).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.searchFor();
            }
          });
        })
        .catch(() => {});
    },
    toBind(id) {
      console.log(id);
      this.treeReportId = id;
      getInstanceTree(id).then((res) => {
        console.log(res);
        if (res.data.data) {
          if (res.data.data.treeNodeList.length > 0) {
            this.testList = res.data.data.treeNodeList;
          }
          this.treeForm.treeName = res.data.data.treeName;
        }

        this.changeTreeVisible = true;
      });
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      console.log(command);
      if (command.command === "a") {
        // this.testList[command.index].childNodeList.push({
        //   childNodeList: [],
        //   nodeName: "",
        //   show: true,
        // });
        this.ruleForm.roleId = command.item.roleId;
        this.ruleForm.roleName = command.item.roleName;
        this.isEdit = true;
        this.isView = false;
        this.changePasswordVisible = true;
      } else if (command.command === "b") {
        // this.testList.splice(command.index, 1);
        this.$confirm("确认删除？")
          .then(() => {
            // done();
            roleDelete(command.item.roleId).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("删除成功");
                roleSearch({
                  limit: 100000,
                  page: 1,
                  roleName: "",
                }).then((res2) => {
                  this.loading = false;
                  console.log(res2);
                  this.tableData = res2.data.data;
                  this.total = res2.data.count;
                  this.currentLeftId = res2.data.data[0].roleId;
                  roleMenu(res2.data.data[0].roleId).then((res) => {
                    console.log(res);
                    if (!res2.data.data[0].updated) {
                      this.isshowBTN = false;
                      res.data.data.forEach((item) => {
                        item.disabled = true;
                        if (item.children.length > 0) {
                          item.children.forEach((item1) => {
                            item1.disabled = true;
                          });
                        }
                      });
                    } else {
                      this.isshowBTN = true;
                    }
                    this.dataTree = res.data.data;
                    let temp = [];
                    this.dataTree.forEach((item) => {
                      if (item.selected) {
                        temp.push(item.menuId);
                      }
                      if (item.children.length > 0) {
                        item.children.forEach((item2) => {
                          if (item2.selected) {
                            temp.push(item2.menuId);
                          }
                        });
                      }
                    });
                    this.$nextTick(() => {
                      this.$refs.eltree.setCheckedKeys(temp);
                    });
                  });
                  // this.tableKey = res2.data.data.timestamps;
                });
              }
            });
          })
          .catch(() => {});
      }
    },
    addTreeNode() {
      this.testList.push({
        childNodeList: [],
        nodeName: "",
        show: true,
      });
    },
    deleteTree(index) {
      this.testList.splice(index, 1);
    },
    selectTimeOption(item, e) {
      e.stopPropagation();
      this.selectTimeV = item;
      this.isShowDay = false;
    },
    selectTimeOption2(item, e) {
      e.stopPropagation();
      this.selectTimeV21 = item;
      this.isShowMonth = false;
    },
    showDaySelect() {
      this.isShowDay = !this.isShowDay;
    },
    showMonthSelect() {
      this.isShowMonth = !this.isShowMonth;
    },
    addTypes() {
      this.ruleForm.reportTypesArray.push({
        params1: "",
        params2: [],
      });
      this.options2.push([]);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    deleteTypes(index) {
      console.log(index);
      this.ruleForm.reportTypesArray.splice(index, 1);
      this.options2.splice(index, 1);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    getOption(e) {
      console.log(e);
      getInstanceAttr(e).then((res2) => {
        // debugger
        // this.options2[v] = res2.data.data;
        // console.log("this.options2", this.options2);
        // let temp = JSON.parse(JSON.stringify(this.options2));
        // this.options2 = temp;
        this.optionsInstanceAttr = res2.data.data;
        this.ruleForm.maxDisplay = "";
        this.ruleForm.minDisplay = "";
        this.ruleForm.attrId = "";
      });
    },
    getOption2(e) {
      console.log(e);
      this.optionsInstanceAttr.forEach((item) => {
        if (item.attrId === e) {
          if (item.maxDisplay !== null) {
            this.ruleForm.maxDisplay = item.maxDisplay;
          }
          if (item.minDisplay !== null) {
            this.ruleForm.minDisplay = item.minDisplay;
          }
        }
      });
      // getInstanceAttr(e).then((res2) => {
      //   // debugger
      //   // this.options2[v] = res2.data.data;
      //   // console.log("this.options2", this.options2);
      //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //   // this.options2 = temp;
      //   this.optionsInstanceAttr = res2.data.data;
      // });
    },
    toAdd() {
      this.changePasswordVisible = true;
      this.isEdit = false;
      this.isView = false;
      this.ruleForm = {
        roleName: "",
        // // alarmId: "",
        // alarmLevelId: "",
        // alarmLevelName: "",
        // alarmType: "",
        // attrId: "",
        // attrName: "",
        // instanceId: "",
        // instanceName: "",
        // maxDisplay: 0,
        // minDisplay: 0,
        // // periodMinute: "",
        // released: false,
      };
    },
    sizeChange(val) {
      this.query.page = 1;
      this.query.limit = val;
      this.searchFor();
    },
    pageChange(val) {
      this.query.page = val;
      this.searchFor();
    },
    searchFor() {
      // this.$router.push({ name: "alarmDetail" });
      // console.log("this.value1", this.value1);
      this.loading = true;
      alarmSearchSet(this.query).then((res) => {
        console.log(res);
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.count;
      });
    },
    reportTypeV(v) {
      let temp = [];
      v.forEach((item) => {
        temp.push(item);
      });
      temp = temp.join(",");
      return temp;
    },
    reportTypeV2(v) {
      let temp = [];
      v.forEach((item) => {
        if (item.reportParas) {
          item.reportParas.forEach((item2) => {
            temp.push(item2.reportTypeName);
          });
        }
      });
      temp = temp.join(",");
      return temp;
    },
    timeChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    clickTime(index) {
      this.isActivenTime = index;
      this.value1 = "";
      this.value2 = [];
      if (index == 1) {
        this.isInterval = false;
        this.changeDateType = "date";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD;
      } else if (index == 2) {
        this.isInterval = false;
        this.changeDateType = "month";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 3) {
        this.isInterval = false;
        this.changeDateType = "year";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 4) {
        this.isInterval = true;
        let dateT = new Date();
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value11 = YY + MM + DD;
        dateT = new Date(this.value11);
        dateT = dateT.setDate(dateT.getDate() + 1);
        this.value22 = this.formatDate(dateT);
        this.value22 = this.value22.split(" ")[0];
        console.log("this.value1", this.value11);
        console.log("this.value2", this.value22);
        this.value2 = [this.value11, this.value22];
        this.query.startTime = this.value11;
        this.query.endTime = this.value22;
      }
    },
    clickX() {},
    clickOptionX() {},
    clickY() {},
    clickOptionY() {},
    onSearch() {
      this.loading = true;
      this.tableData = [];
      this.tableKey = [];
      this.tableKey2 = [];
      if (this.isActivenTime == 1) {
        this.query.startTime = this.value1;
        this.query.endTime = "";
        this.query.unit = "DAYS";
      } else if (this.isActivenTime == 2) {
        this.query.startTime =
          this.value1.split("-")[0] + "-" + this.value1.split("-")[1];
        this.query.endTime = "";
        this.query.unit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        this.query.startTime = this.value1.split("-")[0];
        this.query.endTime = "";
        this.query.unit = "YEARS";
      } else if (this.isActivenTime == 4) {
        // this.query.startTime = this.value1;
        // this.query.endTime = "";
        this.query.unit = null;
      }
      // reportSearch(this.query).then((res2) => {
      //   this.loading = false;
      //   console.log(res2);
      //   this.tableData = res2.data.data.rows;
      //   // this.tableKey = res2.data.data.timestamps;
      //   let temp = [];
      //   res2.data.data.timestamps.forEach((item) => {
      //     temp.push(this.formatDate(item));
      //   });
      //   this.tableKey = temp;
      //   this.tableKey2 = res2.data.data.columns;
      // });
    },
    bindTree() {
      let temp = {
        reportId: this.treeReportId,
        treeName: this.treeForm.treeName,
        treeNodeList: this.testList,
      };
      bindInstanceTree(temp).then((res) => {
        if (res.data.code === 0) {
          this.changeTreeVisible = false;
          this.$message.success("绑定成功");
          this.searchFor();
        }
        // console.log(res);
      });
    },
    addNew() {
      // let temp = {
      //   alarmId: "",
      //   alarmLevelId: "",
      //   alarmLevelName: "",
      //   alarmType: "",
      //   attrId: "",
      //   attrName: "",
      //   instanceId: "",
      //   instanceName: "",
      //   maxDisplay: 0,
      //   minDisplay: 0,
      //   periodMinute: "",
      //   released: true,
      // };
      if (this.isView) {
        this.changePasswordVisible = false;
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            // alarmSetCheck(this.ruleForm).then((resC) => {
            // if (!resC.data.data.exist) {
            if (!this.isEdit) {
              // let temp = [];
              // this.ruleForm.projectList.forEach((item) => {
              //   this.optionsInstance.forEach((item2) => {
              //     if (item === item2.projectId) {
              //       temp.push({
              //         projectId: item2.projectId,
              //         projectName: item2.projectName,
              //         tenantId: item2.tenantId,
              //       });
              //     }
              //   });
              // });
              // this.ruleForm.projectList = temp;
              roleAdd(this.ruleForm).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.$message.success("新增成功");
                  this.changePasswordVisible = false;
                  // this.searchFor();
                  roleSearch({
                    limit: 100000,
                    page: 1,
                    roleName: "",
                  }).then((res2) => {
                    this.loading = false;
                    console.log(res2);
                    this.tableData = res2.data.data;
                  });
                }
              });
            } else {
              roleEdit(this.ruleForm).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.changePasswordVisible = false;
                  this.$message.success("编辑成功");
                  // this.searchFor();
                  roleSearch({
                    limit: 100000,
                    page: 1,
                    roleName: "",
                  }).then((res2) => {
                    this.loading = false;
                    console.log(res2);
                    this.tableData = res2.data.data;
                  });
                }
              });
            }
            // this.ruleForm.beginDay = this.selectTimeV21;
            // this.ruleForm.beginHour = this.selectTimeV;
            // let temp = [];
            // this.ruleForm.reportTypesArray.forEach((item) => {
            //   if (item.params1) {
            //     if (item.params2.length > 0) {
            //       item.params2.forEach((item3) => {
            //         temp.push(item3);
            //       });
            //     } else {
            //       temp.push(item.params1);
            //     }
            //   }
            // });
            // this.ruleForm.reportTypes = temp;
            // if (!this.isEdit) {
            //   reportManageAdd(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("新增成功");
            //       this.searchFor();
            //     }
            //   });
            // } else {
            //   reportManageupdate(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("编辑成功");
            //       this.searchFor();
            //     }
            //   });
            // }
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.isClick {
  background: #3f6df8 !important;
  color: white !important;
  /* border-bottom: 4px solid #3e6cf7; */
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.el-dialog__wrapper >>> input {
  width: 350px !important;
  height: 32px !important;
}

.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
/* .el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
.time-square:hover {
  background: #3f6df8 !important;
  color: white !important;
}
.isSelectTime {
  background: #3f6df8 !important;
  color: white !important;
}
.small-s >>> input {
  width: 220px !important;
  height: 32px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 750px !important;
}
.inline-input >>> input {
  width: 160px !important;
}
.small-input >>> input {
  width: 160px !important;
}
.small-input >>> .el-input {
  width: 160px !important;
}
.el-tree >>> .el-tree-node__content {
  height: 50px !important;
}
.el-tree {
  color: #082254 !important;
}
.el-tree >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background: #3f6df8 !important;
  border-color: #3f6df8 !important;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #3f6df8 !important;
  border-color: #3f6df8 !important;
}
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>