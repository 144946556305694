var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"100%","background":"transparent","padding":"15px","display":"flex","justify-content":"space-between","overflow":"auto"}},[_c('div',{staticStyle:{"height":"100%","width":"250px","flex":"none","border-right":"1px solid rgba(8, 34, 84, 0.1)","overflow":"hidden","position":"relative","background":"white","padding-top":"10px"}},[_c('div',{staticStyle:{"width":"100%","height":"40px","padding-left":"20px","padding-right":"20px","display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',{staticStyle:{"font-size":"16px","font-weight":"600","color":"#082254"}},[_vm._v(" 角色列表 ")]),_c('div',{staticStyle:{"width":"72px","height":"32px","background":"#3f6df8","border-radius":"2px","font-size":"14px","font-weight":"400","color":"#ffffff","cursor":"pointer","line-height":"32px","text-align":"center"},on:{"click":_vm.toAdd}},[_vm._v(" 新增 ")])]),_c('div',{staticStyle:{"width":"100%","overflow":"auto","height":"550px","margin-top":"50px","padding-right":"10px"}},_vm._l((_vm.tableData),function(item,index){return _c('div',{key:index,staticStyle:{"width":"100%","height":"32px","display":"flex","justify-content":"space-between","align-items":"center","color":"#082254","cursor":"pointer"},on:{"click":function($event){return _vm.clickLeft(item, index)}}},[_c('div',{class:{ isClick: _vm.currentLeftId === item.roleId },staticStyle:{"color":"#082254","width":"90%","flex":"none","height":"100%","line-height":"32px","padding-left":"20px"}},[_vm._v(" "+_vm._s(item.roleName)+" ")]),(item.updated)?_c('div',{staticStyle:{"flex":"1"}},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('i',{staticClass:"el-icon-more-outline"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                  command: 'a',
                  item: item,
                }}},[_vm._v("修改")]),_c('el-dropdown-item',{attrs:{"command":{
                  command: 'b',
                  item: item,
                }}},[_vm._v("删除")])],1)],1)],1):_vm._e()])}),0)]),_c('div',{staticStyle:{"flex":"1","padding":"20px","overflow":"hidden","background":"white"}},[_c('div',{staticStyle:{"font-size":"16px","font-weight":"600","color":"#082254","height":"22px","line-height":"22px","padding-left":"10px"}},[_vm._v(" 权限列表 ")]),_c('div',{staticStyle:{"overflow":"auto","width":"100%","height":"90%"}},[_c('el-tree',{ref:"eltree",staticStyle:{"width":"100%","height":"100%","overflow":"auto"},attrs:{"data":_vm.dataTree,"show-checkbox":"","check-on-click-node":_vm.isClickOncheck,"node-key":"menuId","props":_vm.defaultProps,"default-expanded-keys":_vm.defaultExpandArray},on:{"check":_vm.validateCheck}})],1),_c('div',{staticStyle:{"width":"100%"}},[(_vm.isshowBTN)?_c('div',{staticStyle:{"width":"70px","height":"32px","line-height":"32px","text-align":"center","background":"#3f6df8","color":"white","margin":"0 auto","cursor":"pointer","margin-top":"20px"},on:{"click":_vm.saveRole}},[_vm._v(" 保存 ")]):_vm._e()])]),_c('el-dialog',{attrs:{"title":"角色设置","visible":_vm.changePasswordVisible,"close-on-click-modal":false,"width":"30%","center":""},on:{"update:visible":function($event){_vm.changePasswordVisible=$event}}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"120px"}},[_c('el-form-item',{staticStyle:{"position":"relative","margin-bottom":"20px"},attrs:{"label":"角色名称：","prop":"roleName"}},[_c('el-input',{attrs:{"disabled":_vm.isView},model:{value:(_vm.ruleForm.roleName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "roleName", $$v)},expression:"ruleForm.roleName"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v("确 定")]),_c('el-button',{on:{"click":function($event){_vm.changePasswordVisible = false}}},[_vm._v("取 消")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }