var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.list),function(item1,index1){return _c('div',{key:index1,staticStyle:{"padding-left":"20px"}},[_c('el-form-item',{staticClass:"inline-input",staticStyle:{"position":"relative","margin-bottom":"10px"},attrs:{"label":"子节点：","prop":"reportName"}},[_c('el-input',{attrs:{"disabled":_vm.isView,"placeholder":"请输入报表名称"},model:{value:(item1.nodeName),callback:function ($$v) {_vm.$set(item1, "nodeName", $$v)},expression:"item1.nodeName"}})],1),_c('el-form-item',{staticClass:"inline-input",staticStyle:{"position":"relative"},attrs:{"label":"数据源类型：","prop":"reportName"}},[_c('el-select',{attrs:{"disabled":_vm.isView,"placeholder":"请选择"},model:{value:(item1.show),callback:function ($$v) {_vm.$set(item1, "show", $$v)},expression:"item1.show"}},_vm._l((_vm.optionsSourceType),function(item){return _c('el-option',{key:item.name,attrs:{"label":item.name,"value":item.code}})}),1)],1),(!item1.show)?_c('el-form-item',{staticClass:"inline-input",staticStyle:{"position":"relative"},attrs:{"label":"绑定仪表：","prop":"reportName"}},[_c('el-select',{attrs:{"filterable":"","disabled":_vm.isView,"placeholder":"请选择"},model:{value:(item1.instanceId),callback:function ($$v) {_vm.$set(item1, "instanceId", $$v)},expression:"item1.instanceId"}},_vm._l((_vm.insatnceArr),function(item){return _c('el-option',{key:item.instanceId,attrs:{"label":item.name,"value":item.instanceId}})}),1),(!_vm.isView)?_c('div',{staticStyle:{"position":"absolute","top":"0","right":"-25px"}},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('i',{staticClass:"el-icon-more-outline"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                command: 'a',
                index: index1,
              }}},[_vm._v("新增子节点")]),_c('el-dropdown-item',{attrs:{"command":{
                command: 'b',
                index: index1,
              }}},[_vm._v("删除该节点")])],1)],1)],1):_vm._e()],1):_vm._e(),(item1.show)?_c('el-form-item',{staticClass:"inline-input",staticStyle:{"position":"relative"},attrs:{"label":"绑定仪表：","prop":"reportName"}},[_c('el-select',{attrs:{"filterable":"","disabled":_vm.isView,"placeholder":"请选择"},model:{value:(item1.instanceId),callback:function ($$v) {_vm.$set(item1, "instanceId", $$v)},expression:"item1.instanceId"}},_vm._l((_vm.insatnceArr2),function(item){return _c('el-option',{key:item.instanceId,attrs:{"label":item.name,"value":item.instanceId}})}),1),(!_vm.isView)?_c('div',{staticStyle:{"position":"absolute","top":"0","right":"-25px"}},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('i',{staticClass:"el-icon-more-outline"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                command: 'a',
                index: index1,
              }}},[_vm._v("新增子节点")]),_c('el-dropdown-item',{attrs:{"command":{
                command: 'b',
                index: index1,
              }}},[_vm._v("删除该节点")])],1)],1)],1):_vm._e()],1):_vm._e(),(item1.childNodeList.length > 0)?_c('div',{staticStyle:{"padding-left":"20px"}},[_c('form-list',{attrs:{"list":item1.childNodeList,"isView":_vm.isView}})],1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }