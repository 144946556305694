<template>
  <div style="width: 100%; height: 100%; background: white; padding: 15px">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div>算法类型：</div>
        <div
          style="position: relative; margin-left: 5px; cursor: pointer"
          class="select"
        >
          <el-select
            v-model="query.algId"
            placeholder="请选择算法类型"
            style="margin-left: 10px"
            @change="changeAlg"
          >
            <el-option
              v-for="item in options1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-left: 20px">选择服务：</div>
        <div
          style="position: relative; margin-left: 5px; cursor: pointer"
          class="select"
        >
          <el-select
            v-model="query.serviceId"
            placeholder="请选择服务"
            style="margin-left: 10px"
            @change="changeService"
          >
            <el-option
              v-for="item in options222"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-left: 20px">选择设备：</div>
        <div
          style="position: relative; margin-left: 5px; cursor: pointer"
          class="select"
        >
          <el-select
            v-model="query.equipId"
            placeholder="请选择设备"
            style="margin-left: 10px"
            @change="changeEquip"
          >
            <el-option
              v-for="item in options2"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>

        <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
            margin-right: 20px;
            margin-left: 20px;
            cursor: pointer;
          "
          @click="searchFor"
        >
          查询
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #082254;
        margin-top: 20px;
      "
    >
      {{ currentName }}
    </div>
    <div v-if="isShowIce1">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          冷冻机启停及供水温度（℃）
        </div>

        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              width: 24px;
              height: 4px;
              background: #3f6df8;
              margin-right: 10px;
            "
          ></div>
          <div
            style="
              font-size: 12px;
              font-weight: 400;
              color: #082254;
              margin-right: 20px;
            "
          >
            实际运行
          </div>
          <div
            style="
              width: 24px;
              height: 4px;
              background: #52d996;
              margin-right: 10px;
            "
          ></div>
          <div style="font-size: 12px; font-weight: 400; color: #082254">
            预测运行
          </div>
        </div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart11" :option="chartLineOption11" />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          功率（KW）
        </div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart12" :option="chartLineOption12" />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          冷量（KW）
        </div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart13" :option="chartLineOption13" />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">COP</div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart14" :option="chartLineOption14" />
      </div>
    </div>
    <div v-if="isShowIce2">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          出水温度（℃）
        </div>

        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              width: 24px;
              height: 4px;
              background: #3f6df8;
              margin-right: 10px;
            "
          ></div>
          <div
            style="
              font-size: 12px;
              font-weight: 400;
              color: #082254;
              margin-right: 20px;
            "
          >
            湿球温度
          </div>
          <div
            style="
              width: 24px;
              height: 4px;
              background: #f7b975;
              margin-right: 10px;
            "
          ></div>
          <div
            style="
              font-size: 12px;
              font-weight: 400;
              color: #082254;
              margin-right: 20px;
            "
          >
            冷却水回水温度
          </div>
          <div
            style="
              width: 24px;
              height: 4px;
              background: #52d996;
              margin-right: 10px;
            "
          ></div>
          <div style="font-size: 12px; font-weight: 400; color: #082254">
            预测运行
          </div>
        </div>
      </div>

      <div
        style="width: 100%; height: 330px; margin-top: 10px"
        v-if="query.algId === 'yh' && query.equipId === '2ice'"
      >
        <v-chart ref="chart21" :option="chartLineOption21" />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          功率（KW）
        </div>
      </div>

      <div
        style="width: 100%; height: 330px; margin-top: 10px"
        v-if="query.algId === 'yh' && query.equipId === '2ice'"
      >
        <v-chart ref="chart22" :option="chartLineOption22" />
      </div>
    </div>
    <div v-if="isShowIce3">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          供水温度（℃）
        </div>

        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              width: 24px;
              height: 4px;
              background: #3f6df8;
              margin-right: 10px;
            "
          ></div>
          <div
            style="
              font-size: 12px;
              font-weight: 400;
              color: #082254;
              margin-right: 20px;
            "
          >
            实际运行
          </div>
          <div
            style="
              width: 24px;
              height: 4px;
              background: #52d996;
              margin-right: 10px;
            "
          ></div>
          <div style="font-size: 12px; font-weight: 400; color: #082254">
            预测运行
          </div>
        </div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart31" :option="chartLineOption31" />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          功率（KW）
        </div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart32" :option="chartLineOption32" />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          冷量（KW）
        </div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart33" :option="chartLineOption33" />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">COP</div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart34" :option="chartLineOption34" />
      </div>
    </div>
    <!-- <div v-if="query.algId === 'yh' && query.equipId === '4ice'">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          冷冻机启停及供水温度（℃）
        </div>

        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              width: 24px;
              height: 4px;
              background: #3f6df8;
              margin-right: 10px;
            "
          ></div>
          <div
            style="
              font-size: 12px;
              font-weight: 400;
              color: #082254;
              margin-right: 20px;
            "
          >
            实际运行
          </div>
          <div
            style="
              width: 24px;
              height: 4px;
              background: #52d996;
              margin-right: 10px;
            "
          ></div>
          <div style="font-size: 12px; font-weight: 400; color: #082254">
            预测运行
          </div>
        </div>
      </div>

      <div style="width: 100%; height: 600px; margin-top: 10px">
        <v-chart ref="chart41" :option="chartLineOption41" />
      </div>
    </div> -->

    <div v-if="isShowYc">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div v-if="isShowLLYc" style="font-size: 12px; font-weight: 400; color: #082254">
          冷量（KW）
        </div>
        <div v-if="isShowDLYc" style="font-size: 12px; font-weight: 400; color: #082254">
          电量（KW）
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              width: 24px;
              height: 4px;
              background: #3f6df8;
              margin-right: 10px;
            "
          ></div>
          <div
            style="
              font-size: 12px;
              font-weight: 400;
              color: #082254;
              margin-right: 20px;
            "
          >
            实际运行
          </div>
          <div
            style="
              width: 24px;
              height: 4px;
              background: #52d996;
              margin-right: 10px;
            "
          ></div>
          <div style="font-size: 12px; font-weight: 400; color: #082254">
            预测运行
          </div>
        </div>
      </div>

      <div style="width: 100%; height: 600px; margin-top: 10px">
        <v-chart ref="chart51" :option="chartLineOption51" />
      </div>

      <!-- <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          功率（KW）
        </div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart52" :option="chartLineOption52" />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">
          冷量（KW）
        </div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart53" :option="chartLineOption53" />
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        "
      >
        <div style="font-size: 12px; font-weight: 400; color: #082254">COP</div>
      </div>

      <div style="width: 100%; height: 140px; margin-top: 10px">
        <v-chart ref="chart54" :option="chartLineOption54" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import formList from "./list";
import {
  resetPassword,
  getUserDetail,
  addUser,
  // userSearch,
  deleteUser,
  // alarmSetUpdate,
  editUser,
  // alarmSetCheck,
  // alarmSearchSet,
  alarmSetGet,
  // alarmSetLevel,
  bindInstanceTree,
  getInstanceTree,
  // alarmSetDel,
  // getGroupList,
  // getRoleList,
  // reportManageSearch,
  // getReportType,
  // getReportTypeParams,
  // reportManageAdd,
  reportManagechangeStatus,
  // reportManagedeatil,
  // reportManageupdate,
  // reportManageDelete,
  // getInstance,
  getInstanceAttr,
} from "@/request/apiLg";
export default {
  // components: {
  //   formList,
  // },
  filters: {
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
  },
  data() {
    var validConfirmPass = (rule, value, callback) => {
      // debugger
      console.log("this.ruleForm", this.ruleForm);
      if (
        value !== "" &&
        this.ruleForm.newPassword !== "" &&
        value != this.ruleForm.newPassword
      ) {
        callback(new Error("密码与上面的新密码不符"));
      }
      callback();
    };
    return {
      isShowYc:false,
      isShowLLYc:false,
      isShowDLYc:true,
      isShowIce1: true,
      isShowIce3: false,
      isShowIce2: false,
      isShow11: true,
      isShow22: false,
      split111: 0,
      dataValue111: [
        "",
        "",
        "",
        "",
        "",
        "",
        16,
        6,
        7,
        7.5,
        8,
        7.5,
        6,
        11,
        10,
        9,
        8,
        9,
        9.5,
        10,
        "",
        "",
        "",
        "",
      ],
      dataValue222: [
        80, 30, 20, 10, 5, 5, 500, 600, 700, 790, 650, 610, 620, 630, 610, 700,
        730, 780, 720, 700, 5, 5, 30, 5,
      ],
      dataValue333: [
        80, 500, 100, 200, 100, 100, 2800, 3000, 3800, 3200, 3100, 3500, 3330,
        3200, 3100, 3000, 3600, 3150, 3400, 3900, 5, 5, 30, 5,
      ],
      dataValue444: [
        0, 0.3, 0, 0.2, 0, 0.1, 2.8, 3.3, 3, 3.6, 3.8, 3.9, 4.0, 3, 3.2, 3.5,
        3.8, 3.5, 3.2, 3.1, 0, 0.2, 0, 0.1,
      ],
      dataValue555: [
        22, 21.5, 21, 24, 24.5, 25, 26, 26.5, 27, 27.5, 28, 28.5, 29, 28, 27,
        26, 25, 24, 22, 20, 19, 18, 17.5, 17,
      ],
      dataValue666: [
        32, 32.5, 33, 32.5, 32, 31, 30.8, 30, 30.5, 30, 31, 31.5, 32, 31, 30,
        29, 27.6, 27, 25.5, 28, 27, 28, 31, 30,
      ],
      dataValue777: [
        20, 80, 20, 10, 30, 30, 780, 810, 820, 830, 810, 790, 780, 770, 775,
        771, 800, 805, 810, 820, 30, 30, 20, 30,
      ],
      dataValue888: [
        16,
        16.5,
        17,
        18,
        19,
        17.5,
        7,
        6,
        7,
        7.5,
        8,
        7.5,
        11,
        11,
        10,
        9,
        8,
        9,
        9.5,
        10,
        15,
        16,
        17,
        18,
      ],
      dataValue999: [
        80, 30, 20, 10, 5, 5, 500, 600, 700, 790, 650, 610, 620, 630, 610, 700,
        730, 780, 720, 700, 5, 5, 30, 5,
      ],
      dataValue101010: [
        80, 500, 100, 200, 100, 100, 2800, 3000, 3800, 3200, 3100, 3500, 3330,
        3200, 3100, 3000, 3600, 3150, 3400, 3900, 5, 5, 30, 5,
      ],
      dataValue111111: [
        0, 0.3, 0, 0.2, 0, 0.1, 2.8, 3.3, 3, 3.6, 3.8, 3.9, 4.0, 3, 3.2, 3.5,
        3.8, 3.5, 3.2, 3.1, 0, 0.2, 0, 0.1,
      ],
      dataValue121212: [
        100, 400, 100, 200, 100, 100, 4000, 4200, 4300, 4600, 4400, 4500, 4450,
        4400, 4300, 4250, 4400, 4450, 4500, 4550, 100, 200, 100, 150,
      ],

      dataValue112: [400, 320, 300, 310, 200, 320, "", "", "", "", "", ""],

      dataValue21: [400, 320, 300, 310, 200, 320, "", "", "", "", "", ""],
      dataValue22: ["", "", "", "", "", "", 300, 310, 290, 200, 100, 120],
      dataValue31: [400, 400, 400, 400, 400, 400, "", "", "", "", "", ""],
      dataValue32: ["", "", "", "", "", "", 300, 300, 300, 300, 300, 300],
      userSettingTitle: "新增用户",
      tempUserId: "",
      optionsSSS: [
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      optionsSS: [],
      optionsS: [
        {
          value: "ALL",
          label: "全部",
        },
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      isEdit: false,
      isView: false,
      testList: [
        {
          nodeName: "",
          show: true,
          childNodeList: [],
        },
      ],
      // testList: [
      //   {
      //     childNodeList: [
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             instanceId: "",
      //             nodeName: "二级节点",
      //             show: false,
      //           },
      //         ],

      //         nodeName: "一级节点",
      //         show: true,
      //       },
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             nodeName: "二级节点1",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点1",
      //         show: true,
      //       },
      //     ],
      //     // instanceId: "root",
      //     nodeName: "根节点",
      //     show: true,
      //   },
      //   {
      //     childNodeList: [
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             // instanceId: "second",
      //             nodeName: "二级节点",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点",
      //         show: true,
      //       },
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             // instanceId: "second",
      //             nodeName: "二级节点1",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点1",
      //         show: true,
      //       },
      //     ],
      //     // instanceId: "root",
      //     nodeName: "根节点",
      //     show: true,
      //   },
      // ],
      listTemp: [
        {
          name: 1,
          children: [
            {
              name: 2,
              children: [
                {
                  name: 3,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      changeTreeVisible: false,
      changeReVisible: false,
      optionsInstance: [],
      optionsInstanceAttr: [],

      selectTimeV21: 1,
      selectTimeV22: 0,
      isShowMonth: false,
      selectTimeV: 0,
      timeArray: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      timeArray2: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      treeReportId: "",
      isShowDay: false,
      options1: [
        { name: "节能优化", code: "yh" },
        { name: "负荷预测", code: "yc" },
        // { name: "故障诊断", code: "gz" },
      ],
      options222: [
        { name: "POS冷冻系统优化", code: "POS" },
        // { name: "电量预测", code: "dlyc" },
        // { name: "冷量预测", code: "llyc" },
      ],
      options22: [{ name: "逐日报表", code: "zr" }],
      options2: [
        {
          name: "冷冻机",
          code: "1ice",
        },
        { name: "冷却系统", code: "2ice" },
        { name: "冷冻系统", code: "3ice" },
        // { name: "冷冻泵/冷却泵", code: "4ice" },
        // { name: "5#冷冻机", code: "5ice" },
        // { name: "6#冷冻机", code: "6ice" },
        // { name: "7#冷冻机", code: "7ice" },
        // { name: "8#冷冻机", code: "8ice" },
      ],
      options33: [{ name: "运行COP（仅冷机）", code: "cop" }],
      rules: {
        userId: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
        ],
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        dataPrivilegeGroupIdList: [
          { required: true, message: "请选择项目", trigger: "change" },
        ],
        roleIdList: [
          { required: true, message: "请选择用户角色", trigger: "change" },
        ],
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validConfirmPass, trigger: "blur" },
          { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        ],
        expireDate: [
          { required: true, message: "请选择到期时间", trigger: "change" },
        ],
      },
      ruleForm: {
        // alarmId: "",
        userId: "",
        username: "",
        dataPrivilegeGroupIdList: [],
        roleIdList: [],
        newPassword: "",
        confirmPassword: "",
        expireDate: "",
        // maxDisplay: 0,
        // minDisplay: 0,
        // // periodMinute: "",
        // released: false,
      },
      treeForm: {
        treeName: "",
        treeArray: [1],
      },
      changePasswordVisible: false,
      total: 0,
      loading: true,
      value11: "",
      value22: "",
      currentName: "冷冻机",
      currentNameTemp: "冷冻机",
      isInterval: false,
      value2: [],
      tableKey: [],
      tableKey2: [],
      isborder1: false,
      tableData: [],
      changeDateType: "date",
      selectValueX: "",
      isShowSelectx: false,
      selectValueY: "",
      isShowSelecty: false,
      // options1: [
      //   {
      //     value: "P",
      //     label: "电能-有功功率",
      //   },
      // ],
      // options2: [
      //   {
      //     value: "P",
      //     label: "水能-瞬时流量",
      //   },
      // ],
      value1: "",
      // value2: "",
      isActivenTime: 1,
      query: {
        limit: 10,
        page: 1,
        algId: "yh",
        equipId: "1ice",
        serviceId: "POS",
        // type: "zr",
        // time: ["2021-07-18", "2021-07-19"],
        // searchType: "cop",
      },
    };
  },
  mounted() {
    if (this.$route.query.id === 1) {
      this.query.algId = "yc";
      this.query.serviceId = "dlyc";
      this.options222 = [
        { name: "电量预测", code: "dlyc" },
        { name: "冷量预测", code: "llyc" },
      ]
    }
    if (this.$route.query.id === 2) {
      
      this.query.algId = "yh";
      this.query.serviceId = "POS";
      this.options222 = [
        { name: "POS冷冻系统优化", code: "POS" },
      ]
    }
    
    let dateT = new Date();
    let hour = dateT.getHours();
    console.log("hour", hour);
    // let temp = [];
    this.searchFor()
    // this.dataValue111.forEach((item, index) => {
    //   if (index <= hour + 1) {
    //     temp.push(item);
    //   }
    // });
    // this.dataValue111 = temp;
    // let temp2 = [];
    // this.dataValue222.forEach((item, index) => {
    //   if (index <= hour + 1) {
    //     temp2.push(item);
    //   }
    // });
    // this.dataValue222 = temp2;
    // this.split111 = hour;
    // let temp3 = [];
    // this.dataValue333.forEach((item, index) => {
    //   if (index <= hour + 1) {
    //     temp3.push(item);
    //   }
    // });
    // this.dataValue333 = temp3;
    // let temp4 = [];
    // this.dataValue444.forEach((item, index) => {
    //   if (index <= hour + 1) {
    //     temp4.push(item);
    //   }
    // });
    // this.dataValue444 = temp4;

    // var YY = dateT.getFullYear() + "-";
    // var MM =
    //   (dateT.getMonth() + 1 < 10
    //     ? "0" + (dateT.getMonth() + 1)
    //     : dateT.getMonth() + 1) + "-";
    // var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    // this.value1 = YY + MM + DD;

    // userSearch(this.query).then((res2) => {
    //   this.loading = false;
    //   console.log(res2);
    //   this.tableData = res2.data.data;
    //   this.total = res2.data.count;
    //   // this.tableKey = res2.data.data.timestamps;
    // });
    // // getReportType().then((res) => {
    // //   this.options1 = res.data.data;
    // //   getReportTypeParams(this.options1[0].code).then((res2) => {
    // //     this.options2[0] = res2.data.data;
    // //   });
    // // });
    // getRoleList().then((res) => {
    //   console.log(res.data.data);
    //   this.optionsSS = res.data.data;
    //   // this.mutationsGetInstance(res.data.data);
    // });
    // getGroupList().then((res) => {
    //   this.optionsInstance = res.data.data;
    //   // getInstanceAttr(this.optionsInstance[0].instanceId).then((res1) => {
    //   //   this.optionsInstanceAttr = res1.data.data;
    //   // });
    // });
  },
  computed: {
    ...mapState({
      insatnceArr: (state) => state.insatnceArr,
    }),
    chartLineOption41() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "实际",
            type: "line",
            smooth: false,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue21,
          },
          {
            name: "预测",
            type: "line",
            smooth: false,
            // barWidth: 16,
            itemStyle: {
              color: "#41F39A",
            },
            data: this.dataValue22,
          },
        ],
      };
    },
    chartLineOption21() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
        },
        visualMap: [
          {
            show: false,
            dimension: 0,
            seriesIndex: 0,
            pieces: [
              {
                lte: this.split111,
                color: "#3f6df8",
              },
              {
                gt: this.split111,
                lte: this.split111 + 1,
                color: "#41F39A",
              },
            ],
          },
          {
            show: false,
            dimension: 0,
            seriesIndex: 1,
            pieces: [
              {
                lte: this.split111,
                color: "#F7B975",
              },
              {
                gt: this.split111,
                lte: this.split111 + 1,
                color: "#41F39A",
              },
            ],
          },
        ],
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "湿球温度",
            type: "line",
            smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue555,
          },
          {
            name: "冷却水回水温度",
            type: "line",
            smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#F7B975",
            },
            data: this.dataValue666,
          },
        ],
      };
    },
    chartLineOption22() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "COP",
            type: "line",
            smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue777,
          },
        ],
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
          ],
        },
      };
    },
    chartLineOption11() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            boundaryGap: false,
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
            // {
            //   gt: 8,
            //   lte: 14,
            //   color: "green",
            // },
            // {
            //   gt: 14,
            //   lte: 17,
            //   color: "red",
            // },
            // {
            //   gt: 17,
            //   color: "green",
            // },
          ],
        },
        series: [
          {
            name: "值",
            type: "line",
            smooth: false,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue111,
          },
          // {
          //   name: "预测",
          //   type: "line",
          //   smooth: true,
          //   // barWidth: 16,
          //   itemStyle: {
          //     color: "#41F39A",
          //   },
          //   data: this.dataValue22,
          // },
          // {
          //   name: "预测值",
          //   type: "line",
          //   // yAxisIndex: 1,
          //   smooth: true,
          //   itemStyle: {
          //     color: "#41F39A",
          //   },
          //   areaStyle: {
          //     color: {
          //       type: "linear",
          //       x: 0,
          //       y: 0,
          //       x2: 0,
          //       y2: 1,
          //       colorStops: [
          //         {
          //           offset: 0,
          //           color: "#41F39A", // 0% 处的颜色
          //         },
          //         {
          //           offset: 1,
          //           color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
          //         },
          //       ],
          //       global: false, // 缺省为 false
          //     },
          //   },
          //   data: this.dataValue22,
          // },
        ],
      };
    },
    chartLineOption12() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
          ],
        },
        series: [
          {
            name: "值",
            type: "line",
            smooth: false,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue222,
          },
        ],
      };
    },
    chartLineOption13() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
          ],
        },
        series: [
          {
            name: "值",
            type: "line",
            // smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue333,
          },
        ],
      };
    },
    chartLineOption14() {
      return {
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
          ],
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "值",
            type: "line",
            smooth: false,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue444,
          },
        ],
      };
    },

    chartLineOption31() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          }
        ],
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
          ],
        },
        series: [
          {
            name: "值",
            type: "line",
            smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue888,
          }
          // {
          //   name: "预测值",
          //   type: "line",
          //   // yAxisIndex: 1,
          //   smooth: true,
          //   itemStyle: {
          //     color: "#41F39A",
          //   },
          //   areaStyle: {
          //     color: {
          //       type: "linear",
          //       x: 0,
          //       y: 0,
          //       x2: 0,
          //       y2: 1,
          //       colorStops: [
          //         {
          //           offset: 0,
          //           color: "#41F39A", // 0% 处的颜色
          //         },
          //         {
          //           offset: 1,
          //           color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
          //         },
          //       ],
          //       global: false, // 缺省为 false
          //     },
          //   },
          //   data: this.dataValue22,
          // },
        ],
      };
    },
    chartLineOption32() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          }
        ],
        series: [
          {
            name: "值",
            type: "line",
            // smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue999,
          },
        ],
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
          ],
        },
      };
    },
    chartLineOption33() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
          ],
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          }
        ],
        series: [
          {
            name: "值",
            type: "line",
            // smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue101010,
          }
        ],
      };
    },
    chartLineOption34() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
          ],
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          }
        ],
        series: [
          {
            name: "值",
            type: "line",
            smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue111111,
          }
        ],
      };
    },

    chartLineOption51() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: this.split111,
              color: "#3f6df8",
            },
            {
              gt: this.split111,
              lte: this.split111 + 1,
              color: "#41F39A",
            },
          ],
        },
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "值",
            type: "line",
            smooth: false,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue121212,
          },
          
          // {
          //   name: "预测值",
          //   type: "line",
          //   // yAxisIndex: 1,
          //   smooth: true,
          //   itemStyle: {
          //     color: "#41F39A",
          //   },
          //   areaStyle: {
          //     color: {
          //       type: "linear",
          //       x: 0,
          //       y: 0,
          //       x2: 0,
          //       y2: 1,
          //       colorStops: [
          //         {
          //           offset: 0,
          //           color: "#41F39A", // 0% 处的颜色
          //         },
          //         {
          //           offset: 1,
          //           color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
          //         },
          //       ],
          //       global: false, // 缺省为 false
          //     },
          //   },
          //   data: this.dataValue22,
          // },
        ],
      };
    },
    chartLineOption52() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "实际",
            type: "line",
            // smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue21,
          },
          {
            name: "预测",
            type: "line",
            // smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#41F39A",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#41F39A", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.dataValue22,
          },
        ],
      };
    },
    chartLineOption53() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "实际",
            type: "line",
            // smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue21,
          },
          {
            name: "预测",
            type: "line",
            // smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#41F39A",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#41F39A", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.dataValue22,
          },
        ],
      };
    },
    chartLineOption54() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "实际",
            type: "line",
            smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue31,
          },
          {
            name: "预测",
            type: "line",
            smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#41F39A",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#41F39A", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.dataValue32,
          },
        ],
      };
    },
  },
  methods: {
    changeAlg(v) {
      // this.isShow11 = false;
      // this.isShow22 = true;
      console.log("v", v);
      if (v === "yc") {
        this.options222 = [
          { name: "电量预测", code: "dlyc" },
          { name: "冷量预测", code: "llyc" },
        ];
        this.query.serviceId = "dlyc"
        this.query.equipId = "1ice"
      } else {
        this.options222 = [{ name: "POS冷冻系统优化", code: "POS" }];
        this.query.serviceId = "POS"
        this.query.equipId = "1ice"
      }
      this.$nextTick(() => {
        // this.$refs.chart11.resize();
        // this.$refs.chart12.resize();
        // this.$refs.chart13.resize();
        // this.$refs.chart14.resize();
        if (this.$refs.chart41) {
          this.$refs.chart41.resize();
        }
        if (this.$refs.chart21) {
          this.$refs.chart21.resize();
        }
        if (this.$refs.chart22) {
          this.$refs.chart22.resize();
        }
        if (this.$refs.chart11) {
          this.$refs.chart11.resize();
        }
        if (this.$refs.chart12) {
          this.$refs.chart12.resize();
        }
        if (this.$refs.chart13) {
          this.$refs.chart13.resize();
        }
        if (this.$refs.chart14) {
          this.$refs.chart14.resize();
        }
        if (this.$refs.chart31) {
          this.$refs.chart31.resize();
        }
        if (this.$refs.chart32) {
          this.$refs.chart32.resize();
        }
        if (this.$refs.chart33) {
          this.$refs.chart33.resize();
        }
        if (this.$refs.chart34) {
          this.$refs.chart34.resize();
        }

        if (this.$refs.chart51) {
          this.$refs.chart51.resize();
        }
        if (this.$refs.chart52) {
          this.$refs.chart52.resize();
        }
        if (this.$refs.chart53) {
          this.$refs.chart53.resize();
        }
        if (this.$refs.chart54) {
          this.$refs.chart54.resize();
        }
        // this.options2.forEach((item) => {
        //   if (item.code === v) {
        //     this.currentName = item.name;
        //   }
        // });
      });
    },
    changeService(v) {
      // this.isShow11 = false;
      // this.isShow22 = true;
      this.$nextTick(() => {
        // this.$refs.chart11.resize();
        // this.$refs.chart12.resize();
        // this.$refs.chart13.resize();
        // this.$refs.chart14.resize();
        if (this.$refs.chart41) {
          this.$refs.chart41.resize();
        }
        if (this.$refs.chart21) {
          this.$refs.chart21.resize();
        }
        if (this.$refs.chart22) {
          this.$refs.chart22.resize();
        }
        if (this.$refs.chart11) {
          this.$refs.chart11.resize();
        }
        if (this.$refs.chart12) {
          this.$refs.chart12.resize();
        }
        if (this.$refs.chart13) {
          this.$refs.chart13.resize();
        }
        if (this.$refs.chart14) {
          this.$refs.chart14.resize();
        }
        if (this.$refs.chart31) {
          this.$refs.chart31.resize();
        }
        if (this.$refs.chart32) {
          this.$refs.chart32.resize();
        }
        if (this.$refs.chart33) {
          this.$refs.chart33.resize();
        }
        if (this.$refs.chart34) {
          this.$refs.chart34.resize();
        }

        if (this.$refs.chart51) {
          this.$refs.chart51.resize();
        }
        if (this.$refs.chart52) {
          this.$refs.chart52.resize();
        }
        if (this.$refs.chart53) {
          this.$refs.chart53.resize();
        }
        if (this.$refs.chart54) {
          this.$refs.chart54.resize();
        }
        this.options2.forEach((item) => {
          if (item.code === v) {
            this.currentName = item.name;
          }
        });
      });
    },
    changeEquip(v) {
      console.log("v", v);
      this.options2.forEach((item) => {
        if (item.code === v) {
          this.currentNameTemp = item.name;
        }
      });
      // this.isShow11 = false;
      // this.isShow22 = true;
      // let dateT = new Date();
      // let hour = dateT.getHours();
      // console.log("hour", hour);
      // let temp = [];

      // this.dataValue111.forEach((item, index) => {
      //   if (index <= hour + 1) {
      //     temp.push(item);
      //   }
      // });
      // this.dataValue111 = temp;
      // let temp2 = [];
      // this.dataValue222.forEach((item, index) => {
      //   if (index <= hour + 1) {
      //     temp2.push(item);
      //   }
      // });
      // this.dataValue222 = temp2;
      // this.split111 = hour;
      // let temp3 = [];
      // this.dataValue333.forEach((item, index) => {
      //   if (index <= hour + 1) {
      //     temp3.push(item);
      //   }
      // });
      // this.dataValue333 = temp3;
      // let temp4 = [];
      // this.dataValue444.forEach((item, index) => {
      //   if (index <= hour + 1) {
      //     temp4.push(item);
      //   }
      // });
      // this.dataValue444 = temp4;

      // this.$nextTick(() => {
      //   // this.$refs.chart11.resize();
      //   // this.$refs.chart12.resize();
      //   // this.$refs.chart13.resize();
      //   // this.$refs.chart14.resize();
      //   if (this.$refs.chart41) {
      //     this.$refs.chart41.resize();
      //   }
      //   if (this.$refs.chart21) {
      //     this.$refs.chart21.resize();
      //   }
      //   if (this.$refs.chart22) {
      //     this.$refs.chart22.resize();
      //   }
      //   if (this.$refs.chart11) {
      //     this.$refs.chart11.resize();
      //   }
      //   if (this.$refs.chart12) {
      //     this.$refs.chart12.resize();
      //   }
      //   if (this.$refs.chart13) {
      //     this.$refs.chart13.resize();
      //   }
      //   if (this.$refs.chart14) {
      //     this.$refs.chart14.resize();
      //   }
      //   if (this.$refs.chart31) {
      //     this.$refs.chart31.resize();
      //   }
      //   if (this.$refs.chart32) {
      //     this.$refs.chart32.resize();
      //   }
      //   if (this.$refs.chart33) {
      //     this.$refs.chart33.resize();
      //   }
      //   if (this.$refs.chart34) {
      //     this.$refs.chart34.resize();
      //   }

      //   if (this.$refs.chart51) {
      //     this.$refs.chart51.resize();
      //   }
      //   if (this.$refs.chart52) {
      //     this.$refs.chart52.resize();
      //   }
      //   if (this.$refs.chart53) {
      //     this.$refs.chart53.resize();
      //   }
      //   if (this.$refs.chart54) {
      //     this.$refs.chart54.resize();
      //   }
      //   this.options2.forEach((item) => {
      //     if (item.code === v) {
      //       this.currentName = item.name;
      //     }
      //   });
      // });
    },
    resetPassword(id) {
      this.tempUserId = id;
      this.changeReVisible = true;
    },
    sureReset() {
      resetPassword(this.tempUserId).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("重置密码成功，新密码为123456");
          this.changeReVisible = false;
        }
      });
    },
    ...mapMutations(["mutationsGetInstance"]),
    toDetail(id) {
      // alarmSetGet(id).then((resA) => {
      //   console.log(resA)
      // })
      // this.$router.push({
      //   name: "alarmsetDetail",
      //   query: { id: id },
      // });

      alarmSetGet(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          getInstanceAttr(resA.data.data.instanceId).then((res2) => {
            // debugger
            // this.options2[v] = res2.data.data;
            // console.log("this.options2", this.options2);
            // let temp = JSON.parse(JSON.stringify(this.options2));
            // this.options2 = temp;
            this.optionsInstanceAttr = res2.data.data;
            this.changePasswordVisible = true;
            this.isEdit = false;
            this.isView = true;
            this.ruleForm = resA.data.data;
            this.ruleForm.periodTime =
              (this.ruleForm.periodTime / 60).toString() + "分钟";
          });
        }
      });
    },
    showUpdate(id) {
      getUserDetail(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          // getInstanceAttr(resA.data.data.instanceId).then((res2) => {
          //   // debugger
          //   // this.options2[v] = res2.data.data;
          //   // console.log("this.options2", this.options2);
          //   // let temp = JSON.parse(JSON.stringify(this.options2));
          //   // this.options2 = temp;
          //   this.optionsInstanceAttr = res2.data.data;
          //   this.changePasswordVisible = true;
          //   this.isEdit = true;
          //   this.isView = false;
          //   this.ruleForm = resA.data.data;
          // });
          this.userSettingTitle = "编辑用户";
          this.changePasswordVisible = true;
          this.isEdit = true;
          this.isView = false;
          this.ruleForm = resA.data.data;
          // this.ruleForm.reportName = resA.data.data.name;
          // this.selectTimeV21 = resA.data.data.beginDay;
          // this.selectTimeV = resA.data.data.beginHour;
          // this.ruleForm.reportTypesArray = [];
          // this.options2 = [[]];
          // getReportType().then((res) => {
          //   this.options1 = res.data.data;
          //   console.log(
          //     "resA.data.data.reportTypeAndParas",
          //     resA.data.data.reportTypeAndParas
          //   );
          //   // resA.data.data.reportTypeAndParas.forEach((item1, index1) => {
          //   // this.ruleForm.reportTypesArray.push({
          //   //   params1: item1.reportTypeName
          //   // });
          //   // debugger;
          //   for (let i = 0; i < resA.data.data.reportTypeAndParas.length; i++) {
          //     getReportTypeParams(
          //       resA.data.data.reportTypeAndParas[i].reportType
          //     ).then((res2) => {
          //       // debugger
          //       if (resA.data.data.reportTypeAndParas[i].reportParas) {
          //         this.options2[i] = res2.data.data;
          //         this.ruleForm.reportTypesArray.push({
          //           params1: resA.data.data.reportTypeAndParas[i].reportType,
          //           params2: [],
          //         });
          //         resA.data.data.reportTypeAndParas[i].reportParas.forEach(
          //           (item2) => {
          //             this.ruleForm.reportTypesArray[i].params2.push(
          //               item2.reportType
          //             );
          //           }
          //         );
          //       } else {
          //         this.ruleForm.reportTypesArray.push({
          //           params1: resA.data.data.reportTypeAndParas[i].reportType,
          //           params2: [],
          //         });
          //         this.options2[i] = [];
          //       }
          //       this.$nextTick(() => {
          //         let temp1 = JSON.parse(JSON.stringify(this.ruleForm));
          //         let temp2 = JSON.parse(JSON.stringify(this.options2));
          //         this.ruleForm = temp1;
          //         this.options2 = temp2;
          //         console.log(
          //           "111",
          //           this.options2,
          //           this.ruleForm.reportTypesArray
          //         );
          //       });
          //     });
          //   }

          //   // });
          // });
        }
      });
    },
    changeStatus(id, index) {
      if (index === 0) {
        this.$confirm("确认暂停？")
          .then(() => {
            // done();
            reportManagechangeStatus(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      } else if (index === 1) {
        this.$confirm("确认发布？")
          .then(() => {
            // done();
            reportManagechangeStatus(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      }
    },
    deleteReport(id) {
      this.$confirm("确认删除？")
        .then(() => {
          // done();
          deleteUser(id).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.searchFor();
            }
          });
        })
        .catch(() => {});
    },
    toBind(id) {
      console.log(id);
      this.treeReportId = id;
      getInstanceTree(id).then((res) => {
        console.log(res);
        if (res.data.data) {
          if (res.data.data.treeNodeList.length > 0) {
            this.testList = res.data.data.treeNodeList;
          }
          this.treeForm.treeName = res.data.data.treeName;
        }

        this.changeTreeVisible = true;
      });
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      console.log(command);
      if (command.command === "a") {
        this.testList[command.index].childNodeList.push({
          childNodeList: [],
          nodeName: "",
          show: true,
        });
      } else if (command.command === "b") {
        this.testList.splice(command.index, 1);
      }
    },
    addTreeNode() {
      this.testList.push({
        childNodeList: [],
        nodeName: "",
        show: true,
      });
    },
    deleteTree(index) {
      this.testList.splice(index, 1);
    },
    selectTimeOption(item, e) {
      e.stopPropagation();
      this.selectTimeV = item;
      this.isShowDay = false;
    },
    selectTimeOption2(item, e) {
      e.stopPropagation();
      this.selectTimeV21 = item;
      this.isShowMonth = false;
    },
    showDaySelect() {
      this.isShowDay = !this.isShowDay;
    },
    showMonthSelect() {
      this.isShowMonth = !this.isShowMonth;
    },
    addTypes() {
      this.ruleForm.reportTypesArray.push({
        params1: "",
        params2: [],
      });
      this.options2.push([]);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    deleteTypes(index) {
      console.log(index);
      this.ruleForm.reportTypesArray.splice(index, 1);
      this.options2.splice(index, 1);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    getOption(e) {
      console.log(e);
      // getInstanceAttr(e).then((res2) => {
      //   // debugger
      //   // this.options2[v] = res2.data.data;
      //   // console.log("this.options2", this.options2);
      //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //   // this.options2 = temp;
      //   this.optionsInstanceAttr = res2.data.data;
      //   this.ruleForm.maxDisplay = "";
      //   this.ruleForm.minDisplay = "";
      //   this.ruleForm.attrId = "";
      // });
    },
    getOption2(e) {
      console.log(e);
      // this.optionsInstanceAttr.forEach((item) => {
      //   if (item.attrId === e) {
      //     if (item.maxDisplay !== null) {
      //       this.ruleForm.maxDisplay = item.maxDisplay;
      //     }
      //     if (item.minDisplay !== null) {
      //       this.ruleForm.minDisplay = item.minDisplay;
      //     }
      //   }
      // });
      // getInstanceAttr(e).then((res2) => {
      //   // debugger
      //   // this.options2[v] = res2.data.data;
      //   // console.log("this.options2", this.options2);
      //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //   // this.options2 = temp;
      //   this.optionsInstanceAttr = res2.data.data;
      // });
    },
    toAdd() {
      this.changePasswordVisible = true;
      this.isEdit = false;
      this.isView = false;
      this.userSettingTitle = "新增用户";
      this.ruleForm = {
        // alarmId: "",
        userId: "",
        username: "",
        dataPrivilegeGroupIdList: [],
        roleIdList: [],
        newPassword: "",
        confirmPassword: "",
        expireDate: "",
        // maxDisplay: 0,
        // minDisplay: 0,
        // // periodMinute: "",
        // released: false,
      };
    },
    sizeChange(val) {
      this.query.page = 1;
      this.query.limit = val;
      this.searchFor();
    },
    pageChange(val) {
      this.query.page = val;
      this.searchFor();
    },
    searchFor() {
      // this.$router.push({ name: "alarmDetail" });
      // console.log("this.value1", this.value1);
      // this.loading = true;
      // userSearch(this.query).then((res) => {
      //   console.log(res);
      //   this.loading = false;
      //   this.tableData = res.data.data;
      //   this.total = res.data.count;
      // });
      let dateT = new Date();
      let hour = dateT.getHours();
      console.log("hour", hour);
      // let temp = [];
      this.split111 = hour;

      if (
        this.query.algId === "yh" &&
        this.query.serviceId === "POS" &&
        this.query.equipId === "1ice"
      ) {
        this.isShowIce1 = true;
        this.isShowIce2 = false;
        this.isShowIce3 = false;
        let temp = [];
        this.dataValue111.forEach((item, index) => {
          if (index <= hour + 1) {
            temp.push(item);
          }
        });
        this.dataValue111 = temp;
        let temp2 = [];
        this.dataValue222.forEach((item, index) => {
          if (index <= hour + 1) {
            temp2.push(item);
          }
        });
        this.dataValue222 = temp2;
        let temp3 = [];
        this.dataValue333.forEach((item, index) => {
          if (index <= hour + 1) {
            temp3.push(item);
          }
        });
        this.dataValue333 = temp3;
        let temp4 = [];
        this.dataValue444.forEach((item, index) => {
          if (index <= hour + 1) {
            temp4.push(item);
          }
        });
        this.dataValue444 = temp4;
      }

      if (
        this.query.algId === "yh" &&
        this.query.serviceId === "POS" &&
        this.query.equipId === "2ice"
      ) {
        this.isShowIce1 = false;
        this.isShowIce2 = true;
        this.isShowIce3 = false;
        let temp = [];
        this.dataValue555.forEach((item, index) => {
          if (index <= hour + 1) {
            temp.push(item);
          }
        });
        this.dataValue555 = temp;
        let temp2 = [];
        this.dataValue666.forEach((item, index) => {
          if (index <= hour + 1) {
            temp2.push(item);
          }
        });
        this.dataValue666 = temp2;
        let temp3 = [];
        this.dataValue777.forEach((item, index) => {
          if (index <= hour + 1) {
            temp3.push(item);
          }
        });
        this.dataValue777 = temp3;
      }
      if (
        this.query.algId === "yh" &&
        this.query.serviceId === "POS" &&
        this.query.equipId === "3ice"
      ) {
        console.log("1");
        this.isShowIce1 = false;
        this.isShowIce2 = false;
        this.isShowIce3 = true;
        let temp = [];
        this.dataValue888.forEach((item, index) => {
          if (index <= hour + 1) {
            temp.push(item);
          }
        });
        this.dataValue888 = temp;
        let temp2 = [];
        this.dataValue999.forEach((item, index) => {
          if (index <= hour + 1) {
            temp2.push(item);
          }
        });
        this.dataValue999 = temp2;
        let temp3 = [];
        this.dataValue101010.forEach((item, index) => {
          if (index <= hour + 1) {
            temp3.push(item);
          }
        });
        this.dataValue101010 = temp3;
        let temp4 = [];
        this.dataValue111111.forEach((item, index) => {
          if (index <= hour + 1) {
            temp4.push(item);
          }
        });
        this.dataValue111111 = temp4;
      }
      if (
        this.query.algId === "yc" &&
        this.query.serviceId === "dlyc"
      ) {
        this.isShowIce1 = false;
        this.isShowIce2 = false;
        this.isShowIce3 = false;
        this.isShowYc = true;
        this.isShowLLYc = false;
        this.isShowDLYc = true;
        let temp = [];
        this.dataValue121212.forEach((item, index) => {
          if (index <= hour + 1) {
            temp.push(item);
          }
        });
        this.dataValue121212 = temp;
      }
      if (
        this.query.algId === "yc" &&
        this.query.serviceId === "llyc"
      ) {
        this.isShowIce1 = false;
        this.isShowIce2 = false;
        this.isShowIce3 = false;
        this.isShowYc = true;
        this.isShowLLYc = true;
        this.isShowDLYc = false;
        let temp = [];
        this.dataValue121212.forEach((item, index) => {
          if (index <= hour + 1) {
            temp.push(item);
          }
        });
        this.dataValue121212 = temp;
      }
      this.$nextTick(() => {
        // this.$refs.chart11.resize();
        // this.$refs.chart12.resize();
        // this.$refs.chart13.resize();
        // this.$refs.chart14.resize();
        if (this.$refs.chart41) {
          this.$refs.chart41.resize();
        }
        if (this.$refs.chart21) {
          this.$refs.chart21.resize();
        }
        if (this.$refs.chart22) {
          this.$refs.chart22.resize();
        }
        if (this.$refs.chart11) {
          this.$refs.chart11.resize();
        }
        if (this.$refs.chart12) {
          this.$refs.chart12.resize();
        }
        if (this.$refs.chart13) {
          this.$refs.chart13.resize();
        }
        if (this.$refs.chart14) {
          this.$refs.chart14.resize();
        }
        if (this.$refs.chart31) {
          this.$refs.chart31.resize();
        }
        if (this.$refs.chart32) {
          this.$refs.chart32.resize();
        }
        if (this.$refs.chart33) {
          this.$refs.chart33.resize();
        }
        if (this.$refs.chart34) {
          this.$refs.chart34.resize();
        }

        if (this.$refs.chart51) {
          this.$refs.chart51.resize();
        }
        if (this.$refs.chart52) {
          this.$refs.chart52.resize();
        }
        if (this.$refs.chart53) {
          this.$refs.chart53.resize();
        }
        if (this.$refs.chart54) {
          this.$refs.chart54.resize();
        }
        // this.options2.forEach((item) => {
        //   if (item.code === v) {
        //     this.currentName = item.name;
        //   }
        // });
        this.currentName = this.currentNameTemp;
      });
    },
    reportTypeV(v) {
      let temp = [];
      v.forEach((item) => {
        temp.push(item);
      });
      temp = temp.join(",");
      return temp;
    },
    reportTypeV2(v) {
      let temp = [];
      v.forEach((item) => {
        if (item.reportParas) {
          item.reportParas.forEach((item2) => {
            temp.push(item2.reportTypeName);
          });
        }
      });
      temp = temp.join(",");
      return temp;
    },
    timeChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    clickTime(index) {
      this.isActivenTime = index;
      this.value1 = "";
      this.value2 = [];
      if (index == 1) {
        this.isInterval = false;
        this.changeDateType = "date";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD;
      } else if (index == 2) {
        this.isInterval = false;
        this.changeDateType = "month";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 3) {
        this.isInterval = false;
        this.changeDateType = "year";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 4) {
        this.isInterval = true;
        let dateT = new Date();
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value11 = YY + MM + DD;
        dateT = new Date(this.value11);
        dateT = dateT.setDate(dateT.getDate() + 1);
        this.value22 = this.formatDate(dateT);
        this.value22 = this.value22.split(" ")[0];
        console.log("this.value1", this.value11);
        console.log("this.value2", this.value22);
        this.value2 = [this.value11, this.value22];
        this.query.startTime = this.value11;
        this.query.endTime = this.value22;
      }
    },
    clickX() {},
    clickOptionX() {},
    clickY() {},
    clickOptionY() {},
    onSearch() {
      this.loading = true;
      this.tableData = [];
      this.tableKey = [];
      this.tableKey2 = [];
      if (this.isActivenTime == 1) {
        this.query.startTime = this.value1;
        this.query.endTime = "";
        this.query.unit = "DAYS";
      } else if (this.isActivenTime == 2) {
        this.query.startTime =
          this.value1.split("-")[0] + "-" + this.value1.split("-")[1];
        this.query.endTime = "";
        this.query.unit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        this.query.startTime = this.value1.split("-")[0];
        this.query.endTime = "";
        this.query.unit = "YEARS";
      } else if (this.isActivenTime == 4) {
        // this.query.startTime = this.value1;
        // this.query.endTime = "";
        this.query.unit = null;
      }
      // reportSearch(this.query).then((res2) => {
      //   this.loading = false;
      //   console.log(res2);
      //   this.tableData = res2.data.data.rows;
      //   // this.tableKey = res2.data.data.timestamps;
      //   let temp = [];
      //   res2.data.data.timestamps.forEach((item) => {
      //     temp.push(this.formatDate(item));
      //   });
      //   this.tableKey = temp;
      //   this.tableKey2 = res2.data.data.columns;
      // });
    },
    bindTree() {
      let temp = {
        reportId: this.treeReportId,
        treeName: this.treeForm.treeName,
        treeNodeList: this.testList,
      };
      bindInstanceTree(temp).then((res) => {
        if (res.data.code === 0) {
          this.changeTreeVisible = false;
          this.$message.success("绑定成功");
          this.searchFor();
        }
        // console.log(res);
      });
    },
    addNew() {
      // let temp = {
      //   alarmId: "",
      //   alarmLevelId: "",
      //   alarmLevelName: "",
      //   alarmType: "",
      //   attrId: "",
      //   attrName: "",
      //   instanceId: "",
      //   instanceName: "",
      //   maxDisplay: 0,
      //   minDisplay: 0,
      //   periodMinute: "",
      //   released: true,
      // };
      if (this.isView) {
        this.changePasswordVisible = false;
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            // alarmSetCheck(this.ruleForm).then((resC) => {
            // if (!resC.data.data.exist) {
            if (!this.isEdit) {
              this.ruleForm.pwd = this.ruleForm.newPassword;
              addUser(this.ruleForm).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.$message.success("新增成功");
                  this.changePasswordVisible = false;
                  this.searchFor();
                }
              });
            } else {
              if (this.ruleForm.pwd) {
                delete this.ruleForm.pwd;
              }
              let dateT = new Date();
              this.ruleForm.updateTime = this.formatDate(dateT);
              editUser(this.ruleForm).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.changePasswordVisible = false;
                  this.$message.success("编辑成功");
                  this.searchFor();
                }
              });
            }
            // } else {
            //   // this.$message.error("该告警类型已配置，请编辑");
            //   this.$message({
            //     showClose: false,
            //     message: "该告警类型已配置，请编辑",
            //     type: "error",
            //     duration: 7000,
            //   });
            //   alarmSetGet(resC.data.data.alarmId).then((res) => {
            //     console.log(res);
            //     if (res.data.code === 0) {
            //       // this.$message.success("修改状态成功");
            //       getInstanceAttr(res.data.data.instanceId).then((res2) => {
            //         // debugger
            //         // this.options2[v] = res2.data.data;
            //         // console.log("this.options2", this.options2);
            //         // let temp = JSON.parse(JSON.stringify(this.options2));
            //         // this.options2 = temp;
            //         this.optionsInstanceAttr = res2.data.data;
            //         this.changePasswordVisible = true;
            //         this.isEdit = true;
            //         this.isView = false;
            //         this.ruleForm = res.data.data;
            //       });
            //     }
            //   });
            // }
            // });
            // this.ruleForm.beginDay = this.selectTimeV21;
            // this.ruleForm.beginHour = this.selectTimeV;
            // let temp = [];
            // this.ruleForm.reportTypesArray.forEach((item) => {
            //   if (item.params1) {
            //     if (item.params2.length > 0) {
            //       item.params2.forEach((item3) => {
            //         temp.push(item3);
            //       });
            //     } else {
            //       temp.push(item.params1);
            //     }
            //   }
            // });
            // this.ruleForm.reportTypes = temp;
            // if (!this.isEdit) {
            //   reportManageAdd(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("新增成功");
            //       this.searchFor();
            //     }
            //   });
            // } else {
            //   reportManageupdate(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("编辑成功");
            //       this.searchFor();
            //     }
            //   });
            // }
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.el-dialog__wrapper >>> input {
  width: 350px !important;
  height: 32px !important;
}

.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
.time-square:hover {
  background: #3f6df8 !important;
  color: white !important;
}
.isSelectTime {
  background: #3f6df8 !important;
  color: white !important;
}
.small-s >>> input {
  width: 220px !important;
  height: 32px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 750px !important;
}
.inline-input >>> input {
  width: 160px !important;
}
.small-input >>> input {
  width: 160px !important;
}
.small-input >>> .el-input {
  width: 160px !important;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>