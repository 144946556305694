<template>
  <div style="width: 100%; height: 100%; background: white; padding: 15px">
    <div
      style="
        font-size: 16px;
        font-weight: 600;
        color: #082254;
        margin-bottom: 10px;
      "
    >
      数据录入
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="form-input"
    >
      <el-form-item
        label="物实例类型："
        prop="instanceType"
        style="position: relative; margin-bottom: 20px"
      >
        <el-select
          v-model="ruleForm.instanceType"
          placeholder="请选择"
          style=""
          @change="changeSelect1"
        >
          <el-option
            v-for="item in dataTabs"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item
        label="项目名称："
        prop="projectName"
        style="position: relative; margin-bottom: 20px"
      >
        <el-input :disabled="isView" v-model="ruleForm.projectName"></el-input>
      </el-form-item> -->
      <el-form-item
        label="选择物实例："
        prop="instanceIdList"
        style="position: relative; margin-bottom: 20px"
      >
        <el-select
          v-model="ruleForm.instanceIdList"
          placeholder="请选择"
          @change="changeSelect2"
          style=""
          filterable
          multiple
        >
          <el-option
            v-for="item in optionsType2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item
        label="项目面积："
        prop="projectArea"
        style="position: relative; margin-bottom: 20px"
      >
        <el-input :disabled="isView" v-model="ruleForm.projectArea"></el-input>
      </el-form-item>
      <el-form-item
        label="项目简介："
        prop="projectDesc"
        style="position: relative; margin-bottom: 20px"
      >
        <el-input :disabled="isView" v-model="ruleForm.projectDesc"></el-input>
      </el-form-item> -->
      <el-form-item
        label="物实例属性："
        prop="attrId"
        style="position: relative; margin-bottom: 20px"
      >
        <el-select
          v-model="ruleForm.attrId"
          placeholder="请选择"
          @change="getOption"
          :disabled="isView"
          filterable
        >
          <el-option
            v-for="item in optionsInstance"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="数据类型："
        prop="dataType"
        style="position: relative"
        class="inline-input"
      >
        <div style="width: 200px">
          <el-radio-group v-model="ruleForm.dataType" disabled>
            <el-radio
              v-for="(item, index) in attr1"
              :label="item.value"
              :key="index"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item
        label="时间单位："
        prop="timeUnit"
        style="position: relative; margin-bottom: 20px"
      >
        <el-select
          v-model="ruleForm.timeUnit"
          placeholder="请选择"
          @change="getOptionTime"
          :disabled="isView"
        >
          <el-option
            v-for="item in optionsTime"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="用能日期："
        prop="time"
        style="position: relative; margin-bottom: 20px"
      >
        <el-date-picker
          v-if="ruleForm.timeUnit === 'MONTHS'"
          v-model="ruleForm.time"
          type="month"
          placeholder="选择日期"
          value-format="yyyy-MM-01"
          @change="changeDate"
        >
        </el-date-picker>
        <el-date-picker
          v-else
          v-model="ruleForm.time"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="changeDate"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.timeUnit === 'HOURS'"
        label="选择时间："
        prop="hour"
        style="position: relative; margin-bottom: 20px"
      >
        <el-select
          v-model="ruleForm.hour"
          placeholder="请选择"
          @change="getOptionHour"
          :disabled="isView"
        >
          <el-option
            v-for="item in optionsHour"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div v-if="ruleForm.dataType === 'ACCUM'">
        <el-form-item
          label="仪表数据(起始值)："
          prop="begin"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input v-model="ruleForm.begin"></el-input>
        </el-form-item>

        <el-form-item
          label="仪表数据(结束值)："
          prop="end"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input v-model="ruleForm.end"></el-input>
        </el-form-item>
      </div>

      <div v-else>
        <el-form-item
          label="仪表数据(最小值)："
          prop="begin"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input v-model="ruleForm.begin"></el-input>
        </el-form-item>

        <el-form-item
          label="仪表数据(最大值)："
          prop="end"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input v-model="ruleForm.end"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div style="width: 450px; margin-top: 20px; text-align: center">
      <el-button @click="changePasswordVisible = false">取 消</el-button>
      <el-button
        type="primary"
        style="
          margin-left: 20px;
          background-color: #3f6df8;
          borde-color: #3f6df8;
        "
        :disabled="isDisabled"
        @click="addNew"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import formList from "./list";
import {
  setProject,
  getDataRecordV,
  getProjectSetting,
  getProjectSettingDetail,
  // updateProjectSetting,
  changeProjectSetting,
  deleteProjectSetting,
  resetPassword,
  getAttrByL,
  // getUserDetail,
  // addUser,
  // userSearch,
  // deleteUser,
  // alarmSetUpdate,
  // editUser,
  // alarmSetCheck,
  // alarmSearchSet,
  // alarmSetGet,
  // alarmSetLevel,
  bindInstanceTree,
  getInstanceTree,
  // alarmSetDel,
  getModuleType,
  getInstanceT,
  saveRecordV,
  // getRoleList,
  // reportManageSearch,
  // getReportType,
  // getReportTypeParams,
  // reportManageAdd,
  // reportManagechangeStatus,
  // reportManagedeatil,
  // reportManageupdate,
  // reportManageDelete,
  // getInstance,
  // getInstanceAttr,
} from "@/request/apiLg";
export default {
  // components: {
  //   formList,
  // },
  filters: {
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
  },
  data() {
    // var validConfirmPass = (rule, value, callback) => {
    //   // debugger
    //   console.log("this.ruleForm", this.ruleForm);
    //   if (
    //     value !== "" &&
    //     this.ruleForm.newPassword !== "" &&
    //     value != this.ruleForm.newPassword
    //   ) {
    //     callback(new Error("密码与上面的新密码不符"));
    //   }
    //   callback();
    // };
    return {
      isDisabled: false,
      imageUrl: "",
      userSettingTitle: "新增项目",
      tempUserId: "",
      optionsSSS: [
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      optionsSS: [],
      optionsS: [
        {
          value: "ALL",
          label: "全部",
        },
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      isEdit: false,
      isView: false,
      testList: [
        {
          nodeName: "",
          show: true,
          childNodeList: [],
        },
      ],
      // testList: [
      //   {
      //     childNodeList: [
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             instanceId: "",
      //             nodeName: "二级节点",
      //             show: false,
      //           },
      //         ],

      //         nodeName: "一级节点",
      //         show: true,
      //       },
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             nodeName: "二级节点1",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点1",
      //         show: true,
      //       },
      //     ],
      //     // instanceId: "root",
      //     nodeName: "根节点",
      //     show: true,
      //   },
      //   {
      //     childNodeList: [
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             // instanceId: "second",
      //             nodeName: "二级节点",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点",
      //         show: true,
      //       },
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             // instanceId: "second",
      //             nodeName: "二级节点1",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点1",
      //         show: true,
      //       },
      //     ],
      //     // instanceId: "root",
      //     nodeName: "根节点",
      //     show: true,
      //   },
      // ],
      listTemp: [
        {
          name: 1,
          children: [
            {
              name: 2,
              children: [
                {
                  name: 3,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      changeTreeVisible: false,
      changeReVisible: false,
      optionsInstance: [],
      optionsHour: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      optionsTime: [
        {
          value: "MONTHS",
          label: "月",
        },
        {
          value: "DAYS",
          label: "天",
        },
        {
          value: "HOURS",
          label: "小时",
        },
      ],

      optionsType: [
        {
          value: "ALL",
          label: "所有",
        },
        {
          value: "BUSINESS",
          label: "商业用户",
        },
        {
          value: "ENERGY_STATION",
          label: "能源站",
        },
        {
          value: "INDUSTRIAL",
          label: "工业用户",
        },
        {
          value: "POWER_STATION",
          label: "光伏电站",
        },
        {
          value: "STORAGE_POWER_STATION",
          label: "储能电站",
        },
      ],
      optionsType2: [],
      optionsInstanceAttr: [],
      dateV: 1,

      selectTimeV21: 1,
      selectTimeV22: 0,
      isShowMonth: false,
      selectTimeV: 0,
      timeArray: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      timeArray2: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      treeReportId: "",
      isShowDay: false,
      options1: [],
      options2: [[]],
      rules: {
        instanceType: [
          { required: true, message: "请选择物实例类型", trigger: "change" },
        ],
        instanceIdList: [
          { required: true, message: "请选择物实例", trigger: "change" },
        ],
        attrId: [
          { required: true, message: "请选择物实例属性", trigger: "change" },
        ],
        time: [
          { required: true, message: "请选择用能日期", trigger: "change" },
        ],
        begin: [{ required: true, message: "请输入数值", trigger: "blur" }],
        end: [{ required: true, message: "请输入数值", trigger: "blur" }],
        // dataPrivilegeGroupIdList: [
        //   { required: true, message: "请选择项目", trigger: "change" },
        // ],
        // roleIdList: [
        //   { required: true, message: "请选择用户角色", trigger: "change" },
        // ],
        // newPassword: [
        //   { required: true, message: "请输入密码", trigger: "blur" },
        //   { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        // ],
        // confirmPassword: [
        //   { required: true, message: "请再次输入密码", trigger: "blur" },
        //   { validator: validConfirmPass, trigger: "blur" },
        //   { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        // ],
        // expireDate: [
        //   { required: true, message: "请选择到期时间", trigger: "change" },
        // ],
      },
      ruleForm: {
        timeUnit: "MONTHS",
        hour: "00:00",
        instanceType: "",
        instanceIdList: [],
        attrId: "",
        dataType: "",
        time: "",
        // value: "",
        energyTypeId: "",
        begin: "",
        end: "",
      },
      treeForm: {
        treeName: "",
        treeArray: [1],
      },
      changePasswordVisible: false,
      total: 0,
      loading: true,
      value11: "",
      value22: "",
      currentName: "",
      isInterval: false,
      value2: [],
      tableKey: [],
      tableKey2: [],
      isborder1: false,
      tableData: [],
      changeDateType: "date",
      selectValueX: "",
      isShowSelectx: false,
      selectValueY: "",
      isShowSelecty: false,
      // options1: [
      //   {
      //     value: "P",
      //     label: "电能-有功功率",
      //   },
      // ],
      // options2: [
      //   {
      //     value: "P",
      //     label: "水能-瞬时流量",
      //   },
      // ],
      value1: "",
      // value2: "",
      isActivenTime: 1,
      query: {
        typeCode: "",
        limit: 9999999,
        page: 1,
        // projectType: "ALL",
        // projectName: "",
        // userName: "",
      },
      dataTabs: [],
      attr1: [
        { value: "INSTANT", label: "瞬时值" },
        { value: "ACCUM", label: "累计值" },
      ],
    };
  },
  mounted() {
    let dateT = new Date();
    var YY = dateT.getFullYear() + "-";
    var MM =
      dateT.getMonth() + 1 < 10
        ? "0" + (dateT.getMonth() + 1)
        : dateT.getMonth() + 1;
    // var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    this.ruleForm.time = YY + MM + "-01";
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (resF) => {
        if (resF.data.code === 0) {
          getModuleType().then((res) => {
            console.log(res.data.data);
            // this.dataTabs22 = JSON.parse(JSON.stringify(res.data.data));
            this.dataTabs = res.data.data;
            // this.dataTabs.unshift({
            //   name: "全部",
            //   code: "",
            // });

            this.ruleForm.instanceType = res.data.data[0].code;
            this.query.typeCode = this.ruleForm.instanceType;
            getInstanceT(this.query.typeCode).then((res2) => {
              this.loading = false;
              this.tableData = res2.data.data;
              // debugger
              if (res2.data.data.length > 0) {
                this.ruleForm.instanceIdList = [res2.data.data[0].instanceId];
                res2.data.data.forEach((item) => {
                  let temp = {
                    value: "",
                    label: "",
                  };
                  temp.value = item.instanceId;
                  temp.label = item.name + "[" + item.instanceId + "]";
                  this.optionsType2.push(temp);
                });
                // this.total = res2.data.count;
                getAttrByL([res2.data.data[0].instanceId]).then((resAttr) => {
                  resAttr.data.data.forEach((item) => {
                    let temp = {
                      value: "",
                      label: "",
                      energyTypeId: "",
                      dataType: "",
                    };
                    temp.value = item.attrId;
                    temp.energyTypeId = item.energyTypeId;
                    temp.dataType = item.dataType;
                    temp.label =
                      item.name +
                      "[" +
                      item.energyTypeId +
                      "_" +
                      item.attrId +
                      "]";
                    this.optionsInstance.push(temp);
                    this.ruleForm.dataType = resAttr.data.data[0].dataType;
                    this.ruleForm.energyTypeId =
                      resAttr.data.data[0].energyTypeId;
                    this.ruleForm.attrId = resAttr.data.data[0].attrId;
                  });
                  let tempDate = this.ruleForm.time + " 00:00:00";
                  let params = {
                    attrId: this.ruleForm.attrId,
                    instanceIdList: this.ruleForm.instanceIdList,
                    energyTypeId: this.ruleForm.energyTypeId,
                    time: tempDate,
                    dataType: this.ruleForm.dataType,
                    timeUnit: this.ruleForm.timeUnit,
                  };
                  getDataRecordV(params).then((resV) => {
                    this.ruleForm.begin = resV.data.data.begin;
                    this.ruleForm.end = resV.data.data.end;
                  });
                });
              } else {
                this.optionsType2 = [];
                this.optionsInstance = [];
                this.ruleForm.instanceIdList = [];
                this.ruleForm.dataType = "";
                this.ruleForm.attrId = "";
                this.ruleForm.value = "";
              }
            });
          });
        }
      }
    );

    // getProjectSetting(this.query).then((res2) => {
    //   this.loading = false;
    //   console.log(res2);
    //   this.tableData = res2.data.data;
    //   this.total = res2.data.count;
    //   // this.tableKey = res2.data.data.timestamps;
    // });
    // // getReportType().then((res) => {
    // //   this.options1 = res.data.data;
    // //   getReportTypeParams(this.options1[0].code).then((res2) => {
    // //     this.options2[0] = res2.data.data;
    // //   });
    // // });
    // // getRoleList().then((res) => {
    // //   console.log(res.data.data);
    // //   this.optionsSS = res.data.data;
    // //   // this.mutationsGetInstance(res.data.data);
    // // });
    // getGroupList().then((res) => {
    //   this.optionsInstance = res.data.data;
    //   // getInstanceAttr(this.optionsInstance[0].instanceId).then((res1) => {
    //   //   this.optionsInstanceAttr = res1.data.data;
    //   // });
    // });
  },
  computed: {
    ...mapState({
      insatnceArr: (state) => state.insatnceArr,
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
      currentProjectName: (state) => state.currentProjectName,
      projectArray: (state) => state.projectArray,
    }),
  },
  watch: {
    currentProjectId(val) {
      // debugger
      console.log(val);
      this.optionsType2 = [];
      this.optionsInstance = [];
      this.ruleForm.instanceIdList = [];
      this.ruleForm.dataType = "";
      this.ruleForm.attrId = "";
      this.ruleForm.value = "";
      // let dateT = new Date();
      // var YY = dateT.getFullYear() + "-";
      // var MM =
      //   dateT.getMonth() + 1 < 10
      //     ? "0" + (dateT.getMonth() + 1)
      //     : dateT.getMonth() + 1;
      // // var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
      // this.ruleForm.time = YY + MM;
      setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
        (resF) => {
          if (resF.data.code === 0) {
            getModuleType().then((res) => {
              console.log(res.data.data);
              // this.dataTabs22 = JSON.parse(JSON.stringify(res.data.data));
              this.dataTabs = res.data.data;
              // this.dataTabs.unshift({
              //   name: "全部",
              //   code: "",
              // });

              this.ruleForm.instanceType = res.data.data[0].code;
              this.query.typeCode = this.ruleForm.instanceType;
              getInstanceT(this.query.typeCode).then((res2) => {
                this.loading = false;
                this.tableData = res2.data.data;
                // debugger
                if (res2.data.data.length > 0) {
                  this.ruleForm.instanceIdList = [res2.data.data[0].instanceId];
                  res2.data.data.forEach((item) => {
                    let temp = {
                      value: "",
                      label: "",
                    };
                    temp.value = item.instanceId;
                    temp.label = item.name + "[" + item.instanceId + "]";
                    this.optionsType2.push(temp);
                  });
                  // this.total = res2.data.count;
                  getAttrByL([res2.data.data[0].instanceId]).then((resAttr) => {
                    resAttr.data.data.forEach((item) => {
                      let temp = {
                        value: "",
                        label: "",
                        energyTypeId: "",
                        dataType: "",
                      };
                      temp.value = item.attrId;
                      temp.dataType = item.dataType;
                      temp.label =
                        item.name +
                        "[" +
                        item.energyTypeId +
                        "_" +
                        item.attrId +
                        "]";
                      temp.energyTypeId = item.energyTypeId;
                      this.optionsInstance.push(temp);
                      this.ruleForm.dataType = resAttr.data.data[0].dataType;
                      this.ruleForm.attrId = resAttr.data.data[0].attrId;
                      this.ruleForm.energyTypeId =
                        resAttr.data.data[0].energyTypeId;
                    });
                    let tempDate = "";
                    // if (this.ruleForm.timeUnit === "MONTHS") {
                    //   tempDate = this.ruleForm.time + "-01 00:00:00";
                    // } else {
                    //   tempDate = this.ruleForm.time + " 00:00:00";
                    // }
                    tempDate = this.ruleForm.time + " 00:00:00";
                    let params = {
                      attrId: this.ruleForm.attrId,
                      instanceIdList: this.ruleForm.instanceIdList,
                      energyTypeId: this.ruleForm.energyTypeId,
                      time: tempDate,
                      dataType: this.ruleForm.dataType,
                      timeUnit: this.ruleForm.timeUnit,
                    };
                    getDataRecordV(params).then((resV) => {
                      this.ruleForm.begin = resV.data.data.begin;
                      this.ruleForm.end = resV.data.data.end;
                    });
                  });
                } else {
                  this.optionsType2 = [];
                  this.optionsInstance = [];
                  this.ruleForm.instanceIdList = "";
                  this.ruleForm.dataType = "";
                  this.ruleForm.attrId = "";
                  this.ruleForm.value = "";
                }
              });
            });
          }
        }
      );
      // if (val === 5) {
      //   this.changeZoom = "auto";
      // } else {
      //   this.changeZoom = 16;
      // }
    },
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    resetPassword(id) {
      this.tempUserId = id;
      this.changeReVisible = true;
    },
    sureReset() {
      resetPassword(this.tempUserId).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("重置密码成功，新密码为123456");
          this.changeReVisible = false;
        }
      });
    },
    ...mapMutations(["mutationsGetInstance"]),
    toDetail(id) {
      // alarmSetGet(id).then((resA) => {
      //   console.log(resA)
      // })
      this.$router.push({
        name: "projectDetail",
        query: { id: id },
      });

      // alarmSetGet(id).then((resA) => {
      //   if (resA.data.code === 0) {
      //     // this.$message.success("修改状态成功");
      //     getInstanceAttr(resA.data.data.instanceId).then((res2) => {
      //       // debugger
      //       // this.options2[v] = res2.data.data;
      //       // console.log("this.options2", this.options2);
      //       // let temp = JSON.parse(JSON.stringify(this.options2));
      //       // this.options2 = temp;
      //       this.optionsInstanceAttr = res2.data.data;
      //       this.changePasswordVisible = true;
      //       this.isEdit = false;
      //       this.isView = true;
      //       this.ruleForm = resA.data.data;
      //       this.ruleForm.periodTime =
      //         (this.ruleForm.periodTime / 60).toString() + "分钟";
      //     });
      //   }
      // });
    },
    showUpdate(id) {
      getProjectSettingDetail(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          // getInstanceAttr(resA.data.data.instanceId).then((res2) => {
          //   // debugger
          //   // this.options2[v] = res2.data.data;
          //   // console.log("this.options2", this.options2);
          //   // let temp = JSON.parse(JSON.stringify(this.options2));
          //   // this.options2 = temp;
          //   this.optionsInstanceAttr = res2.data.data;
          //   this.changePasswordVisible = true;
          //   this.isEdit = true;
          //   this.isView = false;
          //   this.ruleForm = resA.data.data;
          // });
          this.userSettingTitle = "编辑项目";
          this.changePasswordVisible = true;
          this.isEdit = true;
          this.isView = false;
          this.ruleForm = resA.data.data;
          // this.ruleForm.reportName = resA.data.data.name;
          // this.selectTimeV21 = resA.data.data.beginDay;
          // this.selectTimeV = resA.data.data.beginHour;
          // this.ruleForm.reportTypesArray = [];
          // this.options2 = [[]];
          // getReportType().then((res) => {
          //   this.options1 = res.data.data;
          //   console.log(
          //     "resA.data.data.reportTypeAndParas",
          //     resA.data.data.reportTypeAndParas
          //   );
          //   // resA.data.data.reportTypeAndParas.forEach((item1, index1) => {
          //   // this.ruleForm.reportTypesArray.push({
          //   //   params1: item1.reportTypeName
          //   // });
          //   // debugger;
          //   for (let i = 0; i < resA.data.data.reportTypeAndParas.length; i++) {
          //     getReportTypeParams(
          //       resA.data.data.reportTypeAndParas[i].reportType
          //     ).then((res2) => {
          //       // debugger
          //       if (resA.data.data.reportTypeAndParas[i].reportParas) {
          //         this.options2[i] = res2.data.data;
          //         this.ruleForm.reportTypesArray.push({
          //           params1: resA.data.data.reportTypeAndParas[i].reportType,
          //           params2: [],
          //         });
          //         resA.data.data.reportTypeAndParas[i].reportParas.forEach(
          //           (item2) => {
          //             this.ruleForm.reportTypesArray[i].params2.push(
          //               item2.reportType
          //             );
          //           }
          //         );
          //       } else {
          //         this.ruleForm.reportTypesArray.push({
          //           params1: resA.data.data.reportTypeAndParas[i].reportType,
          //           params2: [],
          //         });
          //         this.options2[i] = [];
          //       }
          //       this.$nextTick(() => {
          //         let temp1 = JSON.parse(JSON.stringify(this.ruleForm));
          //         let temp2 = JSON.parse(JSON.stringify(this.options2));
          //         this.ruleForm = temp1;
          //         this.options2 = temp2;
          //         console.log(
          //           "111",
          //           this.options2,
          //           this.ruleForm.reportTypesArray
          //         );
          //       });
          //     });
          //   }

          //   // });
          // });
        }
      });
    },
    changeStatus(id, index) {
      // debugger
      if (index === 0) {
        this.$confirm("确认暂停？")
          .then(() => {
            // done();
            changeProjectSetting(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      } else if (index === 1) {
        this.$confirm("确认发布？")
          .then(() => {
            // done();
            changeProjectSetting(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      }
    },
    deleteReport(id) {
      this.$confirm("确认删除？")
        .then(() => {
          // done();
          deleteProjectSetting(id).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.searchFor();
            }
          });
        })
        .catch(() => {});
    },
    toBind(id) {
      console.log(id);
      this.treeReportId = id;
      getInstanceTree(id).then((res) => {
        console.log(res);
        if (res.data.data) {
          if (res.data.data.treeNodeList.length > 0) {
            this.testList = res.data.data.treeNodeList;
          }
          this.treeForm.treeName = res.data.data.treeName;
        }

        this.changeTreeVisible = true;
      });
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      console.log(command);
      if (command.command === "a") {
        this.testList[command.index].childNodeList.push({
          childNodeList: [],
          nodeName: "",
          show: true,
        });
      } else if (command.command === "b") {
        this.testList.splice(command.index, 1);
      }
    },
    addTreeNode() {
      this.testList.push({
        childNodeList: [],
        nodeName: "",
        show: true,
      });
    },
    deleteTree(index) {
      this.testList.splice(index, 1);
    },
    selectTimeOption(item, e) {
      e.stopPropagation();
      this.selectTimeV = item;
      this.isShowDay = false;
    },
    selectTimeOption2(item, e) {
      e.stopPropagation();
      this.selectTimeV21 = item;
      this.isShowMonth = false;
    },
    showDaySelect() {
      this.isShowDay = !this.isShowDay;
    },
    showMonthSelect() {
      this.isShowMonth = !this.isShowMonth;
    },
    addTypes() {
      this.ruleForm.reportTypesArray.push({
        params1: "",
        params2: [],
      });
      this.options2.push([]);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    deleteTypes(index) {
      console.log(index);
      this.ruleForm.reportTypesArray.splice(index, 1);
      this.options2.splice(index, 1);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    changeSelect1(e) {
      console.log(e);
      this.optionsType2 = [];
      this.optionsInstance = [];
      // this.ruleForm.instanceType = res.data.data[0].code;
      this.query.typeCode = e;
      getInstanceT(this.query.typeCode).then((res2) => {
        this.loading = false;
        this.tableData = res2.data.data;
        if (res2.data.data.length > 0) {
          this.ruleForm.instanceIdList = [res2.data.data[0].instanceId];
          res2.data.data.forEach((item) => {
            let temp = {
              value: "",
              label: "",
            };
            temp.value = item.instanceId;
            temp.label = item.name + "[" + item.instanceId + "]";
            this.optionsType2.push(temp);
          });
          getAttrByL([res2.data.data[0].instanceId]).then((resAttr) => {
            resAttr.data.data.forEach((item) => {
              let temp = {
                value: "",
                label: "",
                energyTypeId: "",
                dataType: "",
              };
              temp.value = item.attrId;
              temp.label =
                item.name + "[" + item.energyTypeId + "_" + item.attrId + "]";
              temp.energyTypeId = item.energyTypeId;
              temp.dataType = item.dataType;
              this.optionsInstance.push(temp);
              this.ruleForm.dataType = resAttr.data.data[0].dataType;
              this.ruleForm.attrId = resAttr.data.data[0].attrId;
              this.ruleForm.energyTypeId = resAttr.data.data[0].energyTypeId;
            });
            let tempDate = "";
            tempDate = this.ruleForm.time + " " + this.ruleForm.hour + ":00";

            let params = {
              attrId: this.ruleForm.attrId,
              instanceIdList: this.ruleForm.instanceIdList,
              energyTypeId: this.ruleForm.energyTypeId,
              time: tempDate,
              dataType: this.ruleForm.dataType,
              timeUnit: this.ruleForm.timeUnit,
            };
            getDataRecordV(params).then((resV) => {
              this.ruleForm.begin = resV.data.data.begin;
              this.ruleForm.end = resV.data.data.end;
            });
          });
        } else {
          this.optionsType2 = [];
          this.optionsInstance = [];
          this.ruleForm.instanceIdList = [];
          this.ruleForm.dataType = "";
          this.ruleForm.attrId = "";
          this.ruleForm.value = "";
        }
      });
    },
    changeSelect2(e) {
      console.log(e);
      this.optionsInstance = [];
      if (e.length > 0) {
        getAttrByL(e).then((resAttr) => {
          resAttr.data.data.forEach((item) => {
            let temp = {
              value: "",
              label: "",
              energyTypeId: "",
              dataType: "",
            };
            temp.value = item.attrId;
            temp.label =
              item.name + "[" + item.energyTypeId + "_" + item.attrId + "]";
            temp.energyTypeId = item.energyTypeId;
            temp.dataType = item.dataType;
            this.optionsInstance.push(temp);
            this.ruleForm.dataType = resAttr.data.data[0].dataType;
            this.ruleForm.energyTypeId = resAttr.data.data[0].energyTypeId;
            this.ruleForm.attrId = resAttr.data.data[0].attrId;
          });
          let tempDate = "";
          tempDate = this.ruleForm.time + " " + this.ruleForm.hour + ":00";

          let params = {
            attrId: this.ruleForm.attrId,
            instanceIdList: this.ruleForm.instanceIdList,
            energyTypeId: this.ruleForm.energyTypeId,
            time: tempDate,
            dataType: this.ruleForm.dataType,
            timeUnit: this.ruleForm.timeUnit,
          };
          getDataRecordV(params).then((resV) => {
            this.ruleForm.begin = resV.data.data.begin;
            this.ruleForm.end = resV.data.data.end;
          });
        });
      }
    },
    getOption(e) {
      console.log(e);
      this.optionsInstance.forEach((item) => {
        if (item.value === e) {
          this.ruleForm.energyTypeId = item.energyTypeId;
          this.ruleForm.dataType = item.dataType;
        }
      });
      // let params = {
      //   attrId: e,
      //   instanceId: this.ruleForm.instanceId,
      //   time: this.ruleForm.time,
      //   energyTypeId: this.ruleForm.energyTypeId,
      // };
      // getDataRecordV(params).then((resV) => {
      //   this.ruleForm.begin = resV.data.data.begin;
      //   this.ruleForm.end = resV.data.data.end;
      // });
      let tempDate = "";
      tempDate = this.ruleForm.time + " " + this.ruleForm.hour + ":00";

      let params = {
        attrId: e,
        instanceIdList: this.ruleForm.instanceIdList,
        energyTypeId: this.ruleForm.energyTypeId,
        time: tempDate,
        dataType: this.ruleForm.dataType,
        timeUnit: this.ruleForm.timeUnit,
      };
      getDataRecordV(params).then((resV) => {
        this.ruleForm.begin = resV.data.data.begin;
        this.ruleForm.end = resV.data.data.end;
      });
    },
    // getOption2(e) {
    //   console.log(e);
    //   // this.optionsInstanceAttr.forEach((item) => {
    //   //   if (item.attrId === e) {
    //   //     if (item.maxDisplay !== null) {
    //   //       this.ruleForm.maxDisplay = item.maxDisplay;
    //   //     }
    //   //     if (item.minDisplay !== null) {
    //   //       this.ruleForm.minDisplay = item.minDisplay;
    //   //     }
    //   //   }
    //   // });
    //   // getInstanceAttr(e).then((res2) => {
    //   //   // debugger
    //   //   // this.options2[v] = res2.data.data;
    //   //   // console.log("this.options2", this.options2);
    //   //   // let temp = JSON.parse(JSON.stringify(this.options2));
    //   //   // this.options2 = temp;
    //   //   this.optionsInstanceAttr = res2.data.data;
    //   // });
    // },
    changeDate(e) {
      console.log(e);
      console.log(e);
      // let params = {
      //   attrId: this.ruleForm.attrId,
      //   instanceId: this.ruleForm.instanceId,
      //   time: e,
      //   energyTypeId: this.ruleForm.energyTypeId,
      // };
      // getDataRecordV(params).then((resV) => {
      //   this.ruleForm.begin = resV.data.data.begin;
      //   this.ruleForm.end = resV.data.data.end;
      // });

      let tempDate = "";
      tempDate = this.ruleForm.time + " " + this.ruleForm.hour + ":00";

      let params = {
        attrId: this.ruleForm.attrId,
        instanceIdList: this.ruleForm.instanceIdList,
        energyTypeId: this.ruleForm.energyTypeId,
        time: tempDate,
        dataType: this.ruleForm.dataType,
        timeUnit: this.ruleForm.timeUnit,
      };
      getDataRecordV(params).then((resV) => {
        this.ruleForm.begin = resV.data.data.begin;
        this.ruleForm.end = resV.data.data.end;
      });
    },
    getOptionTime(e) {
      console.log(e);
      let tempDate = "";
      if (e === "MONTHS" || e === "DAYS") {
        this.ruleForm.hour = "00:00";
      }
      tempDate = this.ruleForm.time + " " + this.ruleForm.hour + ":00";

      let params = {
        attrId: this.ruleForm.attrId,
        instanceIdList: this.ruleForm.instanceIdList,
        energyTypeId: this.ruleForm.energyTypeId,
        time: tempDate,
        dataType: this.ruleForm.dataType,
        timeUnit: e,
      };
      getDataRecordV(params).then((resV) => {
        this.ruleForm.begin = resV.data.data.begin;
        this.ruleForm.end = resV.data.data.end;
      });
    },
    getOptionHour(e) {
      console.log(e);
      console.log(e);
      let tempDate = "";
      // if (this.ruleForm.timeUnit === "MONTHS") {
      //   tempDate = this.ruleForm.time + "-01 " + e + ":00";
      // } else {
      //   tempDate = this.ruleForm.time + " " + e + ":00";
      // }
      tempDate = this.ruleForm.time + " " + e + ":00";
      let params = {
        attrId: this.ruleForm.attrId,
        instanceIdList: this.ruleForm.instanceIdList,
        energyTypeId: this.ruleForm.energyTypeId,
        time: tempDate,
        dataType: this.ruleForm.dataType,
        timeUnit: this.ruleForm.timeUnit,
      };
      getDataRecordV(params).then((resV) => {
        this.ruleForm.begin = resV.data.data.begin;
        this.ruleForm.end = resV.data.data.end;
      });
    },
    toAdd() {
      // this.changePasswordVisible = true;
      // this.isEdit = false;
      // this.isView = false;
      // this.userSettingTitle = "新增项目";
      // this.ruleForm = {
      //   enabled: false,
      //   projectArea: "",
      //   projectDesc: "",
      //   projectGroups: [],
      //   projectId: "",
      //   projectName: "",
      //   projectType: "",
      // };
    },
    sizeChange(val) {
      this.query.page = 1;
      this.query.limit = val;
      this.searchFor();
    },
    pageChange(val) {
      this.query.page = val;
      this.searchFor();
    },
    searchFor() {
      // this.$router.push({ name: "alarmDetail" });
      // console.log("this.value1", this.value1);
      this.loading = true;
      getProjectSetting(this.query).then((res) => {
        console.log(res);
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.count;
      });
    },
    reportTypeV(v) {
      let temp = [];
      v.forEach((item) => {
        temp.push(item);
      });
      temp = temp.join(",");
      return temp;
    },
    reportTypeV2(v) {
      let temp = [];
      v.forEach((item) => {
        if (item.reportParas) {
          item.reportParas.forEach((item2) => {
            temp.push(item2.reportTypeName);
          });
        }
      });
      temp = temp.join(",");
      return temp;
    },
    timeChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    clickTime(index) {
      this.isActivenTime = index;
      this.value1 = "";
      this.value2 = [];
      if (index == 1) {
        this.isInterval = false;
        this.changeDateType = "date";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD;
      } else if (index == 2) {
        this.isInterval = false;
        this.changeDateType = "month";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 3) {
        this.isInterval = false;
        this.changeDateType = "year";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 4) {
        this.isInterval = true;
        let dateT = new Date();
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value11 = YY + MM + DD;
        dateT = new Date(this.value11);
        dateT = dateT.setDate(dateT.getDate() + 1);
        this.value22 = this.formatDate(dateT);
        this.value22 = this.value22.split(" ")[0];
        console.log("this.value1", this.value11);
        console.log("this.value2", this.value22);
        this.value2 = [this.value11, this.value22];
        this.query.startTime = this.value11;
        this.query.endTime = this.value22;
      }
    },
    clickX() {},
    clickOptionX() {},
    clickY() {},
    clickOptionY() {},
    onSearch() {
      this.loading = true;
      this.tableData = [];
      this.tableKey = [];
      this.tableKey2 = [];
      if (this.isActivenTime == 1) {
        this.query.startTime = this.value1;
        this.query.endTime = "";
        this.query.unit = "DAYS";
      } else if (this.isActivenTime == 2) {
        this.query.startTime =
          this.value1.split("-")[0] + "-" + this.value1.split("-")[1];
        this.query.endTime = "";
        this.query.unit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        this.query.startTime = this.value1.split("-")[0];
        this.query.endTime = "";
        this.query.unit = "YEARS";
      } else if (this.isActivenTime == 4) {
        // this.query.startTime = this.value1;
        // this.query.endTime = "";
        this.query.unit = null;
      }
      // reportSearch(this.query).then((res2) => {
      //   this.loading = false;
      //   console.log(res2);
      //   this.tableData = res2.data.data.rows;
      //   // this.tableKey = res2.data.data.timestamps;
      //   let temp = [];
      //   res2.data.data.timestamps.forEach((item) => {
      //     temp.push(this.formatDate(item));
      //   });
      //   this.tableKey = temp;
      //   this.tableKey2 = res2.data.data.columns;
      // });
    },
    bindTree() {
      let temp = {
        reportId: this.treeReportId,
        treeName: this.treeForm.treeName,
        treeNodeList: this.testList,
      };
      bindInstanceTree(temp).then((res) => {
        if (res.data.code === 0) {
          this.changeTreeVisible = false;
          this.$message.success("绑定成功");
          this.searchFor();
        }
        // console.log(res);
      });
    },
    addNew() {
      // let temp = {
      //   alarmId: "",
      //   alarmLevelId: "",
      //   alarmLevelName: "",
      //   alarmType: "",
      //   attrId: "",
      //   attrName: "",
      //   instanceId: "",
      //   instanceName: "",
      //   maxDisplay: 0,
      //   minDisplay: 0,
      //   periodMinute: "",
      //   released: true,
      // };
      if (this.isView) {
        this.changePasswordVisible = false;
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            // alarmSetCheck(this.ruleForm).then((resC) => {
            // if (!resC.data.data.exist) {
            let temp = JSON.parse(JSON.stringify(this.ruleForm));
            temp.time = temp.time + " " + temp.hour + ":00";
            this.isDisabled = true;
            saveRecordV(temp).then((res) => {
              console.log("res", res);
              this.isDisabled = false;
              if (res.data.code === 0) {
                this.$message.success("录入成功");
                // this.changePasswordVisible = false;
                // this.searchFor();
              }
            });

            // if (!this.isEdit) {
            //   // this.ruleForm.pwd = this.ruleForm.newPassword;
            //   updateProjectSetting(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("新增成功");
            //       this.changePasswordVisible = false;
            //       this.searchFor();
            //     }
            //   });
            // } else {
            //   // if (this.ruleForm.pwd) {
            //   //   delete this.ruleForm.pwd;
            //   // }
            //   // let dateT = new Date();
            //   // this.ruleForm.updateTime = this.formatDate(dateT);
            //   updateProjectSetting(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.changePasswordVisible = false;
            //       this.$message.success("编辑成功");
            //       this.searchFor();
            //     }
            //   });
            // }
            // } else {
            //   // this.$message.error("该告警类型已配置，请编辑");
            //   this.$message({
            //     showClose: false,
            //     message: "该告警类型已配置，请编辑",
            //     type: "error",
            //     duration: 7000,
            //   });
            //   alarmSetGet(resC.data.data.alarmId).then((res) => {
            //     console.log(res);
            //     if (res.data.code === 0) {
            //       // this.$message.success("修改状态成功");
            //       getInstanceAttr(res.data.data.instanceId).then((res2) => {
            //         // debugger
            //         // this.options2[v] = res2.data.data;
            //         // console.log("this.options2", this.options2);
            //         // let temp = JSON.parse(JSON.stringify(this.options2));
            //         // this.options2 = temp;
            //         this.optionsInstanceAttr = res2.data.data;
            //         this.changePasswordVisible = true;
            //         this.isEdit = true;
            //         this.isView = false;
            //         this.ruleForm = res.data.data;
            //       });
            //     }
            //   });
            // }
            // });
            // this.ruleForm.beginDay = this.selectTimeV21;
            // this.ruleForm.beginHour = this.selectTimeV;
            // let temp = [];
            // this.ruleForm.reportTypesArray.forEach((item) => {
            //   if (item.params1) {
            //     if (item.params2.length > 0) {
            //       item.params2.forEach((item3) => {
            //         temp.push(item3);
            //       });
            //     } else {
            //       temp.push(item.params1);
            //     }
            //   }
            // });
            // this.ruleForm.reportTypes = temp;
            // if (!this.isEdit) {
            //   reportManageAdd(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("新增成功");
            //       this.searchFor();
            //     }
            //   });
            // } else {
            //   reportManageupdate(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("编辑成功");
            //       this.searchFor();
            //     }
            //   });
            // }
          }
        });
      }
    },
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style scoped>
.el-form-item >>> .el-form-item__label {
  color: #082254;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.form-input >>> input {
  width: 350px !important;
  min-height: 32px !important;
}

.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  min-height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
.time-square:hover {
  background: #3f6df8 !important;
  color: white !important;
}
.isSelectTime {
  background: #3f6df8 !important;
  color: white !important;
}
.small-s >>> input {
  width: 220px !important;
  height: 32px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 750px !important;
}
.inline-input >>> input {
  width: 160px !important;
}
.small-input >>> input {
  width: 160px !important;
}
.small-input >>> .el-input {
  width: 160px !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 350px !important;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>