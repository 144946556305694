<template>
  <div style="width: 100%; height: 100%; background: white; padding: 15px">
    <div
      style="display: flex; justify-content: flex-start; align-items: center"
    >
      <div>X轴指标：</div>
      <div
        style="position: relative; margin-left: 5px; cursor: pointer"
        class="select"
      >
        <!-- <input
                v-model="selectValue"
                readonly
                style="height: 29px; width: 140px"
              /> -->
        <el-input
          v-model="selectValueX"
          placeholder="请选择时间间隔"
          readonly
          :class="{ isBorder: isShowSelectx }"
          style="width: 160px"
          @click.native="clickX($event)"
        >
          <i slot="suffix" class="el-input__icon el-icon-arrow-down"></i>
        </el-input>

        <div
          v-if="isShowSelectx"
          style="
            position: absolute;
            z-index: 9;
            width: 160px;
            max-height: 116px;
            border: 1px solid #3f6df8;

            top: 29px;
            left: 0px;
            padding-top: 10px;
            padding-bottom: 10px;
            background: white;
            overflow: auto;
          "
        >
          <div
            style=""
            class="sel-option"
            @click="clickOptionX(item)"
            v-for="(item, index) in options1"
            :key="index"
            :class="{ activeOption2: selectValueX === item.value }"
          >
            {{ item.label }}
          </div>
        </div>
      </div>

      <div style="margin-left: 20px">Y轴指标：</div>
      <div
        style="position: relative; margin-left: 5px; cursor: pointer"
        class="select"
      >
        <!-- <input
                v-model="selectValue"
                readonly
                style="height: 29px; width: 140px"
              /> -->
        <el-input
          v-model="selectValueY"
          placeholder="请选择时间间隔"
          readonly
          :class="{ isBorder: isShowSelecty }"
          style="width: 160px"
          @click.native="clickY($event)"
        >
          <i slot="suffix" class="el-input__icon el-icon-arrow-down"></i>
        </el-input>

        <div
          v-if="isShowSelecty"
          style="
            position: absolute;
            z-index: 9;
            width: 160px;
            max-height: 116px;
            border: 1px solid #3f6df8;

            top: 29px;
            left: 0px;
            padding-top: 10px;
            padding-bottom: 10px;
            background: white;
            overflow: auto;
          "
        >
          <div
            style=""
            class="sel-option"
            @click="clickOptionY(item)"
            v-for="(item, index) in options2"
            :key="index"
            :class="{ activeOption2: selectValueY === item.value }"
          >
            {{ item.label }}
          </div>
        </div>
      </div>

      <div
        class="square2-category"
        style="border-radius: 2px 0 0 2px; margin-left: 20px"
        @click="clickTime(1)"
        :class="{ activeNH: isActivenTime === 1 }"
      >
        日
      </div>
      <div
        class="square2-category"
        style=""
        @click="clickTime(2)"
        :class="{ activeNH: isActivenTime === 2 }"
      >
        月
      </div>
      <div
        class="square2-category"
        style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
        @click="clickTime(3)"
        :class="{ activeNH: isActivenTime === 3 }"
      >
        年
      </div>
      <div style="margin-left: 20px; width: 180px" class="date">
        <el-date-picker
          style="width: 180px"
          v-model="value1"
          :type="changeDateType"
          placeholder="选择日期"
          value-format="yyyy-MM-dd 00:00:00"
        >
        </el-date-picker>
      </div>

      <div
        style="
          width: 72px;
          height: 29px;
          background: #3f6df8;
          border-radius: 2px;
          font-size: 12px;
          color: white;
          text-align: center;
          line-height: 29px;
          margin-right: 20px;
          margin-left: 20px;
          cursor: pointer;
        "
        @click="onSearch"
      >
        查询
      </div>

      <div
        style="
          width: 72px;
          height: 29px;
          background: #3f6df8;
          border-radius: 2px;
          font-size: 12px;
          color: white;
          text-align: center;
          line-height: 29px;
        "
      >
        数据导出
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectValueX: "",
      isShowSelectx: false,
      selectValueY: "",
      isShowSelecty: false,
      options1: [
        {
          value: "P",
          label: "电能-有功功率",
        },
      ],
      options2: [
        {
          value: "P",
          label: "水能-瞬时流量",
        },
      ],
      value1: "",
      value2: "",
      isActivenTime: 1,
    };
  },
  methods: {
    clickTime(index) {
      this.isActivenTime = index;

      //   if (index == 1) {
      //     this.changeDateType = "date";
      //     this.attrInterval = ["5分钟", "1小时"];
      //     this.selectValueInterval = "5分钟";
      //   } else if (index == 2) {
      //     this.changeDateType = "month";
      //     this.attrInterval = ["1小时", "1天"];
      //     this.selectValueInterval = "1小时";
      //   } else if (index == 3) {
      //     this.changeDateType = "year";
      //     this.attrInterval = ["1天", "1月"];
      //     this.selectValueInterval = "1天";
      //   }
    },
    clickX() {},
    clickOptionX() {},
    clickY() {},
    clickOptionY() {},
    onSearch(){},
  },
};
</script>

<style scoped>
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.select >>> input {
  width: 160px !important;
  height: 29px !important;
  color: #3f6df8;
  cursor: pointer;
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
.date >>> input {
  width: 180px !important;
  height: 29px !important;
}
.date >>> .el-input__icon {
  line-height: 29px !important;
}
</style>