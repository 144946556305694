<template>
  <div style="height: 100%; width: 100%; padding-top: 30px" class="big-screen">
    <div
      style="
        width: 70px;
        height: 30px;
        border: 1px solid #3f6df8;
        line-height: 30px;
        font-size: 14px;
        color: #3f6df8;
        position: absolute;
        top: 20px;
        left: 30px;
        z-index: 15;
        text-align: center;
        opacity: 0.8;
        cursor: pointer;
      "
      @click="showFull"
    >
      展示全屏
    </div>
    <div
      style="
        width: 100%;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: #bcccff;
      "
    >
      菱感能源智慧平台
    </div>
    <div style="width: 100%; text-align: center; margin-top: -10px">
      <img src="~@/assets/titleLine.png" alt />
    </div>

    <!-- <div
      style="
        width: 100%;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: #bcccff;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <img src="~@/assets/logo-1.png" style="width: 103px; height: 39px;margin-right:10px" alt />
      智慧能源聚联平台
    </div> -->

    <div style="width: 100%; text-align: center; margin-top: -50px">
      <img src="~@/assets/表头轮廓.png" alt style="width: 100%" />
    </div>
    <div style="width: 100%; margin-top: 30px; padding-left: 460px">
      <!-- <el-select v-model="valueP" placeholder="请选择" class="select-class">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <div
        @click="isShowSelectMap = !isShowSelectMap"
        style="
          width: 143px;
          height: 38px;
          border-radius: 2px;
          border: 1px solid #3f6df8;
          background: rgba(4, 23, 76, 0.9);
          position: relative;
          cursor: pointer;
        "
      >
        <div
          style="
            position: absolute;
            height: 38px;
            line-height: 38px;
            top: 0;
            left: 20px;
            font-size: 16px;
            color: #bcccff;
          "
        >
          {{ mapOption }}
        </div>
        <div
          style="
            position: absolute;
            height: 38px;
            line-height: 38px;
            top: 0;
            right: 10px;
            font-size: 16px;
            color: #406df8;
          "
        >
          <i
            class="el-icon-arrow-down"
            style="font-size: 16px; color: #406df8"
          ></i>
        </div>

        <div
          v-if="isShowSelectMap"
          style="
            position: absolute;
            z-index: 9;
            width: 143px;

            top: 37px;
            left: -1px;
            padding-top: 10px;
            padding-bottom: 10px;
            background: rgba(4, 23, 76, 0.7);
            border: 1px solid #3f6df8;
            border-top: none;
          "
        >
          <div
            style=""
            class="sel-option"
            :class="{ activeOption1: mapName === item }"
            v-for="(item, index) in dataOptionMap"
            :key="index"
            @click="clickOptionMap(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; display: flex; justify-content: center">
      <div style="position: relative">
        <img
          src="~@/assets/中国地图.png"
          alt
          @click="clearAll"
          style="width: 750px; height: 433px"
        />
        <div
          style="
            position: absolute;
            top: 215px;
            right: 150px;
            z-index: 10;
            cursor: pointer;
          "
          @mouseover="over1point"
          @click="click1point"
        >
          <img v-if="isClick1" src="~@/assets/定位点.png" alt />
          <img v-else src="~@/assets/定位点no.png" alt />
        </div>
        <div
          style="position: absolute; top: 133px; right: 156px; z-index: 9"
          v-if="isClick1"
        >
          <img src="~@/assets/光柱.png" alt />
          <div
            style="
              width: 178px;
              height: 48px;
              background: #031540;
              opacity: 0.9;
              border: 1px solid #3f6df8;
              text-align: center;
              line-height: 48px;
              font-size: 16px;
              color: white;
              position: absolute;
              top: 30px;
              left: -180px;
            "
          >
            苏州科技城文体中心
          </div>
        </div>

        <div
          style="
            position: absolute;
            top: 225px;
            right: 195px;
            z-index: 10;
            cursor: pointer;
          "
          @mouseover="over2point"
          @click="click2point"
        >
          <img v-if="isClick2" src="~@/assets/定位点.png" alt />
          <img v-else src="~@/assets/定位点no.png" alt />
        </div>
        <div
          style="position: absolute; top: 143px; right: 201px; z-index: 9"
          v-if="isClick2"
        >
          <img src="~@/assets/光柱.png" alt />
          <div
            style="
              width: 178px;
              height: 48px;
              background: #031540;
              opacity: 0.9;
              border: 1px solid #3f6df8;
              text-align: center;
              line-height: 48px;
              font-size: 16px;
              color: white;
              position: absolute;
              top: 30px;
              left: -180px;
            "
          >
            苏州科技城文体中心
          </div>
        </div>

        <div
          style="
            position: absolute;
            top: 125px;
            right: 185px;
            z-index: 10;
            cursor: pointer;
          "
          @mouseover="over3point"
          @click="click3point"
        >
          <img v-if="isClick3" src="~@/assets/定位点.png" alt />
          <img v-else src="~@/assets/定位点no.png" alt />
        </div>
        <div
          style="position: absolute; top: 43px; right: 191px; z-index: 9"
          v-if="isClick3"
        >
          <img src="~@/assets/光柱.png" alt />
          <div
            style="
              width: 178px;
              height: 48px;
              background: #031540;
              opacity: 0.9;
              border: 1px solid #3f6df8;
              text-align: center;
              line-height: 48px;
              font-size: 16px;
              color: white;
              position: absolute;
              top: 30px;
              left: -180px;
            "
          >
            苏州科技城文体中心
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%; text-align: center; margin-top: 20px">
      <img src="~@/assets/下部轮廓.png" alt style="width: 100%" />
    </div>

    <div
      style="
        width: 100%;
        height: 140px;
        margin-top: -40px;
        display: flex;
        justify-content: center;
      "
    >
      <div style="width: 373px; height: 100%; position: relative">
        <div style="width: 113px; height: 100%; float: left">
          <div style="width: 113px; height: 113px">
            <img src="~@/assets/圆环1.png" />
          </div>
          <div
            style="
              text-align: center;
              width: 100%;
              color: #8ca8fe;
              font-size: 15px;
              margin-top: 10px;
            "
          >
            用电功率(kw)
          </div>
        </div>
        <div style="width: 113px; height: 100%; float: right">
          <div style="width: 113px; height: 113px">
            <img src="~@/assets/圆环1.png" />
          </div>
          <div
            style="
              text-align: center;
              width: 100%;
              color: #8ca8fe;
              font-size: 15px;
              margin-top: 10px;
            "
          >
            发电功率(kw)
          </div>
        </div>
        <div style="clear: both"></div>
        <div
          style="
            position: absolute;
            top: 42px;
            left: 25px;
            color: #ed9c00;
            font-size: 20px;
            font-weight: bold;
            width: 68px;
            height: 26px;
            line-height: 26px;
            text-align: center;
          "
        >
          {{ allElecP }}
        </div>
        <div
          style="
            position: absolute;
            top: 42px;
            right: 25px;
            color: #53efc3;
            font-size: 20px;
            font-weight: bold;
            width: 68px;
            height: 26px;
            line-height: 26px;
            text-align: center;
          "
        >
          {{ allElecProvide }}
        </div>
      </div>
    </div>

    <div
      v-if="showModal"
      style="
        width: 671px;
        height: 375px;
        background: #031540;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
        border: 1px solid #3f6df8;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        padding: 15px;
      "
    >
      <div
        style="cursor: pointer; position: absolute; top: 5px; right: 10px"
        @click="clearAll"
      >
        <img src="@/assets/xicon.png" />
      </div>
      <div style="width: 100%; height: 100%; display: flex">
        <div style="width: 174px; height: 100%; flex: none">
          <div style="font-size: 14px; color: #bcccff; width: 100%">
            类型：商业用户
          </div>
          <div
            style="
              width: 100%;
              height: 232px;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
              margin-top: 10px;
            "
          >
            <img
              src="@/assets/插图.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
          <div
            style="
              width: 166px;
              height: 32px;
              background: #3f6df8;
              border-radius: 4px;
              margin-top: 30px;
              color: white;
              text-align: center;
              line-height: 32px;
              position: relative;
              cursor: pointer;
            "
            @click="toProjectOverall"
          >
            进入项目
            <div
              style="
                position: absolute;
                bottom: 1px;
                right: 10px;
                font-size: 20px;
              "
            >
              >
            </div>
          </div>
        </div>
        <div style="flex: 1; padding-left: 5px">
          <div style="width: 100%; text-align: center; color: #ffffff">
            苏州科技城文体中心(面积:4500m3)
          </div>
          <div style="margin-left: 5px; margin-top: 5px">
            <div
              style="
                width: 4px;
                height: 20px;
                background: #bcccff;
                display: inline-block;
                vertical-align: -4px;
              "
            ></div>
            <div
              style="display: inline-block; color: #bcccff; margin-left: 7px"
            >
              能耗监测与预测 (kWh)
            </div>
          </div>
          <div
            v-if="isModalChartShow"
            style="color: #bcccff; text-align: right; padding-right: 10px"
          >
            (℃)
          </div>
          <div
            v-if="isModalChartShow"
            style="width: 100%; height: 200px; margin-top: 10px"
          >
            <v-chart ref="chart1" :option="chartModalOption" />
          </div>

          <div
            v-if="!isModalChartShow"
            style="
              width: 100%;
              height: 200px;
              margin-top: 10px;
              text-align: center;
              line-height: 200px;
              color: rgb(188, 204, 255);
            "
          >
            暂无数据
          </div>
          <div
            style="
              font-size: 14px;
              font-weight: 500;
              color: #fff;
              height: 60px;
              margin-top: 8px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            项目简介：文体中心下设三馆三中心：文化馆、图书管、体育馆、全民健身中心、影视中心和商业中心。【1】其中，文化馆面积2万余平方米，930平米的小剧场可容纳300人，将定期举办演唱会等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等等
          </div>
        </div>
      </div>
    </div>

    <div class="square-1" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>
      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        当月用能总览
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>
      <div style="display: flex; align-items: center; margin-top: 10px">
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          用电：
        </div>
        <div class="square-number" style="">{{ elecuse[0] }}</div>
        <div class="square-number" style="">{{ elecuse[1] }}</div>
        <div class="square-number" style="">{{ elecuse[2] }}</div>
        <div class="square-number" style="">{{ elecuse[3] }}</div>
        <div class="square-number" style="">
          {{ elecuse[4] }}
        </div>

        <div class="square-number" style="margin-right: 5px">
          {{ elecuse[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>
        <div class="square-number" style="margin-right: 20px">
          {{ elecuse[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          kWh
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 10px">
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          用水：
        </div>
        <div class="square-number" style="">{{ wateruse[0] }}</div>
        <div class="square-number" style="">{{ wateruse[1] }}</div>
        <div class="square-number" style="">{{ wateruse[2] }}</div>
        <div class="square-number" style="">{{ wateruse[3] }}</div>
        <div class="square-number" style="">{{ wateruse[4] }}</div>
        <div class="square-number" style="margin-right: 5px">
          {{ wateruse[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>

        <div class="square-number" style="margin-right: 20px">
          {{ wateruse[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          m3
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 10px">
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          用气：
        </div>
        <div class="square-number" style="">{{ airuse[0] }}</div>
        <div class="square-number" style="">{{ airuse[1] }}</div>
        <div class="square-number" style="">{{ airuse[2] }}</div>
        <div class="square-number" style="">{{ airuse[3] }}</div>
        <div class="square-number" style="">{{ airuse[4] }}</div>
        <div class="square-number" style="margin-right: 5px">
          {{ airuse[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>

        <div class="square-number" style="margin-right: 20px">
          {{ airuse[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          m3
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 10px">
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-left: -14px;
          "
        >
          用蒸汽：
        </div>
        <div class="square-number" style="">{{ heatuse[0] }}</div>
        <div class="square-number" style="">{{ heatuse[1] }}</div>
        <div class="square-number" style="">{{ heatuse[2] }}</div>
        <div class="square-number" style="">{{ heatuse[3] }}</div>
        <div class="square-number" style="">{{ heatuse[4] }}</div>
        <div class="square-number" style="margin-right: 5px">
          {{ heatuse[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>

        <div class="square-number" style="margin-right: 20px">
          {{ heatuse[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          kWh
        </div>
      </div>
    </div>
    <div class="square-2" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>
      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        当年能耗
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>

      <div style="width: 100%; margin-top: 5px">
        <div style="float: right">
          <div
            class="square2-category"
            style="border-radius: 2px 0 0 2px"
            @click="clickNH(1)"
            :class="{ activeNH: isActivenNH === 1 }"
          >
            电
          </div>
          <div
            class="square2-category"
            style=""
            @click="clickNH(2)"
            :class="{ activeNH: isActivenNH === 2 }"
          >
            水
          </div>
          <div
            class="square2-category"
            style=""
            @click="clickNH(3)"
            :class="{ activeNH: isActivenNH === 3 }"
          >
            气
          </div>
          <div
            class="square2-category"
            style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
            @click="clickNH(4)"
            :class="{ activeNH: isActivenNH === 4 }"
          >
            蒸汽
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <div
        v-if="isNHshow"
        style="width: 100%; margin-top: -5px; color: #bcccff; font-size: 14px"
      >
        {{ unit2 }}
      </div>
      <div v-if="isNHshow" style="width: 100%; height: 180px">
        <v-chart ref="chart2" :option="chartOption1" />
      </div>

      <div
        v-if="!isNHshow"
        style="
          width: 100%;
          height: 180px;
          text-align: center;
          line-height: 180px;
          color: rgb(188, 204, 255);
        "
      >
        暂无数据
      </div>
    </div>

    <div class="square-3" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>

      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        运维总览
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>

      <div
        v-if="isYWshow"
        style="width: 50%; height: 130px; display: inline-block"
      >
        <v-chart ref="chart3" :option="chartOptionCircle1" />
      </div>
      <div
        v-if="isYWshow"
        style="width: 50%; height: 130px; display: inline-block"
      >
        <v-chart ref="chart4" :option="chartOptionCircle2" />
      </div>

      <div v-if="isYWshow" style="width: 100%; margin-top: -15px">
        <div
          style="
            width: 50%;
            height: 20px;
            display: inline-block;
            text-align: center;
            color: #fff;
          "
        >
          设备数:323 故障:2
        </div>
        <div
          style="
            width: 50%;
            height: 20px;
            display: inline-block;
            text-align: center;
            color: #fff;
          "
        >
          总工单:323 已完成:2
        </div>
      </div>

      <div
        v-if="!isYWshow"
        style="
          width: 100%;
          height: 130px;
          text-align: center;
          line-height: 130px;
          color: rgb(188, 204, 255);
        "
      >
        暂无数据
      </div>
    </div>

    <div class="square-4" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>

      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        当月供能总览
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>
      <div style="display: flex; align-items: center; margin-top: 10px">
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          供电：
        </div>
        <div class="square-number" style="">{{ elecprovide[0] }}</div>
        <div class="square-number" style="">{{ elecprovide[1] }}</div>
        <div class="square-number" style="">{{ elecprovide[2] }}</div>
        <div class="square-number" style="">{{ elecprovide[3] }}</div>
        <div class="square-number" style="">{{ elecprovide[4] }}</div>
        <div class="square-number" style="margin-right: 5px">
          {{ elecprovide[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>

        <div class="square-number" style="margin-right: 20px">
          {{ elecprovide[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          kWh
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 10px">
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          供冷：
        </div>
        <div class="square-number" style="">{{ iceprovide[0] }}</div>
        <div class="square-number" style="">{{ iceprovide[1] }}</div>
        <div class="square-number" style="">{{ iceprovide[2] }}</div>
        <div class="square-number" style="">{{ iceprovide[3] }}</div>
        <div class="square-number" style="">{{ iceprovide[4] }}</div>
        <div class="square-number" style="margin-right: 5px">
          {{ iceprovide[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>

        <div class="square-number" style="margin-right: 20px">
          {{ iceprovide[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          kWh
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 10px">
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          供热：
        </div>
        <div class="square-number" style="">{{ heatprovide[0] }}</div>
        <div class="square-number" style="">{{ heatprovide[1] }}</div>
        <div class="square-number" style="">{{ heatprovide[2] }}</div>
        <div class="square-number" style="">{{ heatprovide[3] }}</div>
        <div class="square-number" style="">{{ heatprovide[4] }}</div>
        <div class="square-number" style="margin-right: 5px">
          {{ heatprovide[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>

        <div class="square-number" style="margin-right: 20px">
          {{ heatprovide[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          kWh
        </div>
      </div>
    </div>
    <div class="square-5" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>

      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        当年供能量
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>

      <div style="width: 100%; margin-top: 5px">
        <div style="float: right">
          <div
            class="square2-category"
            style="border-radius: 2px 0 0 2px"
            @click="clickGN(1)"
            :class="{ activeGN: isActivenGN === 1 }"
          >
            电
          </div>
          <div
            class="square2-category"
            style=""
            @click="clickGN(2)"
            :class="{ activeGN: isActivenGN === 2 }"
          >
            冷
          </div>
          <div
            class="square2-category"
            style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
            @click="clickGN(3)"
            :class="{ activeGN: isActivenGN === 3 }"
          >
            热
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <div
        v-if="isGNshow"
        style="width: 100%; margin-top: -5px; color: #bcccff; font-size: 14px"
      >
        （kWh）
      </div>
      <div v-if="isGNshow" style="width: 100%; height: 180px">
        <v-chart ref="chart5" :option="chartOption2" />
      </div>

      <div
        v-if="!isGNshow"
        style="
          width: 100%;
          height: 180px;
          text-align: center;
          line-height: 180px;
          color: rgb(188, 204, 255);
        "
      >
        暂无数据
      </div>
    </div>
    <div class="square-6" style="padding-left: 0; padding-right: 8px">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>

      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        项目总览
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>
      <div v-if="isZLshow" style="width: 100%; height: 180px; margin-top: 10px">
        <v-chart ref="chart6" :option="chartPieOption" />
      </div>
      <div
        v-if="!isZLshow"
        style="
          width: 100%;
          height: 180px;
          text-align: center;
          line-height: 180px;
          color: rgb(188, 204, 255);
        "
      >
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
// getBigElecProvide
import { mapMutations } from "vuex";
import {
  setProject,
  getBigElecActual,
  getBigElecM,
  getBigWaterM,
  getBigGasM,
  // getBigHeatM,
  getBigSteamM,
  getYearChart,
  getYearChartWater,
  getYearChartAir,
  // getYearChartHeat,
  getYearChartSteam,
} from "@/request/apiLg";
import * as echarts from "echarts";
export default {
  data() {
    var dataAxis = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    var data = [220, 182, 191, 234, 290, 330, 310, 123, 442, 321, 90, 579];
    return {
      unit2: "(kWh)",
      isModalChartShow: false,
      isGNshow: false,
      isNHshow: false,
      isYWshow: false,
      isZLshow: false,
      dataUsed: [],
      monthDataAxis: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      elecuse: [0, 0, 0, 0, 0, 0, 0],
      wateruse: ["-", "-", "-", "-", "-", "-", "-"],
      airuse: ["-", "-", "-", "-", "-", "-", "-"],
      heatuse: ["-", "-", "-", "-", "-", "-", "-"],
      elecprovide: ["-", "-", "-", "-", "-", "-", "-"],
      iceprovide: ["-", "-", "-", "-", "-", "-", "-"],
      heatprovide: ["-", "-", "-", "-", "-", "-", "-"],
      mapOption: "全部",
      isShowSelectMap: false,
      mapName: "全部",
      dataOptionMap: [
        "全部",
        "能源站",
        "光伏电站",
        "储能电站",
        "商业用户",
        "工业用户",
      ],
      allElecP: "",
      allElecProvide: "-- --",
      isActivenNH: 1,
      isActivenGN: 1,
      isClick1: false,
      isClick2: false,
      isClick3: false,
      showModal: false,
      userName: "",
      valueP: "1",
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "选项2",
          label: "2",
        },
        {
          value: "选项3",
          label: "3",
        },
      ],
      chartModalOption: {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "30px",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ["line", "bar"] },
        //     restore: { show: true },
        //     saveAsImage: { show: true },
        //   },
        // },
        legend: {
          data: ["总用电量", "预测总用电量", "温度"],
          right: 100,
          bottom: 0,
          textStyle: {
            color: "#bcccff",
          },
        },
        xAxis: [
          {
            type: "category",
            data: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#BCCCFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "耗能",
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#BCCCFF",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "温度",
            min: 0,
            max: 25,
            interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#BCCCFF",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "总用电量",
            type: "bar",
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3,
            ],
          },
          {
            name: "预测总用电量",
            type: "line",
            // yAxisIndex: 1,
            smooth: true,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#41F39A", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          {
            name: "温度",
            type: "line",
            yAxisIndex: 1,
            data: [
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
            ],
          },
        ],
      },

      chartOption2: {
        tooltip: {
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}kWh",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "10px",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#BCCCFF",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#BCCCFF",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 5,
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#55ffbc" },
                  { offset: 0.62, color: "#47A5E1" },
                  { offset: 1, color: "#3F6DF8" },
                ]),
              },
            },
            data: data,
          },
        ],
      },
      chartPieOption: {
        color: ["#5492D1", "#46BCEE", "#ED9C00", "#53EFC3", "#1464C9"],
        grid: {
          // containLabel: true,
          // top: "10px",
          // left: "5px",
          // right: "10px",
          // bottom: "10px",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 20,
          bottom: 20,
          data: [
            {
              name: "能源站",
              textStyle: {
                color: "#1464C9",
              },
            },
            {
              name: "光伏电站",
              textStyle: {
                color: "#53EFC3",
              },
            },
            {
              name: "储能电站",
              textStyle: {
                color: "#ED9C00",
              },
            },
            {
              name: "商业用户",
              textStyle: {
                color: "#46BCEE",
              },
            },
            {
              name: "工业用户",
              textStyle: {
                color: "#5492D1",
              },
            },
          ],
          // data: data.legendData,
          // selected: data.selected,
          // textStyle: {
          //   color: "#bcccff",
          // },
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["25%", "70%"],
            center: ["40%", "50%"],
            avoidLabelOverlap: false,
            label: {
              position: "outside",
              // alignTo: "edge",
              // formatter: "{per|{c} %}\n",
              formatter: (data) => {
                if (data.name === "工业用户") {
                  return `{a|${data.percent} %\n}`;
                } else if (data.name === "商业用户") {
                  return `{b|${data.percent} %\n}`;
                } else if (data.name === "储能电站") {
                  return `{c|${data.percent} %\n}`;
                } else if (data.name === "光伏电站") {
                  return `{d|${data.percent} %\n}`;
                } else if (data.name === "能源站") {
                  return `{e|${data.percent} %\n}`;
                }
              },
              // minMargin: 5,
              // edgeDistance: 10,
              // lineHeight: 15,
              rich: {
                a: {
                  fontSize: 14,
                  color: "#5492D1",
                  // fontWeight: "bold",
                },
                b: {
                  fontSize: 14,
                  color: "#46BCEE",
                  // fontWeight: "bold",
                },
                c: {
                  fontSize: 14,
                  color: "#ED9C00",
                  // fontWeight: "bold",
                },
                d: {
                  fontSize: 14,
                  color: "#53EFC3",
                  // fontWeight: "bold",
                },
                e: {
                  fontSize: 14,
                  color: "#1464C9",
                  // fontWeight: "bold",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length2: 20,
              // smooth: true,
              // minTurnAngle: 50,
            },
            data: [
              { value: 15, name: "工业用户" },
              { value: 15, name: "商业用户" },
              { value: 40, name: "储能电站" },
              { value: 20, name: "光伏电站" },
              { value: 10, name: "能源站" },
            ],
          },
        ],
      },
      chartOptionCircle1: {
        // grid: {
        //   containLabel: true,
        //   top: "5px",
        //   left: "5px",
        //   right: "5px",
        //   bottom: "5px",
        // },
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                // borderWidth: 1,
                // borderColor: "#464646",
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#F2CA3F",
                  },
                  {
                    offset: 1,
                    color: "#ED814C",
                  },
                ]),
                // [
                //   [
                //     1,
                //     new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                //       {
                //         offset: 0,
                //         color: "#F2CA3F",
                //       },
                //       {
                //         offset: 1,
                //         color: "#ED814C",
                //       },
                //     ]),
                //   ],
                // ],
              },
            },
            axisLine: {
              lineStyle: {
                width: 8,
                color: [
                  [
                    1,
                    // new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    //   {
                    //     offset: 0,
                    //     color: "#F2CA3F",
                    //   },
                    //   {
                    //     offset: 1,
                    //     color: "#ED814C",
                    //   },
                    // ]),
                    "rgba(242, 202, 63, 0.3)",
                  ],
                ],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              {
                value: 20,
                name: "故障率",
                title: {
                  offsetCenter: ["0%", "25%"],
                },
                detail: {
                  offsetCenter: ["0%", "-25%"],
                },
              },
              // {
              //   value: 40,
              //   name: "Good",
              //   title: {
              //     offsetCenter: ["0%", "0%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "10%"],
              //   },
              // },
              // {
              //   value: 60,
              //   name: "Commonly",
              //   title: {
              //     offsetCenter: ["0%", "30%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "40%"],
              //   },
              // },
            ],
            title: {
              fontSize: 18,
              color: "#F2CA3F",
            },
            detail: {
              width: 50,
              height: 18,
              fontSize: 18,
              color: "#F2CA3F",
              // borderColor: "auto",
              // borderRadius: 20,
              // borderWidth: 1,
              formatter: "{value}%",
            },
          },
        ],
      },
      chartOptionCircle2: {
        // grid: {
        //   containLabel: true,
        //   top: "5px",
        //   left: "5px",
        //   right: "5px",
        //   bottom: "5px",
        // },
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                // borderWidth: 1,
                // borderColor: "#464646",
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#3FDFF2",
                  },
                  {
                    offset: 1,
                    color: "#3F68F2",
                  },
                ]),
                // [
                //   [
                //     1,
                //     new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                //       {
                //         offset: 0,
                //         color: "#F2CA3F",
                //       },
                //       {
                //         offset: 1,
                //         color: "#ED814C",
                //       },
                //     ]),
                //   ],
                // ],
              },
            },
            axisLine: {
              lineStyle: {
                width: 8,
                color: [
                  [
                    1,
                    // new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    //   {
                    //     offset: 0,
                    //     color: "#F2CA3F",
                    //   },
                    //   {
                    //     offset: 1,
                    //     color: "#ED814C",
                    //   },
                    // ]),
                    "rgba(4, 23, 76, 0.7)",
                  ],
                ],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              {
                value: 20,
                name: "完成率",
                title: {
                  offsetCenter: ["0%", "25%"],
                },
                detail: {
                  offsetCenter: ["0%", "-25%"],
                },
              },
              // {
              //   value: 40,
              //   name: "Good",
              //   title: {
              //     offsetCenter: ["0%", "0%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "10%"],
              //   },
              // },
              // {
              //   value: 60,
              //   name: "Commonly",
              //   title: {
              //     offsetCenter: ["0%", "30%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "40%"],
              //   },
              // },
            ],
            title: {
              fontSize: 18,
              color: "#3FDFF2",
            },
            detail: {
              width: 50,
              height: 18,
              fontSize: 18,
              color: "#3FDFF2",
              // borderColor: "auto",
              // borderRadius: 20,
              // borderWidth: 1,
              formatter: "{value}%",
            },
          },
        ],
      },
    };
  },
  created() {
    // let data = {
    //   energyAttrId: "P",
    //   energyTypeId: "elec",
    //   projectId: "wtzx",
    // };
    // getBigElecP(data).then((res) => {
    //   // debugger
    //   if (res.data) {
    //     if (res.data.data === null) {
    //       this.allElecP = "-- --";
    //     } else {
    //       this.allElecP = res.data.data;
    //     }
    //   } else {
    //     this.$message.error(res.data.msg);
    //   }
    // });
    // getBigElecActual().then(res=>{
    //   console.log(res)
    // })
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getBigElecM().then((res) => {
            console.log(res);
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.elecuse = ["-", "-", "-", "-", "-", "-", "-"];
              } else {
                // this.allElecP = res.data.data.formatValue;
                let arr1 = res.data.data.formatValue.split("");
                const hasDot = arr1.find((v) => v === ".");
                if (!hasDot) {
                  // this.elecuse[5] = 0;
                  this.elecuse[6] = 0;
                  // if(arr1.length===0){

                  // }
                  if (arr1.length === 1) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = 0;
                    this.elecuse[4] = 0;
                    this.elecuse[5] = arr1[0];
                  } else if (arr1.length === 2) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = 0;
                    this.elecuse[4] = arr1[0];
                    this.elecuse[5] = arr1[1];
                  } else if (arr1.length === 3) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = arr1[0];
                    this.elecuse[4] = arr1[1];
                    this.elecuse[5] = arr1[2];
                  } else if (arr1.length === 4) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = arr1[0];
                    this.elecuse[3] = arr1[1];
                    this.elecuse[4] = arr1[2];
                    this.elecuse[5] = arr1[3];
                    // this.elecuse[5] = arr1[4];
                  } else if (arr1.length === 5) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = arr1[0];
                    this.elecuse[2] = arr1[1];
                    this.elecuse[3] = arr1[2];
                    this.elecuse[4] = arr1[3];
                    this.elecuse[5] = arr1[4];
                  } else if (arr1.length === 6) {
                    this.elecuse[0] = arr1[0];
                    this.elecuse[1] = arr1[1];
                    this.elecuse[2] = arr1[2];
                    this.elecuse[3] = arr1[3];
                    this.elecuse[4] = arr1[4];
                    this.elecuse[5] = arr1[5];
                  }
                } else {
                  let arr2 = res.data.data.formatValue.split(".");
                  let arr3 = arr2[0].toString().split("");
                  this.elecuse[6] = arr2[1];
                  if (arr3.length === 1) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = 0;
                    this.elecuse[4] = 0;
                    this.elecuse[5] = arr3[0];
                  } else if (arr3.length === 2) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = 0;
                    this.elecuse[4] = arr3[0];
                    this.elecuse[5] = arr3[1];
                  } else if (arr3.length === 3) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = arr3[0];
                    this.elecuse[4] = arr3[1];
                    this.elecuse[5] = arr3[2];
                  } else if (arr3.length === 4) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = arr3[0];
                    this.elecuse[3] = arr3[1];
                    this.elecuse[4] = arr3[2];
                    this.elecuse[5] = arr3[3];
                  } else if (arr3.length === 5) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = arr3[0];
                    this.elecuse[2] = arr3[1];
                    this.elecuse[3] = arr3[2];
                    this.elecuse[4] = arr3[3];
                    this.elecuse[5] = arr3[4];
                  } else if (arr3.length === 6) {
                    this.elecuse[0] = arr3[0];
                    this.elecuse[1] = arr3[1];
                    this.elecuse[2] = arr3[2];
                    this.elecuse[3] = arr3[3];
                    this.elecuse[4] = arr3[4];
                    this.elecuse[5] = arr3[5];
                  }
                  // let arr2 = res.data.data.formatValue.split(".");

                  // let tempN = Math.round(Number(arr2[1] / 100));
                  // let arr3 = tempN.toString().split("");
                  // this.elecuse[5] = arr3[0];
                  // this.elecuse[6] = arr3[1];
                  // let arr4 = arr2[0].split("");
                  // if (arr4.length === 1) {
                  //   this.elecuse[0] = 0;
                  //   this.elecuse[1] = 0;
                  //   this.elecuse[2] = 0;
                  //   this.elecuse[3] = 0;
                  //   this.elecuse[4] = arr4[0];
                  // } else if (arr4.length === 2) {
                  //   this.elecuse[0] = 0;
                  //   this.elecuse[1] = 0;
                  //   this.elecuse[2] = 0;
                  //   this.elecuse[3] = arr4[0];
                  //   this.elecuse[4] = arr4[1];
                  // } else if (arr4.length === 3) {
                  //   this.elecuse[0] = 0;
                  //   this.elecuse[1] = 0;
                  //   this.elecuse[2] = arr4[0];
                  //   this.elecuse[3] = arr4[1];
                  //   this.elecuse[4] = arr4[2];
                  // } else if (arr4.length === 4) {
                  //   this.elecuse[0] = 0;
                  //   this.elecuse[1] = arr4[0];
                  //   this.elecuse[2] = arr4[1];
                  //   this.elecuse[3] = arr4[2];
                  //   this.elecuse[4] = arr4[3];
                  // } else if (arr4.length === 5) {
                  //   this.elecuse[0] = arr4[0];
                  //   this.elecuse[1] = arr4[1];
                  //   this.elecuse[2] = arr4[2];
                  //   this.elecuse[3] = arr4[3];
                  //   this.elecuse[4] = arr4[4];
                  // }
                }

                console.log("arr1", arr1);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          getBigWaterM().then((res) => {
            console.log(res);
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.wateruse = ["-", "-", "-", "-", "-", "-", "-"];
              } else {
                // this.allElecP = res.data.data.formatValue;
                let arr1 = res.data.data.formatValue.split("");
                const hasDot = arr1.find((v) => v === ".");
                if (!hasDot) {
                  // this.wateruse[5] = 0;
                  this.wateruse[6] = 0;
                  // if(arr1.length===0){

                  // }
                  if (arr1.length === 1) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = 0;
                    this.wateruse[4] = 0;
                    this.wateruse[5] = arr1[0];
                  } else if (arr1.length === 2) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = 0;
                    this.wateruse[4] = arr1[0];
                    this.wateruse[5] = arr1[1];
                  } else if (arr1.length === 3) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = arr1[0];
                    this.wateruse[4] = arr1[1];
                    this.wateruse[5] = arr1[2];
                  } else if (arr1.length === 4) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = arr1[0];
                    this.wateruse[3] = arr1[1];
                    this.wateruse[4] = arr1[2];
                    this.wateruse[5] = arr1[3];
                    // this.wateruse[5] = arr1[4];
                  } else if (arr1.length === 5) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = arr1[0];
                    this.wateruse[2] = arr1[1];
                    this.wateruse[3] = arr1[2];
                    this.wateruse[4] = arr1[3];
                    this.wateruse[5] = arr1[4];
                  } else if (arr1.length === 6) {
                    this.wateruse[0] = arr1[0];
                    this.wateruse[1] = arr1[1];
                    this.wateruse[2] = arr1[2];
                    this.wateruse[3] = arr1[3];
                    this.wateruse[4] = arr1[4];
                    this.wateruse[5] = arr1[5];
                  }
                } else {
                  let arr2 = res.data.data.formatValue.split(".");
                  let arr3 = arr2[0].toString().split("");
                  this.wateruse[6] = arr2[1];
                  if (arr3.length === 1) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = 0;
                    this.wateruse[4] = 0;
                    this.wateruse[5] = arr3[0];
                  } else if (arr3.length === 2) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = 0;
                    this.wateruse[4] = arr3[0];
                    this.wateruse[5] = arr3[1];
                  } else if (arr3.length === 3) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = arr3[0];
                    this.wateruse[4] = arr3[1];
                    this.wateruse[5] = arr3[2];
                  } else if (arr3.length === 4) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = arr1[0];
                    this.wateruse[3] = arr1[1];
                    this.wateruse[4] = arr1[2];
                    this.wateruse[5] = arr1[3];
                  } else if (arr3.length === 5) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = arr3[0];
                    this.wateruse[2] = arr3[1];
                    this.wateruse[3] = arr3[2];
                    this.wateruse[4] = arr3[3];
                    this.wateruse[5] = arr3[4];
                  } else if (arr3.length === 6) {
                    this.wateruse[0] = arr3[0];
                    this.wateruse[1] = arr3[1];
                    this.wateruse[2] = arr3[2];
                    this.wateruse[3] = arr3[3];
                    this.wateruse[4] = arr3[4];
                    this.wateruse[5] = arr3[5];
                  }
                }

                console.log("arr1", arr1);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });

          getBigGasM().then((res) => {
            console.log(res);
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.airuse = ["-", "-", "-", "-", "-", "-", "-"];
              } else {
                // this.allElecP = res.data.data.formatValue;
                let arr1 = res.data.data.formatValue.split("");
                const hasDot = arr1.find((v) => v === ".");
                if (!hasDot) {
                  // this.airuse[5] = 0;
                  this.airuse[6] = 0;
                  // if(arr1.length===0){

                  // }
                  if (arr1.length === 1) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = 0;
                    this.airuse[4] = 0;
                    this.airuse[5] = arr1[0];
                  } else if (arr1.length === 2) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = 0;
                    this.airuse[4] = arr1[0];
                    this.airuse[5] = arr1[1];
                  } else if (arr1.length === 3) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = arr1[0];
                    this.airuse[4] = arr1[1];
                    this.airuse[5] = arr1[2];
                  } else if (arr1.length === 4) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = arr1[0];
                    this.airuse[3] = arr1[1];
                    this.airuse[4] = arr1[2];
                    this.airuse[5] = arr1[3];
                    // this.airuse[5] = arr1[4];
                  } else if (arr1.length === 5) {
                    this.airuse[0] = 0;
                    this.airuse[1] = arr1[0];
                    this.airuse[2] = arr1[1];
                    this.airuse[3] = arr1[2];
                    this.airuse[4] = arr1[3];
                    this.airuse[5] = arr1[4];
                  } else if (arr1.length === 6) {
                    this.airuse[0] = arr1[0];
                    this.airuse[1] = arr1[1];
                    this.airuse[2] = arr1[2];
                    this.airuse[3] = arr1[3];
                    this.airuse[4] = arr1[4];
                    this.airuse[5] = arr1[5];
                  }
                } else {
                  let arr2 = res.data.data.formatValue.split(".");
                  let arr3 = arr2[0].toString().split("");
                  this.airuse[6] = arr2[1];
                  if (arr3.length === 1) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = 0;
                    this.airuse[4] = 0;
                    this.airuse[5] = arr3[0];
                  } else if (arr3.length === 2) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = 0;
                    this.airuse[4] = arr3[0];
                    this.airuse[5] = arr3[1];
                  } else if (arr3.length === 3) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = arr3[0];
                    this.airuse[4] = arr3[1];
                    this.airuse[5] = arr3[2];
                  } else if (arr3.length === 4) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = arr1[0];
                    this.airuse[3] = arr1[1];
                    this.airuse[4] = arr1[2];
                    this.airuse[5] = arr1[3];
                  } else if (arr3.length === 5) {
                    this.airuse[0] = 0;
                    this.airuse[1] = arr3[0];
                    this.airuse[2] = arr3[1];
                    this.airuse[3] = arr3[2];
                    this.airuse[4] = arr3[3];
                    this.airuse[5] = arr3[4];
                  } else if (arr3.length === 6) {
                    this.airuse[0] = arr3[0];
                    this.airuse[1] = arr3[1];
                    this.airuse[2] = arr3[2];
                    this.airuse[3] = arr3[3];
                    this.airuse[4] = arr3[4];
                    this.airuse[5] = arr3[5];
                  }
                }

                console.log("arr1", arr1);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });

          getBigSteamM().then((res) => {
            console.log(res);
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.heatuse = ["-", "-", "-", "-", "-", "-", "-"];
              } else {
                // this.allElecP = res.data.data.formatValue;
                let arr1 = res.data.data.formatValue.split("");
                const hasDot = arr1.find((v) => v === ".");
                if (!hasDot) {
                  // this.heatuse[5] = 0;
                  this.heatuse[6] = 0;
                  // if(arr1.length===0){

                  // }
                  if (arr1.length === 1) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = 0;
                    this.heatuse[2] = 0;
                    this.heatuse[3] = 0;
                    this.heatuse[4] = 0;
                    this.heatuse[5] = arr1[0];
                  } else if (arr1.length === 2) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = 0;
                    this.heatuse[2] = 0;
                    this.heatuse[3] = 0;
                    this.heatuse[4] = arr1[0];
                    this.heatuse[5] = arr1[1];
                  } else if (arr1.length === 3) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = 0;
                    this.heatuse[2] = 0;
                    this.heatuse[3] = arr1[0];
                    this.heatuse[4] = arr1[1];
                    this.heatuse[5] = arr1[2];
                  } else if (arr1.length === 4) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = 0;
                    this.heatuse[2] = arr1[0];
                    this.heatuse[3] = arr1[1];
                    this.heatuse[4] = arr1[2];
                    this.heatuse[5] = arr1[3];
                    // this.heatuse[5] = arr1[4];
                  } else if (arr1.length === 5) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = arr1[0];
                    this.heatuse[2] = arr1[1];
                    this.heatuse[3] = arr1[2];
                    this.heatuse[4] = arr1[3];
                    this.heatuse[5] = arr1[4];
                  } else if (arr1.length === 6) {
                    this.heatuse[0] = arr1[0];
                    this.heatuse[1] = arr1[1];
                    this.heatuse[2] = arr1[2];
                    this.heatuse[3] = arr1[3];
                    this.heatuse[4] = arr1[4];
                    this.heatuse[5] = arr1[5];
                  }
                } else {
                  let arr2 = res.data.data.formatValue.split(".");
                  let arr3 = arr2[0].toString().split("");
                  this.heatuse[6] = arr2[1];
                  if (arr3.length === 1) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = 0;
                    this.heatuse[2] = 0;
                    this.heatuse[3] = 0;
                    this.heatuse[4] = 0;
                    this.heatuse[5] = arr3[0];
                  } else if (arr3.length === 2) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = 0;
                    this.heatuse[2] = 0;
                    this.heatuse[3] = 0;
                    this.heatuse[4] = arr3[0];
                    this.heatuse[5] = arr3[1];
                  } else if (arr3.length === 3) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = 0;
                    this.heatuse[2] = 0;
                    this.heatuse[3] = arr3[0];
                    this.heatuse[4] = arr3[1];
                    this.heatuse[5] = arr3[2];
                  } else if (arr3.length === 4) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = 0;
                    this.heatuse[2] = arr1[0];
                    this.heatuse[3] = arr1[1];
                    this.heatuse[4] = arr1[2];
                    this.heatuse[5] = arr1[3];
                  } else if (arr3.length === 5) {
                    this.heatuse[0] = 0;
                    this.heatuse[1] = arr3[0];
                    this.heatuse[2] = arr3[1];
                    this.heatuse[3] = arr3[2];
                    this.heatuse[4] = arr3[3];
                    this.heatuse[5] = arr3[4];
                  } else if (arr3.length === 6) {
                    this.heatuse[0] = arr3[0];
                    this.heatuse[1] = arr3[1];
                    this.heatuse[2] = arr3[2];
                    this.heatuse[3] = arr3[3];
                    this.heatuse[4] = arr3[4];
                    this.heatuse[5] = arr3[5];
                  }
                }

                console.log("arr1", arr1);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          getBigElecActual().then((res) => {
            // debugger
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.allElecP = "-- --";
              } else {
                this.allElecP = res.data.data.formatValue;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          // getBigElecProvide().then((res) => {
          //   // debugger
          //   if (res.data) {
          //     if(res.data.data){
          //       if (res.data.data.formatValue === null) {
          //         this.allElecProvide = "-- --";
          //       } else {
          //         this.allElecProvide = res.data.data.formatValue;
          //       }
          //     }
          //     else{
          //       this.$message.error(res.data.msg);
          //     }
          //   } else {
          //     this.$message.error(res.data.msg);
          //   }
          // });
          getYearChart().then((res) => {
            console.log(res);
            if (res.data.data.length > 0) {
              this.isNHshow = true;
              res.data.data.forEach((item) => {
                this.dataUsed.push(item.formatValue);
              });
            }
            // this.dataUsed[3] = '10';
            // this.dataUsed = [
            //   220,
            //   182,
            //   191,
            //   234,
            //   290,
            //   330,
            //   310,
            //   123,
            //   442,
            //   321,
            //   90,
            //   579,
            // ];
            console.log("this.dataUsed", this.dataUsed);
          });
        }
      }
    );

    window.addEventListener("resize", this.resizeHandler);
    setTimeout(() => {
      this.resizeHandler();
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  computed: {
    chartOption1() {
      return {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}kWh",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "10px",
        },
        xAxis: {
          data: this.monthDataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#BCCCFF",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#BCCCFF",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 5,
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#55ffbc" },
                  { offset: 0.62, color: "#47A5E1" },
                  { offset: 1, color: "#3F6DF8" },
                ]),
              },
            },
            data: this.dataUsed,
          },
        ],
      };
    },
  },
  methods: {
    resizeHandler() {
      // this.$refs.chart1.resize();
      // this.$refs.chart2.resize();
      // this.$refs.chart3.resize();
      // this.$refs.chart4.resize();
      // this.$refs.chart5.resize();
      // this.$refs.chart6.resize();
    },
    clickNH(index) {
      this.isActivenNH = index;
      this.dataUsed = [];
      if (index == 1) {
        this.isNHshow = false;
        this.unit2 = "(kWh)";
        getYearChart().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          // this.dataUsed[3] = '10';
          // this.dataUsed = [
          //   220,
          //   182,
          //   191,
          //   234,
          //   290,
          //   330,
          //   310,
          //   123,
          //   442,
          //   321,
          //   90,
          //   579,
          // ];
          console.log("this.dataUsed", this.dataUsed);
        });
      } else if (index === 2) {
        this.isNHshow = false;
        this.unit2 = "(m3)";
        getYearChartWater().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          console.log("this.dataUsed", this.dataUsed);
        });
      } else if (index === 3) {
        this.isNHshow = false;
        this.unit2 = "(m3)";
        getYearChartAir().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          console.log("this.dataUsed", this.dataUsed);
        });
      } else if (index === 4) {
        this.isNHshow = false;
        this.unit2 = "(kWh)";
        getYearChartSteam().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          console.log("this.dataUsed", this.dataUsed);
        });
      }
    },
    clickGN(index) {
      this.isActivenGN = index;
    },
    click1point() {
      this.isClick1 = true;
      this.isClick2 = false;
      this.isClick3 = false;
      this.showModal = true;
    },
    click2point() {
      this.isClick1 = false;
      this.isClick2 = true;
      this.isClick3 = false;
      this.showModal = true;
    },
    click3point() {
      this.isClick1 = false;
      this.isClick2 = false;
      this.isClick3 = true;
      this.showModal = true;
    },
    over1point() {
      this.isClick1 = true;
      this.isClick2 = false;
      this.isClick3 = false;
    },
    over2point() {
      this.isClick1 = false;
      this.isClick2 = true;
      this.isClick3 = false;
    },
    over3point() {
      this.isClick1 = false;
      this.isClick2 = false;
      this.isClick3 = true;
    },
    clearAll() {
      this.isClick1 = false;
      this.isClick2 = false;
      this.isClick3 = false;
      this.showModal = false;
    },
    clickOptionMap(item) {
      this.mapName = item;
    },
    showFull() {
      this.$store.commit("mutationsShowBig");
    },
    toProjectOverall() {
      this.$router.push({ name: "overall" });
    },
    ...mapMutations(["mutationsShowBig", "mutationsHideBig"]),
  },
};
</script>

<style></style>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 256px;
  height: 100%;
}

.header {
  height: 64px;
  width: 100%;
  background: #001529;
  padding-left: 30px;
  padding-right: 30px;
}
.header-content {
  width: 80%;
}
.header-content-left {
  height: 64px;
  line-height: 64px;
  float: left;
}
.header-content-left span {
  margin-left: 10px;
  color: #fff;
  font-size: 20px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.user-name {
  position: absolute;
  top: 19px;
  right: 20px;
  color: #fff;
  font-size: 16px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  cursor: pointer;
  z-index: 9999;
  height: 64px;
}
.down-menu {
  position: absolute;
  top: 70px;
  right: 0;
  background: #001529;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  text-align: center;
  width: 120px;
  color: white;
  z-index: 9999;
  cursor: pointer;
}
</style>
<style scoped>
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  /* padding-left: 15px; */
  color: #bcccff;
  font-size: 13px;
  cursor: pointer;
  text-align: left;
  padding-left: 20px;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.activeOption1 {
  background: #3f6df8;
  color: white;
}
.big-screen {
  background: url("~@/assets/bigBG.png");
  background-size: cover;
  position: relative;
}
.select-class >>> .el-input__inner {
  border-radius: 4px;
  border: 1px solid #3f6df8;
  border-color: rgba(63, 109, 248, 0.5);
  background: transparent;
}
.select-class >>> .el-select__caret {
  color: rgba(63, 109, 248, 0.5);
}
.square-1 {
  padding: 20px;
  padding-top: 10px;
  width: 401px;
  height: 215px;
  position: absolute;
  top: 65px;
  left: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-2 {
  padding: 20px;
  padding-top: 10px;
  width: 401px;
  height: 277px;
  position: absolute;
  top: 302px;
  left: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-3 {
  padding: 20px;
  padding-top: 10px;
  width: 401px;
  height: 217px;
  position: absolute;
  top: 602px;
  left: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-4 {
  padding: 20px;
  padding-top: 10px;
  width: 401px;
  height: 186px;
  position: absolute;
  top: 65px;
  right: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-5 {
  padding: 20px;
  padding-top: 10px;
  width: 401px;
  height: 277px;
  position: absolute;
  top: 272px;
  right: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-6 {
  padding: 20px;
  padding-top: 10px;
  width: 401px;
  height: 247px;
  position: absolute;
  top: 572px;
  right: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-number {
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 24px;
  border: 1px solid #3f6df8;
  border-color: rgba(63, 109, 248, 0.5);
  color: #ffffff;
  margin-right: 10px;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: white;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
}
.activeGN {
  background: #3f6df8;
}
</style>
