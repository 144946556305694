<template>
  <div style="width: 100%; height: 100%; background: white" @click="falseAll">
    <div
      style="
        width: 100%;
        height: 50px;
        border-bottom: 1px solid rgba(8, 34, 84, 0.1);
        padding-left: 50px;
      "
    >
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        v-for="(item, index) in dataTabs"
        :key="index"
        @click="clickTabs(item)"
        :class="{ isClick: currentTabs === item.energyTypeId }"
      >
        {{ item.energyTypeName }}
      </div>
      <!-- <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 1"
        :class="{ isClick: clickCatagory === 1 }"
      >
        水能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 2"
        :class="{ isClick: clickCatagory === 2 }"
      >
        电能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 3"
        :class="{ isClick: clickCatagory === 3 }"
      >
        气能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 4"
        :class="{ isClick: clickCatagory === 4 }"
      >
        热能
      </div> -->
    </div>
    <div
      style="
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        justify-content: space-between;
        overflow: auto;
      "
    >
      <div
        style="
          height: 100%;
          width: 250px;
          flex: none;
          border-right: 1px solid rgba(8, 34, 84, 0.1);
          overflow: hidden;
          position: relative;
        "
      >
        <div
          style="
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            color: #082254;
            text-align: center;
          "
        >
          {{ treeName }}
        </div>
        <div style="overflow: auto; width: 100%; height: 550px">
          <el-tree
            ref="eltree"
            style="width: 100%; max-height: 550px; overflow: auto"
            :data="data"
            show-checkbox
            :check-on-click-node="isClickOncheck"
            :check-strictly="isCheckStrict"
            node-key="id"
            :props="defaultProps"
            @check="validateCheck"
            :default-expanded-keys="defaultExpandArray"
          >
          </el-tree>
          <!-- @node-click="getTreeAttr" -->
        </div>
        <div style="width: 100%; position: absolute; left: 0; bottom: 0">
          <div
            v-for="(item, index) in dataTreeArray"
            :key="index"
            style="
              width: 100%;
              height: 50px;
              line-height: 50px;
              font-size: 14px;
              text-align: center;
              color: #3f6df8;
              border: 1px solid #3f6df8;
              border-bottom: none;
              cursor: pointer;
            "
            class="other-tree"
            @click="clickOtherTree(item, index)"
          >
            {{ item.treeName }}
          </div>
        </div>
        <!-- <div
          style="
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            background: #3f6df8;
            position: relative;
            left: 0;
            bottom: 0;
          "
        >
          分类二
          <img
            src="@/assets/向下.png"
            style="position: absolute; top: 18px; right: 25px"
          />
        </div> -->
      </div>
      <div style="flex: 1; padding: 20px; overflow: hidden">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickTime(1)"
              :class="{ activeNH: isActivenTime === 1 }"
            >
              日
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickTime(2)"
              :class="{ activeNH: isActivenTime === 2 }"
            >
              月
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickTime(3)"
              :class="{ activeNH: isActivenTime === 3 }"
            >
              年
            </div>
            <div style="margin-left: 14px; width: 180px" class="date">
              <el-date-picker
                style="width: 180px"
                v-model="value1"
                :type="changeDateType"
                placeholder="选择日期"
                value-format="yyyy-MM-dd 00:00:00"
              >
              </el-date-picker>
            </div>
            <div
              style="position: relative; margin-left: 14px; cursor: pointer"
              class="select2"
            >
              <!-- <input
                v-model="selectValue"
                readonly
                style="height: 29px; width: 140px"
              /> -->
              <el-input
                v-model="selectValueInterval"
                placeholder="请选择时间间隔"
                readonly
                :class="{ isBorder: isShowSelectInterval }"
                style="width: 160px"
                @click.native="clickInterval($event)"
              >
                <i slot="suffix" class="el-input__icon el-icon-arrow-down"></i>
              </el-input>
              <div
                v-if="isShowSelectInterval"
                style="
                  position: absolute;
                  z-index: 9;
                  width: 160px;
                  max-height: 116px;
                  border: 1px solid #3f6df8;

                  top: 29px;
                  left: 0px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  background: white;
                  overflow: auto;
                "
              >
                <div
                  style=""
                  class="sel-option"
                  @click="clickOptionInterval(item)"
                  v-for="(item, index) in attrInterval"
                  :key="index"
                  :class="{ activeOption2: selectValueInterval === item }"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="margin-right: 20px">
              <el-radio-group v-model="radio" @change="changeRadio($event)">
                <el-radio
                  v-for="(item, index) in attr1"
                  :label="item.attrId"
                  :key="index"
                  >{{ item.attrName }}</el-radio
                >
              </el-radio-group>
              <!-- <el-radio
                v-model="radio"
                v-for="(item, index) in attr1"
                :label="item.attrId"
                :key="index"
                >{{ item.attrName }}</el-radio
              > -->
              <!-- <el-radio v-model="radio" label="1">电量</el-radio>
              <el-radio v-model="radio" label="2">电流</el-radio>
              <el-radio v-model="radio" label="3">功率</el-radio> -->
            </div>

            <div
              style="position: relative; margin-right: 20px; cursor: pointer"
              class="select"
              v-if="attr2.length > 0"
            >
              <!-- <input
                v-model="selectValue"
                readonly
                style="height: 29px; width: 140px"
              /> -->
              <el-input
                v-model="selectValue"
                placeholder="更多监测项"
                readonly
                :class="{ isBorder: isShowSelect }"
                @click.native="clickSelect($event)"
                style=""
              >
                <i slot="suffix" class="el-input__icon el-icon-arrow-down"></i>
              </el-input>

              <div
                v-if="isShowSelect"
                style="
                  position: absolute;
                  z-index: 9;
                  width: 140px;
                  max-height: 116px;
                  border: 1px solid #3f6df8;
                  top: 29px;
                  left: 0;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  background: white;
                  overflow: auto;
                "
              >
                <div
                  style=""
                  class="sel-option"
                  @click="clickOption2(item)"
                  v-for="(item, index) in attr2"
                  :key="index"
                  :class="{ activeOption2: selectValue === item.attrName }"
                >
                  {{ item.attrName }}
                </div>
                <!-- <div
                  style=""
                  class="sel-option"
                  @click="clickOption('相电压频率')"
                >
                  相电压频率
                </div>
                <div
                  style=""
                  class="sel-option"
                  @click="clickOption('功率因数')"
                >
                  功率因数
                </div> -->
              </div>
            </div>

            <div
              style="
                width: 72px;
                height: 29px;
                background: #3f6df8;
                border-radius: 2px;
                font-size: 12px;
                color: white;
                text-align: center;
                line-height: 29px;
                margin-right: 20px;
                cursor: pointer;
              "
              @click="onSearch"
            >
              查询
            </div>

            <div
              style="
                width: 72px;
                height: 29px;
                background: #3f6df8;
                border-radius: 2px;
                font-size: 12px;
                color: white;
                text-align: center;
                line-height: 29px;
              "
            >
              数据导出
            </div>
          </div>
        </div>

        <div
          style="
            width: 100%;
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <!-- <span style="color: #082254; font-weight: bold; margin-right: 10px"
              >全厂用电量：127kWh</span
            >
            <img src="@/assets/指数下降.png" style="vertical-align: -5px" />
            <span style="color: #082254; font-weight: bold; margin-right: 10px"
              >-15%</span
            > -->
            <div v-for="(item, index) in dataAllV" :key="index">
              <span
                style="color: #082254; font-weight: bold; margin-right: 10px"
                >{{ item.name.split("-")[0] }}</span
              >
              <span
                style="color: #082254; font-weight: bold; margin-right: 10px"
                >{{ item.name.split("-")[1] }}：{{ item.all }}
                {{ item.unit }}</span
              >
            </div>
          </div>
          <div>
            <div
              @click="clickZhe"
              style="
                float: left;
                width: 36px;
                height: 32px;
                border-radius: 2px 0 0 2px;
                border: 1px solid #3f6df8;
                text-align: center;
                cursor: pointer;
              "
              :class="{ isActive: isActiveType === 'showZhe' }"
            >
              <img :src="srcZhe" style="vertical-align: middle" />
            </div>
            <div
              @click="clickZhu"
              style="
                float: left;
                width: 36px;
                height: 32px;

                border-radius: 0 2px 2px 0;
                cursor: pointer;
                border: 1px solid #3f6df8;
                text-align: center;
              "
              :class="{ isActive: isActiveType === 'showZhu' }"
            >
              <img :src="srcZhu" style="vertical-align: middle" />
            </div>
          </div>
        </div>
        <!-- <div
          style="
            width: 100%;
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <div>
            <span style="color: #082254; font-weight: bold; margin-right: 10px"
              >办公楼用电量：127kWh</span
            >
          </div>
        </div> -->
        <div
          style="width: 100%; height: 370px; margin-top: 20px"
          :key="loadStatus"
          v-loading="loadStatus"
        >
          <v-chart
            v-if="isActiveType === 'showZhu'"
            ref="chart1"
            :option="chartBarOption1"
          />
          <v-chart
            v-if="isActiveType === 'showZhe'"
            ref="chart2"
            :option="chartLineOption1"
          />
        </div>

        <!-- <div style="width: 100%; height: 326px; margin-top: 20px"> style="width: 1323px" -->
        <el-table
          :data="dataAllV"
          style="width: 100%; overflow: auto; margin-top: 30px"
          :row-class-name="tableRowClassName"
        >
          <el-table-column fixed prop="name" label="监测点位" width="180">
          </el-table-column>
          <el-table-column fixed prop="unit" label="单位" width="120">
          </el-table-column>
          <el-table-column
            v-for="(item, index) in tableKey"
            :key="index"
            :prop="item"
            :label="item"
            width="190"
            align="center"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  scope.row.values[index] === null ||
                  scope.row.values[index] === undefined
                "
                >--</template
              >
              <template v-else>
                {{ scope.row.values[index] }}
              </template>
              <!-- {{ scope.row['00:05'] }} -->
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="zip"
            label="00:00-01:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="01:00-02:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="02:00-03:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="03:00-04:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="04:00-05:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="05:00-06:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="06:00-07:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="07:00-08:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="08:00-09:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="09:00-10:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="10:00-11:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="zip"
            label="11:00-12:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="12:00-13:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="13:00-14:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="14:00-15:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="15:00-16:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="16:00-17:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="17:00-18:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="18:00-19:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="19:00-20:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="20:00-21:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="21:00-22:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="22:00-23:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="23:00-24:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>-->
        </el-table>
        <!-- </div> -->
      </div>
      <!-- <div
        style="
          width: 100%;
          height: 100px;
          line-height: 100px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #082254;
        "
      >
        {{tuopuName}}
      </div>
      <div class="tuopu"></div> -->
    </div>
  </div>
</template>

<script>
// import * as echarts from "echarts";
import { mapState } from "vuex";
import {
  // getProjects,
  setProject,
  getEnergy,
  getTreeInfo,
  getTree,
  getTreeOption,
  getTypeCompare,
  getMultiAttr,
} from "@/request/apiLg";
export default {
  data() {
    // var dataAxis2 = [
    //   "00:00",
    //   "01:00",
    //   "02:00",
    //   "03:00",
    //   "04:00",
    //   "05:00",
    //   "06:00",
    //   "07:00",
    //   "08:00",
    //   "09:00",
    //   "10:00",
    //   "11:00",
    //   "12:00",
    //   "13:00",
    //   "14:00",
    //   "15:00",
    //   "16:00",
    //   "17:00",
    //   "18:00",
    //   "19:00",
    //   "20:00",
    //   "21:00",
    //   "22:00",
    //   "23:00",
    // ];
    // var data2 = [
    //   510,
    //   390,
    //   300,
    //   200,
    //   250,
    //   260,
    //   230,
    //   360,
    //   800,
    //   810,
    //   700,
    //   750,
    //   850,
    //   600,
    //   500,
    //   300,
    //   400,
    //   600,
    //   850,
    //   850,
    //   850,
    //   850,
    //   500,
    //   300,
    // ];
    // var data3 = [
    //   520,
    //   350,
    //   340,
    //   210,
    //   260,
    //   280,
    //   290,
    //   380,
    //   880,
    //   800,
    //   750,
    //   770,
    //   830,
    //   610,
    //   510,
    //   320,
    //   440,
    //   640,
    //   860,
    //   840,
    //   870,
    //   880,
    //   510,
    //   330,
    // ];
    // var data4 = [
    //   410,
    //   690,
    //   300,
    //   400,
    //   550,
    //   360,
    //   530,
    //   260,
    //   400,
    //   510,
    //   600,
    //   250,
    //   450,
    //   500,
    //   600,
    //   300,
    //   200,
    //   400,
    //   550,
    //   450,
    //   250,
    //   150,
    //   500,
    //   600,
    // ];
    // var data5 = [
    //   570,
    //   310,
    //   320,
    //   230,
    //   230,
    //   250,
    //   260,
    //   320,
    //   830,
    //   820,
    //   760,
    //   770,
    //   850,
    //   640,
    //   530,
    //   310,
    //   410,
    //   620,
    //   850,
    //   860,
    //   830,
    //   820,
    //   520,
    //   370,
    // ];
    // var data6 = [
    //   410,
    //   690,
    //   200,
    //   400,
    //   450,
    //   260,
    //   530,
    //   460,
    //   300,
    //   310,
    //   600,
    //   450,
    //   350,
    //   300,
    //   800,
    //   700,
    //   500,
    //   400,
    //   350,
    //   350,
    //   250,
    //   250,
    //   400,
    //   400,
    // ];
    return {
      loadStatus: false,
      tempToggle: [],
      tempToggle2: [],
      dataAllV: [],
      legendName: [],
      isClickOncheck: true,
      isCheckStrict: true,
      currentNodeName: "",
      currentAttrName: "",
      currentAll1: "",
      currentAll2: "",
      currentAllUnit: "",
      tableKey: [],
      loading: true,
      currentNodeID: "",
      currentNodeIDArr: [],
      seriesName1: "",
      seriesName2: "",
      dataValue1: [],
      dataValue2: [],
      dataXaxis: [],
      attrInterval: ["5分钟", "1小时"],
      selectValueInterval: "",
      isShowSelectInterval: false,
      dataTreeArray: [],

      changeDateType: "date",
      defaultExpandArray: [],
      attr1: [],
      attr2: [],
      treeId: "",
      treeName: "",
      currentTabs: "",
      dataTabs: [],
      checkArray: [],
      // chartLineOption1: {

      // },
      isActiveType: "showZhe",
      tableData: [
        {
          date: "办公楼",
          name: "567kWh",
          zip: "567kWh",
        },
        {
          date: "办公楼",
          name: "567kWh",
          zip: "567kWh",
        },
        {
          date: "办公楼",
          name: "567kWh",
          zip: "567kWh",
        },
        {
          date: "办公楼",
          name: "567kWh",
          zip: "567kWh",
        },
      ],
      // chartBarOption1: {

      // },
      srcZhu: `${require("@/assets/柱状图2.png")}`,
      srcZhe: `${require("@/assets/折线图1.png")}`,
      isShowSelect: false,
      value1: "",
      selectValue: "",
      radio: "1",
      isActivenTime: 1,
      activeName: "second",
      clickCatagory: 1,
      data: [
        {
          id: 1,
          label: "高新文体中心",
          children: [
            {
              id: 2,
              label: "制冷机房",
              children: [
                {
                  id: 3,
                  label: "空调主机",
                  children: [
                    { id: 7, label: "1#主机" },
                    { id: 8, label: "2#主机" },
                    { id: 9, label: "3#主机" },
                    { id: 10, label: "4#主机" },
                    { id: 11, label: "5#主机" },
                  ],
                },
                {
                  id: 4,
                  label: "冷冻水泵",
                  children: [
                    { id: 12, label: "冷冻水泵（总）" },
                    { id: 13, label: "7#冷冻水泵" },
                    { id: 14, label: "6#冷冻水泵" },
                    // { id: 15, label: "备用" },
                    // { id: 16, label: "备用" },
                    { id: 17, label: "1#图书馆水泵" },
                    { id: 18, label: "2#图书馆水泵" },
                    { id: 19, label: "3#图书馆水泵" },
                    // { id: 20, label: "备用" },
                    { id: 21, label: "1#文化馆水泵" },
                    { id: 22, label: "2#文化馆水泵" },
                    { id: 23, label: "3#文化馆水泵" },
                    { id: 24, label: "1#体育馆水泵" },
                    { id: 25, label: "2#体育馆水泵" },
                    { id: 26, label: "3#体育馆水泵" },
                    { id: 27, label: "4#体育馆水泵" },
                    // { id: 28, label: "备用" },
                    { id: 29, label: "1#健身中心水泵" },
                    { id: 30, label: "2#健身中心水泵" },
                    { id: 31, label: "3#健身中心水泵" },
                    { id: 32, label: "1#冷冻水泵" },
                    { id: 33, label: "2#冷冻水泵" },
                    { id: 34, label: "3#冷冻水泵" },
                    { id: 35, label: "4#冷冻水泵" },
                    { id: 36, label: "5#冷冻水泵" },
                    // { id: 37, label: "备用" },
                    // { id: 38, label: "备用" },
                  ],
                },
                {
                  id: 5,
                  label: "其他",
                  children: [
                    { id: 39, label: "特殊用电（总）" },
                    { id: 40, label: "特殊用电" },
                    // { id: 41, label: "备用" },
                  ],
                },
                {
                  id: 6,
                  label: "冷却水泵",
                  children: [
                    { id: 42, label: "6#冷却水泵" },
                    { id: 43, label: "7#冷却水泵" },
                    // { id: 44, label: "备用" },
                    { id: 45, label: "4#冷却水泵" },
                    { id: 46, label: "5#冷却水泵" },
                    // { id: 47, label: "备用" },
                    { id: 48, label: "1#冷却水泵" },
                    { id: 49, label: "2#冷却水泵" },
                    { id: 50, label: "3#冷却水泵" },
                    { id: 51, label: "冷却水泵（总）" },
                  ],
                },
                {
                  id: "7r",
                  label: "冷却塔",
                  children: [{ id: 52, label: "冷却塔（总）" }],
                },
                {
                  id: "8r",
                  label: "热量表",
                  children: [
                    { id: 53, label: "热量表1" },
                    { id: 54, label: "热量表2" },
                  ],
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
    };
  },
  mounted() {
    let dateT = new Date();
    var YY = dateT.getFullYear() + "-";
    var MM =
      (dateT.getMonth() + 1 < 10
        ? "0" + (dateT.getMonth() + 1)
        : dateT.getMonth() + 1) + "-";
    var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    this.value1 = YY + MM + DD + " 00:00:00";
    this.selectValueInterval = "5分钟";
    // getProjects().then((res1) => {
    //   if (res1.data) {
    // this.allElecP = res.data.data;
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getEnergy().then((res2) => {
            if (res2.data) {
              this.dataTabs = res2.data.data;
              this.currentTabs = res2.data.data[0].energyTypeId;
              // this.allElecP = res.data.data;
              // debugger
              let obj = {
                energyTypeId: res2.data.data[0].energyTypeId,
                projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
              };
              getTreeInfo(obj).then((res3) => {
                // debugger
                if (res3.data) {
                  if (res3.data.data.length > 0) {
                    this.firstTree = res3.data.data[0];
                    this.treeName = this.firstTree.treeName;
                    this.treeId = this.firstTree.treeId;
                    let temp1 = JSON.parse(JSON.stringify(res3.data.data));
                    temp1.splice(0, 1);
                    // console.log("temp2", temp2);
                    this.dataTreeArray = temp1;
                    // console.log("this.dataTreeArray", this.dataTreeArray);
                    // console.log("this.dataTreeArray", this.dataTreeArray);
                    getTree(res3.data.data[0].treeId).then((res4) => {
                      if (res4.data) {
                        // console.log(1);
                        // let temp = JSON.parse(JSON.stringify(res4.data.data));
                        // this.data = this.toggole2(temp);
                        // this.defaultKey = this.data[0].id;
                        let temp = JSON.parse(JSON.stringify(res4.data.data));
                        this.data = temp;
                        this.defaultExpandArray.push(this.data[0].id);
                        this.data[0].children.forEach((item1) => {
                          this.defaultExpandArray.push(item1.id);
                          if (item1.children.length > 0) {
                            item1.children.forEach((item2) => {
                              this.defaultExpandArray.push(item2.id);
                            });
                          }
                        });
                        // this.$nextTick(() => {
                        //   this.$refs.eltree.setCheckedKeys([
                        //     this.data[0].children[0].children[0].children[0].id,
                        //     this.data[0].children[0].children[0].children[1].id,
                        //   ]);
                        // });
                        this.toggole(this.data);
                        // debugger
                        console.log("this.tempToggle", this.tempToggle);
                        if (this.tempToggle.length >= 2) {
                          this.$nextTick(() => {
                            this.$refs.eltree.setCheckedKeys([
                              this.tempToggle[0],
                              this.tempToggle[1],
                            ]);
                          });
                        } else if (this.tempToggle.length === 1) {
                          // this.$message.error("请至少选中两项进行类比");
                          return;
                        }

                        // this.data.children[0].children.forEach(item1=>{
                        //   if(item1.children.length === 0){

                        //   }
                        //   else if(item1.children.length === 1){

                        //   }
                        //   else if(item1.children.length > 1){

                        //   }
                        // })
                        // if (this.data.children.length === 0) {
                        //   this.$nextTick(() => {
                        //     this.$refs.eltree.setCheckedKeys([this.data[0].id]);
                        //   });
                        // } else {
                        //   if (this.data.children[0].children.length === 0) {
                        //     this.$nextTick(() => {
                        //       this.$refs.eltree.setCheckedKeys([this.data[0].children[0].id]);
                        //     });
                        //   } else {

                        //   }
                        // }
                        // if (this.data.children.length === 0) {
                        //   this.$nextTick(() => {
                        //     this.$refs.eltree.setCheckedKeys([this.data[0].id]);
                        //   });
                        // } else {
                        //   if (this.data.children[0].children.length === 0) {
                        //     if (this.data.children.length > 1) {
                        //       this.$nextTick(() => {
                        //         this.$refs.eltree.setCheckedKeys([
                        //           this.data.children[0].id,
                        //           this.data.children[1].id,
                        //         ]);
                        //       });
                        //     } else {
                        //       this.$nextTick(() => {
                        //         this.$refs.eltree.setCheckedKeys([
                        //           this.data.children[0].id,
                        //         ]);
                        //       });
                        //     }
                        //   } else {
                        //     if (this.data.children[0].children.length === 0) {
                        //       if (this.data.children.length > 1) {
                        //         this.$nextTick(() => {
                        //           this.$refs.eltree.setCheckedKeys([
                        //             this.data.children[0].id,
                        //             this.data.children[1].id,
                        //           ]);
                        //         });
                        //       } else {
                        //         this.$nextTick(() => {
                        //           this.$refs.eltree.setCheckedKeys([
                        //             this.data.children[0].id,
                        //           ]);
                        //         });
                        //       }
                        //     }
                        //   }
                        // }
                        // this.data;
                        // this.data.forEach((item1) => {
                        //   if (item1.children.length > 0) {
                        //     // item1.children.forEach((item2) => {
                        //     //   if (item2.children.length > 0) {

                        //     //   } else {
                        //     //     this.$nextTick(() => {
                        //     //       // this.$refs.eltree.setCurrentKey(this.data[0].id);
                        //     //       this.$refs.eltree.setCheckedKeys([this.data[0].id]);
                        //     //     });
                        //     //   }
                        //     // });
                        //     item1.children[0]
                        //   } else {
                        //     this.$nextTick(() => {
                        //       // this.$refs.eltree.setCurrentKey(this.data[0].id);
                        //       this.$refs.eltree.setCheckedKeys([this.data[0].id]);
                        //     });
                        //   }
                        // });

                        // this.currentNodeID = this.data[0].nodeId;
                        // this.currentNodeIDArr = [
                        //   this.data[0].children[0].children[0].nodeId,
                        //   this.data[0].children[0].children[1].nodeId,
                        // ];
                        this.currentNodeIDArr = [
                          this.tempToggle2[0],
                          this.tempToggle2[1],
                        ];
                        this.currentNodeName = this.data[0].nodeName;
                        let dataT = {
                          nodeIds: this.currentNodeIDArr,
                          projectId: JSON.parse(
                            window.localStorage.getItem("PROJECTID")
                          ),
                          treeId: this.treeId,
                        };

                        getMultiAttr(dataT).then((res) => {
                          // console.log(res);
                          let tempArr1 = res.data.data;
                          let tempArr2 = res.data.data.splice(0, 3);
                          this.attr1 = tempArr2;
                          this.attr2 = tempArr1;
                          // console.log(this.attr1, this.attr2);
                          this.radio = tempArr2[0].attrId;
                          this.currentAttrName = tempArr2[0].attrName;
                          // console.log("this.radio", this.radio);
                          this.onSearch();
                          // temp["data"].forEach((item) => {
                          //   this.dataXaxis.push(item.datetime);
                          //   if (item.values.peace !== null) {
                          //     this.dataValue.push(item.values.peace);
                          //   } else if (item.values.valley !== null) {
                          //     this.dataValue.push({
                          //       value: item.values.valley,
                          //       itemStyle: {
                          //         color: "#41F39A",
                          //       },
                          //     });
                          //   } else if (item.values.peak !== null) {
                          //     this.dataValue.push({
                          //       value: item.values.peak,
                          //       itemStyle: {
                          //         color: "#F3BB3D",
                          //       },
                          //     });
                          //   } else {
                          //     this.dataValue.push("");
                          //   }
                          // });
                        });

                        // getDataSearch().then((res5) => {
                        //   console.log(res5);
                        // });
                      }
                    });
                  } else {
                    this.treeName = "";
                    this.data = [];
                  }
                } else {
                  this.$message.error(res3.data.msg);
                }
              });
            } else {
              this.$message.error(res2.data.msg);
            }
          });
        }
      }
    );
  },
  methods: {
    toggole2(data) {
      data.forEach((item) => {
        if (item.show) {
          item.disabled = true;
        }
        if (item.children.length > 0) {
          this.toggole2(item.children);
        }
      });
      console.log("data", data);
      return data;
    },
    toggole(data) {
      data.forEach((item) => {
        if (item.children.length > 0) {
          // debugger
          this.toggole(item.children);
        } else {
          this.tempToggle.push(item.id);
          this.tempToggle2.push(item.nodeId);
        }
      });
    },
    clickOptionInterval(v) {
      this.isShowSelectInterval = false;

      this.selectValueInterval = v;
    },
    clickInterval(e) {
      e.stopPropagation();
      this.isShowSelectInterval = !this.isShowSelectInterval;
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    changeRadio(e) {
      // console.log(e);
      this.selectValue = "";
      this.selectId = "";
      this.attr1.forEach((item) => {
        if (item.attrId === e) {
          this.currentAttrName = item.attrName;
        }
      });
      // this.currentAttrName = ''
    },
    onSearch() {
      this.loadStatus = true;
      this.tableKey = [];
      this.tableData = [];
      this.currentAll1 = "";
      this.currentAll2 = "";
      this.currentAllUnit = "";
      this.dataValue1 = [];
      this.dataValue2 = [];
      this.dataXaxis = [];
      let queryData = {
        endTime: "2022-05-09 00:00:00",
        energyAttrId: "Ua",
        energyTypeId: this.currentTabs,
        interval: 1,
        // nodeId: this.currentNodeID,
        nodeIds: this.currentNodeIDArr,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
        startTime: "2021-05-01 00:00:00",
        timeUnit: "DAYS",
        treeId: this.treeId,
      };
      if (this.radio === "" && this.selectId === "") {
        this.$message.error("未选择属性");
        return;
      }
      if (this.radio) {
        queryData.energyAttrId = this.radio;
      } else if (this.selectValue) {
        this.currentAttrName = this.selectValue;
        queryData.energyAttrId = this.selectId;
      }
      let dateTime = new Date(this.value1);
      if (this.isActivenTime === 1) {
        // queryData
        if (this.selectValueInterval === "5分钟") {
          queryData.timeUnit = "MINUTES";
          queryData.interval = 5;
          dateTime = dateTime.setDate(dateTime.getDate() + 1);
          dateTime = this.formatDate(dateTime);
          queryData.startTime = this.value1;
          queryData.endTime = dateTime;
        } else if (this.selectValueInterval === "1小时") {
          queryData.timeUnit = "HOURS";
          queryData.interval = 1;
          dateTime = dateTime.setDate(dateTime.getDate() + 1);
          dateTime = this.formatDate(dateTime);
          queryData.startTime = this.value1;
          queryData.endTime = dateTime;
        }
      } else if (this.isActivenTime === 2) {
        if (this.selectValueInterval === "1小时") {
          queryData.timeUnit = "HOURS";
          queryData.interval = 1;
          dateTime = dateTime.setMonth(dateTime.getMonth() + 1);
          dateTime = this.formatDate(dateTime);
          queryData.startTime = this.value1;
          queryData.endTime = dateTime;
        } else if (this.selectValueInterval === "1天") {
          queryData.timeUnit = "DAYS";
          queryData.interval = 1;
          dateTime = dateTime.setMonth(dateTime.getMonth() + 1);
          dateTime = this.formatDate(dateTime);
          queryData.startTime = this.value1;
          queryData.endTime = dateTime;
        }
      } else if (this.isActivenTime === 3) {
        if (this.selectValueInterval === "1天") {
          queryData.timeUnit = "DAYS";
          queryData.interval = 1;
          dateTime = dateTime.setFullYear(dateTime.getFullYear() + 1);
          dateTime = this.formatDate(dateTime);
          queryData.startTime = this.value1;
          queryData.endTime = dateTime;
        } else if (this.selectValueInterval === "1月") {
          queryData.timeUnit = "MONTHS";
          queryData.interval = 1;
          dateTime = dateTime.setFullYear(dateTime.getFullYear() + 1);
          dateTime = this.formatDate(dateTime);
          queryData.startTime = this.value1;
          queryData.endTime = dateTime;
        }
      }
      let tempA = [];
      let tempB = [];
      this.dataAllV = [];
      getTypeCompare(queryData).then((res5) => {
        this.loadStatus = false;
        console.log(res5);
        if (this.isActivenTime === 1) {
          res5.data.data.keys.forEach((item) => {
            tempA.push(
              this.formatDate(item).split(" ")[1].split(":")[0] +
                ":" +
                this.formatDate(item).split(" ")[1].split(":")[1]
            );
          });
        } else if (this.isActivenTime === 2) {
          if (this.selectValueInterval === "1小时") {
            res5.data.data.keys.forEach((item) => {
              tempA.push(
                this.formatDate(item).split(" ")[1].split(":")[0] +
                  ":" +
                  this.formatDate(item).split(" ")[1].split(":")[1]
              );
            });
          } else {
            res5.data.data.keys.forEach((item) => {
              tempA.push(this.formatDate(item).split(" ")[0].split("-")[2]);
            });
          }
        } else if (this.isActivenTime === 3) {
          if (this.selectValueInterval === "1天") {
            res5.data.data.keys.forEach((item) => {
              tempA.push(this.formatDate(item).split(" ")[0].split("-")[2]);
            });
          } else {
            res5.data.data.keys.forEach((item) => {
              tempA.push(this.formatDate(item).split(" ")[0].split("-")[1]);
            });
          }
        }
        this.dataXaxis = tempA;
        this.tableKey = tempA;
        res5.data.data.values.forEach((itemv) => {
          tempB.push(itemv.name.split("-")[0]);
        });
        this.legendName = tempB;
        this.dataAllV = res5.data.data.values;
        // let tempA = [];
        // this.currentAll1 = res5.data.data[0].all;
        // this.currentAll2 = res5.data.data[1].all;
        // this.currentAllUnit = res5.data.data[0].unit;
        // this.tableData = res5.data.data;
        // if (res5.data.data[0].keys.length > res5.data.data[1].keys.length) {
        //   res5.data.data[0].keys.forEach((item) => {
        //     this.tableKey.push(this.formatDate(item));
        //   });
        // } else {
        //   res5.data.data[1].keys.forEach((item) => {
        //     this.tableKey.push(this.formatDate(item));
        //   });
        // }

        // // console.log('tempA',tempA)
        // if (this.isActivenTime === 1) {
        //   if (res5.data.data[0].keys.length > res5.data.data[1].keys.length) {
        //     res5.data.data[0].keys.forEach((item) => {
        //       tempA.push(this.formatDate(item).split(" ")[1]);
        //     });
        //   } else {
        //     res5.data.data[1].keys.forEach((item) => {
        //       tempA.push(this.formatDate(item).split(" ")[1]);
        //     });
        //   }
        //   // console.log("tempA", tempA);
        //   this.dataXaxis = tempA;
        //   this.dataValue1 = res5.data.data[0].values;
        //   this.dataValue2 = res5.data.data[1].values;
        // }
        // if (this.isActivenTime === 2) {
        //   if (res5.data.data[0].keys.length > res5.data.data[1].keys.length) {
        //     res5.data.data[0].keys.forEach((item) => {
        //       if (this.selectValueInterval === "1小时") {
        //         let temps =
        //           this.formatDate(item).split(" ")[0].split("-")[2] +
        //           " " +
        //           this.formatDate(item).split(" ")[1];
        //         tempA.push(temps);
        //       }
        //       if (this.selectValueInterval === "1天") {
        //         let temps = this.formatDate(item).split(" ")[0].split("-")[2];
        //         tempA.push(temps);
        //       }
        //     });
        //   } else {
        //     res5.data.data[1].keys.forEach((item) => {
        //       if (this.selectValueInterval === "1小时") {
        //         let temps =
        //           this.formatDate(item).split(" ")[0].split("-")[2] +
        //           this.formatDate(item).split(" ")[1];
        //         tempA.push(temps);
        //       }
        //       if (this.selectValueInterval === "1天") {
        //         let temps = this.formatDate(item).split(" ")[0].split("-")[2];
        //         tempA.push(temps);
        //       }
        //     });
        //   }
        //   // console.log("tempA", tempA);
        //   this.dataXaxis = tempA;
        //   this.dataValue1 = res5.data.data[0].values;
        //   this.dataValue2 = res5.data.data[1].values;
        // }
        // if (this.isActivenTime === 3) {
        //   if (res5.data.data[0].keys.length > res5.data.data[1].keys.length) {
        //     res5.data.data[0].keys.forEach((item) => {
        //       if (this.selectValueInterval === "1天") {
        //         let temps =
        //           this.formatDate(item).split(" ")[0].split("-")[1] +
        //           "-" +
        //           this.formatDate(item).split(" ")[0].split("-")[2];
        //         tempA.push(temps);
        //       }
        //       if (this.selectValueInterval === "1月") {
        //         let temps =
        //           this.formatDate(item).split(" ")[0].split("-")[1] +
        //           tempA.push(temps);
        //       }
        //     });
        //   } else {
        //     res5.data.data[1].keys.forEach((item) => {
        //       if (this.selectValueInterval === "1天") {
        //         let temps =
        //           this.formatDate(item).split(" ")[0].split("-")[1] +
        //           "-" +
        //           this.formatDate(item).split(" ")[0].split("-")[2];
        //         tempA.push(temps);
        //       }
        //       if (this.selectValueInterval === "1月") {
        //         let temps =
        //           this.formatDate(item).split(" ")[0].split("-")[1] +
        //           tempA.push(temps);
        //       }
        //     });
        //   }
        //   // console.log("tempA", tempA);
        //   this.dataXaxis = tempA;
        //   this.dataValue1 = res5.data.data[0].values;
        //   this.dataValue2 = res5.data.data[1].values;
        // }
      });
    },
    falseAll() {
      this.isShowSelect = false;
      this.isShowSelectInterval = false;
    },
    clickOtherTree(item, index) {
      // this.dataTreeArray.splice(index, 1, this.firstTree);
      this.dataTreeArray[index] = this.firstTree;
      this.firstTree = item;
      // console.log("this.dataTreeArray", this.dataTreeArray);
      this.treeName = item.treeName;
      this.tableKey = [];
      this.tableData = [];
      this.currentAll1 = "";
      this.currentAll2 = "";
      this.currentAllUnit = "";
      this.dataValue = [];
      this.dataXaxis = [];
      this.radio = "";
      // this.firstTree = {};
      // this.treeName = "";
      this.treeId = item.treeId;
      this.data = [];
      this.attr1 = [];
      this.attr2 = [];
      this.selectValue = "";
      this.selectId = "";
      getTree(item.treeId).then((res4) => {
        if (res4.data) {
          // console.log(1);
          // let temp = JSON.parse(JSON.stringify(res4.data.data));
          // this.data = this.toggole2(temp);
          if (this.data.length > 0) {
            this.defaultExpandArray.push(this.data[0].id);
            this.data[0].children.forEach((item1) => {
              this.defaultExpandArray.push(item1.id);
              // if(item1.children.length>0){
              //   item1.children.forEach(item2=>{
              //     this.defaultExpandArray.push(item2.id)
              //   })
              // }
            });

            this.$nextTick(() => {
              this.$refs.eltree.setCurrentKey(this.data[0].id);
            });
            // this.currentNodeID = this.data[0].nodeId;
            this.currentNodeIDArr = [this.data[0].nodeId];
            this.currentNodeName = this.data[0].nodeName;
            let dataT = {
              nodeIds: [this.data[0].nodeId],
              projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
              treeId: item.treeId,
            };

            getMultiAttr(dataT).then((res) => {
              let tempArr1 = res.data.data;
              let tempArr2 = (res.data.data || "").splice(0, 3);
              this.attr1 = tempArr2;
              this.attr2 = tempArr1;
              this.radio = tempArr2[0].attrId;
              this.currentAttrName = tempArr2[0].attrName;
            });
          }
        }
      });
    },
    getTreeAttr(val1) {
      // debugger
      // console.log(val1, val2, val3);
      // if (val1.children.length > 0) {
      //   // this.$refs.eltree.setCurrentNode(3);
      // } else {
      //   let data = {
      //     nodeId: val1.nodeId,
      //     projectId: "wtzx",
      //     treeId: this.treeId,
      //   };

      //   getTreeOption(data).then((res) => {
      //     console.log(res);
      //     let tempArr1 = res.data.data;
      //     let tempArr2 = res.data.data.splice(0, 3);
      //     this.attr1 = tempArr2;
      //     this.attr2 = tempArr1;
      //     console.log(this.attr1, this.attr2);
      //   });
      // }
      let data = {
        nodeId: val1.nodeId,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
        treeId: this.treeId,
      };
      this.currentNodeID = val1.nodeId;
      this.currentNodeName = val1.nodeName;
      this.selectValue = "";
      this.selectId = "";
      getTreeOption(data).then((res) => {
        // console.log(res);
        let tempArr1 = res.data.data;
        let tempArr2 = res.data.data.splice(0, 3);
        this.attr1 = tempArr2;
        this.attr2 = tempArr1;
        // console.log(this.attr1, this.attr2);
        this.radio = tempArr2[0].attrId;
        this.currentAttrName = tempArr2[0].attrName;
      });
    },
    clickTabs(item) {
      this.tempToggle = [];
      this.tempToggle2 = [];
      this.currentTabs = item.energyTypeId;
      this.radio = "";
      this.selectId = "";

      this.tableKey = [];
      this.tableData = [];
      this.currentAll1 = "";
      this.currentAll2 = "";
      this.currentAllUnit = "";
      this.dataValue1 = [];
      this.dataValue2 = [];
      this.dataXaxis = [];
      let obj = {
        energyTypeId: this.currentTabs,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
      };
      this.dataTreeArray = [];
      getTreeInfo(obj).then((res3) => {
        if (res3.data) {
          if (res3.data.data.length > 0) {
            this.firstTree = res3.data.data[0];
            this.treeName = this.firstTree.treeName;
            this.treeId = this.firstTree.treeId;
            let temp1 = JSON.parse(JSON.stringify(res3.data.data));
            temp1.splice(0, 1);
            // console.log("temp2", temp2);
            this.dataTreeArray = temp1;
            // console.log("this.dataTreeArray", this.dataTreeArray);
            getTree(res3.data.data[0].treeId).then((res4) => {
              if (res4.data) {
                // console.log(1);
                let temp = JSON.parse(JSON.stringify(res4.data.data));
                this.data = temp;
                this.defaultExpandArray.push(this.data[0].id);
                this.data[0].children.forEach((item1) => {
                  this.defaultExpandArray.push(item1.id);
                  if (item1.children.length > 0) {
                    item1.children.forEach((item2) => {
                      this.defaultExpandArray.push(item2.id);
                    });
                  }
                });
                this.toggole(this.data);
                console.log("this.tempToggle", this.tempToggle);
                if (this.tempToggle.length >= 2) {
                  this.$nextTick(() => {
                    this.$refs.eltree.setCheckedKeys([
                      this.tempToggle[0],
                      this.tempToggle[1],
                    ]);
                  });
                } else if (this.tempToggle.length === 1) {
                  this.$message.error("请至少选中两项进行类比");
                  return;
                }
                // this.$nextTick(() => {
                //   this.$refs.eltree.setCheckedKeys([
                //     this.data[0].children[0].children[0].id,
                //     this.data[0].children[0].children[1].id,
                //   ]);
                // });
                // this.currentNodeID = this.data[0].nodeId;
                // debugger
                this.currentNodeIDArr = [
                  this.tempToggle2[0],
                  this.tempToggle2[1],
                ];
                this.currentNodeName = this.data[0].nodeName;
                let dataT = {
                  nodeIds: this.currentNodeIDArr,
                  projectId: JSON.parse(
                    window.localStorage.getItem("PROJECTID")
                  ),
                  treeId: this.treeId,
                };

                getMultiAttr(dataT).then((res) => {
                  // console.log(res);
                  let tempArr1 = res.data.data;
                  let tempArr2 = res.data.data.splice(0, 3);
                  this.attr1 = tempArr2;
                  this.attr2 = tempArr1;
                  // console.log(this.attr1, this.attr2);
                  this.radio = tempArr2[0].attrId;
                  this.currentAttrName = tempArr2[0].attrName;
                });
              }
            });
          } else {
            this.firstTree = {};
            this.treeName = "";
            this.treeId = "";
            this.data = [];
            this.attr1 = [];
            this.attr2 = [];
            this.selectValue = "";
            this.selectId = "";
            // this.selectValueInterval = ""
          }
        } else {
          this.$message.error(res3.data.msg);
        }
      });
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    clickSelect(e) {
      e.stopPropagation();
      this.isShowSelect = !this.isShowSelect;
    },
    clickZhu() {
      if (this.isActiveType === "showZhe") {
        this.isActiveType = "showZhu";
        this.srcZhu = `${require("@/assets/柱状图1.png")}`;
        this.srcZhe = `${require("@/assets/折线图2.png")}`;
      } else {
        this.isActiveType = "showZhe";
        this.srcZhu = `${require("@/assets/柱状图2.png")}`;
        this.srcZhe = `${require("@/assets/折线图1.png")}`;
      }
    },
    clickZhe() {
      if (this.isActiveType === "showZhu") {
        this.isActiveType = "showZhe";
        this.srcZhu = `${require("@/assets/柱状图2.png")}`;
        this.srcZhe = `${require("@/assets/折线图1.png")}`;
      } else {
        this.isActiveType = "showZhu";
        this.srcZhu = `${require("@/assets/柱状图1.png")}`;
        this.srcZhe = `${require("@/assets/折线图2.png")}`;
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    clickTime(index) {
      this.isActivenTime = index;

      if (index == 1) {
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD + " 00:00:00";
        this.changeDateType = "date";
        this.attrInterval = ["5分钟", "1小时"];
        this.selectValueInterval = "5分钟";
      } else if (index == 2) {
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD + " 00:00:00";
        this.changeDateType = "month";
        this.attrInterval = ["1小时", "1天"];
        this.selectValueInterval = "1小时";
      } else if (index == 3) {
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD + " 00:00:00";
        this.changeDateType = "year";
        this.attrInterval = ["1天", "1月"];
        this.selectValueInterval = "1天";
      }
    },
    showSelect() {
      this.isShowSelect = true;
    },
    clickOption(v) {
      // debugger
      this.isShowSelect = false;

      this.selectValue = v;
    },
    clickOption2(v) {
      // debugger
      this.isShowSelect = false;
      this.radio = "";
      this.selectValue = v.attrName;
      this.currentAttrName = this.selectValue;
      this.selectId = v.attrId;
    },
    validateCheck(
      checkedNodes,
      checkedKeys,
      halfCheckedNodes,
      halfCheckedKeys
    ) {
      // debugger;
      this.attr1 = [];
      this.attr2 = [];
      this.radio = "";
      this.selectId = "";
      console.log(checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys);
      if (checkedKeys.checkedKeys.length > 5) {
        let tempArray = this.$refs.eltree.getCheckedKeys();
        let res = tempArray.filter((num) => {
          return num !== checkedNodes.id;
        });
        this.$refs.eltree.setCheckedKeys(res);
        console.log("this.checkArray", this.checkArray);
        this.$message.warning("最多选择5项");
      } else {
        // console.log("this.checkArray", this.checkArray);
        // this.currentNodeID
        let tempArray = [];
        checkedKeys.checkedNodes.forEach((item) => {
          tempArray.push(item.nodeId);
        });
        let dataT = {
          nodeIds: tempArray,
          projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
          treeId: this.treeId,
        };
        this.currentNodeIDArr = tempArray;
        getMultiAttr(dataT).then((res) => {
          let tempArr1 = res.data.data;
          let tempArr2 = res.data.data.splice(0, 3);
          this.attr1 = tempArr2;
          this.attr2 = tempArr1;
          console.log(this.attr1, this.attr2);
          this.radio = tempArr2[0].attrId;
          this.currentAttrName = tempArr2[0].attrName;
        });
      }

      // if (
      //   checkedKeys.checkedKeys.length > 6 &&
      //   checkedNodes.name === "高新文体中心"
      // ) {
      //   // this.checkArray = []
      //   this.$refs.checkTree.setCheckedKeys([]);
      //   console.log("this.checkArray", this.checkArray);
      //   this.$message.warning("最多选择5项");
      //   return false;
      // } else {
      //   if (
      //     checkedKeys.checkedKeys.length > 6 &&
      //     checkedNodes.children === undefined
      //   ) {
      //     // debugger
      //     // this.checkArray = []
      //     console.log(
      //       "this.$refs.checkTree.getCheckedKeys()",
      //       this.$refs.checkTree.getCheckedKeys()
      //     );
      //     let tempArray = this.$refs.checkTree.getCheckedKeys();
      //     let res = tempArray.filter((num) => {
      //       return num !== checkedNodes.id;
      //     });
      //     this.$refs.checkTree.setCheckedKeys(res);
      //     console.log("this.checkArray", this.checkArray);
      //     this.$message.warning("最多选择5项");
      //     return false;
      //   } else if (
      //     checkedKeys.checkedKeys.length > 6 &&
      //     checkedNodes.children
      //   ) {
      //     let tempARR1 = [];
      //     tempARR1.push(checkedNodes.id);
      //     this.data[0].children[0].children.forEach((item) => {
      //       // debugger
      //       if (item.id === checkedNodes.id) {
      //         item.children.forEach((item2) => {
      //           // debugger
      //           tempARR1.push(item2.id);
      //         });
      //       }
      //     });
      //     console.log(
      //       "this.$refs.checkTree.getCheckedKeys()",
      //       this.$refs.checkTree.getCheckedKeys()
      //     );
      //     let tempArray = this.$refs.checkTree.getCheckedKeys();
      //     let res = tempArray.filter((num) => {
      //       // return num !== checkedNodes.id;
      //       if (!tempARR1.includes(num)) return num;
      //     });
      //     this.$refs.checkTree.setCheckedKeys(res);
      //     console.log("this.checkArray", this.checkArray);
      //     this.$message.warning("最多选择5项");
      //     return false;
      //   }
      // }
    },
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
    }),
    tuopuName() {
      if (this.clickCatagory === 1) {
        return "水能源拓扑图";
      } else if (this.clickCatagory === 2) {
        return "电能源拓扑图";
      } else if (this.clickCatagory === 3) {
        return "气能源拓扑图";
      } else {
        return "热能源拓扑图";
      }
    },
    chartLineOption1() {
      return {
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "shadow",
          // },
          // formatter: "{b}: {c}kWh",
        },
        legend: {
          data: this.legendName,
          right: 0,
          top: 0,
          textStyle: {
            color: "#082254",
          },
        },
        grid: {
          containLabel: true,
          top: "20px",
          left: "7px",
          right: "5px",
          bottom: "70px",
        },
        xAxis: {
          data: this.dataXaxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "slider",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
          {
            type: "inside",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
        ],
        series: this.seriesLine,
        // [
        //   {
        //     name: "全厂",
        //     type: "line",

        //     itemStyle: {
        //       color: "#41F39A",
        //     },
        //     data: data2,
        //   },
        //   {
        //     name: "办公楼a",
        //     type: "line",
        //     itemStyle: {
        //       color: "#F3BB3D",
        //     },
        //     data: data3,
        //   },
        //   {
        //     name: "办公楼b",
        //     type: "line",
        //     itemStyle: {
        //       color: "#417AF3",
        //       borderRadius: 8,
        //     },
        //     data: data4,
        //   },
        //   {
        //     name: "办公楼c",
        //     type: "line",
        //     itemStyle: {
        //       color: "#6341F3",
        //     },
        //     data: data5,
        //   },
        //   {
        //     name: "办公楼d",
        //     type: "line",
        //     itemStyle: {
        //       color: "#41CCF3",
        //     },
        //     data: data6,
        //   },
        // ],
      };
    },
    chartBarOption1() {
      return {
        legend: {
          data: this.legendName,
          right: 0,
          top: 0,
          textStyle: {
            color: "#082254",
          },
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "shadow",
          // },
          // formatter: "{b}: {c}kWh",
        },
        grid: {
          containLabel: true,
          top: "20px",
          left: "7px",
          right: "5px",
          bottom: "70px",
        },
        xAxis: {
          data: this.dataXaxis,
          interval: 10,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          // name:"()",
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "slider",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
          {
            type: "inside",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
        ],
        series: this.seriesBar,
        // [
        //   {
        //     name: "全厂",
        //     type: "bar",
        //     // barWidth: 16,
        //     itemStyle: {
        //       color: "#41F39A",
        //       borderRadius: 8,
        //     },
        //     data: data2,
        //   },
        //   {
        //     name: "办公楼a",
        //     type: "bar",
        //     // barWidth: 16,
        //     itemStyle: {
        //       color: "#F3BB3D",
        //       borderRadius: 8,
        //     },
        //     data: data3,
        //   },
        //   {
        //     name: "办公楼b",
        //     type: "bar",
        //     // barWidth: 16,
        //     itemStyle: {
        //       color: "#417AF3",
        //       borderRadius: 8,
        //     },
        //     data: data4,
        //   },
        //   {
        //     name: "办公楼c",
        //     type: "bar",
        //     // barWidth: 16,
        //     itemStyle: {
        //       color: "#6341F3",
        //       borderRadius: 8,
        //     },
        //     data: data5,
        //   },
        //   {
        //     name: "办公楼d",
        //     type: "bar",
        //     // barWidth: 16,
        //     itemStyle: {
        //       color: "#41CCF3",
        //       borderRadius: 8,
        //     },
        //     data: data6,
        //     // barGap: "30%",
        //   },
        // ],
      };
    },
    seriesBar() {
      // let arr = [];
      // return arr;
      let colorA = ["#41F39A", "#F3BB3D", "#417AF3", "#6341F3", "#41CCF3"];
      const series = this.dataAllV.map((v, i) => {
        return {
          name: v.name.split("-")[0],
          data: v.values,
          type: "bar",
          itemStyle: {
            color: colorA[i],
          },
        };
      });
      // let arr = [];
      return series;
    },
    seriesLine() {
      let colorA = ["#41F39A", "#F3BB3D", "#417AF3", "#6341F3", "#41CCF3"];
      const series = this.dataAllV.map((v, i) => {
        return {
          name: v.name.split("-")[0],
          data: v.values,
          type: "line",
          itemStyle: {
            color: colorA[i],
          },
        };
      });
      // let arr = [];
      return series;
    },
  },
};
</script>
<style></style>
<style scoped>
.el-tree >>> .is-current > .el-tree-node__content {
  background: transparent !important;
  /* color: white !important; */
}
.el-tree >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background: #3f6df8 !important;
  border-color: #3f6df8 !important;
}
.other-tree:last-child {
  border-bottom: 1px solid #3f6df8 !important;
}
.select2 >>> input {
  width: 160px !important;
  height: 29px !important;
  cursor: pointer;
}
.select2 >>> .el-input__icon {
  line-height: 29px !important;
}
.tuopu {
  width: 100%;
  height: calc(100% - 100px);
  background: url("~@/assets/电拓扑.png");
  background-size: cover;
  position: relative;
}
.isClick {
  color: #3e6cf7 !important;
  border-bottom: 4px solid #3e6cf7;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.date >>> input {
  width: 180px !important;
  height: 29px !important;
}
.date >>> .el-input__icon {
  line-height: 29px !important;
}

.select >>> input {
  width: 140px !important;
  height: 29px !important;
  cursor: pointer;
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 13px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.el-tree >>> .el-tree-node__content {
  height: 36px !important;
}
.el-tree {
  color: #082254 !important;
}
</style>
