import requestJm from "@/request/requestJm";
// 积木报表接口

export function queryOriginal() {
  return requestJm({
    url: "/jmreport/excelQuery?pageNo=1&pageSize=10&reportType=datainfo&name=",
    method: "get",
  });
}

// 获取自定义报表列表
export function reportList(sn) {
  return requestJm({
    url: `/report/list?${sn}`,
    method: "get",
  });
}
