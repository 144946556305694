<template>
  <div style="width: 100%; height: 100%; background: white; padding: 15px">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div>算法类型：</div>
        <div
          style="position: relative; margin-left: 5px; cursor: pointer"
          class="select"
        >
          <el-select
            v-model="query.type"
            placeholder="请选择算法类型"
            style="margin-left: 10px"
            @change="changeAlg"
          >
            <el-option
              v-for="item in options1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>

        <div style="margin-left: 20px">服务名称：</div>
        <div
          style="position: relative; margin-left: 5px; cursor: pointer"
          class="select"
        >
          <!-- <el-select
            v-model="query.algorithmServiceId"
            placeholder="请选择"
            style="margin-left: 10px"
          >
            <el-option
              v-for="item in optionsService"
              :key="item.serviceId"
              :label="item.name"
              :value="item.serviceId"
            >
            </el-option>
          </el-select> -->
          <el-input
            v-model="query.algorithmServiceName"
            placeholder="请输入服务名称"
            style="width: 160px"
          >
          </el-input>
        </div>
        <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
            margin-right: 20px;
            margin-left: 20px;
            cursor: pointer;
          "
          @click="searchFor"
        >
          查询
        </div>
      </div>

      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
            margin-right: 20px;
            cursor: pointer;
          "
          @click="toAdd"
        >
          新增
        </div>
      </div>
    </div>
    <template v-if="!loading">
      <div
        v-for="(item, index) in tableData"
        :key="index"
        style="
          width: 100%;
          height: 224px;
          border: 1px solid rgba(8, 34, 84, 0.1);
          position: relative;
          border-radius: 2px;
          margin-top: 20px;
        "
      >
        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #082254;
            position: absolute;
            top: 92px;
            left: 206px;
          "
        >
          服务名称：
        </div>

        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #082254;
            position: absolute;
            top: 132px;
            left: 206px;
          "
        >
          <!-- 故障诊断 -->
          {{ item.name }}
        </div>

        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #082254;
            position: absolute;
            top: 57px;
            left: 500px;
            line-height: 45px;
            width: 257px;
            height: 135px;
          "
        >
          <!-- 采用灵感能源自有的POS优化算法， 实现开机时间，机组匹配及频率 优化调节 -->
          {{ item.description }}
        </div>

        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #082254;
            position: absolute;
            top: 92px;
            left: 370px;
          "
        >
          引用算法库：
        </div>

        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #082254;
            position: absolute;
            top: 132px;
            left: 370px;
          "
        >
          <!-- AC-POS-01 -->
          {{ item.algorithmId }}
        </div>

        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #082254;
            position: absolute;
            top: 92px;
            left: 800px;
          "
        >
          算法类型：
        </div>

        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #082254;
            position: absolute;
            top: 132px;
            left: 800px;
          "
        >
          <!-- 否 -->
          {{ item.type | formateType }}
        </div>

        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #082254;
            position: absolute;
            top: 92px;
            left: 936px;
          "
        >
          是否使用：
        </div>

        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #082254;
            position: absolute;
            top: 132px;
            left: 936px;
          "
        >
          <!-- 否 -->
          {{ item.released ? "是" : "否" }}
        </div>

        <div v-if="item.type === 'ENERGY_SAVING'">
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              color: #082254;
              position: absolute;
              top: 92px;
              left: 1077px;
            "
          >
            节能量（近15天）
          </div>

          <div
            style="
              font-size: 16px;
              font-weight: 400;
              color: #082254;
              position: absolute;
              top: 132px;
              left: 1077px;
            "
          >
            13230kWh
          </div>

          <div
            style="
              position: absolute;
              top: 50px;
              left: 1218px;
              width: 340px;
              height: 145px;
            "
          >
            <v-chart ref="chart1" :option="chartBarOption1" />
          </div>
        </div>
        <div v-if="item.type === 'FORECAST'">
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              color: #082254;
              position: absolute;
              top: 92px;
              left: 1077px;
            "
          >
            预测准确率
          </div>

          <div
            style="
              font-size: 16px;
              font-weight: 400;
              color: #082254;
              position: absolute;
              top: 132px;
              left: 1077px;
            "
          >
            94%
          </div>

          <div
            style="
              position: absolute;
              top: 50px;
              left: 1218px;
              width: 340px;
              height: 145px;
            "
          >
            <v-chart ref="chart1" :option="chartMultiOption" />
          </div>
        </div>
        <div v-if="item.type === 'DIAGNOSIS'">
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              color: #082254;
              position: absolute;
              top: 92px;
              left: 1077px;
            "
          >
            故障触发（近15天）
          </div>

          <div
            style="
              font-size: 16px;
              font-weight: 400;
              color: #082254;
              position: absolute;
              top: 132px;
              left: 1077px;
            "
          >
            6次
          </div>

          <div
            style="
              position: absolute;
              top: 50px;
              left: 1218px;
              width: 340px;
              height: 145px;
            "
          >
            <v-chart ref="chart1" :option="chartBarOption2" />
          </div>
        </div>

        <div style="position: absolute; top: 10px; right: 20px; z-index: 999">
          <el-dropdown trigger="click" @command="handleCommand">
            <span style="color: #3f6df8"
              >更多<i class="el-icon-more-outline"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{
                  command: 'a',
                  item: item,
                }"
                >修改</el-dropdown-item
              >
              <el-dropdown-item
                :command="{
                  command: 'b',
                  item: item,
                }"
                >删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          style="
            width: 100%;
            position: absolute;
            z-index: 9;
            height: 100%;
            top: 0;
            left: 0;
            background: transparent;
          "
          @click="toMonitor(item)"
        ></div>
        <div
          style="
            width: 136px;
            height: 136px;

            border-radius: 4px;
            position: absolute;
            top: 50px;
            left: 40px;
          "
        >
          <img
            src="@/assets/矩形1.png"
            v-if="item.type === 'ENERGY_SAVING'"
            alt=""
            style="width: 100%; height: 100%"
          />
          <img
            src="@/assets/矩形2.png"
            v-if="item.type === 'FORECAST'"
            alt=""
            style="width: 100%; height: 100%"
          />
          <img
            src="@/assets/矩形3.png"
            v-if="item.type === 'DIAGNOSIS'"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>

        <div
          v-if="item.type === 'ENERGY_SAVING'"
          style="
            background: #f3bb3d;
            border-radius: 2px;
            height: 224px;
            width: 8px;
            position: absolute;
            left: 0;
            top: 0;
          "
        ></div>

        <div
          v-if="item.type === 'FORECAST'"
          style="
            background: #34d182;
            border-radius: 2px;
            height: 224px;
            width: 8px;
            position: absolute;
            left: 0;
            top: 0;
          "
        ></div>

        <div
          v-if="item.type === 'DIAGNOSIS'"
          style="
            background: #417af3;
            border-radius: 2px;
            height: 224px;
            width: 8px;
            position: absolute;
            left: 0;
            top: 0;
          "
        ></div>
      </div>
    </template>

    <div style="margin-top: 10px; text-align: right">
      <el-pagination
        @size-change="sizeChange"
        @current-change="pageChange"
        :current-page="query.page"
        :page-size="query.limit"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      title="上传文件"
      :visible.sync="changeReVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <div style="color: #082254; text-align: center; height: 50px">
        <!-- 是否重置密码？ -->
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="3"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button size="small" icon="el-icon-upload2">点击上传</el-button>
          <!-- <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div> -->
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureReset">确 定</el-button>
        <el-button @click="changeReVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="userSettingTitle"
      :visible.sync="changePasswordVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item
          label="服务名称："
          prop="name"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input :disabled="isView" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item
          label="算法类型："
          prop="type"
          style="position: relative; margin-bottom: 20px"
        >
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择"
            @change="changeAlg"
            :disabled="isView"
          >
            <el-option
              v-for="item in options11"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="引用算法："
          prop="algorithmId"
          style="position: relative; margin-bottom: 20px"
        >
          <el-select
            v-model="ruleForm.algorithmId"
            placeholder="请选择"
            :disabled="isView"
          >
            <el-option
              v-for="item in optionsService"
              :key="item.algorithmId"
              :label="item.name"
              :value="item.algorithmId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="算法描述："
          prop="desc"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input :disabled="isView" v-model="ruleForm.desc"></el-input>
        </el-form-item>
        <el-form-item
          label="执行条件："
          prop="case"
          style="position: relative; margin-bottom: 20px"
        >
          <el-select
            v-model="ruleForm.case"
            placeholder="请选择"
            @change="getOption"
            :disabled="isView"
          >
            <el-option
              v-for="item in optionsCase"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item
          label="数据名称："
          prop="username"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input :disabled="isView" v-model="ruleForm.username"></el-input>
        </el-form-item> -->

        <el-form-item
          label="是否使用："
          prop="released"
          style="position: relative; margin-bottom: 10px"
        >
          <el-switch v-model="ruleForm.released"> </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addNew">确 定</el-button>
        <el-button @click="changePasswordVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import formList from "./list";
import {
  getStoreOption,
  getAlgServiceList,
  editAlgService,
  // getAlgOption,
  deleteAlgService,
  resetPassword,
  getUserDetail,
  // addUser,
  // userSearch,
  deleteUser,
  // alarmSetUpdate,
  // editUser,
  // alarmSetCheck,
  // alarmSearchSet,
  alarmSetGet,
  // alarmSetLevel,
  bindInstanceTree,
  getInstanceTree,
  // alarmSetDel,
  // getGroupList,
  // getRoleList,
  // reportManageSearch,
  // getReportType,
  // getReportTypeParams,
  // reportManageAdd,
  reportManagechangeStatus,
  // reportManagedeatil,
  // reportManageupdate,
  // reportManageDelete,
  // getInstance,
  getInstanceAttr,
} from "@/request/apiLg";
export default {
  // components: {
  //   formList,
  // },
  filters: {
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    formateType: function (value) {
      if (value === "ENERGY_SAVING") {
        return "节能优化";
      } else if (value === "FORECAST") {
        return "负荷预测";
      } else if (value === "DIAGNOSIS") {
        return "故障诊断";
      }
    },
  },
  data() {
    // var validConfirmPass = (rule, value, callback) => {
    //   // debugger
    //   console.log("this.ruleForm", this.ruleForm);
    //   if (
    //     value !== "" &&
    //     this.ruleForm.newPassword !== "" &&
    //     value != this.ruleForm.newPassword
    //   ) {
    //     callback(new Error("密码与上面的新密码不符"));
    //   }
    //   callback();
    // };
    return {
      dataValue21: [
        6643, 7121, 8190, 6521, 6789, 7012, 8976, 7121, 6765, 6015, 7022, 6555,
        7121, 8191, 6752,
      ],
      dataValue22: [
        6244, 6764, 8108, 6315, 6519, 7011, 8571, 6918, 6411, 5902, 6619, 6500,
        6916, 7896, 6415,
      ],
      dataValueBar: [
        1200,
        1050,
        900,
        1050,
        810,
        990,
        {
          value: 390,
          itemStyle: {
            color: "#52D996",
          },
        },
        {
          value: 400,
          itemStyle: {
            color: "#52D996",
          },
        },
        {
          value: 750,
          itemStyle: {
            color: "#52D996",
          },
        },
        {
          value: 600,
          itemStyle: {
            color: "#52D996",
          },
        },
        {
          value: 810,
          itemStyle: {
            color: "#52D996",
          },
        },
        {
          value: 800,
          itemStyle: {
            color: "#52D996",
          },
        },
        {
          value: 610,
          itemStyle: {
            color: "#52D996",
          },
        },
        {
          value: 560,
          itemStyle: {
            color: "#52D996",
          },
        },
        {
          value: 780,
          itemStyle: {
            color: "#52D996",
          },
        },
      ],
      dataValueBar2: ["", "", "", 1, "", 2, 3, "", "", "", "", "", "", 2, ""],
      dataXaxis: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      optionsService: [],
      fileList: [],
      userSettingTitle: "新增算法",
      tempUserId: "",
      optionsSSS: [
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      optionsSS: [],
      optionsS: [
        {
          value: "ALL",
          label: "全部",
        },
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      isEdit: false,
      isView: false,
      testList: [
        {
          nodeName: "",
          show: true,
          childNodeList: [],
        },
      ],
      listTemp: [
        {
          name: 1,
          children: [
            {
              name: 2,
              children: [
                {
                  name: 3,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      changeTreeVisible: false,
      changeReVisible: false,
      optionsInstance: [
        {
          name: "节能优化",
          code: "jnyh",
        },
        {
          name: "负荷预测",
          code: "fhyc",
        },
        {
          name: "故障诊断",
          code: "gzzd",
        },
      ],
      optionsInstanceAttr: [],
      optionsSourceType: [
        {
          name: "展示节点",
          code: true,
        },
        {
          name: "具体仪表",
          code: false,
        },
      ],
      dateV: 1,
      selectTimeV21: 1,
      selectTimeV22: 0,
      isShowMonth: false,
      selectTimeV: 0,
      timeArray: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      timeArray2: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      treeReportId: "",
      isShowDay: false,
      options1: [
        { name: "全部", code: null },
        { name: "节能优化", code: "ENERGY_SAVING" },
        { name: "负荷预测", code: "FORECAST" },
        { name: "故障诊断", code: "DIAGNOSIS" },
      ],
      options11: [
        { name: "节能优化", code: "ENERGY_SAVING" },
        { name: "负荷预测", code: "FORECAST" },
        { name: "故障诊断", code: "DIAGNOSIS" },
      ],
      optionsCase: [{ name: "默认", code: "default" }],
      optionsAlg: [],
      options2: [[]],
      rules: {
        // userId: [
        //   { required: true, message: "请输入登录账号", trigger: "blur" },
        // ],
        // username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        // dataPrivilegeGroupIdList: [
        //   { required: true, message: "请选择项目", trigger: "change" },
        // ],
        // roleIdList: [
        //   { required: true, message: "请选择用户角色", trigger: "change" },
        // ],
        // newPassword: [
        //   { required: true, message: "请输入密码", trigger: "blur" },
        //   { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        // ],
        // confirmPassword: [
        //   { required: true, message: "请再次输入密码", trigger: "blur" },
        //   { validator: validConfirmPass, trigger: "blur" },
        //   { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        // ],
        // expireDate: [
        //   { required: true, message: "请选择到期时间", trigger: "change" },
        // ],
      },
      ruleForm: {
        // // alarmId: "",
        // userId: "",
        // username: "",
        // dataPrivilegeGroupIdList: [],
        // roleIdList: [],
        // newPassword: "",
        // confirmPassword: "",
        // expireDate: "",
        // // maxDisplay: 0,
        // // minDisplay: 0,
        // // // periodMinute: "",
        // // released: false,
        algorithmId: "",
        desc: "",
        name: "",
        released: false,
        case: "default",
        // serviceId: "",
        type: "",
      },
      treeForm: {
        treeName: "",
        treeArray: [1],
      },
      changePasswordVisible: false,
      total: 0,
      loading: true,
      value11: "",
      value22: "",
      currentName: "",
      isInterval: false,
      value2: [],
      tableKey: [],
      tableKey2: [],
      isborder1: false,
      tableData: [
        {
          name: "节能算法1",
          id: "AC-POS-01",
          status: "暂停",
          desc: "利用pos算法优化能效",
          type: "节能优化",
          script: "",
          config: "",
        },
        {
          name: "负荷算法1",
          id: "AC-POS-02",
          status: "启用",
          desc: "移动平均算法、技术",
          type: "负荷预测",
          script: "yuce.py",
          config: "yuce.py",
        },
        {
          name: "故障算法",
          id: "AC-POS-03",
          status: "启用",
          desc: "移动平均算法、技术",
          type: "故障诊断",
          script: "yuce.py",
          config: "yuce.py",
        },
        {
          name: "故障算法",
          id: "AC-POS-04",
          status: "启用",
          desc: "移动平均算法、技术",
          type: "故障诊断",
          script: "yuce.py",
          config: "yuce.py",
        },
      ],
      changeDateType: "date",
      selectValueX: "",
      isShowSelectx: false,
      selectValueY: "",
      isShowSelecty: false,
      // options1: [
      //   {
      //     value: "P",
      //     label: "电能-有功功率",
      //   },
      // ],
      // options2: [
      //   {
      //     value: "P",
      //     label: "水能-瞬时流量",
      //   },
      // ],
      value1: "",
      // value2: "",
      isActivenTime: 1,
      query: {
        limit: 3,
        page: 1,
        type: null,
        algorithmServiceName: "",
      },
    };
  },
  mounted() {
    // let dateT = new Date();
    // var YY = dateT.getFullYear() + "-";
    // var MM =
    //   (dateT.getMonth() + 1 < 10
    //     ? "0" + (dateT.getMonth() + 1)
    //     : dateT.getMonth() + 1) + "-";
    // var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    // this.value1 = YY + MM + DD;
    // this.loading = false;
    // dateT = dateT.setDate(dateT.getDate() + 1);
    let temp = [];
    for (let i = 0; i < 15; i++) {
      let dateT = new Date();
      dateT.setDate(dateT.getDate() - i);
      dateT = this.formatDate(dateT);
      dateT =
        dateT.split(" ")[0].split("-")[1] +
        "-" +
        dateT.split(" ")[0].split("-")[2];
      temp.unshift(dateT);
    }
    console.log("temp", temp);
    this.dataXaxis = temp;
    getAlgServiceList(this.query).then((res2) => {
      this.loading = false;
      console.log(res2);
      this.tableData = res2.data.data;
      this.total = res2.data.count;
      // this.tableKey = res2.data.data.timestamps;
    });
    // getAlgOption("ENERGY_SAVING").then((res2) => {
    //   console.log(res2);
    //   // this.tableKey = res2.data.data.timestamps;
    // });
    // // getReportType().then((res) => {
    // //   this.options1 = res.data.data;
    // //   getReportTypeParams(this.options1[0].code).then((res2) => {
    // //     this.options2[0] = res2.data.data;
    // //   });
    // // });
    // getRoleList().then((res) => {
    //   console.log(res.data.data);
    //   this.optionsSS = res.data.data;
    //   // this.mutationsGetInstance(res.data.data);
    // });
    // getGroupList().then((res) => {
    //   this.optionsInstance = res.data.data;
    //   // getInstanceAttr(this.optionsInstance[0].instanceId).then((res1) => {
    //   //   this.optionsInstanceAttr = res1.data.data;
    //   // });
    // });
  },
  computed: {
    ...mapState({
      insatnceArr: (state) => state.insatnceArr,
    }),
    chartMultiOption() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // legend: {
        //   data: this.datalegend1,
        //   right: "10%",
        //   top: 0,
        //   textStyle: {
        //     color: "#082254",
        //   },
        // },
        xAxis: [
          {
            type: "category",
            data: this.dataXaxis,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "实际值",
            type: "line",
            smooth: true,
            // barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
            },
            data: this.dataValue21,
          },
          {
            name: "预测值",
            type: "line",
            // yAxisIndex: 1,
            smooth: true,
            itemStyle: {
              color: "#41F39A",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#41F39A", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.dataValue22,
          },
        ],
      };
    },
    chartBarOption1() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          // formatter: "{b}: {c}",
          // formatter: (params) => {
          //   // debugger;
          //   // const time = that.chartData.value[params[0].dataIndex].time;
          //   // if (params[0].value[1] === 0) {
          //   //   return (
          //   //     (this.fixType(0, that.chartData.symbol) +
          //   //         " " +
          //   //         that.chartData.unit
          //   //       ) +
          //   //     "<br>" +
          //   //     time
          //   //   );
          //   // }
          //   return (
          //     "时间：" + params[0].axisValue + "<br>" + "值：" + params[0].value
          //     // (params[0].value[1]
          //     //   ? this.fixType(params[0].value[1], that.chartData.symbol) +
          //     //     " " +
          //     //     that.chartData.unit
          //     //   : "") +
          //     // "<br>" +
          //     // time
          //   );
          // },
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        xAxis: {
          data: this.dataXaxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          // name:"()",
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        // dataZoom: [
        //   {
        //     type: "slider",
        //     // type: "inside",
        //     start: 0,
        //     // end: this.changeZoom,
        //     end: 100,
        //   },
        //   {
        //     type: "inside",
        //     // type: "inside",
        //     start: 0,
        //     // end: this.changeZoom,
        //     end: 100,
        //   },
        // ],
        series: [
          {
            name: "当月用能",
            type: "bar",
            itemStyle: {
              color: "#417AF3",
              borderRadius: 15,
            },
            data: this.dataValueBar,
          },
        ],
      };
    },
    chartBarOption2() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "0px",
        },
        xAxis: {
          data: this.dataXaxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          // name:"()",
          minInterval: 1,
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: "当月用能",
            type: "bar",
            itemStyle: {
              color: "#417AF3",
              borderRadius: 15,
            },
            data: this.dataValueBar2,
          },
        ],
      };
    },
  },
  methods: {
    changeAlg(v) {
      console.log("v", v);
      this.ruleForm.algorithmId = "";
      if (v === null) {
        this.optionsService = [];
        this.query.algorithmServiceId = "";
      } else {
        getStoreOption(v).then((res) => {
          this.optionsService = res.data.data;
          this.query.algorithmServiceId =
            this.optionsService[0].algorithmServiceId;
        });
      }
    },
    toMonitor(item) {
      console.log("item", item);

      if (item.type === "FORECAST") {
        this.$router.push({ name: "monitor", query: { id: 1 } });
      } else if (item.type === "ENERGY_SAVING") {
        this.$router.push({ name: "monitor", query: { id: 2 } });
      } else {
        return;
        // this.$router.push({ name: "monitor", id: 3 });
      }
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      console.log(file, fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    resetPassword(id) {
      this.tempUserId = id;
      this.changeReVisible = true;
    },
    sureReset() {
      resetPassword(this.tempUserId).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("重置密码成功，新密码为123456");
          this.changeReVisible = false;
        }
      });
    },
    ...mapMutations(["mutationsGetInstance"]),
    toDetail(id) {
      // alarmSetGet(id).then((resA) => {
      //   console.log(resA)
      // })
      // this.$router.push({
      //   name: "alarmsetDetail",
      //   query: { id: id },
      // });

      alarmSetGet(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          getInstanceAttr(resA.data.data.instanceId).then((res2) => {
            // debugger
            // this.options2[v] = res2.data.data;
            // console.log("this.options2", this.options2);
            // let temp = JSON.parse(JSON.stringify(this.options2));
            // this.options2 = temp;
            this.optionsInstanceAttr = res2.data.data;
            this.changePasswordVisible = true;
            this.isEdit = false;
            this.isView = true;
            this.ruleForm = resA.data.data;
            this.ruleForm.periodTime =
              (this.ruleForm.periodTime / 60).toString() + "分钟";
          });
        }
      });
    },
    showUpdate(id) {
      getUserDetail(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          // getInstanceAttr(resA.data.data.instanceId).then((res2) => {
          //   // debugger
          //   // this.options2[v] = res2.data.data;
          //   // console.log("this.options2", this.options2);
          //   // let temp = JSON.parse(JSON.stringify(this.options2));
          //   // this.options2 = temp;
          //   this.optionsInstanceAttr = res2.data.data;
          //   this.changePasswordVisible = true;
          //   this.isEdit = true;
          //   this.isView = false;
          //   this.ruleForm = resA.data.data;
          // });
          this.userSettingTitle = "编辑算法";
          this.changePasswordVisible = true;
          this.isEdit = true;
          this.isView = false;
          this.ruleForm = resA.data.data;
          // this.ruleForm.reportName = resA.data.data.name;
          // this.selectTimeV21 = resA.data.data.beginDay;
          // this.selectTimeV = resA.data.data.beginHour;
          // this.ruleForm.reportTypesArray = [];
          // this.options2 = [[]];
          // getReportType().then((res) => {
          //   this.options1 = res.data.data;
          //   console.log(
          //     "resA.data.data.reportTypeAndParas",
          //     resA.data.data.reportTypeAndParas
          //   );
          //   // resA.data.data.reportTypeAndParas.forEach((item1, index1) => {
          //   // this.ruleForm.reportTypesArray.push({
          //   //   params1: item1.reportTypeName
          //   // });
          //   // debugger;
          //   for (let i = 0; i < resA.data.data.reportTypeAndParas.length; i++) {
          //     getReportTypeParams(
          //       resA.data.data.reportTypeAndParas[i].reportType
          //     ).then((res2) => {
          //       // debugger
          //       if (resA.data.data.reportTypeAndParas[i].reportParas) {
          //         this.options2[i] = res2.data.data;
          //         this.ruleForm.reportTypesArray.push({
          //           params1: resA.data.data.reportTypeAndParas[i].reportType,
          //           params2: [],
          //         });
          //         resA.data.data.reportTypeAndParas[i].reportParas.forEach(
          //           (item2) => {
          //             this.ruleForm.reportTypesArray[i].params2.push(
          //               item2.reportType
          //             );
          //           }
          //         );
          //       } else {
          //         this.ruleForm.reportTypesArray.push({
          //           params1: resA.data.data.reportTypeAndParas[i].reportType,
          //           params2: [],
          //         });
          //         this.options2[i] = [];
          //       }
          //       this.$nextTick(() => {
          //         let temp1 = JSON.parse(JSON.stringify(this.ruleForm));
          //         let temp2 = JSON.parse(JSON.stringify(this.options2));
          //         this.ruleForm = temp1;
          //         this.options2 = temp2;
          //         console.log(
          //           "111",
          //           this.options2,
          //           this.ruleForm.reportTypesArray
          //         );
          //       });
          //     });
          //   }

          //   // });
          // });
        }
      });
    },
    changeStatus(id, index) {
      if (index === 0) {
        this.$confirm("确认暂停？")
          .then(() => {
            // done();
            reportManagechangeStatus(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      } else if (index === 1) {
        this.$confirm("确认发布？")
          .then(() => {
            // done();
            reportManagechangeStatus(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      }
    },
    deleteReport(id) {
      this.$confirm("确认删除？")
        .then(() => {
          // done();
          deleteUser(id).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.searchFor();
            }
          });
        })
        .catch(() => {});
    },
    toBind(id) {
      console.log(id);
      this.treeReportId = id;
      getInstanceTree(id).then((res) => {
        console.log(res);
        if (res.data.data) {
          if (res.data.data.treeNodeList.length > 0) {
            this.testList = res.data.data.treeNodeList;
          }
          this.treeForm.treeName = res.data.data.treeName;
        }

        this.changeTreeVisible = true;
      });
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      console.log(command);
      if (command.command === "a") {
        // this.testList[command.index].childNodeList.push({
        //   childNodeList: [],
        //   nodeName: "",
        //   show: true,
        // });

        this.ruleForm = JSON.parse(JSON.stringify(command.item));
        this.ruleForm.case = "default";
        this.ruleForm.desc = command.item.description;
        getStoreOption(this.ruleForm.type).then((res) => {
          this.optionsService = res.data.data;
          this.query.algorithmServiceId =
            this.optionsService[0].algorithmServiceId;
          this.isEdit = true;
          this.isView = false;
          this.changePasswordVisible = true;
        });
        // this.ruleForm.roleName = command.item.roleName;
      } else if (command.command === "b") {
        // this.testList.splice(command.index, 1);
        this.$confirm("确认删除？").then(() => {
          // done();
          deleteAlgService(command.item.serviceId).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.searchFor();
            }
          });
        });
      }
    },
    addTreeNode() {
      this.testList.push({
        childNodeList: [],
        nodeName: "",
        show: true,
      });
    },
    deleteTree(index) {
      this.testList.splice(index, 1);
    },
    selectTimeOption(item, e) {
      e.stopPropagation();
      this.selectTimeV = item;
      this.isShowDay = false;
    },
    selectTimeOption2(item, e) {
      e.stopPropagation();
      this.selectTimeV21 = item;
      this.isShowMonth = false;
    },
    showDaySelect() {
      this.isShowDay = !this.isShowDay;
    },
    showMonthSelect() {
      this.isShowMonth = !this.isShowMonth;
    },
    addTypes() {
      this.ruleForm.reportTypesArray.push({
        params1: "",
        params2: [],
      });
      this.options2.push([]);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    deleteTypes(index) {
      console.log(index);
      this.ruleForm.reportTypesArray.splice(index, 1);
      this.options2.splice(index, 1);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    getOption(e) {
      console.log(e);
      // getInstanceAttr(e).then((res2) => {
      //   // debugger
      //   // this.options2[v] = res2.data.data;
      //   // console.log("this.options2", this.options2);
      //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //   // this.options2 = temp;
      //   this.optionsInstanceAttr = res2.data.data;
      //   this.ruleForm.maxDisplay = "";
      //   this.ruleForm.minDisplay = "";
      //   this.ruleForm.attrId = "";
      // });
    },
    getOption2(e) {
      console.log(e);
      // this.optionsInstanceAttr.forEach((item) => {
      //   if (item.attrId === e) {
      //     if (item.maxDisplay !== null) {
      //       this.ruleForm.maxDisplay = item.maxDisplay;
      //     }
      //     if (item.minDisplay !== null) {
      //       this.ruleForm.minDisplay = item.minDisplay;
      //     }
      //   }
      // });
      // getInstanceAttr(e).then((res2) => {
      //   // debugger
      //   // this.options2[v] = res2.data.data;
      //   // console.log("this.options2", this.options2);
      //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //   // this.options2 = temp;
      //   this.optionsInstanceAttr = res2.data.data;
      // });
    },
    toAdd() {
      this.changePasswordVisible = true;
      this.isEdit = false;
      this.isView = false;
      this.userSettingTitle = "新增算法服务";
      this.ruleForm = {
        // // alarmId: "",
        // userId: "",
        // username: "",
        // dataPrivilegeGroupIdList: [],
        // roleIdList: [],
        // newPassword: "",
        // confirmPassword: "",
        // expireDate: "",
        // // maxDisplay: 0,
        // // minDisplay: 0,
        // // // periodMinute: "",
        // // released: false,
        algorithmId: "",
        desc: "",
        name: "",
        released: false,
        case: "default",
        // serviceId: "",
        type: "",
      };
    },
    sizeChange(val) {
      this.query.page = 1;
      this.query.limit = val;
      this.searchFor();
    },
    pageChange(val) {
      this.query.page = val;
      this.searchFor();
    },
    searchFor() {
      // this.$router.push({ name: "alarmDetail" });
      // console.log("this.value1", this.value1);
      this.loading = true;
      getAlgServiceList(this.query).then((res) => {
        console.log(res);
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.count;
      });
    },
    reportTypeV(v) {
      let temp = [];
      v.forEach((item) => {
        temp.push(item);
      });
      temp = temp.join(",");
      return temp;
    },
    reportTypeV2(v) {
      let temp = [];
      v.forEach((item) => {
        if (item.reportParas) {
          item.reportParas.forEach((item2) => {
            temp.push(item2.reportTypeName);
          });
        }
      });
      temp = temp.join(",");
      return temp;
    },
    timeChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    clickTime(index) {
      this.isActivenTime = index;
      this.value1 = "";
      this.value2 = [];
      if (index == 1) {
        this.isInterval = false;
        this.changeDateType = "date";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD;
      } else if (index == 2) {
        this.isInterval = false;
        this.changeDateType = "month";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 3) {
        this.isInterval = false;
        this.changeDateType = "year";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 4) {
        this.isInterval = true;
        let dateT = new Date();
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value11 = YY + MM + DD;
        dateT = new Date(this.value11);
        dateT = dateT.setDate(dateT.getDate() + 1);
        this.value22 = this.formatDate(dateT);
        this.value22 = this.value22.split(" ")[0];
        console.log("this.value1", this.value11);
        console.log("this.value2", this.value22);
        this.value2 = [this.value11, this.value22];
        this.query.startTime = this.value11;
        this.query.endTime = this.value22;
      }
    },
    clickX() {},
    clickOptionX() {},
    clickY() {},
    clickOptionY() {},
    onSearch() {
      this.loading = true;
      this.tableData = [];
      this.tableKey = [];
      this.tableKey2 = [];
      if (this.isActivenTime == 1) {
        this.query.startTime = this.value1;
        this.query.endTime = "";
        this.query.unit = "DAYS";
      } else if (this.isActivenTime == 2) {
        this.query.startTime =
          this.value1.split("-")[0] + "-" + this.value1.split("-")[1];
        this.query.endTime = "";
        this.query.unit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        this.query.startTime = this.value1.split("-")[0];
        this.query.endTime = "";
        this.query.unit = "YEARS";
      } else if (this.isActivenTime == 4) {
        // this.query.startTime = this.value1;
        // this.query.endTime = "";
        this.query.unit = null;
      }
      // reportSearch(this.query).then((res2) => {
      //   this.loading = false;
      //   console.log(res2);
      //   this.tableData = res2.data.data.rows;
      //   // this.tableKey = res2.data.data.timestamps;
      //   let temp = [];
      //   res2.data.data.timestamps.forEach((item) => {
      //     temp.push(this.formatDate(item));
      //   });
      //   this.tableKey = temp;
      //   this.tableKey2 = res2.data.data.columns;
      // });
    },
    bindTree() {
      let temp = {
        reportId: this.treeReportId,
        treeName: this.treeForm.treeName,
        treeNodeList: this.testList,
      };
      bindInstanceTree(temp).then((res) => {
        if (res.data.code === 0) {
          this.changeTreeVisible = false;
          this.$message.success("绑定成功");
          this.searchFor();
        }
        // console.log(res);
      });
    },
    addNew() {
      // let temp = {
      //   alarmId: "",
      //   alarmLevelId: "",
      //   alarmLevelName: "",
      //   alarmType: "",
      //   attrId: "",
      //   attrName: "",
      //   instanceId: "",
      //   instanceName: "",
      //   maxDisplay: 0,
      //   minDisplay: 0,
      //   periodMinute: "",
      //   released: true,
      // };
      if (this.isView) {
        this.changePasswordVisible = false;
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            // alarmSetCheck(this.ruleForm).then((resC) => {
            // if (!resC.data.data.exist) {
            if (!this.isEdit) {
              this.ruleForm.pwd = this.ruleForm.newPassword;
              editAlgService(this.ruleForm).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.$message.success("新增成功");
                  this.changePasswordVisible = false;
                  this.searchFor();
                }
              });
            } else {
              if (this.ruleForm.pwd) {
                delete this.ruleForm.pwd;
              }
              let dateT = new Date();
              this.ruleForm.updateTime = this.formatDate(dateT);
              editAlgService(this.ruleForm).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.changePasswordVisible = false;
                  this.$message.success("编辑成功");
                  this.searchFor();
                }
              });
            }
            // } else {
            //   // this.$message.error("该告警类型已配置，请编辑");
            //   this.$message({
            //     showClose: false,
            //     message: "该告警类型已配置，请编辑",
            //     type: "error",
            //     duration: 7000,
            //   });
            //   alarmSetGet(resC.data.data.alarmId).then((res) => {
            //     console.log(res);
            //     if (res.data.code === 0) {
            //       // this.$message.success("修改状态成功");
            //       getInstanceAttr(res.data.data.instanceId).then((res2) => {
            //         // debugger
            //         // this.options2[v] = res2.data.data;
            //         // console.log("this.options2", this.options2);
            //         // let temp = JSON.parse(JSON.stringify(this.options2));
            //         // this.options2 = temp;
            //         this.optionsInstanceAttr = res2.data.data;
            //         this.changePasswordVisible = true;
            //         this.isEdit = true;
            //         this.isView = false;
            //         this.ruleForm = res.data.data;
            //       });
            //     }
            //   });
            // }
            // });
            // this.ruleForm.beginDay = this.selectTimeV21;
            // this.ruleForm.beginHour = this.selectTimeV;
            // let temp = [];
            // this.ruleForm.reportTypesArray.forEach((item) => {
            //   if (item.params1) {
            //     if (item.params2.length > 0) {
            //       item.params2.forEach((item3) => {
            //         temp.push(item3);
            //       });
            //     } else {
            //       temp.push(item.params1);
            //     }
            //   }
            // });
            // this.ruleForm.reportTypes = temp;
            // if (!this.isEdit) {
            //   reportManageAdd(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("新增成功");
            //       this.searchFor();
            //     }
            //   });
            // } else {
            //   reportManageupdate(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("编辑成功");
            //       this.searchFor();
            //     }
            //   });
            // }
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.el-dialog__wrapper >>> input {
  width: 350px !important;
  height: 32px !important;
}

.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
.time-square:hover {
  background: #3f6df8 !important;
  color: white !important;
}
.isSelectTime {
  background: #3f6df8 !important;
  color: white !important;
}
.small-s >>> input {
  width: 220px !important;
  height: 32px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 750px !important;
}
.inline-input >>> input {
  width: 160px !important;
}
.small-input >>> input {
  width: 160px !important;
}
.small-input >>> .el-input {
  width: 160px !important;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>