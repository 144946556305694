import request from "./request";

//获取字典值
export function getKeyMap(deviceType) {
  return request({
    url: `/jkjd/screen/query/${deviceType}`,
    method: "get",
  });
}

//获取实时值
export function getRealtime(data) {
  return request({
    url: `/monitor/list/realtime`,
    method: "post",
    data,
  });
}
//当日冷量
export function getCoolCount() {
  return request({
    url: `/jkjd/screen/day/cool/capacity`,
    method: "get",
  });
}
//瞬时COP
export function getInstantCOP() {
  return request({
    url: `/jkjd/screen/instant/cop`,
    method: "get",
  });
}
//累计COP
export function getAddCOP() {
  return request({
    url: `/jkjd/screen/add/cop`,
    method: "get",
  });
}
//设备用电占比
export function getElecRate(data) {
  return request({
    url: `/jkjd/screen/device/power/ratio`,
    method: "post",
    data,
  });
}
//根据年月日接测点
export function getPoint(data) {
  return request({
    url: `/jkjd/screen/point/data/query`,
    method: "post",
    data,
  });
}
//运行轨迹
export function getTrack(data) {
  return request({
    url: `/jkjd/screen/running/track`,
    method: "post",
    data,
  });
}

//寻优
export function optimizationData(data) {
  return request({
    url: `/jkjd/screen/optimization/data/query`,
    method: "post",
    data,
  });
}
