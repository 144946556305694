<template>
  <div style="width: 100%; height: 100%; background: white">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="水能" name="first">水能</el-tab-pane>
      <el-tab-pane label="电能" name="second">电能</el-tab-pane>
      <el-tab-pane label="气能" name="third">气能</el-tab-pane>
      <el-tab-pane label="热能" name="fourth">热能</el-tab-pane>
    </el-tabs> -->
    <div
      style="
        width: 100%;
        height: 50px;
        border-bottom: 1px solid rgba(8, 34, 84, 0.1);
        padding-left: 50px;
      "
    >
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        v-for="(item, index) in dataTabs"
        :key="index"
        @click="clickTabs(item)"
        :class="{ isClick: currentTabs === item.energyTypeId }"
      >
        {{ item.energyTypeName }}
      </div>
      <!-- <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 1"
        :class="{ isClick: clickCatagory === 1 }"
      >
        水能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 2"
        :class="{ isClick: clickCatagory === 2 }"
      >
        电能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 3"
        :class="{ isClick: clickCatagory === 3 }"
      >
        气能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 4"
        :class="{ isClick: clickCatagory === 4 }"
      >
        热能
      </div> -->
    </div>
    <!-- <div style="width: 100%; height: 10px; filter: blur(10px);background: rgba(8, 34, 84, 0.1);"></div> -->
    <div
      style="
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        justify-content: space-between;
        overflow: auto;
      "
    >
      <div
        style="
          height: 100%;
          width: 250px;
          flex: none;
          border-right: 1px solid rgba(8, 34, 84, 0.1);
          overflow: hidden;
          position: relative;
        "
      >
        <div
          style="
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            color: #082254;
            text-align: center;
          "
        >
          {{ treeName }}
        </div>
        <div style="overflow: auto; width: 100%; height: 550px">
          <el-tree
            ref="eltree"
            style="width: 100%; max-height: 550px; overflow: auto"
            :data="data"
            node-key="id"
            :props="defaultProps"
            @node-click="getTreeAttr"
            :default-expanded-keys="defaultExpandArray"
            :current-node-key="defaultKey"
          >
          </el-tree>
        </div>
        <div style="width: 100%; position: absolute; left: 0; bottom: 0">
          <!-- <div
            style="
              width: 100%;
              height: 50px;
              line-height: 50px;
              font-size: 14px;
              text-align: center;
              color: #3f6df8;
              border: 1px solid #3f6df8;
            "
          >
            分类二
            <img
            src="@/assets/向下.png"
            style="position: absolute; top: 18px; right: 25px"
            />
          </div> -->
          <div
            v-for="(item, index) in dataTreeArray"
            :key="index"
            style="
              width: 100%;
              height: 50px;
              line-height: 50px;
              font-size: 14px;
              text-align: center;
              color: #3f6df8;
              border: 1px solid #3f6df8;
              border-bottom: none;
              cursor: pointer;
            "
            class="other-tree"
            @click="clickOtherTree(item, index)"
          >
            {{ item.treeName }}
          </div>
        </div>
      </div>
      <div style="flex: 1; padding-left: 50px; padding-right: 50px">
        <div
          style="
            width: 100%;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            color: #082254;
          "
        >
          {{ currentTabsName }}能源流向图
        </div>
        <div v-if="showCharts" style="width: 100%; height: calc(100% - 118px)">
          <v-chart ref="chart1" :option="chartOption1" />
        </div>
      </div>
      <!-- <div
        style="
          width: 100%;
          height: 100px;
          line-height: 100px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #082254;
        "
      >
        {{tuopuName}}
      </div>
      <div class="tuopu"></div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  // getProjects,
  getEnergy,
  getTreeInfo,
  getTree,
  getSankeyData,
  setProject,
} from "@/request/apiLg";
export default {
  data() {
    return {
      showCharts: true,
      currentTabsName: "",
      treeName: "",
      currentTabs: {},
      dataTabs: [],
      dataTreeArray: [],
      defaultExpandArray: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      defaultKey: "",
      dataName: [],
      dataLinks: [],
      // chartOption1: {},
      activeName: "second",
      clickCatagory: 1,
      data: [],
      // defaultProps: {
      //   children: "children",
      //   label: "label",
      // },
    };
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
    }),
    tuopuName() {
      if (this.clickCatagory === 1) {
        return "水能源流向图";
      } else if (this.clickCatagory === 2) {
        return "电能源流向图";
      } else if (this.clickCatagory === 3) {
        return "气能源流向图";
      } else {
        return "热能源流向图";
      }
    },
    chartOption1() {
      return {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        // emphasis:{
        //   focus:"self"
        // },
        series: {
          type: "sankey",
          layout: "none",
          emphasis: {
            focus: "none",
          },
          itemStyle: {
            color: "#071E8C",
          },
          lineStyle: {
            color: "#3F6DF8",
            opacity: 0.3,
            curveness: 0.5,
          },
          data: this.dataName,
          links: this.dataLinks,
        },
      };
    },
  },
  watch: {
    currentProjectId(val) {
      // debugger
      console.log(val);
      this.dataTreeArray = [];
      this.data = [];
      this.dataName = [];
      this.dataLinks = [];
      setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
        (res2) => {
          if (res2.data.code === 0) {
            getEnergy().then((res2) => {
              if (res2.data) {
                this.dataTabs = res2.data.data;
                if (res2.data.data.length === 0) {
                  this.$message.error("能源种类为空");
                  return;
                }
                this.currentTabs = res2.data.data[0].energyTypeId;
                this.currentTabsName = res2.data.data[0].energyTypeName;
                // this.allElecP = res.data.data;
                // debugger
                let obj = {
                  energyTypeId: res2.data.data[0].energyTypeId,
                  projectId: JSON.parse(
                    window.localStorage.getItem("PROJECTID")
                  ),
                };
                getTreeInfo(obj).then((res3) => {
                  // debugger
                  if (res3.data) {
                    if (res3.data.data.length > 0) {
                      this.firstTree = res3.data.data[0];
                      this.treeName = this.firstTree.treeName;
                      this.treeId = this.firstTree.treeId;
                      let temp1 = JSON.parse(JSON.stringify(res3.data.data));
                      temp1.splice(0, 1);
                      // console.log("temp2", temp2);
                      this.dataTreeArray = temp1;
                      // console.log("this.dataTreeArray", this.dataTreeArray);
                      // console.log("this.dataTreeArray", this.dataTreeArray);
                      getTree(res3.data.data[0].treeId).then((res4) => {
                        if (res4.data) {
                          // console.log(1);
                          this.data = res4.data.data;
                          // this.defaultKey = this.data[0].id;
                          this.defaultExpandArray.push(this.data[0].id);
                          this.data[0].children.forEach((item1) => {
                            this.defaultExpandArray.push(item1.id);
                            // if(item1.children.length>0){
                            //   item1.children.forEach(item2=>{
                            //     this.defaultExpandArray.push(item2.id)
                            //   })
                            // }
                          });
                          this.$nextTick(() => {
                            this.$refs.eltree.setCurrentKey(this.data[0].id);
                          });
                          this.currentNodeID = this.data[0].nodeId;
                          this.currentNodeName = this.data[0].nodeName;
                          let dataT = {
                            nodeId: this.data[0].nodeId,
                            projectId: JSON.parse(
                              window.localStorage.getItem("PROJECTID")
                            ),
                            treeId: this.treeId,
                            energyTypeId: this.currentTabs,
                          };
                          getSankeyData(dataT).then((res) => {
                            console.log(res);
                            res.data.data.data.forEach((item) => {
                              this.dataName.push({ name: item.name });
                            });
                            this.dataLinks = res.data.data.links;
                            console.log(this.dataName, this.dataLinks);
                          });
                        }
                      });
                    } else {
                      this.treeName = "";
                      this.data = [];
                    }
                  } else {
                    this.$message.error(res3.data.msg);
                  }
                });
              }
            });
          }
        }
      );
    },
  },
  methods: {
    clickOtherTree(item, index) {
      this.dataName = [];
      this.dataLinks = [];
      this.dataTreeArray[index] = this.firstTree;
      this.firstTree = item;
      console.log("this.dataTreeArray", this.dataTreeArray);
      this.treeName = item.treeName;
      this.treeId = item.treeId;
      getTree(this.treeId).then((res4) => {
        if (res4.data) {
          // console.log(1);
          this.data = res4.data.data;
          // this.defaultKey = this.data[0].id;
          this.defaultExpandArray.push(this.data[0].id);
          this.data[0].children.forEach((item1) => {
            this.defaultExpandArray.push(item1.id);
            // if(item1.children.length>0){
            //   item1.children.forEach(item2=>{
            //     this.defaultExpandArray.push(item2.id)
            //   })
            // }
          });
          this.$nextTick(() => {
            this.$refs.eltree.setCurrentKey(this.data[0].id);
          });
          this.currentNodeID = this.data[0].nodeId;
          this.currentNodeName = this.data[0].nodeName;
          let dataT = {
            nodeId: this.data[0].nodeId,
            projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
            treeId: this.treeId,
            energyTypeId: this.currentTabs,
          };
          getSankeyData(dataT).then((res) => {
            console.log(res);
            this.showCharts = false;
            this.$nextTick(() => {
              this.showCharts = true;
              res.data.data.data.forEach((item) => {
                this.dataName.push({ name: item.name });
              });
              this.dataLinks = res.data.data.links;
              console.log("qqq",this.dataName, this.dataLinks);
            });
          });
        }
      });
    },
    getTreeAttr(val1, val2, val3) {
      console.log(val1, val2, val3);
      this.dataName = [];
      this.dataLinks = [];
      if (val1.children.length === 0) {
        let data = {
          nodeId: val2.parent.data.nodeId,
          projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
          treeId: this.treeId,
          energyTypeId: this.currentTabs,
        };
        this.currentNodeID = val1.nodeId;
        this.currentNodeName = val1.nodeName;
        getSankeyData(data).then((res) => {
          console.log(res);
          res.data.data.data.forEach((item) => {
            this.dataName.push({ name: item.name });
          });
          this.dataLinks = res.data.data.links;
          console.log(this.dataName, this.dataLinks);
        });
      } else {
        let data = {
          nodeId: val1.nodeId,
          projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
          treeId: this.treeId,
          energyTypeId: this.currentTabs,
        };
        this.currentNodeID = val1.nodeId;
        this.currentNodeName = val1.nodeName;
        getSankeyData(data).then((res) => {
          console.log(res);
          res.data.data.data.forEach((item) => {
            this.dataName.push({ name: item.name });
          });
          this.dataLinks = res.data.data.links;
          console.log(this.dataName, this.dataLinks);
        });
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    clickTabs(item) {
      this.dataName = [];
      this.dataLinks = [];
      this.currentTabs = item.energyTypeId;
      this.currentTabsName = item.energyTypeName;
      let obj = {
        energyTypeId: this.currentTabs,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
      };
      this.dataTreeArray = [];
      getTreeInfo(obj).then((res3) => {
        if (res3.data) {
          if (res3.data.data.length > 0) {
            this.firstTree = res3.data.data[0];
            this.treeName = this.firstTree.treeName;
            this.treeId = this.firstTree.treeId;
            let temp1 = JSON.parse(JSON.stringify(res3.data.data));
            temp1.splice(0, 1);
            // console.log("temp2", temp2);
            this.dataTreeArray = temp1;
            // console.log("this.dataTreeArray", this.dataTreeArray);
            getTree(res3.data.data[0].treeId).then((res4) => {
              if (res4.data) {
                // console.log(1);
                this.data = res4.data.data;
                this.defaultExpandArray.push(this.data[0].id);
                this.data[0].children.forEach((item1) => {
                  this.defaultExpandArray.push(item1.id);
                  // if(item1.children.length>0){
                  //   item1.children.forEach(item2=>{
                  //     this.defaultExpandArray.push(item2.id)
                  //   })
                  // }
                });

                this.$nextTick(() => {
                  this.$refs.eltree.setCurrentKey(this.data[0].id);
                });
                this.currentNodeID = this.data[0].nodeId;
                this.currentNodeName = this.data[0].nodeName;
                let dataT = {
                  nodeId: this.data[0].nodeId,
                  projectId: JSON.parse(
                    window.localStorage.getItem("PROJECTID")
                  ),
                  treeId: this.treeId,
                  energyTypeId: this.currentTabs,
                };
                getSankeyData(dataT).then((res) => {
                  console.log(res);
                  res.data.data.data.forEach((item) => {
                    this.dataName.push({ name: item.name });
                  });
                  this.dataLinks = res.data.data.links;
                  console.log(this.dataName, this.dataLinks);
                });
                // let dataT = {
                //   nodeId: this.data[0].nodeId,
                //   projectId: window.localStorage.getItem("PROJECTID"),
                //   treeId: this.treeId,
                // };

                // getTreeOption(dataT).then((res) => {
                //   // console.log(res);
                //   let tempArr1 = res.data.data;
                //   let tempArr2 = res.data.data.splice(0, 3);
                //   this.attr1 = tempArr2;
                //   this.attr2 = tempArr1;
                //   // console.log(this.attr1, this.attr2);
                //   this.radio = tempArr2[0].attrId;
                //   this.currentAttrName = tempArr2[0].attrName;
                // });
              }
            });
          } else {
            this.firstTree = {};
            this.treeName = "";
            this.treeId = "";
            // this.data = [];
            // this.attr1 = [];
            // this.attr2 = [];
            // this.selectValue = "";
            // this.selectId = "";
            // this.selectValueInterval = ""
          }
        } else {
          this.$message.error(res3.data.msg);
        }
      });
    },
  },
  mounted() {
    this.dataTreeArray = [];
    this.data = [];
    this.dataName = [];
    this.dataLinks = [];
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getEnergy().then((res2) => {
            if (res2.data) {
              this.dataTabs = res2.data.data;
              if (res2.data.data.length === 0) {
                this.$message.error("能源种类为空");
                return;
              }
              this.currentTabs = res2.data.data[0].energyTypeId;
              this.currentTabsName = res2.data.data[0].energyTypeName;
              // this.allElecP = res.data.data;
              // debugger
              let obj = {
                energyTypeId: res2.data.data[0].energyTypeId,
                projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
              };
              getTreeInfo(obj).then((res3) => {
                // debugger
                if (res3.data) {
                  if (res3.data.data.length > 0) {
                    this.firstTree = res3.data.data[0];
                    this.treeName = this.firstTree.treeName;
                    this.treeId = this.firstTree.treeId;
                    let temp1 = JSON.parse(JSON.stringify(res3.data.data));
                    temp1.splice(0, 1);
                    // console.log("temp2", temp2);
                    this.dataTreeArray = temp1;
                    // console.log("this.dataTreeArray", this.dataTreeArray);
                    // console.log("this.dataTreeArray", this.dataTreeArray);
                    getTree(res3.data.data[0].treeId).then((res4) => {
                      if (res4.data) {
                        // console.log(1);
                        this.data = res4.data.data;
                        // this.defaultKey = this.data[0].id;
                        this.defaultExpandArray.push(this.data[0].id);
                        this.data[0].children.forEach((item1) => {
                          this.defaultExpandArray.push(item1.id);
                          // if(item1.children.length>0){
                          //   item1.children.forEach(item2=>{
                          //     this.defaultExpandArray.push(item2.id)
                          //   })
                          // }
                        });
                        this.$nextTick(() => {
                          this.$refs.eltree.setCurrentKey(this.data[0].id);
                        });
                        this.currentNodeID = this.data[0].nodeId;
                        this.currentNodeName = this.data[0].nodeName;
                        let dataT = {
                          nodeId: this.data[0].nodeId,
                          projectId: JSON.parse(
                            window.localStorage.getItem("PROJECTID")
                          ),
                          treeId: this.treeId,
                          energyTypeId: this.currentTabs,
                        };
                        getSankeyData(dataT).then((res) => {
                          console.log(res);
                          res.data.data.data.forEach((item) => {
                            this.dataName.push({ name: item.name });
                          });
                          this.dataLinks = res.data.data.links;
                          console.log(this.dataName, this.dataLinks);
                        });

                        // getTreeOption(dataT).then((res) => {
                        //   // console.log(res);
                        //   let tempArr1 = res.data.data;
                        //   let tempArr2 = res.data.data.splice(0, 3);
                        //   this.attr1 = tempArr2;
                        //   this.attr2 = tempArr1;
                        //   // console.log(this.attr1, this.attr2);
                        //   this.radio = tempArr2[0].attrId;
                        //   this.currentAttrName = tempArr2[0].attrName;
                        //   // console.log("this.radio", this.radio);
                        //   this.onSearch();
                        // });
                      }
                    });
                  } else {
                    this.treeName = "";
                    this.data = [];
                  }
                } else {
                  this.$message.error(res3.data.msg);
                }
              });
            }
          });
        }
      }
    );
  },
};
</script>

<style scoped>
.tuopu {
  width: 100%;
  height: calc(100% - 100px);
  background: url("~@/assets/电拓扑.png");
  background-size: cover;
  position: relative;
}
.isClick {
  color: #3e6cf7 !important;
  border-bottom: 4px solid #3e6cf7;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.other-tree:last-child {
  border-bottom: 1px solid #3f6df8 !important;
}
</style>