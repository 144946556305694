<template>
  <div style="width: 100%; height: 100%; padding: 10px">
    <!-- <iframe
      :src="urlIrame"
      style="height: 100%; width: 100%"
      frameborder="0"
    ></iframe> -->
    <div
      v-if="currentProjectId === 'zgds'"
      style="width: 100%; height: 100%; position: relative"
      class="tbg"
    >
      <div
        style="
          width: 100px;
          height: 32px;
          background: #3f6df8;
          border-radius: 18px 18px 0px 0px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 40px;
          left: 40px;
          color: white;
        "
      >
        当日
      </div>
      <div
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 40px;
          color: white;
          padding: 10px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            机房总用电量
          </div>
          <div style="font-size: 14px; font-weight: bold; color: #00c800">
            <template v-if="host_ep_imp_per < 0">
              <i class="el-icon-bottom"></i>{{ host_ep_imp_per_show }}%
            </template>
            <template v-if="host_ep_imp_per > 0">
              <i class="el-icon-top"></i>{{ host_ep_imp_per_show }}%
            </template>
            <template v-if="host_ep_imp_per === 0"> 0% </template>
            <!-- <i class="el-icon-bottom"></i>{{ host_ep_imp_per }}% -->
          </div>
        </div>
        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            host_ep_imp
          }}</span>
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >kWh</span
          >
        </div>
      </div>
      <div
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 240px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            机房总制冷
          </div>
          <div style="font-size: 14px; font-weight: bold; color: #00c800">
            <template v-if="coldVPer < 0">
              <i class="el-icon-bottom"></i>{{ coldVPer_show }}%
            </template>
            <template v-if="coldVPer > 0">
              <i class="el-icon-top"></i>{{ coldVPer_show }}%
            </template>
            <template v-if="coldVPer === 0"> 0% </template>
            <!-- <i class="el-icon-bottom"></i>{{ coldVPer }}% -->
          </div>
        </div>

        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            coldV
          }}</span>
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >kWh</span
          >
        </div>
      </div>
      <div
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 194px;
          left: 40px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #082254;
          "
        >
          系统COP
        </div>

        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            sysCop
          }}</span>
        </div>
      </div>
      <div
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 194px;
          left: 240px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #082254;
          "
        >
          主机COP
        </div>
        <!-- 2.7 4.3 -->
        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mainCop
          }}</span>
        </div>
      </div>

      <div
        style="
          width: 192px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 525px;
          left: 200px;
          padding: 6px 15px;
          padding-right: 0;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷量计
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          FL：252 m/s
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日冷量：{{ coldV }} kWh
        </div>
      </div>
      <div
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 510px;
          left: 490px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        T1: 7.5℃
      </div>
      <div
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 705px;
          left: 800px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        T2: 11.5℃
      </div>
      <div
        style="
          width: 128px;
          height: 56px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 275px;
          left: 590px;
          padding: 6px 15px;
        "
      >
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 2px;
          "
        >
          P：{{
            realtimeData["linkgap.zgds.Elec_DTU1_3_075.P"]
              ? realtimeData["linkgap.zgds.Elec_DTU1_3_075.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          Ia：{{
            realtimeData["linkgap.zgds.Elec_DTU1_3_075.Ia"]
              ? realtimeData["linkgap.zgds.Elec_DTU1_3_075.Ia"]
              : "--"
          }}
        </div>
      </div>
      <div
        style="
          width: 128px;
          height: 56px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 398px;
          left: 748px;
          padding: 6px 15px;
        "
      >
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 2px;
          "
        >
          P：{{
            realtimeData["linkgap.zgds.Elec_DTU1_3_076.P"]
              ? realtimeData["linkgap.zgds.Elec_DTU1_3_076.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          Ia：{{
            realtimeData["linkgap.zgds.Elec_DTU1_3_076.Ia"]
              ? realtimeData["linkgap.zgds.Elec_DTU1_3_076.Ia"]
              : "--"
          }}
        </div>
      </div>
      <div
        style="
          width: 128px;
          height: 56px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 510px;
          left: 905px;
          padding: 6px 15px;
        "
      >
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 2px;
          "
        >
          P：{{
            realtimeData["linkgap.zgds.Elec_DTU1_4_100.P"]
              ? realtimeData["linkgap.zgds.Elec_DTU1_4_100.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          Ia：{{
            realtimeData["linkgap.zgds.Elec_DTU1_4_100.Ia"]
              ? realtimeData["linkgap.zgds.Elec_DTU1_4_100.Ia"]
              : "--"
          }}
        </div>
      </div>
      <div
        style="
          width: 192px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 575px;
          left: 1255px;
          padding: 6px 15px;
          padding-right: 0;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷冻/冷却泵
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：{{
            realtimeData[
              "linkgap.zgds.Elec_DTU1_4_107.P,linkgap.zgds.Elec_DTU1_4_106.P#?+?"
            ]
              ? realtimeData[
                  "linkgap.zgds.Elec_DTU1_4_107.P,linkgap.zgds.Elec_DTU1_4_106.P#?+?"
                ]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：{{
            relativeData[
              "linkgap.zgds.Elec_DTU1_4_107.Ep_imp,linkgap.zgds.Elec_DTU1_4_106.Ep_imp#?+?"
            ]
              ? relativeData[
                  "linkgap.zgds.Elec_DTU1_4_107.Ep_imp,linkgap.zgds.Elec_DTU1_4_106.Ep_imp#?+?"
                ].toFixed(2)
              : "--"
          }}
          kWh
        </div>
      </div>
      <div
        style="
          width: 192px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 115px;
          left: 1120px;
          padding: 6px 15px;
          padding-right: 0;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷却塔
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：{{
            realtimeData["linkgap.zgds.Elec_DTU1_1_019.P"]
              ? realtimeData["linkgap.zgds.Elec_DTU1_1_019.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：{{
            relativeData["linkgap.zgds.Elec_DTU1_1_019.Ep_imp"]
              ? relativeData["linkgap.zgds.Elec_DTU1_1_019.Ep_imp"].toFixed(2)
              : "--"
          }}
          kWh
        </div>
      </div>
    </div>

    <div
      v-else-if="currentProjectId === 'tcsd'"
      style="width: 100%; height: 100%; position: relative"
      class="tbg2"
    >
      <div
        style="
          width: 100px;
          height: 32px;
          background: #3f6df8;
          border-radius: 18px 18px 0px 0px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 40px;
          left: 40px;
          color: white;
        "
      >
        当日
      </div>
      <div
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 40px;
          color: white;
          padding: 10px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            机房总用电量
          </div>
          <div style="font-size: 14px; font-weight: bold; color: #00c800">
            <i class="el-icon-bottom"></i>20%
          </div>
        </div>
        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00"
            >5627.43</span
          >
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >kWh</span
          >
        </div>
      </div>
      <div
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 240px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            机房总制冷
          </div>
          <div style="font-size: 14px; font-weight: bold; color: #00c800">
            <i class="el-icon-bottom"></i>20%
          </div>
        </div>

        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00"
            >5627.43</span
          >
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >kWh</span
          >
        </div>
      </div>
      <div
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 194px;
          left: 40px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #082254;
          "
        >
          系统COP
        </div>

        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00"
            >2.7</span
          >
        </div>
      </div>
      <div
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 194px;
          left: 240px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #082254;
          "
        >
          主机COP
        </div>

        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00"
            >4.3</span
          >
        </div>
      </div>

      <div
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 592px;
          left: 359px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷量计
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          FL：252 m/s
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日冷量：436 kWh
        </div>
      </div>
      <div
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 363px;
          left: 460px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        T2: 11.2℃
      </div>
      <div
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 466px;
          left: 493px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        T1: 7.2℃
      </div>
      <div
        style="
          width: 128px;
          height: 56px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 285px;
          left: 831px;
          padding: 6px 15px;
        "
      >
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 2px;
          "
        >
          P：252 KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          Ia：436
        </div>
      </div>
      <div
        style="
          width: 128px;
          height: 56px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 374px;
          left: 903px;
          padding: 6px 15px;
        "
      >
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 2px;
          "
        >
          P：252 KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          Ia：436
        </div>
      </div>
      <div
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 599px;
          left: 958px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷冻/冷却泵
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：252 m/s
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：436 kWh
        </div>
      </div>
      <div
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 158px;
          left: 1436px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷却泵
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：252 m/s
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：436 kWh
        </div>
      </div>
      <div
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 40px;
          left: 1269px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷却塔
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：252 m/s
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：436 kWh
        </div>
      </div>
    </div>

    <div
      v-else-if="currentProjectId === 'mgm'"
      style="width: 100%; height: 100%; position: relative"
      class="tbgMgm"
    >
      <div
        style="
          width: 100px;
          height: 32px;
          background: #3f6df8;
          border-radius: 18px 18px 0px 0px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 40px;
          left: 40px;
          color: white;
        "
      >
        当日
      </div>
      <div
        style="
          width: 160px;
          height: 80px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 40px;
          color: white;
          padding: 10px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            Plant COP
          </div>
          <!-- <div style="font-size: 14px; font-weight: bold; color: #00c800">
            <i class="el-icon-bottom"></i>20%
          </div> -->
        </div>
        <div style="width: 100%; text-align: center">
          <!-- <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mgmCop1
          }}</span> -->
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mgmRealData["linkgap.mgm.Plant.COP"]
              ? Number(mgmRealData["linkgap.mgm.Plant.COP"]).toFixed(2)
              : "--"
          }}</span>

          <!-- <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >kWh</span
          > -->
        </div>
      </div>
      <div
        style="
          width: 160px;
          height: 80px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 230px;
          color: white;
          padding: 10px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            Hot Water COP
          </div>
        </div>

        <div style="width: 100%; text-align: center">
          <!-- <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mgmCop2
          }}</span> -->
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mgmRealData["linkgap.mgm.HW.COP"]
              ? Number(mgmRealData["linkgap.mgm.HW.COP"]).toFixed(2)
              : "--"
          }}</span>
        </div>
      </div>

      <div
        style="
          width: 160px;
          height: 80px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 420px;
          color: white;
          padding: 10px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            Chilled Water COP
          </div>
        </div>

        <div style="width: 100%; text-align: center">
          <!-- <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mgmCop3
          }}</span> -->
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mgmRealData["linkgap.mgm.CHW.COP"]
              ? Number(mgmRealData["linkgap.mgm.CHW.COP"]).toFixed(2)
              : "--"
          }}</span>
        </div>
      </div>
      <div
        style="
          width: 250px;
          height: 80px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 170px;
          left: 40px;
          color: white;
          padding: 10px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            Chilled Water
          </div>
        </div>

        <div style="width: 100%; text-align: center">
          <!-- <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            chillWater
          }}</span> -->
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mgmRealData["linkgap.mgm.HVAC.ColdTotalFlow"]
              ? Number(mgmRealData["linkgap.mgm.HVAC.ColdTotalFlow"]).toFixed(2)
              : "--"
          }}</span>
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >RT</span
          >
        </div>
      </div>
      <div
        style="
          width: 250px;
          height: 80px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 170px;
          left: 330px;
          color: white;
          padding: 10px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            Hot Water
          </div>
        </div>

        <div style="width: 100%; text-align: center">
          <!-- <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            hotWater
          }}</span> -->
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mgmRealData["linkgap.mgm.HVAC.HotTotalFlow"]
              ? Number(mgmRealData["linkgap.mgm.HVAC.HotTotalFlow"]).toFixed(2)
              : "--"
          }}</span>
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >KW</span
          >
        </div>
      </div>

      <div
        style="
          width: 136px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 65%;
          left: 7%;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        CHW_RT:
        {{
          mgmRealData["linkgap.mgm.CR.Temp"]
            ? Number(mgmRealData["linkgap.mgm.CR.Temp"]).toFixed(2)
            : "--"
        }}℃
      </div>
      <div
        style="
          width: 136px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 88%;
          left: 33%;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        CHW_ST:
        {{
          mgmRealData["linkgap.mgm.CS.Temp"]
            ? Number(mgmRealData["linkgap.mgm.CS.Temp"]).toFixed(2)
            : "--"
        }}℃
      </div>

      <div
        style="
          width: 136px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 71%;
          left: 63%;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        HW_ST:
        {{
          mgmRealData["linkgap.mgm.HS.Temp"]
            ? Number(mgmRealData["linkgap.mgm.HS.Temp"]).toFixed(2)
            : "--"
        }}℃
      </div>
      <div
        style="
          width: 136px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 64%;
          left: 75%;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        HW_RT:
        {{
          mgmRealData["linkgap.mgm.HR.Temp"]
            ? Number(mgmRealData["linkgap.mgm.HR.Temp"]).toFixed(2)
            : "--"
        }}℃
      </div>

      <div
        style="
          width: 158px;
          height: 38px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 47%;
          left: 24%;
          padding-left: 6px;
        "
      >
        <div
          style="
            width: 100%;
            height: 38px;
            line-height: 38px;
            color: white;
            font-weight: bold;
            font-size: 14px;
          "
        >
          CH1A：{{
            mgmRealData["linkgap.mgm.CH1A.P"]
              ? Number(mgmRealData["linkgap.mgm.CH1A.P"]).toFixed(2)
              : "--"
          }}
          KW
        </div>
      </div>

      <div
        style="
          width: 158px;
          height: 38px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 54%;
          left: 32%;
          padding-left: 6px;
        "
      >
        <div
          style="
            width: 100%;
            height: 38px;
            line-height: 38px;
            color: white;
            font-weight: bold;
            font-size: 14px;
          "
        >
          CH2A：{{
            mgmRealData["linkgap.mgm.CH2A.P"]
              ? Number(mgmRealData["linkgap.mgm.CH2A.P"]).toFixed(2)
              : "--"
          }}
          KW
        </div>
      </div>

      <div
        style="
          width: 158px;
          height: 38px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 62%;
          left: 40%;
          padding-left: 6px;
        "
      >
        <div
          style="
            width: 100%;
            height: 38px;
            line-height: 38px;
            color: white;
            font-weight: bold;
            font-size: 14px;
          "
        >
          CH3A：{{
            mgmRealData["linkgap.mgm.CH3A.P"]
              ? Number(mgmRealData["linkgap.mgm.CH3A.P"]).toFixed(2)
              : "--"
          }}
          KW
        </div>
      </div>

      <div
        style="
          width: 158px;
          height: 38px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 33%;
          left: 36%;
          padding-left: 6px;
        "
      >
        <div
          style="
            width: 100%;
            height: 38px;
            line-height: 38px;
            color: white;
            font-weight: bold;
            font-size: 14px;
          "
        >
          CH1B：{{
            mgmRealData["linkgap.mgm.CH1B.P"]
              ? Number(mgmRealData["linkgap.mgm.CH1B.P"]).toFixed(2)
              : "--"
          }}
          KW
        </div>
      </div>

      <div
        style="
          width: 158px;
          height: 38px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 41%;
          left: 42%;
          padding-left: 6px;
        "
      >
        <div
          style="
            width: 100%;
            height: 38px;
            line-height: 38px;
            color: white;
            font-weight: bold;
            font-size: 14px;
          "
        >
          CH2B：{{
            mgmRealData["linkgap.mgm.CH2B.P"]
              ? Number(mgmRealData["linkgap.mgm.CH2B.P"]).toFixed(2)
              : "--"
          }}
          KW
        </div>
      </div>

      <div
        style="
          width: 158px;
          height: 38px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 49%;
          left: 49%;
          padding-left: 6px;
        "
      >
        <div
          style="
            width: 100%;
            height: 38px;
            line-height: 38px;
            color: white;
            font-weight: bold;
            font-size: 14px;
          "
        >
          CH3B：{{
            mgmRealData["linkgap.mgm.CH3B.P"]
              ? Number(mgmRealData["linkgap.mgm.CH3B.P"]).toFixed(2)
              : "--"
          }}
          KW
        </div>
      </div>

      <div
        style="
          width: 158px;
          height: 38px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 57%;
          left: 56%;
          padding-left: 6px;
        "
      >
        <div
          style="
            width: 100%;
            height: 38px;
            line-height: 38px;
            color: white;
            font-weight: bold;
            font-size: 14px;
          "
        >
          CH4：{{
            mgmRealData["linkgap.mgm.CH4.P"]
              ? Number(mgmRealData["linkgap.mgm.CH4.P"]).toFixed(2)
              : "--"
          }}
          KW
        </div>
      </div>

      <div
        style="
          width: 158px;
          height: 38px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 64%;
          left: 63%;
          padding-left: 6px;
        "
      >
        <div
          style="
            width: 100%;
            height: 38px;
            line-height: 38px;
            color: white;
            font-weight: bold;
            font-size: 14px;
          "
        >
          CH5：{{
            mgmRealData["linkgap.mgm.CH5.P"]
              ? Number(mgmRealData["linkgap.mgm.CH5.P"]).toFixed(2)
              : "--"
          }}
          KW
        </div>
      </div>
      <div
        style="
          width: 148px;
          height: 107px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 8%;
          left: 79%;
          padding: 10px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          Wet Bulb:
          {{
            mgmRealData["linkgap.mgm.WetBulb.Temp"]
              ? Number(mgmRealData["linkgap.mgm.WetBulb.Temp"]).toFixed(2)
              : "--"
          }}°C
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 15px;
          "
        >
          Dry Bulb:
          {{
            mgmRealData["linkgap.mgm.DryBulb.Temp"]
              ? Number(mgmRealData["linkgap.mgm.DryBulb.Temp"]).toFixed(2)
              : "--"
          }}°C
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 15px;
          "
        >
          CW Temp:
          {{
            mgmRealData["linkgap.mgm.CW.Temp"]
              ? Number(mgmRealData["linkgap.mgm.CW.Temp"]).toFixed(2)
              : "--"
          }}°C
        </div>
      </div>
    </div>
    <iframe
      v-else-if="currentProjectId === 'wtzx'"
      :src="urlIrame"
      style="height: 100%; width: 100%"
      frameborder="0"
    ></iframe>
    <div
      v-else
      style="width: 100%; height: 100%; position: relative"
      :class="imgDirection === 'left' ? 'tbg331' : 'tbg332'"
    >
      <div
        style="
          wdith: 120px;
          height: 120px;
          position: absolute;
          top: 35%;
          right: 10px;
          cursor: pointer;
        "
        v-if="imgDirection === 'left'"
        @click="toRight"
      >
        <div style="position: absolute; top: 80px; left: 45px">
          <div style="color: white; font-size: 18px">冷</div>
          <div style="color: white; font-size: 18px; margin-top: 10px">冻</div>
          <div style="color: white; font-size: 18px; margin-top: 10px">系</div>
          <div style="color: white; font-size: 18px; margin-top: 10px">统</div>
        </div>
        <img src="@/assets/direction.png" />
      </div>
      <div
        style="
          wdith: 120px;
          height: 120px;
          position: absolute;
          top: 35%;
          right: 10px;
          cursor: pointer;
        "
        v-if="imgDirection === 'right'"
        @click="toLeft"
      >
        <div style="position: absolute; top: 45px; left: 45px">
          <div style="color: white; font-size: 18px">空</div>
          <div style="color: white; font-size: 18px; margin-top: 10px">调</div>
          <div style="color: white; font-size: 18px; margin-top: 10px">热</div>
          <div style="color: white; font-size: 18px; margin-top: 10px">水</div>
          <div style="color: white; font-size: 18px; margin-top: 10px">系</div>
          <div style="color: white; font-size: 18px; margin-top: 10px">统</div>
        </div>
        <img src="@/assets/direction.png" />
      </div>

      <div
        style="
          width: 100px;
          height: 32px;
          background: #3f6df8;
          border-radius: 18px 18px 0px 0px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 40px;
          left: 40px;
          color: white;
        "
      >
        当日
      </div>
      <div
        v-if="imgDirection === 'left'"
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 40px;
          color: white;
          padding: 10px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            机房总用电量
          </div>
          <div style="font-size: 14px; font-weight: bold; color: #00c800">
            <template v-if="host_ep_imp_per < 0">
              <i class="el-icon-bottom"></i>{{ host_ep_imp_per_show }}%
            </template>
            <template v-if="host_ep_imp_per > 0">
              <i class="el-icon-top"></i>{{ host_ep_imp_per_show }}%
            </template>
            <template v-if="host_ep_imp_per === 0"> 0% </template>
            <!-- <i class="el-icon-bottom"></i>{{ host_ep_imp_per }}% -->
          </div>
        </div>
        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            host_ep_imp
          }}</span>
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >kWh</span
          >
        </div>
      </div>
      <div
        v-if="imgDirection === 'left'"
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 240px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            机房总制冷
          </div>
          <div style="font-size: 14px; font-weight: bold; color: #00c800">
            <template v-if="coldVPer < 0">
              <i class="el-icon-bottom"></i>{{ coldVPer_show }}%
            </template>
            <template v-if="coldVPer > 0">
              <i class="el-icon-top"></i>{{ coldVPer_show }}%
            </template>
            <template v-if="coldVPer === 0"> 0% </template>
            <!-- <i class="el-icon-bottom"></i>{{ coldVPer }}% -->
          </div>
        </div>

        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            coldV
          }}</span>
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >kWh</span
          >
        </div>
      </div>
      <div
        v-if="imgDirection === 'right'"
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 40px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            机房总用电量
          </div>
          <div style="font-size: 14px; font-weight: bold; color: #00c800">
            <template v-if="host_ep_imp_per < 0">
              <i class="el-icon-bottom"></i>{{ host_ep_imp_per_show }}%
            </template>
            <template v-if="host_ep_imp_per > 0">
              <i class="el-icon-top"></i>{{ host_ep_imp_per_show }}%
            </template>
            <template v-if="host_ep_imp_per === 0"> 0% </template>
            <!-- <i class="el-icon-bottom"></i>{{ host_ep_imp_per }}% -->
          </div>
        </div>
        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            host_ep_imp
          }}</span>
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >kWh</span
          >
        </div>
      </div>
      <div
        v-if="imgDirection === 'right'"
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 72px;
          left: 240px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="font-size: 14px; font-weight: bold; color: #082254">
            机房总制热
          </div>
          <!-- <div style="font-size: 14px; font-weight: bold; color: #00c800">
            <i class="el-icon-bottom"></i>20%
          </div> -->
        </div>

        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            relativeDataJK[
              "linkgap.jkjd.HVAC_L1.HotTotalFlow,linkgap.jkjd.HVAC_L2.HotTotalFlow,linkgap.jkjd.HVAC_L3.HotTotalFlow,linkgap.jkjd.HVAC_L4.HotTotalFlow#?+?+?+?"
            ] ||
            relativeDataJK[
              "linkgap.jkjd.HVAC_L1.HotTotalFlow,linkgap.jkjd.HVAC_L2.HotTotalFlow,linkgap.jkjd.HVAC_L3.HotTotalFlow,linkgap.jkjd.HVAC_L4.HotTotalFlow#?+?+?+?"
            ] === 0
              ? relativeDataJK[
                  "linkgap.jkjd.HVAC_L1.HotTotalFlow,linkgap.jkjd.HVAC_L2.HotTotalFlow,linkgap.jkjd.HVAC_L3.HotTotalFlow,linkgap.jkjd.HVAC_L4.HotTotalFlow#?+?+?+?"
                ].toFixed(2)
              : "--"
          }}</span>
          <span
            style="
              font-size: 14px;
              font-weight: bold;
              color: #ff7b00;
              margin-left: 5px;
            "
            >kWh</span
          >
        </div>
      </div>
      <div
        v-if="imgDirection === 'left'"
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 194px;
          left: 40px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #082254;
          "
        >
          系统COP
        </div>

        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            sysCop
          }}</span>
        </div>
      </div>
      <div
        v-if="imgDirection === 'left'"
        style="
          width: 178px;
          height: 100px;
          background: rgba(184, 200, 250, 0.95);
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: 194px;
          left: 240px;
          color: white;
          padding: 15px;
        "
      >
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #082254;
          "
        >
          主机COP
        </div>

        <div style="margin-top: 5px; width: 100%; text-align: center">
          <span style="font-size: 26px; font-weight: bold; color: #ff7b00">{{
            mainCop
          }}</span>
        </div>
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 405px;
          left: 712px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_CTS.Temp"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_CTS.Temp"]
            : "--"
        }}
        ℃
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 450px;
          left: 712px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_CTS.Press"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_CTS.Press"]
            : "--"
        }}
        Kg
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 319px;
          left: 847px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_CTR.Temp"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_CTR.Temp"]
            : "--"
        }}
        ℃
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 364px;
          left: 847px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_CTR.Press"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_CTR.Press"]
            : "--"
        }}
        Kg
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 619px;
          left: 990px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_CR.Press"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_CR.Press"]
            : "--"
        }}
        Kg
      </div>
      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 664px;
          left: 990px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_C1.Flow"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_C1.Flow"]
            : "--"
        }}
        m3/s
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 733px;
          left: 1153px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_FSQ.Temp"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_FSQ.Temp"]
            : "--"
        }}
        ℃
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 586px;
          left: 1336px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_JSQ.Temp"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_JSQ.Temp"]
            : "--"
        }}
        ℃
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 430px;
          left: 1169px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_CP1HZ.HZST"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_CP1HZ.HZST"]
            : "--"
        }}
        Hz
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 379px;
          left: 1261px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_CP2HZ.HZST"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_CP2HZ.HZST"]
            : "--"
        }}
        Hz
      </div>
      <div
        v-if="imgDirection === 'left'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 331px;
          left: 1346px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_CP3HZ.HZST"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_CP3HZ.HZST"]
            : "--"
        }}
        Hz
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 552px;
          left: 521px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          1#冷冻机
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：{{
            realtimeDataJK["linkgap.jkjd.Elec_DTU1_3_040.P"]
              ? realtimeDataJK["linkgap.jkjd.Elec_DTU1_3_040.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：{{
            relativeDataJK["linkgap.jkjd.Elec_DTU1_3_040.Ep_imp"]
              ? relativeDataJK["linkgap.jkjd.Elec_DTU1_3_040.Ep_imp"].toFixed(2)
              : "--"
          }}
          kWh
        </div>
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 642px;
          left: 675px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          2#冷冻机
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：{{
            realtimeDataJK["linkgap.jkjd.Elec_DTU1_8_039.P"]
              ? realtimeDataJK["linkgap.jkjd.Elec_DTU1_8_039.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：{{
            relativeDataJK["linkgap.jkjd.Elec_DTU1_8_039.Ep_imp"]
              ? relativeDataJK["linkgap.jkjd.Elec_DTU1_8_039.Ep_imp"].toFixed(2)
              : "--"
          }}
          kWh
        </div>
      </div>
      <div
        v-if="imgDirection === 'left'"
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 157px;
          left: 567px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷却塔
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：{{
            realtimeDataJK["linkgap.jkjd.Elec_DTU1_1_004.P"]
              ? realtimeDataJK["linkgap.jkjd.Elec_DTU1_1_004.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：{{
            relativeDataJK["linkgap.jkjd.Elec_DTU1_1_004.Ep_imp"]
              ? relativeDataJK["linkgap.jkjd.Elec_DTU1_1_004.Ep_imp"].toFixed(2)
              : "--"
          }}
          kWh
        </div>
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 124px;
          left: 1278px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷却泵
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：{{
            realtimeDataJK["linkgap.jkjd.Elec_DTU1_1_011.P"]
              ? realtimeDataJK["linkgap.jkjd.Elec_DTU1_1_011.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：{{
            relativeDataJK["linkgap.jkjd.Elec_DTU1_1_011.Ep_imp"]
              ? relativeDataJK["linkgap.jkjd.Elec_DTU1_1_011.Ep_imp"].toFixed(2)
              : "--"
          }}
          kWh
        </div>
      </div>

      <div
        v-if="imgDirection === 'left'"
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 237px;
          left: 1418px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          冷冻泵
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：{{
            realtimeDataJK["linkgap.jkjd.Elec_DTU1_7_011.P"]
              ? realtimeDataJK["linkgap.jkjd.Elec_DTU1_7_011.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：{{
            relativeDataJK["linkgap.jkjd.Elec_DTU1_7_011.Ep_imp"]
              ? relativeDataJK["linkgap.jkjd.Elec_DTU1_7_011.Ep_imp"].toFixed(2)
              : "--"
          }}
          kWh
        </div>
      </div>

      <div
        v-if="imgDirection === 'right'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 736px;
          left: 942px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_JSQ.Temp"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_JSQ.Temp"]
            : "--"
        }}
        ℃
      </div>

      <div
        v-if="imgDirection === 'right'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 657px;
          left: 1107px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_FSQ.Temp"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_FSQ.Temp"]
            : "--"
        }}
        ℃
      </div>

      <div
        v-if="imgDirection === 'right'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 543px;
          left: 1051px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_HR.Press"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_HR.Press"]
            : "--"
        }}
        Kg
      </div>
      <div
        v-if="imgDirection === 'right'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 578px;
          left: 1265px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_HS.Press"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_HS.Press"]
            : "--"
        }}
        Kg
      </div>
      <div
        v-if="imgDirection === 'right'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 526px;
          left: 1330px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_H1.Flow"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_H1.Flow"]
            : "--"
        }}
        m3/s
      </div>

      <div
        v-if="imgDirection === 'right'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 116px;
          left: 920px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_HP1HZ.HZST"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_HP1HZ.HZST"]
            : "--"
        }}
        Hz
      </div>

      <div
        v-if="imgDirection === 'right'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 230px;
          left: 1049px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_HP2HZ.HZST"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_HP2HZ.HZST"]
            : "--"
        }}
        Hz
      </div>
      <div
        v-if="imgDirection === 'right'"
        style="
          width: 96px;
          height: 34px;
          background: #072356;
          border-radius: 2px;
          position: absolute;
          top: 315px;
          left: 1184px;
          line-height: 34px;
          text-align: center;
          color: white;
          font-size: 14px;
        "
      >
        {{
          realtimeDataJK["linkgap.jkjd.HVAC_HP3HZ.HZST"]
            ? realtimeDataJK["linkgap.jkjd.HVAC_HP3HZ.HZST"]
            : "--"
        }}
        Hz
      </div>
      <!-- <div
        v-if="imgDirection === 'right'"
        style="
          width: 128px;
          height: 56px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 456px;
          left: 586px;
          padding: 6px 15px;
        "
      >
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 2px;
          "
        >
          P：252 KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          Ia：436
        </div>
      </div>
      <div
        v-if="imgDirection === 'right'"
        style="
          width: 128px;
          height: 56px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 642px;
          left: 819px;
          padding: 6px 15px;
        "
      >
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 2px;
          "
        >
          P：252 KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          Ia：436
        </div>
      </div> -->
      <div
        v-if="imgDirection === 'right'"
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 491px;
          left: 571px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          热水锅炉
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：{{
            realtimeDataJK["linkgap.jkjd.Elec_DTU1_7_014.P"]
              ? realtimeDataJK["linkgap.jkjd.Elec_DTU1_7_014.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：{{
            relativeDataJK["linkgap.jkjd.Elec_DTU1_7_014.Ep_imp"]
              ? relativeDataJK["linkgap.jkjd.Elec_DTU1_7_014.Ep_imp"].toFixed(2)
              : "--"
          }}
          kWh
        </div>
      </div>
      <div
        v-if="imgDirection === 'right'"
        style="
          width: 185px;
          height: 78px;
          background: #3f6df8;
          border-radius: 2px;
          position: absolute;
          top: 147px;
          left: 1192px;
          padding: 6px 15px;
        "
      >
        <div
          style="width: 100%; color: white; font-weight: bold; font-size: 14px"
        >
          采暖循环泵
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          P：{{
            realtimeDataJK["linkgap.jkjd.Elec_DTU1_7_012.P"]
              ? realtimeDataJK["linkgap.jkjd.Elec_DTU1_7_012.P"]
              : "--"
          }}
          KW
        </div>
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin-top: 3px;
          "
        >
          日用电：{{
            relativeDataJK["linkgap.jkjd.Elec_DTU1_7_012.Ep_imp"]
              ? relativeDataJK["linkgap.jkjd.Elec_DTU1_7_012.Ep_imp"].toFixed(2)
              : "--"
          }}
          kWh
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  setProject,
  getDiffData,
  getMonitorRealtime,
  // getColdDashboard,
  getActualCop,
  getActualEC,
} from "@/request/apiLg";
export default {
  name: "",
  data() {
    return {
      mgmWB: "--",
      mgmDB: "--",
      mgmCT: "--",
      mgmCop1: "--",
      mgmCop2: "--",
      mgmCop3: "--",
      chillWater: "--",
      hotWater: "--",
      mgmRealData: {},

      coldV: "",
      host_ep_imp: "",
      sysCop: "--",
      mainCop: "--",
      allElec: "",
      allCold: "",
      host_ep_imp_per: 0,
      coldVPer: 0,
      coldVPer_show: 0,
      host_ep_imp_per_show: 0,
      // sysCop: "",
      // mainCop: "",
      FL: "",
      // t11p:'',
      // t11ia:'',
      // t11p:'',
      // t11ia:'',
      // t11p:'',
      // t11ia:'',
      relativeData: {},
      realtimeData: {},
      realtimeDataJK: {},
      relativeDataJK: {},
      imgDirection: "left",
      urlIrame:
        "http://johpcyvc.cloudpreview.cloud.techsel.net/#/outofLogin?loginInfo=dXNlcm5hbWU9bGluZ2dhbiZwYXNzd29yZD1saW5nZ2FuMTIz",
      //   url:
      //     "http://johpcyvc.cloudpreview.cloud.techsel.net/#/outofLogin?loginInfo=dXNlcm5hbWU9YWRtaW4mcGFzc3dvcmQ9MTIzNDU2",
    };
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
      currentProjectName: (state) => state.currentProjectName,
      projectArray: (state) => state.projectArray,
    }),
  },
  methods: {
    toRight() {
      this.imgDirection = "right";
    },
    toLeft() {
      this.imgDirection = "left";
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
  },
  mounted() {
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          console.log(res2.data);
          // let dateT = new Date();
          // var YY = dateT.getFullYear() + "-";
          // var MM =
          //   (dateT.getMonth() + 1 < 10
          //     ? "0" + (dateT.getMonth() + 1)
          //     : dateT.getMonth() + 1) + "-";
          // var DD =
          //   dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();

          // let tempDate = YY + MM + DD + " 00:00:00";
          // dateT = new Date(tempDate);
          // dateT = dateT.setDate(dateT.getDate() + 1);
          // dateT = this.formatDate(dateT);
          // this.params1 = {
          //   queryTypeEnum: "DURATION",
          //   timeUnit: "DAYS",
          //   interval: 1,
          //   startTime: tempDate,
          //   endTime: dateT,
          // };
          // this.params1.copType = "system_cop";
          if (JSON.parse(window.localStorage.getItem("PROJECTID")) === "zgds") {
            getActualCop("system_cop").then((res) => {
              console.log(res);
              if (res.data.data.formatValue === null) {
                this.sysCop = "--";
                // this.dashboardValueC = 0;
              } else {
                this.sysCop = res.data.data.formatValue;
                // this.dashboardValueC = res.data.data.formatValue;
              }
            });
            // this.params1.copType = "host_cop";
            getActualCop("host_cop").then((res) => {
              console.log(res);
              if (res.data.data.formatValue === null) {
                this.mainCop = "--";
                // this.dashboardValueC = 0;
              } else {
                this.mainCop = res.data.data.formatValue;
                // this.dashboardValueC = res.data.data.formatValue;
              }
            });
            getActualEC("cold").then((res) => {
              console.log(res);
              if (res.data.data.thisVal === null) {
                this.coldV = "--";
                this.coldVPer = 0;
                // this.dashboardValueC = 0;
              } else {
                this.coldV = res.data.data.thisVal.toFixed(2);
                this.coldVPer = res.data.data.comparePercent;
                this.coldVPer_show = Math.abs(
                  res.data.data.comparePercent
                ).toFixed(2);
                // this.dashboardValueC = res.data.data.formatValue;
              }
            });
            getActualEC("system_ep_imp").then((res) => {
              console.log(res);
              if (res.data.data.thisVal === null) {
                this.host_ep_imp = "--";
                this.host_ep_imp_per = 0;
              } else {
                this.host_ep_imp = res.data.data.thisVal.toFixed(2);
                this.host_ep_imp_per = res.data.data.comparePercent;
                this.host_ep_imp_per_show = Math.abs(
                  res.data.data.comparePercent
                ).toFixed(2);
                // this.dashboardValueC = res.data.data.formatValue;
              }
            });

            getDiffData([
              "linkgap.zgds.Elec_DTU1_1_019.Ep_imp",
              "linkgap.zgds.Elec_DTU1_4_107.Ep_imp,linkgap.zgds.Elec_DTU1_4_106.Ep_imp#?+?",
            ]).then((res) => {
              this.relativeData = res.data.data;
            });
            getMonitorRealtime([
              // "linkgap.zgds.Elec_DTU1_1_019.Ep_imp",
              // "linkgap.zgds.Elec_DTU1_4_107.Ep_imp,linkgap.zgds.Elec_DTU1_4_106.Ep_imp#?+?",
              "linkgap.zgds.Elec_DTU1_1_019.P",
              "linkgap.zgds.Elec_DTU1_4_107.P,linkgap.zgds.Elec_DTU1_4_106.P#?+?",
              "linkgap.zgds.Elec_DTU1_3_075.P",
              "linkgap.zgds.Elec_DTU1_3_075.Ia",
              "linkgap.zgds.Elec_DTU1_3_076.P",
              "linkgap.zgds.Elec_DTU1_3_076.Ia",
              "linkgap.zgds.Elec_DTU1_4_100.P",
              "linkgap.zgds.Elec_DTU1_4_100.Ia",
            ]).then((res) => {
              this.realtimeData = res.data.data;
            });
          }
          if (JSON.parse(window.localStorage.getItem("PROJECTID")) === "jkjd") {
            getActualCop("system_cop").then((res) => {
              console.log(res);
              if (res.data.data.formatValue === null) {
                this.sysCop = "--";
                // this.dashboardValueC = 0;
              } else {
                this.sysCop = res.data.data.formatValue;
                // this.dashboardValueC = res.data.data.formatValue;
              }
            });
            // this.params1.copType = "host_cop";
            getActualCop("host_cop").then((res) => {
              console.log(res);
              if (res.data.data.formatValue === null) {
                this.mainCop = "--";
                // this.dashboardValueC = 0;
              } else {
                this.mainCop = res.data.data.formatValue;
                // this.dashboardValueC = res.data.data.formatValue;
              }
            });
            getActualEC("cold").then((res) => {
              console.log(res);
              if (res.data.data.thisVal === null) {
                this.coldV = "--";
                this.coldVPer = 0;
                // this.dashboardValueC = 0;
              } else {
                this.coldV = res.data.data.thisVal.toFixed(2);
                this.coldVPer = res.data.data.comparePercent;
                this.coldVPer_show = Math.abs(
                  res.data.data.comparePercent
                ).toFixed(2);
                // this.dashboardValueC = res.data.data.formatValue;
              }
            });
            getActualEC("system_ep_imp").then((res) => {
              console.log(res);
              if (res.data.data.thisVal === null) {
                this.host_ep_imp = "--";
                this.host_ep_imp_per = 0;
              } else {
                this.host_ep_imp = res.data.data.thisVal.toFixed(2);
                this.host_ep_imp_per = res.data.data.comparePercent;
                this.host_ep_imp_per_show = Math.abs(
                  res.data.data.comparePercent
                ).toFixed(2);
                // this.dashboardValueC = res.data.data.formatValue;
              }
            });

            getDiffData([
              "linkgap.jkjd.Elec_DTU1_7_014.Ep_imp",
              "linkgap.jkjd.Elec_DTU1_7_012.Ep_imp",
              "linkgap.jkjd.HVAC_L1.HotTotalFlow,linkgap.jkjd.HVAC_L2.HotTotalFlow,linkgap.jkjd.HVAC_L3.HotTotalFlow,linkgap.jkjd.HVAC_L4.HotTotalFlow#?+?+?+?",
              "linkgap.jkjd.Elec_DTU1_3_040.Ep_imp",
              "linkgap.jkjd.Elec_DTU1_8_039.Ep_imp",
              "linkgap.jkjd.Elec_DTU1_1_004.Ep_imp",
              "linkgap.jkjd.Elec_DTU1_1_011.Ep_imp",
              "linkgap.jkjd.Elec_DTU1_7_011.Ep_imp",
            ]).then((res) => {
              this.relativeDataJK = res.data.data;
            });
            getMonitorRealtime([
              // "linkgap.zgds.Elec_DTU1_1_019.Ep_imp",
              // "linkgap.zgds.Elec_DTU1_4_107.Ep_imp,linkgap.zgds.Elec_DTU1_4_106.Ep_imp#?+?",
              "linkgap.jkjd.HVAC_JSQ.Temp",
              "linkgap.jkjd.HVAC_FSQ.Temp",
              "linkgap.jkjd.HVAC_HR.Press",
              "linkgap.jkjd.HVAC_HS.Press",
              "linkgap.jkjd.HVAC_H1.Flow",
              "linkgap.jkjd.HVAC_HP1HZ.HZST",
              "linkgap.jkjd.HVAC_HP2HZ.HZST",
              "linkgap.jkjd.HVAC_HP3HZ.HZST",
              "linkgap.jkjd.Elec_DTU1_7_014.P",
              "linkgap.jkjd.Elec_DTU1_7_012.P",
              "linkgap.jkjd.HVAC_CTS.Temp",
              "linkgap.jkjd.HVAC_CTS.Press",
              "linkgap.jkjd.HVAC_CTR.Temp",
              "linkgap.jkjd.HVAC_CTR.Press",
              "linkgap.jkjd.HVAC_CR.Press",
              "linkgap.jkjd.HVAC_C1.Flow",
              "linkgap.jkjd.HVAC_JSQ.Temp",
              "linkgap.jkjd.HVAC_FSQ.Temp",
              "linkgap.jkjd.HVAC_CP1HZ.HZST",
              "linkgap.jkjd.HVAC_CP2HZ.HZST",
              "linkgap.jkjd.HVAC_CP3HZ.HZST",
              "linkgap.jkjd.Elec_DTU1_3_040.P",
              "linkgap.jkjd.Elec_DTU1_8_039.P",
              "linkgap.jkjd.Elec_DTU1_1_004.P",
              "linkgap.jkjd.Elec_DTU1_1_011.P",
              "linkgap.jkjd.Elec_DTU1_7_011.P",
            ]).then((res) => {
              this.realtimeDataJK = res.data.data;
            });
          }
          if (JSON.parse(window.localStorage.getItem("PROJECTID")) === "mgm") {
            // getActualCop("system_cop").then((res) => {
            //   console.log(res);
            //   if (res.data.data.formatValue === null) {
            //     this.sysCop = "--";
            //     // this.dashboardValueC = 0;
            //   } else {
            //     this.sysCop = res.data.data.formatValue;
            //     // this.dashboardValueC = res.data.data.formatValue;
            //   }
            // });
            // // this.params1.copType = "host_cop";
            // getActualCop("host_cop").then((res) => {
            //   console.log(res);
            //   if (res.data.data.formatValue === null) {
            //     this.mainCop = "--";
            //     // this.dashboardValueC = 0;
            //   } else {
            //     this.mainCop = res.data.data.formatValue;
            //     // this.dashboardValueC = res.data.data.formatValue;
            //   }
            // });
            // getActualEC("cold").then((res) => {
            //   console.log(res);
            //   if (res.data.data.thisVal === null) {
            //     this.coldV = "--";
            //     this.coldVPer = 0;
            //     // this.dashboardValueC = 0;
            //   } else {
            //     this.coldV = res.data.data.thisVal.toFixed(2);
            //     this.coldVPer = res.data.data.comparePercent;
            //     this.coldVPer_show = Math.abs(
            //       res.data.data.comparePercent
            //     ).toFixed(2);
            //     // this.dashboardValueC = res.data.data.formatValue;
            //   }
            // });
            // getActualEC("system_ep_imp").then((res) => {
            //   console.log(res);
            //   if (res.data.data.thisVal === null) {
            //     this.host_ep_imp = "--";
            //     this.host_ep_imp_per = 0;
            //   } else {
            //     this.host_ep_imp = res.data.data.thisVal.toFixed(2);
            //     this.host_ep_imp_per = res.data.data.comparePercent;
            //     this.host_ep_imp_per_show = Math.abs(
            //       res.data.data.comparePercent
            //     ).toFixed(2);
            //     // this.dashboardValueC = res.data.data.formatValue;
            //   }
            // });

            // getDiffData([
            //   "linkgap.jkjd.Elec_DTU1_7_014.Ep_imp",
            //   "linkgap.jkjd.Elec_DTU1_7_012.Ep_imp",
            //   "linkgap.jkjd.HVAC_L1.HotTotalFlow,linkgap.jkjd.HVAC_L2.HotTotalFlow,linkgap.jkjd.HVAC_L3.HotTotalFlow,linkgap.jkjd.HVAC_L4.HotTotalFlow#?+?+?+?",
            //   "linkgap.jkjd.Elec_DTU1_3_040.Ep_imp",
            //   "linkgap.jkjd.Elec_DTU1_8_039.Ep_imp",
            //   "linkgap.jkjd.Elec_DTU1_1_004.Ep_imp",
            //   "linkgap.jkjd.Elec_DTU1_1_011.Ep_imp",
            //   "linkgap.jkjd.Elec_DTU1_7_011.Ep_imp",
            // ]).then((res) => {
            //   this.relativeDataJK = res.data.data;
            // });
            getMonitorRealtime([
              "linkgap.mgm.Plant.COP",
              "linkgap.mgm.HW.COP",
              "linkgap.mgm.CHW.COP",
              "linkgap.mgm.HVAC.ColdTotalFlow",
              "linkgap.mgm.HVAC.HotTotalFlow",
              "linkgap.mgm.CH1A.P",
              "linkgap.mgm.CH2A.P",
              "linkgap.mgm.CH3A.P",
              "linkgap.mgm.CH1B.P",
              "linkgap.mgm.CH2B.P",
              "linkgap.mgm.CH3B.P",
              "linkgap.mgm.CH4.P",
              "linkgap.mgm.CH5.P",
              "linkgap.mgm.DryBulb.Temp",
              "linkgap.mgm.WetBulb.Temp",
              "linkgap.mgm.CW.Temp",
              "linkgap.mgm.CR.Temp",
              "linkgap.mgm.CS.Temp",
              "linkgap.mgm.HS.Temp",
              "linkgap.mgm.HR.Temp",
            ]).then((res) => {
              this.mgmRealData = res.data.data;
            });
          }
        }
      }
    );
  },
  watch: {
    currentProjectId(val) {
      // debugger
      console.log(val);
      this.sysCop = "--";
      this.mainCop = "--";
      this.coldV = "--";
      this.coldVPer = 0;
      this.host_ep_imp = "--";
      this.host_ep_imp_per = 0;
      this.relativeData = {};
      this.realtimeData = {};
      setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
        (res2) => {
          if (res2.data.code === 0) {
            console.log(res2.data);
            // let dateT = new Date();
            // var YY = dateT.getFullYear() + "-";
            // var MM =
            //   (dateT.getMonth() + 1 < 10
            //     ? "0" + (dateT.getMonth() + 1)
            //     : dateT.getMonth() + 1) + "-";
            // var DD =
            //   dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();

            // let tempDate = YY + MM + DD + " 00:00:00";
            // dateT = new Date(tempDate);
            // dateT = dateT.setDate(dateT.getDate() + 1);
            // dateT = this.formatDate(dateT);
            // this.params1 = {
            //   queryTypeEnum: "DURATION",
            //   timeUnit: "DAYS",
            //   interval: 1,
            //   startTime: tempDate,
            //   endTime: dateT,
            // };
            // this.params1.copType = "system_cop";
            if (
              JSON.parse(window.localStorage.getItem("PROJECTID")) === "zgds"
            ) {
              getActualCop("system_cop").then((res) => {
                console.log(res);
                if (res.data.data.formatValue === null) {
                  this.sysCop = "--";
                  // this.dashboardValueC = 0;
                } else {
                  this.sysCop = res.data.data.formatValue;
                  // this.dashboardValueC = res.data.data.formatValue;
                }
              });
              // this.params1.copType = "host_cop";
              getActualCop("host_cop").then((res) => {
                console.log(res);
                if (res.data.data.formatValue === null) {
                  this.mainCop = "--";
                  // this.dashboardValueC = 0;
                } else {
                  this.mainCop = res.data.data.formatValue;
                  // this.dashboardValueC = res.data.data.formatValue;
                }
              });
              getActualEC("cold").then((res) => {
                console.log(res);
                if (res.data.data.thisVal === null) {
                  this.coldV = "--";
                  this.coldVPer = 0;
                  // this.dashboardValueC = 0;
                } else {
                  this.coldV = res.data.data.thisVal.toFixed(2);
                  this.coldVPer = res.data.data.comparePercent;
                  this.coldVPer_show = Math.abs(
                    res.data.data.comparePercent
                  ).toFixed(2);
                  // this.dashboardValueC = res.data.data.formatValue;
                }
              });
              getActualEC("system_ep_imp").then((res) => {
                console.log(res);
                if (res.data.data.thisVal === null) {
                  this.host_ep_imp = "--";
                  this.host_ep_imp_per = 0;
                } else {
                  this.host_ep_imp = res.data.data.thisVal.toFixed(2);
                  this.host_ep_imp_per = res.data.data.comparePercent;
                  this.host_ep_imp_per_show = Math.abs(
                    res.data.data.comparePercent
                  ).toFixed(2);
                  // this.dashboardValueC = res.data.data.formatValue;
                }
              });

              getDiffData([
                "linkgap.zgds.Elec_DTU1_1_019.Ep_imp",
                "linkgap.zgds.Elec_DTU1_4_107.Ep_imp,linkgap.zgds.Elec_DTU1_4_106.Ep_imp#?+?",
              ]).then((res) => {
                this.relativeData = res.data.data;
              });
              getMonitorRealtime([
                // "linkgap.zgds.Elec_DTU1_1_019.Ep_imp",
                // "linkgap.zgds.Elec_DTU1_4_107.Ep_imp,linkgap.zgds.Elec_DTU1_4_106.Ep_imp#?+?",
                "linkgap.zgds.Elec_DTU1_1_019.P",
                "linkgap.zgds.Elec_DTU1_4_107.P,linkgap.zgds.Elec_DTU1_4_106.P#?+?",
                "linkgap.zgds.Elec_DTU1_3_075.P",
                "linkgap.zgds.Elec_DTU1_3_075.Ia",
                "linkgap.zgds.Elec_DTU1_3_076.P",
                "linkgap.zgds.Elec_DTU1_3_076.Ia",
                "linkgap.zgds.Elec_DTU1_4_100.P",
                "linkgap.zgds.Elec_DTU1_4_100.Ia",
              ]).then((res) => {
                this.realtimeData = res.data.data;
              });
            }
            if (
              JSON.parse(window.localStorage.getItem("PROJECTID")) === "jkjd"
            ) {
              getActualCop("system_cop").then((res) => {
                console.log(res);
                if (res.data.data.formatValue === null) {
                  this.sysCop = "--";
                  // this.dashboardValueC = 0;
                } else {
                  this.sysCop = res.data.data.formatValue;
                  // this.dashboardValueC = res.data.data.formatValue;
                }
              });
              // this.params1.copType = "host_cop";
              getActualCop("host_cop").then((res) => {
                console.log(res);
                if (res.data.data.formatValue === null) {
                  this.mainCop = "--";
                  // this.dashboardValueC = 0;
                } else {
                  this.mainCop = res.data.data.formatValue;
                  // this.dashboardValueC = res.data.data.formatValue;
                }
              });
              getActualEC("cold").then((res) => {
                console.log(res);
                if (res.data.data.thisVal === null) {
                  this.coldV = "--";
                  this.coldVPer = 0;
                  // this.dashboardValueC = 0;
                } else {
                  this.coldV = res.data.data.thisVal.toFixed(2);
                  this.coldVPer = res.data.data.comparePercent;
                  this.coldVPer_show = Math.abs(
                    res.data.data.comparePercent
                  ).toFixed(2);
                  // this.dashboardValueC = res.data.data.formatValue;
                }
              });
              getActualEC("system_ep_imp").then((res) => {
                console.log(res);
                if (res.data.data.thisVal === null) {
                  this.host_ep_imp = "--";
                  this.host_ep_imp_per = 0;
                } else {
                  this.host_ep_imp = res.data.data.thisVal.toFixed(2);
                  this.host_ep_imp_per = res.data.data.comparePercent;
                  this.host_ep_imp_per_show = Math.abs(
                    res.data.data.comparePercent
                  ).toFixed(2);
                  // this.dashboardValueC = res.data.data.formatValue;
                }
              });

              getDiffData([
                "linkgap.jkjd.Elec_DTU1_7_014.Ep_imp",
                "linkgap.jkjd.Elec_DTU1_7_012.Ep_imp",
                "linkgap.jkjd.HVAC_L1.HotTotalFlow,linkgap.jkjd.HVAC_L2.HotTotalFlow,linkgap.jkjd.HVAC_L3.HotTotalFlow,linkgap.jkjd.HVAC_L4.HotTotalFlow#?+?+?+?",
                "linkgap.jkjd.Elec_DTU1_3_040.Ep_imp",
                "linkgap.jkjd.Elec_DTU1_8_039.Ep_imp",
                "linkgap.jkjd.Elec_DTU1_1_004.Ep_imp",
                "linkgap.jkjd.Elec_DTU1_1_011.Ep_imp",
                "linkgap.jkjd.Elec_DTU1_7_011.Ep_imp",
              ]).then((res) => {
                this.relativeDataJK = res.data.data;
              });
              getMonitorRealtime([
                // "linkgap.zgds.Elec_DTU1_1_019.Ep_imp",
                // "linkgap.zgds.Elec_DTU1_4_107.Ep_imp,linkgap.zgds.Elec_DTU1_4_106.Ep_imp#?+?",
                "linkgap.jkjd.HVAC_JSQ.Temp",
                "linkgap.jkjd.HVAC_FSQ.Temp",
                "linkgap.jkjd.HVAC_HR.Press",
                "linkgap.jkjd.HVAC_HS.Press",
                "linkgap.jkjd.HVAC_H1.Flow",
                "linkgap.jkjd.HVAC_HP1HZ.HZST",
                "linkgap.jkjd.HVAC_HP2HZ.HZST",
                "linkgap.jkjd.HVAC_HP3HZ.HZST",
                "linkgap.jkjd.Elec_DTU1_7_014.P",
                "linkgap.jkjd.Elec_DTU1_7_012.P",
                "linkgap.jkjd.HVAC_CTS.Temp",
                "linkgap.jkjd.HVAC_CTS.Press",
                "linkgap.jkjd.HVAC_CTR.Temp",
                "linkgap.jkjd.HVAC_CTR.Press",
                "linkgap.jkjd.HVAC_CR.Press",
                "linkgap.jkjd.HVAC_C1.Flow",
                "linkgap.jkjd.HVAC_JSQ.Temp",
                "linkgap.jkjd.HVAC_FSQ.Temp",
                "linkgap.jkjd.HVAC_CP1HZ.HZST",
                "linkgap.jkjd.HVAC_CP2HZ.HZST",
                "linkgap.jkjd.HVAC_CP3HZ.HZST",
                "linkgap.jkjd.Elec_DTU1_3_040.P",
                "linkgap.jkjd.Elec_DTU1_8_039.P",
                "linkgap.jkjd.Elec_DTU1_1_004.P",
                "linkgap.jkjd.Elec_DTU1_1_011.P",
                "linkgap.jkjd.Elec_DTU1_7_011.P",
              ]).then((res) => {
                this.realtimeDataJK = res.data.data;
              });
            }
            if (
              JSON.parse(window.localStorage.getItem("PROJECTID")) === "mgm"
            ) {
              getMonitorRealtime([
                "linkgap.mgm.Plant.COP",
                "linkgap.mgm.HW.COP",
                "linkgap.mgm.CHW.COP",
                "linkgap.mgm.HVAC.ColdTotalFlow",
                "linkgap.mgm.HVAC.HotTotalFlow",
                "linkgap.mgm.CH1A.P",
                "linkgap.mgm.CH2A.P",
                "linkgap.mgm.CH3A.P",
                "linkgap.mgm.CH1B.P",
                "linkgap.mgm.CH2B.P",
                "linkgap.mgm.CH3B.P",
                "linkgap.mgm.CH4.P",
                "linkgap.mgm.CH5.P",
                "linkgap.mgm.DryBulb.Temp",
                "linkgap.mgm.WetBulb.Temp",
                "linkgap.mgm.CW.Temp",
                "linkgap.mgm.CR.Temp",
                "linkgap.mgm.CS.Temp",
                "linkgap.mgm.HS.Temp",
                "linkgap.mgm.HR.Temp",
              ]).then((res) => {
                this.mgmRealData = res.data.data;
              });
            }
          }
        }
      );
    },
  },
};
</script>

<style>
.tbg {
  background: url("~@/assets/zig.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg2 {
  background: url("~@/assets/tianc.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg331 {
  background: url("~@/assets/金科1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbg332 {
  background: url("~@/assets/金科2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.tbgMgm {
  background: url("~@/assets/tbgmgm.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
</style>