<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 190px;
      "
    >
      <div class="title1">
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
          "
        >
          数据异常
        </div>
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 40px;
            font-weight: 600;
            color: #ffffff;
            margin-top: 10px;
          "
        >
          <img src="@/assets/数据.png" />
          {{ overrunData.alarmTotal }}
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
          "
        >
          <div style="">
            <span
              style="
                font-size: 20px;
                font-weight: 600;
                color: #ffffff;
                vertical-align: 3px;
                margin-right: 10px;
              "
              >新增告警</span
            >
            <span style="font-size: 30px; font-weight: 600; color: #ffffff">{{
              overrunData.newAlarmNum
            }}</span>
          </div>
          <div>
            <span
              style="
                font-size: 20px;
                font-weight: 600;
                color: #ffffff;
                vertical-align: 3px;
                margin-right: 10px;
              "
              >未处理告警</span
            >
            <span style="font-size: 30px; font-weight: 600; color: #ffffff">{{
              overrunData.untreatedAlarmNum
            }}</span>
          </div>
        </div>
      </div>
      <div class="title2">
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
          "
        >
          通信异常
        </div>
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 40px;
            font-weight: 600;
            color: #ffffff;
            margin-top: 10px;
          "
        >
          <img src="@/assets/通信.png" />
          {{ timeoutData.alarmTotal }}
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
          "
        >
          <div style="">
            <span
              style="
                font-size: 20px;
                font-weight: 600;
                color: #ffffff;
                vertical-align: 3px;
                margin-right: 10px;
              "
              >新增异常</span
            >
            <span style="font-size: 30px; font-weight: 600; color: #ffffff">{{
              timeoutData.newAlarmNum
            }}</span>
          </div>
          <div>
            <span
              style="
                font-size: 20px;
                font-weight: 600;
                color: #ffffff;
                vertical-align: 3px;
                margin-right: 10px;
              "
              >未处理异常</span
            >
            <span style="font-size: 30px; font-weight: 600; color: #ffffff">{{
              timeoutData.untreatedAlarmNum
            }}</span>
          </div>
        </div>
      </div>
      <div class="title3">
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
          "
        >
          故障诊断
        </div>
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 40px;
            font-weight: 600;
            color: #ffffff;
            margin-top: 10px;
          "
        >
          <img src="@/assets/故障.png" />
          {{ diagnosisData.alarmTotal }}
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
          "
        >
          <div style="">
            <span
              style="
                font-size: 20px;
                font-weight: 600;
                color: #ffffff;
                vertical-align: 3px;
                margin-right: 10px;
              "
              >新增故障</span
            >
            <span style="font-size: 30px; font-weight: 600; color: #ffffff">{{
              diagnosisData.newAlarmNum
            }}</span>
          </div>
          <div>
            <span
              style="
                font-size: 20px;
                font-weight: 600;
                color: #ffffff;
                vertical-align: 3px;
                margin-right: 10px;
              "
              >未处理故障</span
            >
            <span style="font-size: 30px; font-weight: 600; color: #ffffff">{{
              diagnosisData.untreatedAlarmNum
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 320px;
        margin-top: 20px;
      "
    >
      <div class="body1" style="padding: 18px">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                width: 4px;
                height: 20px;
                background: #3f6df8;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
              近30天告警状态占比
            </div>
          </div>
        </div>

        <div style="width: 100%; height: 300px">
          <v-chart ref="chart1" :option="chartPieOption1" />
        </div>
      </div>
      <div class="body2" style="padding: 18px">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                width: 4px;
                height: 20px;
                background: #3f6df8;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
              近30天告警类型
            </div>
          </div>
          <!-- <div style="">
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickNH(1)"
              :class="{ activeNH: isActivenTime === 1 }"
            >
              年
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickNH(2)"
              :class="{ activeNH: isActivenTime === 2 }"
            >
              月
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickNH(3)"
              :class="{ activeNH: isActivenTime === 3 }"
            >
              日
            </div>
          </div> -->
        </div>
        <div
          style="
            padding-right: 10px;
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          <div
            style="
              width: 24px;
              height: 10px;
              margin-right: 10px;
              background: linear-gradient(to right, #3f6df8, #47a5e1, #55ffbc);
              border-radius: 5px;
            "
          ></div>
          <div style="font-size: 14px; font-weight: 400; color: #082254">
            未处理
          </div>
        </div>
        <div style="width: 100%; height: 250px">
          <v-chart ref="chart2" :option="chartBarOption1" />
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 280px;
        margin-top: 20px;
      "
    >
      <div class="body1" style="padding: 18px">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                width: 4px;
                height: 20px;
                background: #3f6df8;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
              近30天新增告警
            </div>
          </div>
        </div>

        <div style="width: 100%; height: 240px">
          <v-chart ref="chart1" :option="chartBarOption2" />
        </div>
      </div>
      <div class="body2" style="padding: 18px">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                width: 4px;
                height: 20px;
                background: #3f6df8;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
              实时告警
            </div>
          </div>
          <div
            style="font-size: 14px; color: #3f6df8; cursor: pointer"
            @click="toMore"
          >
            更多 <i class="el-icon-d-arrow-right"></i>
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 56px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 14px;
            padding-right: 14px;
            border-bottom: 1px solid #d8d8d8;
            cursor: pointer;
          "
          @click="toDetailAlarm(item)"
          v-for="(item, index) in alarmArray"
          :key="index"
        >
          <div
            v-if="item.alarmLevel === 'emergency'"
            style="
              width: 64px;
              height: 32px;
              border-radius: 2px;
              border: 1px solid #e52323;
              color: #e52323;
              line-height: 32px;
              text-align: center;
            "
          >
            {{ item.alarmLevelName }}
          </div>
          <div
            v-if="item.alarmLevel === 'important'"
            style="
              width: 64px;
              height: 32px;
              border-radius: 2px;
              border: 1px solid #ffb818;
              line-height: 32px;
              color: #ffb818;
              text-align: center;
            "
          >
            {{ item.alarmLevelName }}
          </div>
          <div
            v-if="item.alarmLevel === 'normal'"
            style="
              width: 64px;
              height: 32px;
              border-radius: 2px;
              border: 1px solid #3f6df8;
              line-height: 32px;
              color: #3f6df8;
              text-align: center;
            "
          >
            {{ item.alarmLevelName }}
          </div>
          <div
            style="
              height: 32px;
              line-height: 32px;
              font-weight: 600;
              color: #082254;
              font-size: 14px;
            "
          >
            {{ item.faultDesc }}
          </div>
          <div
            style="
              width: 140px;
              text-align: center;
              height: 32px;
              line-height: 32px;
              font-size: 14px;
              color: #082254;
            "
          >
            {{ item.alarmStartTime | formateV }}
          </div>
        </div>

        <!-- <div
          style="
            width: 100%;
            height: 56px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 14px;
            padding-right: 14px;
            border-bottom: 1px solid #d8d8d8;
          "
        >
          <div
            style="
              width: 64px;
              height: 32px;
              border-radius: 2px;
              border: 1px solid #e52323;
              color: #e52323;
              line-height: 32px;
              text-align: center;
            "
          >
            紧急
          </div>
          <div
            style="
              height: 32px;
              line-height: 32px;
              font-weight: 600;
              color: #082254;
              font-size: 14px;
            "
          >
            热水供水温度偏低
          </div>
          <div
            style="
              width: 130px;
              text-align: center;
              height: 32px;
              line-height: 32px;
              font-size: 14px;
              color: #082254;
            "
          >
            2021-03-05 07:00
          </div>
        </div> -->
        <!-- <div style="width: 100%; height: 310px">
          <v-chart ref="chart2" :option="chartPieOption2" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getAlarmByStatus,
  getAlarmByType,
  getAlarmActual,
  getAlarmMonthNew,
  getAlarmDiagnosis,
  getAlarmOverrun,
  getAlarmTimeout,
  setProject,
} from "@/request/apiLg";
import * as echarts from "echarts";
export default {
  filters: {
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
  },
  data() {
    var dataAxis = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"];
    // var data1 = [
    //   3230.8,
    //   {
    //     value: 2843.1,
    //     itemStyle: {
    //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         { offset: 0, color: "#55ffbc" },
    //         { offset: 0.62, color: "#47A5E1" },
    //         { offset: 1, color: "#3F6DF8" },
    //       ]),
    //       borderRadius: 15,
    //       opacity: 0.6,
    //     },
    //   },
    // ];
    // var data2 = [
    //   27.6,
    //   {
    //     value: 25.9,
    //     itemStyle: {
    //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         { offset: 0, color: "#55ffbc" },
    //         { offset: 0.62, color: "#47A5E1" },
    //         { offset: 1, color: "#3F6DF8" },
    //       ]),
    //       borderRadius: 15,
    //       opacity: 0.6,
    //     },
    //   },
    // ];
    // var data2 = [27.6, 11, 22, 26, 35, 48, 55, 22, 50];
    var data3 = [
      2408.8,
      {
        value: 2842.4,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#55ffbc" },
            { offset: 0.62, color: "#47A5E1" },
            { offset: 1, color: "#3F6DF8" },
          ]),
          borderRadius: 15,
          opacity: 0.6,
        },
      },
    ];
    // var colorList_data_2 = [
    //   {
    //     c1: "#6BCDFF", //实践
    //     c2: "#277CFE",
    //   },
    //   {
    //     c1: "#91ED74", //操作
    //     c2: "#72C556",
    //   },
    //   {
    //     c1: "#F6BF58", //操作
    //     c2: "#F6BF58",
    //   },
    //   // {
    //   //   c1: "#277CFE",
    //   //   c2: "#6BCDFF", //操作
    //   // },
    // ];
    return {
      alarmArray: [],
      timer: null,
      dataValue1: [],
      dataValue11: "",
      dataValue12: "",
      dataValue13: "",
      colorList_data_2: [
        {
          c1: "#6BCDFF", //实践
          c2: "#277CFE",
        },
        {
          c1: "#91ED74", //操作
          c2: "#72C556",
        },
        {
          c1: "#F6BF58", //操作
          c2: "#F6BF58",
        },
        // {
        //   c1: "#277CFE",
        //   c2: "#6BCDFF", //操作
        // },
      ],
      dataValue2: [],
      dataValue31: [],
      dataValue32: [],
      dataValue33: [],
      dataValue4: [],
      dataAxis: [],
      overrunData: {},
      timeoutData: {},
      diagnosisData: {},

      overrunData2: {},
      timeoutData2: {},
      diagnosisData2: {},

      isActivenTime: 1,
      // chartBarOption1: {},
      // chartBarOption2: {

      // },
      chartBarOption3: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: data3,
          },
        ],
      },
      chartBarOption4: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: [0, 0],
          },
        ],
      },
      // chartPieOption1: {},
      // chartPieOption2: {
      //   color: ["#5492D1", "#46BCEE", "#ED9C00", "#53EFC3"],
      //   grid: {
      //     // containLabel: true,
      //     // top: "10px",
      //     // left: "5px",
      //     // right: "10px",
      //     // bottom: "10px",
      //   },
      //   tooltip: {
      //     trigger: "item",
      //     formatter: "{b}: {d}%",
      //   },
      //   legend: {
      //     type: "scroll",
      //     orient: "vertical",
      //     right: 0,
      //     top: 150,
      //     bottom: 20,
      //     // data: data.legendData,
      //     // selected: data.selected,
      //     textStyle: {
      //       color: "#082254",
      //     },
      //     data: [
      //       {
      //         name: "用电量",
      //         textStyle: {
      //           color: "#EA9779",
      //         },
      //       },
      //       {
      //         name: "用水量",
      //         textStyle: {
      //           color: "#3F6DF8",
      //         },
      //       },

      //       {
      //         name: "燃气量",
      //         textStyle: {
      //           color: "#A05CEB",
      //         },
      //       },
      //       {
      //         name: "蒸汽量",
      //         textStyle: {
      //           color: "#6DCB86",
      //         },
      //       },
      //     ],
      //   },
      //   series: [
      //     {
      //       // name: "访问来源",
      //       type: "pie",
      //       // roseType: "radius",
      //       radius: "80%",
      //       center: ["50%", "50%"],
      //       avoidLabelOverlap: false,
      //       itemStyle: {
      //         color: function (params) {
      //           // debugger
      //           var colorList = colorList_data_2;
      //           if (params.dataIndex === 3) {
      //             return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
      //               {
      //                 //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

      //                 offset: 0,
      //                 color: colorList[params.dataIndex].c1,
      //               },
      //               {
      //                 offset: 1,
      //                 color: colorList[params.dataIndex].c2,
      //               },
      //             ]);
      //           } else {
      //             return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
      //               {
      //                 //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

      //                 offset: 0,
      //                 color: colorList[params.dataIndex].c1,
      //               },
      //               {
      //                 //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

      //                 offset: 0.5,
      //                 color: colorList[params.dataIndex].c2,
      //               },
      //               {
      //                 offset: 1,
      //                 color: colorList[params.dataIndex].c3,
      //               },
      //             ]);
      //           }
      //           /*  return colorList[params.dataIndex]*/
      //         },
      //       },
      //       label: {
      //         position: "outside",
      //         // alignTo: "edge",
      //         formatter: (data) => {
      //           // console.log("data", data);
      //           // debugger
      //           if (data.name === "蒸汽量") {
      //             return `{a|蒸汽量: ${data.value}万元 ${data.percent}%}`;
      //           } else if (data.name === "用电量") {
      //             return `{b|用电量: ${data.value}万元 ${data.percent}%}`;
      //           } else if (data.name === "燃气量") {
      //             return `{c|燃气量: ${data.value}万元 ${data.percent}%}`;
      //           } else if (data.name === "用水量") {
      //             return `{d|用水量: ${data.value}万元 ${data.percent}%}`;
      //           }
      //           // const rowArr = [];
      //           // data.forEach(() => {
      //           // const value = this.avaliableTwo(v.data.value[1]);
      //           // if (value) {
      //           //   if (v.data.itemStyle) {
      //           //     rowArr.push(
      //           //       `<span style="color:red;">单耗: ${value} </span>`
      //           //     );
      //           //   } else {
      //           //     rowArr.push(`<span style="">单耗: ${value} </span>`);
      //           //   }
      //           // }
      //           // });
      //           // rowArr.push(`记录时间：${this.formatToMin(data[0].axisValue)}`);
      //           // return rowArr.join("<br/>");
      //         },
      //         // minMargin: 5,
      //         // edgeDistance: 10,
      //         // lineHeight: 15,
      //         rich: {
      //           a: {
      //             fontSize: 14,
      //             color: "#6DCB86",
      //             // fontWeight: "bold",
      //           },
      //           b: {
      //             fontSize: 14,
      //             color: "#EA9779",
      //             // fontWeight: "bold",
      //           },
      //           c: {
      //             fontSize: 14,
      //             color: "#A05CEB",
      //             // fontWeight: "bold",
      //           },
      //           d: {
      //             fontSize: 14,
      //             color: "#3F6DF8",
      //             // fontWeight: "bold",
      //           },
      //         },
      //       },
      //       emphasis: {
      //         label: {
      //           show: true,
      //           fontSize: 14,
      //           // fontWeight: "bold",
      //         },
      //       },
      //       labelLine: {
      //         show: true,
      //         length2: 20,
      //         // smooth: true,
      //         // minTurnAngle: 50,
      //       },
      //       data: [
      //         { value: 0, name: "蒸汽量" },
      //         { value: 560.3, name: "用电量" },
      //         { value: 112.8, name: "燃气量" },
      //         { value: 12.4, name: "用水量" },
      //       ],
      //     },
      //   ],
      // },
    };
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
      currentProjectName: (state) => state.currentProjectName,
      projectArray: (state) => state.projectArray,
    }),
    chartPieOption1() {
      return {
        color: ["#6BCDFF", "#91ED74", "#F6BF58"],
        // color: ["#417AF3", "#82C84A", "#FAC858", "#41CBF9", "#E95B5B"],
        grid: {
          // containLabel: true,
          // top: "10px",
          // left: "5px",
          // right: "10px",
          // bottom: "10px",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 150,
          bottom: 20,
          // data: data.legendData,
          // selected: data.selected,
          textStyle: {
            color: "#082254",
          },
          data: [
            {
              name: "已忽略",
              textStyle: {
                color: "#3F6DF8",
              },
            },
            {
              name: "未处理",
              textStyle: {
                color: "#F6BF58",
              },
            },

            {
              name: "已处理",
              textStyle: {
                color: "#6DCB86",
              },
            },
            // {
            //   name: "蒸汽量",
            //   textStyle: {
            //     color: "#6DCB86",
            //   },
            // },
          ],
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            // roseType: "radius",
            radius: "75%",
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              color: function (params) {
                // debugger
                let colorList = [
                  {
                    c1: "#6BCDFF", //实践
                    c2: "#277CFE",
                  },
                  {
                    c1: "#F6BF58", //操作
                    c2: "#F6BF58",
                  },
                  {
                    c1: "#91ED74", //操作
                    c2: "#72C556",
                  },
                ];
                // if (params.dataIndex === 3) {
                return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                    offset: 0,
                    color: colorList[params.dataIndex].c1,
                  },
                  {
                    offset: 1,
                    color: colorList[params.dataIndex].c2,
                  },
                ]);
                // }
                // } else {
                //   return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                //     {
                //       //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                //       offset: 0,
                //       color: colorList[params.dataIndex].c1,
                //     },
                //     {
                //       //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                //       offset: 0.5,
                //       color: colorList[params.dataIndex].c2,
                //     },
                //     {
                //       offset: 1,
                //       color: colorList[params.dataIndex].c3,
                //     },
                //   ]);
                // }
                /*  return colorList[params.dataIndex]*/
              },
            },
            label: {
              position: "outside",
              // alignTo: "edge",
              formatter: (data) => {
                // console.log("data", data);
                // debugger
                if (data.name === "已忽略") {
                  return `{a|已忽略: ${data.value} ${data.percent}%}`;
                } else if (data.name === "未处理") {
                  return `{b|未处理: ${data.value} ${data.percent}%}`;
                } else if (data.name === "已处理") {
                  return `{c|已处理: ${data.value} ${data.percent}%}`;
                }
              },
              rich: {
                a: {
                  fontSize: 14,
                  color: "#3F6DF8",
                  // fontWeight: "bold",
                },
                b: {
                  fontSize: 14,
                  color: "#F6BF58",
                  // fontWeight: "bold",
                },
                c: {
                  fontSize: 14,
                  color: "#6DCB86",
                  // fontWeight: "bold",
                },
                // d: {
                //   fontSize: 14,
                //   color: "#3F6DF8",
                //   // fontWeight: "bold",
                // },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length2: 20,
              // smooth: true,
              // minTurnAngle: 50,
            },
            data: this.dataValue1,
            // [
            //   { value: this.dataValue11, name: "已忽略" },
            //   { value: this.dataValue12, name: "已处理" },
            //   { value: this.dataValue13, name: "未处理" },
            //   // { value: 16.7, name: "用水量" },
            // ],
          },
        ],
      };
    },
    chartBarOption2() {
      return {
        legend: {
          data: ["数据异常", "通信异常", "故障诊断"],
          right: 0,
          top: 0,
          textStyle: {
            color: "#082254",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          // formatter: "{b}: {c}",
          formatter: (params) => {
            if (
              params[0].value !== undefined &&
              params[1].value !== undefined &&
              params[2].value !== undefined
            ) {
              return (
                "时间：" +
                params[0].axisValue +
                "<br>" +
                "数据异常：" +
                params[0].value +
                "<br>" +
                "通信异常：" +
                params[1].value +
                "<br>" +
                "故障诊断：" +
                params[2].value
                // (params[0].value[1]
                //   ? this.fixType(params[0].value[1], that.chartData.symbol) +
                //     " " +
                //     that.chartData.unit
                //   : "") +
                // "<br>" +
                // time
              );
            }
          },
        },
        grid: {
          containLabel: true,
          top: "50px",
          left: "5px",
          right: "50px",
          bottom: "10px",
        },
        xAxis: {
          data: this.dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            name: "数据异常",
            type: "bar",
            stack: "广告",
            barWidth: 22,
            itemStyle: {
              // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: "#55ffbc" },
              //   { offset: 0.62, color: "#47A5E1" },
              //   { offset: 1, color: "#3F6DF8" },
              // ]),
              color: "#41F39A",
              borderRadius: [0, 0, 15, 15],
            },
            data: this.dataValue31,
          },
          {
            name: "通信异常",
            type: "bar",
            stack: "广告",
            barWidth: 22,
            itemStyle: {
              // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: "#55ffbc" },
              //   { offset: 0.62, color: "#47A5E1" },
              //   { offset: 1, color: "#3F6DF8" },
              // ]),
              color: "#F3BB3D",
              borderRadius: [0, 0, 0, 0],
            },
            data: this.dataValue32,
          },
          {
            name: "故障诊断",
            type: "bar",
            barWidth: 22,
            stack: "广告",
            itemStyle: {
              // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: "#55ffbc" },
              //   { offset: 0.62, color: "#47A5E1" },
              //   { offset: 1, color: "#3F6DF8" },
              // ]),
              color: "#417AF3",
              borderRadius: [15, 15, 0, 0],
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: this.dataValue33,
          },
        ],
      };
    },
    chartBarOption1() {
      return {
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "shadow",
          // },
          // backgroundColor: "#031540",
          // borderColor: "#3F6DF8",
          // textStyle: {
          //   color: "#bcccff",
          // },
        },
        // legend: {
        //   data: ["2011年", "2012年"],
        // },
        grid: {
          //   left: "3%",
          //   right: "4%",
          //   bottom: "3%",
          top: "5",
          left: "5",
          right: "10",
          bottom: "5",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          minInterval: 1,
          boundaryGap: [0, 0.5],
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#3F6DF8"],
              width: 1,
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "category",
          data: ["故障诊断", "通信异常", "数据异常"],
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#3F6DF8"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
        },
        series: [
          {
            // name: "2011年",
            type: "bar",
            data: this.dataValue2,
            barWidth: 30,
            label: {
              show: true,
              position: ["90%", "35%"],
              color: "#082254",
              formatter: "{c}个",
              // verticalAlign:"top"
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: "#3F6DF8" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#55ffbc" },
              ]),
              borderRadius: 15,
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "#3F6DF8" },
                  { offset: 0.62, color: "#47A5E1" },
                  { offset: 1, color: "#55ffbc" },
                ]),
                borderRadius: 15,
              },
            },
            showBackground: true,
            backgroundStyle: {
              color: "rgba(63, 109, 248, 0.1)",
              borderRadius: 15,
            },
          },
        ],
      };
    },
  },
  watch: {
    currentProjectId(val) {
      // debugger
      console.log(val);
      this.dataValue1 = [];
      this.dataValue2 = [];
      this.alarmArray = [];
      this.dataValue31 = [];
      this.dataValue32 = [];
      this.dataValue33 = [];
      this.dataAxis = [];
      this.diagnosisData = {};
      this.overrunData = {};
      this.timeoutData = {};
      setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
        (res2) => {
          if (res2.data.code === 0) {
            getAlarmByStatus().then((res) => {
              console.log(res);
              res.data.data.forEach((item) => {
                if (item.alarmStatus === "IGNORED") {
                  this.dataValue11 = item.alarmNum;
                  // this.dataValue1.push({
                  //   value: this.dataValue11,
                  //   name: "已忽略",
                  // });
                } else if (item.alarmStatus === "UNTREATED") {
                  this.dataValue13 = item.alarmNum;
                  // this.dataValue1.push({
                  //   value: this.dataValue13,
                  //   name: "已处理",
                  // });
                } else if (item.alarmStatus === "PROCESSED") {
                  this.dataValue12 = item.alarmNum;
                  // this.dataValue1.push({
                  //   value: this.dataValue12,
                  //   name: "未处理",
                  // });
                }
                // this.dataValue1.push()
              });
              this.dataValue1 = [
                {
                  value: this.dataValue11,
                  name: "已忽略",
                },
                {
                  value: this.dataValue12,
                  name: "未处理",
                },
                {
                  value: this.dataValue13,
                  name: "已处理",
                },
              ];
            });
            getAlarmByType().then((res) => {
              console.log(res);
              res.data.data.forEach((item) => {
                if (item.alarmType === "OVERRUN") {
                  this.overrunData2 = item;
                } else if (item.alarmType === "TIMEOUT") {
                  this.timeoutData2 = item;
                } else if (item.alarmType === "DIAGNOSIS") {
                  this.diagnosisData2 = item;
                }
              });
              this.dataValue2 = [
                this.diagnosisData2.untreatedAlarmNum,
                this.timeoutData2.untreatedAlarmNum,
                this.overrunData2.untreatedAlarmNum,
              ];
            });
            getAlarmActual().then((res) => {
              console.log(res);
              if (res.data.data.length > 0) {
                if (res.data.data.length === 1) {
                  this.alarmArray = [res.data.data[0]];
                } else if (res.data.data.length === 2) {
                  this.alarmArray = [res.data.data[0], res.data.data[1]];
                } else if (res.data.data.length === 3) {
                  this.alarmArray = [
                    res.data.data[0],
                    res.data.data[1],
                    res.data.data[2],
                  ];
                } else if (res.data.data.length >= 4) {
                  this.alarmArray = [
                    res.data.data[0],
                    res.data.data[1],
                    res.data.data[2],
                    res.data.data[3],
                  ];
                }
              } else {
                this.alarmArray = [];
              }
              // this.alarmArray = [
              //   res.data.data[0],
              //   res.data.data[1],
              //   res.data.data[2],
              //   res.data.data[3],
              // ];
            });
            this.timer = setInterval(() => {
              getAlarmActual().then((res) => {
                console.log(res);
                if (res.data.data.length > 0) {
                  if (res.data.length === 1) {
                    this.alarmArray = [res.data.data[0]];
                  } else if (res.data.data.length === 2) {
                    this.alarmArray = [res.data.data[0], res.data.data[1]];
                  } else if (res.data.data.length === 3) {
                    this.alarmArray = [
                      res.data.data[0],
                      res.data.data[1],
                      res.data.data[2],
                    ];
                  } else if (res.data.data.ength >= 4) {
                    this.alarmArray = [
                      res.data.data[0],
                      res.data.data[1],
                      res.data.data[2],
                      res.data.data[3],
                    ];
                  }
                } else {
                  this.alarmArray = [];
                }
              });
            }, 60 * 1000);
            getAlarmMonthNew().then((res) => {
              console.log(res);
              let temp1 = [];
              res.data.data.timeList.forEach((item) => {
                let temp2 = this.formatDate(item).split(" ")[0];
                let temp3 = temp2.split("-")[1] + "-" + temp2.split("-")[2];
                temp1.push(temp3);
              });
              let temp4 = [];
              let temp5 = [];
              let temp6 = [];
              res.data.data.alarmTypeNumList.forEach((item) => {
                temp4.push(item.diagnosis);
                temp5.push(item.overrun);
                temp6.push(item.timeout);
              });
              this.dataValue31 = temp5;
              this.dataValue32 = temp6;
              this.dataValue33 = temp4;
              this.dataAxis = temp1;
            });
            getAlarmDiagnosis().then((res) => {
              console.log(res);
              this.diagnosisData = res.data.data;
            });
            getAlarmOverrun().then((res) => {
              console.log(res);
              this.overrunData = res.data.data;
            });
            getAlarmTimeout().then((res) => {
              console.log(res);
              this.timeoutData = res.data.data;
            });
          }
        }
      );
    },
  },
  methods: {
    toMore() {
      this.$router.push({ name: "alarmSearch" });
    },
    toDetailAlarm(row) {
      this.$router.push({
        name: "alarmDetail",
        query: { id: row.id },
      });
    },
    clickNH(index) {
      this.isActivenTime = index;
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
  },
  mounted() {
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getAlarmByStatus().then((res) => {
            console.log(res);
            res.data.data.forEach((item) => {
              if (item.alarmStatus === "IGNORED") {
                this.dataValue11 = item.alarmNum;
                // this.dataValue1.push({
                //   value: this.dataValue11,
                //   name: "已忽略",
                // });
              } else if (item.alarmStatus === "UNTREATED") {
                this.dataValue13 = item.alarmNum;
                // this.dataValue1.push({
                //   value: this.dataValue13,
                //   name: "已处理",
                // });
              } else if (item.alarmStatus === "PROCESSED") {
                this.dataValue12 = item.alarmNum;
                // this.dataValue1.push({
                //   value: this.dataValue12,
                //   name: "未处理",
                // });
              }
              // this.dataValue1.push()
            });
            this.dataValue1 = [
              {
                value: this.dataValue11,
                name: "已忽略",
              },
              {
                value: this.dataValue12,
                name: "未处理",
              },
              {
                value: this.dataValue13,
                name: "已处理",
              },
            ];
          });
          getAlarmByType().then((res) => {
            console.log(res);
            res.data.data.forEach((item) => {
              if (item.alarmType === "OVERRUN") {
                this.overrunData2 = item;
              } else if (item.alarmType === "TIMEOUT") {
                this.timeoutData2 = item;
              } else if (item.alarmType === "DIAGNOSIS") {
                this.diagnosisData2 = item;
              }
            });
            this.dataValue2 = [
              this.diagnosisData2.untreatedAlarmNum,
              this.timeoutData2.untreatedAlarmNum,
              this.overrunData2.untreatedAlarmNum,
            ];
          });
          getAlarmActual().then((res) => {
            console.log(res);
            if (res.data.data.length > 0) {
              if (res.data.data.length === 1) {
                this.alarmArray = [res.data.data[0]];
              } else if (res.data.data.length === 2) {
                this.alarmArray = [res.data.data[0], res.data.data[1]];
              } else if (res.data.data.length === 3) {
                this.alarmArray = [
                  res.data.data[0],
                  res.data.data[1],
                  res.data.data[2],
                ];
              } else if (res.data.data.length >= 4) {
                this.alarmArray = [
                  res.data.data[0],
                  res.data.data[1],
                  res.data.data[2],
                  res.data.data[3],
                ];
              }
            } else {
              this.alarmArray = [];
            }
            // this.alarmArray = [
            //   res.data.data[0],
            //   res.data.data[1],
            //   res.data.data[2],
            //   res.data.data[3],
            // ];
          });
          this.timer = setInterval(() => {
            getAlarmActual().then((res) => {
              console.log(res);
              if (res.data.data.length > 0) {
                if (res.data.length === 1) {
                  this.alarmArray = [res.data.data[0]];
                } else if (res.data.data.length === 2) {
                  this.alarmArray = [res.data.data[0], res.data.data[1]];
                } else if (res.data.data.length === 3) {
                  this.alarmArray = [
                    res.data.data[0],
                    res.data.data[1],
                    res.data.data[2],
                  ];
                } else if (res.data.data.ength >= 4) {
                  this.alarmArray = [
                    res.data.data[0],
                    res.data.data[1],
                    res.data.data[2],
                    res.data.data[3],
                  ];
                }
              } else {
                this.alarmArray = [];
              }
            });
          }, 60 * 1000);
          getAlarmMonthNew().then((res) => {
            console.log(res);
            let temp1 = [];
            res.data.data.timeList.forEach((item) => {
              let temp2 = this.formatDate(item).split(" ")[0];
              let temp3 = temp2.split("-")[1] + "-" + temp2.split("-")[2];
              temp1.push(temp3);
            });
            let temp4 = [];
            let temp5 = [];
            let temp6 = [];
            res.data.data.alarmTypeNumList.forEach((item) => {
              temp4.push(item.diagnosis);
              temp5.push(item.overrun);
              temp6.push(item.timeout);
            });
            this.dataValue31 = temp5;
            this.dataValue32 = temp6;
            this.dataValue33 = temp4;
            this.dataAxis = temp1;
          });
          getAlarmDiagnosis().then((res) => {
            console.log(res);
            this.diagnosisData = res.data.data;
          });
          getAlarmOverrun().then((res) => {
            console.log(res);
            this.overrunData = res.data.data;
          });
          getAlarmTimeout().then((res) => {
            console.log(res);
            this.timeoutData = res.data.data;
          });
        }
      }
    );
  },
};
</script>

<style scoped>
.title1 {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  width: 32%;
  height: 100%;
  background: #4dc7d3;
  border-radius: 4px;
  position: relative;
}
.title2 {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  width: 32%;
  height: 100%;
  background: #bd8def;
  border-radius: 4px;
  position: relative;
}
.title3 {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  width: 32%;
  height: 100%;
  background: #8792f4;
  border-radius: 4px;
  position: relative;
}
/* .title4 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #6bcf84 0%, #6ec887 46%, #2aab4c 100%);
  border-radius: 4px;
  position: relative;
} */

.body1 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.body2 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
</style>