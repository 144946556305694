<template>
    <router-view></router-view>
</template>

<script>
export default {
    // 空路由组件
};
</script>

<style>
</style>
