<template>
  <div class="coldSystemJk">
    <div class="first" v-if="!showSecond">
      <div class="body">
        <div class="body-left">
          <div class="status">
            <div class="status-name">制冷系统</div>
            <div
              class="status-circle"
              :class="{
                start: pointMap[keyValueMap['cool.run.mode']] === '0',
                stop: pointMap[keyValueMap['cool.run.mode']] === '1',
              }"
            ></div>
            <div
              class="status-icon"
              :class="{
                start: pointMap[keyValueMap['cool.run.mode']] === '0',
                stop: pointMap[keyValueMap['cool.run.mode']] === '1',
              }"
            ></div>
            <span
              >运行模式：<span>{{
                pointMap[keyValueMap["cool.run.mode"]] === "0" ? "寻优" : "手动"
              }}</span></span
            >
          </div>
          <div class="left-list">
            <div
              class="left-item"
              v-for="(item, index) in leftList"
              :key="item.name"
              :class="'left-item-' + index"
            >
              <div class="item-val">
                {{ item.value === null ? "-- --" : item.value }}
              </div>
              <div class="item-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="body-center">
          <div class="track-img" @click="showSecond = true">
            <img src="@/assets/jinke/track.png" />
            <div class="txt">运行轨迹</div>
          </div>
          <img
            class="lengque-1"
            v-if="pointMap[keyValueMap['ct1.rs']] === '1'"
            src="@/assets/jinke/动.gif"
          />
          <div class="item-valp lengque-1-txt"><span>1#</span>冷却塔</div>
          <div class="item-valp lengque-2-txt"><span>2#</span>冷却塔</div>
          <div class="item-valp lengque-3-txt"><span>3#</span>冷却塔</div>
          <div class="item-valp lengque-4-txt"><span>4#</span>冷却塔</div>
          <img
            class="lengque-1"
            v-if="pointMap[keyValueMap['ct1.rs']] === '0'"
            src="@/assets/jinke/不动.png"
          />
          <img
            class="lengque-2"
            v-if="pointMap[keyValueMap['ct2.rs']] === '1'"
            src="@/assets/jinke/动.gif"
          />
          <img
            class="lengque-2"
            v-if="pointMap[keyValueMap['ct2.rs']] === '0'"
            src="@/assets/jinke/不动.png"
          />
          <img
            class="lengque-3"
            v-if="pointMap[keyValueMap['ct3.rs']] === '1'"
            src="@/assets/jinke/动.gif"
          />
          <img
            class="lengque-3"
            v-if="pointMap[keyValueMap['ct3.rs']] === '0'"
            src="@/assets/jinke/不动.png"
          />
          <img
            class="lengque-4"
            v-if="pointMap[keyValueMap['ct4.rs']] === '1'"
            src="@/assets/jinke/动.gif"
          />
          <img
            class="lengque-4"
            v-if="pointMap[keyValueMap['ct4.rs']] === '0'"
            src="@/assets/jinke/不动.png"
          />
          <img class="body-bg" src="~@/assets/jinke/coldBack.png" alt="" />
          <img
            class="body-bg"
            v-if="
              pointMap[keyValueMap['ch1.yx.st']] === '1' ||
              pointMap[keyValueMap['ch2.yx.st']] === '1'
            "
            src="~@/assets/jinke/制冷动.webp"
            alt=""
          />
          <div class="item-valp lengdongji-1">
            <span>1#</span> 供水温度：{{
              pointMap[keyValueMap["ch1.zflev.temp"]] != null
                ? pointMap[keyValueMap["ch1.zflev.temp"]] + "℃"
                : "-- --"
            }}
          </div>
          <!-- 冷冻机1 -->
          <img
            class="lengdongji-1-status"
            v-if="pointMap[keyValueMap['ch1.yx.st']] === '1'"
            :src="statusPic['run']"
          />
          <img
            class="lengdongji-1-status"
            v-if="pointMap[keyValueMap['ch1.yx.st']] === '0'"
            :src="statusPic['stop']"
          />
          <img
            class="lengdongji-1-status"
            v-if="pointMap[keyValueMap['ch1.yx.st']] == null"
            :src="statusPic['bug']"
          />
          <!-- 冷冻机2 -->
          <img
            class="lengdongji-2-status"
            v-if="pointMap[keyValueMap['ch2.yx.st']] === '1'"
            :src="statusPic['run']"
          />
          <img
            class="lengdongji-2-status"
            v-if="pointMap[keyValueMap['ch2.yx.st']] === '0'"
            :src="statusPic['stop']"
          />
          <img
            class="lengdongji-2-status"
            v-if="pointMap[keyValueMap['ch2.yx.st']] == null"
            :src="statusPic['bug']"
          />
          <!-- 冷却泵1 -->
          <img
            class="lengquebeng-1-status"
            v-if="pointMap[keyValueMap['ctp1.rs']] === '1'"
            :src="statusPic['run']"
          />
          <img
            class="lengquebeng-1-status"
            v-if="pointMap[keyValueMap['ctp1.rs']] === '0'"
            :src="statusPic['stop']"
          />
          <img
            class="lengquebeng-1-status"
            v-if="pointMap[keyValueMap['ctp1.rs']] == null"
            :src="statusPic['bug']"
          />
          <!-- 冷却泵2 -->
          <img
            class="lengquebeng-2-status"
            v-if="pointMap[keyValueMap['ctp2.rs']] === '1'"
            :src="statusPic['run']"
          />
          <img
            class="lengquebeng-2-status"
            v-if="pointMap[keyValueMap['ctp2.rs']] === '0'"
            :src="statusPic['stop']"
          />
          <img
            class="lengquebeng-2-status"
            v-if="pointMap[keyValueMap['ctp2.rs']] == null"
            :src="statusPic['bug']"
          />
          <!-- 冷却泵3 -->
          <img
            class="lengquebeng-3-status"
            v-if="pointMap[keyValueMap['ctp3.rs']] === '1'"
            :src="statusPic['run']"
          />
          <img
            class="lengquebeng-3-status"
            v-if="pointMap[keyValueMap['ctp3.rs']] === '0'"
            :src="statusPic['stop']"
          />
          <img
            class="lengquebeng-3-status"
            v-if="pointMap[keyValueMap['ctp3.rs']] == null"
            :src="statusPic['bug']"
          />
          <!-- 冷冻泵1 -->
          <img
            class="lengdongbeng-1-status"
            v-if="pointMap[keyValueMap['cp1.rs']] === '1'"
            :src="statusPic['run']"
          />
          <img
            class="lengdongbeng-1-status"
            v-if="pointMap[keyValueMap['cp1.rs']] === '0'"
            :src="statusPic['stop']"
          />
          <img
            class="lengdongbeng-1-status"
            v-if="pointMap[keyValueMap['cp1.rs']] == null"
            :src="statusPic['bug']"
          />
          <!-- 冷冻泵2 -->
          <img
            class="lengdongbeng-2-status"
            v-if="pointMap[keyValueMap['cp2.rs']] === '1'"
            :src="statusPic['run']"
          />
          <img
            class="lengdongbeng-2-status"
            v-if="pointMap[keyValueMap['cp2.rs']] === '0'"
            :src="statusPic['stop']"
          />
          <img
            class="lengdongbeng-2-status"
            v-if="pointMap[keyValueMap['cp2.rs']] == null"
            :src="statusPic['bug']"
          />
          <!-- 冷冻泵3 -->
          <img
            class="lengdongbeng-3-status"
            v-if="pointMap[keyValueMap['cp3.rs']] === '1'"
            :src="statusPic['run']"
          />
          <img
            class="lengdongbeng-3-status"
            v-if="pointMap[keyValueMap['cp3.rs']] === '0'"
            :src="statusPic['stop']"
          />
          <img
            class="lengdongbeng-3-status"
            v-if="pointMap[keyValueMap['cp3.rs']] == null"
            :src="statusPic['bug']"
          />

          <div class="item-valp zonghuishui">
            总回水温度：{{
              pointMap[keyValueMap["chw.rtemp"]] != null
                ? pointMap[keyValueMap["chw.rtemp"]] + "℃"
                : "-- --"
            }}
          </div>
          <div class="item-valp zonggongshui">
            总供水温度：{{
              pointMap[keyValueMap["chw.stemp"]] != null
                ? pointMap[keyValueMap["chw.stemp"]] + "℃"
                : "-- --"
            }}
          </div>
          <div class="item-valp lengquebeng-1-w">
            <span>1#</span>冷冻泵频率：{{
              pointMap[keyValueMap["cp1.hz.hzst"]] != null
                ? pointMap[keyValueMap["cp1.hz.hzst"]] + "Hz"
                : "-- --"
            }}
          </div>
          <div class="item-valp lengquebeng-2-w">
            <span>2#</span>冷冻泵频率：{{
              pointMap[keyValueMap["cp2.hz.hzst"]] != null
                ? pointMap[keyValueMap["cp2.hz.hzst"]] + "Hz"
                : "-- --"
            }}
          </div>
          <div class="item-valp lengquebeng-3-w">
            <span>3#</span>冷冻泵频率：{{
              pointMap[keyValueMap["cp3.hz.hzst"]] != null
                ? pointMap[keyValueMap["cp3.hz.hzst"]] + "Hz"
                : "-- --"
            }}
          </div>

          <div class="item-valp lengdongji-2">
            <span>2#</span> 供水温度：{{
              pointMap[keyValueMap["ch2.zflev.temp"]] != null
                ? pointMap[keyValueMap["ch2.zflev.temp"]] + "℃"
                : "-- --"
            }}
          </div>
          <div class="item6">
            <div class="item6-item">
              <img :src="statusPic['run']" alt="" />
              运行
            </div>
            <div class="item6-item">
              <img :src="statusPic['stop']" alt="" />
              停止
            </div>
            <div class="item6-item">
              <img :src="statusPic['bug']" alt="" />
              故障
            </div>
          </div>
          <img
            class="left"
            src="~@/assets/heatingSystemWebp/窗口左边.webp"
            alt=""
          />
          <img
            class="right"
            src="~@/assets/heatingSystemWebp/窗口右边.webp"
            alt=""
          />
        </div>
        <div class="body-right">
          <div class="heat-box boiler">
            <div class="heat-title">当日能耗</div>
            <div class="boiler-body">
              <div class="boiler-list">
                <div
                  class="boiler-item"
                  v-for="(item, index) in boilerList"
                  :key="index"
                >
                  <div class="boiler-body">
                    <img :src="item.src" alt="" />
                    <div class="boiler-num">
                      <div class="boiler-name">{{ item.name }}</div>
                      <div class="boiler-change">{{ item.change }}</div>
                      <div class="boiler-value">
                        {{ item.value != null ? item.value : "--" }}
                      </div>
                      <div class="boiler-unit">{{ item.unit }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="heat-box columnar" v-loading="columnarStatus">
          <div class="heat-title">
            <span>用电能耗统计分析</span>
            <div class="columnar-title">
              <div
                v-for="item in columnarArr"
                :key="item.value"
                class="columnar-title-item"
                :class="{ active: columnarIndex == item.value }"
                @click="changecolumnarIndex(item.value)"
              >
                {{ item.name }}
              </div>
              <span style="font-size: 14px; margin: 0 5px; font-weight: 500"
                >时间选择</span
              >
              <el-date-picker
                v-show="columnarIndex === 'HOURS'"
                class="columnar-searchTime"
                v-model="searchTime"
                type="date"
                placeholder="选择日期"
                size="mini"
                style="width: 130px"
                popper-class="columnar-time"
                align="left"
                :clearable="false"
                :append-to-body="true"
                @change="loadColumnarData"
              >
              </el-date-picker>
              <el-date-picker
                v-show="columnarIndex === 'DAYS'"
                class="columnar-searchTime"
                v-model="searchTime"
                type="month"
                placeholder="选择日期"
                size="mini"
                style="width: 130px"
                :append-to-body="true"
                popper-class="columnar-time"
                align="left"
                :clearable="false"
                @change="loadColumnarData"
              >
              </el-date-picker>
              <el-date-picker
                v-show="columnarIndex === 'MONTHS'"
                class="columnar-searchTime"
                v-model="searchTime"
                type="year"
                placeholder="选择日期"
                size="mini"
                :append-to-body="true"
                style="width: 130px"
                popper-class="columnar-time"
                align="left"
                :clearable="false"
                @change="loadColumnarData"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="chartPart" style="width: 100%; height: calc(100% - 32px)">
            <div class="left">
              <div class="imgC">
                <div class="img">
                  <img src="@/assets/jinke/coldChartBack.png" class="bg" />
                  <img src="@/assets/jinke/冷9.webp" class="icon" />
                  <div class="name">系统用电</div>
                  <div class="data">
                    {{ elec != null ? elec : "--" }}
                  </div>
                  <div class="unit">(kwh)</div>
                </div>
              </div>
              <div class="chart">
                <v-chart ref="chartPie" :option="optionPie" />
                <div class="lengdongji">
                  <span>{{ optionPie.series[0].data[0].value }}</span
                  >kwh
                </div>
                <div class="lengquebeng">
                  <span>{{ optionPie.series[0].data[1].value }}</span
                  >kwh
                </div>
                <div class="lengdongbeng">
                  <span>{{ optionPie.series[0].data[2].value }}</span
                  >kwh
                </div>
                <div class="lengqueta">
                  <span>{{ optionPie.series[0].data[3].value }}</span
                  >kwh
                </div>
              </div>
            </div>
            <v-chart ref="chartColumnar" :option="optionColumnar" />
          </div>
        </div>
      </div>
    </div>
    <div class="second" v-if="showSecond">
      <Second></Second>
    </div>
  </div>
</template>

<script>
import {
  getKeyMap,
  getRealtime,
  getCoolCount,
  getInstantCOP,
  getAddCOP,
  getElecRate,
  getPoint,
  optimizationData,
} from "@/request/apiJk";
import * as echarts from "echarts";
import Second from "./second.vue";
import dayjs from "dayjs";
export default {
  name: "heatingSystem",
  data() {
    return {
      elec: null,
      leftList: [
        {
          name: "冷冻水供水温度 (℃)",
          value: "",
        },
        {
          name: "冷冻水回水温度 (℃)",
          value: "",
        },
        {
          name: "冷却水出水温度 (℃)",
          value: "",
        },
        {
          name: "冷却水进水温度 (℃)",
          value: "",
        },
      ],
      boilerList: [
        {
          name: "冷量",
          value: null,
          unit: "(kwh)",
          src: require("@/assets/jinke/冷5.webp"),
        },
        {
          name: "实时流量",
          value: null,
          unit: "(m³/h)",
          src: require("@/assets/jinke/冷6.webp"),
        },
        {
          name: "瞬时COP",
          value: null,
          unit: "",
          src: require("@/assets/jinke/冷7.webp"),
        },
        {
          name: "累计COP",
          value: null,
          unit: "",
          src: require("@/assets/jinke/冷8.webp"),
        },
      ],
      statusPic: {
        run: require("@/assets/heatingSystemWebp/boiler0.webp"),
        stop: require("@/assets/heatingSystemWebp/boiler2.png"),
        bug: require("@/assets/heatingSystemWebp/boiler1.png"),
      },
      columnarArr: [
        {
          name: "日",
          value: "HOURS",
        },
        {
          name: "月",
          value: "DAYS",
        },
        {
          name: "年",
          value: "MONTHS",
        },
      ],
      searchTime: new Date(),
      columnarIndex: "HOURS",
      optionPie: {
        title: {
          text: "设备用电占比",
          left: "14%",
          top: "4%",
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
        legend: {
          orient: "vertical",
          top: "30%",
          right: "12%",
          textStyle: {
            color: "#fff",
          },
          formatter: (name) => {
            const data = this.optionPie.series[0].data;
            const valueObj = data.find((item) => item.name === name);
            return `${name} ${valueObj.value} kwh`;
          },
          data: ["冷冻机", "冷却泵", "冷冻泵", "冷却塔"],
        },
        series: [
          {
            name: "Radius Mode",
            type: "pie",
            radius: [40, 70],
            center: ["24%", "57%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 5,
            },
            label: {
              show: false,
              position: "center",
              color: "#fff",
              formatter: "{d|{d}%}\n{b}",
              rich: {
                d: {
                  fontSize: 16,
                  fontWeight: "bold",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: [
              { value: 0, name: "冷冻机", itemStyle: { color: "#0CE9F4" } },
              { value: 0, name: "冷却泵", itemStyle: { color: "#296AFF" } },
              { value: 0, name: "冷冻泵", itemStyle: { color: "#A42AFF" } },
              { value: 0, name: "冷却塔", itemStyle: { color: "#ED52FF" } },
            ],
          },
        ],
      },
      optionColumnar: {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            let res = `${params[0].name}<br/>`;
            if (params[0].value !== undefined) {
              res += `用电能耗（${params[0].seriesName}）：${params[0].value}<br/>`;
            } else if (params[1].value !== undefined) {
              res += `用电能耗（${params[1].seriesName}）：${params[1].value}<br/>`;
            }
            if (params[2] && params[2].value) {
              res += `${params[2].seriesName}：${params[2].value}<br/>`;
            }
            if (params[3] && params[3].value) {
              res += `${params[3].seriesName}：${params[3].value}<br/>`;
            }
            return res;
          },
        },
        legend: {
          bottom: "bottom",
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "40px",
          left: "40px",
          right: "10px",
          bottom: "20px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          // 轴上的刻度设置
          axisLabel: {
            inside: false,
            interval: 23,
            rotate: 30,
            textStyle: {
              color: "#fff",
            },
          },
          // 轴线样式设置
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "kwh",
            min: 0,
            axisTick: {
              show: false,
            },
            // 单位设置
            nameTextStyle: {
              color: "#fff",
              verticalAlign: "top",
              align: "left",
            },
            // 轴上延申线
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63, 109, 248, 0.3)"],
                width: 1,
                type: "dashed",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(63, 109, 248, 0.8)",
              },
            },
            axisLabel: {
              color: "red",
              inside: false,
              textStyle: {
                color: "#fff",
              },
              lineStyle: {
                color: "red",
                widtg: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "℃",
            max: 40,
            min: -20,
            interval: 15,
            axisLabel: {
              formatter: "{value} °C",
              inside: false,
              textStyle: {
                color: "#fff",
              },
              lineStyle: {
                color: "red",
                widtg: 1,
                type: "dashed",
              },
            },
            // 单位设置
            nameTextStyle: {
              color: "#fff",
              verticalAlign: "top",
              align: "right",
            },
            // 轴上延申线
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63, 109, 248, 0.3)"],
                width: 1,
                type: "dashed",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(63, 109, 248, 0.8)",
              },
            },
          },
        ],
        series: [
          {
            name: "非优化",
            stack: "Ad",
            barWidth: 12,
            type: "bar",
            data: [],
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(47, 89, 215, 0.8)" }, //柱图渐变色
                  { offset: 1, color: "rgba(47, 89, 215, 0.8)" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(47, 89, 215, 1)" }, //柱图渐变色
                  { offset: 1, color: "rgba(47, 89, 215, 1)" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: "优化",
            type: "bar",
            stack: "Ad",
            barWidth: 12,
            data: [],
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#55FFBC" }, //柱图渐变色
                  { offset: 1, color: "#49E6FF" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#49E6FF" }, //柱图渐变色
                  { offset: 1, color: "#49E6FF" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: "空间温度",
            type: "line",
            yAxisIndex: 1,
            smooth: true,
            showSymbol: false,
            tooltip: {
              valueFormatter: function (value) {
                return value + " °C";
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "rgba(199, 185, 94, 1)",
                },
              },
            },
            data: [],
          },
          {
            name: "室外温度",
            type: "line",
            showSymbol: false,
            yAxisIndex: 1,
            smooth: true,
            tooltip: {
              valueFormatter: function (value) {
                return value + " °C";
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "rgba(192, 74, 252, 1)",
                },
              },
            },
            data: [],
          },
        ],
      },
      showSecond: false,
      columnarStatus: false,
      pointArr: [],
      keyValueMap: {},
      pointMap: {},
      pointQuery: {
        endTime: "",
        interval: 0,
        startTime: "",
        timeUnit: "",
        queryType: 0,
        point: "",
      },
      elecQuery: {
        endTime: "",
        interval: 0,
        point: "",
        startTime: "",
        timeUnit: "",
      },
      totalKeyValueMap: {},
    };
  },
  components: { Second },
  methods: {
    getKeyMap() {
      return new Promise((resolve, reject) => {
        getKeyMap("cool")
          .then((res) => {
            if (res.data.code === 0) {
              this.pointArr = res.data.data;
              let obj = {};
              res.data.data.map((item) => {
                obj[item.key] = item.value;
              });
              this.keyValueMap = obj;
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTotalKeyMap() {
      return new Promise((resolve, reject) => {
        getKeyMap("total")
          .then((res) => {
            if (res.data.code === 0) {
              let obj = {};
              res.data.data.map((item) => {
                obj[item.key] = item.value;
              });
              this.totalKeyValueMap = obj;
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changecolumnarIndex(index) {
      this.columnarIndex = index;
      this.loadColumnarData();
    },
    async loadColumnarData() {
      if (!this.searchTime) {
        this.$message({
          message: "日期不能为空！",
          type: "warning",
        });
        this.searchTime = new Date();
        return;
      }
      if (this.columnarIndex === "HOURS") {
        this.optionColumnar.xAxis.data = [
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:00",
          "24:00",
        ];
        this.elecQuery.startTime = dayjs(this.searchTime).format(
          "YYYY-MM-DD 00:00:00"
        );
        this.elecQuery.endTime = dayjs(this.searchTime)
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");

        this.pointQuery.startTime = dayjs(this.searchTime).format(
          "YYYY-MM-DD 00:00:00"
        );
        this.pointQuery.endTime = dayjs(this.searchTime)
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");
      } else if (this.columnarIndex === "DAYS") {
        let num = dayjs(this.searchTime).daysInMonth();
        let month = dayjs(this.searchTime).format("MM");
        let arr = [];
        for (let i = 1; i <= num; i++) {
          for (let j = 0; j < 24; j++) {
            if (j !== 0) {
              arr.push(
                month +
                  "-" +
                  i.toString().padStart(2, "0") +
                  " " +
                  j.toString().padStart(2, "0") +
                  ":00"
              );
            } else {
              arr.push(month + "-" + i.toString().padStart(2, "0"));
            }
          }
        }
        this.optionColumnar.xAxis.data = arr;
        this.elecQuery.startTime = dayjs(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        this.elecQuery.endTime = dayjs(this.searchTime)
          .endOf("month")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");

        this.pointQuery.startTime = dayjs(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        this.pointQuery.endTime = dayjs(this.searchTime)
          .endOf("month")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");
      } else if (this.columnarIndex === "MONTHS") {
        let arr = [];
        let year = dayjs(this.searchTime).format("YYYY");
        for (let i = 1; i <= 12; i++) {
          let monthDyas = dayjs(year + "-" + i).daysInMonth();
          for (let d = 1; d <= monthDyas; d++) {
            arr.push(
              i.toString().padStart(2, "0") +
                "-" +
                d.toString().padStart(2, "0")
            );
          }
        }
        this.optionColumnar.xAxis.data = arr;
        this.elecQuery.startTime = dayjs(this.searchTime)
          .startOf("year")
          .format("YYYY-MM-DD 00:00:00");
        this.elecQuery.endTime = dayjs(this.searchTime)
          .endOf("year")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");

        this.pointQuery.startTime = dayjs(this.searchTime)
          .startOf("year")
          .format("YYYY-MM-DD 00:00:00");
        this.pointQuery.endTime = dayjs(this.searchTime)
          .endOf("year")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");
      }

      if (this.columnarIndex === "MONTHS") {
        this.pointQuery.timeUnit = "DAYS";
        this.elecQuery.timeUnit = "YEARS";
        this.changeEchartsSize(2);
      } else {
        if (this.columnarIndex === "HOURS") {
          this.pointQuery.timeUnit = "HOURS";
          this.elecQuery.timeUnit = "DAYS";
          this.changeEchartsSize(12);
        } else {
          this.pointQuery.timeUnit = "HOURS";
          this.elecQuery.timeUnit = "MONTHS";
          this.changeEchartsSize(1);
        }
      }

      this.getElecRate();

      getPoint({
        endTime: this.elecQuery.endTime,
        interval: 0,
        startTime: this.elecQuery.startTime,
        timeUnit: this.elecQuery.timeUnit,
        queryType: 0,
        point: this.keyValueMap["sys.power"],
      }).then((res) => {
        if (res.data.code === 0) {
          this.elec = res.data.data.first[0].formatValue
            ? parseFloat(res.data.data.first[0].formatValue).toFixed(2)
            : null;
        }
      });
      //室内温度
      getPoint({
        endTime: this.pointQuery.endTime,
        interval: 0,
        startTime: this.pointQuery.startTime,
        timeUnit: this.pointQuery.timeUnit,
        queryType: 1,
        point: this.totalKeyValueMap["indoor.temp"],
      }).then((res) => {
        if (res.data.code === 0) {
          this.optionColumnar.series[2].data = (
            (res.data.data || {}).first || []
          ).map((res1) => {
            return res1.formatValue;
          });
        }
      });
      //室外温度
      getPoint({
        endTime: this.pointQuery.endTime,
        interval: 0,
        startTime: this.pointQuery.startTime,
        timeUnit: this.pointQuery.timeUnit,
        queryType: 1,
        point: this.totalKeyValueMap["outdoor.temp"],
      }).then((res) => {
        if (res.data.code === 0) {
          this.optionColumnar.series[3].data = (
            (res.data.data || {}).first || []
          ).map((res1) => {
            return res1.formatValue;
          });
        }
      });
      //优化
      optimizationData({
        endTime: this.pointQuery.endTime,
        interval: 0,
        optimizePoint: this.keyValueMap["cool.run.mode"],
        startTime: this.pointQuery.startTime,
        timeUnit: this.pointQuery.timeUnit,
        queryType: 1,
        point: this.keyValueMap["sys.power"],
      }).then((res) => {
        if (res.data.code === 0) {
          let max = 0;
          let arr1 = [],
            arr2 = [];
          ((res.data.data || {}).first || []).forEach((element, index) => {
            if (parseInt(element.formatValue) > max) {
              max = parseInt(element.formatValue);
            }
            if (((res.data.data || {}).second[index] || {}).formatValue !== "1") {
              arr1.push(element.formatValue);
              arr2.push(null);
            } else {
              arr2.push(element.formatValue);
              arr1.push(null);
            }
          });
          if (max && max > 0) {
            // 取全部数据的最大值
            // 最大值的第一位+1 获取到整数的最大值
            let maxStart = max.toString()[0] - -1;
            let maxLength = (max + "").length;
            // 如果第一位+1 = 10，则需要多补一位
            if (maxStart === 10) {
              maxLength += 1;
            }
            // 获取到最大的整数值
            let maxNumber = maxStart.toString().padEnd(maxLength, "0");
            this.optionColumnar.yAxis[0].max = maxNumber - 0;
            this.optionColumnar.yAxis[0].interval = maxNumber / 4;
          } else {
            this.optionColumnar.yAxis[0].max = 4000;
            this.optionColumnar.yAxis[0].interval = 4000 / 4;
          }
          this.optionColumnar.series[0].data = arr1;
          this.optionColumnar.series[1].data = arr2;
        }
      });
    },
    changeEchartsSize(index) {
      if (index === 12) {
        this.optionColumnar.series[0].barWidth = 12;
        this.optionColumnar.series[1].barWidth = 12;
        this.optionColumnar.xAxis.axisLabel.interval = 0;
        this.optionColumnar.xAxis.axisLabel.rotate = 0;
        this.optionColumnar.grid.bottom = "25px";
      } else if (index === 1) {
        this.optionColumnar.series[0].barWidth = 1;
        this.optionColumnar.series[1].barWidth = 1;
        this.optionColumnar.xAxis.axisLabel.interval = 23;
        this.optionColumnar.xAxis.axisLabel.rotate = 30;
        this.optionColumnar.grid.bottom = "0px";
      } else if (index === 2) {
        this.optionColumnar.series[0].barWidth = 1;
        this.optionColumnar.series[1].barWidth = 1;
        this.optionColumnar.xAxis.axisLabel.interval = 7;
        this.optionColumnar.xAxis.axisLabel.rotate = 50;
        this.optionColumnar.grid.bottom = "20px";
      }
    },
    getElecRate() {
      this.columnarStatus = true;
      getElecRate(this.elecQuery).then((res) => {
        this.columnarStatus = false;
        if (res.data.code === 0) {
          for (let item of res.data.data) {
            if (item.key === "ldj.power") {
              this.optionPie.series[0].data[0].value = item.value
                ? parseFloat(item.value).toFixed(2)
                : 0;
            } else if (item.key === "lqb.power") {
              this.optionPie.series[0].data[1].value = item.value
                ? parseFloat(item.value).toFixed(2)
                : 0;
            } else if (item.key === "ldb.power") {
              this.optionPie.series[0].data[2].value = item.value
                ? parseFloat(item.value).toFixed(2)
                : 0;
            } else if (item.key === "lqt.power") {
              this.optionPie.series[0].data[3].value = item.value
                ? parseFloat(item.value).toFixed(2)
                : 0;
            }
          }
        }
      });
    },
  },
  async mounted() {
    window.onresize = () => {
      if (this.$refs.chartPie) {
        this.$refs.chartPie.resize();
      }
    };
    // 请求全部字典表的数据
    await this.getKeyMap();
    await this.getTotalKeyMap();
    let valueArr = this.pointArr.map((item) => item.value);
    getRealtime(valueArr).then((res) => {
      if (res.data.code === 0) {
        this.pointMap = res.data.data;
        this.leftList[0].value = res.data.data[this.keyValueMap["chw.stemp"]];
        this.leftList[1].value = res.data.data[this.keyValueMap["chw.rtemp"]];
        this.leftList[2].value = res.data.data[this.keyValueMap["cw.stemp"]];
        this.leftList[3].value = res.data.data[this.keyValueMap["cw.rtemp"]];
        this.boilerList[1].value =
          res.data.data[this.keyValueMap["real.time.traffic"]];

        this.loadColumnarData();
      }
    });
    //当日冷量
    getCoolCount().then((res) => {
      if (res.data.code === 0) {
        this.boilerList[0].value = res.data.data.value;
      }
    });
    //瞬时COP
    getInstantCOP().then((res) => {
      if (res.data.code === 0) {
        this.boilerList[2].value = res.data.data.value;
      }
    });
    //累计COP
    getAddCOP().then((res) => {
      if (res.data.code === 0) {
        this.boilerList[3].value = res.data.data.value;
      }
    });
  },
};
</script>

<style lang="less">
.coldSystemJk {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #01051d;
  .first {
    height: 100%;
    width: 100%;
    position: relative;
    .body {
      height: 72%;
      display: flex;
      .body-left {
        width: 18%;
        z-index: 2;
        .status {
          color: #ffffff;
          width: 354px;
          height: 100px;
          background: url("~@/assets/jinke/bg.svg") no-repeat center;
          background-size: 100% 100%;
          margin: 5px 0px 0px -8px;
          display: flex;
          align-items: center;
          .status-img {
            height: 89px;
            margin: 6px 5px;
          }
          .status-name {
            width: 32px;
            height: 46px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 23px;
            margin-left: 47px;
          }
          .status-circle {
            width: 98px;
            height: 98px;
            text-align: center;
            color: #020202;
            margin-left: 8px;
            margin-bottom: 3px;
            &.start {
              background: url("~@/assets/jinke/touyong.webp") no-repeat center;
              background-size: 100% 100%;
            }
            &.stop {
              background: none;
              background-size: 100% 100%;
            }
          }
          .status-icon {
            width: 40px;
            height: 43px;
            &.start {
              background: url("~@/assets/jinke/yindao.webp") no-repeat center;
              background-size: 100% 100%;
            }
            &.stop {
              background: url("~@/assets/jinke/yindao.webp") no-repeat center;
              background-size: 100% 100%;
            }
          }
          > span > span {
            color: #00dbff;
          }
        }
        .left-list {
          display: flex;
          flex-direction: column;
          padding-left: 24px;
          .left-item {
            width: 273px;
            height: 96px;
            margin-top: 4%;
            padding-left: 43px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            &.left-item-0 {
              background: url("~@/assets/jinke/冷1.webp") no-repeat center;
              background-size: 100% 100%;
            }
            &.left-item-1 {
              background: url("~@/assets/jinke/冷2.webp") no-repeat center;
              background-size: 100% 100%;
            }
            &.left-item-2 {
              background: url("~@/assets/jinke/冷3.webp") no-repeat center;
              background-size: 100% 100%;
            }
            &.left-item-3 {
              background: url("~@/assets/jinke/冷4.webp") no-repeat center;
              background-size: 100% 100%;
            }
            .item-val {
              font-size: 22px;
              font-weight: bold;
              color: #00dbff;
              text-shadow: 0px 0px 0px #236aff;
            }
            .item-name {
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
      .body-center {
        flex: 1;
        position: relative;
        .track-img {
          position: absolute;
          width: 200px;
          left: 9%;
          top: 3%;
          z-index: 20;
          cursor: pointer;
          img {
            width: 100%;
          }
          .txt {
            position: absolute;
            z-index: 21;
            color: #fff;
            top: 16px;
            left: 72px;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .item-valp {
          height: 33px;
          background: url("~@/assets/heatingSystem/设备弹框大.png") no-repeat
            center;
          background-size: 100% 100%;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          padding: 2px 8px 0 8px;
          text-align: center;
          &.data-item-width {
            width: 160px;
          }
          > span {
            color: #2ed6ff;
          }
        }
        .lengdongji-1 {
          position: absolute;
          left: 38%;
          top: 45%;
          z-index: 20;
        }
        .lengque-1-txt {
          position: absolute;
          left: 5%;
          top: 35%;
          z-index: 20;
        }
        .lengque-2-txt {
          position: absolute;
          left: 13%;
          top: 25%;
          z-index: 20;
        }
        .lengque-3-txt {
          position: absolute;
          left: 21%;
          top: 15%;
          z-index: 20;
        }
        .lengque-4-txt {
          position: absolute;
          left: 30.6%;
          top: 5%;
          z-index: 20;
        }
        .lengdongji-2 {
          position: absolute;
          left: 49%;
          top: 56%;
          z-index: 20;
        }
        .lengdongji-1-status {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 44.8%;
          top: 50%;
          z-index: 20;
        }
        .lengdongji-2-status {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 55%;
          top: 61.4%;
          z-index: 20;
        }
        .lengquebeng-1-status {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 60.4%;
          top: 27%;
          z-index: 20;
        }
        .lengquebeng-2-status {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 66.4%;
          top: 20%;
          z-index: 20;
        }
        .lengquebeng-3-status {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 72.1%;
          top: 13%;
          z-index: 20;
        }
        .lengdongbeng-1-status {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 72%;
          top: 49%;
          z-index: 20;
        }
        .lengdongbeng-2-status {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 78.4%;
          top: 42.8%;
          z-index: 20;
        }
        .lengdongbeng-3-status {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 84%;
          top: 36%;
          z-index: 20;
        }
        .zonghuishui {
          position: absolute;
          left: 70%;
          top: 58%;
          z-index: 20;
        }
        .zonggongshui {
          position: absolute;
          left: 58%;
          top: 72%;
          z-index: 20;
        }
        .lengquebeng-1-w {
          position: absolute;
          left: 63.2%;
          top: 38%;
          z-index: 20;
        }
        .lengquebeng-2-w {
          position: absolute;
          left: 69.6%;
          top: 31%;
          z-index: 20;
        }
        .lengquebeng-3-w {
          position: absolute;
          left: 75%;
          top: 24%;
          z-index: 20;
        }
        .item6 {
          // flex-direction: column;
          position: absolute;
          display: block;
          left: 9%;
          bottom: 6%;
          .item6-item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            font-size: 14px;
            color: #fff;
            img {
              width: 22px;
              height: 22px;
              margin-right: 5px;
            }
          }
        }
        > img {
          position: absolute;
          flex-shrink: 0;
          top: 0;
          &.left {
            left: 20px;
          }
          &.right {
            right: 20px;
          }
          &.body-bg {
            left: -24%;
            width: 142%;
            height: 140%;
            z-index: 0;
          }
          &.lengque-1 {
            width: 4.2%;
            height: 3.8%;
            left: 7.8%;
            top: 41.4%;
            z-index: 99;
          }
          &.lengque-2 {
            width: 4.2%;
            height: 3.8%;
            left: 15.3%;
            top: 31%;
            z-index: 99;
          }
          &.lengque-3 {
            width: 4.2%;
            height: 3.8%;
            left: 23%;
            top: 20.6%;
            z-index: 99;
          }
          &.lengque-4 {
            width: 4.2%;
            height: 3.8%;
            left: 30.7%;
            top: 10.3%;
            z-index: 99;
          }
        }
      }
      .body-right {
        width: 16%;
        z-index: 2;
        .boiler {
          .boiler-item {
            margin-top: 38px;
            .boiler-title {
              display: flex;
              margin-bottom: 10px;
              .boiler-status {
                width: 20px;
                height: 20px;
                &.boiler-status-0 {
                  background: url("~@/assets/heatingSystemWebp/boiler0.webp");
                  background-size: 100% 100%;
                }
                &.boiler-status-1 {
                  background: url("~@/assets/heatingSystemWebp/boiler1.png");
                  background-size: 100% 100%;
                }
                &.boiler-status-2 {
                  background: url("~@/assets/heatingSystemWebp/boiler2.png");
                  background-size: 100% 100%;
                }
                margin-right: 9px;
              }
              .boiler-name {
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                span {
                  color: #2ed6ff;
                }
              }
            }
            .boiler-body {
              display: flex;
              > img {
                width: 86px;
                height: auto;
              }
              .boiler-num {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .boiler-name {
                  font-size: 16px;
                  color: #fff;
                }
                .boiler-change {
                  font-size: 14px;
                  color: #ff8f00;
                }
                .boiler-value {
                  font-size: 26px;
                  font-weight: bold;
                  color: #00dbff;
                  text-shadow: 0px 0px 0px #236aff;
                }
                .boiler-unit {
                  font-size: 14px;
                  font-weight: 400;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
    .bgWebp {
      display: none;
    }
    .foot {
      height: 28%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: flex;
      > div {
        background: #031029;
        // box-shadow: 2px -2px 10px 2px #001358;
        border: 1px solid #3b75ffb3;
      }
      .columnar {
        flex: 1;
      }
      .month {
        flex: 1;
      }
      .columnar {
        .heat-title {
          display: flex;
          justify-content: space-between;
          padding-right: 20px;
        }
        .columnar-title {
          display: flex;
          border-radius: 4px;
          .columnar-title-item {
            height: 32px;
            padding: 0 10px;
            line-height: 32px;
            text-align: center;
            border-radius: 0px;
            border: 1px solid #3f6df8;
            font-size: 14px;
            cursor: pointer;
            &.active {
              background-color: #3f6df8;
            }
          }
        }
        .chartPart {
          display: flex;
          .left {
            width: 36%;
            flex-shrink: 0;
            position: relative;
            display: flex;
            .chart {
              width: 74%;
              height: 100%;
              flex-shrink: 0;
            }
            .imgC {
              margin-left: 10px;
              width: 26%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .img {
                height: 95%;
                width: 100%;
                position: relative;
                .bg {
                  width: 100%;
                  height: 100%;
                }
                .icon {
                  position: absolute;
                  top: 10px;
                  width: 25%;
                  left: 20%;
                  top: 10%;
                }
                .name {
                  position: absolute;
                  top: 10px;
                  width: 80%;
                  left: 20%;
                  top: 38%;
                  color: #fff;
                  font-size: 14px;
                }
                .data {
                  position: absolute;
                  width: 80%;
                  left: 20%;
                  top: 50%;
                  font-family: DINAlternate-Bold, DINAlternate;
                  font-weight: bold;
                  color: #00dbff;
                  font-size: 21px;
                  text-shadow: 0px 0px 0px #236aff;
                }
                .unit {
                  position: absolute;
                  width: 26%;
                  left: 20%;
                  top: 68%;
                  color: #fff;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .month-body {
        display: flex;
        flex-direction: column;
        height: calc(100% - 32px);
        justify-content: space-around;
        .month-item {
          height: 46%;
          width: 100%;
          background: linear-gradient(
            100deg,
            rgba(44, 119, 242, 0.18) 0%,
            rgb(1, 8, 35, 0.31) 100%
          );
          box-shadow: 0px 2px 4px 0px rgb(0, 0, 0, 0.31);
          max-height: 110px;
          display: flex;
          align-items: center;
          .item-box-center {
            width: 90%;
            height: 90%;
            background: linear-gradient(
              90deg,
              #00030c 0%,
              rgba(0, 0, 0, 0.69) 100%
            );
            padding: 5px;
            text-align: center;
            .item-box-center-img {
              width: 45px;
              height: 45px;
              background: linear-gradient(
                268deg,
                #c58608 0%,
                rgba(202, 140, 0, 0.43) 100%
              );
              box-shadow: 0px 2px 4px 0px #000000;
              font-size: 14px;
              font-weight: 500;
              color: #ffffff;
              text-shadow: 0px 2px 4px #000000;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .item-box-center-val {
              font-size: 18px;
              font-weight: bold;
              color: #00dbff;
              text-shadow: 0px 2px 4px #000000, 0px 0px 0px #236aff;
              &.top {
                color: #fbbb18;
              }
            }
          }
        }
      }
      .item-circle {
        display: flex;
        color: #fff;
        flex: 1;
        .circle-box {
          width: 72px;
          height: 72px;
          background: url("~@/assets/heatingSystemWebp/能耗圆底.webp");
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          .circle-box-name {
            font-size: 16px;
            font-weight: 800;
            color: #ffffff;
            text-shadow: 0px 2px 4px #000000;
          }
          .circle-box-val {
            font-size: 12px;
            color: #ffffff;
            text-shadow: 0px 2px 4px #000000;
          }
        }
        .circle-right {
          display: flex;
          justify-content: center;
          flex-direction: column;
          // align-items: center;
        }
        .circle-val {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
          text-shadow: 0px 2px 4px #000000, 0px 0px 0px #236aff;
        }
        .circle-name {
          font-size: 12px;
          color: #ffffff;
          line-height: 20px;
          text-shadow: 0px 2px 4px #000000;
        }
      }
      .month-item {
        .item-box {
          width: 25%;
          max-width: 130px;
          height: 100%;
          border: 1px solid #3b76ff81;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          &:before,
          &:after {
            content: " ";
            display: block;
            width: 7px;
            height: 100%;
            border-top: 2px solid #1dfff5;
            border-bottom: 2px solid #1dfff5;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            top: 0;
          }
          &:after {
            right: 0;
            left: auto;
          }
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
    .columnar-searchTime {
      margin-right: 0px;
      .el-input__inner {
        background: transparent;
        color: #fff;
      }
    }
  }
  .second {
    height: 100%;
    width: 100%;
    position: relative;
  }
}
</style>
<style>
.heat-title {
  width: 100%;
  height: 32px;
  line-height: 30px;
  padding-left: 35px;
  background: url("~@/assets/heatingSystem/标题底纹理.png") no-repeat left,
    linear-gradient(90deg, #2c50bf 0%, #2c50bf 0%, rgba(11, 53, 182, 0) 100%);
  background-size: auto 100%;
  opacity: 1;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #8ba7fa, rgba(236, 6, 37, 0) 50%) 10 0;
}
.coldSystemJk .el-loading-mask {
  background-color: #1f386566;
}
</style>
