<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 420px;
      "
    >
      <div class="body1" style="padding: 18px">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <div
            style="
              width: 4px;
              height: 20px;
              background: #3f6df8;
              margin-right: 10px;
            "
          ></div>
          <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
            用能同比/环比分析
          </div>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickTime(1)"
              :class="{ activeNH: isActivenTime === 1 }"
            >
              日
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickTime(2)"
              :class="{ activeNH: isActivenTime === 2 }"
            >
              月
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickTime(3)"
              :class="{ activeNH: isActivenTime === 3 }"
            >
              年
            </div>
            <div style="margin-left: 14px">
              <el-date-picker
                v-model="value1"
                :type="changeDateType"
                placeholder="选择日期"
                value-format="yyyy-MM-dd 00:00:00"
                @change="searchChart1"
              >
              </el-date-picker>
            </div>
          </div>
          <div style="">
            <div
              class="square2-category"
              :class="{ activeNH: isActivenNH === index + 1 }"
              v-for="(item, index) in dataEnergyArray"
              :key="index"
              @click="clickNH(index + 1, item)"
            >
              {{ item.energyTypeName }}
            </div>

            <!-- <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickNH(1)"
              :class="{ activeNH: isActivenNH === 1 }"
            >
              电
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickNH(2)"
              :class="{ activeNH: isActivenNH === 2 }"
            >
              水
            </div>
            <div
              class="square2-category"
              @click="clickNH(3)"
              :class="{ activeNH: isActivenNH === 3 }"
            >
              气
            </div>
            <div
              class="square2-category"
              @click="clickNH(4)"
              :class="{ activeNH: isActivenNH === 4 }"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
            >
              蒸汽
            </div> -->

            <!-- <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickNH(5)"
              :class="{ activeNH: isActivenNH === 5 }"
            >
              冷
            </div> -->
          </div>
        </div>

        <div style="width: 100%; text-align: center; margin-top: 20px">
          <span style="color: #082254; font-size: 16px; font-weight: bold"
            >{{ value1.split(" ")[0] }}用{{
              selectEnergy[isActivenNH - 1]
            }}情况分析</span
          ><span style="color: #082254; font-size: 14px">{{
            selecctUnit[isActivenNH - 1]
          }}</span>
        </div>

        <div style="width: 100%; display: flex; margin-top: 15px; height: 21px">
          <div
            style="
              width: 50%;
              height: 100%;
              text-align: center;
              position: relative;
            "
          >
            同比：
            <template v-if="isAdd1"
              ><img
                src="@/assets/指数上升.png"
                style="vertical-align: middle"
              />+{{ Percent1 }}%</template
            >
            <template v-if="isLess1"
              ><img
                src="@/assets/指数下降.png"
                style="vertical-align: middle"
              />-{{ Percent1 }}%</template
            >
            <template v-if="isZero1"> 0% </template>
            <template v-if="isNull1"> -- </template>
            <div
              style="
                font-size: 14px;
                color: #082254;
                position: absolute;
                left: 20px;
                top: 5px;
              "
            >
              {{ selecctUnit[isActivenNH] }}
            </div>
          </div>
          <div
            style="
              width: 50%;
              height: 100%;
              text-align: center;
              position: relative;
            "
          >
            环比：
            <template v-if="isAdd2"
              ><img
                src="@/assets/指数上升.png"
                style="vertical-align: middle"
              />+{{ Percent2 }}%</template
            >
            <template v-if="isLess2"
              ><img
                src="@/assets/指数下降.png"
                style="vertical-align: middle"
              />-{{ Percent2 }}%</template
            >
            <template v-if="isZero2"> 0% </template>
            <template v-if="isNull2"> -- </template>
            <div
              style="
                font-size: 14px;
                color: #082254;
                position: absolute;
                left: 20px;
                top: 5px;
              "
            >
              {{ selecctUnit[isActivenNH] }}
            </div>
          </div>
        </div>

        <div
          style="width: 100%; height: 232px; display: flex; margin-top: 15px"
        >
          <div style="width: 50%; height: 100%">
            <v-chart ref="chart1" :option="chartBarOption1" />
          </div>
          <div style="width: 50%; height: 100%">
            <v-chart ref="chart2" :option="chartBarOption2" />
          </div>
        </div>
      </div>

      <div class="body2" style="padding: 18px">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <div
            style="
              width: 4px;
              height: 20px;
              background: #3f6df8;
              margin-right: 10px;
            "
          ></div>
          <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
            能耗监测与预测
          </div>
        </div>

        <div
          style="
            width: 100%;
            text-align: center;
            margin-top: 20px;
            position: relative;
          "
        >
          <span style="color: #082254; font-size: 16px; font-weight: bold"
            >{{ value3.split(" ")[0] }}用{{
              selectEnergy2[isActivenNH2 - 1]
            }}情况分析</span
          ><span style="color: #082254; font-size: 14px">{{
            selecctUnit2[isActivenNH2 - 1]
          }}</span>

          <div style="position: absolute; top: 0; right: 0">
            <div
              class="square2-category"
              :class="{ activeNH: isActivenNH2 === index + 1 }"
              v-for="(item, index) in dataEnergyArray"
              :key="index"
              @click="clickNH2(index + 1, item)"
            >
              {{ item.energyTypeName }}
            </div>
            <!-- <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickNH2(1)"
              :class="{ activeNH: isActivenNH2 === 1 }"
            >
              电
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickNH2(2)"
              :class="{ activeNH: isActivenNH2 === 2 }"
            >
              水
            </div>
            <div
              class="square2-category"
              @click="clickNH2(3)"
              :class="{ activeNH: isActivenNH2 === 3 }"
            >
              气
            </div>
            <div
              class="square2-category"
              @click="clickNH2(4)"
              :class="{ activeNH: isActivenNH2 === 4 }"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
            >
              蒸汽
            </div> -->
            <!-- <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickNH2(5)"
              :class="{ activeNH: isActivenNH2 === 5 }"
            >
              冷
            </div> -->
          </div>
        </div>

        <div style="width: 100%; height: 314px; margin-top: 20px">
          <v-chart ref="chart3" :option="chartMultiOption" />
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        background: white;
        height: 393px;
        margin-top: 20px;
        padding: 20px;
        padding-bottom: 0;
        padding-top: 10px;
      "
    >
      <div
        style="
          height: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        "
      >
        <div
          style="
            width: 4px;
            height: 20px;
            background: #3f6df8;
            margin-right: 10px;
          "
        ></div>
        <div style="font-size: 16px; font-weight: bold; color: #3f6df8">
          用能数据查询
        </div>
      </div>

      <div
        style="
          width: 100%;
          height: 29px;
          line-height: 29px;
          text-align: center;
          position: relative;
          margin-top: 20px;
        "
      >
        <span style="color: #082254; font-size: 16px; font-weight: bold"
          >{{ timeSpan }}用{{ selectEnergy3[isActivenNH3 - 1] }}情况分析</span
        ><span style="color: #082254; font-size: 14px">{{
          selecctUnit3[isActivenNH3 - 1]
        }}</span>

        <div style="position: absolute; left: 0; top: 0">
          <div
            class="square2-category"
            style="border-radius: 2px 0 0 2px"
            @click="clickTime2(1)"
            :class="{ activeNH: isActivenTime2 === 1 }"
          >
            日
          </div>
          <div
            class="square2-category"
            style=""
            @click="clickTime2(2)"
            :class="{ activeNH: isActivenTime2 === 2 }"
          >
            月
          </div>
          <div
            class="square2-category"
            style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
            @click="clickTime2(3)"
            :class="{ activeNH: isActivenTime2 === 3 }"
          >
            年
          </div>
          <div style="margin-left: 14px; display: inline-block">
            <el-date-picker
              v-model="value2"
              :type="changeDateType2"
              placeholder="选择日期"
              value-format="yyyy-MM-dd 00:00:00"
              @change="searchChart2"
            >
            </el-date-picker>
          </div>
        </div>

        <div style="position: absolute; right: 0; top: 0">
          <div
            class="square2-category"
            :class="{ activeNH: isActivenNH3 === index + 1 }"
            v-for="(item, index) in dataEnergyArray"
            :key="index"
            @click="clickNH3(index + 1, item)"
          >
            {{ item.energyTypeName }}
          </div>
          <!-- <div
            class="square2-category"
            style="border-radius: 2px 0 0 2px"
            @click="clickNH3(1)"
            :class="{ activeNH: isActivenNH3 === 1 }"
          >
            电
          </div>
          <div
            class="square2-category"
            style=""
            @click="clickNH3(2)"
            :class="{ activeNH: isActivenNH3 === 2 }"
          >
            水
          </div>
          <div
            class="square2-category"
            @click="clickNH3(3)"
            :class="{ activeNH: isActivenNH3 === 3 }"
          >
            气
          </div> -->
          <!-- <div
            class="square2-category"
            @click="clickNH3(4)"
            :class="{ activeNH: isActivenNH3 === 4 }"
          >
            热
          </div> -->
          <!-- <div
            class="square2-category"
            style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
            @click="clickNH3(4)"
            :class="{ activeNH: isActivenNH3 === 5 }"
          >
            蒸汽
          </div> -->
        </div>
      </div>

      <div style="width: 100%; height: 280px; margin-top: 20px">
        <v-chart ref="chart4" :option="chartBarOptionBig" />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  // getProjects,
  setProject,
  getShowTypes,
  useEnergyCompare,
  useEnergyForecast,
  useEnergySearch,
} from "@/request/apiLg";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    // var dataAxis = ["当月", "上月"];
    // var dataAxis2 = [
    //   "01",
    //   "02",
    //   "03",
    //   "04",
    //   "05",
    //   "06",
    //   "07",
    //   "08",
    //   "09",
    //   "10",
    //   "11",
    //   "12",
    // ];
    // var data = [
    //   400,
    //   {
    //     value: 550,
    //     itemStyle: {
    //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         { offset: 0, color: "#55ffbc" },
    //         { offset: 0.62, color: "#47A5E1" },
    //         { offset: 1, color: "#3F6DF8" },
    //       ]),
    //       borderRadius: 15,
    //       opacity: 0.6,
    //     },
    //   },
    // ];
    // var data2 = [400, 600, 500, 300, 400, 600, 500, 300, 400, 600, 500, 300];
    // var data3 = [400, 600, 500, 300, 400, 600, 500, 300, 400, 600, 500, 300];
    // var colorList_data_2 = [
    //   {
    //     c1: "#6DCB86", //实践
    //     c2: "#6DCB86",
    //     c3: "#6DCB86",
    //   },
    //   {
    //     c1: "#E97F75", //操作
    //     c2: "#EA9B7A",
    //     c3: "#F6B084",
    //   },
    //   {
    //     c1: "#8C44E1", //操作
    //     c2: "#AD6BF1",
    //     c3: "#B97DF9",
    //   },
    //   {
    //     c1: "#277CFE",
    //     c2: "#6BCDFF", //操作
    //   },
    // ];
    return {
      // timeSpan:'',
      dataEnergyArray: [],
      value22: "",
      dataAxis3: [],
      dataValue31: [],
      dataValue32: [],
      datalegend1: ["总用电量", "预测总用电量", "温度"],
      datalegend3: ["当日用能", "上日用能"],
      value3: "",
      energyId: "elec",
      energyId2: "elec",
      energyId3: "elec",
      isAdd1: false,
      isLess1: false,
      isZero1: false,
      isNull1: false,

      isAdd2: false,
      isLess2: false,
      isZero2: false,
      isNull2: false,

      dataAxis1: [],
      dataAxis2: [],
      Percent1: "",
      Percent2: "",
      dataValue1: [],
      dataValue2: [],

      dataValue21: [],
      dataValue22: [],
      dataValue23: [],
      changeDateType: "date",
      changeDateType2: "date",
      selectEnergy: ["电", "水", "气", "蒸汽"],
      selecctUnit: ["(kWh)", "(m3)", "(m3)", "(T)"],
      selectEnergy2: ["电", "水", "气", "蒸汽"],
      selecctUnit2: ["(kWh)", "(m3)", "(m3)", "(T)"],
      selectEnergy3: ["电", "水", "气", "蒸汽"],
      selecctUnit3: ["(kWh)", "(m3)", "(m3)", "(T)"],
      value1: "",
      value2: "",
      isActivenNH3: 1,
      isActivenNH2: 1,
      isActivenNH: 1,
      isActivenTime: 1,
      isActivenTime2: 1,
      // chartBarOptionBig: {

      // },
      // chartMultiOption: {},
      // chartBarOption1: {

      // },
      // chartBarOption2: {

      // },
    };
  },
  methods: {
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    clickTime(index) {
      this.isActivenTime = index;

      if (index == 1) {
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD + " 00:00:00";
        this.changeDateType = "date";
        let data = {
          endTime: "2021-05-09 00:00:00",
          energyTypeId: this.energyId,
          interval: 1,
          projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
          startTime: "2021-05-01 00:00:00",
          timeUnit: "DAYS",
        };
        data.startTime = this.value1;
        data.endTime = this.value1;
        this.onSearch(data);
      } else if (index == 2) {
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD + " 00:00:00";
        this.changeDateType = "month";
        let data = {
          endTime: "2021-05-09 00:00:00",
          energyTypeId: this.energyId,
          interval: 1,
          projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
          startTime: "2021-05-01 00:00:00",
          timeUnit: "MONTHS",
        };
        data.startTime = this.value1;
        data.endTime = this.value1;
        this.onSearch(data);
      } else if (index == 3) {
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD + " 00:00:00";
        this.changeDateType = "year";
        let data = {
          endTime: "2021-05-09 00:00:00",
          energyTypeId: this.energyId,
          interval: 1,
          projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
          startTime: "2021-05-01 00:00:00",
          timeUnit: "YEARS",
        };
        data.startTime = this.value1;
        data.endTime = this.value1;
        this.onSearch(data);
      }
    },
    clickTime2(index) {
      this.isActivenTime2 = index;

      if (index == 1) {
        this.datalegend3 = ["当日用能", "上日用能"];
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value2 = YY + MM + DD + " 00:00:00";
        this.changeDateType2 = "date";

        let data = {
          endTime: "2021-05-09 00:00:00",
          energyTypeId: this.energyId,
          interval: 5,
          projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
          startTime: "2021-05-01 00:00:00",
          timeUnit: "MINUTES",
        };
        let dateTT = new Date(this.value2);
        dateTT = dateTT.setDate(dateTT.getDate() + 1);
        dateTT = this.formatDate(dateTT);
        data.startTime = this.value2;
        data.endTime = dateTT;
        this.value22 = dateTT;
        data.startTime = this.value2;
        data.endTime = dateTT;
        this.onSearch3(data);
      } else if (index == 2) {
        this.datalegend3 = ["当月用能", "上月用能"];
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value2 = YY + MM + DD + " 00:00:00";
        this.changeDateType2 = "month";

        let data = {
          endTime: "2021-05-09 00:00:00",
          energyTypeId: this.energyId,
          interval: 1,
          projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
          startTime: "2021-05-01 00:00:00",
          timeUnit: "DAYS",
        };
        let dateTT = new Date(this.value2);
        dateTT = dateTT.setMonth(dateTT.getMonth() + 1);
        dateTT = this.formatDate(dateTT);
        data.startTime = this.value2;
        data.endTime = dateTT;
        this.value22 = dateTT;
        data.startTime = this.value2;
        data.endTime = dateTT;
        this.onSearch3(data);
      } else if (index == 3) {
        this.datalegend3 = ["当年用能", "上年用能"];
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value2 = YY + MM + DD + " 00:00:00";
        this.changeDateType2 = "year";

        let data = {
          endTime: "2021-05-09 00:00:00",
          energyTypeId: this.energyId,
          interval: 1,
          projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
          startTime: "2021-05-01 00:00:00",
          timeUnit: "MONTHS",
        };
        let dateTT = new Date(this.value2);
        dateTT = dateTT.setFullYear(dateTT.getFullYear() + 1);
        dateTT = this.formatDate(dateTT);
        data.startTime = this.value2;
        data.endTime = dateTT;
        this.value22 = dateTT;
        data.startTime = this.value2;
        data.endTime = dateTT;
        this.onSearch3(data);
      }
    },
    clickNH(index, v) {
      this.isActivenNH = index;
      // if (index === 1) {
      //   this.energyId = "elec";
      // } else if (index === 2) {
      //   this.energyId = "water";
      // } else if (index === 3) {
      //   this.energyId = "gas";
      // } else if (index === 4) {
      //   this.energyId = "heat";
      // }
      this.energyId = v.energyTypeId;
      let data = {
        endTime: "2021-05-09 00:00:00",
        energyTypeId: this.energyId,
        interval: 1,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
        startTime: "2021-05-01 00:00:00",
        timeUnit: "YEARS",
      };
      data.startTime = this.value1;
      data.endTime = this.value1;
      this.onSearch(data);
    },
    clickNH2(index, v) {
      this.isActivenNH2 = index;
      if (index === 1) {
        // this.energyId2 = "elec";
        this.datalegend1 = ["总用电量", "预测总用电量", "温度"];
      } else if (index === 2) {
        // this.energyId2 = "water";
        this.datalegend1 = ["总用水量", "预测总用水量", "温度"];
      } else if (index === 3) {
        // this.energyId2 = "gas";
        this.datalegend1 = ["总用气量", "预测总用气量", "温度"];
      } else if (index === 4) {
        // this.energyId2 = "heat";
        this.datalegend1 = ["总用蒸汽量", "预测总用蒸汽量", "温度"];
      }
      this.energyId2 = v.energyTypeId;
      this.dataValue21 = [];
      this.dataValue22 = [];
      this.dataValue23 = [];
      // this.dataValue21 = temp1;
      let data2 = {
        energyTypeId: this.energyId2,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
      };
      useEnergyForecast(data2).then((res) => {
        console.log(res.data.data);
        let temp1 = [];
        let temp2 = [];
        let temp3 = [];
        let temp4 = [];
        res.data.data.first.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.first[index1].formatValue = "";
          }
          temp1.push(item1.formatValue);
        });
        res.data.data.last.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.last[index1].formatValue = "";
            temp4.push(1);
          }
          temp2.push(item1.formatValue);
        });
        res.data.data.temp.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.temp[index1].formatValue = "";
          }
          temp3.push(item1.formatValue);
        });
        console.log("temp1", temp1);
        this.dataValue21 = temp1;

        this.dataValue22 = temp2;
        if (temp4.length === 24) {
          this.dataValue22 = [];
        }
        this.dataValue23 = temp3;
      });
    },
    clickNH3(index, v) {
      this.isActivenNH3 = index;
      if (index === 1) {
        this.energyId3 = "elec";
      } else if (index === 2) {
        this.energyId3 = "water";
      } else if (index === 3) {
        this.energyId3 = "gas";
      } else if (index === 4) {
        this.energyId3 = "heat";
      }
      this.energyId3 = v.energyTypeId;
      let data = {
        endTime: this.value22,
        energyTypeId: this.energyId3,
        interval: 1,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
        startTime: this.value2,
        timeUnit: "HOURS",
      };
      if(this.isActivenTime2===1){
        data.timeUnit="HOURS"
      }
      if(this.isActivenTime2===2){
        data.timeUnit="DAYS"
      }
      if(this.isActivenTime2===3){
        data.timeUnit="MONTHS"
      }
      this.onSearch3(data);
    },
    onSearch3(data) {
      this.dataAxis3 = [];
      this.dataValue31 = [];
      this.dataValue32 = [];
      useEnergySearch(data).then((res) => {
        console.log(res);
        let temp3 = [];
        let temp4 = [];
        let temp5 = [];
        if (this.isActivenTime2 === 1) {
          res.data.data.first.forEach((item) => {
            temp3.push(
              this.formatDate(item.timestamp).split(" ")[1].split(":")[0] +
                ":" +
                this.formatDate(item.timestamp).split(" ")[1].split(":")[1]
            );
            temp4.push(item.formatValue);
          });
          res.data.data.last.forEach((item) => {
            // temp3.push(this.formatDate(item.timestamp).split(" ")[1])
            temp5.push(item.formatValue);
          });
          this.dataAxis3 = temp3;
          this.dataValue31 = temp4;
          this.dataValue32 = temp5;
        } else if (this.isActivenTime2 === 2) {
          res.data.data.first.forEach((item) => {
            let temp = this.formatDate(item.timestamp).split(" ")[0];
            // temp.split("-")[2]
            temp3.push(temp.split("-")[2]);
            temp4.push(item.formatValue);
          });
          res.data.data.last.forEach((item) => {
            // temp3.push(this.formatDate(item.timestamp).split(" ")[1])
            temp5.push(item.formatValue);
          });
          this.dataAxis3 = temp3;
          this.dataValue31 = temp4;
          this.dataValue32 = temp5;
        } else if (this.isActivenTime2 === 3) {
          res.data.data.first.forEach((item) => {
            let temp = this.formatDate(item.timestamp).split(" ")[0];
            let temp2 = temp.split("-")[1];
            temp3.push(temp2);
            temp4.push(item.formatValue);
          });
          res.data.data.last.forEach((item) => {
            // temp3.push(this.formatDate(item.timestamp).split(" ")[1])
            temp5.push(item.formatValue);
          });
          this.dataAxis3 = temp3;
          this.dataValue31 = temp4;
          this.dataValue32 = temp5;
        }
      });
    },
    searchChart1(v) {
      // debugger
      // let params3 = new Date(v).getTime();
      let data = {
        endTime: "2021-05-09 00:00:00",
        energyTypeId: this.energyId,
        interval: 1,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
        startTime: "2021-05-01 00:00:00",
        timeUnit: "DAYS",
      };
      data.startTime = v;
      data.endTime = v;
      if (this.isActivenTime == 1) {
        data.timeUnit = "DAYS";
      } else if (this.isActivenTime == 2) {
        data.timeUnit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        data.timeUnit = "YEARS";
      }
      this.onSearch(data);
    },
    searchChart2(v) {
      // debugger
      // let params3 = new Date(v).getTime();
      let data3 = {
        endTime: "2021-05-09 00:00:00",
        energyTypeId: this.energyId,
        interval: 1,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
        startTime: "2021-05-01 00:00:00",
        timeUnit: "HOURS",
      };
      data3.startTime = v;

      if (this.isActivenTime2 == 1) {
        data3.timeUnit = "MINUTES";
        data3.interval = 5;
        let dateTT = new Date(v);
        dateTT = dateTT.setDate(dateTT.getDate() + 1);
        dateTT = this.formatDate(dateTT);
        data3.endTime = dateTT;
        this.value22 = dateTT;
      } else if (this.isActivenTime2 == 2) {
        data3.timeUnit = "DAYS";
        let dateTT = new Date(v);
        dateTT = dateTT.setMonth(dateTT.getMonth() + 1);
        dateTT = this.formatDate(dateTT);
        data3.endTime = dateTT;
        this.value22 = dateTT;
      } else if (this.isActivenTime2 == 3) {
        data3.timeUnit = "MONTHS";
        let dateTT = new Date(v);
        dateTT = dateTT.setFullYear(dateTT.getFullYear() + 1);
        dateTT = this.formatDate(dateTT);
        data3.endTime = dateTT;
        this.value22 = dateTT;
      }
      this.onSearch3(data3);
    },
    onSearch(data) {
      // let data = {
      //   endTime: "2021-05-09 00:00:00",
      //   energyTypeId: this.energyId,
      //   interval: 1,
      //   projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
      //   startTime: "2021-05-01 00:00:00",
      //   timeUnit: "DAYS",
      // };
      // data.startTime = this.value1;
      // data.endTime = this.value1;
      useEnergyCompare(data).then((res) => {
        console.log(res);
        // res.data.data.first.forEach((item1) => {
        //   this.dataAxis1.push(this.formatDate(item1.timestamp).split(" ")[0]);

        // });
        // res.data.data.last.forEach((item2) => {
        //   this.dataAxis2.push(this.formatDate(item2.timestamp).split(" ")[0]);
        // });
        this.dataAxis1 = [
          this.formatDate(res.data.data.first[0].timestamp).split(" ")[0],
          this.formatDate(res.data.data.first[1].timestamp).split(" ")[0],
        ];
        this.dataAxis2 = [
          this.formatDate(res.data.data.last[0].timestamp).split(" ")[0],
          this.formatDate(res.data.data.last[1].timestamp).split(" ")[0],
        ];

        if (res.data.data.first[1].formatValue === null) {
          res.data.data.first[1].formatValue = "";
        }
        if (res.data.data.first[0].formatValue === null) {
          res.data.data.first[0].formatValue = "";
        }

        if (
          res.data.data.first[1].formatValue === "" &&
          res.data.data.first[0].formatValue === ""
        ) {
          this.isAdd1 = false;
          this.isLess1 = false;
          this.isZero1 = false;
          this.isNull1 = true;
        } else if (
          res.data.data.first[0].formatValue === "0" ||
          res.data.data.first[0].formatValue === ""
        ) {
          this.isAdd1 = false;
          this.isLess1 = false;
          this.isZero1 = false;
          this.isNull1 = true;
        }
        // Number(str)
        else if (
          Number(res.data.data.first[1].formatValue) ===
          Number(res.data.data.first[0].formatValue)
        ) {
          this.isAdd1 = false;
          this.isLess1 = false;
          this.isZero1 = true;
          this.isNull1 = false;
        } else if (
          Number(res.data.data.first[1].formatValue) >
          Number(res.data.data.first[0].formatValue)
        ) {
          this.isAdd1 = true;
          this.isLess1 = false;
          this.isZero1 = false;
          this.isNull1 = false;
          this.Percent1 = (
            ((Number(res.data.data.first[1].formatValue) -
              Number(res.data.data.first[0].formatValue)) /
              Number(res.data.data.first[0].formatValue)) *
            100
          ).toFixed(1);
        } else if (
          Number(res.data.data.first[1].formatValue) <
          Number(res.data.data.first[0].formatValue)
        ) {
          this.isAdd1 = false;
          this.isLess1 = true;
          this.isZero1 = false;
          this.isNull1 = false;
          this.Percent1 = (
            ((Number(res.data.data.first[0].formatValue) -
              Number(res.data.data.first[1].formatValue)) /
              Number(res.data.data.first[0].formatValue)) *
            100
          ).toFixed(1);
        }
        this.dataValue1 = [
          {
            value: res.data.data.first[0].formatValue,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
              opacity: 0.6,
            },
          },
          res.data.data.first[1].formatValue,
        ];

        if (res.data.data.last[1].formatValue === null) {
          res.data.data.last[1].formatValue = "";
        }
        if (res.data.data.last[0].formatValue === null) {
          res.data.data.last[0].formatValue = "";
        }

        if (
          res.data.data.last[1].formatValue === "" &&
          res.data.data.last[0].formatValue === ""
        ) {
          this.isAdd2 = false;
          this.isLess2 = false;
          this.isZero2 = false;
          this.isNull2 = true;
        } else if (
          res.data.data.last[0].formatValue === "0" ||
          res.data.data.last[0].formatValue === ""
        ) {
          this.isAdd2 = false;
          this.isLess2 = false;
          this.isZero2 = false;
          this.isNull2 = true;
        }
        // Number(str)
        else if (
          Number(res.data.data.last[1].formatValue) ===
          Number(res.data.data.last[0].formatValue)
        ) {
          this.isAdd2 = false;
          this.isLess2 = false;
          this.isZero2 = true;
          this.isNull2 = false;
        } else if (
          Number(res.data.data.last[1].formatValue) >
          Number(res.data.data.last[0].formatValue)
        ) {
          this.isAdd2 = true;
          this.isLess2 = false;
          this.isZero2 = false;
          this.isNull2 = false;
          this.Percent2 = (
            ((Number(res.data.data.last[1].formatValue) -
              Number(res.data.data.last[0].formatValue)) /
              Number(res.data.data.last[0].formatValue)) *
            100
          ).toFixed(1);
        } else if (
          Number(res.data.data.last[1].formatValue) <
          Number(res.data.data.last[0].formatValue)
        ) {
          this.isAdd2 = false;
          this.isLess2 = true;
          this.isZero2 = false;
          this.isNull2 = false;
          this.Percent2 = (
            ((Number(res.data.data.last[0].formatValue) -
              Number(res.data.data.last[1].formatValue)) /
              Number(res.data.data.last[0].formatValue)) *
            100
          ).toFixed(1);
        }
        this.dataValue2 = [
          {
            value: res.data.data.last[0].formatValue,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
              opacity: 0.6,
            },
          },
          res.data.data.last[1].formatValue,
        ];
      });
    },
    ...mapMutations(["mutationsChangeId"]),
  },
  mounted() {
    let dateT = new Date();
    var YY = dateT.getFullYear() + "-";
    var MM =
      (dateT.getMonth() + 1 < 10
        ? "0" + (dateT.getMonth() + 1)
        : dateT.getMonth() + 1) + "-";
    var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    this.value1 = YY + MM + DD + " 00:00:00";
    this.value2 = YY + MM + DD + " 00:00:00";
    this.value3 = YY + MM + DD + " 00:00:00";

    // getProjects().then((res1) => {
    //   if (res1.data) {
    // this.dataOption1 = res1.data.data;
    // this.projectName = res1.data.data[0].projectName;
    // this.mutationsChangeId(res1.data.data[0].projectId);
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getShowTypes().then((res) => {
            console.log(res);
            this.dataEnergyArray = res.data.data;
            if (res.data.data.length === 0) {
              this.$message.error("能源种类为空");
              return;
            }
            this.energyId = this.dataEnergyArray[0].energyTypeId;
            let data = {
              endTime: "2021-05-09 00:00:00",
              energyTypeId: this.energyId,
              interval: 1,
              projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
              startTime: "2021-05-01 00:00:00",
              timeUnit: "DAYS",
            };
            data.startTime = this.value1;
            data.endTime = this.value1;
            this.onSearch(data);
            let data3 = {
              endTime: "2021-05-09 00:00:00",
              energyTypeId: this.energyId,
              interval: 5,
              projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
              startTime: "2021-05-01 00:00:00",
              timeUnit: "MINUTES",
            };
            // data3.startTime = this.value2;
            // data3.endTime = this.value2;
            let dateT = new Date(this.value2);
            dateT = dateT.setDate(dateT.getDate() + 1);
            dateT = this.formatDate(dateT);
            data3.startTime = this.value2;
            data3.endTime = dateT;
            this.value22 = dateT;
            this.onSearch3(data3);
            let data2 = {
              energyTypeId: this.energyId2,
              projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
            };
            useEnergyForecast(data2).then((res) => {
              console.log(res.data.data);
              let temp1 = [];
              let temp2 = [];
              let temp3 = [];
              let temp4 = [];
              res.data.data.first.forEach((item1, index1) => {
                if (item1.formatValue === null) {
                  res.data.data.first[index1].formatValue = "";
                }
                temp1.push(item1.formatValue);
              });
              res.data.data.last.forEach((item1, index1) => {
                if (item1.formatValue === null) {
                  res.data.data.last[index1].formatValue = "";
                  temp4.push(1);
                }
                temp2.push(item1.formatValue);
              });
              res.data.data.temp.forEach((item1, index1) => {
                if (item1.formatValue === null) {
                  res.data.data.first[index1].formatValue = "";
                }
                temp3.push(item1.formatValue);
              });
              this.dataValue21 = temp1;
              this.dataValue22 = temp2;
              if (temp4.length === 24) {
                this.dataValue22 = [];
              }
              this.dataValue23 = temp3;
            });
          });
        }
      }
    );

    //   } else {
    //     this.$message.error(res1.data.msg);
    //   }
    // });
  },
  watch: {
    currentProjectId(val) {
      // debugger
      console.log(val);
      setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
        (res2) => {
          if (res2.data.code === 0) {
            getShowTypes().then((res) => {
              console.log(res);
              this.dataEnergyArray = res.data.data;
              this.energyId = this.dataEnergyArray[0].energyTypeId;
              let data = {
                endTime: "2021-05-09 00:00:00",
                energyTypeId: this.energyId,
                interval: 1,
                projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
                startTime: "2021-05-01 00:00:00",
                timeUnit: "DAYS",
              };
              data.startTime = this.value1;
              data.endTime = this.value1;
              this.onSearch(data);
              let data3 = {
                endTime: "2021-05-09 00:00:00",
                energyTypeId: this.energyId,
                interval: 1,
                projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
                startTime: "2021-05-01 00:00:00",
                timeUnit: "HOURS",
              };
              let dateT = new Date(this.value2);
              dateT = dateT.setDate(dateT.getDate() + 1);
              dateT = this.formatDate(dateT);
              data3.startTime = this.value2;
              data3.endTime = dateT;
              this.value22 = dateT;
              this.onSearch3(data3);
              let data2 = {
                energyTypeId: this.energyId2,
                projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
              };
              useEnergyForecast(data2).then((res) => {
                console.log(res.data.data);
                let temp1 = [];
                let temp2 = [];
                let temp3 = [];
                let temp4 = [];
                res.data.data.first.forEach((item1, index1) => {
                  if (item1.formatValue === null) {
                    res.data.data.first[index1].formatValue = "";
                  }
                  temp1.push(item1.formatValue);
                });
                res.data.data.last.forEach((item1, index1) => {
                  if (item1.formatValue === null) {
                    res.data.data.last[index1].formatValue = "";
                    temp4.push(1);
                  }
                  temp2.push(item1.formatValue);
                });
                res.data.data.temp.forEach((item1, index1) => {
                  if (item1.formatValue === null) {
                    res.data.data.first[index1].formatValue = "";
                  }
                  temp3.push(item1.formatValue);
                });
                this.dataValue21 = temp1;
                this.dataValue22 = temp2;
                if (temp4.length === 24) {
                  this.dataValue22 = [];
                }
                this.dataValue23 = temp3;
              });
            });
          }
        }
      );
    },
  },
  computed: {
    timeSpan() {
      let temp = "";
      if (this.isActivenTime2 === 1) {
        temp = this.value2.split(" ")[0];
      } else if (this.isActivenTime2 === 2) {
        temp =
          this.value2.split(" ")[0].split("-")[0] +
          "-" +
          this.value2.split(" ")[0].split("-")[1];
      } else if (this.isActivenTime2 === 3) {
        temp = this.value2.split(" ")[0].split("-")[0];
      }
      return temp;
    },
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
    }),
    chartBarOptionBig() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            // debugger;
            if (params.length == 2) {
              if (
                params[0].value !== undefined &&
                params[1].value !== undefined
              ) {
                return (
                  "时间：" +
                  " " +
                  params[0].axisValue +
                  " " +
                  "值：" +
                  params[0].seriesName +
                  params[0].value +
                  "<br>" +
                  "时间：" +
                  " " +
                  params[1].axisValue +
                  " " +
                  "值：" +
                  params[1].seriesName +
                  params[1].value
                );
              } else if (
                params[0].value === undefined &&
                params[1].value === undefined
              ) {
                return;
              } else {
                if (params[0].value === undefined) {
                  return (
                    "时间：" +
                    " " +
                    params[1].axisValue +
                    " " +
                    "值：" +
                    params[1].seriesName +
                    params[1].value
                  );
                } else if (params[1].value === undefined) {
                  return (
                    "时间：" +
                    " " +
                    params[0].axisValue +
                    " " +
                    "值：" +
                    params[0].seriesName +
                    params[0].value
                  );
                }
              }
            } else {
              if (params[0].value !== undefined) {
                return (
                  "时间：" +
                  " " +
                  params[0].axisValue +
                  " " +
                  "值：" +
                  params[0].seriesName +
                  params[0].value
                );
              } else {
                return (
                  "时间：" +
                  " " +
                  params[0].axisValue +
                  " " +
                  "值：" +
                  params[0].seriesName +
                  "--"
                );
              }
            }
          },
        },
        legend: {
          data: this.datalegend3,
          right: "3%",
          top: 0,
          textStyle: {
            color: "#082254",
          },
        },
        grid: {
          containLabel: true,
          top: "30px",
          left: "5px",
          right: "50px",
          bottom: "30px",
        },
        xAxis: {
          data: this.dataAxis3,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          // name:"()",
          name: this.selecctUnit3[this.isActivenNH3 - 1],
          nameTextStyle: {
            color: "#082254",
            fontSize: 14,
            padding: -4,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            name: this.datalegend3[0],
            type: "bar",
            barWidth: this.isActivenTime2 == 1 ? 2 : 16,
            itemStyle: {
              color: "#F3BB3D",
              borderRadius: 8,
            },

            data: this.dataValue31,
          },
          {
            name: this.datalegend3[1],
            type: "bar",
            barWidth: this.isActivenTime2 == 1 ? 2 : 16,
            itemStyle: {
              color: "#417AF3",
              borderRadius: 8,
            },

            data: this.dataValue32,
          },
        ],
      };
    },
    chartMultiOption() {
      return {
        grid: {
          containLabel: true,
          top: "30px",
          left: "5px",
          right: "12px",
          bottom: "30px",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        legend: {
          data: this.datalegend1,
          right: "10%",
          top: 0,
          textStyle: {
            color: "#082254",
          },
        },
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#082254",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: this.selecctUnit2[this.isActivenNH2 - 1],
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // name: "耗能",
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "(°C)",
            nameTextStyle: {
              color: "#082254",
              fontSize: 14,
              padding: -4,
            },
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#082254",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: this.datalegend1[0],
            type: "bar",
            barWidth: 16,
            itemStyle: {
              color: "#3f6df8",
              borderRadius: 8,
            },
            data: this.dataValue21,
          },
          {
            name: this.datalegend1[1],
            type: "line",
            // yAxisIndex: 1,
            smooth: true,
            itemStyle: {
              color: "#41F39A",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#41F39A", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.dataValue22,
          },
          {
            name: this.datalegend1[2],
            type: "line",
            yAxisIndex: 1,
            itemStyle: {
              color: "#FCD98B",
            },
            data: this.dataValue23,
          },
        ],
      };
    },
    chartBarOption1() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "30px",
        },
        xAxis: {
          data: this.dataAxis1,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          // name:"()",
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            data: this.dataValue1,
          },
        ],
      };
    },
    chartBarOption2() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}: {c}",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "50px",
          bottom: "30px",
        },
        xAxis: {
          data: this.dataAxis2,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 15,
            },
            data: this.dataValue2,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.title1 {
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #f6b084 0%, #ea9b7a 46%, #e97f75 100%);
  border-radius: 4px;
  position: relative;
}
.title2 {
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #71a1de 0%, #5a82dd 46%, #516adc 100%);
  border-radius: 4px;
  position: relative;
}
.title3 {
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #b97df9 0%, #ad6bf1 54%, #8c44e1 100%);
  border-radius: 4px;
  position: relative;
}
.title4 {
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #6bcf84 0%, #6ec887 46%, #2aab4c 100%);
  border-radius: 4px;
  position: relative;
}

.body1 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.body2 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.square2-category:first-child {
  border-radius: 2px 0 0 2px;
}
.square2-category:last-child {
  border-radius: 0 2px 2px 0;
  border: 1px solid #3f6df8;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.el-input >>> input {
  width: 180px !important;
  height: 29px !important;
}
.el-input >>> .el-input__icon {
  line-height: 29px !important;
}
</style>