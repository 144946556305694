<template>
  <div>
    <div
      style="padding-left: 20px"
      v-for="(item1, index1) in list"
      :key="index1"
    >
      <el-form-item
        label="子节点："
        prop="reportName"
        style="position: relative; margin-bottom: 10px"
        class="inline-input"
      >
        <el-input :disabled="isView" v-model="item1.nodeName" placeholder="请输入报表名称">
        </el-input>
      </el-form-item>
      <el-form-item
        label="数据源类型："
        prop="reportName"
        style="position: relative"
        class="inline-input"
      >
        <el-select :disabled="isView" v-model="item1.show" placeholder="请选择">
          <el-option
            v-for="item in optionsSourceType"
            :key="item.name"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>

        <!-- <div style="position: absolute; top: 0; right: -25px" v-if="item1.show">
          <el-dropdown @command="handleCommand" v-if="!isView">
            <i class="el-icon-more-outline"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{
                  command: 'a',
                  index: index1,
                }"
                >新增子节点</el-dropdown-item
              >
              <el-dropdown-item
                :command="{
                  command: 'b',
                  index: index1,
                }"
                >删除该节点</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->

        <!-- <div style="position: absolute; top: 0; right: -40px">
          <i class="el-icon-circle-plus-outline"></i>
        </div>
        <div style="position: absolute; top: 0; right: -80px">
          <i class="el-icon-circle-close"></i>
        </div> -->
      </el-form-item>

      <el-form-item
        v-if="!item1.show"
        label="绑定仪表："
        prop="reportName"
        style="position: relative"
        class="inline-input"
      >
        <el-select filterable :disabled="isView" v-model="item1.instanceId" placeholder="请选择">
          <el-option
            v-for="item in insatnceArr"
            :key="item.instanceId"
            :label="item.name"
            :value="item.instanceId"
          >
          </el-option>
        </el-select>

        <div style="position: absolute; top: 0; right: -25px" v-if="!isView">
          <el-dropdown trigger="click" @command="handleCommand">
            <i class="el-icon-more-outline"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{
                  command: 'a',
                  index: index1,
                }"
                >新增子节点</el-dropdown-item
              >
              <el-dropdown-item
                :command="{
                  command: 'b',
                  index: index1,
                }"
                >删除该节点</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form-item>

      <el-form-item
        v-if="item1.show"
        label="绑定仪表："
        prop="reportName"
        style="position: relative"
        class="inline-input"
      >
        <el-select filterable :disabled="isView" v-model="item1.instanceId" placeholder="请选择">
          <el-option
            v-for="item in insatnceArr2"
            :key="item.instanceId"
            :label="item.name"
            :value="item.instanceId"
          >
          </el-option>
        </el-select>

        <div style="position: absolute; top: 0; right: -25px" v-if="!isView">
          <el-dropdown trigger="click" @command="handleCommand">
            <i class="el-icon-more-outline"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{
                  command: 'a',
                  index: index1,
                }"
                >新增子节点</el-dropdown-item
              >
              <el-dropdown-item
                :command="{
                  command: 'b',
                  index: index1,
                }"
                >删除该节点</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form-item>

      <div v-if="item1.childNodeList.length > 0" style="padding-left: 20px">
        <form-list :list="item1.childNodeList" :isView="isView"></form-list>
      </div>
      <!-- <form-list></form-list> -->
      <!-- <div v-if="item1.children.length > 0" style="padding-left: 20px">
        <form-list></form-list>
      </div>
      <div v-else>
        1
      </div> -->
    </div>
  </div>
</template>

<script>
// import { getInstance } from "@/request/apiLg";
import { mapState } from "vuex";
export default {
  name: "formList",
  props: ["list","isView"],
  data() {
    return {
      params1: "",
      params2: "",
      optionsInstance: [],
      optionsSourceType: [
        {
          name: "虚拟节点",
          code: true,
        },
        {
          name: "具体仪表",
          code: false,
        },
      ],
      //   list: [
      //     {
      //       name: 1,
      //       children: [
      //         {
      //           name: 2,
      //           children: [],
      //         },
      //       ],
      //     },
      //   ],
    };
  },
  methods: {
    handleCommand(command) {
      // this.$message("click on item " + command);
      console.log(command);
      if (command.command === "a") {
        this.list[command.index].childNodeList.push({
          childNodeList: [],
          nodeName: "",
          show: true,
        });
      } else if (command.command === "b") {
        this.list.splice(command.index, 1);
      }
    },
  },
  created() {
    // getInstance().then((res) => {
    //   this.optionsInstance = res.data.data;
    // });
  },
  computed: {
    ...mapState({
      insatnceArr: (state) => state.insatnceArr,
      insatnceArr2: (state) => state.insatnceArr2,
    }),
  },
};
</script>

<style scoped>
.inline-input >>> .el-input input {
  width: 160px !important;
}
</style>