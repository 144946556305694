<template>
  <div style="width: 100%; height: 100%; background: white; padding: 20px">
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div style="margin-right: 20px">
          告警类型:
          <el-select
            v-model="query.alarmType"
            placeholder="请选择"
            style="margin-left: 10px"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-right: 20px">
          告警状态:
          <el-select
            v-model="query.alarmStatus"
            placeholder="请选择"
            style="margin-left: 10px"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          时间选择:
          <el-date-picker
            style="margin-left: 10px"
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd 00:00:00"
            @change="timeChange"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <div
          style="
            width: 72px;
            height: 32px;
            background: #3f6df8;
            border-radius: 2px;
            line-height: 32px;
            text-align: center;
            color: white;
            font-size: 14px;
            cursor: pointer;
          "
          @click="searchFor"
        >
          查询
        </div>
      </div>
    </div>

    <div style="width: 100%; margin-top: 20px">
      <div
        style="
          width: 72px;
          height: 32px;
          background: #3f6df8;
          border-radius: 2px;
          line-height: 32px;
          text-align: center;
          color: white;
          float: right;
          font-size: 14px;
        "
      >
        批量处理
      </div>
      <div style="clear: both"></div>
    </div>
    <!-- @row-click="toDetail" -->
    <div style="width: 100%; margin-top: 20px">
      <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 30px"
        :row-class-name="tableRowClassName"
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column label="优先级" width="120">
          <template slot-scope="scope"
            >{{ scope.row.date }}
            <div
              v-if="scope.row.alarmLevel === 'emergency'"
              style="
                width: 64px;
                height: 32px;
                border-radius: 2px;
                border: 1px solid #e52323;
                line-height: 32px;
                color: #e52323;
                text-align: center;
              "
            >
              {{ scope.row.alarmLevelName }}
            </div>
            <div
              v-else-if="scope.row.alarmLevel === 'important'"
              style="
                width: 64px;
                height: 32px;
                border-radius: 2px;
                border: 1px solid #ffb818;
                line-height: 32px;
                color: #ffb818;
                text-align: center;
              "
            >
              {{ scope.row.alarmLevelName }}
            </div>
            <div
              v-else-if="scope.row.alarmLevel === 'normal'"
              style="
                width: 64px;
                height: 32px;
                border-radius: 2px;
                border: 1px solid #3f6df8;
                line-height: 32px;
                color: #3f6df8;
                text-align: center;
              "
            >
              {{ scope.row.alarmLevelName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="instanceAndAttrName"
          label="设备/系统"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="alarmType"
          label="告警类型"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <template v-if="scope.row.alarmType">
              <template v-if="scope.row.alarmType === 'OVERRUN'">
                数据异常
              </template>
              <template v-if="scope.row.alarmType === 'TIMEOUT'">
                通信异常
              </template>
              <template v-if="scope.row.alarmType === 'DIAGNOSIS'">
                故障诊断
              </template>
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="告警时间"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <template v-if="scope.row.alarmStartTime">
              {{ scope.row.alarmStartTime | formateV }}
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column
          prop="faultDesc"
          label="故障描述"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="持续故障"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <template v-if="scope.row.continued"> 是 </template>
            <template v-else>否</template>
          </template>
        </el-table-column>
        <el-table-column
          prop="faultEffect"
          label="故障影响"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="告警状态"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <template v-if="scope.row.alarmStatus">
              <template v-if="scope.row.alarmStatus === 'UNTREATED'">
                未处理
              </template>
              <template v-if="scope.row.alarmStatus === 'PROCESSED'">
                已处理
              </template>
              <template v-if="scope.row.alarmStatus === 'INGORED'">
                已忽略
              </template>
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <template v-if="scope.row.alarmLevel === 'emergency'">
              <span
                v-if="scope.row.alarmStatus === 'UNTREATED'"
                style="color: #3f6df8; cursor: pointer; margin-right: 5px"
              >
                忽略
              </span>
              <span
                style="color: #02b84b; margin-right: 5px; cursor: pointer"
                @click="toDetail(scope.row)"
              >
                详情
              </span>
            </template>
            <template v-else
              ><span
                style="color: #02b84b; margin-right: 5px; cursor: pointer"
                @click="toDetail(scope.row)"
              >
                详情
              </span></template
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="margin-top: 15px; text-align: right">
      <el-pagination
        @size-change="sizeChange"
        @current-change="pageChange"
        :current-page="query.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="query.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
// import * as echarts from "echarts";
import { mapState, mapMutations } from "vuex";
import {
  getAlarmList,
  setProject,
  // getAlarmDetail
} from "@/request/apiLg";
export default {
  data() {
    return {
      multipleSelection: [],
      loading: false,
      tableData: [],
      value1: "",
      isActivenTime: 1,
      options1: [
        {
          value: "ALL",
          label: "全部",
        },
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      options2: [
        {
          value: "ALL",
          label: "全部",
        },
        {
          value: "UNTREATED",
          label: "未处理",
        },
        {
          value: "PROCESSED",
          label: "已处理",
        },
        {
          value: "INGORED",
          label: "已忽略",
        },
      ],
      value: "",
      query: {
        limit: 10,
        page: 1,
        alarmStatus: "ALL",
        alarmType: "ALL",
        startTimestamp: 1619503814000,
        endTimestamp: 1624774214000,
      },
      total: 0,
    };
  },
  computed: {
    ...mapState({
      alarmQuery: (state) => state.alarmQuery,
    }),
  },
  filters: {
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
  },
  methods: {
    ...mapMutations(["mutationsSetAlarmQuery"]),
    toDetail(row) {
      console.log("row", row);
      this.$router.push({
        name: "alarmDetail",
        query: { id: row.id },
      });
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    searchFor() {
      // this.$router.push({ name: "alarmDetail" });
      console.log("this.value1", this.value1);
      this.mutationsSetAlarmQuery(this.query);
      getAlarmList(this.query).then((res) => {
        console.log(res);
        this.tableData = res.data.data;
        this.total = res.data.count;
      });
    },
    clickNH(index) {
      this.isActivenTime = index;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    sizeChange(val) {
      this.query.page = 1;
      this.query.limit = val;
      this.searchFor();
    },
    pageChange(val) {
      this.query.page = val;
      this.searchFor();
    },
    toUnix(v) {
      var date = new Date(v);
      let res = date.getTime();
      return res;
    },
    timeChange(val) {
      if (val) {
        this.query.startTimestamp = this.toUnix(val[0]);
        this.query.endTimestamp = this.toUnix(val[1]);
      } else {
        this.query.startTimestamp = "";
        this.query.endTimestamp = "";
      }
    },
  },
  mounted() {
    let dateT = new Date();
    var YY = dateT.getFullYear() + "-";
    var MM =
      (dateT.getMonth() + 1 < 10
        ? "0" + (dateT.getMonth() + 1)
        : dateT.getMonth() + 1) + "-";
    var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    this.value11 = YY + MM + DD + " 00:00:00";
    dateT = new Date(this.value11);
    dateT = dateT.setDate(dateT.getDate() + 1);
    this.value22 = this.formatDate(dateT);

    console.log("this.value1", this.value11);
    console.log("this.value2", this.value22);
    this.value1 = [this.value11, this.value22];

    // let data = {
    //   alarmStatus: "ALL",
    //   alarmType: "ALL",
    //   endTimestamp: this.toUnix(this.value22),
    //   limit: 10,
    //   page: 1,
    //   startTimestamp: this.toUnix(this.value11),
    // };
    this.query.startTimestamp = this.toUnix(this.value11);
    this.query.endTimestamp = this.toUnix(this.value22);

    if (Object.keys(this.alarmQuery).length !== 0) {
      this.query = this.alarmQuery;
      this.value1 = [
        this.formatDate(this.query.startTimestamp),
        this.formatDate(this.query.endTimestamp),
      ];
    }
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getAlarmList(this.query).then((res) => {
            console.log(res);
            this.tableData = res.data.data;
            this.total = res.data.count;
          });
        }
      }
    );
  },
};
</script>

<style scoped>
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.title1 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #f6b084 0%, #ea9b7a 46%, #e97f75 100%);
  border-radius: 4px;
  position: relative;
}
.title2 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #71a1de 0%, #5a82dd 46%, #516adc 100%);
  border-radius: 4px;
  position: relative;
}
.title3 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #b97df9 0%, #ad6bf1 54%, #8c44e1 100%);
  border-radius: 4px;
  position: relative;
}
.title4 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #6bcf84 0%, #6ec887 46%, #2aab4c 100%);
  border-radius: 4px;
  position: relative;
}

.body1 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.body2 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
</style>