<template>
  <div style="width: 100%; height: 100%; background: white" @click="falseAll">
    <div
      style="
        width: 100%;
        height: 50px;
        border-bottom: 1px solid rgba(8, 34, 84, 0.1);
        padding-left: 50px;
      "
    >
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        v-for="(item, index) in dataTabs"
        :key="index"
        @click="clickTabs(item)"
        :class="{ isClick: currentTabs === item.energyTypeId }"
      >
        {{ item.energyTypeName }}
      </div>

      <!-- <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 1"
        :class="{ isClick: clickCatagory === 1 }"
      >
        水能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 2"
        :class="{ isClick: clickCatagory === 2 }"
      >
        电能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 3"
        :class="{ isClick: clickCatagory === 3 }"
      >
        气能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        @click="clickCatagory = 4"
        :class="{ isClick: clickCatagory === 4 }"
      >
        热能
      </div> -->
    </div>
    <div
      style="
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        justify-content: space-between;
        overflow: auto;
      "
    >
      <div
        style="
          height: 100%;
          width: 250px;
          flex: none;
          border-right: 1px solid rgba(8, 34, 84, 0.1);
          overflow: hidden;
          position: relative;
        "
      >
        <div
          style="
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            color: #082254;
            text-align: center;
          "
        >
          {{ treeName }}
        </div>
        <div style="overflow: auto; width: 100%; height: 550px">
          <el-tree
            ref="eltree"
            style="width: 100%; max-height: 550px; overflow: auto"
            :data="data"
            node-key="id"
            :props="defaultProps"
            @node-click="getTreeAttr"
            :default-expanded-keys="defaultExpandArray"
            :current-node-key="defaultKey"
          >
          </el-tree>
        </div>
        <div style="width: 100%; position: absolute; left: 0; bottom: 0">
          <!-- <div
            style="
              width: 100%;
              height: 50px;
              line-height: 50px;
              font-size: 14px;
              text-align: center;
              color: #3f6df8;
              border: 1px solid #3f6df8;
            "
          >
            分类二
            <img
            src="@/assets/向下.png"
            style="position: absolute; top: 18px; right: 25px"
            />
          </div> -->
          <div
            v-for="(item, index) in dataTreeArray"
            :key="index"
            style="
              width: 100%;
              height: 50px;
              line-height: 50px;
              font-size: 14px;
              text-align: center;
              color: #3f6df8;
              border: 1px solid #3f6df8;
              border-bottom: none;
              cursor: pointer;
            "
            class="other-tree"
            @click="clickOtherTree(item, index)"
          >
            {{ item.treeName }}
          </div>
        </div>
      </div>
      <div style="flex: 1; padding: 20px; overflow: hidden">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              class="square2-category"
              style="border-radius: 2px 0 0 2px"
              @click="clickTime(1)"
              :class="{ activeNH: isActivenTime === 1 }"
            >
              日
            </div>
            <div
              class="square2-category"
              style=""
              @click="clickTime(2)"
              :class="{ activeNH: isActivenTime === 2 }"
            >
              月
            </div>
            <div
              class="square2-category"
              style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
              @click="clickTime(3)"
              :class="{ activeNH: isActivenTime === 3 }"
            >
              年
            </div>
            <div style="margin-left: 14px; width: 180px" class="date">
              <el-date-picker
                style="width: 180px"
                v-model="value1"
                :type="changeDateType"
                placeholder="选择日期"
                value-format="yyyy-MM-dd 00:00:00"
              >
              </el-date-picker>
            </div>
            <div
              style="position: relative; margin-left: 14px; cursor: pointer"
              class="select2"
            >
              <!-- <input
                v-model="selectValue"
                readonly
                style="height: 29px; width: 140px"
              /> -->
              <el-input
                v-model="selectValueInterval"
                placeholder="请选择时间间隔"
                readonly
                :class="{ isBorder: isShowSelectInterval }"
                style="width: 160px"
                @click.native="clickInterval($event)"
              >
                <i slot="suffix" class="el-input__icon el-icon-arrow-down"></i>
              </el-input>

              <div
                v-if="isShowSelectInterval"
                style="
                  position: absolute;
                  z-index: 9;
                  width: 160px;
                  max-height: 116px;
                  border: 1px solid #3f6df8;

                  top: 29px;
                  left: 0px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  background: white;
                  overflow: auto;
                "
              >
                <div
                  style=""
                  class="sel-option"
                  @click="clickOptionInterval(item)"
                  v-for="(item, index) in attrInterval"
                  :key="index"
                  :class="{ activeOption2: selectValueInterval === item }"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="margin-right: 20px">
              <el-radio-group v-model="radio" @change="changeRadio($event)">
                <!-- <el-radio :label="3">备选项</el-radio>
                <el-radio :label="6">备选项</el-radio>
                <el-radio :label="9">备选项</el-radio> -->
                <el-radio
                  v-for="(item, index) in attr1"
                  :label="item.attrId"
                  :key="index"
                  >{{ item.attrName }}</el-radio
                >
              </el-radio-group>

              <!-- <el-radio v-model="radio" label="1">电量</el-radio>
              <el-radio v-model="radio" label="2">电流</el-radio>
              <el-radio v-model="radio" label="3">功率</el-radio> -->
            </div>

            <div
              style="position: relative; margin-right: 20px; cursor: pointer"
              class="select"
              v-if="attr2.length > 0"
            >
              <!-- <input
                v-model="selectValue"
                readonly
                style="height: 29px; width: 140px"
              /> -->
              <el-input
                v-model="selectValue"
                placeholder="更多监测项"
                readonly
                :class="{ isBorder: isShowSelect }"
                @click.native="clickSelect($event)"
                style=""
              >
                <i slot="suffix" class="el-input__icon el-icon-arrow-down"></i>
              </el-input>

              <div
                v-if="isShowSelect"
                style="
                  position: absolute;
                  z-index: 9;
                  width: 140px;
                  max-height: 116px;
                  border: 1px solid #3f6df8;

                  top: 29px;
                  left: 0px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  background: white;
                  overflow: auto;
                "
              >
                <div
                  style=""
                  class="sel-option"
                  @click="clickOption2(item)"
                  v-for="(item, index) in attr2"
                  :key="index"
                  :class="{ activeOption2: selectValue === item.attrName }"
                >
                  {{ item.attrName }}
                </div>
                <!-- <div
                  style=""
                  class="sel-option"
                  @click="clickOption('相电压频率')"
                >
                  相电压频率
                </div>
                <div
                  style=""
                  class="sel-option"
                  @click="clickOption('功率因数')"
                >
                  功率因数
                </div> -->
              </div>
            </div>

            <div
              style="
                width: 72px;
                height: 29px;
                background: #3f6df8;
                border-radius: 2px;
                font-size: 12px;
                color: white;
                text-align: center;
                line-height: 29px;
                margin-right: 20px;
                cursor: pointer;
              "
              @click="onSearch"
            >
              查询
            </div>

            <div
              v-loading="downXlsxStatus"
              @click="analysisDataExport"
              style="
                width: 72px;
                height: 29px;
                background: #3f6df8;
                border-radius: 2px;
                font-size: 12px;
                color: white;
                text-align: center;
                line-height: 29px;
                cursor: pointer;
              "
            >
              数据导出
            </div>
          </div>
        </div>

        <div
          style="
            width: 100%;
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div>
            <span
              style="color: #082254; font-weight: bold; margin-right: 10px"
              >{{ currentNodeName }}</span
            >
            <span
              style="color: #082254; font-weight: bold; margin-right: 10px"
              >{{ value1.split(" ")[0] }}</span
            >
            <span style="color: #082254; font-weight: bold; margin-right: 10px"
              >{{ currentAttrName }}：{{ currentAll }}
              {{ currentAllUnit }}</span
            >
            <!-- <img src="@/assets/指数下降.png" style="vertical-align: -5px" />
            <span style="color: #082254; font-weight: bold; margin-right: 10px"
              >-15%</span
            > -->
          </div>
          <div>
            <div
              @click="clickZhe"
              style="
                float: left;
                width: 36px;
                height: 32px;
                border-radius: 2px 0 0 2px;
                border: 1px solid #3f6df8;
                text-align: center;
                cursor: pointer;
              "
              :class="{ isActive: isActiveType === 'showZhe' }"
            >
              <img :src="srcZhe" style="vertical-align: middle" />
            </div>
            <div
              @click="clickZhu"
              style="
                float: left;
                width: 36px;
                height: 32px;

                border-radius: 0 2px 2px 0;
                cursor: pointer;
                border: 1px solid #3f6df8;
                text-align: center;
              "
              :class="{ isActive: isActiveType === 'showZhu' }"
            >
              <img :src="srcZhu" style="vertical-align: middle" />
            </div>
          </div>
        </div>
        <div
          v-if="currentTabs === 'elec' && radio === 'Ep_imp'"
          style="
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-right: 10px;
            "
          >
            <div
              style="
                width: 24px;
                height: 10px;
                background: #f3bb3d;
                border-radius: 5px;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 12px; color: #082254">峰</div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-right: 10px;
            "
          >
            <div
              style="
                width: 24px;
                height: 10px;
                background: #41f39a;
                border-radius: 5px;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 12px; color: #082254">谷</div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-right: 10px;
            "
          >
            <div
              style="
                width: 24px;
                height: 10px;
                background: #417af3;
                border-radius: 5px;
                margin-right: 10px;
              "
            ></div>
            <div style="font-size: 12px; color: #082254">平</div>
          </div>
        </div>
        <div style="width: 100%; height: 370px; margin-top: 20px">
          <v-chart
            v-if="isActiveType === 'showZhu' && !isStack"
            ref="chart1"
            :option="chartBarOption1"
          />
          <v-chart
            v-if="isActiveType === 'showZhe'"
            ref="chart2"
            :option="chartLineOption1"
          />
          <v-chart
            v-if="isActiveType === 'showZhu' && isStack"
            ref="chart3"
            :option="chartBarOptionStack"
          />
        </div>

        <!-- <div style="width: 100%; height: 326px; margin-top: 20px"> style="width: 1323px" -->
        <el-table
          :data="tableData"
          style="width: 100%; overflow: auto; margin-top: 30px"
          :row-class-name="tableRowClassName"
          v-loading="loading"
        >
          <el-table-column fixed prop="name" label="监测点位" width="200">
          </el-table-column>
          <el-table-column fixed prop="unit" label="单位" width="120">
          </el-table-column>
          <el-table-column
            v-for="(item, index) in tableKey"
            :key="index"
            :prop="item"
            :label="item"
            width="190"
            align="center"
          >
            <template slot-scope="scope">
              <template v-if="scope.row[item] === null">--</template>
              <template v-else>
                {{ scope.row[item] }}
              </template>
              <!-- {{ scope.row['00:05'] }} -->
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="zip"
            label="00:00-01:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="01:00-02:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="02:00-03:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="03:00-04:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="04:00-05:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="05:00-06:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="06:00-07:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="07:00-08:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="08:00-09:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="09:00-10:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="10:00-11:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="zip"
            label="11:00-12:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="12:00-13:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="13:00-14:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="14:00-15:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="15:00-16:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="16:00-17:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="17:00-18:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="18:00-19:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="19:00-20:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="20:00-21:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="21:00-22:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="22:00-23:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="zip"
            label="23:00-24:00 用能量"
            width="190"
            align="center"
          >
          </el-table-column> -->
        </el-table>
        <!-- </div> -->
      </div>
      <!-- <div
        style="
          width: 100%;
          height: 100px;
          line-height: 100px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #082254;
        "
      >
        {{tuopuName}}
      </div>
      <div class="tuopu"></div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import {
  // getProjects,
  setProject,
  getEnergy,
  getTreeInfo,
  getTree,
  getTreeOption,
  getDataSearch,
  getDataSearchTable,
  // analysisDataExport,
} from "@/request/apiLg";
import * as echarts from "echarts";
// import temp from "@/test.js";
export default {
  data() {
    // var dataAxis2 = [
    //   "00:00",
    //   "01:00",
    //   "02:00",
    //   "03:00",
    //   "04:00",
    //   "05:00",
    //   "06:00",
    //   "07:00",
    //   "08:00",
    //   "09:00",
    //   "10:00",
    //   "11:00",
    //   "12:00",
    //   "13:00",
    //   "14:00",
    //   "15:00",
    //   "16:00",
    //   "17:00",
    //   "18:00",
    //   "19:00",
    //   "20:00",
    //   "21:00",
    //   "22:00",
    //   "23:00",
    // ];
    // var data2 = [
    //   510,
    //   {
    //     value: 390,
    //     itemStyle: {
    //       color: "#41F39A",
    //     },
    //   },
    //   {
    //     value: 300,
    //     itemStyle: {
    //       color: "#41F39A",
    //     },
    //   },
    //   {
    //     value: 200,
    //     itemStyle: {
    //       color: "#41F39A",
    //     },
    //   },
    //   {
    //     value: 250,
    //     itemStyle: {
    //       color: "#41F39A",
    //     },
    //   },
    //   {
    //     value: 260,
    //     itemStyle: {
    //       color: "#41F39A",
    //     },
    //   },
    //   {
    //     value: 230,
    //     itemStyle: {
    //       color: "#41F39A",
    //     },
    //   },
    //   {
    //     value: 360,
    //     itemStyle: {
    //       color: "#41F39A",
    //     },
    //   },
    //   {
    //     value: 800,
    //     itemStyle: {
    //       color: "#F3BB3D",
    //     },
    //   },
    //   {
    //     value: 810,
    //     itemStyle: {
    //       color: "#F3BB3D",
    //     },
    //   },
    //   {
    //     value: 700,
    //     itemStyle: {
    //       color: "#F3BB3D",
    //     },
    //   },
    //   {
    //     value: 750,
    //     itemStyle: {
    //       color: "#F3BB3D",
    //     },
    //   },
    //   {
    //     value: 850,
    //     itemStyle: {
    //       color: "#F3BB3D",
    //     },
    //   },
    //   600,
    //   500,
    //   300,
    //   400,
    //   600,
    //   {
    //     value: 850,
    //     itemStyle: {
    //       color: "#F3BB3D",
    //     },
    //   },
    //   {
    //     value: 850,
    //     itemStyle: {
    //       color: "#F3BB3D",
    //     },
    //   },
    //   {
    //     value: 850,
    //     itemStyle: {
    //       color: "#F3BB3D",
    //     },
    //   },
    //   {
    //     value: 850,
    //     itemStyle: {
    //       color: "#F3BB3D",
    //     },
    //   },
    //   500,
    //   300,
    // ];
    return {
      downXlsxStatus: false,
      headers: {
        Authorization: localStorage.getItem("accessToken"),
      },
      postqueryData: "",
      isStack: false,
      changeZoom: 10,
      currentAttrName: "",
      currentAll: "",
      currentAllUnit: "",
      currentNodeName: "",
      loading: true,
      firstTree: {},
      dataTreeArray: [],
      currentNodeID: "",
      dataXaxis: [],
      dataValueLine: [],
      dataValueBar: [],
      dataValueStackPeak: [],
      dataValueStackValley: [],
      dataValueStackPeace: [],
      isShowSelectInterval: false,
      attrInterval: ["5分钟", "1小时"],
      selectValueInterval: "",
      defaultKey: "",
      changeDateType: "date",
      defaultExpandArray: [],
      attr1: [],
      attr2: [],
      treeId: "",
      treeName: "",
      currentTabs: "",
      dataTabs: [],
      // chartLineOption1: {

      // },
      isActiveType: "showZhe",
      tableKey: ["", "", "", ""],
      tableData: [
        // {
        //   date: "办公楼",
        //   name: "567kWh",
        //   zip: "567kWh",
        // },
        // {
        //   date: "办公楼",
        //   name: "567kWh",
        //   zip: "567kWh",
        // },
        // {
        //   date: "办公楼",
        //   name: "567kWh",
        //   zip: "567kWh",
        // },
        // {
        //   date: "办公楼",
        //   name: "567kWh",
        //   zip: "567kWh",
        // },
      ],
      // chartBarOption1: {},
      srcZhu: `${require("@/assets/柱状图2.png")}`,
      srcZhe: `${require("@/assets/折线图1.png")}`,
      isShowSelect: false,
      value1: "",
      selectValue: "",
      selectId: "",
      radio: "1",
      isActivenTime: 1,
      activeName: "second",
      clickCatagory: 1,
      data: [
        {
          id: 1,
          label: "高新文体中心",
          children: [
            {
              id: 2,
              label: "制冷机房",
              children: [
                {
                  id: 3,
                  label: "空调主机",
                  children: [
                    { id: 7, label: "1#主机" },
                    { id: 8, label: "2#主机" },
                    { id: 9, label: "3#主机" },
                    { id: 10, label: "4#主机" },
                    { id: 11, label: "5#主机" },
                  ],
                },
                {
                  id: 4,
                  label: "冷冻水泵",
                  children: [
                    { id: 12, label: "冷冻水泵（总）" },
                    { id: 13, label: "7#冷冻水泵" },
                    { id: 14, label: "6#冷冻水泵" },
                    // { id: 15, label: "备用" },
                    // { id: 16, label: "备用" },
                    { id: 17, label: "1#图书馆水泵" },
                    { id: 18, label: "2#图书馆水泵" },
                    { id: 19, label: "3#图书馆水泵" },
                    // { id: 20, label: "备用" },
                    { id: 21, label: "1#文化馆水泵" },
                    { id: 22, label: "2#文化馆水泵" },
                    { id: 23, label: "3#文化馆水泵" },
                    { id: 24, label: "1#体育馆水泵" },
                    { id: 25, label: "2#体育馆水泵" },
                    { id: 26, label: "3#体育馆水泵" },
                    { id: 27, label: "4#体育馆水泵" },
                    // { id: 28, label: "备用" },
                    { id: 29, label: "1#健身中心水泵" },
                    { id: 30, label: "2#健身中心水泵" },
                    { id: 31, label: "3#健身中心水泵" },
                    { id: 32, label: "1#冷冻水泵" },
                    { id: 33, label: "2#冷冻水泵" },
                    { id: 34, label: "3#冷冻水泵" },
                    { id: 35, label: "4#冷冻水泵" },
                    { id: 36, label: "5#冷冻水泵" },
                    // { id: 37, label: "备用" },
                    // { id: 38, label: "备用" },
                  ],
                },
                {
                  id: 5,
                  label: "其他",
                  children: [
                    { id: 39, label: "特殊用电（总）" },
                    { id: 40, label: "特殊用电" },
                    // { id: 41, label: "备用" },
                  ],
                },
                {
                  id: 6,
                  label: "冷却水泵",
                  children: [
                    { id: 42, label: "6#冷却水泵" },
                    { id: 43, label: "7#冷却水泵" },
                    // { id: 44, label: "备用" },
                    { id: 45, label: "4#冷却水泵" },
                    { id: 46, label: "5#冷却水泵" },
                    // { id: 47, label: "备用" },
                    { id: 48, label: "1#冷却水泵" },
                    { id: 49, label: "2#冷却水泵" },
                    { id: 50, label: "3#冷却水泵" },
                    { id: 51, label: "冷却水泵（总）" },
                  ],
                },
                {
                  id: 7,
                  label: "冷却塔",
                  children: [{ id: 52, label: "冷却塔（总）" }],
                },
                {
                  id: 8,
                  label: "热量表",
                  children: [
                    { id: 53, label: "热量表1" },
                    { id: 54, label: "热量表2" },
                  ],
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
    };
  },

  mounted() {
    let dateT = new Date();
    var YY = dateT.getFullYear() + "-";
    var MM =
      (dateT.getMonth() + 1 < 10
        ? "0" + (dateT.getMonth() + 1)
        : dateT.getMonth() + 1) + "-";
    var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    this.value1 = YY + MM + DD + " 00:00:00";
    this.selectValueInterval = "5分钟";
    // getProjects().then((res1) => {
    //   if (res1.data) {
    // this.allElecP = res.data.data;
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getEnergy().then((res2) => {
            if (res2.data) {
              this.dataTabs = res2.data.data;
              this.currentTabs = res2.data.data[0].energyTypeId;
              // this.allElecP = res.data.data;
              // debugger
              let obj = {
                energyTypeId: res2.data.data[0].energyTypeId,
                projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
              };
              getTreeInfo(obj).then((res3) => {
                // debugger
                if (res3.data) {
                  if (res3.data.data.length > 0) {
                    this.firstTree = res3.data.data[0];
                    this.treeName = this.firstTree.treeName;
                    this.treeId = this.firstTree.treeId;
                    let temp1 = JSON.parse(JSON.stringify(res3.data.data));
                    temp1.splice(0, 1);
                    // console.log("temp2", temp2);
                    this.dataTreeArray = temp1;
                    // console.log("this.dataTreeArray", this.dataTreeArray);
                    // console.log("this.dataTreeArray", this.dataTreeArray);
                    getTree(res3.data.data[0].treeId).then((res4) => {
                      if (res4.data) {
                        // console.log(1);
                        this.data = res4.data.data;
                        // this.defaultKey = this.data[0].id;
                        this.defaultExpandArray.push(this.data[0].id);
                        this.data[0].children.forEach((item1) => {
                          this.defaultExpandArray.push(item1.id);
                          // if(item1.children.length>0){
                          //   item1.children.forEach(item2=>{
                          //     this.defaultExpandArray.push(item2.id)
                          //   })
                          // }
                        });
                        this.$nextTick(() => {
                          this.$refs.eltree.setCurrentKey(this.data[0].id);
                        });
                        this.currentNodeID = this.data[0].nodeId;
                        this.currentNodeName = this.data[0].nodeName;
                        let dataT = {
                          nodeId: this.data[0].nodeId,
                          projectId: JSON.parse(
                            window.localStorage.getItem("PROJECTID")
                          ),
                          treeId: this.treeId,
                        };

                        getTreeOption(dataT).then((res) => {
                          // console.log(res);
                          let tempArr1 = res.data.data;
                          let tempArr2 = res.data.data.splice(0, 3);
                          this.attr1 = tempArr2;
                          this.attr2 = tempArr1;
                          // console.log(this.attr1, this.attr2);
                          this.radio = tempArr2[0].attrId;
                          this.currentAttrName = tempArr2[0].attrName;
                          // console.log("this.radio", this.radio);
                          this.onSearch();
                          // temp["data"].forEach((item) => {
                          //   this.dataXaxis.push(item.datetime);
                          //   if (item.values.peace !== null) {
                          //     this.dataValue.push(item.values.peace);
                          //   } else if (item.values.valley !== null) {
                          //     this.dataValue.push({
                          //       value: item.values.valley,
                          //       itemStyle: {
                          //         color: "#41F39A",
                          //       },
                          //     });
                          //   } else if (item.values.peak !== null) {
                          //     this.dataValue.push({
                          //       value: item.values.peak,
                          //       itemStyle: {
                          //         color: "#F3BB3D",
                          //       },
                          //     });
                          //   } else {
                          //     this.dataValue.push("");
                          //   }
                          // });
                        });

                        // getDataSearch().then((res5) => {
                        //   console.log(res5);
                        // });
                      }
                    });
                  } else {
                    this.treeName = "";
                    this.data = [];
                  }
                } else {
                  this.$message.error(res3.data.msg);
                }
              });
            } else {
              this.$message.error(res2.data.msg);
            }
          });
        }
      }
    );

    // } else {
    //   this.$message.error(res1.data.msg);
    // }
    // });

    // let queryData = {
    //   endTime: "2021-05-19 00:00:00",
    //   energyAttrId: "P",
    //   energyTypeId: "elec",
    //   interval: 1,
    //   nodeId: "elec_a_1_1601",
    //   projectId: "demo",
    //   startTime: "2021-05-01 00:00:00",
    //   timeUnit: "DAYS",
    //   treeId: "elec_energy",
    // };
    // getDataSearchTable(queryData).then((resTable) => {
    //   this.loading = false;
    //   let temp1 = resTable.data.data.keys.splice(0, 3);
    //   console.log(temp1);
    //   this.tableKey = resTable.data.data.keys;
    //   console.log("this.tableKey", this.tableKey);
    //   this.tableData = resTable.data.data.values;
    // });
  },
  methods: {
    changeRadio(e) {
      // console.log(e);
      this.selectValue = "";
      this.selectId = "";
      this.attr1.forEach((item) => {
        if (item.attrId === e) {
          this.currentAttrName = item.attrName;
        }
      });
      // this.currentAttrName = ''
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    getTreeAttr(val1) {
      // debugger
      console.log(val1);
      // if (val1.children.length > 0) {
      //   // this.$refs.eltree.setCurrentNode(3);
      // } else {
      //   let data = {
      //     nodeId: val1.nodeId,
      //     projectId: "wtzx",
      //     treeId: this.treeId,
      //   };

      //   getTreeOption(data).then((res) => {
      //     console.log(res);
      //     let tempArr1 = res.data.data;
      //     let tempArr2 = res.data.data.splice(0, 3);
      //     this.attr1 = tempArr2;
      //     this.attr2 = tempArr1;
      //     console.log(this.attr1, this.attr2);
      //   });
      // }

      // if(val1.show){
      //   this.$message.info("展示节点不可选择")
      //   this.$refs.eltree.setCurrentNode('');
      //   return false
      // }
      let data = {
        nodeId: val1.nodeId,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
        treeId: this.treeId,
      };
      this.currentNodeID = val1.nodeId;
      this.currentNodeName = val1.nodeName;
      this.selectValue = "";
      // this.selectId = "";
      this.radio = "";
      this.selectId = "";
      getTreeOption(data).then((res) => {
        // console.log(res);
        let tempArr1 = res.data.data;
        let tempArr2 = res.data.data.splice(0, 3);
        this.attr1 = tempArr2;
        this.attr2 = tempArr1;
        // console.log(this.attr1, this.attr2);
        this.radio = tempArr2[0].attrId;
        this.currentAttrName = tempArr2[0].attrName;
      });
    },
    clickOtherTree(item, index) {
      // this.dataTreeArray.splice(index, 1, this.firstTree);
      this.dataTreeArray[index] = this.firstTree;
      this.firstTree = item;
      console.log("this.dataTreeArray", this.dataTreeArray);
      this.treeName = item.treeName;
      this.tableKey = [];
      this.tableData = [];
      this.currentAll = "";
      this.currentAllUnit = "";
      this.dataValueLine = [];
      this.dataValueBar = [];
      this.dataValueStackPeak = [];
      this.dataValueStackValley = [];
      this.dataValueStackPeace = [];
      this.dataXaxis = [];

      // this.firstTree = {};
      // this.treeName = "";
      this.treeId = item.treeId;
      this.data = [];
      this.attr1 = [];
      this.attr2 = [];
      this.selectValue = "";
      // this.selectId = "";

      this.radio = "";
      this.selectId = "";
      getTree(item.treeId).then((res4) => {
        if (res4.data) {
          // console.log(1);
          this.data = res4.data.data;
          if (this.data.length > 0) {
            this.defaultExpandArray.push(this.data[0].id);
            this.data[0].children.forEach((item1) => {
              this.defaultExpandArray.push(item1.id);
              // if(item1.children.length>0){
              //   item1.children.forEach(item2=>{
              //     this.defaultExpandArray.push(item2.id)
              //   })
              // }
            });

            this.$nextTick(() => {
              this.$refs.eltree.setCurrentKey(this.data[0].id);
            });
            this.currentNodeID = this.data[0].nodeId;
            this.currentNodeName = this.data[0].nodeName;
            let dataT = {
              nodeId: this.data[0].nodeId,
              projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
              treeId: item.treeId,
            };

            getTreeOption(dataT).then((res) => {
              // console.log(res);
              let tempArr1 = res.data.data;
              let tempArr2 = res.data.data.splice(0, 3);
              this.attr1 = tempArr2;
              this.attr2 = tempArr1;
              // console.log(this.attr1, this.attr2);
              this.radio = tempArr2[0].attrId;
              this.currentAttrName = tempArr2[0].attrName;
            });
          }
        }
      });
    },
    clickTabs(item) {
      this.currentTabs = item.energyTypeId;
      this.tableKey = [];
      this.tableData = [];
      this.currentAll = "";
      this.currentAllUnit = "";
      this.dataValueLine = [];
      this.dataValueBar = [];
      this.dataValueStackPeak = [];
      this.dataValueStackValley = [];
      this.dataValueStackPeace = [];
      this.dataXaxis = [];
      this.radio = "";
      this.selectId = "";
      let obj = {
        energyTypeId: this.currentTabs,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
      };
      this.dataTreeArray = [];
      getTreeInfo(obj).then((res3) => {
        if (res3.data) {
          if (res3.data.data.length > 0) {
            this.firstTree = res3.data.data[0];
            this.treeName = this.firstTree.treeName;
            this.treeId = this.firstTree.treeId;
            let temp1 = JSON.parse(JSON.stringify(res3.data.data));
            temp1.splice(0, 1);
            // console.log("temp2", temp2);
            this.dataTreeArray = temp1;
            // console.log("this.dataTreeArray", this.dataTreeArray);
            getTree(res3.data.data[0].treeId).then((res4) => {
              if (res4.data) {
                // console.log(1);
                this.data = res4.data.data;
                this.defaultExpandArray.push(this.data[0].id);
                this.data[0].children.forEach((item1) => {
                  this.defaultExpandArray.push(item1.id);
                  // if(item1.children.length>0){
                  //   item1.children.forEach(item2=>{
                  //     this.defaultExpandArray.push(item2.id)
                  //   })
                  // }
                });

                this.$nextTick(() => {
                  this.$refs.eltree.setCurrentKey(this.data[0].id);
                });
                this.currentNodeID = this.data[0].nodeId;
                this.currentNodeName = this.data[0].nodeName;
                let dataT = {
                  nodeId: this.data[0].nodeId,
                  projectId: JSON.parse(
                    window.localStorage.getItem("PROJECTID")
                  ),
                  treeId: this.treeId,
                };

                getTreeOption(dataT).then((res) => {
                  // console.log(res);
                  let tempArr1 = res.data.data;
                  let tempArr2 = res.data.data.splice(0, 3);
                  this.attr1 = tempArr2;
                  this.attr2 = tempArr1;
                  // console.log(this.attr1, this.attr2);
                  this.radio = tempArr2[0].attrId;
                  this.currentAttrName = tempArr2[0].attrName;
                });
              }
            });
          } else {
            this.firstTree = {};
            this.treeName = "";
            this.treeId = "";
            this.data = [];
            this.attr1 = [];
            this.attr2 = [];
            this.selectValue = "";
            this.selectId = "";
            // this.selectValueInterval = ""
          }
        } else {
          this.$message.error(res3.data.msg);
        }
      });
    },
    clickZhu() {
      if (this.isActiveType === "showZhe") {
        this.isActiveType = "showZhu";
        this.srcZhu = `${require("@/assets/柱状图1.png")}`;
        this.srcZhe = `${require("@/assets/折线图2.png")}`;
      } else {
        this.isActiveType = "showZhe";
        this.srcZhu = `${require("@/assets/柱状图2.png")}`;
        this.srcZhe = `${require("@/assets/折线图1.png")}`;
      }
    },
    clickZhe() {
      if (this.isActiveType === "showZhu") {
        this.isActiveType = "showZhe";
        this.srcZhu = `${require("@/assets/柱状图2.png")}`;
        this.srcZhe = `${require("@/assets/折线图1.png")}`;
      } else {
        this.isActiveType = "showZhu";
        this.srcZhu = `${require("@/assets/柱状图1.png")}`;
        this.srcZhe = `${require("@/assets/折线图2.png")}`;
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    analysisDataExport() {
      this.downXlsxStatus = true;
      const url = process.env.VUE_APP_BASE_API+"/analysis/data/export";
      let params = this.postqueryData;
      axios
        .post(url, params, {
          responseType: "blob",
          headers: this.headers,
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/xlsx",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          console.log(url);
          a.download = this.currentNodeName + ".xlsx";
          a.click();

          setTimeout(()=>{
            this.downXlsxStatus = false;
          },500)
        })
        .catch((err) => {
          console.log(err);
          setTimeout(()=>{
            this.downXlsxStatus = false;
          },500)
        });

      // analysisDataExport(this.postqueryData).then(res=>{
      //   if(res.status == 200){
      //     debugger
      //       const blob = new Blob([res.data], {
      //         type: 'application/vnd.ms-excel'
      //       })
      //       const url = window.URL.createObjectURL(blob)
      //       const a = document.createElement('a')
      //       a.href = url
      //       console.log(url)
      //       a.download = '值班日志.xls'
      //       a.click()
      //   }
      // })
    },
    onSearch() {
      this.tableKey = [];
      this.tableData = [];
      this.currentAll = "";
      this.currentAllUnit = "";
      this.dataValueLine = [];
      this.dataValueBar = [];
      this.dataValueStackPeak = [];
      this.dataValueStackValley = [];
      this.dataValueStackPeace = [];
      this.dataXaxis = [];
      let queryData = {
        endTime: "2022-01-01 00:00:00",
        energyAttrId: "P",
        energyTypeId: this.currentTabs,
        interval: 1,
        nodeId: this.currentNodeID,
        projectId: JSON.parse(window.localStorage.getItem("PROJECTID")),
        startTime: "2021-01-01 00:00:00",
        timeUnit: "DAYS",
        treeId: this.treeId,
      };
      if (this.radio === "" && this.selectId === "") {
        this.$message.info("未选择属性");
        return;
      }

      if (this.radio) {
        queryData.energyAttrId = this.radio;
      } else if (this.selectValue) {
        this.currentAttrName = this.selectValue;
        queryData.energyAttrId = this.selectId;
      }
      var dateTime = new Date(this.value1);
      if (this.isActivenTime === 1) {
        // queryData
        if (this.selectValueInterval === "5分钟") {
          queryData.timeUnit = "MINUTES";
          queryData.interval = 5;
          queryData.startTime = this.value1;
          this.changeZoom = 10;

          dateTime = dateTime.setDate(dateTime.getDate() + 1);
          dateTime = this.formatDate(dateTime);
          // console.log("dateTime", dateTime);
          queryData.endTime = dateTime;
        } else if (this.selectValueInterval === "1小时") {
          queryData.timeUnit = "HOURS";
          queryData.interval = 1;
          queryData.startTime = this.value1;
          this.changeZoom = 80;
          // var dateTime = new Date(this.value1);
          dateTime = dateTime.setDate(dateTime.getDate() + 1);
          dateTime = this.formatDate(dateTime);
          // console.log("dateTime", dateTime);
          queryData.endTime = dateTime;
        }
      } else if (this.isActivenTime === 2) {
        if (this.selectValueInterval === "1小时") {
          queryData.timeUnit = "HOURS";
          this.changeZoom = 5;
          queryData.interval = 1;
          queryData.startTime = this.value1;
          // var dateTime = new Date(this.value1);
          dateTime = dateTime.setMonth(dateTime.getMonth() + 1);
          dateTime = this.formatDate(dateTime);
          // console.log("dateTime", dateTime);
          queryData.endTime = dateTime;
        } else if (this.selectValueInterval === "1天") {
          queryData.timeUnit = "DAYS";
          queryData.interval = 1;
          this.changeZoom = 100;
          queryData.startTime = this.value1;
          // var dateTime = new Date(this.value1);
          dateTime = dateTime.setMonth(dateTime.getMonth() + 1);
          dateTime = this.formatDate(dateTime);
          // console.log("dateTime", dateTime);
          queryData.endTime = dateTime;
        }
      } else if (this.isActivenTime === 3) {
        if (this.selectValueInterval === "1天") {
          queryData.timeUnit = "DAYS";
          queryData.interval = 1;
          this.changeZoom = 10;
          queryData.startTime = this.value1;
          // var dateTime = new Date(this.value1);
          dateTime = dateTime.setFullYear(dateTime.getFullYear() + 1);
          dateTime = this.formatDate(dateTime);
          // console.log("dateTime", dateTime);
          queryData.endTime = dateTime;
        } else if (this.selectValueInterval === "1月") {
          queryData.timeUnit = "MONTHS";
          queryData.interval = 1;
          this.changeZoom = 100;
          queryData.startTime = this.value1;
          // var dateTime = new Date(this.value1);
          dateTime = dateTime.setFullYear(dateTime.getFullYear() + 1);
          dateTime = this.formatDate(dateTime);
          // console.log("dateTime", dateTime);
          queryData.endTime = dateTime;
        }
      }

      // console.log(this.radio, this.value1);
      this.dataXaxis = [];
      this.dataValueLine = [];
      this.dataValueBar = [];
      this.dataValueStackPeak = [];
      this.dataValueStackValley = [];
      this.dataValueStackPeace = [];
      this.postqueryData = queryData;
      getDataSearch(queryData).then((res5) => {
        // console.log(res5);
        res5.data.data.forEach((item) => {
          if (this.isActivenTime === 3) {
            let tempA = item.datetime.split(" ");
            // console.log(tempA);
            this.dataXaxis.push(tempA[0]);
            this.isStack = true;
            if (item.all === null) {
              this.dataValueLine.push("");
            } else {
              this.dataValueLine.push(item.all);
            }

            if (item.values.peak === null) {
              this.dataValueStackPeak.push("");
            } else {
              this.dataValueStackPeak.push(item.values.peak);
            }

            if (item.values.valley === null) {
              this.dataValueStackValley.push("");
            } else {
              this.dataValueStackValley.push(item.values.valley);
            }

            if (item.values.peace === null) {
              this.dataValueStackPeace.push("");
            } else {
              this.dataValueStackPeace.push(item.values.peace);
            }
            // this.dataValueStackPeak.push(item.values.peak);
            // this.dataValueStackValley.push(item.values.valley);
            // this.dataValueStackPeace.push(item.values.peace);

            // if (this.selectValueInterval === "1天") {
            //   this.isStack = true
            //   this.dataValueLine.push(item.all)
            //   this.dataValueStackPeak.push(item.values.peak)
            //   this.dataValueStackValley.push(item.values.valley)
            //   this.dataValueStackPeace.push(item.values.peace)
            // }
            // else{
            //   this.isStack = true
            //   this.dataValueLine.push(item.all)
            // }

            // if (item.values.peace !== null) {
            //   this.dataValue.push(item.values.peace);
            // } else if (item.values.valley !== null) {
            //   this.dataValue.push({
            //     value: item.values.valley,
            //     itemStyle: {
            //       color: "#41F39A",
            //     },
            //   });
            // } else if (item.values.peak !== null) {
            //   this.dataValue.push({
            //     value: item.values.peak,
            //     itemStyle: {
            //       color: "#F3BB3D",
            //     },
            //   });
            // } else {
            //   this.dataValue.push("");
            // }
          }
          if (this.isActivenTime === 1) {
            let tempA = item.datetime.split(" ");
            // console.log(tempA);
            this.dataXaxis.push(tempA[1]);
            this.isStack = false;

            if (item.values.peace !== null) {
              this.dataValueLine.push(item.values.peace);
              this.dataValueBar.push(item.values.peace);
            } else if (item.values.valley !== null) {
              this.dataValueLine.push({
                value: item.values.valley,
                itemStyle: {
                  color: "#41F39A",
                },
              });
              this.dataValueBar.push({
                value: item.values.valley,
                itemStyle: {
                  color: "#41F39A",
                },
              });
            } else if (item.values.peak !== null) {
              this.dataValueLine.push({
                value: item.values.peak,
                itemStyle: {
                  color: "#F3BB3D",
                },
              });
              this.dataValueBar.push({
                value: item.values.peak,
                itemStyle: {
                  color: "#F3BB3D",
                },
              });
            } else {
              this.dataValueLine.push("");
              this.dataValueBar.push("");
            }
          }
          if (this.isActivenTime === 2) {
            if (this.selectValueInterval === "1天") {
              this.isStack = true;
              let tempA = item.datetime.split(" ");
              // console.log(tempA);
              this.dataXaxis.push(tempA[0]);
              if (item.all === null) {
                this.dataValueLine.push("");
              } else {
                this.dataValueLine.push(item.all);
              }

              if (item.values.peak === null) {
                this.dataValueStackPeak.push("");
              } else {
                this.dataValueStackPeak.push(item.values.peak);
              }

              if (item.values.valley === null) {
                this.dataValueStackValley.push("");
              } else {
                this.dataValueStackValley.push(item.values.valley);
              }

              if (item.values.peace === null) {
                this.dataValueStackPeace.push("");
              } else {
                this.dataValueStackPeace.push(item.values.peace);
              }
              // if (item.values.peace !== null) {
              //   this.dataValue.push(item.values.peace);
              // } else if (item.values.valley !== null) {
              //   this.dataValue.push({
              //     value: item.values.valley,
              //     itemStyle: {
              //       color: "#41F39A",
              //     },
              //   });
              // } else if (item.values.peak !== null) {
              //   this.dataValue.push({
              //     value: item.values.peak,
              //     itemStyle: {
              //       color: "#F3BB3D",
              //     },
              //   });
              // } else {
              //   this.dataValue.push("");
              // }
            } else {
              this.isStack = false;
              this.dataXaxis.push(item.datetime);
              if (item.values.peace !== null) {
                this.dataValueLine.push(item.values.peace);
                this.dataValueBar.push(item.values.peace);
              } else if (item.values.valley !== null) {
                this.dataValueLine.push({
                  value: item.values.valley,
                  itemStyle: {
                    color: "#41F39A",
                  },
                });
                this.dataValueBar.push({
                  value: item.values.valley,
                  itemStyle: {
                    color: "#41F39A",
                  },
                });
              } else if (item.values.peak !== null) {
                this.dataValueLine.push({
                  value: item.values.peak,
                  itemStyle: {
                    color: "#F3BB3D",
                  },
                });
                this.dataValueBar.push({
                  value: item.values.peak,
                  itemStyle: {
                    color: "#F3BB3D",
                  },
                });
              } else {
                this.dataValueLine.push("");
                this.dataValueBar.push("");
              }
            }
          }
        });
        getDataSearchTable(queryData).then((resTable) => {
          // console.log(resTable);
          this.loading = false;
          resTable.data.data.keys.splice(0, 3);
          // console.log(temp1);
          this.tableKey = resTable.data.data.keys;
          // if (this.isActivenTime === 1) {
          //   resTable.data.data.keys.forEach((item) => {
          //     this.tableKey.push(
          //       item.split(" ")[1].split(":")[0] +
          //         ":" +
          //         item.split(" ")[1].split(":")[1]
          //     );
          //   });
          // }
          // if (this.isActivenTime === 2) {
          //   if (this.selectValueInterval === "1小时") {
          //     resTable.data.data.keys.forEach((item) => {
          //       this.tableKey.push(
          //         item.split(" ")[1].split(":")[0] +
          //           ":" +
          //           item.split(" ")[1].split(":")[1]
          //       );
          //     });
          //   }
          //   if (this.selectValueInterval === "1天") {
          //     resTable.data.data.keys.forEach((item) => {
          //       this.tableKey.push(item.split(" ")[0].split("-")[2]);
          //     });
          //   }
          // }
          // if (this.isActivenTime === 3) {
          //   if (this.selectValueInterval === "1天") {
          //     resTable.data.data.keys.forEach((item) => {
          //       this.tableKey.push(item.split(" ")[0].split("-")[2]);
          //     });
          //   }
          //   if (this.selectValueInterval === "1月") {
          //     resTable.data.data.keys.forEach((item) => {
          //       this.tableKey.push(item.split(" ")[0].split("-")[1]);
          //     });
          //   }
          // }

          // console.log("this.tableKey", this.tableKey);
          this.tableData = resTable.data.data.values;
          this.currentAll = resTable.data.data.values[0].all;
          this.currentAllUnit = resTable.data.data.values[0].unit;
        });
        // console.log("this.dataValue", this.dataValue);
      });
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    clickTime(index) {
      this.isActivenTime = index;

      if (index == 1) {
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD + " 00:00:00";
        this.changeDateType = "date";
        this.attrInterval = ["5分钟", "1小时"];
        this.selectValueInterval = "5分钟";
      } else if (index == 2) {
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD + " 00:00:00";
        this.changeDateType = "month";
        this.attrInterval = ["1小时", "1天"];
        this.selectValueInterval = "1小时";
      } else if (index == 3) {
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD + " 00:00:00";
        this.changeDateType = "year";
        this.attrInterval = ["1天", "1月"];
        this.selectValueInterval = "1天";
      }
    },
    showSelect() {
      this.isShowSelect = !this.isShowSelect;
    },
    clickOption2(v) {
      // debugger
      this.isShowSelect = false;
      this.radio = "";
      this.selectValue = v.attrName;
      this.currentAttrName = this.selectValue;
      this.selectId = v.attrId;
    },
    clickOptionInterval(v) {
      this.isShowSelectInterval = false;

      this.selectValueInterval = v;
    },
    toProjectOverall() {
      this.$router.push({ name: "overall" });
    },
    clickInterval(e) {
      e.stopPropagation();
      this.isShowSelectInterval = !this.isShowSelectInterval;
    },
    clickSelect(e) {
      e.stopPropagation();
      this.isShowSelect = !this.isShowSelect;
    },
    falseAll() {
      this.isShowSelect = false;
      this.isShowSelectInterval = false;
    },
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
    }),
    tuopuName() {
      if (this.clickCatagory === 1) {
        return "水能源拓扑图";
      } else if (this.clickCatagory === 2) {
        return "电能源拓扑图";
      } else if (this.clickCatagory === 3) {
        return "气能源拓扑图";
      } else {
        return "热能源拓扑图";
      }
    },
    chartLineOption1() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          // formatter: "{b}: {c}",
          formatter: (params) => {
            // debugger;
            // const time = that.chartData.value[params[0].dataIndex].time;
            // if (params[0].value[1] === 0) {
            //   return (
            //     (this.fixType(0, that.chartData.symbol) +
            //         " " +
            //         that.chartData.unit
            //       ) +
            //     "<br>" +
            //     time
            //   );
            // }
            return (
              "时间：" + params[0].axisValue + "<br>" + "值：" + params[0].value
              // (params[0].value[1]
              //   ? this.fixType(params[0].value[1], that.chartData.symbol) +
              //     " " +
              //     that.chartData.unit
              //   : "") +
              // "<br>" +
              // time
            );
          },
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "70px",
        },
        xAxis: {
          data: this.dataXaxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
            // rotate:"45"
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
          min: function (value) {
            // debugger
            return value.min;
          },
        },
        dataZoom: [
          {
            type: "slider",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
          {
            type: "inside",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
        ],
        series: [
          {
            data: this.dataValueLine,
            type: "line",
            smooth: true,
            lineStyle: {
              width: 4,
              shadowColor: "#08A758",
              shadowBlur: 5,
              shadowOffsetX: 2,
              shadowOffsetY: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#3F6DF8",
                },
                {
                  offset: 0.5,
                  color: "#47A5E1",
                },
                {
                  offset: 1,
                  color: "#55FFBC",
                },
              ]),
            },
          },
        ],
      };
    },
    chartBarOptionStack() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          // formatter: "{b}: {c}",
          formatter: (params) => {
            // debugger;
            // const time = that.chartData.value[params[0].dataIndex].time;
            // if (params[0].value[1] === 0) {
            //   return (
            //     (this.fixType(0, that.chartData.symbol) +
            //         " " +
            //         that.chartData.unit
            //       ) +
            //     "<br>" +
            //     time
            //   );
            // }
            return (
              "时间：" + params[0].axisValue + "<br>" + "值：" + params[0].value
              // (params[0].value[1]
              //   ? this.fixType(params[0].value[1], that.chartData.symbol) +
              //     " " +
              //     that.chartData.unit
              //   : "") +
              // "<br>" +
              // time
            );
          },
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "70px",
        },
        xAxis: {
          data: this.dataXaxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          // name:"()",
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "slider",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
          {
            type: "inside",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
        ],
        series: [
          {
            name: "平",
            type: "bar",
            stack: "广告",
            // barWidth: 26,
            // barGap:"200%",
            itemStyle: {
              color: "#417AF3",
              borderRadius: [0, 0, 15, 15],
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: this.dataValueStackPeace,
          },
          {
            name: "谷",
            type: "bar",
            stack: "广告",
            // barWidth: 26,
            // barGap:"200%",
            itemStyle: {
              color: "#41F39A",
              borderRadius: [0, 0, 0, 0],
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: this.dataValueStackValley,
          },
          {
            name: "峰",
            type: "bar",
            // barWidth: 26,
            // barGap:"200%",
            stack: "广告",
            itemStyle: {
              color: "#F3BB3D",
              borderRadius: [15, 15, 0, 0],
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: this.dataValueStackPeak,
          },
        ],
      };
    },
    chartBarOption1() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          // formatter: "{b}: {c}",
          formatter: (params) => {
            // debugger;
            // const time = that.chartData.value[params[0].dataIndex].time;
            // if (params[0].value[1] === 0) {
            //   return (
            //     (this.fixType(0, that.chartData.symbol) +
            //         " " +
            //         that.chartData.unit
            //       ) +
            //     "<br>" +
            //     time
            //   );
            // }
            return (
              "时间：" + params[0].axisValue + "<br>" + "值：" + params[0].value
              // (params[0].value[1]
              //   ? this.fixType(params[0].value[1], that.chartData.symbol) +
              //     " " +
              //     that.chartData.unit
              //   : "") +
              // "<br>" +
              // time
            );
          },
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "70px",
        },
        xAxis: {
          data: this.dataXaxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#082254",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          // name:"()",
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,1)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#082254",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "slider",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
          {
            type: "inside",
            // type: "inside",
            start: 0,
            // end: this.changeZoom,
            end: 100,
          },
        ],
        series: [
          {
            name: "当月用能",
            type: "bar",
            // barWidth: 26,
            // barGap:"200%",
            itemStyle: {
              color: "#417AF3",
              borderRadius: 15,
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#55ffbc" },
            //       { offset: 0.62, color: "#47A5E1" },
            //       { offset: 1, color: "#3F6DF8" },
            //     ]),
            //   },
            // },
            data: this.dataValueBar,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.other-tree:last-child {
  border-bottom: 1px solid #3f6df8 !important;
}
.tuopu {
  width: 100%;
  height: calc(100% - 100px);
  background: url("~@/assets/电拓扑.png");
  background-size: cover;
  position: relative;
}
.isClick {
  color: #3e6cf7 !important;
  border-bottom: 4px solid #3e6cf7;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.date >>> input {
  width: 180px !important;
  height: 29px !important;
}
.date >>> .el-input__icon {
  line-height: 29px !important;
}

.select >>> input {
  width: 140px !important;
  height: 29px !important;
  color: #3f6df8;
  cursor: pointer;
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
/* .el-tree >>> .is-current .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
.activeOption2 {
  background: #3f6df8;
  color: white;
}
.el-tree >>> .el-tree-node {
  overflow: auto !important;
  height: 100% !important;
}
.select2 >>> input {
  width: 160px !important;
  height: 29px !important;
  cursor: pointer;
}
.select2 >>> .el-input__icon {
  line-height: 29px !important;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.el-tree >>> .el-tree-node__content {
  height: 36px !important;
}
.el-tree {
  color: #082254 !important;
}
</style>
<style></style>
