<template>
  <div style="width: 100%; height: 100%; background: white; padding: 15px">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div>报表名称：</div>
        <div
          style="position: relative; margin-left: 5px; cursor: pointer"
          class="select"
        >
          <el-input
            v-model="query.reportName"
            placeholder="请输入报表名称"
            style="width: 160px"
          >
          </el-input>
        </div>

        <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
            margin-right: 20px;
            margin-left: 20px;
            cursor: pointer;
          "
          @click="searchFor"
        >
          查询
        </div>
      </div>

      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
            margin-right: 20px;
            cursor: pointer;
          "
          @click="toAdd"
        >
          新增
        </div>
        <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
          "
        >
          数据导出
        </div>
      </div>
    </div>
    <div style="width: 100%">
      <!-- <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 30px"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column fixed prop="name" label="监测点位" width="120">
        </el-table-column>
        <el-table-column fixed prop="unit" label="单位" width="120">
        </el-table-column>
      </el-table> -->

      <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 10px"
        :border="isborder1"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <!-- <el-table-column
          
          prop="reportId"
          label="报表ID"
          width="100"
          align="left"
        >
        </el-table-column> -->
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="name" label="报表名称" width="150">
        </el-table-column>
        <el-table-column prop="released" label="状态" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.released" style="">发布</div>
            <div v-else>暂停</div>
          </template>
        </el-table-column>
        <el-table-column label="报表参数" width="200">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="reportTypeV(scope.row.reportPara)"
              placement="bottom"
            >
              <div
                style="
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                <span
                  style="margin-right: 5px"
                  v-for="(item, index) in scope.row.reportPara"
                  :key="index"
                  >{{ item }}</span
                >
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- <el-table-column label="报表类型" width="200">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="reportTypeV(scope.row.reportTypeAndParas)"
              placement="bottom"
            >
              <div
                style="
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                <span
                  style="margin-right: 5px"
                  v-for="(item, index) in scope.row.reportTypeAndParas"
                  :key="index"
                  >{{ item.reportTypeName }}</span
                >
              </div>
            </el-tooltip>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="报表参数" width="200">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="reportTypeV2(scope.row.reportTypeAndParas)"
              placement="bottom"
            >
              <div
                style="
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                <span
                  v-for="(item, index) in scope.row.reportTypeAndParas"
                  :key="index"
                >
                  <template v-if="item.reportParas">
                    <span
                      style="margin-right: 5px"
                      v-for="(item2, index2) in item.reportParas"
                      :key="index2"
                      >{{ item2.reportTypeName }}</span
                    >
                  </template>
                </span>
              </div>
            </el-tooltip>
          </template>
        </el-table-column> -->
        <el-table-column prop="beginHour" label="日起始点" width="100">
        </el-table-column>
        <el-table-column prop="beginDay" label="月起始点" width="100">
        </el-table-column>
        <el-table-column
          prop="instanceAndAttrName"
          label="绑定树结构"
          width="150"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.detailTreeName">
              <span
                style="color: #3f6df8; cursor: pointer"
                @click="toBind(scope.row)"
              >
                {{ scope.row.detailTreeName }}
              </span>
            </template>
            <template v-else>
              <span
                style="color: #3f6df8; cursor: pointer"
                @click="toBind(scope.row)"
              >
                绑定树结构
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="memo" label="备注" width="150">
        </el-table-column>
        <el-table-column prop="createUser" label="创建者" width="150">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              v-if="scope.row.released"
              style="color: #f2bc40; margin-right: 5px; cursor: pointer"
              @click="changeStatus(scope.row.reportId, 0)"
            >
              暂停
            </span>
            <span
              v-else
              style="color: #02b84b; margin-right: 5px; cursor: pointer"
              @click="changeStatus(scope.row.reportId, 1)"
            >
              发布
            </span>
            <!-- <span
              style="color: #3f6df8; margin-right: 5px; cursor: pointer"
              @click="showDetail(scope.row.reportId)"
            >
              详情
            </span> -->
            <span
              style="color: #3f6df8; margin-right: 5px; cursor: pointer"
              @click="showUpdate(scope.row.reportId)"
            >
              修改
            </span>
            <span
              style="color: #e52323; margin-right: 5px; cursor: pointer"
              @click="deleteReport(scope.row.reportId)"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 15px; text-align: right">
        <el-pagination
          @size-change="sizeChange"
          @current-change="pageChange"
          :current-page="query.page"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="query.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      title="绑定树结构"
      :visible.sync="changeTreeVisible"
      :close-on-click-modal="false"
      width="70%"
      center
    >
      <el-form
        :model="treeForm"
        :rules="rules"
        :inline="true"
        ref="treeForm"
        label-width="100px"
      >
        <el-form-item
          label="树结构名称："
          prop="treeName"
          style="position: relative"
          class="tree-big"
        >
          <el-input v-model="treeForm.treeName" :disabled="isView"></el-input>
          <div
            style="
              position: absolute;
              width: 26px;
              height: 26px;
              color: #999999;
              text-align: center;
              line-height: 24px;
              border: 1px solid #999999;
              border-radius: 2px;
              font-size: 20px;
              top: 7px;
              right: -40px;
              cursor: pointer;
            "
            @click="addTreeNode"
            v-if="!isView"
          >
            +
          </div>
        </el-form-item>

        <div
          style="
            width: 100%;
            min-height: 124px;
            background: rgba(63, 109, 248, 0.1);
            margin-bottom: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;
          "
          v-for="(item, index) in testList"
          :key="index"
        >
          <div
            v-if="!isView"
            style="position: absolute; top: 3px; right: 10px; cursor: pointer"
            @click="deleteTree(index)"
          >
            <i class="el-icon-circle-close" style="color: #406df8"></i>
          </div>
          <div>
            <el-form-item
              label="父节点："
              prop="reportName"
              style="position: relative; margin-bottom: 10px"
              class="inline-input"
            >
              <el-input :disabled="isView" v-model="item.nodeName" placeholder="请输入名称">
              </el-input>
            </el-form-item>
            <el-form-item
              label="数据源类型："
              prop="reportName"
              style="position: relative"
              class="inline-input"
            >
              <el-select :disabled="isView" v-model="item.show" placeholder="请选择">
                <el-option
                  v-for="item in optionsSourceType"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>

              <div
                style="position: absolute; top: 0; right: -25px"
                v-if="item.show"
              >
                <el-dropdown trigger="click" @command="handleCommand" v-if="!isView">
                  <i class="el-icon-more-outline"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="{
                        command: 'a',
                        index: index,
                      }"
                      >新增子节点</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="{
                        command: 'b',
                        index: index,
                      }"
                      >删除该节点</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <!-- <div style="position: absolute; top: 0; right: -40px">
                <i class="el-icon-circle-plus-outline"></i>
              </div>
              <div style="position: absolute; top: 0; right: -80px">
                <i class="el-icon-circle-close"></i>
              </div> -->
            </el-form-item>

            <el-form-item
              v-if="!item.show"
              label="绑定仪表："
              prop="reportName"
              style="position: relative"
              class="inline-input"
            >
              <el-select filterable :disabled="isView" v-model="item.instanceId" placeholder="请选择">
                <el-option
                  v-for="item in insatnceArr"
                  :key="item.instanceId"
                  :label="item.name"
                  :value="item.instanceId"
                >
                </el-option>
              </el-select>

              <div style="position: absolute; top: 0; right: -25px" v-if="!isView">
                <el-dropdown trigger="click" @command="handleCommand">
                  <i class="el-icon-more-outline"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="{
                        command: 'a',
                        index: index,
                      }"
                      >新增子节点</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="{
                        command: 'b',
                        index: index,
                      }"
                      >删除该节点</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
          </div>
          <form-list :list="testList[index].childNodeList" :isView="isView"></form-list>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeTreeVisible = false">取 消</el-button>
        <el-button type="primary" @click="bindTree">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="新增报表"
      :visible.sync="changePasswordVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item
          label="报表名称："
          prop="reportName"
          style="position: relative"
        >
          <el-input v-model="ruleForm.reportName"></el-input>
          <div
            style="
              position: absolute;
              width: 26px;
              height: 26px;
              color: #999999;
              text-align: center;
              line-height: 24px;
              border: 1px solid #999999;
              border-radius: 2px;
              font-size: 20px;
              top: 7px;
              right: 20px;
              cursor: pointer;
            "
            @click="addTypes"
          >
            +
          </div>
        </el-form-item>

        <div
          style="
            width: 100%;
            height: 124px;
            background: rgba(63, 109, 248, 0.1);
            margin-bottom: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;
          "
          v-for="(item, index) in ruleForm.reportTypesArray"
          :key="index"
        >
          <div
            style="position: absolute; top: 3px; right: 10px; cursor: pointer"
            @click="deleteTypes(index)"
          >
            <i class="el-icon-circle-close" style="color: #406df8"></i>
          </div>
          <el-form-item
            label="报表类型："
            prop="reportName"
            style="position: relative; margin-bottom: 10px"
          >
            <el-select
              v-model="item.params1"
              placeholder="请选择"
              @change="getOption($event, index)"
            >
              <el-option
                v-for="item in options1"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!--  -->
          <el-form-item
            v-if="options2[index].length > 0"
            label="报表参数："
            prop="reportName"
            style="position: relative"
          >
            <el-select v-model="item.params2" multiple placeholder="请选择">
              <el-option
                v-for="item in options2[index]"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div
          style="
            width: 100%;
            height: 125px;
            background: rgba(63, 109, 248, 0.1);
            margin-bottom: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;
          "
        >
          <el-form-item
            label="日起始点："
            prop="reportName"
            style="position: relative; margin-bottom: 10px"
          >
            <div
              style="
                width: 350px;
                height: 32px;
                margin-top: 4px;
                color: #606266;
                line-height: 40px;
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                background: white;
                padding-left: 30px;
                padding-right: 15px;
                line-height: 30px;
                position: relative;
                cursor: pointer;
              "
              @click="showDaySelect"
            >
              <div style="position: absolute; left: 10px">
                <i class="el-icon-date" style="color: #c0c4cc"></i>
              </div>
              <div style="position: absolute; right: 10px">
                <i class="el-icon-arrow-down" style="color: #c0c4cc"></i>
              </div>
              当日 {{ selectTimeV }}时

              <div
                style="
                  position: absolute;
                  height: 138px;
                  width: 350px;
                  background: #ffffff;
                  border-radius: 2px;
                  border: 1px solid #3f6df8;
                  top: 40px;
                  left: 0;
                  padding: 20px;
                  z-index: 99999;
                "
                v-if="isShowDay"
              >
                <div
                  class="time-square"
                  :class="{ isSelectTime: selectTimeV === item }"
                  style="
                    width: 30px;
                    height: 30px;
                    color: #082254;
                    font-size: 14px;
                    line-height: 30px;
                    text-align: center;
                    float: left;
                    margin-bottom: 5px;
                    cursor: pointer;
                  "
                  v-for="(item, index) in timeArray"
                  :key="index"
                  @click="selectTimeOption(item, $event)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item
            label="月起始点："
            prop="reportName"
            style="position: relative; margin-bottom: 10px"
          >
            <div
              style="
                width: 350px;
                height: 32px;
                margin-top: 4px;
                color: #606266;
                line-height: 40px;
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                background: white;
                padding-left: 30px;
                padding-right: 15px;
                line-height: 30px;
                position: relative;
                cursor: pointer;
              "
              @click="showMonthSelect"
            >
              <div style="position: absolute; left: 10px">
                <i class="el-icon-date" style="color: #c0c4cc"></i>
              </div>
              <div style="position: absolute; right: 10px">
                <i class="el-icon-arrow-down" style="color: #c0c4cc"></i>
              </div>
              上月 {{ selectTimeV21 }}日
              <!-- {{ selectTimeV22 }}时 -->
              <div
                style="
                  position: absolute;
                  height: 165px;
                  width: 350px;
                  background: #ffffff;
                  border-radius: 2px;
                  border: 1px solid #3f6df8;
                  top: 40px;
                  left: 0;
                  padding: 20px;
                  z-index: 99999;
                "
                v-if="isShowMonth"
              >
                <!-- <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 20px;
                  "
                >
                  <div>日期选择：</div>
                  <div class="small-s">
                    <el-select
                      v-model="selectTimeV21"
                      placeholder="请选择"
                      @change="getOption"
                    >
                      <el-option
                        v-for="item in optionsDate"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div> -->
                <div
                  class="time-square"
                  :class="{ isSelectTime: selectTimeV21 === item }"
                  style="
                    width: 30px;
                    height: 30px;
                    color: #082254;
                    font-size: 14px;
                    line-height: 30px;
                    text-align: center;
                    float: left;
                    margin-bottom: 5px;
                    cursor: pointer;
                  "
                  v-for="(item, index) in timeArray2"
                  :key="index"
                  @click="selectTimeOption2(item, $event)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
        <el-form-item
          label="备注："
          prop="memo"
          style="position: relative; margin-top: 20px; margin-bottom: 0"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
            v-model="ruleForm.memo"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePasswordVisible = false">取 消</el-button>
        <el-button type="primary" @click="addNew">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import formList from "./list";
import {
  bindInstanceTree,
  getInstanceTree,
  reportManageSearch,
  getReportType,
  getReportTypeParams,
  reportManageAdd,
  reportManagechangeStatus,
  reportManagedeatil,
  reportManageupdate,
  reportManageDelete,
  getInstance,
  setProject,
} from "@/request/apiLg";
export default {
  components: {
    formList,
  },
  data() {
    return {
      isView:false,
      isEdit: false,
      testList: [
        {
          nodeName: "",
          show: true,
          childNodeList: [],
        },
      ],
      // testList: [
      //   {
      //     childNodeList: [
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             instanceId: "",
      //             nodeName: "二级节点",
      //             show: false,
      //           },
      //         ],

      //         nodeName: "一级节点",
      //         show: true,
      //       },
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             nodeName: "二级节点1",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点1",
      //         show: true,
      //       },
      //     ],
      //     // instanceId: "root",
      //     nodeName: "根节点",
      //     show: true,
      //   },
      //   {
      //     childNodeList: [
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             // instanceId: "second",
      //             nodeName: "二级节点",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点",
      //         show: true,
      //       },
      //       {
      //         childNodeList: [
      //           {
      //             childNodeList: [],
      //             // instanceId: "second",
      //             nodeName: "二级节点1",
      //             show: true,
      //           },
      //         ],
      //         // instanceId: "first",
      //         nodeName: "一级节点1",
      //         show: true,
      //       },
      //     ],
      //     // instanceId: "root",
      //     nodeName: "根节点",
      //     show: true,
      //   },
      // ],
      listTemp: [
        {
          name: 1,
          children: [
            {
              name: 2,
              children: [
                {
                  name: 3,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      changeTreeVisible: false,
      optionsInstance: [],
      optionsSourceType: [
        {
          name: "展示节点",
          code: true,
        },
        {
          name: "具体仪表",
          code: false,
        },
      ],
      dateV: 1,
      optionsDate: [
        {
          name: "1日",
          code: 1,
        },
        {
          name: "2日",
          code: 2,
        },
        {
          name: "3日",
          code: 3,
        },
        {
          name: "4日",
          code: 4,
        },
        {
          name: "5日",
          code: 5,
        },

        {
          name: "6日",
          code: 6,
        },
        {
          name: "7日",
          code: 7,
        },
        {
          name: "8日",
          code: 8,
        },
        {
          name: "9日",
          code: 9,
        },
        {
          name: "10日",
          code: 10,
        },
        {
          name: "11日",
          code: 11,
        },
        {
          name: "12日",
          code: 12,
        },
        {
          name: "13日",
          code: 13,
        },
        {
          name: "14日",
          code: 14,
        },
        {
          name: "15日",
          code: 15,
        },
        {
          name: "16日",
          code: 16,
        },
        {
          name: "17日",
          code: 17,
        },
        {
          name: "18日",
          code: 18,
        },
        {
          name: "19日",
          code: 19,
        },
        {
          name: "20日",
          code: 20,
        },
        {
          name: "21日",
          code: 21,
        },
        {
          name: "22日",
          code: 22,
        },
        {
          name: "23日",
          code: 23,
        },
        {
          name: "24日",
          code: 24,
        },
        {
          name: "25日",
          code: 25,
        },
        {
          name: "26日",
          code: 26,
        },
        {
          name: "27日",
          code: 27,
        },
        {
          name: "28日",
          code: 28,
        },
        {
          name: "29日",
          code: 29,
        },
        {
          name: "30日",
          code: 30,
        },
        {
          name: "31日",
          code: 31,
        },
      ],
      selectTimeV21: 1,
      selectTimeV22: 0,
      isShowMonth: false,
      selectTimeV: 0,
      timeArray: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      timeArray2: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      treeReportId: "",
      isShowDay: false,
      options1: [],
      options2: [[]],
      rules: {
        banner: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        activeName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
      },
      ruleForm: {
        reportName: "",
        beginDay: "",
        beginHour: "",
        reportTypesArray: [
          {
            params1: "",
            params2: [],
          },
        ],
      },
      treeForm: {
        treeName: "",
        treeArray: [1],
      },
      changePasswordVisible: false,
      total: 0,
      loading: true,
      value11: "",
      value22: "",
      currentName: "",
      isInterval: false,
      value2: [],
      tableKey: [],
      tableKey2: [],
      isborder1: false,
      tableData: [],
      changeDateType: "date",
      selectValueX: "",
      isShowSelectx: false,
      selectValueY: "",
      isShowSelecty: false,
      // options1: [
      //   {
      //     value: "P",
      //     label: "电能-有功功率",
      //   },
      // ],
      // options2: [
      //   {
      //     value: "P",
      //     label: "水能-瞬时流量",
      //   },
      // ],
      value1: "",
      // value2: "",
      isActivenTime: 1,
      query: {
        limit: 10,
        page: 1,
        reportName: "",
      },
    };
  },
  mounted() {
    // let dateT = new Date();
    // var YY = dateT.getFullYear() + "-";
    // var MM =
    //   (dateT.getMonth() + 1 < 10
    //     ? "0" + (dateT.getMonth() + 1)
    //     : dateT.getMonth() + 1) + "-";
    // var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    // this.value1 = YY + MM + DD;
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          reportManageSearch(this.query).then((res2) => {
            this.loading = false;
            console.log(res2);
            this.tableData = res2.data.data;
            this.total = res2.data.count;
            // this.tableKey = res2.data.data.timestamps;
          });
          getReportType().then((res) => {
            this.options1 = res.data.data;
            getReportTypeParams(this.options1[0].code).then((res2) => {
              this.options2[0] = res2.data.data;
            });
          });
          getInstance().then((res) => {
            // this.optionsInstance = res.data.data;
            this.mutationsGetInstance(res.data.data);
          });
        }
      }
    );
  },
  computed: {
    ...mapState({
      insatnceArr: (state) => state.insatnceArr,
    }),
  },
  methods: {
    showDetail(id) {
      this.$router.push({
        name: "reportDetail",
        query: { id: id },
      });
    },
    ...mapMutations(["mutationsGetInstance"]),
    showUpdate(id) {
      reportManagedeatil(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          this.changePasswordVisible = true;
          this.isEdit = true;
          this.ruleForm = resA.data.data;
          this.ruleForm.reportName = resA.data.data.name;
          this.selectTimeV21 = resA.data.data.beginDay;
          this.selectTimeV = resA.data.data.beginHour;
          this.ruleForm.reportTypesArray = [];
          this.options2 = [[]];
          getReportType().then((res) => {
            this.options1 = res.data.data;
            console.log(
              "resA.data.data.reportTypeAndParas",
              resA.data.data.reportTypeAndParas
            );
            // resA.data.data.reportTypeAndParas.forEach((item1, index1) => {
            // this.ruleForm.reportTypesArray.push({
            //   params1: item1.reportTypeName
            // });
            // debugger;
            let temp = [];
            for (let i = 0; i < resA.data.data.reportTypeAndParas.length; i++) {
              let P = getReportTypeParams(
                resA.data.data.reportTypeAndParas[i].reportType
              ).then((res2) => {
                // debugger
                console.log("i", i);
                // if (resA.data.data.reportTypeAndParas[i].reportParas) {
                //   this.options2[i] = res2.data.data;
                //   this.ruleForm.reportTypesArray.push({
                //     params1: resA.data.data.reportTypeAndParas[i].reportType,
                //     params2: [],
                //   });
                //   resA.data.data.reportTypeAndParas[i].reportParas.forEach(
                //     (item2) => {
                //       this.ruleForm.reportTypesArray[i].params2.push(
                //         item2.reportType
                //       );
                //     }
                //   );
                // } else {
                //   this.ruleForm.reportTypesArray.push({
                //     params1: resA.data.data.reportTypeAndParas[i].reportType,
                //     params2: [],
                //   });
                //   this.options2[i] = [];
                // }
                return {
                  index: i,
                  data: res2.data.data,
                };
              });
              temp.push(P);
            }
            Promise.all(temp).then((result) => {
              console.log(result);
              result.forEach((item, i) => {
                if (resA.data.data.reportTypeAndParas[i].reportParas) {
                  this.options2[i] = item.data;
                  this.ruleForm.reportTypesArray.push({
                    params1: resA.data.data.reportTypeAndParas[i].reportType,
                    params2: [],
                  });
                  resA.data.data.reportTypeAndParas[i].reportParas.forEach(
                    (item2) => {
                      this.ruleForm.reportTypesArray[i].params2.push(
                        item2.reportType
                      );
                    }
                  );
                } else {
                  this.ruleForm.reportTypesArray.push({
                    params1: resA.data.data.reportTypeAndParas[i].reportType,
                    params2: [],
                  });
                  this.options2[i] = [];
                }
              });
              this.$nextTick(() => {
                let temp1 = JSON.parse(JSON.stringify(this.ruleForm));
                let temp2 = JSON.parse(JSON.stringify(this.options2));
                this.ruleForm = temp1;
                this.options2 = temp2;
                console.log(
                  "111",
                  this.options2,
                  this.ruleForm.reportTypesArray
                );
              });
            });

            // });
          });
        }
      });
    },
    changeStatus(id, index) {
      if (index === 0) {
        this.$confirm("确认暂停？")
          .then(() => {
            // done();
            reportManagechangeStatus(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      } else if (index === 1) {
        this.$confirm("确认发布？")
          .then(() => {
            // done();
            reportManagechangeStatus(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      }
    },
    deleteReport(id) {
      this.$confirm("确认删除？")
        .then(() => {
          // done();
          reportManageDelete(id).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.searchFor();
            }
          });
        })
        .catch(() => {});
    },
    toBind(row) {
      console.log(row);
      // debugger
      if (row.released) {
        // this.$message({
        //   showClose: false,
        //   message: "已发布无法绑定树结构",
        //   type: "warning",
        //   duration: 5000,
        // });
        this.isView = true
        this.treeReportId = row.reportId;
        getInstanceTree(row.reportId).then((res) => {
          console.log(res);
          if (res.data.data) {
            if (res.data.data.treeNodeList.length > 0) {
              this.testList = res.data.data.treeNodeList;
            } else {
              this.testList = [
                {
                  nodeName: "",
                  show: true,
                  childNodeList: [],
                },
              ];
            }
            this.treeForm.treeName = res.data.data.treeName;
          }

          this.changeTreeVisible = true;
        });
      } else {
        this.isView = false
        this.treeReportId = row.reportId;
        getInstanceTree(row.reportId).then((res) => {
          console.log(res);
          if (res.data.data) {
            if (res.data.data.treeNodeList.length > 0) {
              this.testList = res.data.data.treeNodeList;
            } else {
              this.testList = [
                {
                  nodeName: "",
                  show: true,
                  childNodeList: [],
                },
              ];
            }
            this.treeForm.treeName = res.data.data.treeName;
          }

          this.changeTreeVisible = true;
        });
      }
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      console.log(command);
      if (command.command === "a") {
        this.testList[command.index].childNodeList.push({
          childNodeList: [],
          nodeName: "",
          show: true,
        });
      } else if (command.command === "b") {
        this.testList.splice(command.index, 1);
      }
    },
    addTreeNode() {
      this.testList.push({
        childNodeList: [],
        nodeName: "",
        show: true,
      });
    },
    deleteTree(index) {
      this.testList.splice(index, 1);
    },
    selectTimeOption(item, e) {
      e.stopPropagation();
      this.selectTimeV = item;
      this.isShowDay = false;
    },
    selectTimeOption2(item, e) {
      e.stopPropagation();
      this.selectTimeV21 = item;
      this.isShowMonth = false;
    },
    showDaySelect() {
      this.isShowDay = !this.isShowDay;
    },
    showMonthSelect() {
      this.isShowMonth = !this.isShowMonth;
    },
    addTypes() {
      this.ruleForm.reportTypesArray.push({
        params1: "",
        params2: [],
      });
      this.options2.push([]);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    deleteTypes(index) {
      console.log(index);
      this.ruleForm.reportTypesArray.splice(index, 1);
      this.options2.splice(index, 1);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    getOption(e, v) {
      console.log(e, v);
      getReportTypeParams(e).then((res2) => {
        // debugger
        this.options2[v] = res2.data.data;
        console.log("this.options2", this.options2);
        let temp = JSON.parse(JSON.stringify(this.options2));
        this.options2 = temp;
      });
    },
    toAdd() {
      this.changePasswordVisible = true;
      this.isEdit = false;
      this.ruleForm = {
        reportName: "",
        beginDay: "",
        beginHour: "",
        reportTypesArray: [
          {
            params1: "",
            params2: [],
          },
        ],
      };
      this.options2 = [[]];
    },
    sizeChange(val) {
      this.query.page = 1;
      this.query.limit = val;
      this.searchFor();
    },
    pageChange(val) {
      this.query.page = val;
      this.searchFor();
    },
    searchFor() {
      // this.$router.push({ name: "alarmDetail" });
      // console.log("this.value1", this.value1);
      this.loading = true;
      reportManageSearch(this.query).then((res) => {
        console.log(res);
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.count;
      });
    },
    reportTypeV(v) {
      let temp = [];
      v.forEach((item) => {
        temp.push(item);
      });
      temp = temp.join(",");
      return temp;
    },
    reportTypeV2(v) {
      let temp = [];
      v.forEach((item) => {
        if (item.reportParas) {
          item.reportParas.forEach((item2) => {
            temp.push(item2.reportTypeName);
          });
        }
      });
      temp = temp.join(",");
      return temp;
    },
    timeChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    clickTime(index) {
      this.isActivenTime = index;
      this.value1 = "";
      this.value2 = [];
      if (index == 1) {
        this.isInterval = false;
        this.changeDateType = "date";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD;
      } else if (index == 2) {
        this.isInterval = false;
        this.changeDateType = "month";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 3) {
        this.isInterval = false;
        this.changeDateType = "year";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 4) {
        this.isInterval = true;
        let dateT = new Date();
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value11 = YY + MM + DD;
        dateT = new Date(this.value11);
        dateT = dateT.setDate(dateT.getDate() + 1);
        this.value22 = this.formatDate(dateT);
        this.value22 = this.value22.split(" ")[0];
        console.log("this.value1", this.value11);
        console.log("this.value2", this.value22);
        this.value2 = [this.value11, this.value22];
        this.query.startTime = this.value11;
        this.query.endTime = this.value22;
      }
    },
    clickX() {},
    clickOptionX() {},
    clickY() {},
    clickOptionY() {},
    onSearch() {
      this.loading = true;
      this.tableData = [];
      this.tableKey = [];
      this.tableKey2 = [];
      if (this.isActivenTime == 1) {
        this.query.startTime = this.value1;
        this.query.endTime = "";
        this.query.unit = "DAYS";
      } else if (this.isActivenTime == 2) {
        this.query.startTime =
          this.value1.split("-")[0] + "-" + this.value1.split("-")[1];
        this.query.endTime = "";
        this.query.unit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        this.query.startTime = this.value1.split("-")[0];
        this.query.endTime = "";
        this.query.unit = "YEARS";
      } else if (this.isActivenTime == 4) {
        // this.query.startTime = this.value1;
        // this.query.endTime = "";
        this.query.unit = null;
      }
      // reportSearch(this.query).then((res2) => {
      //   this.loading = false;
      //   console.log(res2);
      //   this.tableData = res2.data.data.rows;
      //   // this.tableKey = res2.data.data.timestamps;
      //   let temp = [];
      //   res2.data.data.timestamps.forEach((item) => {
      //     temp.push(this.formatDate(item));
      //   });
      //   this.tableKey = temp;
      //   this.tableKey2 = res2.data.data.columns;
      // });
    },
    bindTree() {
      let temp = {
        reportId: this.treeReportId,
        treeName: this.treeForm.treeName,
        treeNodeList: this.testList,
      };
      if(this.isView){
        this.changeTreeVisible = false
      }
      else{

        bindInstanceTree(temp).then((res) => {
          if (res.data.code === 0) {
            this.changeTreeVisible = false;
            this.$message.success("绑定成功");
            this.searchFor();
          }
          // console.log(res);
        });
      }
    },
    addNew() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.ruleForm.beginDay = this.selectTimeV21;
          this.ruleForm.beginHour = this.selectTimeV;
          let temp = [];
          this.ruleForm.reportTypesArray.forEach((item) => {
            if (item.params1) {
              if (item.params2.length > 0) {
                item.params2.forEach((item3) => {
                  temp.push(item3);
                });
              } else {
                temp.push(item.params1);
              }
            }
          });
          this.ruleForm.reportTypes = temp;
          if (!this.isEdit) {
            reportManageAdd(this.ruleForm).then((res) => {
              console.log("res", res);
              if (res.data.code === 0) {
                this.$message.success("新增成功");
                this.changePasswordVisible = false;
                this.searchFor();
              }
            });
          } else {
            reportManageupdate(this.ruleForm).then((res) => {
              console.log("res", res);
              if (res.data.code === 0) {
                this.$message.success("编辑成功");
                this.changePasswordVisible = false;
                this.searchFor();
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.el-dialog__wrapper >>> input {
  width: 350px !important;
  height: 32px !important;
}

.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
.time-square:hover {
  background: #3f6df8 !important;
  color: white !important;
}
.isSelectTime {
  background: #3f6df8 !important;
  color: white !important;
}
.small-s >>> input {
  width: 220px !important;
  height: 32px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 750px !important;
}
.inline-input >>> input {
  width: 160px !important;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>