<template>
  <div class="heatingSystem">
    <div class="body">
      <div class="body-left">
        <div class="status">
          <img
            class="status-img"
            src="~@/assets/heatingSystemWebp/status-img.webp"
            alt=""
          />
          <!-- <img
              class="icon status-img"
              v-if="hotData == 1"
              src="~@/assets/heatingSystemWebp/引导按钮2.webp"
              alt=""
            />
            <img
              class="icon status-img"
              v-else
              src="~@/assets/heatingSystem/start-icon.png"
              alt=""
            /> -->
          <div class="status-name">采暖系统</div>
          <div
            class="status-circle"
            :class="{ start: hotData === '1', stop: hotData !== '1' }"
          ></div>
          <div
            class="status-icon"
            :class="{ start: hotData === '1', stop: hotData !== '1' }"
          ></div>
          <span
            >运行模式：<span>{{
              hotData === "1" ? "寻优" : "手动"
            }}</span></span
          >
        </div>
        <div class="left-list">
          <div
            class="left-item"
            v-for="(item, index) in leftList"
            :key="item.name"
            :class="'left-item-' + index"
          >
            <div class="item-val">
              {{ item.value === null ? "-- --" : item.value }}
            </div>
            <div class="item-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="body-center">
        <div class="body-data">
          <div class="data-item item1-1">
            <img
              src="~@/assets/heatingSystemWebp/boiler2.png"
              v-if="(boilerList[3] || {}).status == 1"
              alt=""
            />
            <img v-else src="~@/assets/heatingSystemWebp/boiler0.webp" alt="" />
            <div class="item-val">
              <span>4#</span> 供水温度：{{
                HVAC_B4_LEV_Temp ? HVAC_B4_LEV_Temp + "℃" : "--"
              }}
            </div>
          </div>
          <!-- <div class="data-item item1-2">
            <div class="item-val"></div>
          </div> -->
          <div class="data-item item1-3">
            <div class="item-val" style="color: #68b7d8">
              回水温度：{{ HVAC_AHU_WS_T ? HVAC_AHU_WS_T + "℃" : "--" }}
            </div>
          </div>
          <div class="data-item item2-1">
            <img
              src="~@/assets/heatingSystemWebp/boiler2.png"
              v-if="(boilerList[2] || {}).status == 1"
              alt=""
            />
            <img v-else src="~@/assets/heatingSystemWebp/boiler0.webp" alt="" />
            <div class="item-val">
              <span>3#</span> 供水温度：{{
                HVAC_B3_LEV_Temp ? HVAC_B3_LEV_Temp + "℃" : "--"
              }}
            </div>
          </div>
          <!-- <div class="data-item item2-2">
            <img src="~@/assets/heatingSystemWebp/boiler0.webp" alt="" />
            <div class="item-val">P：15.2KW</div>
          </div> -->
          <div class="data-item item3-1">
            <img
              src="~@/assets/heatingSystemWebp/boiler2.png"
              v-if="(boilerList[1] || {}).status == 1"
              alt=""
            />
            <img v-else src="~@/assets/heatingSystemWebp/boiler0.webp" alt="" />
            <div class="item-val">
              <span>2#</span> 供水温度：{{
                HVAC_B2_LEV_Temp ? HVAC_B2_LEV_Temp + "℃" : "--"
              }}
            </div>
          </div>
          <!-- <div class="data-item item3-2">
            <img src="~@/assets/heatingSystemWebp/boiler0.webp" alt="" />
            <div class="item-val">P：15.2KW</div>
          </div> -->
          <div class="data-item item4-1">
            <img
              src="~@/assets/heatingSystemWebp/boiler2.png"
              v-if="(boilerList[0] || {}).status == 1"
              alt=""
            />
            <img v-else src="~@/assets/heatingSystemWebp/boiler0.webp" alt="" />
            <div class="item-val">
              <span>1#</span> 供水温度1：{{
                HVAC_B1_LEV_Temp ? HVAC_B1_LEV_Temp + "℃" : "--"
              }}
            </div>
          </div>
          <div class="data-item item4-2">
            <!-- <img src="~@/assets/heatingSystemWebp/boiler0.webp" alt="" /> -->
            <div class="item-val" style="width: 150px">
              {{ Elec_RYDB_01 ? "总电功率：" + Elec_RYDB_01 + "kW" : "--" }}
            </div>
          </div>
          <div class="data-item item5-1">
            <div class="item-val data-item-width" style="color: #68b7d8">
              总回水温度：{{ HVAC_AHU_WR_T ? HVAC_AHU_WR_T + "℃" : "--" }}
            </div>
          </div>
          <div class="data-item item5-2">
            <div class="item-val data-item-width">
              供水温度：{{ HVAC_AHU_WS_T ? HVAC_AHU_WS_T + "℃" : "--" }}
            </div>
          </div>
          <div class="data-item item5-3">
            <div class="item-val" style="width: 180px">
              热水流量：{{
                Water_DTU2_1_2_Flow ? Water_DTU2_1_2_Flow + "m³/h" : "--"
              }}
            </div>
          </div>
          <div class="data-item item5-4">
            <div class="item-val data-item-width">
              供水温度：{{ HVAC_RFH_WS_T ? HVAC_RFH_WS_T + "℃" : "--" }}
            </div>
          </div>
          <div class="data-item item6">
            <div class="item6-item">
              <img src="~@/assets/heatingSystemWebp/boiler0.webp" alt="" />
              运行
            </div>
            <div class="item6-item">
              <img src="~@/assets/heatingSystemWebp/boiler2.png" alt="" />
              停止
            </div>
            <div class="item6-item">
              <img src="~@/assets/heatingSystemWebp/boiler1.png" alt="" />
              故障
            </div>
          </div>
        </div>
        <img class="body-bg" src="~@/assets/heatingSystem/bg2.png" alt="" />
        <img
          class="body-webp"
          src="~@/assets/heatingSystemWebp/设备动态图.webp"
          alt=""
        />
        <img
          class="left"
          src="~@/assets/heatingSystemWebp/窗口左边.webp"
          alt=""
        />
        <img
          class="right"
          src="~@/assets/heatingSystemWebp/窗口右边.webp"
          alt=""
        />
      </div>
      <div class="body-right">
        <div class="heat-box boiler">
          <div class="heat-title">当日能耗</div>
          <div class="boiler-body">
            <div class="boiler-list">
              <div
                class="boiler-item"
                v-for="(item, index) in boilerList"
                :key="index"
              >
                <div class="boiler-title">
                  <div
                    class="boiler-status"
                    :class="'boiler-status-' + item.status"
                  ></div>
                  <div class="boiler-name">
                    <span>{{ index + 1 }}# </span>{{ item.name }}
                  </div>
                </div>
                <div class="boiler-body">
                  <img
                    src="~@/assets/heatingSystemWebp/boiler.webp"
                    v-if="item.status == 0 || !item.status"
                    alt=""
                  />
                  <img v-else src="~@/assets/heatingSystem/boiler.png" alt="" />
                  <div class="boiler-num">
                    <div class="boiler-change">{{ item.change }}</div>
                    <div class="boiler-value">{{ item.value }}</div>
                    <div class="boiler-unit">{{ item.unit }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="heat-box columnar" v-loading="columnarStatus">
        <div class="heat-title">
          <span>燃气消耗柱状分析</span>
          <div class="columnar-title">
            <div
              v-for="item in columnarArr"
              :key="item.value"
              class="columnar-title-item"
              :class="{ active: columnarIndex == item.value }"
              @click="changecolumnarIndex(item.value)"
            >
              {{ item.name }}
            </div>
            <span style="font-size: 14px; margin: 0 5px; font-weight: 500"
              >时间选择</span
            >
            <el-date-picker
              v-show="columnarIndex === 'HOURS'"
              class="columnar-searchTime"
              v-model="searchTime"
              type="date"
              placeholder="选择日期"
              size="mini"
              style="width: 130px"
              popper-class="columnar-time"
              :append-to-body="true"
              align="left"
              :clearable="false"
              @change="loadColumnarData"
            >
            </el-date-picker>
            <el-date-picker
              v-show="columnarIndex === 'DAYS'"
              class="columnar-searchTime"
              v-model="searchTime"
              type="month"
              placeholder="选择日期"
              size="mini"
              style="width: 130px"
              popper-class="columnar-time"
              :append-to-body="true"
              align="left"
              :clearable="false"
              @change="loadColumnarData"
            >
            </el-date-picker>
            <el-date-picker
              v-show="columnarIndex === 'MONTHS'"
              class="columnar-searchTime"
              v-model="searchTime"
              type="year"
              placeholder="选择日期"
              size="mini"
              style="width: 130px"
              popper-class="columnar-time"
              :append-to-body="true"
              align="left"
              :clearable="false"
              @change="loadColumnarData"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="boiler-body" style="width: 100%; height: calc(100% - 32px)">
          <v-chart ref="chartColumnar" :option="optionColumnar" />
        </div>
      </div>
      <div class="heat-box month">
        <div class="heat-title">逐月能耗分析</div>
        <div class="month-body">
          <div class="month-item">
            <div class="item-box">
              <div class="item-box-center">
                <div class="item-box-center-img">
                  燃气<br />
                  消耗
                </div>
                <span
                  class="item-box-center-val"
                  :class="electricData.comparePercent >= 0 ? 'top' : 'down'"
                >
                  <i
                    :class="
                      gasData.comparePercent < 0
                        ? 'el-icon-caret-bottom'
                        : 'el-icon-caret-top'
                    "
                  ></i>
                  {{
                    gasData.comparePercent
                      ? gasData.comparePercent + "%"
                      : "-- --"
                  }}
                </span>
              </div>
            </div>
            <div class="item-circle">
              <div class="circle-box">
                <div class="circle-box-name">上月</div>
                <div class="circle-box-val">同时段</div>
              </div>
              <div class="circle-right">
                <div class="circle-val">
                  {{
                    gasData.lastDayVal === null
                      ? "-- --"
                      : parseInt(gasData.lastDayVal || 0)
                  }}
                </div>
                <div class="circle-name">(m³)</div>
              </div>
            </div>
            <div class="item-circle">
              <div class="circle-box">
                <div class="circle-box-name">本月</div>
                <div class="circle-box-val">用气量</div>
              </div>
              <div class="circle-right">
                <div class="circle-val">
                  {{
                    gasData.thisVal === null
                      ? "-- --"
                      : parseInt(gasData.thisVal || 0)
                  }}
                </div>
                <div class="circle-name">(m³)</div>
              </div>
            </div>
          </div>
          <div class="month-item">
            <div class="item-box">
              <div class="item-box-center">
                <div class="item-box-center-img">
                  电力<br />
                  消耗
                </div>
                <span
                  class="item-box-center-val"
                  :class="electricData.comparePercent >= 0 ? 'top' : 'down'"
                >
                  <i
                    :class="
                      electricData.comparePercent < 0
                        ? 'el-icon-caret-bottom'
                        : 'el-icon-caret-top'
                    "
                  ></i>
                  {{
                    electricData.comparePercent
                      ? electricData.comparePercent + "%"
                      : "-- --"
                  }}
                </span>
              </div>
            </div>
            <div class="item-circle">
              <div class="circle-box">
                <div class="circle-box-name">上月</div>
                <div class="circle-box-val">同时段</div>
              </div>
              <div class="circle-right">
                <div class="circle-val">
                  {{
                    electricData.lastDayVal === null
                      ? "-- --"
                      : parseInt(electricData.lastDayVal || 0)
                  }}
                </div>
                <div class="circle-name">(kWh)</div>
              </div>
            </div>
            <div class="item-circle">
              <div class="circle-box">
                <div class="circle-box-name">本月</div>
                <div class="circle-box-val">用电量</div>
              </div>
              <div class="circle-right">
                <div class="circle-val">
                  {{
                    electricData.thisVal === null
                      ? "-- --"
                      : parseInt(electricData.thisVal || 0)
                  }}
                </div>
                <div class="circle-name">(kWh)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getMonitorRealtime,
  getDiffData,
  pointQuery,
  monthData,
  optimizationData,
  multipleQuery,
} from "@/request/apiLg";
import dayjs from "dayjs";
export default {
  name: "heatingSystem",
  data() {
    return {
      leftList: [
        {
          name: "供水温度 (℃)",
          value: "",
        },
        {
          name: "回水温度 (℃)",
          value: "",
        },
        {
          name: "热水量 (m³)",
          value: "",
        },
        {
          name: "燃气用量 (m³)",
          value: "",
        },
        {
          name: "系统用电 (kWh)",
          value: "",
        },
      ],
      boilerList: [],
      columnarArr: [
        {
          name: "日",
          value: "HOURS",
        },
        {
          name: "月",
          value: "DAYS",
        },
        {
          name: "年",
          value: "MONTHS",
        },
      ],
      searchTime: new Date(),
      columnarIndex: "HOURS",
      optionColumnar: {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            let res = `${params[0].name}<br/>`;
            if (params[0].value !== undefined) {
              res += `综合能耗（${params[0].seriesName}）：${params[0].value}<br/>`;
            } else if (params[1].value !== undefined) {
              res += `综合能耗（${params[1].seriesName}）：${params[1].value}<br/>`;
            }
            if (params[2] && params[2].value) {
              res += `${params[2].seriesName}：${params[2].value}<br/>`;
            }
            if (params[3] && params[3].value) {
              res += `${params[3].seriesName}：${params[3].value}<br/>`;
            }
            return res;
          },
        },
        legend: {
          bottom: "bottom",
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "40px",
          left: "10px",
          right: "10px",
          bottom: "20px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          // 轴上的刻度设置
          axisLabel: {
            inside: false,
            interval: 23,
            rotate: 30,
            textStyle: {
              color: "#fff",
            },
          },
          // 轴线样式设置
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "m³",
            min: 0,
            axisTick: {
              show: false,
            },
            // 单位设置
            nameTextStyle: {
              color: "#fff",
              verticalAlign: "top",
              align: "left",
            },
            // 轴上延申线
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63, 109, 248, 0.3)"],
                width: 1,
                type: "dashed",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(63, 109, 248, 0.8)",
              },
            },
            axisLabel: {
              color: "red",
              inside: false,
              textStyle: {
                color: "#fff",
              },
              lineStyle: {
                color: "red",
                widtg: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "℃",
            max: 40,
            min: -20,
            interval: 15,
            axisLabel: {
              formatter: "{value} °C",
              inside: false,
              textStyle: {
                color: "#fff",
              },
              lineStyle: {
                color: "red",
                widtg: 1,
                type: "dashed",
              },
            },
            // 单位设置
            nameTextStyle: {
              color: "#fff",
              verticalAlign: "top",
              align: "right",
            },
            // 轴上延申线
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63, 109, 248, 0.3)"],
                width: 1,
                type: "dashed",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(63, 109, 248, 0.8)",
              },
            },
          },
        ],
        series: [
          {
            name: "非优化",
            stack: "Ad",
            barWidth: 12,
            type: "bar",
            data: [],
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(47, 89, 215, 0.8)" }, //柱图渐变色
                  { offset: 1, color: "rgba(47, 89, 215, 0.8)" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(47, 89, 215, 1)" }, //柱图渐变色
                  { offset: 1, color: "rgba(47, 89, 215, 1)" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: "优化",
            type: "bar",
            stack: "Ad",
            barWidth: 12,
            data: [],
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#55FFBC" }, //柱图渐变色
                  { offset: 1, color: "#49E6FF" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#49E6FF" }, //柱图渐变色
                  { offset: 1, color: "#49E6FF" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: "空间温度",
            type: "line",
            yAxisIndex: 1,
            smooth: true,
            showSymbol: false,
            tooltip: {
              valueFormatter: function (value) {
                return value + " °C";
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "rgba(199, 185, 94, 1)",
                },
              },
            },
            data: [],
          },
          {
            name: "室外温度",
            type: "line",
            showSymbol: false,
            yAxisIndex: 1,
            smooth: true,
            tooltip: {
              valueFormatter: function (value) {
                return value + " °C";
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "rgba(192, 74, 252, 1)",
                },
              },
            },
            data: [],
          },
        ],
      },
      HVAC_AHU_WS: "",
      HVAC_AHU_WR: "",
      Water_DTU2_1_2: "",
      Elec_RSB_012: "",
      Gas_Power001: "",
      Gas_Power002: "",
      Gas_Power003: "",
      Gas_Power004: "",
      datePickerType: "date",
      pointData: [],
      pointDataQuery: {
        endTime: "",
        interval: 0,
        point: "",
        startTime: "",
        timeUnit: "",
      },
      "energy.electric.point": "",
      "energy.indoor.temp": "",
      "energy.outdoor.temp": "",
      "energy.gas.point": "",
      "energy.p": "",
      electricArr: [],
      indoorArr: [],
      outdoorArr: [],
      monthDataGasQuery: {
        compareEndTime: dayjs()
          .add(1, "days")
          .subtract(1, "month")
          .format("YYYY-MM-DD 00:00:00"),
        compareStartTime: dayjs()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: dayjs().add(1, "days").format("YYYY-MM-DD 00:00:00"),
        pointId: "",
        startTime: dayjs().startOf("month").format("YYYY-MM-DD 00:00:00"),
        timeUnit: "MONTHS",
      },
      monthDataGasData: [],
      monthDataelectricalQuery: {
        compareEndTime: dayjs()
          .add(1, "days")
          .subtract(1, "month")
          .format("YYYY-MM-DD 00:00:00"),
        compareStartTime: dayjs()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: dayjs().add(1, "days").format("YYYY-MM-DD 00:00:00"),
        pointId: "",
        startTime: dayjs().startOf("month").format("YYYY-MM-DD 00:00:00"),
        timeUnit: "MONTHS",
      },
      monthDataPQuyer: {},
      hotData: "1",
      monthDataelectricalData: [],
      p22: "",
      p32: "",
      p42: "",
      p52: "",
      p53: "",
      columnarStatus: true,
      gasData: [],
      electricData: [],
      HVAC_B4_LEV_Temp: "",
      HVAC_RFH_WR_T: "",
      HVAC_B3_LEV_Temp: "",
      HVAC_B2_LEV_Temp: "",
      HVAC_B1_LEV_Temp: "",
      HVAC_AHU_WR_T: "",
      Elec_RYDB_01: "",
      HVAC_AHU_WS_T: "",
      HVAC_RFH_WS_T: "",
      Water_DTU2_1_2_Flow: "",
      energyP: "",
      "system.electric.point": "",
      "hot.system.supply.water.temp": "",
      "hot.system.return.water.temp": "",
      "hot.run.mode": "",
      "energy.use.hot.water": "",
      "hot.system.gas.power1": "",
      "hot.system.gas.power2": "",
      "hot.system.gas.power3": "",
      "hot.system.gas.power4": "",
      "hot.system.supply.water.temp4": "",
      "hot.system.supply.water.temp3": "",
      "hot.system.supply.water.temp2": "",
      "hot.system.supply.water.temp1": "",
      "beng.supply.water.temp": "",
      "beng.total.electric.p": "",
      "beng.hot.water.flow": "",
    };
  },
  components: {},
  methods: {
    // screenQuery() {
    //   // screenQuery("day_optimize_period").then((res) => {
    //   //   if (res.data.code === 0) {
    //   //     this.day_optimize_period = res.data.data;
    //   //   }
    //   // });
    //   // screenQuery("month_optimize_period").then((res) => {
    //   //   if (res.data.code === 0) {
    //   //     this.month_optimize_period = res.data.data;
    //   //   }
    //   // });
    //   // screenQuery("year_optimize_period").then((res) => {
    //   //   if (res.data.code === 0) {
    //   //     this.year_optimize_period = res.data.data;
    //   //   }
    //   // });
    //   // 地图中心的五个假点
    //   // 2-2点
    //   // screenQuery("p1").then((res) => {
    //   //   if (res.data.code === 0) {
    //   //     this.p22 = res.data.data;
    //   //   }
    //   // });
    //   // screenQuery("p2").then((res) => {
    //   //   if (res.data.code === 0) {
    //   //     this.p32 = res.data.data;
    //   //   }
    //   // });
    //   // screenQuery("p3").then((res) => {
    //   //   if (res.data.code === 0) {
    //   //     this.p42 = res.data.data;
    //   //   }
    //   // });
    //   // screenQuery("p4").then((res) => {
    //   //   if (res.data.code === 0) {
    //   //     this.p52 = res.data.data;
    //   //   }
    //   // });
    //   // screenQuery("p5").then((res) => {
    //   //   if (res.data.code === 0) {
    //   //     this.p53 = res.data.data;
    //   //   }
    //   // });
    // },
    async loadColumnarData() {
      this.columnarStatus = true;
      if (!this.searchTime) {
        this.$message({
          message: "日期不能为空！",
          type: "warning",
        });
        this.searchTime = new Date();
        return;
      }
      if (this.columnarIndex === "HOURS") {
        this.optionColumnar.xAxis.data = [
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:00",
          "24:00",
        ];
        this.pointDataQuery.startTime = dayjs(this.searchTime).format(
          "YYYY-MM-DD 00:00:00"
        );
        this.pointDataQuery.endTime = dayjs(this.searchTime)
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");
      } else if (this.columnarIndex === "DAYS") {
        let num = dayjs(this.searchTime).daysInMonth();
        let month = dayjs(this.searchTime).format("MM");
        let arr = [];
        for (let i = 1; i <= num; i++) {
          for (let j = 0; j < 24; j++) {
            if (j !== 0) {
              arr.push(
                month +
                  "-" +
                  i.toString().padStart(2, "0") +
                  " " +
                  j.toString().padStart(2, "0") +
                  ":00"
              );
            } else {
              arr.push(month + "-" + i.toString().padStart(2, "0"));
            }
          }
        }
        this.optionColumnar.xAxis.data = arr;
        this.pointDataQuery.startTime = dayjs(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        this.pointDataQuery.endTime = dayjs(this.searchTime)
          .endOf("month")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");
      } else if (this.columnarIndex === "MONTHS") {
        let arr = [];
        let year = dayjs(this.searchTime).format("YYYY");
        for (let i = 1; i <= 12; i++) {
          let monthDyas = dayjs(year + "-" + i).daysInMonth();
          for (let d = 1; d <= monthDyas; d++) {
            arr.push(
              i.toString().padStart(2, "0") +
                "-" +
                d.toString().padStart(2, "0")
            );
          }
        }
        this.optionColumnar.xAxis.data = arr;
        this.pointDataQuery.startTime = dayjs(this.searchTime)
          .startOf("year")
          .format("YYYY-MM-DD 00:00:00");
        this.pointDataQuery.endTime = dayjs(this.searchTime)
          .endOf("year")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");
      }
      // 针对处理样式
      if (this.columnarIndex === "MONTHS") {
        this.pointDataQuery.timeUnit = "DAYS";
        this.changeEchartsSize(2);
      } else {
        this.pointDataQuery.timeUnit = "HOURS";
        if (this.columnarIndex === "HOURS") {
          this.changeEchartsSize(12);
        } else {
          this.changeEchartsSize(1);
        }
      }

      // if (this["energy.electric.point"] === "") {
      //   await screenQuery("energy.electric.point").then((res) => {
      //     if (res.data.code === 0) {
      //       this["energy.electric.point"] = res.data.data.value;
      //       // 加载电
      //     }
      //   });
      // }
      this.optimizationData("energy.gas.point", 0);

      // if (this["energy.indoor.temp"] === "") {
      //   await screenQuery("energy.indoor.temp").then((res) => {
      //     if (res.data.code === 0) {
      //       this["energy.indoor.temp"] = res.data.data.value;
      //     }
      //   });
      // }
      // 空间温度
      this.pointQuery("energy.indoor.temp", 1);

      // if (this["energy.outdoor.temp"] === "") {
      //   await screenQuery("energy.outdoor.temp").then((res) => {
      //     if (res.data.code === 0) {
      //       this["energy.outdoor.temp"] = res.data.data.value;
      //     }
      //   });
      // }
      // 室外温度
      this.pointQuery("energy.outdoor.temp", 1);
      // 大屏综合能耗柱状图
      this.loadColumnar();
    },
    changecolumnarIndex(index) {
      this.columnarIndex = index;
      this.loadColumnarData();
    },
    loadColumnar() {
      this.$refs.chartColumnar.setOption(this.optionColumnar, true);
      setTimeout(() => {
        this.columnarStatus = false;
      }, 1000);
    },
    getMonitorRealtime() {
      getMonitorRealtime([
        this["hot.system.supply.water.temp"],
        this["hot.system.return.water.temp"],
        this["hot.run.mode"],
      ]).then((res) => {
        if (res.data.code === 0) {
          this.leftList[0].value =
            res.data.data[this["hot.system.supply.water.temp"]];
          this.leftList[1].value =
            res.data.data[this["hot.system.return.water.temp"]];
          this.hotData = res.data.data[this["hot.run.mode"]];
          // this.HVAC_AHU_WS = res.data.data["hub.bjzgc.HVAC_AHU_WS.T"];
          // this.HVAC_AHU_WR = res.data.data["hub.bjzgc.HVAC_AHU_WR.T"];
        }
      });
    },
    getDiffData() {
      getDiffData([
        this["energy.use.hot.water"],
        this["system.electric.point"],
        this["hot.system.gas.power1"],
        this["hot.system.gas.power2"],
        this["hot.system.gas.power3"],
        this["hot.system.gas.power4"],
      ]).then((res) => {
        if (res.data.code === 0) {
          this.leftList[2].value = res.data.data[this["energy.use.hot.water"]];
          this.leftList[4].value = res.data.data[this["system.electric.point"]];
          let boilerList = [];
          let Gas_PowerALL =
            res.data.data[this["hot.system.gas.power1"]] +
            res.data.data[this["hot.system.gas.power2"]] +
            res.data.data[this["hot.system.gas.power3"]] +
            res.data.data[this["hot.system.gas.power4"]];

          this.leftList[3].value = Gas_PowerALL;

          for (let i = 0; i < 4; i++) {
            let thisData =
              res.data.data[this["hot.system.gas.power" + (i + 1)]];
            let change = "";
            if (thisData && thisData > 0) {
              change = ((thisData / Gas_PowerALL) * 100 || 0).toFixed(1) + "%";
            } else {
              change = "--";
            }
            boilerList.push({
              name: "锅炉",
              status: (thisData && thisData) > 0 ? 0 : 1,
              value: (thisData || 0).toFixed(2),
              unit: "日用气(m³)",
              change: "占比：" + change,
            });
          }
          this.boilerList = boilerList;
        }
      });
    },
    async pointQuery(type, queryType) {
      this.pointDataQuery.point = this[type];
      this.pointDataQuery.queryType = queryType;
      await pointQuery(this.pointDataQuery).then((res) => {
        if (res.data.code === 0) {
          if (type === "energy.indoor.temp") {
            this.optionColumnar.series[2].data = (
              (res.data.data || {}).first || []
            ).map((res) => {
              return res.formatValue;
            });
          } else if (type === "energy.outdoor.temp") {
            this.optionColumnar.series[3].data = (
              (res.data.data || {}).first || []
            ).map((res) => {
              return res.formatValue;
            });
          }
        }
      });
    },
    changeEchartsSize(index) {
      if (index === 12) {
        this.optionColumnar.series[0].barWidth = 12;
        this.optionColumnar.series[1].barWidth = 12;
        this.optionColumnar.xAxis.axisLabel.interval = 0;
        this.optionColumnar.xAxis.axisLabel.rotate = 0;
        this.optionColumnar.grid.bottom = "25px";
      } else if (index === 1) {
        this.optionColumnar.series[0].barWidth = 1;
        this.optionColumnar.series[1].barWidth = 1;
        this.optionColumnar.xAxis.axisLabel.interval = 23;
        this.optionColumnar.xAxis.axisLabel.rotate = 30;
        this.optionColumnar.grid.bottom = "0px";
      } else if (index === 2) {
        this.optionColumnar.series[0].barWidth = 1;
        this.optionColumnar.series[1].barWidth = 1;
        this.optionColumnar.xAxis.axisLabel.interval = 7;
        this.optionColumnar.xAxis.axisLabel.rotate = 50;
        this.optionColumnar.grid.bottom = "20px";
      }
    },
    optimizationData(type, queryType) {
      this.pointDataQuery.point = this[type];
      this.pointDataQuery.queryType = queryType;
      optimizationData(this.pointDataQuery).then((res) => {
        if (res.data.code === 0) {
          let max = 0;
          let arr1 = [],
            arr2 = [];
          ((res.data.data || {}).first || []).forEach((element, index) => {
            if (parseInt(element.formatValue) > max) {
              max = parseInt(element.formatValue);
            }
            if (((res.data.data || {}).second[index] || {}).formatValue !== "1") {
              arr1.push(element.formatValue);
              arr2.push(null);
            } else {
              arr2.push(element.formatValue);
              arr1.push(null);
            }
          });
          if (max && max > 0) {
            // 取全部数据的最大值
            // 最大值的第一位+1 获取到整数的最大值
            let maxStart = max.toString()[0] - -1;
            let maxLength = (max + "").length;
            // 如果第一位+1 = 10，则需要多补一位
            if (maxStart === 10) {
              maxLength += 1;
            }
            // 获取到最大的整数值
            let maxNumber = maxStart.toString().padEnd(maxLength, "0");
            this.optionColumnar.yAxis[0].max = maxNumber - 0;
            this.optionColumnar.yAxis[0].interval = maxNumber / 4;
          } else {
            this.optionColumnar.yAxis[0].max = 4000;
            this.optionColumnar.yAxis[0].interval = 4000 / 4;
          }
          this.optionColumnar.series[0].data = arr1;
          this.optionColumnar.series[1].data = arr2;
        }
      });
    },
    monthData() {
      // 获取物实例点
      this.monthDataGasQuery.pointId = this["energy.gas.point"];
      monthData(this.monthDataGasQuery).then((res) => {
        if (res.data.code === 0) {
          this.gasData = res.data.data;
        }
      });
      this.monthDataelectricalQuery.pointId = this["system.electric.point"];
      monthData(this.monthDataelectricalQuery).then((res) => {
        if (res.data.code === 0) {
          this.electricData = res.data.data;
        }
      });
      getMonitorRealtime([this["energy.p"]]).then((res) => {
        if (res.data.code === 0) {
          this.energyP = res.data.data[[this["energy.p"]]];
        }
      });
    },
    loadBody() {
      getMonitorRealtime([
        this["hot.system.supply.water.temp1"],
        this["hot.system.supply.water.temp2"],
        this["hot.system.supply.water.temp3"],
        this["hot.system.supply.water.temp4"],
        // "hub.bjzgc.HVAC_RFH_WR.T",
        this["hot.system.return.water.temp"],
        this["hot.system.supply.water.temp"],
        this["beng.supply.water.temp"],
        this["beng.total.electric.p"],
        this["beng.hot.water.flow"],
      ]).then((res) => {
        if (res.data.code === 0) {
          this["HVAC_B4_LEV_Temp"] =
            res.data.data[this["hot.system.supply.water.temp4"]];
          // this["HVAC_RFH_WR_T"] = res.data.data["hub.bjzgc.HVAC_RFH_WR.T"];
          this["HVAC_B3_LEV_Temp"] =
            res.data.data[this["hot.system.supply.water.temp3"]];
          this["HVAC_B2_LEV_Temp"] =
            res.data.data[this["hot.system.supply.water.temp2"]];
          this["HVAC_B1_LEV_Temp"] =
            res.data.data[this["hot.system.supply.water.temp1"]];
          this["HVAC_AHU_WR_T"] =
            res.data.data[this["hot.system.return.water.temp"]];
          this["HVAC_AHU_WS_T"] =
            res.data.data[this["hot.system.supply.water.temp"]];

          this["Elec_RYDB_01"] = res.data.data[this["beng.total.electric.p"]];
          this["HVAC_RFH_WS_T"] = res.data.data[this["beng.supply.water.temp"]];
          this["Water_DTU2_1_2_Flow"] =
            res.data.data[this["beng.hot.water.flow"]];
        }
      });
    },
    multipleQuery() {
      multipleQuery([
        "energy.electric.point",
        "energy.indoor.temp",
        "energy.outdoor.temp",
        "energy.p",
        "p1",
        "p2",
        "p3",
        "p4",
        "p5",
        "energy.gas.point",
        "system.electric.point",
        "hot.system.supply.water.temp",
        "hot.system.return.water.temp",
        "hot.run.mode",
        "energy.use.hot.water",
        "hot.system.gas.power1",
        "hot.system.gas.power2",
        "hot.system.gas.power3",
        "hot.system.gas.power4",
        "hot.system.supply.water.temp4",
        "hot.system.supply.water.temp3",
        "hot.system.supply.water.temp2",
        "hot.system.supply.water.temp1",
        "beng.supply.water.temp",
        "beng.total.electric.p",
        "beng.hot.water.flow",
      ]).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("datadatadatadata", data);
          // 电
          this["energy.electric.point"] = data["energy.electric.point"].value;
          // 室内温度
          this["energy.indoor.temp"] = data["energy.indoor.temp"].value;
          // 室外温度
          this["energy.outdoor.temp"] = data["energy.outdoor.temp"].value;
          // 逐月能耗分析-用电
          this["system.electric.point"] = data["system.electric.point"].value;
          // 中间部分磅总功率 温度
          this["energy.p"] = data["energy.p"].value;
          this["P1"] = data["P1"].value;
          this["P2"] = data["P2"].value;
          this["P3"] = data["P3"].value;
          this["P4"] = data["P4"].value;
          this["P5"] = data["P5"].value;
          // 中间部分磅总功率
          this["energy.gas.point"] = data["energy.gas.point"].value;
          //
          this["hot.system.supply.water.temp"] =
            data["hot.system.supply.water.temp"].value;
          this["hot.system.return.water.temp"] =
            data["hot.system.return.water.temp"].value;
          this["hot.run.mode"] = data["hot.run.mode"].value;
          this["energy.use.hot.water"] = data["energy.use.hot.water"].value;
          this["hot.system.gas.power1"] = data["hot.system.gas.power1"].value;
          this["hot.system.gas.power2"] = data["hot.system.gas.power2"].value;
          this["hot.system.gas.power3"] = data["hot.system.gas.power3"].value;
          this["hot.system.gas.power4"] = data["hot.system.gas.power4"].value;
          this["hot.system.supply.water.temp1"] =
            data["hot.system.supply.water.temp1"].value;
          this["hot.system.supply.water.temp2"] =
            data["hot.system.supply.water.temp2"].value;
          this["hot.system.supply.water.temp3"] =
            data["hot.system.supply.water.temp3"].value;
          this["hot.system.supply.water.temp4"] =
            data["hot.system.supply.water.temp4"].value;
          this["beng.supply.water.temp"] = data["beng.supply.water.temp"].value;
          this["beng.total.electric.p"] = data["beng.total.electric.p"].value;
          this["beng.hot.water.flow"] = data["beng.hot.water.flow"].value;

          this.loadColumnarData();
          // 获取 pointId 实时值
          this.getMonitorRealtime();
          // 累计值
          this.getDiffData();
          // 逐月能耗分析
          this.monthData();
          // 加载中间的数据
          this.loadBody();
        }
      });
    },
  },
  mounted() {
    // 请求全部字典表的数据
    this.multipleQuery();

    // 根据key获取到数据
    // // // this.screenQuery();

    // this.loadColumnarData();
    // // 获取 pointId 实时值
    // this.getMonitorRealtime();
    // // 累计值
    // this.getDiffData();
    // // 逐月能耗分析
    // this.monthData();
    // // 加载中间的数据
    // this.loadBody();
  },
};
</script>

<style lang="less">
.heatingSystem {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #01051d;
  .body {
    height: 72%;
    display: flex;
    .body-left {
      width: 18%;
      z-index: 2;
      .status {
        color: #ffffff;
        width: 354px;
        height: 100px;
        background: url("~@/assets/heatingSystem/status-bg.png") no-repeat
          center;
        background-size: 100% 100%;
        margin: 5px 0px 0px 11px;
        display: flex;
        align-items: center;
        .status-img {
          height: 89px;
          margin: 6px 5px;
        }
        .status-name {
          width: 32px;
          height: 46px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 23px;
          margin-left: 7px;
        }
        .status-circle {
          width: 98px;
          height: 98px;
          text-align: center;
          color: #020202;
          margin-left: 6px;
          margin-bottom: 3px;
          &.start {
            background: url("~@/assets/heatingSystemWebp/status-circle.webp")
              no-repeat center;
            background-size: 100% 100%;
          }
          &.stop {
            background: url("~@/assets/heatingSystem/停用2.png") no-repeat
              center;
            background-size: 100% 100%;
          }
        }
        .status-icon {
          width: 40px;
          height: 43px;
          &.start {
            background: url("~@/assets/heatingSystemWebp/start-icon.webp")
              no-repeat center;
            background-size: 100% 100%;
          }
          &.stop {
            background: url("~@/assets/heatingSystemWebp/start-icon.webp")
              no-repeat center;
            background-size: 100% 100%;
          }
        }
        > span > span {
          color: #fbbb18;
        }
      }
      .left-list {
        display: flex;
        flex-direction: column;
        padding-left: 24px;
        .left-item {
          width: 239px;
          height: 89px;
          margin-bottom: 2%;
          padding-left: 43px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          &.left-item-0 {
            background: url("~@/assets/heatingSystemWebp/指标1.webp") no-repeat
              center;
            background-size: 100% 100%;
          }
          &.left-item-1 {
            background: url("~@/assets/heatingSystemWebp/指标2.webp") no-repeat
              center;
            background-size: 100% 100%;
          }
          &.left-item-2 {
            background: url("~@/assets/heatingSystemWebp/指标3.webp") no-repeat
              center;
            background-size: 100% 100%;
          }
          &.left-item-3 {
            background: url("~@/assets/heatingSystemWebp/指标4.webp") no-repeat
              center;
            background-size: 100% 100%;
          }
          &.left-item-4 {
            background: url("~@/assets/heatingSystemWebp/指标5.webp") no-repeat
              center;
            background-size: 100% 100%;
          }
          .item-val {
            font-size: 22px;
            font-weight: bold;
            color: #00dbff;
            text-shadow: 0px 0px 0px #236aff;
          }
          .item-name {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
    .body-center {
      flex: 1;
      position: relative;
      > img {
        position: absolute;
        height: 100%;
        top: 0;
        &.left {
          left: 20px;
        }
        &.right {
          right: 20px;
        }
        &.body-bg {
          left: -5%;
          width: 100%;
        }
        &.body-webp {
          left: -5%;
          width: 100%;
        }
      }
      .data-item {
        color: #fff;
        z-index: 3;
        position: absolute;
        display: flex;
        > img {
          width: 26px;
          height: 26px;
          margin-right: 5px;
        }
        .item-val {
          height: 33px;
          background: url("~@/assets/heatingSystem/设备弹框大.png") no-repeat
            center;
          background-size: 100% 100%;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          padding: 2px 8px 0 8px;
          text-align: center;
          &.data-item-width {
            width: 160px;
          }
          > span {
            color: #2ed6ff;
          }
        }
        &.item1-1 {
          top: 27%;
          left: 16%;
        }
        &.item1-2 {
          top: 18%;
          left: 34%;
        }
        &.item1-3 {
          top: 8%;
          left: 43%;
        }
        &.item2-1 {
          left: 21%;
          top: 40%;
        }
        &.item2-2 {
          left: 42%;
          top: 25%;
        }
        &.item3-1 {
          left: 26%;
          top: 53%;
        }
        &.item3-2 {
          left: 46%;
          top: 35%;
        }
        &.item4-1 {
          left: 32%;
          top: 66%;
        }
        &.item4-2 {
          left: 46%;
          top: 36%;
        }
        &.item4-3 {
          left: 63%;
          top: 40%;
        }
        &.item5-1 {
          left: 45%;
          top: 80%;
        }
        &.item5-2 {
          left: 62%;
          top: 75%;
        }
        &.item5-3 {
          left: 55%;
          top: 52%;
        }
        &.item5-4 {
          left: 72%;
          top: 56%;
        }
        &.item6 {
          display: flex;
          // flex-direction: column;
          right: 6%;
          top: 5%;
          .item6-item {
            display: flex;
            justify-content: center;
            margin-right: 10px;
            img {
              width: 22px;
              height: 22px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .body-right {
      width: 16%;
      z-index: 2;
      .boiler {
        .boiler-item {
          margin-top: 14px;
          .boiler-title {
            display: flex;
            margin-bottom: 10px;
            .boiler-status {
              width: 20px;
              height: 20px;
              &.boiler-status-0 {
                background: url("~@/assets/heatingSystemWebp/boiler0.webp");
                background-size: 100% 100%;
              }
              &.boiler-status-1 {
                background: url("~@/assets/heatingSystemWebp/boiler2.png");
                background-size: 100% 100%;
              }
              &.boiler-status-2 {
                background: url("~@/assets/heatingSystemWebp/boiler1.png");
                background-size: 100% 100%;
              }
              margin-right: 9px;
            }
            .boiler-name {
              font-size: 16px;
              font-weight: 500;
              color: #fff;
              span {
                color: #2ed6ff;
              }
            }
          }
          .boiler-body {
            display: flex;
            > img {
              width: 88px;
              height: 86px;
            }
            .boiler-num {
              margin-left: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .boiler-change {
                font-size: 14px;
                color: #ff8f00;
              }
              .boiler-value {
                font-size: 26px;
                font-weight: bold;
                color: #00dbff;
                text-shadow: 0px 0px 0px #236aff;
              }
              .boiler-unit {
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
  .bgWebp {
    display: none;
  }
  .foot {
    height: 28%;
    display: flex;
    > div {
      background: #031029;
      // box-shadow: 2px -2px 10px 2px #001358;
      border: 1px solid #3b75ffb3;
    }
    .columnar {
      flex: 2;
      margin-right: 10px;
    }
    .month {
      flex: 1;
    }
    .columnar {
      .heat-title {
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
      }
      .columnar-title {
        display: flex;
        border-radius: 4px;
        .columnar-title-item {
          height: 32px;
          padding: 0 10px;
          line-height: 32px;
          text-align: center;
          border-radius: 0px;
          border: 1px solid #3f6df8;
          font-size: 14px;
          cursor: pointer;
          &.active {
            background-color: #3f6df8;
          }
        }
      }
    }
    .month-body {
      display: flex;
      flex-direction: column;
      height: calc(100% - 32px);
      justify-content: space-around;
      .month-item {
        height: 46%;
        width: 100%;
        background: linear-gradient(
          100deg,
          rgba(44, 119, 242, 0.18) 0%,
          rgb(1, 8, 35, 0.31) 100%
        );
        box-shadow: 0px 2px 4px 0px rgb(0, 0, 0, 0.31);
        max-height: 110px;
        display: flex;
        align-items: center;
        .item-box-center {
          width: 90%;
          height: 90%;
          background: linear-gradient(
            90deg,
            #00030c 0%,
            rgba(0, 0, 0, 0.69) 100%
          );
          padding: 5px;
          text-align: center;
          .item-box-center-img {
            width: 45px;
            height: 45px;
            background: linear-gradient(
              268deg,
              #c58608 0%,
              rgba(202, 140, 0, 0.43) 100%
            );
            box-shadow: 0px 2px 4px 0px #000000;
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            text-shadow: 0px 2px 4px #000000;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .item-box-center-val {
            font-size: 18px;
            font-weight: bold;
            color: #00dbff;
            text-shadow: 0px 2px 4px #000000, 0px 0px 0px #236aff;
            &.top {
              color: #fbbb18;
            }
          }
        }
      }
    }
    .item-circle {
      display: flex;
      color: #fff;
      flex: 1;
      .circle-box {
        width: 72px;
        height: 72px;
        background: url("~@/assets/heatingSystemWebp/能耗圆底.webp");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        .circle-box-name {
          font-size: 16px;
          font-weight: 800;
          color: #ffffff;
          text-shadow: 0px 2px 4px #000000;
        }
        .circle-box-val {
          font-size: 12px;
          color: #ffffff;
          text-shadow: 0px 2px 4px #000000;
        }
      }
      .circle-right {
        display: flex;
        justify-content: center;
        flex-direction: column;
        // align-items: center;
      }
      .circle-val {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 2px 4px #000000, 0px 0px 0px #236aff;
      }
      .circle-name {
        font-size: 12px;
        color: #ffffff;
        line-height: 20px;
        text-shadow: 0px 2px 4px #000000;
      }
    }
    .month-item {
      .item-box {
        width: 25%;
        max-width: 130px;
        height: 100%;
        border: 1px solid #3b76ff81;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before,
        &:after {
          content: " ";
          display: block;
          width: 7px;
          height: 100%;
          border-top: 2px solid #1dfff5;
          border-bottom: 2px solid #1dfff5;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          top: 0;
        }
        &:after {
          right: 0;
          left: auto;
        }
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  .columnar-searchTime {
    margin-right: 0px;
    .el-input__inner {
      background: transparent;
      color: #fff;
    }
  }
}
</style>
<style>
.heat-title {
  width: 100%b;
  height: 32px;
  line-height: 30px;
  padding-left: 35px;
  background: url("~@/assets/heatingSystem/标题底纹理.png") no-repeat left,
    linear-gradient(90deg, #2c50bf 0%, #2c50bf 0%, rgba(11, 53, 182, 0) 100%);
  background-size: auto 100%;
  opacity: 0.9;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #8ba7fa, rgba(236, 6, 37, 0) 50%) 10 0;
}
.heatingSystem .el-loading-mask {
  background-color: #1f386566;
}
</style>
