<template>
  <div class="reportSearch1">
    <img
      class="reportSearch1-img"
      src="~@/assets/workshopMonitor/add_notes.png"
      alt=""
    />
    <span class="reportSearch1-span">功能即将上线，敬请期待~</span>
  </div>
</template>

<script>
export default {
  name: "reportSearch1",
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style>
.reportSearch1 {
  background-color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.reportSearch1-img {
  width: 400px;
}
span.reportSearch1-span {
  font-size: 16px;
}
</style>
