<template>
  <div style="width: 100%; height: 100%; background: white; padding: 15px">
    <div
      style="
        width: 100%;
        height: 50px;
        border-bottom: 1px solid rgba(8, 34, 84, 0.1);
        padding-left: 50px;
      "
    >
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor: pointer;
        "
        v-for="(item, index) in dataTabs"
        :key="index"
        @click="clickTabs(item)"
        :class="{ isClick: currentTabs === item.energyTypeId }"
      >
        {{ item.energyTypeName }}
      </div>
    </div>

    <div
      style="
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10px;
      "
    >
      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
            margin-right: 20px;
            cursor: pointer;
          "
          @click="toAdd"
        >
          新增
        </div>
        <!-- <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
          "
        >
          数据导出
        </div> -->
      </div>
    </div>
    <div style="width: 100%">
      <!-- <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 30px"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column fixed prop="name" label="监测点位" width="120">
        </el-table-column>
        <el-table-column fixed prop="unit" label="单位" width="120">
        </el-table-column>
      </el-table> -->

      <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 10px"
        :border="isborder1"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <!-- <el-table-column prop="userId" width="100" label="用户编码">
        </el-table-column> -->
        <el-table-column
          prop="treeName"
          label="树结构名称"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="energyType"
          label="能源类型"
          width="150"
        ></el-table-column>

        <el-table-column prop="createUserName" label="创建者" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.createUserName">
              {{ scope.row.createUserName }}
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="170">
          <template slot-scope="scope">
            <template v-if="scope.row.createTime">
              {{ scope.row.createTime | formateV }}
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column prop="updateUserName" label="修改者" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.updateUserName">
              {{ scope.row.updateUserName }}
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="修改时间" width="170">
          <template slot-scope="scope">
            <template v-if="scope.row.updateTime">
              {{ scope.row.updateTime | formateV }}
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column prop="defaultTree" label="默认树" width="170">
          <template slot-scope="scope">
            <template v-if="scope.row.defaultTree"> 是 </template>
            <template v-else>否</template>
          </template>
        </el-table-column>
        <el-table-column prop="memo" label="备注" width="170">
          <template slot-scope="scope">
            <template v-if="scope.row.memo">
              {{ scope.row.memo }}
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              v-if="!scope.row.defaultTree"
              style="color: #f2bc40; margin-right: 5px; cursor: pointer"
              @click="changeStatus(scope.row.treeId, 0)"
            >
              设为默认
            </span>
            <span
              style="color: #3f6df8; margin-right: 5px; cursor: pointer"
              @click="toDetail(scope.row.treeId)"
            >
              详情
            </span>
            <!-- <span
              style="color: #3f6df8; margin-right: 5px; cursor: pointer"
              @click="resetPassword(scope.row.userId)"
            >
              重置密码
            </span> -->
            <span
              style="color: #3f6df8; margin-right: 5px; cursor: pointer"
              @click="showUpdate(scope.row.treeId)"
              v-if="!scope.row.defaultTree"
            >
              <!-- v-if="!scope.row.released" -->
              修改
            </span>
            <span
              style="color: #e52323; margin-right: 5px; cursor: pointer"
              @click="deleteReport(scope.row.treeId)"
              v-if="!scope.row.defaultTree"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 15px; text-align: right">
        <el-pagination
          @size-change="sizeChange"
          @current-change="pageChange"
          :current-page="query.page"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="query.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      title="重置密码"
      :visible.sync="changeReVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <div style="color: #082254; text-align: center; height: 50px">
        是否重置密码？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureReset">确 定</el-button>
        <el-button @click="changeReVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="userSettingTitle"
      :visible.sync="changePasswordVisible"
      :close-on-click-modal="false"
      width="70%"
      center
    >
      <el-form
        :model="treeForm"
        :rules="rules"
        :inline="true"
        ref="treeForm"
        label-width="110px"
      >
        <el-form-item
          label="能源类型："
          prop="energyTypeId"
          style="position: relative"
          class="tree-big"
        >
          <el-select
            :disabled="isView"
            placeholder="请选择"
            v-model="treeForm.energyTypeId"
          >
            <el-option
              v-for="item in dataTabs2"
              :key="item.energyTypeId"
              :label="item.energyTypeName"
              :value="item.energyTypeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="树结构名称："
          prop="treeName"
          style="position: relative"
          class="tree-big"
        >
          <el-input v-model="treeForm.treeName" :disabled="isView"></el-input>
          <div
            style="
              position: absolute;
              width: 26px;
              height: 26px;
              color: #999999;
              text-align: center;
              line-height: 24px;
              border: 1px solid #999999;
              border-radius: 2px;
              font-size: 20px;
              top: 7px;
              right: -40px;
              cursor: pointer;
            "
            @click="addTreeNode"
            v-if="!isView"
          >
            +
          </div>
        </el-form-item>

        <div
          style="
            width: 100%;
            min-height: 124px;
            background: rgba(63, 109, 248, 0.1);
            margin-bottom: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;
          "
          v-for="(item, index) in treeForm.treeConfigNodeList"
          :key="index"
        >
          <div
            v-if="!isView"
            style="position: absolute; top: 3px; right: 10px; cursor: pointer"
            @click="deleteTree(index)"
          >
            <i class="el-icon-circle-close" style="color: #406df8"></i>
          </div>
          <!-- :prop="'testList[' + index + '].nodeName'" -->
          <div>
            <el-form-item
              label="父节点："
              :rules="rules.nodeName"
              :prop="'treeConfigNodeList.' + index + '.nodeName'"
              style="position: relative; margin-bottom: 10px"
              class="inline-input"
            >
              <el-input
                :disabled="isView"
                v-model="item.nodeName"
                placeholder="请输入名称"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="数据源类型："
              prop="reportName"
              style="position: relative"
              class="inline-input"
            >
              <el-select
                :disabled="isView"
                v-model="item.show"
                placeholder="请选择"
                @change="changeInType"
              >
                <el-option
                  v-for="item in optionsSourceType"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>

              <!-- <div
                style="position: absolute; top: 0; right: -25px"
                v-if="item.show"
              >
                <el-dropdown @command="handleCommand" v-if="!isView">
                  <i class="el-icon-more-outline"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="{
                        command: 'a',
                        index: index,
                      }"
                      >新增子节点</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="{
                        command: 'b',
                        index: index,
                      }"
                      >删除该节点</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div> -->
              <!-- <div style="position: absolute; top: 0; right: -40px">
                <i class="el-icon-circle-plus-outline"></i>
              </div>
              <div style="position: absolute; top: 0; right: -80px">
                <i class="el-icon-circle-close"></i>
              </div> -->
            </el-form-item>

            <el-form-item
              v-if="!item.show"
              label="绑定仪表："
              prop="reportName"
              style="position: relative"
              class="inline-input"
            >
              <el-select
                :disabled="isView"
                v-model="item.instanceId"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in insatnceArr"
                  :key="item.instanceId"
                  :label="item.name"
                  :value="item.instanceId"
                >
                </el-option>
              </el-select>

              <div
                style="position: absolute; top: 0; right: -25px"
                v-if="!isView"
              >
                <el-dropdown trigger="click" @command="handleCommand">
                  <i class="el-icon-more-outline"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="{
                        command: 'a',
                        index: index,
                      }"
                      >新增子节点</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="{
                        command: 'b',
                        index: index,
                      }"
                      >删除该节点</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>

            <el-form-item
              v-if="item.show"
              label="绑定仪表："
              prop="reportName"
              style="position: relative"
              class="inline-input"
            >
              <el-select
                :disabled="isView"
                filterable
                v-model="item.instanceId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in insatnceArr2"
                  :key="item.instanceId"
                  :label="item.name"
                  :value="item.instanceId"
                >
                </el-option>
              </el-select>

              <div
                style="position: absolute; top: 0; right: -25px"
                v-if="!isView"
              >
                <el-dropdown trigger="click" @command="handleCommand">
                  <i class="el-icon-more-outline"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="{
                        command: 'a',
                        index: index,
                      }"
                      >新增子节点</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="{
                        command: 'b',
                        index: index,
                      }"
                      >删除该节点</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
          </div>
          <form-list
            :list="treeForm.treeConfigNodeList[index].childNodeList"
            :isView="isView"
          ></form-list>
        </div>
        <el-form-item
          label="备注："
          prop="memo"
          style="position: relative"
          class="tree-big"
        >
          <el-input :disabled="isView" v-model="treeForm.memo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePasswordVisible = false">取 消</el-button>
        <el-button type="primary" @click="addNew">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import formList from "./list";
import {
  setProject,
  getInstanceType,
  // getModuleType,
  // getEnergy,
  // getInstance,
  updateTree,
  deleteTree,
  getTreeDetail,
  // updateModule,
  getTreeList,
  resetPassword,
  // getUserDetail,
  // addUser,
  // userSearch,
  // deleteUser,
  // editUser,
  // alarmSetGet,
  // bindInstanceTree,
  getInstanceTree,
  setDefaultTree,
  reportManagechangeStatus,
  // getInstanceAttr,
  // getModuleType,
  getEnergy,
  getEnergyAttr,
} from "@/request/apiLg";
export default {
  components: {
    formList,
  },
  filters: {
    formateV: function (value) {
      if (value) {
        var date1 = new Date(value);
        var YY = date1.getFullYear() + "-";
        var MM =
          (date1.getMonth() + 1 < 10
            ? "0" + (date1.getMonth() + 1)
            : date1.getMonth() + 1) + "-";
        var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
        var hh =
          (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
          ":";
        var mm =
          (date1.getMinutes() < 10
            ? "0" + date1.getMinutes()
            : date1.getMinutes()) + ":";
        var ss =
          date1.getSeconds() < 10
            ? "0" + date1.getSeconds()
            : date1.getSeconds();
        return YY + MM + DD + " " + hh + mm + ss;
      } else {
        return "--";
      }
    },
  },
  data() {
    // var validConfirmPass = (rule, value, callback) => {
    //   // debugger
    //   console.log("this.ruleForm", this.ruleForm);
    //   if (
    //     value !== "" &&
    //     this.ruleForm.newPassword !== "" &&
    //     value != this.ruleForm.newPassword
    //   ) {
    //     callback(new Error("密码与上面的新密码不符"));
    //   }
    //   callback();
    // };
    return {
      isReload: true,
      tempUNIT: [],
      optionsEnergyType: [],
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          // const { level } = node;
          // setTimeout(() => {
          //   let id = 1;
          //   const nodes = Array.from({ length: level + 1 }).map(() => ({
          //     value: ++id,
          //     label: `选项${id}`,
          //     leaf: level >= 2,
          //   }));
          //   // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          //   resolve(nodes);
          // }, 1000);
          setTimeout(() => {
            if (node.level == 0) {
              getEnergy().then((response) => {
                const cities = response.data.data.map((value) => ({
                  value: value.energyTypeId,
                  label: value.energyTypeName,
                  leaf: node.level >= 1,
                }));
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getEnergyAttr(node.value).then((response) => {
                const areas = response.data.data.map((value) => ({
                  value: value.energyAttrId,
                  label: value.energyAttrName,
                  leaf: node.level >= 1,
                }));
                // this.tempUNIT = response.data.data
                resolve(areas);
              });
            }
            // else if (node.level == 2) {
            //   findChildById(node.value).then(response => {
            //     const areas = response.data.map((value, i) => ({
            //       value: value.id,
            //       label: value.name,
            //       leaf: node.level >= 2
            //     }));
            //     resolve(areas);
            //   });
            // }
          }, 100);
        },
      },

      dataTabs: [],
      dataTabs2: [],
      currentTabs: "",
      // insatnceArr:[],

      userSettingTitle: "新增树结构",
      tempUserId: "",

      isEdit: false,
      isView: false,
      testList: [
        {
          nodeName: "",
          show: false,
          childNodeList: [],
        },
      ],
      listTemp: [
        {
          name: 1,
          children: [
            {
              name: 2,
              children: [
                {
                  name: 3,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      changeTreeVisible: false,
      changeReVisible: false,
      optionsInstance: [],
      optionsInstanceAttr: [],
      optionsSourceType: [
        {
          name: "具体仪表",
          code: false,
        },
        {
          name: "虚拟节点",
          code: true,
        },
      ],
      dateV: 1,
      attr1: [
        {
          attrId: "ANALOG",
          attrName: "模拟量",
        },
        {
          attrId: "DIGITAL",
          attrName: "数字量",
        },
      ],
      attr2: [
        {
          attrId: "DIRECT_GATHER",
          attrName: "直接采集",
        },
        {
          attrId: "CALC",
          attrName: "计算量",
        },
      ],
      attr3: [
        {
          attrId: "INSTANT",
          attrName: "瞬时值",
        },
        {
          attrId: "ACCUM",
          attrName: "累计值",
        },
      ],
      selectTimeV21: 1,
      selectTimeV22: 0,
      isShowMonth: false,
      selectTimeV: 0,
      treeReportId: "",
      isShowDay: false,
      options1: [
        { code: "R", name: "只读" },
        { code: "RW", name: "读写" },
      ],
      options2: [[]],
      rules: {
        energyTypeId: [
          { required: true, message: "请输入选择能源类型", trigger: "change" },
        ],
        treeName: [
          { required: true, message: "请选中树结构名称", trigger: "blur" },
        ],
        nodeName: [
          { required: true, message: "请输入节点名称", trigger: "blur" },
        ],
        // dataPrivilegeGroupIdList: [
        //   { required: true, message: "请选择项目", trigger: "change" },
        // ],
        // roleIdList: [
        //   { required: true, message: "请选择用户角色", trigger: "change" },
        // ],
        // newPassword: [
        //   { required: true, message: "请输入密码", trigger: "blur" },
        //   { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        // ],
        // confirmPassword: [
        //   { required: true, message: "请再次输入密码", trigger: "blur" },
        //   { validator: validConfirmPass, trigger: "blur" },
        //   { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        // ],
        // expireDate: [
        //   { required: true, message: "请选择到期时间", trigger: "change" },
        // ],
      },
      ruleForm: {
        memo: "",
        released: false,
        templateAttrList: [
          {
            attrId: [],
            attrName: "",
            dataResource: "DIRECT_GATHER",
            dataSource: "",
            dataSourceType: "ANALOG",
            dataType: "INSTANT",
            defaultAttr: true,
            energyTypeId: "",
            maxDisplay: 0,
            memo: "",
            minDisplay: 0,
            unit: "",
            privilege: "",
          },
        ],
        // templateId: "",
        templateName: "",
        templateTypeCode: "",
      },
      treeForm: {
        // treeName: "",
        // treeArray: [1],
        energyTypeId: "",
        memo: "",
        treeConfigNodeList: [
          {
            nodeName: "",
            show: false,
            childNodeList: [],
          },
          // {
          //   childNodeList: [
          //     {
          //       childNodeList: [{}],
          //       instanceId: "",
          //       nodeName: "",
          //       show: true,
          //     },
          //   ],
          //   instanceId: "",
          //   nodeName: "",
          //   show: true,
          // },
        ],
        // treeId: "",
        treeName: "",
      },
      changePasswordVisible: false,
      total: 0,
      loading: true,
      value11: "",
      value22: "",
      currentName: "",
      isInterval: false,
      value2: [],
      tableKey: [],
      tableKey2: [],
      isborder1: false,
      tableData: [],
      changeDateType: "date",
      selectValueX: "",
      isShowSelectx: false,
      selectValueY: "",
      isShowSelecty: false,
      // options1: [
      //   {
      //     value: "P",
      //     label: "电能-有功功率",
      //   },
      // ],
      // options2: [
      //   {
      //     value: "P",
      //     label: "水能-瞬时流量",
      //   },
      // ],
      value1: "",
      // value2: "",
      isActivenTime: 1,
      query: {
        limit: 10,
        page: 1,
        energyTypeId: "",
      },
    };
  },
  mounted() {
    // let dateT = new Date();
    // var YY = dateT.getFullYear() + "-";
    // var MM =
    //   (dateT.getMonth() + 1 < 10
    //     ? "0" + (dateT.getMonth() + 1)
    //     : dateT.getMonth() + 1) + "-";
    // var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    // this.value1 = YY + MM + DD;
    // userSearch(this.query).then((res2) => {
    //   this.loading = false;
    //   console.log(res2);
    //   this.tableData = res2.data.data;
    //   this.total = res2.data.count;
    // });

    // getModuleType().then((res) => {

    // });
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getEnergy().then((res) => {
            this.optionsEnergyType = res.data.data;
            console.log(res.data.data);
            this.dataTabs2 = JSON.parse(JSON.stringify(res.data.data));
            this.dataTabs = res.data.data;
            this.dataTabs.unshift({
              energyTypeName: "全部",
              energyTypeId: null,
            });

            this.currentTabs = res.data.data[0].energyTypeId;
            this.query.energyTypeId = res.data.data[0].energyTypeId;
            //   getTreeList(this.query).then((res2) => {
            //     this.loading = false;
            //     this.tableData = res2.data.data;
            //     this.total = res2.data.count;
            //   });
            getTreeList(this.query).then((res2) => {
              this.loading = false;
              this.tableData = res2.data.data;
              this.total = res2.data.count;
            });
          });

          getInstanceType(false).then((res) => {
            // this.insatnceArr = res.data.data;
            this.mutationsGetInstance(res.data.data);
          });
          getInstanceType(true).then((res) => {
            // this.insatnceArr = res.data.data;
            this.mutationsGetInstance2(res.data.data);
          });
        }
      }
    );

    // getEnergy().then(res=>{
    //   this.dataEnergy = res.data.data
    // })
  },
  watch: {
    currentProjectId(val) {
      console.log(val);
      this.optionsEnergyType = [];
      this.dataTabs = [];
      this.dataTabs2 = [];
      this.currentTabs = "";
      this.optionsInstance = [];
      this.tableData = [];
      this.total = 0;
      this.query = {
        limit: 10,
        page: 1,
        energyTypeId: "",
      };
      setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
        (res2) => {
          if (res2.data.code === 0) {
            getEnergy().then((res) => {
              this.optionsEnergyType = res.data.data;
              console.log(res.data.data);
              this.dataTabs2 = JSON.parse(JSON.stringify(res.data.data));
              this.dataTabs = res.data.data;
              this.dataTabs.unshift({
                energyTypeName: "全部",
                energyTypeId: null,
              });

              this.currentTabs = res.data.data[0].energyTypeId;
              this.query.energyTypeId = res.data.data[0].energyTypeId;
              //   getTreeList(this.query).then((res2) => {
              //     this.loading = false;
              //     this.tableData = res2.data.data;
              //     this.total = res2.data.count;
              //   });
              getTreeList(this.query).then((res2) => {
                this.loading = false;
                this.tableData = res2.data.data;
                this.total = res2.data.count;
              });
            });

            getInstanceType(false).then((res) => {
              // this.insatnceArr = res.data.data;
              this.mutationsGetInstance(res.data.data);
            });
            getInstanceType(true).then((res) => {
              // this.insatnceArr = res.data.data;
              this.mutationsGetInstance2(res.data.data);
            });
          }
        }
      );
    },
  },
  computed: {
    ...mapState({
      insatnceArr: (state) => state.insatnceArr,
      insatnceArr2: (state) => state.insatnceArr2,
      currentProjectId: (state) => state.currentProjectId,
    }),
  },
  methods: {
    changeInType(v) {
      console.log(v);
      // getInstanceType(v).then(res=>{
      //   this.insatnceArr = res.data.data
      // })
    },
    downItem(item, index) {
      console.log(item, index);
      let itemT = item;
      let tempArray = JSON.parse(JSON.stringify(this.ruleForm));
      if (index < tempArray.templateAttrList.length - 1) {
        this.isReload = false;
        this.$nextTick(() => {
          this.isReload = true;
          tempArray.templateAttrList[index] =
            tempArray.templateAttrList[index + 1];
          tempArray.templateAttrList[index + 1] = itemT;
          this.ruleForm = tempArray;
          console.log(
            "this.ruleForm.templateAttrList",
            this.ruleForm.templateAttrList
          );
        });
      }
    },
    upItem(item, index) {
      console.log(item, index);
      let itemT = item;
      let tempArray = JSON.parse(
        JSON.stringify(this.ruleForm.templateAttrList)
      );
      if (index > 0) {
        this.isReload = false;
        this.$nextTick(() => {
          this.isReload = true;
          tempArray[index] = tempArray[index - 1];
          tempArray[index - 1] = itemT;
          this.ruleForm.templateAttrList = tempArray;
          console.log(
            "this.ruleForm.templateAttrList",
            this.ruleForm.templateAttrList
          );
        });
      }
    },
    changeCas(value, index) {
      console.log(value, index);
      getEnergyAttr(value[0]).then((response) => {
        response.data.data.forEach((item) => {
          if (item.energyAttrId === value[1]) {
            let temp = "--";
            if (item.unit) {
              temp = item.unit;
            }
            this.ruleForm.templateAttrList[index].unit = temp;
            let temp2 = "--";
            if (item.dataSource) {
              temp2 = item.dataSource;
            }
            this.ruleForm.templateAttrList[index].dataSource = temp2;
            this.ruleForm.templateAttrList[index].attrName =
              item.energyAttrName;
            this.ruleForm.instanceAttrConfigVoList[index].privilege =
              item.privilege;
          }
        });
      });
    },
    clickTabs(i) {
      this.currentTabs = i.energyTypeId;
      this.query.energyTypeId = this.currentTabs;
      this.searchFor();
    },
    resetPassword(id) {
      this.tempUserId = id;
      this.changeReVisible = true;
    },
    sureReset() {
      resetPassword(this.tempUserId).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("重置密码成功，新密码为123456");
          this.changeReVisible = false;
        }
      });
    },
    ...mapMutations(["mutationsGetInstance", "mutationsGetInstance2"]),
    toDetail(id) {
      // alarmSetGet(id).then((resA) => {
      //   console.log(resA)
      // })
      // this.$router.push({
      //   name: "moduleDetail",
      //   query: { id: id },
      // });
      getTreeDetail(id).then((resA) => {
        if (resA.data.code === 0) {
          this.userSettingTitle = "树结构详情";
          this.changePasswordVisible = true;
          this.isEdit = false;
          this.isView = true;
          this.treeForm = resA.data.data;
          // this.testList = resA.data.data.treeConfigNodeList;
        }
      });
    },
    showUpdate(id) {
      getTreeDetail(id).then((resA) => {
        if (resA.data.code === 0) {
          this.userSettingTitle = "编辑树结构";
          this.changePasswordVisible = true;
          this.isEdit = true;
          this.isView = false;
          // resA.data.data.templateAttrList.forEach((item) => {
          //   if (!item.unit) {
          //     item.unit = "--";
          //   }
          //   item.attrId = [item.energyTypeId, item.attrId];
          // });
          // // let temp = JSON.parse(JSON.stringify(row));
          // // temp.attrId = [temp.energyTypeId, temp.attrId];
          this.treeForm = resA.data.data;
          // if (resA.data.data.treeConfigNodeList.length > 0) {
          //   this.testList = resA.data.data.treeConfigNodeList;
          // } else {
          //   this.testList = [
          //     {
          //       nodeName: "",
          //       show: true,
          //       childNodeList: [],
          //     },
          //   ];
          // }
          // this.testList = resA.data.data.treeConfigNodeList;
        }
      });
    },
    changeStatus(id, index) {
      if (index === 0) {
        this.$confirm("确认设为默认？")
          .then(() => {
            // done();
            setDefaultTree(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("设为默认成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      } else if (index === 1) {
        this.$confirm("确认发布？")
          .then(() => {
            // done();
            reportManagechangeStatus(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      }
    },
    deleteReport(id) {
      this.$confirm("确认删除？")
        .then(() => {
          // done();
          deleteTree(id).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.searchFor();
            }
          });
        })
        .catch(() => {});
    },
    toBind(id) {
      console.log(id);
      this.treeReportId = id;
      getInstanceTree(id).then((res) => {
        console.log(res);
        if (res.data.data) {
          if (res.data.data.treeNodeList.length > 0) {
            this.testList = res.data.data.treeNodeList;
          }
          this.treeForm.treeName = res.data.data.treeName;
        }

        this.changeTreeVisible = true;
      });
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      console.log(command);
      if (command.command === "a") {
        this.treeForm.treeConfigNodeList[command.index].childNodeList.push({
          childNodeList: [],
          nodeName: "",
          show: false,
        });
      } else if (command.command === "b") {
        this.treeForm.treeConfigNodeList.splice(command.index, 1);
      }
    },
    addTreeNode() {
      this.treeForm.treeConfigNodeList.push({
        childNodeList: [],
        nodeName: "",
        show: false,
      });
    },
    deleteTree(index) {
      this.treeForm.treeConfigNodeList.splice(index, 1);
    },
    selectTimeOption(item, e) {
      e.stopPropagation();
      this.selectTimeV = item;
      this.isShowDay = false;
    },
    selectTimeOption2(item, e) {
      e.stopPropagation();
      this.selectTimeV21 = item;
      this.isShowMonth = false;
    },
    showDaySelect() {
      this.isShowDay = !this.isShowDay;
    },
    showMonthSelect() {
      this.isShowMonth = !this.isShowMonth;
    },
    addTypes() {
      this.ruleForm.templateAttrList.push({
        attrId: [],
        attrName: "",
        dataResource: "DIRECT_GATHER",
        dataSource: "",
        dataSourceType: "ANALOG",
        dataType: "INSTANT",
        defaultAttr: true,
        energyTypeId: "",
        maxDisplay: 0,
        memo: "",
        minDisplay: 0,
        privilege: "R",
        unit: "",
      });
      // this.options2.push([]);
      // let temp = JSON.parse(JSON.stringify(this.options2));
      // this.options2 = temp;
    },
    deleteTypes(index) {
      console.log(index);
      this.ruleForm.templateAttrList.splice(index, 1);
      // this.options2.splice(index, 1);
      // let temp = JSON.parse(JSON.stringify(this.options2));
      // this.options2 = temp;
    },
    getOption(e) {
      console.log(e);
      // getInstanceAttr(e).then((res2) => {
      //   // debugger
      //   // this.options2[v] = res2.data.data;
      //   // console.log("this.options2", this.options2);
      //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //   // this.options2 = temp;
      //   this.optionsInstanceAttr = res2.data.data;
      //   this.ruleForm.maxDisplay = "";
      //   this.ruleForm.minDisplay = "";
      //   this.ruleForm.attrId = "";
      // });
    },
    getOption2(e) {
      console.log(e);
      // this.optionsInstanceAttr.forEach((item) => {
      //   if (item.attrId === e) {
      //     if (item.maxDisplay !== null) {
      //       this.ruleForm.maxDisplay = item.maxDisplay;
      //     }
      //     if (item.minDisplay !== null) {
      //       this.ruleForm.minDisplay = item.minDisplay;
      //     }
      //   }
      // });
      // getInstanceAttr(e).then((res2) => {
      //   // debugger
      //   // this.options2[v] = res2.data.data;
      //   // console.log("this.options2", this.options2);
      //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //   // this.options2 = temp;
      //   this.optionsInstanceAttr = res2.data.data;
      // });
    },
    toAdd() {
      this.changePasswordVisible = true;
      this.isEdit = false;
      this.isView = false;
      this.userSettingTitle = "新增树结构";
      this.treeForm = {
        energyTypeId: "",
        memo: "",
        treeConfigNodeList: [
          {
            nodeName: "",
            show: false,
            childNodeList: [],
          },
          // {
          //   childNodeList: [
          //     {
          //       childNodeList: [{}],
          //       instanceId: "",
          //       nodeName: "",
          //       show: true,
          //     },
          //   ],
          //   instanceId: "",
          //   nodeName: "",
          //   show: true,
          // },
        ],
        // treeId: "",
        treeName: "",
      };
    },
    sizeChange(val) {
      this.query.page = 1;
      this.query.limit = val;
      this.searchFor();
    },
    pageChange(val) {
      this.query.page = val;
      this.searchFor();
    },
    searchFor() {
      // this.$router.push({ name: "alarmDetail" });
      // console.log("this.value1", this.value1);
      this.loading = true;
      this.query.energyTypeId = this.currentTabs;
      getTreeList(this.query).then((res2) => {
        this.loading = false;
        this.tableData = res2.data.data;
        this.total = res2.data.count;
      });
    },
    reportTypeV(v) {
      let temp = [];
      v.forEach((item) => {
        temp.push(item);
      });
      temp = temp.join(",");
      return temp;
    },
    reportTypeV2(v) {
      let temp = [];
      v.forEach((item) => {
        if (item.reportParas) {
          item.reportParas.forEach((item2) => {
            temp.push(item2.reportTypeName);
          });
        }
      });
      temp = temp.join(",");
      return temp;
    },
    timeChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    clickTime(index) {
      this.isActivenTime = index;
      this.value1 = "";
      this.value2 = [];
      if (index == 1) {
        this.isInterval = false;
        this.changeDateType = "date";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD;
      } else if (index == 2) {
        this.isInterval = false;
        this.changeDateType = "month";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 3) {
        this.isInterval = false;
        this.changeDateType = "year";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 4) {
        this.isInterval = true;
        let dateT = new Date();
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value11 = YY + MM + DD;
        dateT = new Date(this.value11);
        dateT = dateT.setDate(dateT.getDate() + 1);
        this.value22 = this.formatDate(dateT);
        this.value22 = this.value22.split(" ")[0];
        console.log("this.value1", this.value11);
        console.log("this.value2", this.value22);
        this.value2 = [this.value11, this.value22];
        this.query.startTime = this.value11;
        this.query.endTime = this.value22;
      }
    },
    clickX() {},
    clickOptionX() {},
    clickY() {},
    clickOptionY() {},
    onSearch() {
      this.loading = true;
      this.tableData = [];
      this.tableKey = [];
      this.tableKey2 = [];
      if (this.isActivenTime == 1) {
        this.query.startTime = this.value1;
        this.query.endTime = "";
        this.query.unit = "DAYS";
      } else if (this.isActivenTime == 2) {
        this.query.startTime =
          this.value1.split("-")[0] + "-" + this.value1.split("-")[1];
        this.query.endTime = "";
        this.query.unit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        this.query.startTime = this.value1.split("-")[0];
        this.query.endTime = "";
        this.query.unit = "YEARS";
      } else if (this.isActivenTime == 4) {
        // this.query.startTime = this.value1;
        // this.query.endTime = "";
        this.query.unit = null;
      }
    },
    bindTree() {
      // let temp = {
      //   reportId: this.treeReportId,
      //   treeName: this.treeForm.treeName,
      //   treeConfigNodeList: this.testList,
      // };
      this.treeForm.treeConfigNodeList = this.testList;
      updateTree(this.treeForm).then((res) => {
        if (res.data.code === 0) {
          this.changeTreeVisible = false;
          this.$message.success("绑定成功");
          this.searchFor();
        }
      });
    },
    addNew() {
      if (this.isView) {
        this.changePasswordVisible = false;
      } else {
        // this.treeForm.treeConfigNodeList = this.testList;

        this.$refs["treeForm"].validate((valid) => {
          if (valid) {
            if (!this.isEdit) {
              // let temp = JSON.parse(JSON.stringify(this.ruleForm));
              // temp.templateAttrList.forEach((item) => {
              //   let tempA = JSON.parse(JSON.stringify(item.attrId));
              //   item.attrId = tempA[1];
              //   item.energyTypeId = tempA[0];
              //   if (item.unit === "--") {
              //     item.unit = "";
              //   }
              // });
              // this.treeForm.treeConfigNodeList = this.testList;
              let isNUll = false
              const toggle3 = (data) => {
                data.forEach((item) => {
                  // debugger
                  if (!item.instanceId) {
                    isNUll = true
                  } else {
                    if (item.childNodeList) {
                      toggle3(item.childNodeList);
                    }
                  }
                });
              };
              toggle3(this.treeForm.treeConfigNodeList);
              // this.treeForm.treeConfigNodeList;
              if(isNUll){
                this.$message.error('节点绑定仪表不能为空')
                return
              }
              updateTree(this.treeForm).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.$message.success("新增成功");
                  this.changePasswordVisible = false;
                  this.searchFor();
                }
              });
            } else {
              // let temp = JSON.parse(JSON.stringify(this.ruleForm));
              // temp.templateAttrList.forEach((item) => {
              //   let tempA = JSON.parse(JSON.stringify(item.attrId));
              //   item.attrId = tempA[1];
              //   item.energyTypeId = tempA[0];
              //   if (item.unit === "--") {
              //     item.unit = "";
              //   }
              // });
              // this.treeForm.treeConfigNodeList = this.testList;
              let isNUll = false
              const toggle3 = (data) => {
                data.forEach((item) => {
                  // debugger
                  if (!item.instanceId) {
                    isNUll = true
                  } else {
                    if (item.childNodeList) {
                      toggle3(item.childNodeList);
                    }
                  }
                });
              };
              toggle3(this.treeForm.treeConfigNodeList);
              // this.treeForm.treeConfigNodeList;
              if(isNUll){
                this.$message.error('节点绑定仪表不能为空')
                return
              }
              updateTree(this.treeForm).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.changePasswordVisible = false;
                  this.$message.success("编辑成功");
                  this.searchFor();
                }
              });
            }
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.isClick {
  color: #3e6cf7 !important;
  border-bottom: 4px solid #3e6cf7;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  color: #3f6df8;
  /* color: white; */
}
.el-dialog__wrapper >>> input {
  width: 350px !important;
  height: 32px !important;
}

.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
.time-square:hover {
  background: #3f6df8 !important;
  color: white !important;
}
.isSelectTime {
  background: #3f6df8 !important;
  color: white !important;
}
.small-s >>> input {
  width: 220px !important;
  height: 32px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 750px !important;
}
.inline-input >>> input {
  width: 160px !important;
}
.small-input >>> input {
  width: 80px !important;
}
.small-input >>> .el-input {
  width: 80px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 1100px !important;
}
.inline-input >>> input {
  width: 200px !important;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>