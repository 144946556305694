<template>
  <el-dialog
    :title="userSettingTitle"
    :visible.sync="projectVisible"
    :close-on-click-modal="false"
    width="30%"
    center
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
    >
      <el-form-item
        label="项目编码："
        prop="projectId"
        style="position: relative; margin-bottom: 20px"
      >
        <el-input :disabled="isView" v-model="ruleForm.projectId"></el-input>
      </el-form-item>
      <el-form-item
        label="项目名称："
        prop="projectName"
        style="position: relative; margin-bottom: 20px"
      >
        <el-input :disabled="isView" v-model="ruleForm.projectName"></el-input>
      </el-form-item>
      <el-form-item
        label="项目类型："
        prop="projectType"
        style="position: relative; margin-bottom: 20px"
      >
        <el-select
          v-model="ruleForm.projectType"
          placeholder="请选择"
          style=""
          clearable
        >
          <el-option
            v-for="item in optionsType2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="项目面积："
        prop="projectArea"
        style="position: relative; margin-bottom: 20px"
      >
        <el-input :disabled="isView" v-model="ruleForm.projectArea"></el-input>
      </el-form-item>
      <el-form-item
        label="项目简介："
        prop="projectDesc"
        style="position: relative; margin-bottom: 20px"
      >
        <el-input :disabled="isView" v-model="ruleForm.projectDesc"></el-input>
      </el-form-item>
      <el-form-item
        label="上传图片："
        prop="username"
        style="position: relative; margin-bottom: 20px"
      >
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item
        label="是否发布："
        prop="enabled"
        style="position: relative; margin-bottom: 10px"
      >
        <el-switch :disabled="isView" v-model="ruleForm.enabled"> </el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="addProjectBtn">新 增</el-button>
      <el-button @click="projectVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateProjectSetting } from "@/request/apiLg";

export default {
  props: {
    addProject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userSettingTitle: "新增项目",
      projectVisible: false,
      imageUrl: "",
      ruleForm: {
        enabled: false,
        projectArea: "",
        projectDesc: "",
        // projectGroups: [],
        projectId: "",
        projectName: "",
        projectType: "",
      },
      isView: false,
      isEdit: false,
      optionsType: [
        {
          value: "ALL",
          label: "所有",
        },
        {
          value: "BUSINESS",
          label: "商业用户",
        },
        {
          value: "ENERGY_STATION",
          label: "能源站",
        },
        {
          value: "INDUSTRIAL",
          label: "工业用户",
        },
        {
          value: "POWER_STATION",
          label: "光伏电站",
        },
        {
          value: "STORAGE_POWER_STATION",
          label: "储能电站",
        },
      ],
      optionsType2: [
        {
          value: "BUSINESS",
          label: "商业用户",
        },
        {
          value: "ENERGY_STATION",
          label: "能源站",
        },
        {
          value: "INDUSTRIAL",
          label: "工业用户",
        },
        {
          value: "POWER_STATION",
          label: "光伏电站",
        },
        {
          value: "STORAGE_POWER_STATION",
          label: "储能电站",
        },
      ],
      rules: {},
    };
  },
  methods: {
    changeProjectVisible() {
      this.projectVisible = !this.projectVisible;
    },
    addProjectBtn() {
      debugger;
      let _this = this;
      if (this.isView) {
        this.projectVisible = false;
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            if (!this.isEdit) {
              updateProjectSetting(this.ruleForm).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.$message.success("新增成功");
                  this.$emit("addProject", {
                    projectId: _this.ruleForm.projectId,
                    projectName: _this.ruleForm.projectName,
                    tenantId: "",
                  });
                  this.projectVisible = false;
                  this.searchFor();
                }
              });
            } else {
              updateProjectSetting(this.ruleForm).then((res) => {
                if (res.data.code === 0) {
                  this.projectVisible = false;
                  this.$message.success("编辑成功");
                  this.searchFor();
                }
              });
            }
          }
        });
      }
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
