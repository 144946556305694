<template>
  <div
    style="
      width: 100%;
      height: 100%;
      background: white;
      padding: 15px;
      overflow: auto;
    "
  >
    <div
      style="display: flex; justify-content: flex-start; align-items: center"
    >
      <div>报表名称：</div>
      <div
        style="position: relative; margin-left: 5px; cursor: pointer"
        class="select"
      >
        <el-select
          v-model="query.reportId"
          placeholder="请选择报表"
          style="margin-left: 10px"
        >
          <el-option
            v-for="item in options1"
            :key="item.reportId"
            :label="item.name"
            :value="item.reportId"
          >
          </el-option>
        </el-select>
        <!-- <input
                v-model="selectValue"
                readonly
                style="height: 29px; width: 140px"
              /> -->
        <!-- <el-input
          v-model="selectValueX"
          placeholder="请输入报表名称"
          style="width: 160px"
        >
          
        </el-input> -->
      </div>

      <div
        class="square2-category"
        style="border-radius: 2px 0 0 2px; margin-left: 20px"
        @click="clickTime(1)"
        :class="{ activeNH: isActivenTime === 1 }"
      >
        日
      </div>
      <div
        class="square2-category"
        style=""
        @click="clickTime(2)"
        :class="{ activeNH: isActivenTime === 2 }"
      >
        月
      </div>
      <div
        class="square2-category"
        style=""
        @click="clickTime(3)"
        :class="{ activeNH: isActivenTime === 3 }"
      >
        年
      </div>
      <div
        class="square2-category"
        style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
        @click="clickTime(4)"
        :class="{ activeNH: isActivenTime === 4 }"
      >
        区间
      </div>
      <div style="margin-left: 20px; width: 350px" class="date">
        <!-- <el-date-picker
          v-if="!isInterval"
          style="width: 180px"
          v-model="value1"
          :type="changeDateType"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker> -->
        <el-date-picker
          v-if="!isInterval && isActivenTime === 1"
          style="width: 180px"
          v-model="value1"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-date-picker
          v-if="!isInterval && isActivenTime === 2"
          style="width: 180px"
          v-model="value1"
          type="month"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-date-picker
          v-if="!isInterval && isActivenTime === 3"
          style="width: 180px"
          v-model="value1"
          type="year"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <el-date-picker
          v-if="isInterval"
          style="width: 350px"
          v-model="value2"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="timeChange"
        >
        </el-date-picker>
      </div>

      <div
        style="
          width: 72px;
          height: 29px;
          background: #3f6df8;
          border-radius: 2px;
          font-size: 12px;
          color: white;
          text-align: center;
          line-height: 29px;
          margin-right: 20px;
          margin-left: 20px;
          cursor: pointer;
        "
        @click="onSearch"
      >
        查询
      </div>

      <div
        style="
          width: 72px;
          height: 29px;
          background: #3f6df8;
          border-radius: 2px;
          font-size: 12px;
          color: white;
          text-align: center;
          line-height: 29px;
          cursor: pointer;
        "
        @click="reportExport"
      >
        数据导出
      </div>
    </div>

    <div
      style="
        width: 100%;
        text-align: center;
        font-weight: 600;
        color: #082254;
        font-size: 16px;
        line-height: 50px;
        height: 50px;
        margin-top: 10px;
      "
    >
      <!-- 文体中心体育馆-电量统计（kWh） -->
      <!-- 文体中心空调主机能耗统计表 -->
      {{ currentName }}
    </div>
    <div style="width: 100%">
      <!-- <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 30px"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column fixed prop="name" label="监测点位" width="120">
        </el-table-column>
        <el-table-column fixed prop="unit" label="单位" width="120">
        </el-table-column>
      </el-table> -->

      <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 10px"
        :border="isborder1"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column
          fixed
          prop="nodeName"
          label="监测点位"
          width="150"
          align="left"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.nodeName.split('')[0] === ' '"
              style="margin-left: 20px"
            >
              {{ scope.row.nodeName }}
            </div>
            <div v-else>
              {{ scope.row.nodeName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableKey"
          :key="index"
          :label="item"
          align="center"
        >
          <el-table-column
            width="140"
            v-for="(item2, index2) in tableKey2"
            :key="index2"
            :label="item2.name"
            align="center"
          >
            <!-- <template slot-scope="scope"> -->
            <template v-if="item2.child !== null">
              <el-table-column
                width="140"
                v-for="(item3, index3) in item2.child"
                :key="index3"
                :label="item3.name"
                align="center"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row[item3.code][index] === 0"
                    >0</template
                  >
                  <template
                    v-else-if="
                      scope.row[item3.code][index] === undefined ||
                      scope.row[item3.code][index] === null
                    "
                    >--</template
                  >
                  <template v-else>{{ scope.row[item3.code][index] }}</template>
                </template>
              </el-table-column>
            </template>
            <template v-if="item2.child === null" slot-scope="scope">
              <!-- <template > -->
              <!-- {{scope.row}} -->
              <template v-if="scope.row[item2.code][index] === 0">0</template>
              <template
                v-else-if="
                  scope.row[item2.code][index] === undefined ||
                  scope.row[item2.code][index] === null
                "
                >--</template
              >
              <template v-else>{{ scope.row[item2.code][index] }}</template>
              <!-- </template> -->
            </template>
            <!-- </template> -->
          </el-table-column>
        </el-table-column>

        <!-- <el-table-column label="00:00" align="center">
          <el-table-column
            prop="功率(W)"
            label="功率"
            width="120"
            align="center"
          >
            <el-table-column
              prop="max"
              label="最大值"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <template>
                  {{ scope.row["01"].max }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="min"
              label="最小值"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <template>
                  {{ scope.row["01"].min }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="average"
              label="平均值"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <template>
                  {{ scope.row["01"].average }}
                </template>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="能源统计(kWh)"
            prop="ny"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.energyStatistics">
                <template v-if="scope.row.energyStatistics[0]">
                  {{ scope.row.energyStatistics[0] }}
                </template>
                <template v-else> -- </template>
              </template>
              <template v-else> -- </template>
            </template>
          </el-table-column>
          <el-table-column
            label="电量抄表(kWh)"
            prop="cb"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <template>
                {{ scope.row["01"].cb }}
              </template>
            </template>
          </el-table-column>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { reportSearch, getReportList, setProject } from "@/request/apiLg";
export default {
  data() {
    return {
      loading: true,
      headers: {
        Authorization: localStorage.getItem("accessToken"),
      },
      value11: "",
      value22: "",
      currentName: "",
      isInterval: false,
      value2: [],
      tableKey: [],
      tableKey2: [],
      isborder1: false,
      tableData: [],
      changeDateType: "date",
      selectValueX: "",
      isShowSelectx: false,
      selectValueY: "",
      isShowSelecty: false,
      options1: [
        {
          value: "P",
          label: "电能-有功功率",
        },
      ],
      options2: [
        {
          value: "P",
          label: "水能-瞬时流量",
        },
      ],
      value1: "",
      // value2: "",
      isActivenTime: 1,
      query: {
        reportId: "",
        startTime: "2021-05",
        endTime: "",
        unit: "MONTHS",
      },
    };
  },
  mounted() {
    let dateT = new Date();
    var YY = dateT.getFullYear() + "-";
    var MM =
      (dateT.getMonth() + 1 < 10
        ? "0" + (dateT.getMonth() + 1)
        : dateT.getMonth() + 1) + "-";
    var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    this.value1 = YY + MM + DD;

    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getReportList().then((res) => {
            console.log(res);
            if (res.data.data) {
              if (res.data.data.length > 0) {
                this.options1 = res.data.data;
                this.query.reportId = res.data.data[0].reportId;
                this.currentName = res.data.data[0].name;
                // let data = {
                //   reportId: "r1001",
                //   startTime: "2021",
                //   // endTime: "2021-12-01",
                //   unit: "YEARS",
                // };
                // data = {
                //   reportId: "r1001",
                //   startTime: "2021-05",
                //   endTime: "",
                //   unit: "MONTHS",
                // };
                this.query.startTime = this.value1;
                this.query.endTime = "";
                this.query.unit = "DAYS";
                reportSearch(this.query).then((res2) => {
                  this.loading = false;
                  console.log(res2);
                  this.tableData = res2.data.data.rows;
                  // this.tableKey = res2.data.data.timestamps;
                  let temp = [];
                  res2.data.data.timestamps.forEach((item) => {
                    temp.push(this.formatDate(item));
                  });
                  this.tableKey = temp;
                  this.tableKey2 = res2.data.data.columns;
                });
              } else {
                this.loading = false;
              }
            }
          });
        }
      }
    );
  },
  methods: {
    timeChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    clickTime(index) {
      this.isActivenTime = index;
      this.value1 = "";
      this.value2 = [];
      if (index == 1) {
        this.isInterval = false;
        this.changeDateType = "date";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD;
      } else if (index == 2) {
        this.isInterval = false;
        this.changeDateType = "month";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 3) {
        this.isInterval = false;
        this.changeDateType = "year";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 4) {
        this.isInterval = true;
        let dateT = new Date();
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value11 = YY + MM + DD;
        dateT = new Date(this.value11);
        dateT = dateT.setDate(dateT.getDate() + 1);
        this.value22 = this.formatDate(dateT);
        this.value22 = this.value22.split(" ")[0];
        console.log("this.value1", this.value11);
        console.log("this.value2", this.value22);
        this.value2 = [this.value11, this.value22];
        this.query.startTime = this.value11;
        this.query.endTime = this.value22;
      }
    },
    clickX() {},
    clickOptionX() {},
    clickY() {},
    clickOptionY() {},
    onSearch() {
      this.loading = true;
      this.tableData = [];
      this.tableKey = [];
      this.tableKey2 = [];
      if (this.isActivenTime == 1) {
        this.query.startTime = this.value1;
        this.query.endTime = "";
        this.query.unit = "DAYS";
      } else if (this.isActivenTime == 2) {
        this.query.startTime =
          this.value1.split("-")[0] + "-" + this.value1.split("-")[1];
        this.query.endTime = "";
        this.query.unit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        this.query.startTime = this.value1.split("-")[0];
        this.query.endTime = "";
        this.query.unit = "YEARS";
      } else if (this.isActivenTime == 4) {
        // this.query.startTime = this.value1;
        // this.query.endTime = "";
        this.query.unit = null;
      }
      reportSearch(this.query).then((res2) => {
        this.loading = false;
        console.log(res2);
        this.tableData = res2.data.data.rows;
        // this.tableKey = res2.data.data.timestamps;
        this.currentName = res2.data.data.title
        let temp = [];
        res2.data.data.timestamps.forEach((item) => {
          temp.push(this.formatDate(item));
        });
        this.tableKey = temp;
        this.tableKey2 = res2.data.data.columns;
      });
    },
    reportExport() {
      this.onSearch();
      this.loading = true;
      if (this.isActivenTime == 1) {
        this.query.startTime = this.value1;
        this.query.endTime = "";
        this.query.unit = "DAYS";
      } else if (this.isActivenTime == 2) {
        this.query.startTime =
          this.value1.split("-")[0] + "-" + this.value1.split("-")[1];
        this.query.endTime = "";
        this.query.unit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        this.query.startTime = this.value1.split("-")[0];
        this.query.endTime = "";
        this.query.unit = "YEARS";
      } else if (this.isActivenTime == 4) {
        // this.query.startTime = this.value1;
        // this.query.endTime = "";
        this.query.unit = null;
      }

      this.loading = true;
      const url = process.env.VUE_APP_BASE_API + "/report/export";
      let params = this.query;
      axios
        .post(url, params, {
          responseType: "blob",
          headers: this.headers,
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/xlsx",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          console.log(url);
          a.download = this.currentName + ".xlsx";
          a.click();

          setTimeout(() => {
            this.loading = false;
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.downXlsxStatus = false;
          }, 500);
        });
    },
  },
};
</script>

<style scoped>
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>
