<template>
  <div style="width: 100%; height: 100%">
    <iframe
      :src="urlIrame"
      style="height: 100%; width: 100%"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
        urlIrame:"http://johpcyvc.cloudpreview.cloud.techsel.net/#/outofLogin?loginInfo=dXNlcm5hbWU9bGluZ2dhbiZwYXNzd29yZD1saW5nZ2FuMTIz"
    //   url:
    //     "http://johpcyvc.cloudpreview.cloud.techsel.net/#/outofLogin?loginInfo=dXNlcm5hbWU9YWRtaW4mcGFzc3dvcmQ9MTIzNDU2",
    };
  },
};
</script>

<style>
</style>