<template>
  <div class="workshopMonitor2">
    <div class="workshopMonitor-title">
      <div class="title-name">空间示意</div>
    </div>
    <div class="workshopMonitor-content"></div>
    <div
      class="workshopMonitor-shop"
      :style="{
        left: 10 * item + '%',
        bottom: (item % 2 === 0 ? '12' : '23') + '%',
      }"
      v-for="item in 8"
      :key="item"
      @click="goShopEdit(item)"
    >
      <div v-if="item === 2 || item === 3 || item === 6 || item === 8">
        <div class="workshopMonitor-span">{{ item }}#机台</div>
        <img
          class="shop-title"
          src="~@/assets/workshopMonitor/workshopBtn.png"
          alt=""
        />
        <img
          class="shop-item"
          src="~@/assets/workshopMonitor/workshopShop.png"
          alt=""
        />
      </div>
      <div v-else>
        <div class="workshopMonitor-span" style="opacity: 0.5">
          {{ item }}#机台
        </div>
        <img
          class="shop-title"
          src="~@/assets/workshopMonitor/workshopBtn.png"
          alt=""
        />
        <img
          class="shop-item"
          src="~@/assets/workshopMonitor/workshopShop2.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "workshopMonitor2",
  data() {
    return {};
  },
  components: {},
  methods: {
    goShopEdit(item) {
      if (item === 2 || item === 3 || item === 6 || item === 8) {
        this.$router.push({ name: "actualTimeWorkshopMonitor" });
      } else {
        this.$message({
          message: "机台离线中！",
          type: "warning",
        });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.workshopMonitor2 {
  width: 100%;
  height: 100%;
  background: url("~@/assets/workshopMonitor/workshopBg.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;
  .workshopMonitor-title {
    border-bottom: 1px solid #2f446a;
    .title-name {
      width: 125px;
      border-bottom: 2px solid #3c6ff9;
      /* color: #fff; */
      line-height: 35px;
      padding-top: 10px;
      margin-left: 35px;
      text-align: left;
      padding-left: 14px;
      font-weight: 600;
      font-size: 20px;
      background-image: -webkit-linear-gradient(bottom, #3c6ff9, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .workshopMonitor-content {
    width: 100%;
    height: calc(100% - 48px);
  }
  .workshopMonitor-shop {
    position: absolute;
    bottom: 22%;
    left: 10%;
    cursor: pointer;
    img.shop-item {
      width: 160px;
      height: 186px;
      z-index: 5;
    }
    img.shop-title {
      width: 173px;
      height: 64px;
      position: absolute;
      top: -35px;
      left: -6px;
      z-index: 6;
    }
    .workshopMonitor-span {
      width: 173px;
      height: 64px;
      position: absolute;
      top: -35px;
      left: -6px;
      color: #fff;
      font-size: 22px;
      position: absolute;
      line-height: 64px;
      text-align: center;
      letter-spacing: 2px;
      z-index: 9;
    }
  }
}
</style>
