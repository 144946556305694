var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"workshopMonitor"},[_vm._m(0),_c('div',{staticClass:"day-list"},[_vm._m(1),_vm._l((_vm.dayList),function(item,index){return _c('div',{key:index,staticClass:"day-item"},[_c('div',{staticClass:"item-index"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"item-body"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-num"},[_c('div',{staticClass:"item-value",style:({ width: (item.number * 100) / _vm.numSum + '%' })})])])])})],2),_c('div',{staticClass:"census-list"},[_vm._m(2),_c('div',{staticClass:"census-item"},[_c('div',{staticClass:"census-circle"}),_c('div',{staticClass:"census-value"},[_vm._v("总机："+_vm._s(_vm.jitaiList.length)+"台")])]),_vm._l((_vm.censusList),function(item){return _c('div',{key:item.name,staticClass:"census-item"},[_c('div',{staticClass:"census-circle"}),_c('div',{staticClass:"census-value"},[_vm._v(_vm._s(item.name)+"："+_vm._s(item.value)+"台")])])})],2),_c('div',{staticClass:"jitai-list"},_vm._l((_vm.jitaiList),function(item,index){return _c('div',{key:item.name,staticClass:"jitai-item",class:[
        'jitai-item' + (index + 1),
        'jitai-item-status-' + item.status,
      ],on:{"mouseover":function($event){return _vm.changeShow(item, index)},"mouseleave":function($event){_vm.showIndex = -1},"click":function($event){return _vm.goEdit(item)}}},[_c('div',{staticClass:"jitai-num"},[_c('div',{staticClass:"num-title"},[_vm._v(_vm._s(item.name))]),(item.status === 0 && item.number > 0)?_c('div',{staticClass:"num-value"},[_vm._v(" 当日产量:"),_c('span',[_vm._v(_vm._s(item.number.toFixed(2)))]),_vm._v(" t "),_c('br'),_vm._v(" 实时功率:"),_c('span',[_vm._v(_vm._s(35))]),_vm._v(" kw ")]):_vm._e(),_c('div',{staticClass:"num-status",class:'num-status' + item.status},[_c('div',{staticClass:"num-circle"}),_vm._v(" "+_vm._s(item.status === 0 ? "生产" : item.status === 1 ? "离线" : "停产")+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex === index),expression:"showIndex === index"}],staticClass:"router"},[_c('span',[_vm._v("进入详情")]),_c('img',{attrs:{"src":require("@/assets/workshopMonitor/router-icon.png"),"alt":""}})])])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center"},[_c('img',{staticClass:"bg-line",attrs:{"src":require("@/assets/workshopMonitor/circle.webp"),"alt":""}}),_c('div',{staticClass:"title-bg"},[_vm._v("挤压车间")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-name"},[_c('img',{attrs:{"src":require("@/assets/workshopMonitor/day-title.png"),"alt":""}}),_vm._v(" 当日产量排行 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-name"},[_c('img',{attrs:{"src":require("@/assets/workshopMonitor/day-title.png"),"alt":""}}),_vm._v(" 机台统计 ")])
}]

export { render, staticRenderFns }