<template>
  <div class="secondContainer">
    <div class="back"><span @click="goBack">返回上一级</span></div>
    <div class="topBanner">
      <div style="display: flex; align-items: center">
        <img
          src="@/assets/jinke/xuanze.png"
          style="width: 40px; height: 40px"
        />

        <div>选择设备:</div>
        <el-select
          v-model="selectList"
          multiple
          placeholder="请添加"
          :popper-append-to-body="false"
          :collapse-tags="true"
          clearable
          @change="getTrack"
        >
          <el-option
            v-for="item in equipmentList"
            :key="item.key"
            :value="item"
            :label="item.memo"
          ></el-option>
        </el-select>
      </div>
      <div class="tagContainer">
        <el-tag
          v-for="(item, index) in selectList"
          :key="item.key"
          closable
          size="small"
          @close="removeTag(index)"
          >{{ item.memo }}</el-tag
        >
      </div>
    </div>
    <div class="timer">
      <div class="columnar-title">
        <div
          v-for="item in columnarArr"
          :key="item.value"
          class="columnar-title-item"
          :class="{ active: columnarIndex == item.value }"
          @click="changecolumnarIndex(item.value)"
        >
          {{ item.name }}
        </div>
        <span style="font-size: 14px; margin: 0 5px; font-weight: 500"
          >时间选择</span
        >
        <el-date-picker
          v-show="columnarIndex === 'HOURS'"
          class="columnar-searchTime"
          v-model="searchTime"
          type="date"
          placeholder="选择日期"
          size="mini"
          style="width: 130px"
          popper-class="columnar-time"
          align="left"
          :clearable="false"
          :append-to-body="true"
          @change="getTrack"
        >
        </el-date-picker>
        <el-date-picker
          v-show="columnarIndex === 'DAYS'"
          class="columnar-searchTime"
          v-model="searchTime"
          type="month"
          placeholder="选择日期"
          size="mini"
          style="width: 130px"
          :append-to-body="true"
          popper-class="columnar-time"
          align="left"
          :clearable="false"
          @change="getTrack"
        >
        </el-date-picker>
        <el-date-picker
          v-show="columnarIndex === 'MONTHS'"
          class="columnar-searchTime"
          v-model="searchTime"
          type="year"
          placeholder="选择日期"
          size="mini"
          :append-to-body="true"
          style="width: 130px"
          popper-class="columnar-time"
          align="left"
          :clearable="false"
          @change="getTrack"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="content" id="content">
      <div class="chartContainer" id="chartContainer" v-loading="chartLoading">
        <div
          class="nowLegend"
          :style="{ left: nowLeft + 'px' }"
          v-if="columnarIndex === 'HOURS'"
        ></div>
        <div
          class="nowTxt"
          :style="{ left: nowLeft - 25 + 'px' }"
          v-if="columnarIndex === 'HOURS'"
        >
          当前时间
        </div>
        <div
          v-for="item in yAxisList"
          :key="item.key"
          :style="{
            position: 'absolute',
            left: '-86px',
            bottom: item.bottom + heightItem / 2 + 'px',
          }"
        >
          {{ item.memo }}
        </div>
        <div
          v-for="(item, index) in xAxisList"
          :key="item.left - 1"
          :style="{
            position: 'absolute',
            bottom: '-24px',
            left: item.left + 'px',
            minWidth: '60px',
          }"
        >
          {{
            index % keyCount === 0
              ? columnarIndex === "HOURS"
                ? formateTime(item.content)
                : columnarIndex === "DAYS"
                ? formateDate(item.content)
                : formateYear(item.content)
              : ""
          }}
        </div>
        <div v-for="(item, index1) in yAxisList" :key="index1">
          <div
            v-for="(x, i) in item.rsValueList"
            :key="i"
            class="blockItem"
            :style="{
              height: heightItem / 1.4 + 'px',
              position: 'absolute',
              bottom: item.bottom + heightItem / 4 + 'px',
              width: widthItem + 0.1 + 'px',
              left: xAxisList[i].left + 'px',
            }"
          >
            <div
              class="item"
              :style="{
                height: '30px',
                background:
                  x === 1
                    ? currentTime > xAxisList[i].content
                      ? item.freqValueList
                        ? 'rgb(28,53,125)'
                        : 'rgb(63,109,248)'
                      : 'rgb(151,232,192)'
                    : 'none',
                width: '100%',
              }"
            >
              <div
                v-if="item.freqValueList"
                class="fre"
                :style="{
                  background:
                    x === 1
                      ? currentTime > xAxisList[i].content
                        ? 'rgb(63,109,248)'
                        : 'rgb(151,232,192)'
                      : 'none',
                  width: '100%',
                  height: (item.freqValueList[i] / 50) * 30 + 'px',
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="legend">
        <div class="icon gonglv"></div>
        <div>功率状态</div>
        <div class="icon" style="background: #417af3"></div>
        <div>实际运行</div>
        <div class="icon" style="background: #97e8c0"></div>
        <div>计划运行</div>
      </div>
    </div>
  </div>
</template>

<script>
//年 天  日月 小时
import { getKeyMap, getTrack } from "@/request/apiJk";
import dayjs from "dayjs";
export default {
  name: "second",
  data() {
    return {
      equipmentList: [],
      selectList: [],
      yAxisList: [],
      xAxisList: [],
      widthItem: 0,
      heightItem: 0,
      currentTime: new Date().getTime(),
      keyCount: 0,
      resObj: null,
      chartLoading: false,
      nowLeft: 0,
      searchTime: new Date(),
      columnarArr: [
        {
          name: "日",
          value: "HOURS",
        },
        {
          name: "月",
          value: "DAYS",
        },
        {
          name: "年",
          value: "MONTHS",
        },
      ],
      columnarIndex: "HOURS",
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.handleChart();
      })();
    };
    getKeyMap("heat.device").then((res) => {
      if (res.data.code === 0) {
        this.equipmentList = res.data.data;
        this.selectList = [...this.equipmentList];
        this.getTrack();
      }
    });
  },
  methods: {
    goBack() {
      this.$parent.showSecond = false;
    },
    removeTag(index) {
      this.selectList.splice(index, 1);
      this.getTrack();
    },
    getTrack() {
      if (!this.searchTime) {
        this.$message({
          message: "日期不能为空！",
          type: "warning",
        });
        this.searchTime = new Date();
        return;
      }
      if (!this.selectList.length) {
        this.yAxisList = [];
        return;
      }
      let startTime;
      let endTime;
      let timeUnit;
      if (this.columnarIndex === "HOURS") {
        startTime = dayjs(this.searchTime).format("YYYY-MM-DD 00:00:00");
        endTime = dayjs(this.searchTime)
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");
        timeUnit = "DAYS";
      } else if (this.columnarIndex === "DAYS") {
        startTime = dayjs(this.searchTime)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        endTime = dayjs(this.searchTime)
          .endOf("month")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");
        timeUnit = "MONTHS";
      } else if (this.columnarIndex === "MONTHS") {
        startTime = dayjs(this.searchTime)
          .startOf("year")
          .format("YYYY-MM-DD 00:00:00");
        endTime = dayjs(this.searchTime)
          .endOf("year")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00");
        timeUnit = "YEARS";
      }
      this.chartLoading = true;
      getTrack({
        keyList: this.selectList.map((item) => item.key),
        startTime,
        endTime,
        timeUnit,
        interval: 0,
      }).then((res) => {
        this.resObj = res;
        this.handleChart();
        this.chartLoading = false;
      });
    },
    handleChart() {
      let res = this.resObj;
      let yCount = res.data.data.dataList.length;
      let xCount = res.data.data.timestamps.length;
      let chart = document.getElementById("chartContainer");
      let chartHeight = chart.clientHeight;
      let chartWidth = chart.clientWidth;
      let itemHeight = chartHeight / yCount;
      this.heightItem = itemHeight;

      let yAxisList = [];
      for (let i = 0; i < res.data.data.dataList.length; i++) {
        yAxisList.unshift(res.data.data.dataList[i]);
      }
      for (let i = 0; i < yAxisList.length; i++) {
        yAxisList[i].bottom = i * itemHeight;
      }

      let xAxisList = [];
      let itemWidth = chartWidth / xCount;
      this.widthItem = itemWidth;
      let now = new Date().getTime();

      let haveNow = false;
      for (let i = 0; i < res.data.data.timestamps.length; i++) {
        let obj = {
          content: res.data.data.timestamps[i],
        };
        obj.left = i * itemWidth;

        if (obj.content > now && !haveNow) {
          this.nowLeft = i * itemWidth;
          haveNow = true;
        }

        xAxisList.push(obj);
      }
      this.yAxisList = yAxisList;
      this.xAxisList = xAxisList;
      this.keyCount = Math.ceil(
        this.xAxisList.length / (this.columnarIndex === "DAYS" ? 31 : 27)
      );
    },
    formateDate(time) {
      const timeObj = new Date(time);
      return `${this.formateTwoPath(timeObj.getDate())}`;
    },
    formateYear(time) {
      const timeObj = new Date(time);
      return `${this.formateTwoPath(
        timeObj.getMonth() + 1
      )}-${this.formateTwoPath(timeObj.getDate())}`;
    },
    formateTime(time) {
      const timeObj = new Date(time);
      return `${this.formateTwoPath(timeObj.getHours())}:${this.formateTwoPath(
        timeObj.getMinutes()
      )}`;
    },
    formateTwoPath(number) {
      return number < 10 ? `0${number}` : number;
    },
    changecolumnarIndex(index) {
      this.columnarIndex = index;
      this.getTrack();
    },
  },
};
</script>

<style lang="less">
.secondContainer {
  overflow: hidden;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #021b72 0%, #070c1c 100%);
  .back {
    width: 100%;
    text-align: right;
    color: #fff;
    font-size: 14px;
    padding: 10px 20px;
    span {
      cursor: pointer;
    }
  }
  .topBanner {
    padding: 12px 10px;
    width: 98%;
    margin: 0 auto;
    background: linear-gradient(
      90deg,
      rgba(33, 59, 184, 0.76) 0%,
      rgba(5, 59, 144, 0.15) 100%
    );
    border: 1px solid #3b98ff;
    color: #fff;
    font-size: 14px;
    .el-select {
      margin-left: 10px;
      width: 210px;
      height: 32px;
      .el-input__inner {
        color: #fff;
        height: 30px;
        background: #4a77ff;
        box-shadow: inset 1px 1px 20px 0px #0034c5;
        border: 1px solid #3b98ff;
        border-radius: 2px;
        width: 210px;
      }
      .el-input__icon {
        line-height: 30px;
      }
      .el-select-dropdown {
        border: none;
      }
      .el-select-dropdown__empty {
        color: #fff;
      }

      ::-webkit-input-placeholder {
        color: #fff;
      }
    }
    .tagContainer {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      padding-left: 100px;
    }
    .el-tag {
      background: #030f39;
      border-radius: 4px;
      opacity: 0.7;
      color: #fff;
      border: none;
      line-height: 28px;
      height: 28px;
      margin-left: 10px;
      padding: 0px 14px;
      margin-bottom: 4px;
      .el-icon-close {
        color: #fff;
        border: 1px solid #fff;
        font-size: 10px;
      }
      .el-tag__close:hover {
        background: #0034c5;
      }
    }
  }
  .timer {
    height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 98%;
    .columnar-title {
      display: flex;
      color: #fff;
      align-items: center;
      border-radius: 4px;
      .columnar-title-item {
        height: 32px;
        padding: 0 10px;
        line-height: 32px;
        text-align: center;
        border-radius: 0px;
        border: 1px solid #3f6df8;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        &.active {
          background-color: #3f6df8;
        }
      }
    }
    .columnar-searchTime {
      margin-right: 0px;

      .el-input__inner {
        background: transparent;
        color: #fff;
      }
    }
  }
  .content {
    width: 98%;
    flex: 1;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .chartContainer {
      position: absolute;
      width: 86%;
      height: 85%;
      margin-bottom: 2%;
      border-left: 1px solid rgb(52, 90, 209);
      border-bottom: 1px solid rgb(52, 90, 209);
      margin-left: 10px;
      color: #ddd;
      font-size: 14px;
      box-shadow: -10px 0 0px 0px rgb(16, 39, 118);
      .blockItem {
        display: flex;
        align-items: center;
      }
    }
    .nowLegend {
      height: 100%;
      width: 0;
      position: absolute;
      z-index: 20;
      border-left: 1px dotted rgb(63, 109, 248);
    }
    .nowTxt {
      position: absolute;
      top: -26px;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .fre {
      width: 100%;
    }
  }
  .legend {
    position: absolute;
    bottom: 1%;
    width: 100%;
    height: 40px;
    display: flex;
    z-index: 20;
    color: #ddd;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    .icon {
      width: 22px;
      height: 18px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .gonglv {
      background: url("../../../assets/jinke/gonglv.png");
      background-size: 100% 100%;
    }
  }
}
</style>
