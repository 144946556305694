<template>
  <div class="EnergyConfigBuildingList">
    <div v-loading="tableLoadding" class="content">
      <el-table
        stripe
        :header-cell-style="{
          background: 'rgba(65, 122, 243, 0.1)',
          color: '#082254',
        }"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="name" label="报表名称" />

        <el-table-column label="操作" width="160" align="center" fixed="right">
          <template slot-scope="scope">
            <span
              style="cursor: pointer; font-size: 14px; color: #3f6df8"
              @click="goEdit(scope.row)"
            >
              <icon theme="outline" size="14" fill="#406DF8" />
              查看
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="paging">
        <el-pagination
          :current-page="query.page"
          :page-size="query.limit"
          :pager-count="7"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="pageChange"
          @size-change="sizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { reportList } from "@/request/apiJimu";

export default {
  name: "EnergyConfigBuildingList",
  components: {},
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        projectId: "lpsj",
        template: 1,
        tenantId: "linkgap",
      },
      tableData: [],
      tableLoadding: false,
      total: 0,
    };
  },
  computed: {
    ...mapState({
      projectArray: (state) => state.projectArray,
    }),
  },
  created() {
    let projectId = JSON.parse(localStorage.getItem("PROJECTID"));
    if (projectId) {
      this.query.projectId = projectId;
    }
    this.query.tenantId = process.env.VUE_APP_JIMU_USER
    // this.query.tenantId = (this.projectArray[0] || {}).tenantId;
    this.reportList();
  },
  methods: {
    reportList() {
      const query = this.concatParams(this.query);
      reportList(query).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data;
          this.total = res.count;
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
        this.tableLoadding = false;
      });
    },
    concatParams(params) {
      let paramsStr = "";
      for (var k in params) {
        paramsStr += k + "=" + (params[k] + "") + "&";
      }
      paramsStr = paramsStr.substr(0, paramsStr.length - 1);
      return paramsStr;
    },
    pageChange(val) {
      this.query.page = val;
      this.buildingQuery();
    },
    sizeChange(val) {
      this.query.page = val;
      this.buildingQuery();
    },
    goEdit(row) {
      // 计算表格的宽度并且返回
      const jonStr = JSON.parse(row.jonStr);
      this.$router.push({
        name: "energyCostCustomEdit",
        query: {
          id: row.id || "",
          allWidth: jonStr.dataRectWidth,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.EnergyConfigBuildingList {
  width: 100%;
  height: 100%;
  background: white;
  padding: 15px;
  .content {
    .paging {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
