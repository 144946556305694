import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
// import { getToken } from '@/utils/auth'
import router from "@/router";
// create an axios instance
const service = axios.create({
  baseURL: "https://hubenergy.cn:18085", // url = base url + request url
  // baseURL: "http://192.168.2.8:6061", // 后台地址
  timeout: 50000, // request timeout ,
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (config.url.indexOf("export") > -1) {
      config.responseType = "arraybuffer";
    }
    // const token = localStorage.getItem('accessToken')
    if (store.getters.token) {
      config.headers.Authorization = store.getters.token;
    }
    // XXX:这里搬运存在疑问
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    if (config.method === "put") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 请求报错
    // XXX：端口响应code为1是什么情况
    if (res.code === 1) {
      Message.error(res.msg);
    }
    // token过期
    if (res.msg === "访问令牌已过期" || res.code === 403) {
      // 不在登录则清除token回到登录页面
      if (router.history.current.name !== "login") {
        MessageBox.confirm(res.data.msg, {
          confirmButtonText: "Re-Login",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          store.dispatch("user/resetToken").then(() => {
            location.reload();
          });
        });
      }
    }
    return res;
  },
  (error) => {
    const err = error.response;
    if (err) {
      if (err.data.msg) {
        // token过期
        if (err.data.code === 403) {
          // 不在登录则清除token回到登录页面
          MessageBox.confirm(err.data.msg, {
            confirmButtonText: "重新登录",
            cancelButtonText: "关闭",
            type: "warning",
          }).then(() => {
            store.dispatch("user/resetToken").then(() => {
              location.reload();
            });
          });
        } else {
          Message.error(err.data.msg);
        }
      } else {
        Message.error("请求出错！");
      }
    } else {
      Message.error("请求错误，请重试！");
    }
    return Promise.reject(error);
  }
);

export default service;
