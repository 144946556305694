<template>
  <div style="width: 100%; height: 100%; background: white; padding: 15px">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div>
          算法类型:
          <el-select
            v-model="query.type"
            placeholder="请选择"
            style="margin-left: 10px"
            clearable
          >
            <el-option
              v-for="item in options1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-left: 20px">算法名称：</div>
        <div
          style="position: relative; margin-left: 5px; cursor: pointer"
          class="select"
        >
          <el-input
            v-model="query.name"
            placeholder="请输入算法名称"
            style="width: 160px"
          >
          </el-input>
        </div>
        <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
            margin-right: 20px;
            margin-left: 20px;
            cursor: pointer;
          "
          @click="searchFor"
        >
          查询
        </div>
      </div>

      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
            margin-right: 20px;
            cursor: pointer;
          "
          @click="toAdd"
        >
          新增
        </div>
        <!-- <div
          style="
            width: 72px;
            height: 29px;
            background: #3f6df8;
            border-radius: 2px;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 29px;
          "
        >
          数据导出
        </div> -->
      </div>
    </div>
    <div style="width: 100%">
      <!-- <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 30px"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column fixed prop="name" label="监测点位" width="120">
        </el-table-column>
        <el-table-column fixed prop="unit" label="单位" width="120">
        </el-table-column>
      </el-table> -->

      <el-table
        :data="tableData"
        style="width: 100%; overflow: auto; margin-top: 10px"
        :border="isborder1"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column type="index" align="center" width="50" label="序号">
        </el-table-column>
        <!-- <el-table-column prop="userId" width="100" label="用户编码">
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="name"
          label="算法名称"
          width="150"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="algorithmId"
          label="算法ID"
          width="150"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="released"
          label="状态"
          width="120"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.released"> 已发布 </template>
            <template v-else>未发布</template>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="desc"
          label="算法描述"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <template v-if="scope.row.desc">
              {{ scope.row.desc }}
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="type"
          label="算法类型"
          width="200"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.type">
              <template v-if="scope.row.type === 'ENERGY_SAVING'">
                节能优化
              </template>
              <template v-else-if="scope.row.type === 'FORECAST'">
                负荷预测
              </template>
              <template v-else> 故障诊断 </template>
            </template>
            <template v-else>--</template>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="script"
          label="算法脚本文件"
          width="220"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.scriptFileName">
              <span
                @click="showUploadScript(0, scope.row)"
                style="color: #3f6df8; cursor: pointer"
                >{{ scope.row.scriptFileName }}</span
              >
            </template>
            <template v-else
              ><span
                @click="showUploadScript(1, scope.row)"
                style="color: #3f6df8; cursor: pointer"
                ><i class="el-icon-upload2"></i>上传脚本文件</span
              ></template
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="config"
          label="算法配置文件"
          width="220"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.algorithmConfigFileName">
              <span
                @click="showUploadConfig(0, scope.row)"
                style="color: #3f6df8; cursor: pointer"
                >{{ scope.row.algorithmConfigFileName }}</span
              >
            </template>
            <template v-else
              ><span
                @click="showUploadConfig(1, scope.row)"
                style="color: #3f6df8; cursor: pointer"
                ><i class="el-icon-upload2"></i>上传配置文件</span
              ></template
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              v-if="!scope.row.released"
              style="color: #02b84b; margin-right: 5px; cursor: pointer"
              @click="changeStatus(scope.row.algorithmId, 1)"
            >
              发布
            </span>
            <span
              v-if="scope.row.released"
              style="color: #f2bc40; margin-right: 5px; cursor: pointer"
              @click="changeStatus(scope.row.algorithmId, 0)"
            >
              暂停
            </span>
            <!-- <span
              style="color: #02b84b; margin-right: 5px; cursor: pointer"
              @click="toDetail(scope.row.alarmId)"
            >
              详情
            </span> -->
            <span
              style="color: #3f6df8; margin-right: 5px; cursor: pointer"
              @click="showUpdate(scope.row)"
              v-if="!scope.row.released"
            >
              修改
            </span>
            <span
              style="color: #e52323; margin-right: 5px; cursor: pointer"
              @click="deleteReport(scope.row.algorithmId)"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 15px; text-align: right">
        <el-pagination
          @size-change="sizeChange"
          @current-change="pageChange"
          :current-page="query.page"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="query.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      title="上传脚本文件"
      :visible.sync="changeReVisibleScript"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <div
        v-if="nowScriptName"
        style="
          cursor: pointer;
          color: #3f6df8;
          text-align: center;
          margin-bottom: 10px;
        "
        @click="downLoadScript"
      >
        {{ nowScriptName }}
      </div>
      <div v-if="!nowScriptCode.released" style="color: #082254; text-align: center; height: 70px">
        <!-- 是否重置密码？ -->
        <el-upload
          class="upload-demo"
          action=""
          :multiple="ismultiple"
          :on-preview="handlePreview"
          :on-change="fileChangeScript"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-exceed="handleExceed"
          :file-list="fileListScript"
        >
          <el-button size="small" icon="el-icon-upload2">点击上传</el-button>
          <!-- <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div> -->
        </el-upload>
      </div>
      <!-- <div>
        <label
          for="files"
          class="el-button el-button--medium el-button--success"
          plain
        >
          导入
          <input
            type="file"
            style="display: none"
            id="files"
            class="mrl"
            
            @change="handlePreviewS"
          />
        </label>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureReset1">确 定</el-button>
        <el-button @click="changeReVisibleScript = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="上传配置文件"
      :visible.sync="changeReVisibleConfig"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <div
        v-if="nowConfigName"
        style="
          cursor: pointer;
          color: #3f6df8;
          text-align: center;
          margin-bottom: 10px;
        "
        @click="downLoadConfig"
      >
        {{ nowConfigName }}
      </div>
      <div v-if="!nowScriptCode.released" style="color: #082254; text-align: center; height: 70px">
        <!-- 是否重置密码？ -->
        <el-upload
          class="upload-demo"
          action=""
          :multiple="ismultiple"
          :on-preview="handlePreview"
          :on-change="fileChangeConfig"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-exceed="handleExceed"
          :file-list="fileListConfig"
        >
          <el-button size="small" icon="el-icon-upload2">点击上传</el-button>
          <!-- <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div> -->
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureReset2">确 定</el-button>
        <el-button @click="changeReVisibleConfig = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="userSettingTitle"
      :visible.sync="changePasswordVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item
          label="算法编码："
          prop="algorithmId"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input
            :disabled="isView"
            v-model="ruleForm.algorithmId"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="算法名称："
          prop="name"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input :disabled="isView" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item
          label="算法描述："
          prop="desc"
          style="position: relative; margin-bottom: 20px"
        >
          <el-input :disabled="isView" v-model="ruleForm.desc"></el-input>
        </el-form-item>
        <el-form-item
          label="算法类型："
          prop="type"
          style="position: relative; margin-bottom: 20px"
        >
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择"
            @change="getOption"
            :disabled="isView"
          >
            <el-option
              v-for="item in options1s"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="算法脚本文件："
          prop="scriptFile"
          style="position: relative; margin-bottom: 20px"
        >
          <el-upload
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-exceed="handleExceed"
            :file-list="fileListScript"
            :on-change="fileChangeScript"
          >
            <el-button size="small" icon="el-icon-upload2">点击上传</el-button>
            <!-- <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div> -->
          </el-upload>
        </el-form-item>

        <el-form-item
          label="算法配置文件："
          prop="algorithmConfigFile"
          style="position: relative; margin-bottom: 20px"
        >
          <el-upload
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-exceed="handleExceed"
            :file-list="fileListConfig"
            :on-change="fileChangeConfig"
          >
            <el-button size="small" icon="el-icon-upload2">点击上传</el-button>
            <!-- <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div> -->
          </el-upload>
        </el-form-item>

        <el-form-item
          label="状态："
          prop="released"
          style="position: relative; margin-bottom: 10px"
        >
          <el-switch v-model="ruleForm.released"> </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addNew">确 定</el-button>
        <el-button @click="changePasswordVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import formList from "./list";
import {
  // resetPassword,
  // getUserDetail,
  uploadAlg,
  downloadFile,
  getAlgList,
  // addUser,
  // userSearch,
  deleteAlgS,
  // alarmSetUpdate,
  editAlgStore,
  // alarmSetCheck,
  // alarmSearchSet,
  alarmSetGet,
  // alarmSetLevel,
  bindInstanceTree,
  getInstanceTree,
  // alarmSetDel,
  // getGroupList,
  // getRoleList,
  // reportManageSearch,
  // getReportType,
  // getReportTypeParams,
  // reportManageAdd,
  sendAlgS,
  // reportManagedeatil,
  // reportManageupdate,
  // reportManageDelete,
  // getInstance,
  getInstanceAttr,
} from "@/request/apiLg";
export default {
  // components: {
  //   formList,
  // },
  filters: {
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
  },
  data() {
    // var validConfirmPass = (rule, value, callback) => {
    //   // debugger
    //   console.log("this.ruleForm", this.ruleForm);
    //   if (
    //     value !== "" &&
    //     this.ruleForm.newPassword !== "" &&
    //     value != this.ruleForm.newPassword
    //   ) {
    //     callback(new Error("密码与上面的新密码不符"));
    //   }
    //   callback();
    // };
    return {
      options1: [
        { name: "全部", code: "ALL" },
        { name: "节能优化", code: "ENERGY_SAVING" },
        { name: "负荷预测", code: "FORECAST" },
        { name: "故障诊断", code: "DIAGNOSIS" },
      ],
      options1s: [
        { name: "节能优化", code: "ENERGY_SAVING" },
        { name: "负荷预测", code: "FORECAST" },
        { name: "故障诊断", code: "DIAGNOSIS" },
      ],
      // fileList: [],
      fileListScript: [],
      fileListConfig: [],
      userSettingTitle: "新增算法",
      tempUserId: "",
      optionsSSS: [
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      optionsSS: [],
      optionsS: [
        {
          value: "ALL",
          label: "全部",
        },
        {
          value: "OVERRUN",
          label: "数据异常",
        },
        {
          value: "TIMEOUT",
          label: "通信异常",
        },
        {
          value: "DIAGNOSIS",
          label: "故障诊断",
        },
      ],
      isEdit: false,
      isView: false,
      testList: [
        {
          nodeName: "",
          show: true,
          childNodeList: [],
        },
      ],
      listTemp: [
        {
          name: 1,
          children: [
            {
              name: 2,
              children: [
                {
                  name: 3,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      changeTreeVisible: false,
      // changeReVisible: false,
      changeReVisibleScript: false,
      changeReVisibleConfig: false,
      optionsInstance: [
        {
          name: "节能优化",
          code: "jnyh",
        },
        {
          name: "负荷预测",
          code: "fhyc",
        },
        {
          name: "故障诊断",
          code: "gzzd",
        },
      ],
      optionsInstanceAttr: [],
      optionsSourceType: [
        {
          name: "展示节点",
          code: true,
        },
        {
          name: "具体仪表",
          code: false,
        },
      ],
      dateV: 1,
      selectTimeV21: 1,
      selectTimeV22: 0,
      isShowMonth: false,
      selectTimeV: 0,
      timeArray: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      timeArray2: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      treeReportId: "",
      isShowDay: false,
      // options1: [],
      options2: [[]],
      rules: {
        // userId: [
        //   { required: true, message: "请输入登录账号", trigger: "blur" },
        // ],
        // username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        // dataPrivilegeGroupIdList: [
        //   { required: true, message: "请选择项目", trigger: "change" },
        // ],
        // roleIdList: [
        //   { required: true, message: "请选择用户角色", trigger: "change" },
        // ],
        // newPassword: [
        //   { required: true, message: "请输入密码", trigger: "blur" },
        //   { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        // ],
        // confirmPassword: [
        //   { required: true, message: "请再次输入密码", trigger: "blur" },
        //   { validator: validConfirmPass, trigger: "blur" },
        //   { min: 3, message: "密码长度最少为3位", trigger: "blur" },
        // ],
        // expireDate: [
        //   { required: true, message: "请选择到期时间", trigger: "change" },
        // ],
      },
      ruleForm: {
        // alarmId: "",
        // userId: "",
        // username: "",
        // dataPrivilegeGroupIdList: [],
        // roleIdList: [],
        // newPassword: "",
        // confirmPassword: "",
        // expireDate: "",
        // maxDisplay: 0,
        // minDisplay: 0,
        // // periodMinute: "",
        // released: false,
        scriptFile: null,
        scriptFileName: "",
        algorithmId: "",
        name: "",
        released: false,
        type: "ENERGY_SAVING",
        algorithmConfigFile: null,
        algorithmConfigFileName: "",
        desc: "",
      },
      treeForm: {
        treeName: "",
        treeArray: [1],
      },
      changePasswordVisible: false,
      total: 0,
      loading: true,
      value11: "",
      value22: "",
      currentName: "",
      isInterval: false,
      value2: [],
      tableKey: [],
      tableKey2: [],
      isborder1: false,
      tableData: [
        {
          name: "节能算法1",
          id: "AC-POS-01",
          status: "暂停",
          desc: "利用pos算法优化能效",
          type: "节能优化",
          script: "",
          config: "",
        },
        {
          name: "负荷算法1",
          id: "AC-POS-02",
          status: "启用",
          desc: "移动平均算法、技术",
          type: "负荷预测",
          script: "yuce.py",
          config: "yuce.py",
        },
        {
          name: "故障算法",
          id: "AC-POS-03",
          status: "启用",
          desc: "移动平均算法、技术",
          type: "故障诊断",
          script: "yuce.py",
          config: "yuce.py",
        },
        {
          name: "故障算法",
          id: "AC-POS-04",
          status: "启用",
          desc: "移动平均算法、技术",
          type: "故障诊断",
          script: "yuce.py",
          config: "yuce.py",
        },
      ],
      changeDateType: "date",
      selectValueX: "",
      isShowSelectx: false,
      selectValueY: "",
      isShowSelecty: false,
      // options1: [
      //   {
      //     value: "P",
      //     label: "电能-有功功率",
      //   },
      // ],
      // options2: [
      //   {
      //     value: "P",
      //     label: "水能-瞬时流量",
      //   },
      // ],
      value1: "",
      // value2: "",
      isActivenTime: 1,
      query: {
        limit: 10,
        page: 1,
        type: "ALL",
        name: "",
      },
      nowScriptName: "",
      nowScriptFile: null,
      nowScriptCode: "",
      nowConfigName: "",
      nowConfigFile: null,
      nowConfigCode: "",
      ismultiple: false,
    };
  },
  mounted() {
    // let dateT = new Date();
    // var YY = dateT.getFullYear() + "-";
    // var MM =
    //   (dateT.getMonth() + 1 < 10
    //     ? "0" + (dateT.getMonth() + 1)
    //     : dateT.getMonth() + 1) + "-";
    // var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
    // this.value1 = YY + MM + DD;
    // this.loading = false;
    getAlgList(this.query).then((res2) => {
      this.loading = false;
      console.log(res2);
      this.tableData = res2.data.data;
      // this.tableData = [
      //   {
      //     algorithmConfigFile: null,
      //     algorithmConfigFileName: "zgds.png",
      //     algorithmId: "AC-POS-01",
      //     desc: "利用pos算法优化能效",
      //     id: 1,
      //     name: "节能优化1",
      //     released: true,
      //     scriptFile: null,
      //     scriptFileName: "金科酒店点位信息表(分项计量)0818.xlsx",
      //     type: "ENERGY_SAVING",
      //   },
      // ];
      this.total = res2.data.count;
      // this.tableKey = res2.data.data.timestamps;
    });
    // // getReportType().then((res) => {
    // //   this.options1 = res.data.data;
    // //   getReportTypeParams(this.options1[0].code).then((res2) => {
    // //     this.options2[0] = res2.data.data;
    // //   });
    // // });
    // getRoleList().then((res) => {
    //   console.log(res.data.data);
    //   this.optionsSS = res.data.data;
    //   // this.mutationsGetInstance(res.data.data);
    // });
    // getGroupList().then((res) => {
    //   this.optionsInstance = res.data.data;
    //   // getInstanceAttr(this.optionsInstance[0].instanceId).then((res1) => {
    //   //   this.optionsInstanceAttr = res1.data.data;
    //   // });
    // });
  },
  computed: {
    ...mapState({
      insatnceArr: (state) => state.insatnceArr,
    }),
  },
  methods: {
    handlePreviewS(file) {
      let formData = new FormData();
      console.log("file", file.target.files[0]);
      formData.append("file", file.target.files[0]);
      console.log("formData", formData);
    },
    fileChangeScript(file) {
      console.log("file", file);
      this.fileListScript = [];
      this.nowScriptFile = file.raw;
      this.fileListScript[0] = file;
      // this.fileListScript = []
      // let reader = new FileReader();
      // reader.readAsDataURL(file.raw);
      // if (file.percentage === 100) {
      //   this.fileListScript = [];
      //   this.nowScriptFile = file.raw;
      //   this.fileListScript[0] = file;
      //   // reader.onload = () => {
      //   //   console.log(reader);
      //   //   let formData = new FormData();
      //   //   formData.append("file", file.raw);
      //   //   this.nowScriptFile = file.raw;
      //   //   // console.log("formData", formData);
      //   //   // this.nowScriptFile = reader.result;
      //   // };
      // }
    },
    downLoadConfig() {
      downloadFile(this.nowConfigName).then((res) => {
        let link = document.createElement("a");
        link.href = res.data.msg;
        // link.setAttribute("download", "11.png");
        link.click();
      });
    },
    fileChangeConfig(file) {
      console.log("file", file);
      this.fileListConfig = [];
      this.nowConfigFile = file.raw;
      this.fileListConfig[0] = file;
    },
    downLoadScript() {
      downloadFile(this.nowScriptName).then((res) => {
        let link = document.createElement("a");
        link.href = res.data.msg;
        // link.setAttribute("download", "11.png");
        link.click();
      });
    },
    showUploadScript(index, item) {
      this.changeReVisibleScript = true;
      this.nowScriptName = item.scriptFileName;
      // if (index === 0) {
      //   this.fileList = [
      //     {
      //       name: "yuce.py",
      //       url: "",
      //     },
      //   ];
      // } else {
      //   this.fileList = [];
      // }
      this.nowScriptFile = null;
      this.nowScriptCode = item;
      this.fileListScript = [];
    },
    showUploadConfig(index, item) {
      console.log(item);
      this.changeReVisibleConfig = true;
      this.nowConfigName = item.algorithmConfigFileName;
      // if (index === 0) {
      //   this.fileList = [
      //     {
      //       name: "yuce.py",
      //       url: "",
      //     },
      //   ];
      // } else {
      //   this.fileList = [];
      // }
      this.nowConfigFile = null;
      this.nowConfigCode = item;
      this.fileListConfig = [];
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      console.log(file, fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    resetPassword(id) {
      this.tempUserId = id;
      this.changeReVisible = true;
    },
    sureReset1() {
      // resetPassword(this.tempUserId).then((res) => {
      //   if (res.data.code === 0) {
      //     this.$message.success("重置密码成功，新密码为123456");
      //     this.changeReVisible = false;
      //   }
      // });
      let formData = new FormData();
      formData.append("scriptFile", this.nowScriptFile);
      // formData.append("id", this.nowScriptCode.id);
      // formData.append("name", this.nowScriptCode.name);
      // formData.append("released", this.nowScriptCode.released);
      formData.append("scriptFileName", this.nowScriptFile.name);
      // formData.append("type", this.nowScriptCode.type);
      // formData.append(
      //   "algorithmConfigFile",
      //   this.nowScriptCode.algorithmConfigFile
      // );
      // formData.append(
      //   "algorithmConfigFileName",
      //   this.nowScriptCode.algorithmConfigFileName
      // );
      formData.append("algorithmId", this.nowScriptCode.algorithmId);
      // formData.append("desc", this.nowScriptCode.desc);
      // this.changeReVisibleScript = false;
      uploadAlg(formData).then((res) => {
        if (res.data.code === 0) {
          this.changeReVisibleScript = false;
          this.searchFor();
        }
      });
    },
    sureReset2() {
      // resetPassword(this.tempUserId).then((res) => {
      //   if (res.data.code === 0) {
      //     this.$message.success("重置密码成功，新密码为123456");
      //     this.changeReVisible = false;
      //   }
      // });

      let formData = new FormData();
      formData.append("algorithmConfigFile", this.nowConfigFile);

      formData.append("algorithmConfigFileName", this.nowConfigFile.name);
      formData.append("algorithmId", this.nowScriptCode.algorithmId);
      // formData.append("desc", this.nowScriptCode.desc);
      // this.changeReVisibleScript = false;
      uploadAlg(formData).then((res) => {
        if (res.data.code === 0) {
          this.changeReVisibleConfig = false;
          this.searchFor();
        }
      });
    },
    ...mapMutations(["mutationsGetInstance"]),
    toDetail(id) {
      // alarmSetGet(id).then((resA) => {
      //   console.log(resA)
      // })
      // this.$router.push({
      //   name: "alarmsetDetail",
      //   query: { id: id },
      // });

      alarmSetGet(id).then((resA) => {
        if (resA.data.code === 0) {
          // this.$message.success("修改状态成功");
          getInstanceAttr(resA.data.data.instanceId).then((res2) => {
            // debugger
            // this.options2[v] = res2.data.data;
            // console.log("this.options2", this.options2);
            // let temp = JSON.parse(JSON.stringify(this.options2));
            // this.options2 = temp;
            this.optionsInstanceAttr = res2.data.data;
            this.changePasswordVisible = true;
            this.isEdit = false;
            this.isView = true;
            this.ruleForm = resA.data.data;
            this.ruleForm.periodTime =
              (this.ruleForm.periodTime / 60).toString() + "分钟";
          });
        }
      });
    },
    showUpdate(row) {
      this.userSettingTitle = "编辑算法";
      this.changePasswordVisible = true;
      this.isEdit = true;
      this.isView = false;
      let temp = JSON.parse(JSON.stringify(row));
      // if (temp.status === "启用") {
      //   temp.status = true;
      // } else {
      //   temp.status = false;
      // }
      // if (temp.name === "节能算法1") {
      //   this.fileListScript = [];
      //   this.fileListConfig = [];
      // } else {
      //   this.fileListScript = [{ name: "yuce.py", url: "" }];
      //   this.fileListConfig = [{ name: "yuce.py", url: "" }];
      // }
      this.ruleForm = temp;

      this.nowScriptFile = null;
      // this.nowScriptCode = item;
      this.fileListScript = [];
      this.fileListScript.push({ name: temp.scriptFileName });
      this.nowConfigFile = null;
      // this.nowConfigCode = item;
      this.fileListConfig = [];
      this.fileListConfig.push({ name: temp.algorithmConfigFileName });
      // getUserDetail(id).then((resA) => {
      //   if (resA.data.code === 0) {

      //     this.userSettingTitle = "编辑算法";
      //     this.changePasswordVisible = true;
      //     this.isEdit = true;
      //     this.isView = false;
      //     this.ruleForm = resA.data.data;
      //   }
      // });
    },
    changeStatus(id, index) {
      console.log(id);
      if (index === 0) {
        this.$confirm("确认暂停？")
          .then(() => {
            // done();
            sendAlgS(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      } else if (index === 1) {
        this.$confirm("确认发布？")
          .then(() => {
            // done();
            sendAlgS(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                this.searchFor();
              }
            });
          })
          .catch(() => {});
      }
    },
    deleteReport(id) {
      console.log(id);
      this.$confirm("确认删除？")
        .then(() => {
          // done();
          deleteAlgS(id).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.searchFor();
            }
          });
        })
        .catch(() => {});
    },
    toBind(id) {
      console.log(id);
      this.treeReportId = id;
      getInstanceTree(id).then((res) => {
        console.log(res);
        if (res.data.data) {
          if (res.data.data.treeNodeList.length > 0) {
            this.testList = res.data.data.treeNodeList;
          }
          this.treeForm.treeName = res.data.data.treeName;
        }

        this.changeTreeVisible = true;
      });
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      console.log(command);
      if (command.command === "a") {
        this.testList[command.index].childNodeList.push({
          childNodeList: [],
          nodeName: "",
          show: true,
        });
      } else if (command.command === "b") {
        this.testList.splice(command.index, 1);
      }
    },
    addTreeNode() {
      this.testList.push({
        childNodeList: [],
        nodeName: "",
        show: true,
      });
    },
    deleteTree(index) {
      this.testList.splice(index, 1);
    },
    selectTimeOption(item, e) {
      e.stopPropagation();
      this.selectTimeV = item;
      this.isShowDay = false;
    },
    selectTimeOption2(item, e) {
      e.stopPropagation();
      this.selectTimeV21 = item;
      this.isShowMonth = false;
    },
    showDaySelect() {
      this.isShowDay = !this.isShowDay;
    },
    showMonthSelect() {
      this.isShowMonth = !this.isShowMonth;
    },
    addTypes() {
      this.ruleForm.reportTypesArray.push({
        params1: "",
        params2: [],
      });
      this.options2.push([]);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    deleteTypes(index) {
      console.log(index);
      this.ruleForm.reportTypesArray.splice(index, 1);
      this.options2.splice(index, 1);
      let temp = JSON.parse(JSON.stringify(this.options2));
      this.options2 = temp;
    },
    getOption(e) {
      console.log(e);
      // getInstanceAttr(e).then((res2) => {
      //   // debugger
      //   // this.options2[v] = res2.data.data;
      //   // console.log("this.options2", this.options2);
      //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //   // this.options2 = temp;
      //   this.optionsInstanceAttr = res2.data.data;
      //   this.ruleForm.maxDisplay = "";
      //   this.ruleForm.minDisplay = "";
      //   this.ruleForm.attrId = "";
      // });
    },
    getOption2(e) {
      console.log(e);
      // this.optionsInstanceAttr.forEach((item) => {
      //   if (item.attrId === e) {
      //     if (item.maxDisplay !== null) {
      //       this.ruleForm.maxDisplay = item.maxDisplay;
      //     }
      //     if (item.minDisplay !== null) {
      //       this.ruleForm.minDisplay = item.minDisplay;
      //     }
      //   }
      // });
      // getInstanceAttr(e).then((res2) => {
      //   // debugger
      //   // this.options2[v] = res2.data.data;
      //   // console.log("this.options2", this.options2);
      //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //   // this.options2 = temp;
      //   this.optionsInstanceAttr = res2.data.data;
      // });
    },
    toAdd() {
      this.changePasswordVisible = true;
      this.isEdit = false;
      this.isView = false;
      this.userSettingTitle = "新增算法";
      this.nowScriptFile = null;
      // this.nowScriptCode = item;
      this.fileListScript = [];

      this.nowConfigFile = null;
      // this.nowConfigCode = item;
      this.fileListConfig = [];
      this.ruleForm = {
        // // alarmId: "",
        // userId: "",
        // username: "",
        // dataPrivilegeGroupIdList: [],
        // roleIdList: [],
        // newPassword: "",
        // confirmPassword: "",
        // expireDate: "",
        // // maxDisplay: 0,
        // // minDisplay: 0,
        // // // periodMinute: "",
        // // released: false,
        scriptFile: null,
        scriptFileName: "",
        algorithmId: "",
        name: "",
        released: false,
        type: "ENERGY_SAVING",
        algorithmConfigFile: null,
        algorithmConfigFileName: "",
        desc: "",
      };
    },
    sizeChange(val) {
      this.query.page = 1;
      this.query.limit = val;
      this.searchFor();
    },
    pageChange(val) {
      this.query.page = val;
      this.searchFor();
    },
    searchFor() {
      // this.$router.push({ name: "alarmDetail" });
      // console.log("this.value1", this.value1);
      this.loading = true;
      getAlgList(this.query).then((res) => {
        console.log(res);
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.count;
      });
    },
    reportTypeV(v) {
      let temp = [];
      v.forEach((item) => {
        temp.push(item);
      });
      temp = temp.join(",");
      return temp;
    },
    reportTypeV2(v) {
      let temp = [];
      v.forEach((item) => {
        if (item.reportParas) {
          item.reportParas.forEach((item2) => {
            temp.push(item2.reportTypeName);
          });
        }
      });
      temp = temp.join(",");
      return temp;
    },
    timeChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },
    formatDate(date) {
      var date1 = new Date(date);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    clickTime(index) {
      this.isActivenTime = index;
      this.value1 = "";
      this.value2 = [];
      if (index == 1) {
        this.isInterval = false;
        this.changeDateType = "date";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value1 = YY + MM + DD;
      } else if (index == 2) {
        this.isInterval = false;
        this.changeDateType = "month";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 3) {
        this.isInterval = false;
        this.changeDateType = "year";
        let dateT = new Date();
        let YY = dateT.getFullYear() + "-";
        let MM = "01-";
        let DD = "01";
        this.value1 = YY + MM + DD;
      } else if (index == 4) {
        this.isInterval = true;
        let dateT = new Date();
        var YY = dateT.getFullYear() + "-";
        var MM =
          (dateT.getMonth() + 1 < 10
            ? "0" + (dateT.getMonth() + 1)
            : dateT.getMonth() + 1) + "-";
        var DD = dateT.getDate() < 10 ? "0" + dateT.getDate() : dateT.getDate();
        this.value11 = YY + MM + DD;
        dateT = new Date(this.value11);
        dateT = dateT.setDate(dateT.getDate() + 1);
        this.value22 = this.formatDate(dateT);
        this.value22 = this.value22.split(" ")[0];
        console.log("this.value1", this.value11);
        console.log("this.value2", this.value22);
        this.value2 = [this.value11, this.value22];
        this.query.startTime = this.value11;
        this.query.endTime = this.value22;
      }
    },
    clickX() {},
    clickOptionX() {},
    clickY() {},
    clickOptionY() {},
    onSearch() {
      this.loading = true;
      this.tableData = [];
      this.tableKey = [];
      this.tableKey2 = [];
      if (this.isActivenTime == 1) {
        this.query.startTime = this.value1;
        this.query.endTime = "";
        this.query.unit = "DAYS";
      } else if (this.isActivenTime == 2) {
        this.query.startTime =
          this.value1.split("-")[0] + "-" + this.value1.split("-")[1];
        this.query.endTime = "";
        this.query.unit = "MONTHS";
      } else if (this.isActivenTime == 3) {
        this.query.startTime = this.value1.split("-")[0];
        this.query.endTime = "";
        this.query.unit = "YEARS";
      } else if (this.isActivenTime == 4) {
        // this.query.startTime = this.value1;
        // this.query.endTime = "";
        this.query.unit = null;
      }
      // reportSearch(this.query).then((res2) => {
      //   this.loading = false;
      //   console.log(res2);
      //   this.tableData = res2.data.data.rows;
      //   // this.tableKey = res2.data.data.timestamps;
      //   let temp = [];
      //   res2.data.data.timestamps.forEach((item) => {
      //     temp.push(this.formatDate(item));
      //   });
      //   this.tableKey = temp;
      //   this.tableKey2 = res2.data.data.columns;
      // });
    },
    bindTree() {
      let temp = {
        reportId: this.treeReportId,
        treeName: this.treeForm.treeName,
        treeNodeList: this.testList,
      };
      bindInstanceTree(temp).then((res) => {
        if (res.data.code === 0) {
          this.changeTreeVisible = false;
          this.$message.success("绑定成功");
          this.searchFor();
        }
        // console.log(res);
      });
    },
    addNew() {
      // let temp = {
      //   alarmId: "",
      //   alarmLevelId: "",
      //   alarmLevelName: "",
      //   alarmType: "",
      //   attrId: "",
      //   attrName: "",
      //   instanceId: "",
      //   instanceName: "",
      //   maxDisplay: 0,
      //   minDisplay: 0,
      //   periodMinute: "",
      //   released: true,
      // };
      if (this.isView) {
        this.changePasswordVisible = false;
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            // alarmSetCheck(this.ruleForm).then((resC) => {
            // if (!resC.data.data.exist) {
            if (!this.isEdit) {
              // this.ruleForm.pwd = this.ruleForm.newPassword;
              let formData = new FormData();
              if (this.ruleForm.released) {
                formData.append("released", 1);
              } else {
                formData.append("released", 0);
              }
              if (this.nowScriptFile) {
                formData.append("scriptFileName", this.nowScriptFile.name);
                formData.append("scriptFile", this.nowScriptFile);
              }
              if (this.nowConfigFile) {
                formData.append("algorithmConfigFile", this.nowConfigFile);
                formData.append(
                  "algorithmConfigFileName",
                  this.nowConfigFile.name
                );
              }
              formData.append("name", this.ruleForm.name);
              formData.append("released", this.ruleForm.released);
              formData.append("type", this.ruleForm.type);

              formData.append("algorithmId", this.ruleForm.algorithmId);
              formData.append("desc", this.ruleForm.desc);
              editAlgStore(formData).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.$message.success("新增成功");
                  this.changePasswordVisible = false;
                  this.searchFor();
                }
              });
            } else {
              // if (this.ruleForm.pwd) {
              //   delete this.ruleForm.pwd;
              // }
              // let dateT = new Date();
              // this.ruleForm.updateTime = this.formatDate(dateT);
              let formData = new FormData();
              if (this.ruleForm.released) {
                formData.append("released", 1);
              } else {
                formData.append("released", 0);
              }
              if (this.nowScriptFile) {
                formData.append("scriptFileName", this.nowScriptFile.name);
                formData.append("scriptFile", this.nowScriptFile);
              }
              if (this.nowConfigFile) {
                formData.append("algorithmConfigFile", this.nowConfigFile);
                formData.append(
                  "algorithmConfigFileName",
                  this.nowConfigFile.name
                );
              }
              formData.append("name", this.ruleForm.name);
              formData.append("released", this.ruleForm.released);
              formData.append("type", this.ruleForm.type);
              formData.append("id", this.ruleForm.id);
              formData.append("algorithmId", this.ruleForm.algorithmId);
              formData.append("desc", this.ruleForm.desc);
              editAlgStore(formData).then((res) => {
                console.log("res", res);
                if (res.data.code === 0) {
                  this.$message.success("编辑成功");
                  this.changePasswordVisible = false;
                  this.searchFor();
                }
              });
            }
            // } else {
            //   // this.$message.error("该告警类型已配置，请编辑");
            //   this.$message({
            //     showClose: false,
            //     message: "该告警类型已配置，请编辑",
            //     type: "error",
            //     duration: 7000,
            //   });
            //   alarmSetGet(resC.data.data.alarmId).then((res) => {
            //     console.log(res);
            //     if (res.data.code === 0) {
            //       // this.$message.success("修改状态成功");
            //       getInstanceAttr(res.data.data.instanceId).then((res2) => {
            //         // debugger
            //         // this.options2[v] = res2.data.data;
            //         // console.log("this.options2", this.options2);
            //         // let temp = JSON.parse(JSON.stringify(this.options2));
            //         // this.options2 = temp;
            //         this.optionsInstanceAttr = res2.data.data;
            //         this.changePasswordVisible = true;
            //         this.isEdit = true;
            //         this.isView = false;
            //         this.ruleForm = res.data.data;
            //       });
            //     }
            //   });
            // }
            // });
            // this.ruleForm.beginDay = this.selectTimeV21;
            // this.ruleForm.beginHour = this.selectTimeV;
            // let temp = [];
            // this.ruleForm.reportTypesArray.forEach((item) => {
            //   if (item.params1) {
            //     if (item.params2.length > 0) {
            //       item.params2.forEach((item3) => {
            //         temp.push(item3);
            //       });
            //     } else {
            //       temp.push(item.params1);
            //     }
            //   }
            // });
            // this.ruleForm.reportTypes = temp;
            // if (!this.isEdit) {
            //   reportManageAdd(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("新增成功");
            //       this.searchFor();
            //     }
            //   });
            // } else {
            //   reportManageupdate(this.ruleForm).then((res) => {
            //     console.log("res", res);
            //     if (res.data.code === 0) {
            //       this.$message.success("编辑成功");
            //       this.searchFor();
            //     }
            //   });
            // }
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.el-dialog__wrapper >>> input {
  width: 350px !important;
  height: 32px !important;
}

.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
.time-square:hover {
  background: #3f6df8 !important;
  color: white !important;
}
.isSelectTime {
  background: #3f6df8 !important;
  color: white !important;
}
.small-s >>> input {
  width: 220px !important;
  height: 32px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 750px !important;
}
.inline-input >>> input {
  width: 160px !important;
}
.small-input >>> input {
  width: 160px !important;
}
.small-input >>> .el-input {
  width: 160px !important;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>