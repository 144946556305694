<template>
  <div
    class="overall-class"
    :style="{ backgroundImage: 'url(' + coverImgUrl + ')' }"
  >
    <div style="width: 100%; height: 183px; background: rgba(8, 34, 84, 0.7)">
      <div style="height: 100%; width: 16.6%; float: left; position: relative">
        <div
          class="up-border"
          style="position: absolute; top: 20px; right: 0"
        ></div>
        <!-- <div class="down-border" style=""></div> -->
        <div
          style="
            font-weight: bold;
            color: #54f9be;
            font-size: 28px;
            text-align: center;
            margin-top: 50px;
          "
        >
          <!-- 6340.3 -->
          {{ elecUse }}
        </div>
        <div
          style="
            color: white;
            font-size: 14px;
            text-align: center;
            margin-top: 5px;
            height: 27px;
            line-height: 27px;
          "
        >
          <img src="@/assets/电灯泡.png" style="" /><span
            style="vertical-align: top; margin-left: 5px"
            >当日用电量（kWh）</span
          >
        </div>
      </div>
      <div style="height: 100%; width: 16.6%; float: left; position: relative">
        <div
          class="up-border"
          style="position: absolute; top: 20px; right: 0"
        ></div>

        <div
          style="
            font-weight: bold;
            color: #54f9be;
            font-size: 28px;
            text-align: center;
            margin-top: 50px;
          "
        >
          <!-- 440.3 -->
          {{ waterUse }}
        </div>
        <div
          style="
            color: white;
            font-size: 14px;
            text-align: center;
            margin-top: 5px;
            height: 27px;
            line-height: 27px;
          "
        >
          <img src="@/assets/水.png" style="" /><span
            style="vertical-align: top; margin-left: 5px"
            >当日用水量（m3）</span
          >
        </div>
      </div>
      <div style="height: 100%; width: 16.6%; float: left; position: relative">
        <div
          class="up-border"
          style="position: absolute; top: 20px; right: 0"
        ></div>

        <div
          style="
            font-weight: bold;
            color: #54f9be;
            font-size: 28px;
            text-align: center;
            margin-top: 50px;
          "
        >
          <!-- 119.3 -->
          <!-- {{ airUse }} -->
          321
        </div>
        <div
          style="
            color: white;
            font-size: 14px;
            text-align: center;
            margin-top: 5px;
            height: 27px;
            line-height: 27px;
          "
        >
          <img src="@/assets/火.png" style="" /><span
            style="vertical-align: top; margin-left: 5px"
            >当日用气量（m3）</span
          >
        </div>
      </div>
      <div style="height: 100%; width: 16.6%; float: left; position: relative">
        <div
          class="up-border"
          style="position: absolute; top: 20px; right: 0"
        ></div>

        <div
          style="
            font-weight: bold;
            color: #54f9be;
            font-size: 28px;
            text-align: center;
            margin-top: 50px;
          "
        >
          <!-- 431.2 -->
          {{ coldUse }}
        </div>
        <div
          style="
            color: white;
            font-size: 14px;
            text-align: center;
            margin-top: 5px;
            height: 27px;
            line-height: 27px;
          "
        >
          <img src="@/assets/雪.png" style="vertical-align: -4px" /><span
            style="vertical-align: top; margin-left: 5px"
            >当日制冷量（kWh）</span
          >
        </div>
      </div>
      <div style="height: 100%; width: 16.6%; float: left; position: relative">
        <div
          class="up-border"
          style="position: absolute; top: 20px; right: 0"
        ></div>

        <div
          style="
            font-weight: bold;
            color: #54f9be;
            font-size: 28px;
            text-align: center;
            margin-top: 50px;
          "
        >
          <!-- 65.3 -->
          <!-- {{ heatUse }} -->
          2673
        </div>
        <div
          style="
            color: white;
            font-size: 14px;
            text-align: center;
            margin-top: 5px;
            height: 27px;
            line-height: 27px;
          "
        >
          <img src="@/assets/太阳.png" style="vertical-align: -4px" /><span
            style="vertical-align: top; margin-left: 5px"
            >当日制热量（kWh）</span
          >
        </div>
      </div>
      <div style="height: 100%; width: 16.6%; float: left; position: relative">
        <!-- <div class="up-border" style="position:absolute;top:20px;right:0"></div> -->

        <div
          style="
            font-weight: bold;
            color: #54f9be;
            font-size: 28px;
            text-align: center;
            margin-top: 50px;
          "
        >
          <!-- 5.9 -->
          <!-- {{ copV }} -->
          5.4
        </div>
        <div
          style="
            color: white;
            font-size: 14px;
            text-align: center;
            margin-top: 5px;
            height: 27px;
            line-height: 27px;
          "
        >
          <img src="@/assets/能效管控.png" style="vertical-align: -4px" /><span
            style="vertical-align: top; margin-left: 5px"
            >
            <!-- 机房能效Cop -->
            冷机COP
            </span
          >
        </div>
      </div>
      <div style="clear: both"></div>
    </div>

    <!-- <div
      style="
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      "
      @click="toIframe"
    >
      <img src="@/assets/定位.png" />
      <div
        @click="toIframe"
        style="
          width: 106px;
          height: 40px;
          border: 1px solid #3f6df8;
          background: rgba(3, 21, 64, 0.7);
          line-height: 40px;
          color: white;
          font-size: 16px;
          text-align: center;
          position: absolute;
          top: 5px;
          left: -110px;
          cursor: pointer;
        "
      >
        实时监测
      </div>
    </div> -->

    <div
      style="
        position: absolute;
        top: 205px;
        right: 555px;
        z-index: 9;
        cursor: pointer;
      "
      @click="collapseRight"
      v-if="isCollapse"
    >
      <img src="@/assets/down2.png" />
    </div>
    <!-- class="rotate-img" -->
    <div
      style="
        position: absolute;
        top: 195px;
        right: 550px;
        width: 112px;
        height: 113px;
      "
      class="rotate-img"
      v-if="isCollapse"
    >
      <img src="@/assets/allspin.png" />
    </div>

    <div
      style="
        position: absolute;
        bottom: 16px;
        right: 18px;
        z-index: 9;
        cursor: pointer;
      "
      @click="isCollapse = true"
      v-if="!isCollapse"
    >
      <img src="@/assets/up2.png" />
    </div>

    <div
      style="
        position: absolute;
        bottom: 22px;
        right: 23px;
        width: 112px;
        height: 113px;
      "
      class="rotate-img"
      v-if="!isCollapse"
    >
      <img src="@/assets/allspin.png" />
    </div>

    <div
      v-if="isCollapse"
      style="
        width: 526px;
        height: 600px;
        background: rgba(8, 34, 84, 0.7);
        position: absolute;
        right: 0;
        top: 200px;
        padding: 15px;
      "
    >
      <div style="width: 100%">
        <div
          style="
            width: 4px;
            height: 20px;
            background: #bcccff;
            display: inline-block;
            vertical-align: -4px;
            margin-right: 5px;
          "
        ></div>
        <div style="display: inline-block; color: #bcccff">当月能源成本</div>
      </div>
      <div style="width: 100%">
        <div style="width: 33.33%; float: left">
          <div
            style="
              color: #54f9be;
              font-size: 28px;
              text-align: center;
              margin-top: 10px;
            "
          >
            <!-- {{totalCost}} -->
            19.49
          </div>
          <div
            style="
              color: white;
              font-size: 14px;
              text-align: center;
              margin-top: 5px;
              height: 27px;
              line-height: 27px;
            "
          >
            <!-- <img src="@/assets/水.png" style="vertical-align: -4px" /> -->
            <span style="color: #bcccff">总计用能成本(万元)</span>
          </div>
        </div>
        <div style="width: 33.33%; float: left">
          <div
            style="
              color: #54f9be;
              font-size: 28px;
              text-align: center;
              margin-top: 10px;
            "
          >
            -13%
          </div>
          <div
            style="
              color: white;
              font-size: 14px;
              text-align: center;
              margin-top: 5px;
              height: 27px;
              line-height: 27px;
            "
          >
            <img src="@/assets/指数下降.png" style="vertical-align: -4px" />
            <span style="color: #bcccff; margin-left: 5px">同比</span>
          </div>
        </div>
        <div style="width: 33.33%; float: left">
          <div
            style="
              color: #ed9c00;
              font-size: 28px;
              text-align: center;
              margin-top: 10px;
            "
          >
            +0.9%
          </div>
          <div
            style="
              color: white;
              font-size: 14px;
              text-align: center;
              margin-top: 5px;
              height: 27px;
              line-height: 27px;
            "
          >
            <img src="@/assets/指数上升.png" style="vertical-align: -4px" />
            <span style="color: #bcccff; margin-left: 5px">环比</span>
          </div>
        </div>
        <div style="clear: both"></div>

        <div style="width: 100%; height: 180px">
          <v-chart ref="chart1" :option="chartBarOption1" />
        </div>
      </div>

      <div style="width: 100%; margin-top: 20px">
        <div
          style="
            width: 4px;
            height: 20px;
            background: #bcccff;
            display: inline-block;
            vertical-align: -4px;
            margin-right: 5px;
          "
        ></div>
        <div style="display: inline-block; color: #bcccff">当日节能减排量</div>
      </div>
      <div style="width: 100%">
        <div style="width: 50%; float: left">
          <div
            style="
              color: #54f9be;
              font-size: 28px;
              text-align: center;
              margin-top: 10px;
            "
          >
            {{SaveEnergyToday}}
          </div>
          <div
            style="
              color: white;
              font-size: 14px;
              text-align: center;
              margin-top: 5px;
              height: 27px;
              line-height: 27px;
            "
          >
            <!-- <img src="@/assets/水.png" style="vertical-align: -4px" /> -->
            <span style="color: #bcccff">今日节能量(万元)</span>
          </div>
        </div>
        <div style="width: 50%; float: left">
          <div
            style="
              color: #54f9be;
              font-size: 28px;
              text-align: center;
              margin-top: 10px;
            "
          >
            {{SaveEnergyYears}}
          </div>
          <div
            style="
              color: white;
              font-size: 14px;
              text-align: center;
              margin-top: 5px;
              height: 27px;
              line-height: 27px;
            "
          >
            <!-- <img src="@/assets/水.png" style="vertical-align: -4px" /> -->
            <span style="color: #bcccff">年累计节能量(万元)</span>
          </div>
        </div>
        <div style="clear: both"></div>

        <div style="width: 100%; height: 180px">
          <v-chart ref="chart2" :option="chartBarOption2" />
        </div>
      </div>
    </div>
    <!-- <div
      v-if="isCollapse"
      style="
        width: 526px;
        height: 300px;
        background: rgba(8, 34, 84, 0.7);
        position: absolute;
        right: 0;
        bottom: 20px;
        padding: 15px;
      "
    ></div> -->
  </div>
</template>

<script>
import {
  getDayElecUse,
  getDayColdUse,
  getDayAirUse,
  getDayHeatUse,
  getDayWaterUse,
  getOverallCOP,
  setProject,
  getBackImage,
} from "@/request/apiLg";
import { mapState } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      totalCost:'',//总用能成本
      SaveEnergyToday:'',//今日节能量
      SaveEnergyYears:'',//年累计节能量
      coverImgUrl: "",
      copV: "-- --",
      elecUse: "-- --",
      coldUse: "-- --",
      airUse: "-- --",
      heatUse: "-- --",
      waterUse: "-- --",
      isCollapse: true,
      chartBarOption1: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
        },
        // legend: {
        //   data: ["2011年", "2012年"],
        // },
        grid: {
          //   left: "3%",
          //   right: "4%",
          //   bottom: "3%",
          top: "5",
          left: "5",
          right: "10",
          bottom: "5",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.5],
          splitLine: {
            show: false,
          },
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#BCCCFF",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#BCCCFF"],
              width: 1,
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "category",
          data: ["燃气成本", "用水费用", "用电费用"],
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#BCCCFF"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#BCCCFF",
            },
          },
        },
        series: [
          {
            // name: "2011年",
            type: "bar",
            data: [9, 15, "30.4"],
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 8,
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "#55ffbc" },
                  { offset: 0.62, color: "#47A5E1" },
                  { offset: 1, color: "#3F6DF8" },
                ]),
                borderRadius: 8,
              },
            },
          },
        ],
      },
      chartBarOption2: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
        },
        // legend: {
        //   data: ["2011年", "2012年"],
        // },
        grid: {
          //   left: "3%",
          //   right: "4%",
          //   bottom: "3%",
          top: "5",
          left: "5",
          right: "10",
          bottom: "5",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.5],
          splitLine: {
            show: false,
          },
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#BCCCFF",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#BCCCFF"],
              width: 1,
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "category",
          data: [ "减少树木砍伐(课)","节约标煤(T)","CO2减排量(T)"],
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#BCCCFF"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#BCCCFF",
            },
          },
        },
        series: [
          {
            // name: "2011年",
            type: "bar",
            data: [1.01, 0.3, 0.92],
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 8,
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "#55ffbc" },
                  { offset: 0.62, color: "#47A5E1" },
                  { offset: 1, color: "#3F6DF8" },
                ]),
                borderRadius: 8,
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    collapseRight() {
      this.isCollapse = false;
    },
    toIframe() {
      this.$router.push({ name: "actual" });
    },
  },
  mounted() {
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          if(this.currentProjectId == 'jkjd'){
            this.totalCost = '4.6'//总用能成本
            this.SaveEnergyToday = '0.2'//今日节能量
            this.SaveEnergyYears = '48.2'//年累计节能量
            this.chartBarOption1.series[0].data = [2.7,1.1,15.7]
            this.chartBarOption2.series[0].data = [485,0.6,1.6]
          }else if(this.currentProjectId == 'zgds'){
            this.totalCost = '4.4'//总用能成本
            this.SaveEnergyToday = '0.3'//今日节能量
            this.SaveEnergyYears = '53.2'//年累计节能量
            this.chartBarOption1.series[0].data = [2.3,0.8,1.3]
            this.chartBarOption2.series[0].data = [0.18,0.04,0.08]
          }else if(this.currentProjectId == 'mgm'){
            this.totalCost = '4.7'//总用能成本
            this.SaveEnergyToday = '0.5'//今日节能量
            this.SaveEnergyYears = '42.6'//年累计节能量
            this.chartBarOption1.series[0].data = [2.6,1.1,1]
            this.chartBarOption2.series[0].data = [0.28,0.1,0.12]
          }else if(this.currentProjectId == 'wtzx'){
            this.totalCost = '5.1'//总用能成本
            this.SaveEnergyToday = '0.4'//今日节能量
            this.SaveEnergyYears = '46.6'//年累计节能量
            this.chartBarOption1.series[0].data = [2.5,1.5,1.1]
            this.chartBarOption2.series[0].data = [0.18,0.16,0.06]
          }
          getBackImage().then((res) => {
            this.coverImgUrl = res.data.msg;
          });
          getOverallCOP().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.copV = "-- --";
              } else {
                this.copV = res.data.data.formatValue;
              }
            } else {
              this.copV = "-- --";
            }
          });
          getDayElecUse().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.elecUse = "-- --";
              } else {
                this.elecUse = res.data.data.formatValue;
              }
            } else {
              this.elecUse = "-- --";
            }
          });
          getDayColdUse().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.coldUse = "-- --";
              } else {
                this.coldUse = res.data.data.formatValue;
              }
            } else {
              this.coldUse = "-- --";
            }
          });
          getDayAirUse().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.airUse = "-- --";
              } else {
                this.airUse = res.data.data.formatValue;
              }
            } else {
              this.airUse = "-- --";
            }
          });
          getDayHeatUse().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.heatUse = "-- --";
              } else {
                this.heatUse = res.data.data.formatValue;
              }
            } else {
              this.heatUse = "-- --";
            }
          });
          getDayWaterUse().then((res) => {
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.waterUse = "-- --";
              } else {
                this.waterUse = res.data.data.formatValue;
              }
            } else {
              this.waterUse = "-- --";
            }
          });
        }
      }
    );
  },
  watch: {

    currentProjectId(val) {
      if(val == 'jkjd'){
        this.totalCost = '4.6'//总用能成本
        this.SaveEnergyToday = '0.2'//今日节能量
        this.SaveEnergyYears = '48.2'//年累计节能量
        this.chartBarOption1.series[0].data = [2.3,1.2,1.1]
        this.chartBarOption2.series[0].data = [0.12,0.02,0.06]
      }else if(val == 'zgds'){
        this.totalCost = '4.4'//总用能成本
        this.SaveEnergyToday = '0.3'//今日节能量
        this.SaveEnergyYears = '53.2'//年累计节能量
        this.chartBarOption1.series[0].data = [2.3,0.8,1.3]
        this.chartBarOption2.series[0].data = [0.18,0.04,0.08]
      }else if(val == 'mgm'){
        this.totalCost = '4.7'//总用能成本
        this.SaveEnergyToday = '0.5'//今日节能量
        this.SaveEnergyYears = '42.6'//年累计节能量
        this.chartBarOption1.series[0].data = [2.6,1.1,1]
        this.chartBarOption2.series[0].data = [0.28,0.1,0.12]
      }else if(val == 'wtzx'){
        this.totalCost = '5.1'//总用能成本
        this.SaveEnergyToday = '0.4'//今日节能量
        this.SaveEnergyYears = '46.6'//年累计节能量
        this.chartBarOption1.series[0].data = [2.5,1.5,1.1]
        this.chartBarOption2.series[0].data = [0.18,0.16,0.06]
      }
      // debugger
      console.log(val);
      // if (val === 5) {
      //   this.changeZoom = "auto";
      // } else {
      //   this.changeZoom = 16;
      // }
      this.copV = "-- --";
      this.elecUse = "-- --";
      this.coldUse = "-- --";
      this.airUse = "-- --";
      this.heatUse = "-- --";
      this.waterUse = "-- --";
      setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
        (res2) => {
          if (res2.data.code === 0) {
            getBackImage().then((res) => {
              this.coverImgUrl = res.data.msg;
            });
            getOverallCOP().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.copV = "-- --";
                } else {
                  this.copV = res.data.data.formatValue;
                }
              } else {
                this.copV = "-- --";
              }
            });
            getDayElecUse().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.elecUse = "-- --";
                } else {
                  this.elecUse = res.data.data.formatValue;
                }
              } else {
                this.elecUse = "-- --";
              }
            });
            getDayColdUse().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.coldUse = "-- --";
                } else {
                  this.coldUse = res.data.data.formatValue;
                }
              } else {
                this.coldUse = "-- --";
              }
            });
            getDayAirUse().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.airUse = "-- --";
                } else {
                  this.airUse = res.data.data.formatValue;
                }
              } else {
                this.airUse = "-- --";
              }
            });
            getDayHeatUse().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.heatUse = "-- --";
                } else {
                  this.heatUse = res.data.data.formatValue;
                }
              } else {
                this.heatUse = "-- --";
              }
            });
            getDayWaterUse().then((res) => {
              if (res.data) {
                if (res.data.data.formatValue === null) {
                  this.waterUse = "-- --";
                } else {
                  this.waterUse = res.data.data.formatValue;
                }
              } else {
                this.waterUse = "-- --";
              }
            });
          }
        }
      );
    },
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
      currentProjectName: (state) => state.currentProjectName,
      projectArray: (state) => state.projectArray,
    }),
  },
};
</script>

<style scoped>
@keyframes changeright {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.rotate-img {
  animation: changeright 6s linear infinite;
}

.overall-class {
  height: 100%;
  width: 100%;
  /* background: url("~@/assets/WechatIMG46.png"); */
  background-size: 100% 100%;
  position: relative;
}
.up-border {
  width: 2px;
  height: 150px;
  background: linear-gradient(
    180deg,
    rgba(223, 232, 255, 0) 0%,
    #bcccff 50%,
    rgba(223, 232, 255, 0) 100%
  );
}
/* .down-border {
  width: 2px;
  height: 75px;
  background: linear-gradient(180deg, #bcccff 0%, rgba(223, 232, 255, 0) 100%);
} */
</style>