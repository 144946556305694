<template>
  <div style="width: 100%; height: 100%; background: white">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="水能" name="first">水能</el-tab-pane>
      <el-tab-pane label="电能" name="second">电能</el-tab-pane>
      <el-tab-pane label="气能" name="third">气能</el-tab-pane>
      <el-tab-pane label="热能" name="fourth">热能</el-tab-pane>
    </el-tabs> -->
    <div
      style="
        width: 100%;
        height: 50px;
        border-bottom: 1px solid rgba(8, 34, 84, 0.1);
        padding-left: 50px;
      "
    >
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor:pointer;
        "
        @click="clickCatagory = 1"
        :class="{isClick:clickCatagory===1}"
      >
        水能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor:pointer;
        "
        @click="clickCatagory = 2"
        :class="{isClick:clickCatagory===2}"
      >
        电能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor:pointer;
        "
        @click="clickCatagory = 3"
        :class="{isClick:clickCatagory===3}"
      >
        气能
      </div>
      <div
        style="
          float: left;
          padding: 15px 15px 10px 15px;
          text-align: center;
          color: #082254;
          font-size: 16px;
          margin-right: 10px;
          cursor:pointer;
        "
        @click="clickCatagory = 4"
        :class="{isClick:clickCatagory===4}"
      >
        热能
      </div>
    </div>
    <!-- <div style="width: 100%; height: 10px; filter: blur(10px);background: rgba(8, 34, 84, 0.1);"></div> -->
    <div
      style="
        width: 100%;
        height: calc(100% - 50px);
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom:20px;
      "
    >
      <div
        style="
          width: 100%;
          height: 100px;
          line-height: 100px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #082254;
        "
      >
        <!-- 电能源拓扑图 -->
        {{tuopuName}}
      </div>
      <div class="tuopu"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "second",
      clickCatagory:1,
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  computed:{
      tuopuName(){
          if(this.clickCatagory === 1){
              return "水能源拓扑图"
          }
          else if(this.clickCatagory === 2){
              return "电能源拓扑图"
          }
          else if(this.clickCatagory === 3){
              return "气能源拓扑图"
          }
          else{
              return "热能源拓扑图"
          }
      }
  }
};
</script>

<style scoped>
.tuopu {
  width: 100%;
  height: calc(100% - 100px);
  background: url("~@/assets/电拓扑.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position:center;
  position: relative;
}
.isClick {
    color: #3e6cf7 !important;
    border-bottom: 4px solid #3e6cf7;
}

</style>