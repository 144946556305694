<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 70px;
        height: 30px;
        border: 1px solid #3f6df8;
        line-height: 30px;
        font-size: 14px;
        color: #3f6df8;
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 15;
        text-align: center;
        opacity: 0.8;
        cursor: pointer;
      "
      @click="closeScreenFull"
    >
      退出全屏
    </div>
    <!-- <div
      style="
        width: 100%;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: #bcccff;
      "
    >
      菱感能源智慧平台
    </div>
    <div style="width: 100%; text-align: center; margin-top: -10px">
      <img src="~@/assets/titleLine.png" alt />
    </div> -->
    <div
      style="
        width: 100%;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: #bcccff;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <img
        src="~@/assets/logo-1.png"
        style="width: 103px; height: 39px; margin-right: 10px"
        alt
      />
      智慧能源聚联平台
    </div>
    <div style="width: 100%; text-align: center; margin-top: -50px">
      <img src="~@/assets/表头轮廓.png" alt />
    </div>
    <div style="width: 100%; margin-top: 30px; padding-left: 460px">
      <!-- <el-select v-model="valueP" placeholder="请选择" class="select-class">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
      <div
        @click="isShowSelectMap = !isShowSelectMap"
        style="
          width: 143px;
          height: 38px;
          border-radius: 2px;
          border: 1px solid #3f6df8;
          background: rgba(4, 23, 76, 0.9);
          position: relative;
          cursor: pointer;
        "
      >
        <div
          style="
            position: absolute;
            height: 38px;
            line-height: 38px;
            top: 0;
            left: 20px;
            font-size: 16px;
            color: #bcccff;
          "
        >
          {{ mapOption }}
        </div>
        <div
          style="
            position: absolute;
            height: 38px;
            line-height: 38px;
            top: 0;
            right: 10px;
            font-size: 16px;
            color: #406df8;
          "
        >
          <i
            class="el-icon-arrow-down"
            style="font-size: 16px; color: #406df8"
          ></i>
        </div>

        <div
          v-if="isShowSelectMap"
          style="
            position: absolute;
            z-index: 9;
            width: 143px;

            top: 37px;
            left: -1px;
            padding-top: 10px;
            padding-bottom: 10px;
            background: rgba(4, 23, 76, 0.7);
            border: 1px solid #3f6df8;
            border-top: none;
          "
        >
          <div
            style=""
            class="sel-option"
            :class="{ activeOption1: mapName === item }"
            v-for="(item, index) in dataOptionMap"
            :key="index"
            @click="clickOptionMap(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; display: flex; justify-content: center">
      <div style="position: relative">
        <img
          src="~@/assets/中国地图.png"
          alt
          @click="clearAll"
          style="width: 750px; height: 433px"
        />
        <div v-if="isShowPoint">
          <div
            style="
              position: absolute;
              top: 212px;
              right: 120px;
              z-index: 10;
              cursor: pointer;
            "
            @mouseover="over1point"
            @click="click1point"
          >
            <img v-if="isClick1" src="~@/assets/定位点.png" alt />
            <img v-else src="~@/assets/定位点no.png" alt />
          </div>
          <div
            style="position: absolute; top: 132px; right: 126px; z-index: 9"
            v-if="isClick1"
          >
            <img src="~@/assets/光柱.png" alt />
            <div
              style="
                width: 178px;
                height: 48px;
                background: #031540;
                opacity: 0.9;
                border: 1px solid #3f6df8;
                text-align: center;
                line-height: 48px;
                font-size: 16px;
                color: white;
                position: absolute;
                top: 30px;
                left: -180px;
              "
            >
              清华紫光大厦
            </div>
          </div>

          <div
            style="
              position: absolute;
              top: 206px;
              right: 132px;
              z-index: 10;
              cursor: pointer;
            "
            @mouseover="over2point"
            @click="click2point"
          >
            <img v-if="isClick2" src="~@/assets/定位点.png" alt />
            <img v-else src="~@/assets/定位点no.png" alt />
          </div>
          <div
            style="position: absolute; top: 126px; right: 139px; z-index: 9"
            v-if="isClick2"
          >
            <img src="~@/assets/光柱.png" alt />
            <div
              style="
                width: 178px;
                height: 48px;
                background: #031540;
                opacity: 0.9;
                border: 1px solid #3f6df8;
                text-align: center;
                line-height: 48px;
                font-size: 16px;
                color: white;
                position: absolute;
                top: 30px;
                left: -180px;
              "
            >
              天成时代商务广场
            </div>
          </div>
          <div
            style="
              position: absolute;
              top: 190px;
              right: 126px;
              z-index: 10;
              cursor: pointer;
            "
            @mouseover="over3point"
            @click="click3point"
          >
            <img v-if="isClick3" src="~@/assets/定位点.png" alt />
            <img v-else src="~@/assets/定位点no.png" alt />
          </div>
          <div
            style="position: absolute; top: 110px; right: 132px; z-index: 9"
            v-if="isClick3"
          >
            <img src="~@/assets/光柱.png" alt />
            <div
              style="
                width: 178px;
                height: 48px;
                background: #031540;
                opacity: 0.9;
                border: 1px solid #3f6df8;
                text-align: center;
                line-height: 48px;
                font-size: 16px;
                color: white;
                position: absolute;
                top: 30px;
                left: -180px;
              "
            >
              金科酒店
            </div>
          </div>
        </div>

        <!-- <div
          style="
            position: absolute;
            top: 125px;
            right: 185px;
            z-index: 10;
            cursor: pointer;
          "
          @mouseover="over3point"
          @click="click3point"
        >
          <img v-if="isClick3" src="~@/assets/定位点.png" alt />
          <img v-else src="~@/assets/定位点no.png" alt />
        </div>
        <div
          style="position: absolute; top: 43px; right: 191px; z-index: 9"
          v-if="isClick3"
        >
          <img src="~@/assets/光柱.png" alt />
          <div
            style="
              width: 178px;
              height: 48px;
              background: #031540;
              opacity: 0.9;
              border: 1px solid #3f6df8;
              text-align: center;
              line-height: 48px;
              font-size: 16px;
              color: white;
              position: absolute;
              top: 30px;
              left: -180px;
            "
          >
            苏州科技城文体中心
          </div> -->
        <!-- </div> -->
      </div>
    </div>

    <div style="width: 100%; text-align: center; margin-top: 20px">
      <img src="~@/assets/下部轮廓.png" alt />
    </div>

    <div
      style="
        width: 100%;
        height: 140px;
        margin-top: -40px;
        display: flex;
        justify-content: center;
      "
    >
      <div style="width: 500px; height: 100%; position: relative">
        <div style="width: 113px; height: 100%; float: left">
          <div style="width: 113px; height: 113px">
            <img src="~@/assets/圆环1.png" />
          </div>
          <div
            style="
              text-align: center;
              width: 100%;
              color: #8ca8fe;
              font-size: 15px;
              margin-top: 10px;
            "
          >
            日用电量(kWh)
          </div>
        </div>
        <div style="width: 113px; height: 100%; float: right">
          <div style="width: 113px; height: 113px">
            <img src="~@/assets/圆环1.png" />
          </div>
          <div
            style="
              text-align: center;
              width: 100%;
              color: #8ca8fe;
              font-size: 15px;
              margin-top: 10px;
            "
          >
            日用气量(m³)
          </div>
        </div>
        <div style="clear: both"></div>
        <div
          style="
            width: 113px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 190px;
          "
        >
          <div style="width: 113px; height: 113px">
            <img src="~@/assets/圆环1.png" />
          </div>
          <div
            style="
              text-align: center;
              width: 100%;
              color: #8ca8fe;
              font-size: 15px;
              margin-top: 10px;
            "
          >
            日用水量(T)
          </div>
        </div>
        <div
          style="
            position: absolute;
            top: 42px;
            left: 24px;
            color: #ed9c00;
            font-size: 20px;
            font-weight: bold;
            width: 68px;
            height: 26px;
            line-height: 26px;
            text-align: center;
          "
        >
          {{ allElecP }}
        </div>
        <div
          style="
            position: absolute;
            top: 42px;
            right: 22px;
            color: #53efc3;
            font-size: 20px;
            font-weight: bold;
            width: 68px;
            height: 26px;
            line-height: 26px;
            text-align: center;
          "
        >
          {{ allElecProvide }}
        </div>
        <div
          style="
            position: absolute;
            top: 42px;
            right: 211px;
            color: #cde3ff;
            font-size: 20px;
            font-weight: bold;
            width: 68px;
            height: 26px;
            line-height: 26px;
            text-align: center;
          "
        >
          {{ allElecAdd }}
        </div>
      </div>
    </div>

    <div
      v-if="showModal"
      style="
        width: 671px;
        height: 375px;
        background: #031540;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
        border: 1px solid #3f6df8;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        padding: 15px;
      "
    >
      <div
        style="cursor: pointer; position: absolute; top: 5px; right: 10px"
        @click="clearAll"
      >
        <img src="@/assets/xicon.png" />
      </div>
      <div style="width: 100%; height: 100%; display: flex">
        <div style="width: 174px; height: 100%; flex: none">
          <div style="font-size: 14px; color: #bcccff; width: 100%">
            类型：商业用户
          </div>
          <div
            style="
              width: 100%;
              height: 232px;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
              margin-top: 10px;
            "
          >
            <img
              :src="detailData.src"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
          <div
            style="
              width: 166px;
              height: 32px;
              background: #3f6df8;
              border-radius: 4px;
              margin-top: 30px;
              color: white;
              text-align: center;
              line-height: 32px;
              position: relative;
              cursor: pointer;
            "
            @click="toProjectOverall(detailData)"
          >
            进入项目
            <div
              style="
                position: absolute;
                bottom: 1px;
                right: 10px;
                font-size: 20px;
              "
            >
              >
            </div>
          </div>
        </div>
        <div style="flex: 1; padding-left: 5px">
          <div style="width: 100%; text-align: center; color: #ffffff">
            {{ detailData.title }}(面积:{{ detailData.area }}m2)
          </div>
          <div style="margin-left: 5px; margin-top: 5px">
            <div
              style="
                width: 4px;
                height: 20px;
                background: #bcccff;
                display: inline-block;
                vertical-align: -4px;
              "
            ></div>
            <div
              style="display: inline-block; color: #bcccff; margin-left: 7px"
            >
              能耗监测与预测 (kWh)
            </div>
          </div>
          <div
            v-if="isModalChartShow"
            style="color: #bcccff; text-align: right; padding-right: 10px"
          >
            (℃)
          </div>
          <div
            v-if="isModalChartShow"
            style="width: 100%; height: 200px; margin-top: 10px"
          >
            <v-chart ref="chart1" :option="chartModalOption" />
          </div>

          <div
            v-if="!isModalChartShow"
            style="
              width: 100%;
              height: 200px;
              margin-top: 10px;
              text-align: center;
              line-height: 200px;
              color: rgb(188, 204, 255);
            "
          >
            暂无数据
          </div>
          <div
            style="
              font-size: 14px;
              font-weight: 500;
              color: #fff;
              height: 60px;
              margin-top: 8px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            项目简介：{{ detailData.desc }}
          </div>
        </div>
      </div>
    </div>

    <div class="square-1" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>
      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        年用能总览
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>
      <div style="display: flex; align-items: center; margin-top: 15px">
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          用电：
        </div>
        <div class="square-number" style="">{{ elecuse[0] }}</div>
        <div class="square-number" style="">{{ elecuse[1] }}</div>
        <div class="square-number" style="">{{ elecuse[2] }}</div>
        <div class="square-number" style="">{{ elecuse[3] }}</div>
        <div class="square-number" style="">
          {{ elecuse[4] }}
        </div>
        <div class="square-number" style="margin-right: 5px">
          {{ elecuse[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>
        <div class="square-number" style="margin-right: 20px">
          {{ elecuse[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          万kWh
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 15px">
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          用水：
        </div>
        <div class="square-number" style="">{{ wateruse[0] }}</div>
        <div class="square-number" style="">{{ wateruse[1] }}</div>
        <div class="square-number" style="">{{ wateruse[2] }}</div>
        <div class="square-number" style="">{{ wateruse[3] }}</div>
        <div class="square-number" style="">
          {{ wateruse[4] }}
        </div>
        <div class="square-number" style="margin-right: 5px">
          {{ wateruse[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>

        <div class="square-number" style="margin-right: 20px">
          {{ wateruse[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          m3
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 15px">
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          用气：
        </div>
        <div class="square-number" style="">{{ airuse[0] }}</div>
        <div class="square-number" style="">{{ airuse[1] }}</div>
        <div class="square-number" style="">{{ airuse[2] }}</div>
        <div class="square-number" style="">{{ airuse[3] }}</div>
        <div class="square-number" style="">
          {{ airuse[4] }}
        </div>
        <div class="square-number" style="margin-right: 5px">
          {{ airuse[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>

        <div class="square-number" style="margin-right: 20px">
          {{ airuse[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          m3
        </div>
      </div>

      <!-- <div style="display: flex; align-items: center; margin-top: 15px">
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-left: -14px;
          "
        >
          用蒸汽：
        </div>
        <div class="square-number" style="">{{ heatuse[0] }}</div>
        <div class="square-number" style="">{{ heatuse[1] }}</div>
        <div class="square-number" style="">{{ heatuse[2] }}</div>
        <div class="square-number" style="">{{ heatuse[3] }}</div>
        <div class="square-number" style="">
          {{ heatuse[4] }}
        </div>
        <div class="square-number" style="margin-right: 5px">
          {{ heatuse[5] }}
        </div>
        <div
          style="
            display: inline-block;
            color: #ffffff;
            font-size: 14px;
            margin-right: 5px;
          "
        >
          .
        </div>

        <div class="square-number" style="margin-right: 20px">
          {{ heatuse[6] }}
        </div>
        <div style="display: inline-block; color: #ffffff; font-size: 14px">
          kWh
        </div>
      </div> -->
    </div>
    <div class="square-2" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>
      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        用能明细
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>

      <div style="width: 100%; margin-top: 10px">
        <div style="float: right">
          <div
            class="square2-category"
            style="border-radius: 2px 0 0 2px"
            @click="clickNH(1)"
            :class="{ activeNH: isActivenNH === 1 }"
          >
            电
          </div>
          <div
            class="square2-category"
            style=""
            @click="clickNH(2)"
            :class="{ activeNH: isActivenNH === 2 }"
          >
            水
          </div>
          <div
            class="square2-category"
            style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
            @click="clickNH(3)"
            :class="{ activeNH: isActivenNH === 3 }"
          >
            气
          </div>
          <!-- <div
            class="square2-category"
            style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
            @click="clickNH(4)"
            :class="{ activeNH: isActivenNH === 4 }"
          >
            蒸汽
          </div> -->
        </div>
        <div style="clear: both"></div>
      </div>
      <div
        v-if="isNHshow"
        style="width: 100%; margin-top: -5px; color: #bcccff; font-size: 14px"
      >
        {{ unit2 }}
      </div>
      <div v-if="isNHshow" style="width: 100%; height: 180px; margin-top: 10px">
        <v-chart ref="chart2" :option="chartOption1" />
      </div>
      <div
        v-if="!isNHshow"
        style="
          width: 100%;
          height: 180px;
          text-align: center;
          line-height: 180px;
          color: rgb(188, 204, 255);
        "
      >
        暂无数据
      </div>
    </div>

    <div class="square-3" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>

      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        运维总览
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>

      <div style="width: 50%; height: 180px; display: inline-block">
        <v-chart ref="chart3" :option="chartOptionCircle1" />
      </div>
      <div style="width: 50%; height: 180px; display: inline-block">
        <v-chart ref="chart4" :option="chartOptionCircle2" />
      </div>

      <!-- <div  style="width: 100%; margin-top: -15px">
        <div
          style="
            width: 50%;
            height: 20px;
            display: inline-block;
            text-align: center;
            color: #fff;
          "
        >
          设备数:323 故障:2
        </div>
        <div
          style="
            width: 50%;
            height: 20px;
            display: inline-block;
            text-align: center;
            color: #fff;
          "
        >
          总工单:323 已完成:2
        </div>
      </div> -->

      <!-- <div
        v-if="!isYWshow"
        style="
          width: 100%;
          height: 130px;
          text-align: center;
          line-height: 130px;
          color: rgb(188, 204, 255);
        "
      >
        暂无数据
      </div> -->
    </div>

    <div class="square-4" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>

      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        用能占比
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>
      <div style="position: absolute; top: 60px; right: 34px">
        <div
          class="square2-category"
          style="border-radius: 2px 0 0 2px"
          @click="clickYN(1)"
          :class="{ activeNH: isActivenYN === 1 }"
        >
          tce
        </div>
        <div
          class="square2-category"
          style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
          @click="clickYN(2)"
          :class="{ activeNH: isActivenYN === 2 }"
        >
          费用
        </div>
      </div>
      <div style="width: 100%; height: 180px; margin-top: 10px">
        <v-chart ref="chart6" :option="chartPieOption1" />
      </div>
    </div>
    <div class="square-5" style="">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>

      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        电能实时负荷及预测
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>

      <div style="width: 100%; margin-top: 10px">
        <div style="float: right">
          <div
            class="square2-category"
            style="border-radius: 2px 0 0 2px"
            @click="clickGN(1)"
            :class="{ activeGN: isActivenGN === 1 }"
          >
            电
          </div>
          <div
            class="square2-category"
            style=""
            @click="clickGN(2)"
            :class="{ activeGN: isActivenGN === 2 }"
          >
            冷
          </div>
          <div
            class="square2-category"
            style="border: 1px solid #3f6df8; border-radius: 0 2px 2px 0"
            @click="clickGN(3)"
            :class="{ activeGN: isActivenGN === 3 }"
          >
            热
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <div
        v-if="isGNshow"
        style="width: 100%; margin-top: -5px; color: #bcccff; font-size: 14px"
      >
        （kWh）
      </div>
      <div v-if="isGNshow" style="width: 100%; height: 180px; margin-top: 10px">
        <v-chart ref="chart5" :option="chartOption2" />
      </div>

      <div
        v-if="!isGNshow"
        style="
          width: 100%;
          height: 180px;
          text-align: center;
          line-height: 180px;
          color: rgb(188, 204, 255);
        "
      >
        暂无数据
      </div>
    </div>
    <div class="square-6" style="padding-left: 0; padding-right: 8px">
      <div style="position: absolute; top: -2px; left: -2px">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: -2px; right: -2px">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; right: -2px">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -6px; left: -2px">
        <img src="~@/assets/直角4.png" alt />
      </div>

      <div
        style="
          width: 100%;
          text-align: center;
          color: #bcccff;
          font-size: 16px;
          font-weight: bold;
        "
      >
        项目总览
      </div>
      <div style="width: 100%; text-align: center; margin-top: 0px">
        <img src="~@/assets/smallTitleLine.png" alt />
      </div>
      <div style="width: 100%; height: 180px; margin-top: 10px">
        <v-chart ref="chart6" :option="chartPieOption2" />
      </div>

      <!-- <div
        v-if="!isZLshow"
        style="
          width: 100%;
          height: 180px;
          text-align: center;
          line-height: 180px;
          color: rgb(188, 204, 255);
        "
      >
        暂无数据
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import fullScreen from "@/views/layoutFull";
// import * as utils from "@/utils/utils";
// import axios from "axios";
import {
  //   getMenu,
  //   getProjects,
  getMapHbDElec,
  getMapHbDWater,
  getMapHbDGas,
  getMapHbElec,
  getMapHbWater,
  getMapHbGas,
  setProject,
  // getBigHeatM,
  // getBigSteamM,
  getMapHbDetailElec,
  getMapHbDetailWater,
  getMapHbDetailGas,
  getMapHbElecMonitor,
  getMapHbElecDialog,
  // getYearChartHeat,
  // getYearChartSteam,
  loginOut,
} from "@/request/apiLg";
import * as echarts from "echarts";
export default {
  components: {},
  data() {
    // var dataAxis = [
    //   "01",
    //   "02",
    //   "03",
    //   "04",
    //   "05",
    //   "06",
    //   "07",
    //   "08",
    //   "09",
    //   "10",
    //   "11",
    //   "12",
    // ];
    // var data = [220, 182, 191, 234, 290, 330, 310, 123, 442, 321, 90, 579];
    return {
      isActivenYN: 1,
      datalegend1: ["实时负荷", "预测负荷"],
      dataValue22: [],
      dataValue211: [],
      dataValue222: [],
      dataValue233: [],
      dataValue23: [],
      detailData: {},
      iconObject: {
        home: "iconfont icon-ditu",
        overall: "iconfont icon-integral",
        actual: "iconfont icon-baobiao",
        resourceManagement: "iconfont icon-NMStubiao-",
        energyComment: "iconfont icon-fenxi",
        dataAnalyze: "iconfont icon-fenxi",
        dataReport: "iconfont icon-fenxi",
        alarmManage: "iconfont icon-alert",
        sysSetting: "iconfont icon-set",
      },
      unit2: "(kWh)",
      isModalChartShow: true,
      isGNshow: false,
      isNHshow: false,
      isYWshow: false,
      isZLshow: false,
      dataUsed: [],
      monthDataAxis: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      elecuse: [0, 0, 0, 0, 0, 0, 0],
      wateruse: ["-", "-", "-", "-", "-", "-", "-"],
      airuse: ["-", "-", "-", "-", "-", "-", "-"],
      heatuse: ["-", "-", "-", "-", "-", "-", "-"],
      elecprovide: ["-", "-", "-", "-", "-", "-", "-"],
      iceprovide: ["-", "-", "-", "-", "-", "-", "-"],
      heatprovide: ["-", "-", "-", "-", "-", "-", "-"],
      isCollapseAnimate: false,
      mapOption: "全部",
      isShowSelectMap: false,
      isShowPoint: true,
      mapName: "全部",
      dataOptionMap: ["全部", "商业", "办公", "酒店", "医院", "其他"],
      ruleForm: {
        name: "",
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
      },
      rules: {
        name: [{ required: true, message: "请输入账号", trigger: "blur" }],
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword1: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        newPassword2: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
      changePasswordVisible: false,
      isShowSelect3: false,
      dataOption1: [],
      dataOption2: ["简体中文", "English"],
      // dataOption3: ["修改密码", "注销登录"],
      languageP: "简体中文",
      projectName: "文体中心",
      isShowSelect1: false,
      isShowSelect2: false,
      collapseImg: `${require("@/assets/收起.png")}`,
      allElecP: "-- --",
      allElecProvide: "-- --",
      allElecAdd: "-- --",
      isBgDeep: true,
      activeIndex: "home",
      isPadding: true,
      isRouter: true,
      isActivenNH: 1,
      isActivenGN: 1,
      isCollapseM: false,
      showModal: false,
      isClick1: false,
      isClick2: false,
      isClick3: false,
      userName: "",
      valueP: "1",
      menuSide: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "选项2",
          label: "2",
        },
        {
          value: "选项3",
          label: "3",
        },
      ],
      // isBigScreen: false,
      url: "http://johpcyvc.cloudpreview.cloud.techsel.net/#/outofLogin?loginInfo=dXNlcm5hbWU9YWRtaW4mcGFzc3dvcmQ9MTIzNDU2",
      isCollapse: false,
      current: "",
      currentKey: "",
      theme: "dark",
      isSide: true,
      isLogout: false,

      chartOptionCircle1: {
        // grid: {
        //   containLabel: true,
        //   top: "5px",
        //   left: "5px",
        //   right: "5px",
        //   bottom: "5px",
        // },
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                // borderWidth: 1,
                // borderColor: "#464646",
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#F2CA3F",
                  },
                  {
                    offset: 1,
                    color: "#ED814C",
                  },
                ]),
                // [
                //   [
                //     1,
                //     new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                //       {
                //         offset: 0,
                //         color: "#F2CA3F",
                //       },
                //       {
                //         offset: 1,
                //         color: "#ED814C",
                //       },
                //     ]),
                //   ],
                // ],
              },
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [
                    1,
                    // new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    //   {
                    //     offset: 0,
                    //     color: "#F2CA3F",
                    //   },
                    //   {
                    //     offset: 1,
                    //     color: "#ED814C",
                    //   },
                    // ]),
                    "rgba(242, 202, 63, 0.3)",
                  ],
                ],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              {
                value: 5,
                name: "故障率",
                title: {
                  offsetCenter: ["0%", "25%"],
                },
                detail: {
                  offsetCenter: ["0%", "-25%"],
                },
              },
              // {
              //   value: 40,
              //   name: "Good",
              //   title: {
              //     offsetCenter: ["0%", "0%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "10%"],
              //   },
              // },
              // {
              //   value: 60,
              //   name: "Commonly",
              //   title: {
              //     offsetCenter: ["0%", "30%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "40%"],
              //   },
              // },
            ],
            title: {
              fontSize: 18,
              color: "#F2CA3F",
            },
            detail: {
              width: 50,
              height: 18,
              fontSize: 18,
              color: "#F2CA3F",
              // borderColor: "auto",
              // borderRadius: 20,
              // borderWidth: 1,
              formatter: "{value}%",
            },
          },
        ],
      },
      chartOptionCircle2: {
        // grid: {
        //   containLabel: true,
        //   top: "5px",
        //   left: "5px",
        //   right: "5px",
        //   bottom: "5px",
        // },
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                // borderWidth: 1,
                // borderColor: "#464646",
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#3FDFF2",
                  },
                  {
                    offset: 1,
                    color: "#3F68F2",
                  },
                ]),
                // [
                //   [
                //     1,
                //     new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                //       {
                //         offset: 0,
                //         color: "#F2CA3F",
                //       },
                //       {
                //         offset: 1,
                //         color: "#ED814C",
                //       },
                //     ]),
                //   ],
                // ],
              },
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [
                    1,
                    // new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    //   {
                    //     offset: 0,
                    //     color: "#F2CA3F",
                    //   },
                    //   {
                    //     offset: 1,
                    //     color: "#ED814C",
                    //   },
                    // ]),
                    "rgba(4, 23, 76, 0.7)",
                  ],
                ],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              {
                value: 90,
                name: "完成率",
                title: {
                  offsetCenter: ["0%", "25%"],
                },
                detail: {
                  offsetCenter: ["0%", "-25%"],
                },
              },
              // {
              //   value: 40,
              //   name: "Good",
              //   title: {
              //     offsetCenter: ["0%", "0%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "10%"],
              //   },
              // },
              // {
              //   value: 60,
              //   name: "Commonly",
              //   title: {
              //     offsetCenter: ["0%", "30%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "40%"],
              //   },
              // },
            ],
            title: {
              fontSize: 18,
              color: "#3FDFF2",
            },
            detail: {
              width: 50,
              height: 18,
              fontSize: 18,
              color: "#3FDFF2",
              // borderColor: "auto",
              // borderRadius: 20,
              // borderWidth: 1,
              formatter: "{value}%",
            },
          },
        ],
      },
    };
  },
  mounted() {
    setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
      (res2) => {
        if (res2.data.code === 0) {
          getMapHbElecMonitor().then((res) => {
            console.log(res);
            // debugger
            if (res.data.data.first.length > 0) {
              // debugger
              this.isGNshow = true;
              res.data.data.first.forEach((item) => {
                this.dataValue22.push(item.formatValue);
              });
              res.data.data.last.forEach((item) => {
                this.dataValue23.push(item.formatValue);
              });
              console.log(this.dataValue22, this.dataValue23);
            }
          });
          getMapHbElec().then((res) => {
            console.log(res);
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.elecuse = ["-", "-", "-", "-", "-", "-", "-"];
              } else {
                // this.allElecP = res.data.data.formatValue;
                res.data.data.formatValue = (
                  Number(res.data.data.formatValue) / 10000
                ).toFixed(1);
                let arr1 = res.data.data.formatValue.split("");
                const hasDot = arr1.find((v) => v === ".");
                if (!hasDot) {
                  // this.elecuse[5] = 0;
                  this.elecuse[6] = 0;
                  // if(arr1.length===0){

                  // }
                  if (arr1.length === 1) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = 0;
                    this.elecuse[4] = 0;
                    this.elecuse[5] = arr1[0];
                  } else if (arr1.length === 2) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = 0;
                    this.elecuse[4] = arr1[0];
                    this.elecuse[5] = arr1[1];
                  } else if (arr1.length === 3) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = arr1[0];
                    this.elecuse[4] = arr1[1];
                    this.elecuse[5] = arr1[2];
                  } else if (arr1.length === 4) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = arr1[0];
                    this.elecuse[3] = arr1[1];
                    this.elecuse[4] = arr1[2];
                    this.elecuse[5] = arr1[3];
                    // this.elecuse[5] = arr1[4];
                  } else if (arr1.length === 5) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = arr1[0];
                    this.elecuse[2] = arr1[1];
                    this.elecuse[3] = arr1[2];
                    this.elecuse[4] = arr1[3];
                    this.elecuse[5] = arr1[4];
                  } else if (arr1.length === 6) {
                    this.elecuse[0] = arr1[0];
                    this.elecuse[1] = arr1[1];
                    this.elecuse[2] = arr1[2];
                    this.elecuse[3] = arr1[3];
                    this.elecuse[4] = arr1[4];
                    this.elecuse[5] = arr1[5];
                  }
                } else {
                  let arr2 = res.data.data.formatValue.split(".");
                  let arr3 = arr2[0].toString().split("");
                  this.elecuse[6] = arr2[1];
                  if (arr3.length === 1) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = 0;
                    this.elecuse[4] = 0;
                    this.elecuse[5] = arr3[0];
                  } else if (arr3.length === 2) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = 0;
                    this.elecuse[4] = arr3[0];
                    this.elecuse[5] = arr3[1];
                  } else if (arr3.length === 3) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = 0;
                    this.elecuse[3] = arr3[0];
                    this.elecuse[4] = arr3[1];
                    this.elecuse[5] = arr3[2];
                  } else if (arr3.length === 4) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = 0;
                    this.elecuse[2] = arr3[0];
                    this.elecuse[3] = arr3[1];
                    this.elecuse[4] = arr3[2];
                    this.elecuse[5] = arr3[3];
                  } else if (arr3.length === 5) {
                    this.elecuse[0] = 0;
                    this.elecuse[1] = arr3[0];
                    this.elecuse[2] = arr3[1];
                    this.elecuse[3] = arr3[2];
                    this.elecuse[4] = arr3[3];
                    this.elecuse[5] = arr3[4];
                  } else if (arr3.length === 6) {
                    this.elecuse[0] = arr3[0];
                    this.elecuse[1] = arr3[1];
                    this.elecuse[2] = arr3[2];
                    this.elecuse[3] = arr3[3];
                    this.elecuse[4] = arr3[4];
                    this.elecuse[5] = arr3[5];
                  }
                }

                console.log("arr1", arr1);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          getMapHbWater().then((res) => {
            console.log(res);
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.wateruse = ["-", "-", "-", "-", "-", "-", "-"];
              } else {
                // this.allElecP = res.data.data.formatValue;
                res.data.data.formatValue = Number(
                  res.data.data.formatValue
                ).toFixed(1);
                let arr1 = res.data.data.formatValue.split("");
                const hasDot = arr1.find((v) => v === ".");
                if (!hasDot) {
                  // this.wateruse[5] = 0;
                  this.wateruse[6] = 0;
                  // if(arr1.length===0){

                  // }
                  if (arr1.length === 1) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = 0;
                    this.wateruse[4] = 0;
                    this.wateruse[5] = arr1[0];
                  } else if (arr1.length === 2) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = 0;
                    this.wateruse[4] = arr1[0];
                    this.wateruse[5] = arr1[1];
                  } else if (arr1.length === 3) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = arr1[0];
                    this.wateruse[4] = arr1[1];
                    this.wateruse[5] = arr1[2];
                  } else if (arr1.length === 4) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = arr1[0];
                    this.wateruse[3] = arr1[1];
                    this.wateruse[4] = arr1[2];
                    this.wateruse[5] = arr1[3];
                    // this.wateruse[5] = arr1[4];
                  } else if (arr1.length === 5) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = arr1[0];
                    this.wateruse[2] = arr1[1];
                    this.wateruse[3] = arr1[2];
                    this.wateruse[4] = arr1[3];
                    this.wateruse[5] = arr1[4];
                  } else if (arr1.length === 6) {
                    this.wateruse[0] = arr1[0];
                    this.wateruse[1] = arr1[1];
                    this.wateruse[2] = arr1[2];
                    this.wateruse[3] = arr1[3];
                    this.wateruse[4] = arr1[4];
                    this.wateruse[5] = arr1[5];
                  }
                } else {
                  let arr2 = res.data.data.formatValue.split(".");
                  let arr3 = arr2[0].toString().split("");
                  this.wateruse[6] = arr2[1];
                  if (arr3.length === 1) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = 0;
                    this.wateruse[4] = 0;
                    this.wateruse[5] = arr3[0];
                  } else if (arr3.length === 2) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = 0;
                    this.wateruse[4] = arr3[0];
                    this.wateruse[5] = arr3[1];
                  } else if (arr3.length === 3) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = 0;
                    this.wateruse[3] = arr3[0];
                    this.wateruse[4] = arr3[1];
                    this.wateruse[5] = arr3[2];
                  } else if (arr3.length === 4) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = 0;
                    this.wateruse[2] = arr1[0];
                    this.wateruse[3] = arr1[1];
                    this.wateruse[4] = arr1[2];
                    this.wateruse[5] = arr1[3];
                  } else if (arr3.length === 5) {
                    this.wateruse[0] = 0;
                    this.wateruse[1] = arr3[0];
                    this.wateruse[2] = arr3[1];
                    this.wateruse[3] = arr3[2];
                    this.wateruse[4] = arr3[3];
                    this.wateruse[5] = arr3[4];
                  } else if (arr3.length === 6) {
                    this.wateruse[0] = arr3[0];
                    this.wateruse[1] = arr3[1];
                    this.wateruse[2] = arr3[2];
                    this.wateruse[3] = arr3[3];
                    this.wateruse[4] = arr3[4];
                    this.wateruse[5] = arr3[5];
                  }
                }

                console.log("arr1", arr1);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });

          getMapHbGas().then((res) => {
            console.log(res);
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.airuse = ["-", "-", "-", "-", "-", "-", "-"];
              } else {
                // debugger
                // this.allElecP = res.data.data.formatValue;
                res.data.data.formatValue = Number(
                  res.data.data.formatValue
                ).toFixed(1);
                let arr1 = res.data.data.formatValue.split("");
                const hasDot = arr1.find((v) => v === ".");
                if (!hasDot) {
                  // this.airuse[5] = 0;
                  this.airuse[6] = 0;
                  // if(arr1.length===0){

                  // }
                  if (arr1.length === 1) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = 0;
                    this.airuse[4] = 0;
                    this.airuse[5] = arr1[0];
                  } else if (arr1.length === 2) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = 0;
                    this.airuse[4] = arr1[0];
                    this.airuse[5] = arr1[1];
                  } else if (arr1.length === 3) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = arr1[0];
                    this.airuse[4] = arr1[1];
                    this.airuse[5] = arr1[2];
                  } else if (arr1.length === 4) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = arr1[0];
                    this.airuse[3] = arr1[1];
                    this.airuse[4] = arr1[2];
                    this.airuse[5] = arr1[3];
                    // this.airuse[5] = arr1[4];
                  } else if (arr1.length === 5) {
                    this.airuse[0] = 0;
                    this.airuse[1] = arr1[0];
                    this.airuse[2] = arr1[1];
                    this.airuse[3] = arr1[2];
                    this.airuse[4] = arr1[3];
                    this.airuse[5] = arr1[4];
                  } else if (arr1.length === 6) {
                    this.airuse[0] = arr1[0];
                    this.airuse[1] = arr1[1];
                    this.airuse[2] = arr1[2];
                    this.airuse[3] = arr1[3];
                    this.airuse[4] = arr1[4];
                    this.airuse[5] = arr1[5];
                  }
                } else {
                  let arr2 = res.data.data.formatValue.split(".");
                  let arr3 = arr2[0].toString().split("");
                  this.airuse[6] = arr2[1];
                  if (arr3.length === 1) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = 0;
                    this.airuse[4] = 0;
                    this.airuse[5] = arr3[0];
                  } else if (arr3.length === 2) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = 0;
                    this.airuse[4] = arr3[0];
                    this.airuse[5] = arr3[1];
                  } else if (arr3.length === 3) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = 0;
                    this.airuse[3] = arr3[0];
                    this.airuse[4] = arr3[1];
                    this.airuse[5] = arr3[2];
                  } else if (arr3.length === 4) {
                    this.airuse[0] = 0;
                    this.airuse[1] = 0;
                    this.airuse[2] = arr1[0];
                    this.airuse[3] = arr1[1];
                    this.airuse[4] = arr1[2];
                    this.airuse[5] = arr1[3];
                  } else if (arr3.length === 5) {
                    this.airuse[0] = 0;
                    this.airuse[1] = arr3[0];
                    this.airuse[2] = arr3[1];
                    this.airuse[3] = arr3[2];
                    this.airuse[4] = arr3[3];
                    this.airuse[5] = arr3[4];
                  } else if (arr3.length === 6) {
                    this.airuse[0] = arr3[0];
                    this.airuse[1] = arr3[1];
                    this.airuse[2] = arr3[2];
                    this.airuse[3] = arr3[3];
                    this.airuse[4] = arr3[4];
                    this.airuse[5] = arr3[5];
                  }
                }

                console.log("arr1", arr1);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          getMapHbDElec().then((res) => {
            // debugger
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.allElecP = "-- --";
              } else {
                this.allElecP = res.data.data.formatValue;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          getMapHbDWater().then((res) => {
            // debugger
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.allElecAdd = "-- --";
              } else {
                this.allElecAdd = res.data.data.formatValue;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          getMapHbDGas().then((res) => {
            // debugger
            if (res.data) {
              if (res.data.data.formatValue === null) {
                this.allElecProvide = "-- --";
              } else {
                this.allElecProvide = res.data.data.formatValue;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          getMapHbDetailElec().then((res) => {
            console.log(res);
            if (res.data.data.length > 0) {
              this.isNHshow = true;
              res.data.data.forEach((item) => {
                this.dataUsed.push(item.formatValue);
              });
            }
            // this.dataUsed[3] = '10';
            // this.dataUsed = [
            //   220,
            //   182,
            //   191,
            //   234,
            //   290,
            //   330,
            //   310,
            //   123,
            //   442,
            //   321,
            //   90,
            //   579,
            // ];
            console.log("this.dataUsed", this.dataUsed);
          });
          if (this.$route.name === "actual") {
            this.isPadding = false;
          } else {
            this.isPadding = true;
          }

          if (
            this.$route.name == "actual" ||
            this.$route.name == "home" ||
            this.$route.name == "overall"
          ) {
            this.isBgDeep = true;
          } else {
            this.isBgDeep = false;
          }
          this.activeIndex = this.$route.name;

          this.userName = localStorage.getItem("userName");
          let self = this;
          this.$nextTick(function () {
            document.addEventListener("keyup", function (e) {
              //此处填写你的业务逻辑即可
              // debugger
              if (e.key === "Escape") {
                self.closeScreenFull();
              }
            });
          });
        }
      }
    );
  },
  computed: {
    chartModalOption() {
      return {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "30px",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ["line", "bar"] },
        //     restore: { show: true },
        //     saveAsImage: { show: true },
        //   },
        // },
        legend: {
          data: ["总用电量", "预测总用电量", "温度"],
          right: 100,
          bottom: 0,
          textStyle: {
            color: "#bcccff",
          },
        },
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#BCCCFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "耗能",
            min: function (value) {
              // debugger
              return value.min;
            },
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#BCCCFF",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "温度",
            // min: 0,
            // max: 25,
            // interval: 5,
            min: function (value) {
              // debugger
              return value.min;
            },
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#BCCCFF",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "总用电量",
            type: "bar",
            data: this.dataValue211,
          },
          {
            name: "预测总用电量",
            type: "line",
            // yAxisIndex: 1,
            smooth: true,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#41F39A", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.dataValue222,
          },
          {
            name: "温度",
            type: "line",
            yAxisIndex: 1,
            data: this.dataValue233,
          },
        ],
      };
    },
    chartOption2() {
      return {
        legend: {
          data: ["实时负荷", "预测负荷"],
          right: 0,
          top: 0,
          textStyle: {
            color: "#BCCCFF",
          },
        },
        tooltip: {
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}: {c}kWh",
        },
        grid: {
          containLabel: true,
          top: "20px",
          left: "5px",
          right: "5px",
          bottom: "10px",
        },
        xAxis: {
          type: "category",
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#BCCCFF",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#BCCCFF",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            name: "实时负荷",
            type: "line",
            // yAxisIndex: 1,
            smooth: true,
            itemStyle: {
              color: "#41F39A",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#41F39A", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.dataValue22,
          },
          {
            name: "预测负荷",
            type: "line",
            smooth: true,
            // yAxisIndex: 1,
            itemStyle: {
              color: "#3FDFF2",
            },
            data: this.dataValue23,
          },
        ],
      };
    },
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      currentProjectId: (state) => state.currentProjectId,
      currentProjectName: (state) => state.currentProjectName,
      projectArray: (state) => state.projectArray,
    }),
    chartPieOption1() {
      return {
        color: ["#ED9C00", "#53EFC3", "#3F6DF8"],
        grid: {
          // containLabel: true,
          // top: "10px",
          // left: "5px",
          // right: "10px",
          // bottom: "10px",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 20,
          bottom: 20,
          data: [
            {
              name: "用电",
              textStyle: {
                color: "#ED9C00",
              },
            },
            {
              name: "用水",
              textStyle: {
                color: "#53EFC3",
              },
            },
            {
              name: "用气",
              textStyle: {
                color: "#3F6DF8",
              },
            },
          ],
          // data: data.legendData,
          // selected: data.selected,
          // textStyle: {
          //   color: "#bcccff",
          // },
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["20%", "60%"],
            center: ["40%", "45%"],
            avoidLabelOverlap: false,
            label: {
              position: "outside",
              // alignTo: "edge",
              // formatter: "{per|{c} %}\n",
              formatter: (data) => {
                if (data.name === "用电") {
                  return `{a|${data.percent} %\n}`;
                } else if (data.name === "用水") {
                  return `{b|${data.percent} %\n}`;
                } else if (data.name === "用气") {
                  return `{c|${data.percent} %\n}`;
                }
                // else if (data.name === "光伏电站") {
                //   return `{d|${data.percent} %\n}`;
                // } else if (data.name === "能源站") {
                //   return `{e|${data.percent} %\n}`;
                // }
              },
              // minMargin: 5,
              // edgeDistance: 10,
              // lineHeight: 15,
              rich: {
                a: {
                  fontSize: 14,
                  color: "#ED9C00",
                  // fontWeight: "bold",
                },
                b: {
                  fontSize: 14,
                  color: "#53EFC3",
                  // fontWeight: "bold",
                },
                c: {
                  fontSize: 14,
                  color: "#3F6DF8",
                  // fontWeight: "bold",
                },
                // d: {
                //   fontSize: 14,
                //   color: "#53EFC3",
                //   // fontWeight: "bold",
                // },
                // e: {
                //   fontSize: 14,
                //   color: "#1464C9",
                //   // fontWeight: "bold",
                // },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length2: 20,
              // smooth: true,
              // minTurnAngle: 50,
            },
            data: [
              { value: 55, name: "用电" },
              { value: 20, name: "用水" },
              { value: 25, name: "用气" },
              // { value: 20, name: "光伏电站" },
              // { value: 10, name: "能源站" },
            ],
          },
        ],
      };
    },
    chartPieOption2() {
      return {
        color: ["#5492D1", "#46BCEE", "#ED9C00", "#53EFC3", "#1464C9"],
        grid: {
          // containLabel: true,
          // top: "10px",
          // left: "5px",
          // right: "10px",
          // bottom: "10px",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 20,
          bottom: 20,
          data: [
            {
              name: "商业",
              textStyle: {
                color: "#1464C9",
              },
            },
            {
              name: "办公",
              textStyle: {
                color: "#53EFC3",
              },
            },
            {
              name: "酒店",
              textStyle: {
                color: "#ED9C00",
              },
            },
            {
              name: "医院",
              textStyle: {
                color: "#46BCEE",
              },
            },
            {
              name: "其他",
              textStyle: {
                color: "#5492D1",
              },
            },
          ],
          // data: data.legendData,
          // selected: data.selected,
          // textStyle: {
          //   color: "#bcccff",
          // },
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["20%", "60%"],
            center: ["40%", "45%"],
            avoidLabelOverlap: false,
            label: {
              position: "outside",
              // alignTo: "edge",
              // formatter: "{per|{c} %}\n",
              formatter: (data) => {
                if (data.name === "其他") {
                  return `{a|${data.percent} %\n}`;
                } else if (data.name === "医院") {
                  return `{b|${data.percent} %\n}`;
                } else if (data.name === "酒店") {
                  return `{c|${data.percent} %\n}`;
                } else if (data.name === "办公") {
                  return `{d|${data.percent} %\n}`;
                } else if (data.name === "商业") {
                  return `{e|${data.percent} %\n}`;
                }
              },
              // minMargin: 5,
              // edgeDistance: 10,
              // lineHeight: 15,
              rich: {
                a: {
                  fontSize: 14,
                  color: "#5492D1",
                  // fontWeight: "bold",
                },
                b: {
                  fontSize: 14,
                  color: "#46BCEE",
                  // fontWeight: "bold",
                },
                c: {
                  fontSize: 14,
                  color: "#ED9C00",
                  // fontWeight: "bold",
                },
                d: {
                  fontSize: 14,
                  color: "#53EFC3",
                  // fontWeight: "bold",
                },
                e: {
                  fontSize: 14,
                  color: "#1464C9",
                  // fontWeight: "bold",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length2: 20,
              // smooth: true,
              // minTurnAngle: 50,
            },
            data: [
              { value: 15, name: "其他" },
              { value: 15, name: "医院" },
              { value: 40, name: "酒店" },
              { value: 20, name: "办公" },
              { value: 10, name: "商业" },
            ],
          },
        ],
      };
    },
    chartOption1() {
      return {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}kWh",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "10px",
        },
        xAxis: {
          data: this.monthDataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#BCCCFF",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#BCCCFF",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 5,
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#55ffbc" },
                  { offset: 0.62, color: "#47A5E1" },
                  { offset: 1, color: "#3F6DF8" },
                ]),
              },
            },
            data: this.dataUsed,
          },
        ],
      };
    },
    key() {
      return this.$route.fullPath;
    },
    classObject() {
      let temp1 = "";
      let temp2 = "";
      if (this.isPadding) {
        temp1 = "isPaddingTBody";
      } else {
        temp1 = "isPaddingFBody";
      }
      if (this.isBgDeep) {
        temp2 = "isBgDeepT";
      } else {
        temp2 = "isBgDeepF";
      }
      return temp1 + " " + temp2;
    },
  },
  methods: {
    over1point() {
      this.isClick1 = true;
      this.isClick2 = false;
      this.isClick3 = false;
    },
    over2point() {
      this.isClick1 = false;
      this.isClick2 = true;
      this.isClick3 = false;
    },
    over3point() {
      this.isClick1 = false;
      this.isClick2 = false;
      this.isClick3 = true;
    },
    clickSelect1(e) {
      e.stopPropagation();
      this.isShowSelect1 = !this.isShowSelect1;
    },
    clickSelect2(e) {
      e.stopPropagation();
      this.isShowSelect2 = !this.isShowSelect2;
    },
    falseAll() {
      this.isShowSelect1 = false;
      this.isShowSelect2 = false;
      // this.isShowSelectInterval = false;
    },
    clickOptionMap(item) {
      this.mapName = item;
      if (item === "商业" || item === "全部") {
        this.isShowPoint = true;
      } else {
        this.isShowPoint = false;
      }
    },
    changePassord() {
      this.changePasswordVisible = true;
    },
    clickOption1(item) {
      // this.projectName = item.projectName;
      this.mutationsChangeName(item.projectName);
      this.mutationsChangeId(item.projectId);
      setProject(item.projectId).then((res) => {
        if (res.data.code === 0) {
          // this.mutationsChangeArray(item.projectId);
        }
      });
    },
    clickOption2(item) {
      this.languageP = item;
    },
    collapaseMenu() {
      this.isCollapseM = !this.isCollapseM;
      this.isCollapse = !this.isCollapse;
      if (this.isCollapseM) {
        this.collapseImg = `${require("@/assets/展开.png")}`;
      } else {
        this.collapseImg = `${require("@/assets/收起.png")}`;
      }
    },
    click1point() {
      let data2 = {
        energyTypeId: "elec",
        projectId: "zgds",
      };
      getMapHbElecDialog(data2).then((res) => {
        console.log(res.data.data);
        this.isClick1 = true;
        this.isClick2 = false;
        this.isClick3 = false;
        this.showModal = true;
        this.detailData = {
          id: "zgds",
          src: require("@/assets/zgdsdialog.jpg"),
          title: "清华紫光大厦",
          area: 7180,
          desc: "紫光大厦位于苏州中心城市“一核四城”发展定位的北部核心板块——苏州高铁新城启动区，占地面积7180平方米，总建筑面积约5.8万平方米，总高150米。是苏州市高铁新城启动区入驻的首批项目之一。该建筑由6700平方米的商业裙房以及一座150米高、总面积3.6万平方米的办公塔楼构成。是苏州风格现代都市城区、枢纽型高端服务业态区、低碳生态可持续示范区的重要组成部分。",
        };
        let temp1 = [];
        let temp2 = [];
        let temp3 = [];
        let temp4 = [];
        res.data.data.first.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.first[index1].formatValue = "";
          }
          temp1.push(item1.formatValue);
        });
        res.data.data.last.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.last[index1].formatValue = "";
            temp4.push(1);
          }
          temp2.push(item1.formatValue);
        });
        res.data.data.temp.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.first[index1].formatValue = "";
          }
          temp3.push(item1.formatValue);
        });
        this.dataValue211 = temp1;
        this.dataValue222 = temp2;
        if (temp4.length === 24) {
          this.dataValue222 = [];
        }
        this.dataValue233 = temp3;
      });
    },
    click2point() {
      let data2 = {
        energyTypeId: "elec",
        projectId: "tcsd",
      };
      getMapHbElecDialog(data2).then((res) => {
        console.log(res.data.data);
        this.isClick1 = false;
        this.isClick2 = true;
        this.isClick3 = false;
        this.showModal = true;
        this.detailData = {
          id: "tcsd",
          src: require("@/assets/tcsddialog.jpg"),
          title: "天成时代商务广场",
          area: 10527,
          desc: "天成时代商务广场位于相城高铁新城板块，苏州青龙港路58号。占地面积：10527㎡总建筑面积：81617㎡。临近轨交高铁北站，是一栋由约150米高的三十三层塔楼和四层裙房组成的超高层城市综合体，建筑立面主要由石材和玻璃幕墙相结合。地下室设两层。具体用途是：1-4层裙房为商业用房；6-15层、17-26层、28-33层为塔楼办公用房。",
        };

        let temp1 = [];
        let temp2 = [];
        let temp3 = [];
        let temp4 = [];
        res.data.data.first.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.first[index1].formatValue = "";
          }
          temp1.push(item1.formatValue);
        });
        res.data.data.last.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.last[index1].formatValue = "";
            temp4.push(1);
          }
          temp2.push(item1.formatValue);
        });
        res.data.data.temp.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.first[index1].formatValue = "";
          }
          temp3.push(item1.formatValue);
        });
        this.dataValue211 = temp1;
        this.dataValue222 = temp2;
        if (temp4.length === 24) {
          this.dataValue222 = [];
        }
        this.dataValue233 = temp3;
      });
    },
    click3point() {
      let data2 = {
        energyTypeId: "elec",
        projectId: "jkjd",
      };
      getMapHbElecDialog(data2).then((res) => {
        console.log(res.data.data);
        this.isClick1 = false;
        this.isClick2 = false;
        this.isClick3 = true;
        this.showModal = true;
        this.detailData = {
          id: "jkjd",
          src: require("@/assets/jkjddialog.jpg"),
          title: "金科酒店",
          area: 10527,
          desc: "金科酒店位于相城高铁新城板块，苏州青龙港路58号。占地面积：10527㎡总建筑面积：81617㎡。临近轨交高铁北站，是一栋由约150米高的三十三层塔楼和四层裙房组成的超高层城市综合体，建筑立面主要由石材和玻璃幕墙相结合。地下室设两层。具体用途是：1-4层裙房为商业用房；6-15层、17-26层、28-33层为塔楼办公用房。",
        };

        let temp1 = [];
        let temp2 = [];
        let temp3 = [];
        let temp4 = [];
        res.data.data.first.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.first[index1].formatValue = "";
          }
          temp1.push(item1.formatValue);
        });
        res.data.data.last.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.last[index1].formatValue = "";
            temp4.push(1);
          }
          temp2.push(item1.formatValue);
        });
        res.data.data.temp.forEach((item1, index1) => {
          if (item1.formatValue === null) {
            res.data.data.first[index1].formatValue = "";
          }
          temp3.push(item1.formatValue);
        });
        this.dataValue211 = temp1;
        this.dataValue222 = temp2;
        if (temp4.length === 24) {
          this.dataValue222 = [];
        }
        this.dataValue233 = temp3;
      });
    },
    clearAll() {
      this.isClick1 = false;
      this.isClick2 = false;
      this.isClick3 = false;
      this.showModal = false;
    },
    closeScreenFull() {
      // this.isBigScreen = false;
      // this.mutationsHideBig()
      this.$store.commit("mutationsHideBig");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    showLogout() {
      this.isLogout = true;
    },
    hideLogout() {
      this.isLogout = false;
    },
    logOut() {
      // let data = {};
      // axios
      //   .post("http://192.168.2.102:8088/api/auth/logout", data, {
      //     headers: {
      //       Authorization: localStorage.getItem("accessToken"),
      //     },
      //   })
      loginOut()
        .then((res) => {
          if (res.data) {
            if (res.data.code === 0) {
              this.$message.info("退出登录");
              console.log("1");
              localStorage.removeItem("accessToken");
              location.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          } else {
            if (res.data.msg) {
              this.$message.error(res.data.msg);
            } else {
              this.$message.error("系统未知错误");
            }
          }
        })
        .catch((error) => {
          this.$message.error("系统未知错误");
          console.log(error);
        });
    },
    init() {
      this.current = this.$route.name;
      this.currentKey = this.$route.name;
      console.log("1111", this.$route);
      console.log("2222", this.current);
      console.log("3333", this.currentKey);
      if (this.currentKey == "basicInfo") {
        this.isSide = false;
      } else {
        this.currentKey = this.$route.meta.one;
      }
    },
    handleClick(e) {
      console.log("click ", e);
      this.current = e.key;
      if (e.key == "channelData") {
        this.$router.push({ name: "channelData" });
      } else if (e.key == "original") {
        this.$router.push({ name: "original" });
      }
    },
    handleClickOne(e) {
      console.log("click", e);
      console.log(this.$route.name);
      this.currentKey = e.key;
      if (e.key == "dataManagement") {
        this.current = "channelData";
        this.isSide = true;
        this.$router.push({ name: "channelData" });
      } else if (e.key == "basicInfo") {
        this.isSide = false;
        this.$router.push({ name: "basicInfo" });
      }
    },
    toProjectOverall(item) {
      this.mutationsChangeName(item.title);
      this.mutationsChangeId(item.id);
      setProject(item.id).then((res) => {
        if (res.data.code === 0) {
          // this.mutationsChangeArray(item.projectId);
          this.$router.push({ name: "overall" });
        }
      });
    },
    changeTheme(checked) {
      this.theme = checked ? "dark" : "light";
    },
    clickYN(index) {
      this.isActivenYN = index;
    },
    clickNH(index) {
      this.isActivenNH = index;
      this.dataUsed = [];
      if (index == 1) {
        this.isNHshow = false;
        this.unit2 = "(kWh)";
        getMapHbDetailElec().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          // this.dataUsed[3] = '10';
          // this.dataUsed = [
          //   220,
          //   182,
          //   191,
          //   234,
          //   290,
          //   330,
          //   310,
          //   123,
          //   442,
          //   321,
          //   90,
          //   579,
          // ];
          console.log("this.dataUsed", this.dataUsed);
        });
      } else if (index === 2) {
        this.isNHshow = false;
        this.unit2 = "(m3)";
        getMapHbDetailWater().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          console.log("this.dataUsed", this.dataUsed);
        });
      } else if (index === 3) {
        this.isNHshow = false;
        this.unit2 = "(m3)";
        getMapHbDetailGas().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          console.log("this.dataUsed", this.dataUsed);
        });
      }
      // else if (index === 4) {
      //   this.isNHshow = false;
      //   this.unit2 = "(kWh)";
      //   getYearChartSteam().then((res) => {
      //     console.log(res);
      //     if (res.data.data.length > 0) {
      //       this.isNHshow = true;
      //       res.data.data.forEach((item) => {
      //         this.dataUsed.push(item.formatValue);
      //       });
      //     }
      //     console.log("this.dataUsed", this.dataUsed);
      //   });
      // }
    },
    clickGN(index) {
      this.isActivenGN = index;
    },
    resizeHandler() {
      // this.$refs.chart1.resize();
      // this.$refs.chart2.resize();
      // this.$refs.chart3.resize();
      // this.$refs.chart4.resize();
      // this.$refs.chart5.resize();
      // this.$refs.chart6.resize();
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    ...mapMutations([
      "mutationsShowBig",
      "mutationsHideBig",
      "mutationsChangeId",
      "mutationsChangeArray",
      "mutationsChangeName",
    ]),
  },
};
</script>
<style scoped>
.el-submenu >>> .el-submenu__title i {
  font-size: 16px;
  color: white;
  opacity: 0.8;
}
.el-menu {
  border: none !important;
}

.el-menu-item span {
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 16px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 250px;
  height: 100%;
}

.header {
  height: 96px;
  width: 100%;
  background: #082254;
  box-shadow: 2px 2px 10px 0px rgba(8, 34, 84, 0.2);
  /* padding-left: 30px;
  padding-right: 30px; */
  position: relative;
  display: flex;
  align-items: center;
}
.header-content {
  width: 250px;
  flex: none;
  padding-left: 48px;
}
.isCollapseTHeader {
  width: 64px;
  flex: none;
  padding-left: 48px;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.isCollapseFHeader {
  width: 250px;
  flex: none;
  padding-left: 48px;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.isCollapseTBody {
  width: 64px;
  flex: none;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.isCollapseFBody {
  width: 250px;
  flex: none;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.header-content-left {
  height: 96px;
  line-height: 96px;
  /* float: left; */
}
.header-content-right {
  flex: 1;
  text-align: center;
  height: 96px;
  line-height: 96px;
  position: relative;
  /* display: flex;
  align-items: center; */
}
.header-content-left span {
  margin-left: 10px;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 24px;
  font-weight: bold;
}
.user-name {
  position: absolute;
  top: 19px;
  right: 20px;
  color: #fff;
  font-size: 16px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  cursor: pointer;
  z-index: 9999;
  height: 64px;
}
.down-menu {
  position: absolute;
  top: 70px;
  right: 0;
  background: #001529;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  text-align: center;
  width: 120px;
  color: white;
  z-index: 9999;
  cursor: pointer;
}
</style>
<style scoped>
.big-screen {
  background: url("~@/assets/bigBG.png");
  background-size: cover;
  position: relative;
}
.select-class >>> .el-input__inner {
  border-radius: 4px;
  border: 1px solid #3f6df8;
  border-color: rgba(63, 109, 248, 0.5);
  background: transparent;
}
.select-class >>> .el-select__caret {
  color: rgba(63, 109, 248, 0.5);
}
.square-1 {
  padding: 20px;
  padding-top: 10px;
  width: 401px;
  height: 206px;
  position: absolute;
  top: 85px;
  left: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-2 {
  padding: 20px;
  width: 401px;
  height: 317px;
  position: absolute;
  top: 312px;
  left: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-3 {
  padding: 20px;
  width: 401px;
  height: 267px;
  position: absolute;
  top: 652px;
  left: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-4 {
  padding: 20px;
  width: 401px;
  height: 250px;
  position: absolute;
  top: 85px;
  right: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-5 {
  padding: 20px;
  width: 401px;
  height: 287px;
  position: absolute;
  top: 357px;
  right: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-6 {
  padding: 20px;
  width: 401px;
  height: 252px;
  position: absolute;
  top: 670px;
  right: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-number {
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 24px;
  border: 1px solid #3f6df8;
  border-color: rgba(63, 109, 248, 0.5);
  color: #ffffff;
  margin-right: 10px;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: white;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
}
.activeGN {
  background: #3f6df8;
}
.isPaddingTBody {
  padding: 20px;
}
.isPaddingFBody {
  padding: 0;
}
.isBgDeepT {
  background: #203865;
}
.isBgDeepF {
  background: #f4f7fc;
}

.el-submenu >>> .el-menu--inline li {
  background: rgba(6, 28, 70, 0.64) !important;
}
.is-active {
  background: #3f6df8 !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}
.el-submenu >>> .el-menu--inline .is-active {
  background: #3f6df8 !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}
.el-menu-item:hover {
  background: #3f6df8 !important;
}
.el-submenu >>> .el-submenu__title:hover {
  background: #3f6df8 !important;
}
.el-submenu >>> .el-menu--inline li:hover {
  background: #3f6df8 !important;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  /* padding-left: 15px; */
  color: #bcccff;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.activeOption1 {
  background: #3f6df8;
  color: white;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
</style>
<style>
/* .el-menu--vertical ul li {
  background: rgba(6, 28, 70, 0.64) !important;
}
.el-menu--vertical ul .is-active {
  background: #3f6df8 !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
} */
.el-menu-vertical-demo::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.el-menu-vertical-demo::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background-color: #3f6df8;
  /* background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  ); */
}
.el-menu-vertical-demo::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
  border-radius: 10px;
}
</style>