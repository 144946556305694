<template>
  <div>
    <a-select
      :getPopupContainer="triggerNode => triggerNode.parentNode"
      @dropdownVisibleChange="handleClick"
      :open="false"
      class="btn-select-component"
      value="1"
    >
      <a-select-option value="1">{{text}}</a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  props: ["text"],
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="less">
.btn-select-component {
  position: relative;
  .ant-select-arrow-icon {
    svg {
      transform: rotate(0deg) !important;
    }
  }
}
</style>
